import React from 'react'
import { ReactComponent as AmexIcon } from '../assets/icons/paymentIcons/amex.svg'
import { ReactComponent as AmexIcon2 } from '../assets/icons/paymentIcons/amexLg.svg'
import { ReactComponent as AmexClosedIcon } from '../assets/icons/paymentIcons/amexClosed.svg'
import { ReactComponent as ApplePayIcon } from '../assets/icons/paymentIcons/applePay.svg'
import { ReactComponent as ApplePayIcon2 } from '../assets/icons/paymentIcons/applePayLg.svg'
import { ReactComponent as ApplePayClosedIcon } from '../assets/icons/paymentIcons/applePayClosed.svg'
import { ReactComponent as CardDeclinedIcon } from '../assets/icons/paymentIcons/cardDeclined.svg'
import { ReactComponent as CashIcon } from '../assets/icons/paymentIcons/cash.svg'
import { ReactComponent as CashIcon2 } from '../assets/icons/paymentIcons/cash2.svg'
import { ReactComponent as AppIcon } from '../assets/icons/paymentIcons/appIcon.svg'
import { ReactComponent as CashClosedIcon } from '../assets/icons/paymentIcons/cashClosed.svg'
import { ReactComponent as DiscoverIcon } from '../assets/icons/paymentIcons/discover.svg'
import { ReactComponent as DiscoverIcon2 } from '../assets/icons/paymentIcons/discoverLg.svg'
import { ReactComponent as DiscoverClosedIcon } from '../assets/icons/paymentIcons/discoverClosed.svg'
import { ReactComponent as GenericCardIcon } from '../assets/icons/paymentIcons/genericCard.svg'
import { ReactComponent as TenderSelectCardIcon } from '../assets/icons/paymentIcons/tender-select-card-icon.svg'
import { ReactComponent as MasterCardIcon } from '../assets/icons/paymentIcons/mastercard.svg'
import { ReactComponent as MasterCardIcon2 } from '../assets/icons/paymentIcons/mastercardLg.svg'
import { ReactComponent as MasterCardClosedIcon } from '../assets/icons/paymentIcons/mastercardClosed.svg'
import { ReactComponent as MobileWalletIcon } from '../assets/icons/paymentIcons/mobileWallet.svg'
import { ReactComponent as MobileWalletClosedIcon } from '../assets/icons/paymentIcons/mobileWalletClosed.svg'
import { ReactComponent as NoCardIcon } from '../assets/icons/paymentIcons/noCard.svg'
import { ReactComponent as VisaIcon } from '../assets/icons/paymentIcons/visa.svg'
import { ReactComponent as VisaIcon2 } from '../assets/icons/paymentIcons/visaLg.svg' 
import { ReactComponent as VisaClosedIcon } from '../assets/icons/paymentIcons/visaClosed.svg'
import { ReactComponent as SplitTender } from '../assets/icons/paymentIcons/splitTender.svg'
import { ReactComponent as SplitPay } from '../assets/icons/paymentIcons/splitPayIcon.svg'
import { ReactComponent as Ticket } from '../assets/icons/paymentIcons/ticketIcon.svg'
import { ReactComponent as SplitTenderClosed } from '../assets/icons/paymentIcons/splitTenderClosed.svg'
import { ReactComponent as UnknownCard } from '../assets/icons/paymentIcons/unknownCard.svg'
import { ReactComponent as UnknownCardClosed } from '../assets/icons/paymentIcons/unknownCardClosed.svg'
import { ReactComponent as NotValidCardClosed } from '../assets/icons/PaymentNotValid.svg'
import { ReactComponent as CustomTender } from '../assets/icons/paymentIcons/customTenderIcon.svg'
import { ReactComponent as GiftCard } from '../assets/icons/paymentIcons/gift-card.svg'
import { PAYMENT_TYPES } from './paymentTypes'

// TODO (JEFF) we can probably consolidate the use cases between
// quickServicePaymentIcons and tenderSelectionPaymentIcons
// both of these should not need to be maintained. 
export const quickServicePaymentIcons = {
    'amex': <AmexIcon2 />,
    'american': <AmexIcon2 />,
    'cash': <CashIcon2 />,
    'disc': <DiscoverIcon2 />,
    'discovery': <DiscoverIcon2 />,
    'mc': <MasterCardIcon2 />,
    'mast': <MasterCardIcon2 />,
    'mastercard': <MasterCardIcon2 />,
    'vs': <VisaIcon2 />,
    'visa': <VisaIcon2 />,
    'custom': <CustomTender />,
    'split': <SplitPay />,
    'app': <AppIcon />,
    'qr pay': <AppIcon />,
    'apple pay': <ApplePayIcon2 />,
    'ticket': <Ticket />,
    'gift card': <GiftCard />,
    'n/a': <NotValidCardClosed />,
    [PAYMENT_TYPES.CASH]: <CashIcon2 />,
    [PAYMENT_TYPES.CREDIT_CARD]: <GenericCardIcon />,
    [PAYMENT_TYPES.QR_PAY]: <AppIcon />,
    [PAYMENT_TYPES.TICKET]: <Ticket />,
    [PAYMENT_TYPES.GIFT_CARD]: <GiftCard />,
    [PAYMENT_TYPES.VN_API_QR_PAY]: <AppIcon />,
    [PAYMENT_TYPES.VN_API_TICKET]: <Ticket />,
    [PAYMENT_TYPES.VN_API_GIFT_CARD]: <GiftCard />,
}

export const tenderSelecctionPaymentIcons = {
  'card': <TenderSelectCardIcon />,
  'qr pay': <AppIcon />,
  'cash': <CashIcon2 />,
  'custom': <CustomTender />,
  'split': <SplitPay />,
  'app': <AppIcon />,
  'apple pay': <ApplePayIcon2 />,
  'ticket': <Ticket />,
  'gift card': <GiftCard />,
  'n/a': <NotValidCardClosed />,
}

export const paymentIcons = {
    'American Express': {
        open: <AmexIcon />,
        closed: <AmexClosedIcon />
    },
    'Apple Pay': {
        open: <ApplePayIcon />,
        closed: <ApplePayClosedIcon />
    },
    'Cash': {
        open: <CashIcon />,
        closed: <CashClosedIcon />
    },
    'Payment Pending': {
        open: <NoCardIcon />,
        closed: <NoCardIcon />
    },
    'Discover': {
        open: <DiscoverIcon />,
        closed: <DiscoverClosedIcon />
    },
    'Mastercard': {
        open: <MasterCardIcon />,
        closed: <MasterCardClosedIcon />
    },
    'Mobile Pay': {
        open: <MobileWalletIcon />,
        closed: <MobileWalletClosedIcon />
    },
    'Visa': {
        open: <VisaIcon />,
        closed: <VisaClosedIcon />
    },
    'Card Declined': {
        open: <CardDeclinedIcon />,
        closed: <CardDeclinedIcon />,
    },
    'Generic Card': {
        open: <GenericCardIcon />,
        closed: <GenericCardIcon />,
    },
    'Card Required': {
        open: <NoCardIcon />,
        closed: <NoCardIcon />,
    },
    'Split Tender': {
        open: <SplitTender />,
        closed: <SplitTenderClosed />,
    },
    'Unknown Card': {
        open: <UnknownCard />,
        closed: <UnknownCardClosed />,
    },
}

export const tenderSelectionIcons = (paymentType) => {
  const lowercasedCardType = paymentType?.toLowerCase?.() ?? 'custom'
  return tenderSelecctionPaymentIcons[lowercasedCardType] ?? <CustomTender />
}

export const resolvePaymentIcon = (cardType) => {
    const lowercasedCardType = cardType?.toLowerCase?.() ?? 'custom'
    return quickServicePaymentIcons[lowercasedCardType] ?? <CustomTender />
}
