import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import AttractLoop from './AttractLoop'
import Main from './Main'
import Signature from '../screens/Signature'
import MenuSelect from './MenuSelect'
import AppRoutes from '../AppRoutes'

export class Routes extends Component {
  _router = React.createRef()

  render() {
    const carouselImageVersion = new Date().getTime()
    return (
      <Router ref={this._router}>
        <Route component={Main} path="/kiosk/order" />
        <Route
          exact
          path="/kiosk/attract_loop"
          render={props => <AttractLoop carouselImageVersion={carouselImageVersion} />}
        />
        <Route path="/kiosk/signature" exact component={Signature} />
        <Route component={MenuSelect} path="/kiosk/menu_select" />
        <AppRoutes />
      </Router>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Routes)
