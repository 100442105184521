import { stringFloatToCents } from '../utils/formatters'

const FullModifierViewModel = (modifier) => {
  return {
    defaults: modifier?.modifierPropertiesContainer?.defaults ?? [],
    defaultPriceInCents: stringFloatToCents(modifier?.price ?? "0.00"),
    featured: modifier?.modifierPropertiesContainer?.featured ?? [],
    id: modifier?.menuItemUuid,
    items: modifier?.modifierPropertiesContainer?.items ?? [],
    lineItemUuid: modifier?.uuid,
    max: modifier?.max ?? null,
    menuItemUuid: modifier?.menuItemUuid,
    min: modifier?.min ?? null,
    modifiedLineItemUuid: modifier?.modifiedLineItemUuid,
    name: modifier?.name ?? 'Modifier',
    secondaryPriceThresholdCount: modifier?.secondaryPriceThresholdCount ?? 0,
    sortOrder: modifier?.sortOrder ?? 0,
  }
}

export default FullModifierViewModel