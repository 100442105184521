/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'

import ScanGiftCardGif from '../assets/gifs/gift-card.gif'

import GratuityHeader from '../components/GratuityHeader'
import SegmentedControlV2 from '../components/segmentedControl/SegmentedControl'
import ConnectingIndicator from '../components/ConnectingIndicator'
import useCreateOrderInProgress from '../hooks/useCreateOrderInProgress'
import useGetGiftCardBalance from '../hooks/useGetGiftCardBalance'
import { getOrder, getOrderInProgress } from '../selectors/order'
import { centsToDollars } from '../utils/formatters'
import PeripheralBridge from '../utils/peripheralBridge'
import { KEY_CODES } from '../kiosk/utils/constants'
import { addPaymentToOrderInProgress, clearOrderInProgress, closeNoOpTender, setOrderInProgress } from '../actions/order'
import styles from './ScanScreen.module.scss'
import { makeSegmentChoices, onMountScanScreen, registerScanListeners, toastError } from '../utils/scanScreen'
import VNConcessionsNavBar from '../components/VNConcessionsNavBar'
import { handleFullPpiFlow } from '../VNPPI/Utils'
import { VNPPI_TENDERS } from '../VNPPI/Enums'
import { MobileScanner } from '../VNMobileScanner/components/MobileScanner'
import { CameraAlt } from '@material-ui/icons'
import { getVNScannerInitialized, getVNScannerResults } from '../VNMobileScanner/Selectors'
import ConfirmModalV2 from '../components/ConfirmModalV2'
import { getDeviceMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import { sendCFDScreenNav } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../VNCustomerFacingDisplay/Reducer'
import { VNInjectablePpi } from '../VNPPI/containers/VNInjectablePpi'
import { codeAlreadyUsed } from '../VNUtil/VNUtils'
import { isTabbed } from '../utils/orderStates'
import { ToastManager } from '../utils/toastManager'

const segmentChoices = makeSegmentChoices('Gift Card')

const GiftCard = ({
  className,
  onGoBack,
  needCreateOrder,
  order,
  addPaymentToOrderInProgress,
  balanceDueInCents,
  placeFreeOrder,
  push,
  tabOrderInState,
}) => {
  const classNames = cn(styles.scanScreen, className)
  const isMobile = window.matchMedia('(max-width: 700px)')?.matches
  const [selectedSubmissionType, setSelectedSubmissionType] = useState(isMobile ? 'entry' : 'scan')
  const [isProcessing, setIsProcessing] = useState(false)
  const [giftCardCode, setGiftCardCode] = useState('')
  const [createOrder, orderCreated] = useCreateOrderInProgress()
  const [getGiftCardBalance, { giftCardBalance, dataSucceeded, dataFailed, cardNotRecognized }] = useGetGiftCardBalance()
  const [keyboardIsActive, setKeyboardIsActive] = useState(false)
  const [scanError, setScanError] = useState(false)
  const [mobileScannerEnabled, setMobileScannerEnabled] = useState(true)
  const [scannerLive, setScannerLive] = useState(false)
  const hasNoBalance = +giftCardBalance === 0
  const isMobileScannerInitialized = useSelector(state => getVNScannerInitialized(state))
  const mobileScanResults = useSelector(state => getVNScannerResults(state))
  const cfdMode = useSelector(state => getDeviceMode(state))

  const GiftCardRef = useRef()

  const ppiInjectableRef = useRef()


  /**
   * This should be only called once, calling it more than one time causes lag.
   * Android has fixed this in APK 2.16.0 but legacy versions still have this issue so be careful.
  */
  const stopScanner = () => {
    const bridgeInstance = PeripheralBridge.getBridge()

    if (!bridgeInstance) return
    if (!scannerLive) return

    setScannerLive(false)
    bridgeInstance.call('stopScanner')
  }

  const closeKeyBoard = () => {
    if (keyboardIsActive) {
      GiftCardRef?.current?.blur()
    }
  }

  const handleFocusChange = () => {
    setTimeout(() => {
      setKeyboardIsActive(document.activeElement === GiftCardRef?.current)
    }, 150)
  }

  useEffect(() => {

    if (needCreateOrder) {
      createOrder()
    }

    return onMountScanScreen(handleFocusChange)
  }, [])

  useEffect(() => {
    if (cfdMode === MODES.POS) {
      sendCFDScreenNav(CFD_SCREEN.GIFT_CARD_PAYMENT)
    }
  }, [])

  useEffect(() => {
    if (dataFailed) {
      setScanError(true)
      setIsProcessing(false)
      if (cardNotRecognized) {
        toastError('Card not recognized. Please try again.', 'sm', isMobile)
      } else {
        toastError('Error requesting card balance. Please try again.', 'md', isMobile)
      }
    }
  }, [dataFailed])

  useEffect(() => {
    if (orderCreated && order?.amountInCents === 0) {
      placeFreeOrder()
    }
  }, [orderCreated])

  useEffect(() => {
    if (dataSucceeded) {
      handleSuccess()
    }
  }, [dataSucceeded])

  const onScanSuccess = (scannedCode) => {

    setGiftCardCode(scannedCode)

    startBalanceCheck(scannedCode)
  }

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (selectedSubmissionType === 'entry' && GiftCardRef.current) {
      GiftCardRef.current?.focus()
      if (!bridgeInstance) return

      stopScanner()
      setScannerLive(false)
    }

    if (selectedSubmissionType === 'scan' && !isEmpty(order)) {
      if (!bridgeInstance) return

      setScannerLive(true)
      registerScanListeners({ order, onSuccess: onScanSuccess, paymentType: 'Gift card', setSelectedSubmissionType, isMobile, setScanError })
    }
  }, [selectedSubmissionType, order])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    if (isProcessing || selectedSubmissionType === 'entry') {
      stopScanner()
      setScannerLive(false)
    } else if (selectedSubmissionType === 'scan') {
      setScannerLive(true)
      bridgeInstance.call('startScanner')
    }
  }, [isProcessing, selectedSubmissionType])

  useEffect(() => {
    if (mobileScanResults && mobileScanResults?.get('results')) {
      onMobileScan(mobileScanResults?.get("results"))
    }
  }, [mobileScanResults])

  const primaryText = centsToDollars(balanceDueInCents !== 0 ? balanceDueInCents : order?.amountInCents)
  const secondaryText =
    selectedSubmissionType === 'scan'
      ? 'Please scan gift card'
      : 'Please type gift card code'

  const handleSubmissionTypeChange = value => {
    if (selectedSubmissionType !== value) {
      setSelectedSubmissionType(value)
    }
  }

  const redirect = (tenderAmountInCents) => {
    if (cfdMode === MODES.POS) {
      sendCFDScreenNav(CFD_SCREEN.BALANCE_DUE, { balanceToBePaid: { amountPaid: (order?.amountInCents - tenderAmountInCents), balanceDue: tenderAmountInCents}})
    }

    push({
      pathname: '/tender-selection',
      state: {
        tenderAmountInCents,
        scanScreenType: 'gift card',
        fromSplit: true,
      }
    })
  }

  const resetTicket = () => {
    setGiftCardCode('')
    setIsProcessing(false)
  }

  /**
   *
   * @param {String} giftCardCode - The value that was entered for the gift card
   */
  const startBalanceCheck = async (giftCardCode) => {

    setIsProcessing(true)

    const ppiInjectableData = ppiInjectableRef.current.getData(VNPPI_TENDERS.GIFTCARD_TENDER)

    if (!isEmpty(ppiInjectableData.ppiTender)) {

      const externalFunctionality = {
        functions: {
          ...ppiInjectableData.functions,
          setError: setScanError,
          redirect: redirect,
          placeFreeOrder: placeFreeOrder
        },
        balanceDueInCents: ppiInjectableData.order?.balanceDueInCents,
        promotions: ppiInjectableData.promotions,
        token: giftCardCode,
        paymentType: "vnapi_giftcard",
        shortDescription: "Gift Card",
      }

      await handleFullPpiFlow(ppiInjectableData, externalFunctionality)

      resetTicket()

    } else {
      getGiftCardBalance(giftCardCode)
    }
  }

  const handleSuccess = () => {
    if (hasNoBalance) {
        setScanError(true)
        toastError('Card has no funds. Please try another card or tender.', 'md', isMobile)
        setIsProcessing(false)
        return
    }

    let tenderAmountInCents
    let redirectToSplit = false
    if (giftCardBalance >= balanceDueInCents) {
      tenderAmountInCents = balanceDueInCents
    } else {
      tenderAmountInCents = giftCardBalance
      redirectToSplit = true
    }

    addPaymentToOrderInProgress({
      tenderAmountInCents,
      paymentType: 'gift_card',
      shortDescription: 'Gift Card',
      token: giftCardCode,
    })

    ToastManager.success('Scan gift card successful')
    if (redirectToSplit) {
      redirect(balanceDueInCents - giftCardBalance)
    }
  }

  const handleKeyPress = e => {
    if (e.keyCode === KEY_CODES.ENTER || e.keyCode === '9' || e.key === 'ENTER' || e.key === 'Tab') {
      const isValid = /^[a-z|0-9\-]*?$/gi.test(giftCardCode);
      if (!isValid) {
        toastError('The code entered contains invalid characters', 'md', isMobile)
        return
      }
      if (codeAlreadyUsed(order, giftCardCode)) {
        toastError('Gift card already entered and applied to order', 'md', isMobile)
        return
      }

      closeKeyBoard()

      // Enforce a slight delay to prevent the on screen keyboard from freezing
      // due to device resources being used for network calls and order processing.
      setTimeout(() => {
        startBalanceCheck(giftCardCode)
      }, 300)
    }
  }

  const onMobileCameraClick = () => {
    setMobileScannerEnabled(true)
  }

  const onMobileScan = (scanResult) => {
    if (codeAlreadyUsed(order, scanResult)) {
      ToastManager.error('QR code already scanned and applied to order')
      return
    }

    onScanSuccess(scanResult)
  }

  const handleTicketCodeChange = e => {
    setGiftCardCode(e?.target?.value)
  }

  if (!orderCreated && needCreateOrder) {
    return <ConnectingIndicator />
  }

  const chooseTenderOptionButton =
    !isProcessing &&
    !keyboardIsActive &&
    (dataFailed || hasNoBalance || scanError) && (
      <div
        className={styles.chooseTenderOption}
        onClick={() => {
          redirect(balanceDueInCents)
        }}
      >
        Pay With Other Method
      </div>
    )

  const scanErrorModal = () => {
    if (scanError) {
      return (
        <ConfirmModalV2
          onButtonOneClick={() => {
            if (balanceDueInCents > 0) {
              redirect(balanceDueInCents)
            }
          }}
          onButtonTwoClick={() => {
            setScanError(false)
          }}
          headerText={null}
          subtext={'There was an issue when scanning this gift card. Would you like to try again?'}
          isKiosk={false}
          buttonOneText={'OTHER TENDER'}
          buttonTwoText={'TRY AGAIN'}
        />
      )
    }

    return
  }

  const displayVNInjectablePpi = () => {
    return <VNInjectablePpi ref={ppiInjectableRef} />
  }

  const display = () => {
    if (mobileScannerEnabled && isMobileScannerInitialized) {
      return (
        <div className={classNames}>
          {displayVNInjectablePpi()}
          <MobileScanner
            onKeyboardClick={() => {
              setMobileScannerEnabled(false)
              setTimeout(() => {
                GiftCardRef?.current?.focus()
              }, 150);
            }}
            goBack={() => {onGoBack(tabOrderInState, isTabbed(order))}}
            textDisplay={primaryText}
            toolTipText={isProcessing ? 'Processing...' : 'Please scan gift card'}
          />
          {scanErrorModal()}
        </div>
      )
    }

    return (
      <div className={classNames} >
        {displayVNInjectablePpi()}
        <VNConcessionsNavBar
          onClick={() => {
            closeKeyBoard()
            setTimeout(() => {
              onGoBack(tabOrderInState, isTabbed(order))
            }, 200)
          }}
          rightAccessory={
            isMobileScannerInitialized &&
            <CameraAlt onClick={onMobileCameraClick}/>
          }
          textDisplay={isMobile && primaryText}
        />
        <div className={styles.scanScreen__header}>
          <GratuityHeader
            className={styles.gratuityHeader}
            primaryText={isMobile ? '' : primaryText}
            secondaryText={secondaryText}
          />
        </div>
        {!isMobile && <div className={styles.scanScreen__submissionChoices}>
          <SegmentedControlV2
            className={styles.segmentedControl}
            items={segmentChoices}
            selectedValue={selectedSubmissionType}
            onSelect={handleSubmissionTypeChange}
          />
        </div>}
        <div className={styles.scanScreen__body}>
          <div className={styles.choicesContent}>
            <div
              className={cn(styles.option, {
                [styles.isHidden]:
                  selectedSubmissionType === 'entry' || isProcessing
              })}
            >
              <img src={ScanGiftCardGif} alt={"Scan Gift Card"} className={styles.qrImage}/>
            </div>
            <div
              className={cn(styles.option, {
                [styles.isHidden]:
                  selectedSubmissionType === 'scan' || isProcessing
              })}
            >
              <input
                className={styles.manualEntry}
                ref={GiftCardRef}
                value={giftCardCode}
                onChange={handleTicketCodeChange}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div
              className={cn(styles.processing, {
                [styles.isHidden]: !isProcessing
              })}
            >
              <div className={styles.loadingSpinner}>
                <ConnectingIndicator position={'initial'}/>
              </div>
              <span className={styles.topText}>Processing...</span>
              <span className={styles.bottomText}>please wait.</span>
            </div>
          </div>
          {chooseTenderOptionButton}
        </div>
      </div>
    )
  }

  return display()
}

const mapStateToProps = (state, props) => {
  const order = getOrderInProgress(state)
  const balanceDueInCents = order?.balanceDueInCents
  const push = props?.history?.push ?? (() => {})

  // order would only exist in order list state if being a tabbed order
  const tabOrderInState = getOrder(state, order.uuid)

  return {
    order,
    needCreateOrder: isEmpty(order),
    balanceDueInCents,
    push,
    tabOrderInState,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const goBack = props?.history?.goBack ?? (() => {})

  return {
    onGoBack: (tabOrderInState, isOrderTabbed) => {
      if (isEmpty(tabOrderInState)) {
        dispatch(clearOrderInProgress())
      } else {
        dispatch(setOrderInProgress(tabOrderInState))
      }

      if (isOrderTabbed) {
        sendCFDScreenNav(CFD_SCREEN.IDLE)
      }
      goBack()
    },
    addPaymentToOrderInProgress: payment =>
      dispatch(addPaymentToOrderInProgress(payment)),
    placeFreeOrder: (payload) => {
      dispatch(closeNoOpTender(payload))
    },
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GiftCard)
)
