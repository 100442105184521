import { connect } from 'react-redux'
import { getAutomaticallyUpdate, getUpdateAvailable } from '../selectors/appState'
import { setAutomaticUpdate } from '../actions/appState'
import { onSafIsUploading } from '../actions/peripheral'

import { getSafIsUploading } from '../selectors/peripheral'

import Navbar from '../components/Navbar'

const mapStateToProps = (state) => {
  const updateAvailable = getUpdateAvailable(state)
  const automaticallyUpdate = getAutomaticallyUpdate(state)
  const safIsUploading = getSafIsUploading(state)

  return { updateAvailable, automaticallyUpdate, safIsUploading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAutomaticUpdate: () => dispatch(setAutomaticUpdate({ automaticallyUpdate: false })),
    setSafIsUploading: (safIsUploading) => dispatch(onSafIsUploading(safIsUploading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
