import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './GradientButton.module.scss'

const GradientButton = ({ text, className, ...props }) => {
  const classNames = cn(styles.gradientButton, className)

  return <button className={classNames} {...props}>{text}</button>
}

GradientButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

export default memo(GradientButton)
