import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { last, isEmpty } from 'lodash'
import cn from 'classnames'
import SegmentedControlV2 from '../../components/segmentedControl/SegmentedControl'
import GratuityHeader from '../../components/GratuityHeader'
import NumberPadV2 from '../../components/NumberPadV2'
import SelectionGroup from './SelectionGroup'

import makeTenderSelections from './utils/makeTenderSelections'
import { getOrderInProgress } from '../../selectors/order'
import { centsToDollars } from '../../utils/formatters'
import useNumberPad from '../../hooks/useNumberPad'
import useCreateOrderInProgress from '../../hooks/useCreateOrderInProgress'
import { numberAsCurrency } from '../../utils/orderUtils'

import styles from './index.module.scss'

import { clearOrderInProgress } from '../../actions/order'
import ConnectingIndicator from '../../components/ConnectingIndicator'
import { setCurrentPaymentFlow, updateTransactionLifecycle } from '../../VNCustomerFacingDisplay/ActionCreators'
import { CFD_EVENTS, CFD_POS_PAYMENT_FLOWS } from '../../VNCustomerFacingDisplay/Enums'
import VNConcessionsNavBar from '../../components/VNConcessionsNavBar'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'
import VNBottomButton from '../../components/VNBottomButton'
import { ToastManager } from '../../utils/toastManager'

const splitTenderChoices = [
  { label: 'Evenly', value: 'evenly' },
  { label: 'Percent', value: 'percent' },
  { label: 'Custom', value: 'custom' }
]

const SplitTender = ({
  balanceDueDisplay,
  className,
  effectiveBalanceDueInCents,
  order,
  orderId,
  onConfirm,
  onGoBack,
  lastPaymentId,
  totalAmountInCents,
  totalDisplay,
}) => {
  const [createOrder] = useCreateOrderInProgress()
  const [shouldAnimatePop, setShouldAnimatePop] = useState(false)
  const [shouldAnimateSlide, setShouldAnimateSlide] = useState(false)
  const [selectedTenderType, setSelectedTenderType] = useState('evenly')
  const [selectedTenderAmount, setSelectedTenderAmount] = useState(undefined)
  const [selectionItemsEvenly, setSelectionItemsEvenly] = useState(
    makeTenderSelections('evenly', totalAmountInCents, effectiveBalanceDueInCents)
  )
  const [selectionItemsPercent, setSelectionItemsPercent] = useState(
    makeTenderSelections('percent', totalAmountInCents)
  )
  const selectionItemsCustom = []
  const customEntry = selectedTenderType === 'custom'
  const isMobile = useGetIsMobileScreen()

  const [customOutput, onCustomInput, customRawValue, customIsValid] = useNumberPad(
    0,
    centsToDollars,
    (value) => parseInt(value) <= effectiveBalanceDueInCents && parseInt(value) > 0,
    6
  )

  useEffect(() => {
    if (isEmpty(order)) {
      createOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectionItemsEvenly(
      makeTenderSelections('evenly', totalAmountInCents, effectiveBalanceDueInCents)
    )
    setSelectionItemsPercent(
      makeTenderSelections('percent', totalAmountInCents, effectiveBalanceDueInCents)
    )
  }, [totalAmountInCents, effectiveBalanceDueInCents])

  const selectionItems = {
    evenly: selectionItemsEvenly,
    percent: selectionItemsPercent,
    custom: selectionItemsCustom
  }
  const selectionGroupItems = selectionItems[selectedTenderType]

  const primaryText = customEntry ? customOutput : selectedTenderAmount?.text ?? centsToDollars(0)

  const secondaryTextComponent = (<div className={styles.priceContainer}>
    <div className={styles.costWithLabel}>
      <div className={styles.balanceDue}>Total:</div>
      <div className={styles.amount}>{totalDisplay}</div>
    </div>
    <div className={styles.costWithLabel}>
      <div className={styles.balanceDue}>Balance Due:</div>
      <div className={styles.amount}>{balanceDueDisplay}</div>
    </div>
  </div>)

  const secondaryText = isMobile
    ? secondaryTextComponent
    : `Total: ${centsToDollars(order?.amountInCents)} • Balance Due: ${balanceDueDisplay}`

  const noBalanceDue = effectiveBalanceDueInCents === 0

  const triggerPopAnimation = () => {
    setShouldAnimatePop(true)
    setTimeout(() => setShouldAnimatePop(false), 100)
  }

  const handleTenderTypeDidChange = (value) => {
    if (selectedTenderType !== value && !shouldAnimateSlide) {
      setShouldAnimateSlide(true)
    }

    setTimeout(() => {
      onCustomInput(0, true)
      setSelectedTenderType(value)
      setSelectedTenderAmount(undefined)
      setShouldAnimateSlide(false)
    }, 100)
  }

  const handleTenderAmountDidChange = (item, index) => {
    setSelectedTenderAmount({ ...item, index })

    if (selectedTenderAmount?.index !== index && !shouldAnimatePop) {
      triggerPopAnimation()
    }
  }

  const handleNewPaymentConfirmed = () => {
    if (noBalanceDue) {
    } else if (customEntry) {
      if (customIsValid) {
        onConfirm(+customRawValue, order)
      } else {
        ToastManager.error('Please enter a valid amount before proceeding')
      }
    } else if (selectedTenderAmount?.amountInCents > 0) {
      onConfirm(Math.round(selectedTenderAmount?.amountInCents), order)
    } else {
      ToastManager.error('Please enter a valid amount before proceeding')
    }
  }


  const handleOnConfirm = () => {
    handleNewPaymentConfirmed()
  }

  let confirmButtonText = 'Next'

  if (noBalanceDue) {
    confirmButtonText = 'Next'
  }

  if (isEmpty(order)) {
    return <ConnectingIndicator />
  }

  return (
    <div className={cn(styles.SplitTender, className)}>
      <VNConcessionsNavBar
          textDisplay={isMobile && primaryText}
          onClick={() => onGoBack(lastPaymentId)}
      />
      <GratuityHeader
        className={styles.gratuityHeader}
        primaryText={!isMobile && primaryText}
        secondaryText={secondaryText}
      />
      <div className={styles.SplitTender__splitChoices}>
        <SegmentedControlV2
          className={styles.segmentedControl}
          items={splitTenderChoices}
          selectedValue={selectedTenderType}
          onSelect={handleTenderTypeDidChange}
          disabled={noBalanceDue}
        />
      </div>
      <div className={styles.SplitTender__body}>
        <div className={cn(styles.splitChoicesContent, { [styles.slide]: shouldAnimateSlide })}>
          <SelectionGroup
            className={cn(styles.selectionGroup, { [styles.isHidden]: customEntry })}
            items={selectionGroupItems}
            selectedIndex={selectedTenderAmount?.index}
            onChange={handleTenderAmountDidChange}
          />
          <NumberPadV2
            className={cn(styles.numberPad, { [styles.isHidden]: !customEntry })}
            backspace
            disabled={noBalanceDue}
            onTapButton={onCustomInput}
            isCashTender={true}
          />
        </div>
        <VNBottomButton
          text={confirmButtonText}
          onClick={handleOnConfirm}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {

  let order = getOrderInProgress(state)
  const balanceDueDisplay = numberAsCurrency(order.balanceDueInCents / 100.0)
  const lastPaymentId = last(order.payments)?.paymentId

  return {
    orderId : order?.uuid,
    lastPaymentId,
    totalAmountInCents: order?.amountInCents,
    totalDisplay: centsToDollars(order?.amountInCents),
    balanceDueDisplay,
    effectiveBalanceDueInCents: order?.balanceDueInCents,
    order,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const push = props?.history?.push ?? function () {}
  const goBack = props?.history?.goBack ?? function () {}

  return {
    onConfirm: (tenderAmountInCents, order) => {
      const fromSplit = true
      push({
        pathname: '/tender-selection',
        state: { tenderAmountInCents, order, fromSplit }
      })
    },
    onGoBack: (lastPaymentId) => {

      dispatch(updateTransactionLifecycle(CFD_EVENTS.IDLE))
      dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true))

      if (lastPaymentId) {
        push(`/receipt/split-tender/${lastPaymentId}`)
      } else {
        dispatch(clearOrderInProgress())
        goBack()
      }
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SplitTender))
