import * as ActionTypes from './ActionTypes'

/**
 * set the scanner to initialized when scandit sdk has finished processing and launching the sdk
 * @param {Boolean} initialized - True - scanner is initialized and can be used, False - camera will not run so go back to vanilla flow
 */
export function setVNScannerInitialized(initialized) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNSCANNER_INITIALIZED,
      initialized: initialized
    })
  }
}

/**
 * If the scanner is being used while setup is still running, scanning will pause. 
 * This fixes that issue and lets the setup know that its already being handled
 * @param {Boolean} usingVNScanner - True - scanner is being used, False - scanner is not being used
 */
export function setUsingVNScanner(usingVNScanner) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USING_VNSCANNER,
      usingVNScanner: usingVNScanner
    })
  }
}

/**
 * @param {string} scanResults The results return back from mobile scanner
 */
export function setVNScannerResults(scanResults) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SCANNER_RESULTS,
      scanResults: scanResults
    })
  }
}