import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'

import { fetchAttendants, attemptPinLogin, clearAttemptPinLoginStatus, clearFetchAttendantsStatus, registerLogin } from '../actions/attendant'
import { cleanupState } from '../actions/shared'
import { menusRequested } from '../actions/menus'

import { getAttemptPinLoginStatus, getFetchAttendantsStatus } from '../selectors/attendant'
import { getAttendantSettingsError } from '../selectors/attendantSettings'
import { getCurrentConfiguration } from '../selectors/config'
import { getPrintingEnabled } from '../selectors/printer'

import { BRANDING_COLOR } from '../constants'

import { getAccessToken, getDeviceId } from '../utils/deviceData'
import { ToastManager } from '../utils/toastManager'

import UserLoginComponent from '../components/userLogin/Component'
import NavMenu from '../components/NavMenu'
import { getEmployeeApprovalCodes, getRolesLoadingComplete } from '../selectors/employeeRole'
import { getCurrentStand, getCurrentMenu, getTextReceiptsEnabled, getEmailReceiptsEnabled } from '../selectors/menus'

import { sendInitializeCFD } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { MODES } from '../VNMode/Reducer'
import { fetchEmployeeRoles } from '../actions/employeeRole'
import { setDeviceMode } from '../VNMode/ActionCreators'

const UserLogin = ({
  accessToken,
  approvalCodes,
  attemptPinLogin,
  deviceConfiguration,
  attemptRegisterLogin,
  configurationUuid,
  currentStand,
  currentMenu,
  deviceId,
  fetchAttendantsStatus,
  fetchRolesStatus,
  loadRevenueCenters,
  onMount,
  onSuccess,
  onUnmount,
  pinLoginStatus,
  pinLoginStatusText,
  printingEnabled,
  textReceiptsEnabled,
  emailReceiptsEnabled,
  venueName,
}) => {
  const dispatch = useDispatch()

  const [showMenu, setShowMenu] = useState(false)
  const [pin, setPin] = useState('')
  const [isMounted, setIsMounted] = useState(false)

  const onMenuToggle = () => {
    setShowMenu(!showMenu)
  }

  const handleSubmit = (pin) => {
    if (fetchAttendantsStatus === 'loading') {
      ToastManager.error('Attendants are still being fetched. Please try again.')
    } else {
      attemptPinLogin(pin)
      setPin(pin)
    }
  }

  useEffect(() => {
    onMount()
    setIsMounted(true)

    let isCFD = false

    if (deviceConfiguration?.configuration?.deviceMode) {
      dispatch(setDeviceMode(deviceConfiguration?.configuration?.deviceMode))
      isCFD = deviceConfiguration?.configuration?.deviceMode === MODES.POS
    }

    if (isCFD) {
      const initializationValues = {
        title: currentStand?.name,
        color: BRANDING_COLOR,
        deviceId: deviceId,
        appConfigurationDeviceId: configurationUuid,
        accessToken: accessToken,
        printingEnabled: printingEnabled,
        textReceiptsEnabled: textReceiptsEnabled,
        emailReceiptsEnabled: emailReceiptsEnabled,
      }

      initializationValues.menuImage = currentMenu?.images?.detail
      initializationValues.image = currentStand?.images?.detail

      sendInitializeCFD(initializationValues)
    }

    if (isEmpty(venueName)) {
      loadRevenueCenters()
    }

    return () => {
      onUnmount()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pinLoginStatus === 'succeeded') {
      onSuccess()
      attemptRegisterLogin(pin)
    }

    if (pinLoginStatus === 'failed') {
      ToastManager.error(pinLoginStatusText)
    }
  }, [pinLoginStatus])

  return (
    <>
      <UserLoginComponent
        standName={venueName}
        pinLoginStatus={pinLoginStatus}
        pinLoginStatusText={pinLoginStatusText}
        handleSubmit={handleSubmit}
        onSuccess={onSuccess}
        approvalCodes={approvalCodes}
        fetchAttendantsStatus={fetchAttendantsStatus}
        fetchRolesStatus={fetchRolesStatus}
        isMounted={isMounted}
      />
      {showMenu && <NavMenu onToggle={onMenuToggle} />}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const pinLoginStatus = getAttemptPinLoginStatus(state)
  const errorMessage = getAttendantSettingsError(state)
  const menu = getCurrentMenu(state)
  const approvalCodes = getEmployeeApprovalCodes(state)
  const fetchAttendantsStatus = getFetchAttendantsStatus(state)
  const fetchRolesStatus = getRolesLoadingComplete(state)

  const PIN_LOGIN_STATUS_TEXT_MAP = {
    loading: 'Requesting Access',
    succeeded: 'Access Granted',
    failed: errorMessage,
  }

  const pinLoginStatusText = PIN_LOGIN_STATUS_TEXT_MAP[pinLoginStatus]

  const currentStand = getCurrentStand(state)
  const deviceId = getDeviceId()
  const accessToken = getAccessToken()
  const deviceConfiguration = getCurrentConfiguration(state)
  const configurationUuid = deviceConfiguration?.uuid
  const printingEnabled = getPrintingEnabled(state)

  const textReceiptsEnabled = getTextReceiptsEnabled(state)
  const emailReceiptsEnabled = getEmailReceiptsEnabled(state)

  return {
    venueName: menu.standName,
    pinLoginStatusText,
    pinLoginStatus,
    approvalCodes,
    fetchAttendantsStatus,
    fetchRolesStatus,
    currentStand,
    currentMenu: menu,
    deviceId,
    accessToken,
    configurationUuid,
    deviceConfiguration,
    printingEnabled,
    textReceiptsEnabled: textReceiptsEnabled,
    emailReceiptsEnabled: emailReceiptsEnabled,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const replace = get(ownProps, 'history.replace', () => {})
  const attendantsAlreadyFetched = ownProps?.history?.location?.state?.attendantsAlreadyFetched ?? false

  return {
    loadRevenueCenters: () => dispatch(menusRequested({ ignoreThrottle: true })),
    onMount: () => {
      dispatch(clearAttemptPinLoginStatus())
      dispatch(cleanupState())
      if (!attendantsAlreadyFetched) {
        dispatch(fetchAttendants())
        dispatch(fetchEmployeeRoles())
      }
    },
    onUnmount: () => {
      dispatch(clearAttemptPinLoginStatus())
      dispatch(clearFetchAttendantsStatus())
    },
    attemptPinLogin: (pin) => {
      dispatch(attemptPinLogin(pin))
    },
    attemptRegisterLogin: (pin) => {
      dispatch(registerLogin(pin))
    },
    onSuccess: () => {
      replace('/concession-order?from=user-login')
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin)
