import Portico from '@ordernext/networking-stadium/portico'
import Stadium from '@ordernext/networking-stadium/stadium'
import PeripheralBridge from './peripheralBridge'
import smoothscroll from 'smoothscroll-polyfill'
import { configureNewAppVersionAvailable } from './newAppVersionAvailable'

const configurePortico = () => {
  Portico.config = {
    baseURL: process.env.REACT_APP_OIDC_ISSUER,
    headers: {
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
      Expires: 0,
    },
  }
}

const configureStadium = () => {
  Stadium.config = {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
      Expires: 0,
    },
  }
}

const configurePeripherals = () => {
  PeripheralBridge.setupWebViewJavascriptBridge(() => {})
}

const configurePolyfills = () => {
  smoothscroll.polyfill()
}

const configureServices = () => {
  const services = [
    configurePortico,
    configureStadium,
    configurePeripherals,
    configurePolyfills,
    configureNewAppVersionAvailable,
  ]

  services.forEach((service) => service())
}

export default configureServices
