import * as ActionTypes from './ActionTypes'
import { Map, List } from 'immutable'
import { CFD_POS_PAYMENT_FLOWS } from './Enums'

// ON DEPRECATION NOTICE
export const CFD_SCREEN = {
  IDLE: 'IDLE',
  CART: 'CART',
  CARD_PAYMENT: 'CARD_PAYMENT',
  RICH_CHECKOUT: 'RICH_CHECKOUT',
  RECEIPT: 'RECEIPT',
  PROCESSING: 'PROCESSING',
  CARD_DECLINED: 'CARD_DECLINED',
  RICH_CHECKOUT_QR_CODE: 'RICH_CHECKOUT_QR_CODE',
  RICH_CHECKOUT_FINISH_TRANSACTION_ON_PHONE: 'RICH_CHECKOUT_FINISH_TRANSACTION_ON_PHONE',
  RICH_CHECKOUT_SHOW_RECEIPT: 'RICH_CHECKOUT_SHOW_RECEIPT',
  SPLIT_PAY_WAITING_FOR_ATTENDANT: 'SPLIT_PAY_WAITING_FOR_ATTENDANT',
  PRESENT_QR_PAY_CODE: 'PRESENT_QR_PAY_CODE',
  BALANCE_DUE: 'BALANCE_DUE',
  TRANSACTION_SUMMARY: 'TRANSACTION_SUMMARY',
  TICKET_PAYMENT: 'TICKET_PAYMENT',
  GIFT_CARD_PAYMENT: 'GIFT_CARD_PAYMENT',
  TICKET_PAYMENT_BALANCE: 'TICKET_PAYMENT_BALANCE'
}

const getInitialState = () => {
  return Map({
    'cartItems': List([]), // a list of all the items in the cart
    'screen': CFD_SCREEN.IDLE, // which screen section are they on, handles navigation between the screens, this way we can handle animations if need be
    'executeSalesRequest': Map({}), // the details about executing the sales request on the POS from the CFD
    'transactionResult': Map({}), // The transaction result so we can display the receipt or error on the CFD
    'cartTotals': Map({}), // The totals for the current cart
    'orderDetails': Map({}),
    'paymentProgress': Map({}),
    'cardError': Map({}), // What error the CFD Card Reader had so we can display it
    'paymentData': Map({}),
    'tips': Map({}), // contains details from POS to CFD about tips being enabled and tip suggestions
    'orderUpdate': Map({}), // contains order details from CFD
    'canExecuteSalesRequest': true,
    'balanceToBePaid': Map({}),
    'currentPaymentFlow': CFD_POS_PAYMENT_FLOWS.UNSET,
    
    // temporary state
    'calculatedCurrentCartValues': {},
    'formattedCart': {},

    // NEW ARCHITECTURE
    'currentCart': Map({}),
    'currentTransactionLifecycle': "",
    'currentOrder': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNCFD_UPDATE_CART:
      return state.set('cartItems', List(action.cartItems))
    case ActionTypes.VNCFD_RICH_CHECKOUT_ORDER_DETAILS:
      return state.set('orderDetails', action.data)
    case ActionTypes.VNCFD_SET_SCREEN:
      return state.set('screen', action.screen)
    case ActionTypes.VNCFD_EXECUTE_SALES_REQUEST:
      return state.set('executeSalesRequest', Map(action.data))
    case ActionTypes.VNCFD_TRANSACTION_RESULT:
      return state.set('transactionResult', Map(action.data))
    case ActionTypes.VNCFD_SET_CART_TOTALS:
      return state.set('cartTotals', Map(action.totals))
    case ActionTypes.VNCFD_USER_PAYMENT_PROGRESS:
      return state.set('paymentProgress', action.data)
    case ActionTypes.VNCFD_CARD_ERROR:
      return state.set('cardError', Map(action.data))
    case ActionTypes.VNCFD_PAYMENT_DATA:
      return state.set('paymentData', Map(action.data))
    case ActionTypes.VNCFD_TIPS_DATA:
      return state.set('tips', Map(action.tips))
    case ActionTypes.VN_ORDER_UPDATE:
      return state.set('orderUpdate', Map(action.data))
    case ActionTypes.VN_CAN_EXECUTE_SALES_REQUEST:
      return state.set('canExecuteSalesRequest', action.data)
    case ActionTypes.VNCFD_BALANCE_TO_BE_PAID:
      return state.set('balanceToBePaid', Map(action.data))
    case ActionTypes.VNCFD_CURRENT_PAYMENT_FLOW:
      return state.set('currentPaymentFlow', action.currentFlow)
    case ActionTypes.VNCFD_CURRENT_CART:
      return state.set('currentCart', Map(action.cart))
    case ActionTypes.VNCFD_UPDATE_TRANSACTION_LIFECYCLE:
      return state.set('currentTransactionLifecycle', action.moment)
    case ActionTypes.VNCFD_SET_CURRENT_ORDER:
      return state.set('currentOrder', Map(action.order))
    case ActionTypes.VNCFD_CALCULATED_CURRENT_CART_VALUES:
      return state.set('calculatedCurrentCartValues', action.cart)
    case ActionTypes.VNCFD_UPDATE_FORMATTED_CART:
      return state.set('formattedCart', action.cartItems)
    default:
      return state
  }
}

export default reducer