import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './GratuityHeader.module.scss'

const GratuityHeader = ({ primaryText, secondaryText, extraText, className }) => {
  const classNames = cn(styles.gratuityHeader, className)

  return (
    <div className={classNames}>
      {primaryText && <span className={styles.primaryText}>{primaryText}</span>}
      {extraText && <span className={styles.extraText}>{extraText}</span>}
			<span className={styles.secondaryText}>{secondaryText}</span>
    </div>
  )
}

GratuityHeader.defaultProps = {
  primaryText: '',
	secondaryText: '',
}

GratuityHeader.propTypes = {
  primaryText: PropTypes.string,
	secondaryText: PropTypes.string,
}

export default memo(GratuityHeader)
