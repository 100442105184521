import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import Immutable from 'immutable'
import immutableTransform from 'redux-persist-transform-immutable'

import Environment from '../utils/environment'
import watchAll from '../sagas'
import initialState from './initialState'

import rootReducer from '../reducers'
import migrations from './migrations'

import { createBrowserHistory } from "history"

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  version: 5,
  storage,
  blacklist: ['config', 'revenueCenter', 'search', 'attendant', 'peripheral', 'order', 'appState', 'VNDialogs', 'VNCustomerFacingDisplay'],
  migrate: createMigrate(migrations, { debug: true })
}

let dispatchHistory = []
const addToDispatchHistory = (action) => {
  if (dispatchHistory.length >= 20) {
    dispatchHistory.pop()
  }
  dispatchHistory.unshift(action)
}

const createDispatchHistoryMiddleWare = store => next => action => {
  addToDispatchHistory(action)
  let result = next(action)
  return result
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const logger = store => next => action => {
  // console.log('--> dispatching', action.type, action)
  let result = next(action)
  // console.log('--> next state', store.getState())
  return result
}

function configureStore (initialState) {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers = composeWithDevTools(
    { 
      trace: true, 
      traceLimit: 25,
      serialize: {
        immutable: Immutable
      }
    }
  )

  let middlewares = [sagaMiddleware, createDispatchHistoryMiddleWare, thunk]

  if (!Environment.node.isTest && !Environment.app.isProduction) middlewares.push(logger)

  const middleware = composeEnhancers(
    applyMiddleware(...middlewares)
  )

  const store = createStore(
    persistedReducer,
    initialState,
    middleware,
  )

  const persistor = persistStore(store)

  sagaMiddleware.run(watchAll)

  return { store, persistor }
}

const { store, persistor } = configureStore(initialState)
const customHistory = createBrowserHistory()

if (!Environment.app.isProduction) {
  window.__VN_REDUX_STORE__ = store
}

export {
  store,
  persistor,
  customHistory,
  dispatchHistory,
}
