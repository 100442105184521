import axios from 'axios'
import { reduce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'

import Remote from '../remote'
import { centsToDollars } from '../utils/formatters'
import { getActiveAttendantId } from '../selectors/attendant'
import { useDispatch, useSelector } from 'react-redux'
import { orderReportsLastFetchedAt } from '../actions/order'

const formatTotal = (total) => {
  let totalInCents
  if (typeof total === 'string') {
    const result = parseFloat(total).toFixed(2) // convert to number and round to 2nd decimal
    totalInCents = result * 100
  } else {
    totalInCents = total * 100
  }
  return centsToDollars(totalInCents)
}


const formatTotals = (totals) => reduce(totals, (formattedTotals, total, key) => {
    return {
      ...formattedTotals,
      [key]: formatTotal(total)
    }
  }, {})


// Since tax is currently not sent from the platform, we can calulate the tax by the algorithm below
// gross - (net + refunds + tips + discounts)
// If ever a platform change to the order reports endpoint to start sending the tax amount,
// we should then remove the below implementation.
const addTaxToTotals = (totals, formatTotals) => {
  return {
    ...formatTotals,
    tax: `$${(parseFloat(totals?.gross) -
      (
        parseFloat(totals?.net) +
        parseFloat(totals?.refunds) +
        parseFloat(totals?.tips) +
        parseFloat(totals?.discounts)
      )).toFixed(2)}`
  }
}

const useGetSummaryReport = () => {
  const [dataLoading, setLoading] = useState(false)
  const [dataSucceeded, setSuccess] = useState(false)
  const [dataFailed, setFailed] = useState(false)
  const [data, setData] = useState()
  const deviceUuid = localStorage.getItem('device_id')
  const employeeUuid = useSelector(getActiveAttendantId)
  const cancelTokenRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    cancelTokenRef.current = axios.CancelToken.source()

    return () => {
      cancelTokenRef.current.cancel()
    }
  }, [])

  const executeRequest = () => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)

    Remote.getSummaryReport(deviceUuid, employeeUuid).then((result) => {
      const data = result?.data?.reports?.[0]
      const totals = addTaxToTotals(data.totals, formatTotals(data.totals ?? {}))
      setData({ ...totals, pendingRefundCount: data?.unappliedRefundsCount ?? 0, tabsCount: data?.tabsCount ?? 0 })
      setLoading(false)
      setSuccess(true)
      dispatch(orderReportsLastFetchedAt(moment().format('MM/DD/YY h:mm A')))
    }).catch((err) => {
      if (axios.isCancel(err)) return

      setLoading(false)
      setFailed(true)
    })
  }

  return [executeRequest, { data, dataLoading, dataSucceeded, dataFailed }]
}

export default useGetSummaryReport
