import { connect } from 'react-redux'
import { clearOrderSearch, searchOrders } from '../../actions/order'
import { get } from 'lodash'
import OrdersHeader from '../../components/orders/Header'

const mapStateToProps = (state) => {
  const currentSearch = get(state, 'order.ordersSearch')

  return {
    currentSearch,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSearch: () => dispatch(clearOrderSearch()),
    onSearch: (search) => dispatch(searchOrders(search)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHeader)
