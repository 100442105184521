import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, map, head, find, each, isEqual, keys } from 'lodash'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import cn from 'classnames'

import Item from './Item'

import styles from './Items.module.scss'

const MIN_ITEM_COUNT_TABLET_THRESHOLD = 14
const MIN_ITEM_COUNT_MOBILE_THRESHOLD = 7

const Items = ({ items, onTapItem, className, searchQuery, highlightedItemsById, focusedItemId, hasQuickSearchResults, selectedCategory, itemCategories, setSelectedCategory }) => {
  const classNames = cn(styles.items, className)
  const itemContainerRef = useRef()
  const [scrollPositions, setScrollPositions] = useState({})
  const isMobile = useGetIsMobileScreen()
  const itemCount = isMobile ? MIN_ITEM_COUNT_MOBILE_THRESHOLD : MIN_ITEM_COUNT_TABLET_THRESHOLD

  if (!focusedItemId && selectedCategory.search){
    focusedItemId = get(find(items, (item) => item.categoryId === selectedCategory.id), 'id', undefined)
  }

  //this is a temporary fix to an issue where this component is first mounted with a previous menu's items at first
  //which will affect the scrollPositions collected from categories if both menu's shared a category.
  useEffect(() => {
    setSelectedCategory({ id: head(keys(itemCategories)) || '', search: false, highlight: true })
    setScrollPositions({})
    
    return () => {
      setSelectedCategory({ id: '', search: false, highlight: false }) // removing highlighted category on unMount to avoid having a shared category highlighted in a different menu
    }
    
  }, [])

  const categoryBreakPoints = {}
  const isCategoryBreakPoint = (item, itemCategories) => {
    if (itemCategories[item.categoryId] && !categoryBreakPoints[item.categoryId]) {
      categoryBreakPoints[item.categoryId] = item.id
    }
  }

  const isHighlighted = (searchQuery, hasQuickSearchResults, highlightedItemsById, item) => {
    let result
    if (!searchQuery) {
      result = true
    } else if (!hasQuickSearchResults) {
      result = false
    } else {
      result = !!(get(highlightedItemsById, item.id, false))
    }
    return result
  }

  const handleScroll = () => {
    const currentLocation = itemContainerRef.current.scrollTop
    let currentCategoryId = ''
    if (currentLocation === 0 ) {
      const categoryId = head(keys(scrollPositions))
      return setSelectedCategory({ id: categoryId, search: false, highlight: true })
    }
    each(scrollPositions, (scrollPosition, categoryId) => {
      if (currentLocation >= scrollPosition) {
        currentCategoryId = categoryId
      }
    })
    if (currentCategoryId !== selectedCategory.id && !selectedCategory.search) {
      setSelectedCategory({ id: currentCategoryId, search: false, highlight: true })
    }
  }

  return (
    <div className={classNames} style={items.length > itemCount ? {height: "92%"} : null} ref={itemContainerRef} onScroll={handleScroll}>
      {map(items, item => {
        const highlighted = isHighlighted(
          searchQuery,
          hasQuickSearchResults,
          highlightedItemsById,
          item
        )
        const focusedItem = focusedItemId === item.id && item.categoryId === selectedCategory.id
        isCategoryBreakPoint(item, itemCategories)
 
        if(!highlighted) {
          return null
        } 
        return ( 
        <div className={styles.itemContainer} key={item.id + item.categoryId} >
          <Item
            {...item}
            key={item.id + item.categoryId}
            className={styles.item}
            onTap={() => onTapItem(item)}
            highlighted={highlighted}
            focusedItem={focusedItem}
            categoryBreakPoints={categoryBreakPoints}
            scrollPositions={scrollPositions}
            setScrollPositions={setScrollPositions}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div> 
        )
      })}
    </div>
  )
}

Items.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onTapItem: PropTypes.func,
  className: PropTypes.string,
  searchQuery: PropTypes.string,
  highlightedItemsById: PropTypes.object
}

export default Items
