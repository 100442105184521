import React from 'react'
import cn from 'classnames'

import GratuityHeader from '../components/GratuityHeader'

import styles from './PaymentCompleted.module.scss'
import checkmarkCircle from '../assets/icons/checkmark-circle.svg'

const PaymentCompleted = ({ className }) => {
  const classNames = cn(styles.paymentCompleted, className)

  return (
    <div className={classNames}>
      <div className={styles.paymentCompletedContent}>
        <GratuityHeader
          className={styles.gratuityHeader}
          primaryText="$60.00"
          secondaryText="$50.00 + $10.00 tip"
        />
        <h1 className={styles.header}>Transaction Complete</h1>
        <img className={styles.checkmark} src={checkmarkCircle} alt="transaction completed, thank you!" />
      </div>
    </div>
  )
}

export default PaymentCompleted
