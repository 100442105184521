import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  get,
  sumBy,
  find,
  some,
  findIndex,
  isEmpty,
  filter,
} from 'lodash'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { getCurrentMenuItemsFilteredByCategory } from '../../selectors/items'
import { getCurrentStand, getCurrentMenu } from '../../selectors/menus'
import {
  getTotal,
  getCartItems,
  alcoholItemCountSelector,
  getCartLineItems,
} from '../../selectors/cart'
import { addToCartOrIncrement, removeFromCart } from '../../actions/cart';
import { toggleAppState } from '../../actions/appState'
import Item from './Item'
// import analytics from '../utils/analytics'
import { KEY_CODES } from "../utils/constants";
import { removedFromCartMessage, addedToCartMessage, incrementedQuantityMessage } from "../utils/messages";

export class CategoryItems extends Component {
  static propTypes = {
    cartLineItems: PropTypes.array.isRequired,
    category: PropTypes.object,
    items: PropTypes.array.isRequired,
    cart: PropTypes.array.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    refProp: PropTypes.func.isRequired,
    isAgeConfirmed: PropTypes.bool.isRequired,
    isAlcoholWarningConfirmed: PropTypes.bool.isRequired,
    isSidebarOpen: PropTypes.bool.isRequired,
    toggleAppState: PropTypes.func.isRequired,
    alcoholItemCount: PropTypes.number.isRequired,
    alcoholLimit: PropTypes.number.isRequired,
    alcoholLimitMessage: PropTypes.string.isRequired,
    scrollableAncestorRef: PropTypes.object.isRequired,
    onViewportItemEnter: PropTypes.func,
    setItemsSummary: PropTypes.func,
  }

  constructor() {
    super();
    this.itemsRefs = {}
  }

  readSectionRef = React.createRef()


  handleAddToCart = item => {
    const {setItemsSummary} = this.props
    setItemsSummary([])
    const { addToCartOrIncrement, toggleSidebar, cartLineItems } = this.props
    const cartItemsMatchingItemId = filter(cartLineItems, ['itemId', item.id])
    const totalItemQuantity = sumBy(cartItemsMatchingItemId, 'quantity')
    const isMaxAmountReached = totalItemQuantity >= (get(item, 'orderMaxAmount') || Infinity)
    // analytics.addToCartEvent(item, 1)

    addToCartOrIncrement({ itemId: item.uuid })
    if (!isEmpty(item.modifierGroups) && !isMaxAmountReached) {
      toggleSidebar(true)
    }
    if (totalItemQuantity === 0) {
      this.onAddedToCart(item)
    }
    else {
      this.onIncrementedQuantity(item, get(cartItemsMatchingItemId, '[0].quantity') || 0)
    }
  }

  handleRemoveFromCart = item => {
    const { removeFromCart } = this.props
    const index = findIndex(this.props.cartLineItems, cartItem => cartItem.itemId === item.id)
    // const cartItem = cart.find(cartItem => cartItem.id === item.id)
    // analytics.removeFromCartEvent(item, cartItem.quantity)
    removeFromCart({ index })
    this.onRemovedFromCart(item)
    this.setState({focusedItem: item})
  }

  handleCategoryFocus = (e) => {
    this.setState({focusedItem: null})
    if (e.currentTarget === e.target) {
      setTimeout(() => this.handlePreviousItem(1), 500)
    }
  }

  handleKeyPress = (event, index) => {
    event.stopPropagation();
    const { items } = this.props;

    switch (event.keyCode) {
      case KEY_CODES.LEFT:
        this.handlePreviousItem(index)
        break;
      case KEY_CODES.RIGHT:
        this.handleNextItem(index);
        break;
      case KEY_CODES.ESC:
        this.props.handleBackToCategories()
        break;
      case KEY_CODES.ENTER:
        this.handleAddToCart(items[index]);
        break;
    }
  }

  handlePreviousItem = (index) => {
    const { items, setRef } = this.props;
    const focusedItem = get(this.state, 'focusedItem')

    let itemToFocus;
    let indexToFocus = index

    if(focusedItem){
      indexToFocus = items.indexOf(focusedItem)
      this.setState({focusedItem: null})
    }

    if (indexToFocus === 0) {
      itemToFocus = items[items.length -1]
    } else {
      itemToFocus = items[indexToFocus-1]
    }
    setRef(this.itemsRefs[itemToFocus.id])
    this.itemsRefs[itemToFocus.id].focus()
  }

  handleNextItem = (index) => {
    const { items, setRef } = this.props;
    const focusedItem = get(this.state, 'focusedItem')

    let itemToFocus;
    let indexToFocus = index

    if(focusedItem){
      indexToFocus = items.indexOf(focusedItem)
      this.setState({ focusedItem: null })
    }

    if (indexToFocus === items.length -1) {
      itemToFocus = items[0]
    } else {
      itemToFocus = items[indexToFocus+1]
    }

    setRef(this.itemsRefs[itemToFocus.id])
    this.itemsRefs[itemToFocus.id].focus()
  }

  onAddedToCart = (item) => {
    this.setState({focusedItem: item})
    this.props.setVoiceInstructions(addedToCartMessage(item.name))
    this.readSectionRef.current.focus()
  }

  onIncrementedQuantity = (item, quantity) => {
    this.setState({focusedItem: item})
    this.props.setVoiceInstructions(incrementedQuantityMessage(item.name, quantity))
    this.readSectionRef.current.focus()
  }

  onRemovedFromCart = (item) => {
    this.setState({focusedItem: item})
    this.props.setVoiceInstructions(removedFromCartMessage(item.name))
    this.readSectionRef.current.focus()
  }


  render() {
    const {
      items,
      cart,
      category,
      isAgeConfirmed,
      isAlcoholWarningConfirmed,
      alcoholItemCount,
      alcoholLimit,
      alcoholLimitMessage,
      toggleAppState,
      scrollableAncestorRef,
      onViewportItemEnter,
      cartLineItems,
      handleMainSectionKeyPress,
      isSidebarOpen,
      totalPrice,
      isItemWithModifierAddedToCart,
      setIsItemWithModifierAddedToCart,
      voiceInstructions,
    } = this.props

    const changeItemsNumberAriaLabel = !isSidebarOpen ? `${voiceInstructions}. Cart total is ${(totalPrice/100).toFixed(2)}` : ''

    return (
      <div className="categories__group" ref={this.props.refProp} tabIndex={-1} onFocus={this.handleCategoryFocus} aria-label={category.name}>
        <div
          className="sr-only"
          aria-live="assertive"
          ref={this.readSectionRef}
          onKeyDown={this.handleKeyPress}
          aria-label={changeItemsNumberAriaLabel}
        >
          {changeItemsNumberAriaLabel}
        </div>
        <div className="categories__name">{category.name}</div>
        <div className="categories__items">
          {items.map((item, index) => {
            const itemHasPhoto = !!item.images.detail
            const itemTileHeight = itemHasPhoto ? '18.125vh' : '13.594vh' 

           return <Waypoint
              fireOnRapidScroll={false}
              key={item.id + item.categoryId}
              onEnter={() => onViewportItemEnter(item)}
              scrollableAncestor={scrollableAncestorRef?.current}
            >
              <Item
                addedToCart={some(find(cart, cartItem => cartItem.id === item.id))}
                addToCart={this.handleAddToCart}
                alcoholItemCount={alcoholItemCount}
                alcoholLimit={alcoholLimit}
                alcoholLimitMessage={alcoholLimitMessage}
                cartLineItems={cartLineItems}
                confirmAge={() => toggleAppState({ isAgeConfirmed: true })}
                confirmAlcoholWarning={() => toggleAppState({ isAlcoholWarningConfirmed: true })}
                isAgeConfirmed={isAgeConfirmed}
                isAlcoholWarningConfirmed={isAlcoholWarningConfirmed}
                isItemWithModifierAddedToCart={isItemWithModifierAddedToCart}
                item={item}
                itemHeight={itemTileHeight}
                key={item.id + item.categoryId}
                removeFromCart={this.handleRemoveFromCart}
                refProp={node => {
                  this.itemsRefs[item.id] = node
                }}
                handlePreviousItem={() => this.handlePreviousItem(index)}
                handleMainSectionKeyPress={handleMainSectionKeyPress}
                handleNextItem={() => this.handleNextItem(index)}
                setIsItemWithModifierAddedToCart={setIsItemWithModifierAddedToCart}
                itemHasPhoto={itemHasPhoto}
              />
            </Waypoint>
            }
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  totalPrice: getTotal(state),
  items: getCurrentMenuItemsFilteredByCategory(state, get(ownProps, 'category.itemCategoryUuid', '')),
  cart: getCartItems(state),
  isAgeConfirmed: state.appState?.isAgeConfirmed,
  isAlcoholWarningConfirmed: state.appState?.isAlcoholWarningConfirmed,
  alcoholLimitMessage: getCurrentStand(state)?.alcoholLimitMessage,
  alcoholLimit: getCurrentMenu(state)?.alcoholLimit,
  alcoholItemCount: alcoholItemCountSelector(state),
  cartLineItems: getCartLineItems(state),
})

const mapDispatchToProps = {
  addToCartOrIncrement,
  removeFromCart,
  toggleAppState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryItems)
