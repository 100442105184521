import { isEmpty, values } from 'lodash'
import humps from 'humps'

import ModifierGroupsViewModel from '../viewmodels/ModifierGroupsViewModel'

import { mapOrderDetails, mapOrder } from '../models/Order'

export default class DataMapper {

  static objectsById = (objects = []) => {
    return objects.reduce((result, object) => {
      return {
        ...result,
        [object.uuid || object.id || object.itemCategoryUuid]: object
      }
    }, {})
  }

  static itemModifiersMapper = (item) => {
    if (isEmpty(item.modifierGroups)) return

    let result = ModifierGroupsViewModel(item.modifierGroups)
    return result
  }

  static ordersMapper = (orders = []) => {
    let byId = DataMapper.objectsById(orders)

    values(byId).forEach(tabOrder => {
      const order = humps.camelizeKeys(tabOrder)

      byId[tabOrder.uuid] = mapOrder(order)
    })

    return byId
  }

  static orderDetailsMapper = (orderDetail = {}, items) => {
    const order = humps.camelizeKeys(orderDetail)

    return mapOrderDetails(order, items)
  }
}