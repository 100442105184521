import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './ChangeCalculatorField.module.scss'
import { ReactComponent as ClearIcon } from '../assets/clear-icon.svg'

const ChangeCalculatorField = ({ label, text, state, canClear, onClear, className, ...props }) => {
  const classNames = cn(styles.changeCalculatorField, className)
  let clearButton = null

  if (state === 'active' && canClear) clearButton = <ClearIcon className={styles.clearButton} onClick={onClear} />

  return (
    <div {...props} className={classNames}>
      <span className={styles.label}>{label}</span>
      <div className={cn(styles.textContainer, styles[state])}>
        <span className={styles.text}>{text}</span>
        {/* {clearButton} */}
      </div>
    </div>
  )
}

ChangeCalculatorField.defaultProps = {
  label: '',
  text: '',
  state: 'default',
  canClear: false,
  onClear: () => {},
}

ChangeCalculatorField.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  state: PropTypes.oneOf(['default', 'active', 'prominent']),
  canClear: PropTypes.bool,
  onClear: PropTypes.func,
}

export default ChangeCalculatorField
