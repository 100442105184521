import ConnectingIndicator from '../components/ConnectingIndicator'

const SpinnerComponent = () => {
  return (
    <>
      <ConnectingIndicator transparent/>
    </>
  )
}

export default SpinnerComponent
