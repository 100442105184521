import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'

export const getPromotions = (state) => {
  return state?.promotion?.promotions ?? []
}

export const makeGetPromotions = () => createSelector(
  [getPromotions],
  (promotions) => promotions
)

export const getDiscountsAvailable = (state) => {
  return !isEmpty(state?.promotion?.promotions ?? [])
}

export const getSelectedPromotions = (state) => {
  return state?.promotion?.selectedPromotions ?? []
}
