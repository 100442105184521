/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import uuidv4 from 'uuid/v4'
import PeripheralBridge from '../utils/peripheralBridge'

import QRCode from './QRCode'

import styles from './QRCodeRegistration.module.scss'

const QRCodeRegistration = ({ className }) => {
  const classNames = cn(styles.qrCodeRegistration, className)
  const [uuid, setUuid] = useState('')

  useEffect(() => {
    const deviceIdExist = !!window.localStorage.getItem('device_id')

    const createLocalUuid = () => {
      const localUuid = uuidv4()

      window.localStorage.setItem('device_id', localUuid)
      setUuid(localUuid)
    }

    const getDeviceSerialNumber = () => {
      if (!window?.navigator?.userAgent?.includes('Android')) {
        createLocalUuid()
        return
      }

      const bridgeInstance = PeripheralBridge.getBridge()

      if (!bridgeInstance) {
        setTimeout(getDeviceSerialNumber, 2000)
        return
      }

      bridgeInstance.callHandler('getSerialNumber', null, (uuid) => {
        if (!uuid) {
          setTimeout(getDeviceSerialNumber, 2000)
          return
        }

        window.localStorage.setItem('device_id', uuid)
        setUuid(uuid)
      })
    }

    if (!deviceIdExist) {
      getDeviceSerialNumber()
    } else {
      setUuid(window.localStorage.device_id)
    }
  }, [])

  const issuer = process.env.REACT_APP_OIDC_ISSUER
  const orgName = process.env.REACT_APP_ORG
  
  return (
    <div className={classNames}>
      <div className={styles}>
        { uuid && <QRCode data={`${issuer}/devices/register/pos/${uuid}?organization_name=${orgName}`} color='black' /> }
      </div>
      <span className={styles.deviceId}>{window.localStorage.getItem('device_id')}</span>
    </div>
  )
}

export default QRCodeRegistration
