import { get } from 'lodash'

const add_mode_configuration = {
  2: (state) => {
    return {
      ...state,
      config: {
        ...get(state, 'config', {}),
        mode: 'concession',
      }
    }
  },
}

export default add_mode_configuration
