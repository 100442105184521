import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

// ON DEPRECATION NOTICE
const mockInitializationData = {
  base: {
    title: 'Please Configure Your POS',
    menuImage: undefined,
    image: undefined,
    color: '#000000',
    accessToken: undefined
  }
}

const getInitialState = () => {
  return Map({
    'CFDisPaired': true, // is the CFD paired with the POS or not
    'POSisPaired': true, // is the POS paired with the CFD or not
    'base': Map(mockInitializationData.base), // the base initialization data from POS
    'debugger': "", // Temporary - The last message sent to the debugger
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNCFD_CFD_SET_PAIRED:
      return state.set('CFDisPaired', action.paired)
    case ActionTypes.VNCFD_POS_SET_PAIRED:
      return state.set('POSisPaired', action.paired)
    case ActionTypes.VNCFD_SET_CFD_INITIALIZED:
      return state.set('base', Map(action.data))
    case ActionTypes.VNCFD_DEBUGGER_DATA:
      return state.setIn('debugger', action.data)
    default:
      return state
  }
}

export default reducer