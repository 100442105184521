import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'confirmPaymentDialog': false,
    'customerPaymentDialog': Map({}),
    'customerViewingReceipt': false,
    'customerPayingWithCard': Map({}),
    'transactionCompleteDialog': false,
    'transactionErrorDialog': Map({}),
    'customerEnteringTipDialog': Map({}),
    'genericDialog': '',
    'genericDialogOpen': false,
    'genericDialogData': Map({}),
    'cardAuthStepOne': Map({}),
    'cardAuthStepTwo': Map({}),
    'kitchenPrintErrorDialog': false,
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNDIALOGS_CONFIRM_PAYMENT_READY:
      return state.set('confirmPaymentDialog', action.dialog)
    case ActionTypes.VNDIALOGS_CUSTOMER_PAYMENT:
      return state.set('customerPaymentDialog', Map(action.dialog))
    case ActionTypes.VNDIALOGS_CUSTOMER_VIEWING_RECEIPT:
      return state.set('customerViewingReceipt', action.dialog)
    case ActionTypes.VNDIALOGS_CUSTOMER_PAYING_WITH_CARD:
      return state.set('customerPayingWithCard', Map(action.dialog))
    case ActionTypes.VNDIALOGS_TRANSACTION_COMPLETE:
      return state.set('transactionCompleteDialog', action.dialog)
    case ActionTypes.VNDIALOGS_TRANSACTION_ERROR:
      return state.set('transactionErrorDialog', Map(action.dialog))
    case ActionTypes.VNDIALOGS_CUSTOMER_ENTERING_TIP:
      return state.set('customerEnteringTipDialog', Map(action.dialog))
    case ActionTypes.VNDIALOGS_CARD_AUTH_STEP_ONE:
      return state.set('cardAuthStepOne', Map(action.dialog))
    case ActionTypes.VNDIALOGS_CARD_AUTH_STEP_TWO:
      return state.set('cardAuthStepTwo', Map(action.dialog))
    case ActionTypes.VNDIALOGS_KITCHEN_PRINTER_ERROR_DIALOG:
      return state.set('kitchenPrintErrorDialog', action.dialog)
    default:
      return state
  }
}

export default reducer
