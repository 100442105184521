import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { get, forEach } from 'lodash'
import cn from 'classnames'
import moment from 'moment'

import Button from '../components/Button'
import styles from './DebugInfo.module.scss'
import { Box, Typography } from '@material-ui/core'

import useManagerApproval from '../context/hooks/useManagerApproval'

import PeripheralBridge from '../utils/peripheralBridge'

import { onBuildInfoReceived } from '../actions/peripheral'
import { purgeAllOrders } from '../actions/order'

import { getErrorInfo } from '../selectors/appState'
import { getCurrentConfiguration, getDeviceWhitelist } from '../selectors/config'
import { getCurrentMenu } from '../selectors/menus'
import {
  getBuildInfo,
  getTerminalInfo,
  getPaymentType,
  PAX_PAYMENT_TYPE,
  getSafFullResponse
} from '../selectors/peripheral'
import { networkSignalSpeed } from '../utils/networkConnected'
import { ToastManager } from '../utils/toastManager'

import useSafMode from '../hooks/useSafMode'
import { bridge_resetDevice } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import ConfirmModalV2 from './ConfirmModalV2'
import VNConcessionsNavBar from './VNConcessionsNavBar'
import SegmentedControlV2 from './segmentedControl/SegmentedControl'
import VNBlueSwitch from './VNBlueSwitch'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'

const DEBUG_TABS = {
  HARDWARE: 'hardware',
  SOFTWARE: 'software',
  NETWORK: 'network'
}

const misMatchElement = (configuredValue) => {
  return (
        <div>{`${configuredValue}`}</div>
  )
}

const DebugInfo = ({ presetToggle, whitelist, config, buildInfo, onBuildInfoReceived,
    secondaryDeviceId, className, deviceType, errorInfo, safFullResponse,
    onClickClearAllOrders }) => {
  const classNames = cn(styles.debugInfo, className)

  const [terminalId, setTerminalId] = useState('loading...')
  const [storeId, setStoreId] = useState('loading...')
  const [esKey, setESKey] = useState('loading...')
  const [serviceURL, setServiceURL] = useState('loading...')
  const [terminalIdMatches, setTerminalIdMatches] = useState(true)
  const [storeIdMatches, setStoreIdMatches] = useState(true)
  const [esKeyMatches, setESKeyMatches] = useState(true)
  const [serviceURLMatches, setServiceURLMatches] = useState(true)
  const [configsMatch, setConfigsMatch] = useState(true)
  const { openManagerApproval, setManagerApprovalOnSuccess, setManagerApprovalButtonText } = useManagerApproval()
  const isMobileViewPort = useGetIsMobileScreen()
  const menu = useSelector(getCurrentMenu)

  // for switch between hardware and software view
  const [toggle, setToggle] = useState(presetToggle)

  const [refreshTimestamp, setRefreshTimestamp] = useState(new Date().toString())
  const [signalSpeed, setSignalSpeed] = useState(0)
  const [signalSpeedDisplay, setSignalSpeedDisplay] = useState('Offline')
  const [initiateSafBridgeCommunication, updateSafMode, getSafModeFromBridge, { safMode }] = useSafMode()
  const [showClearOrdersModal, setShowClearOrdersModal] = useState(false)
  const [showReregisterModal, setShowReregisterModal] = useState(false)

  const token = localStorage.getItem('accessToken')
  const tokenHeader = token !== null ? JSON.parse(window.atob( token.split('.')[0] )) : undefined
  const tokenPayload = token !== null ? JSON.parse(window.atob( token.split('.')[1] )) : undefined

  // Create a mapping of whitelist urls to their status.
  const [whitelistStatuses, setWhitelistStatuses] = useState(whitelist.reduce((acc, url) => {
    acc[url] = 'UNKNOWN'
    return acc
  }, {}))

  const onClickCardReaderCondfiguration = () => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    bridgeInstance.call('navigateToSettings')
  }

  const onClickClearOrders = () => {
    setManagerApprovalOnSuccess(() => {
      onClickClearAllOrders()
      ToastManager.success('Orders cleared.')
    })
    setManagerApprovalButtonText("Clear Orders")
    openManagerApproval()
  }

  const onClickReregisterDevice = () => {
    setManagerApprovalOnSuccess(() => {
      bridge_resetDevice()
      ToastManager.success('Reregistered device.')
    })
    setManagerApprovalButtonText("Reregister Device")
    openManagerApproval()
  }

  const handleOnSafToggle = () => {
    if (safMode !== 3) {
      updateSafMode(3)
    } else {
      updateSafMode(2)
    }
  }

  async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 2000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);

    return response;
  }

  useEffect(() => {
    if (toggle === DEBUG_TABS.NETWORK) {
      try {
        networkSignalSpeed(setSignalSpeed)
      } catch {
        setSignalSpeed(0)
      }

      // Loop through whitelist and check if each url is reachable within 2 seconds.
      whitelist.forEach((url) => {
        fetchWithTimeout(url, { mode: 'no-cors' })
          .then(() => {
            whitelistStatuses[url] = 'SUCCESS'
          })
          .catch(() => {
            whitelistStatuses[url] = 'FAILED'
          })
      })
    }
  }, [toggle])

  useEffect(() => {
    const matches = terminalIdMatches && storeIdMatches && esKeyMatches
    setConfigsMatch(matches)
  }, [terminalIdMatches, storeIdMatches, esKeyMatches])

  useEffect(() => {
    if (signalSpeed === 1) {
      setSignalSpeedDisplay('Very Slow')
    } else if (signalSpeed === 2) {
      setSignalSpeedDisplay('Slow')
    } else if (signalSpeed === 3) {
      setSignalSpeedDisplay('Fair')
    } else if (signalSpeed === 4) {
      setSignalSpeedDisplay('Good')
    }
  }, [signalSpeed])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    const configurationStatusResolver = (configuredValue, expectedValue, updateValidityMethod, updateValueMethod) => {
      if (configuredValue !== expectedValue) {
        updateValueMethod(misMatchElement(configuredValue))
      } else {
        updateValueMethod(expectedValue)
      }

      updateValidityMethod(configuredValue === expectedValue)
    }

    if (!bridgeInstance) {
      configurationStatusResolver('unavailable', get(config, 'terminalId'), setTerminalIdMatches, setTerminalId)
      configurationStatusResolver('unavailable', get(config, 'storeId'), setStoreIdMatches, setStoreId)
      configurationStatusResolver('unavailable', get(config, 'esKey'), setESKeyMatches, setESKey)
      configurationStatusResolver('unavailable', get(config, 'url'), setServiceURLMatches, setServiceURL)
      return
    }

    bridgeInstance?.callHandler('getBuildInfo', null, (response) => {
      onBuildInfoReceived(response)
    })
    bridgeInstance?.callHandler('executeTerminalRequest', null)

    bridgeInstance?.callHandler('getConfig', null, (response) => {
      const configuredTerminalId = get(response, 'terminalID')
      const configuredStoreId = get(response, 'storeID')
      const configuredESKey = get(response, 'esKey')
      const configuredServiceURL = get(response, 'url')
      const resolverParams = [
        [configuredTerminalId, get(config, 'terminalId'), setTerminalIdMatches, setTerminalId],
        [configuredStoreId, get(config, 'storeId'), setStoreIdMatches, setStoreId],
        [configuredESKey, get(config, 'esKey'), setESKeyMatches, setESKey],
        [configuredServiceURL, get(config, 'url'), setServiceURLMatches, setServiceURL],
      ]

      forEach(resolverParams, (params) => configurationStatusResolver(...params))

    })
  }, [config, refreshTimestamp])

  const onChange = (newValue) => {
    setToggle(newValue);
  };

  // Switch Selector
  const options = [
    {
        label: <span>Hardware</span>,
        value: DEBUG_TABS.HARDWARE,
        selectedBackgroundColor: "#2253FF",
    },
    {
        label: <span>Software</span>,
        value: DEBUG_TABS.SOFTWARE,
        selectedBackgroundColor: "#2253FF",
    },
    {
      label: <span>Network</span>,
      value: DEBUG_TABS.NETWORK,
      selectedBackgroundColor: "#2253FF",
    }
  ];

  const initialSelectedIndex = options.findIndex(({value}) => value === presetToggle);
  const screenSize = `${window.innerWidth} x ${window.innerHeight}`

  // temp
  const websocketInfo = {
    device_id: localStorage.getItem('device_id'),
    organization_name: process.env.REACT_APP_ORG,
    app_type: null,
    device_name: null,
    locked_at: null,
    presence: null,
  }

  const onClickSpeedTest = () => {
    document.getElementById("externalWebsite").src = "https://fast.com"
    document.getElementById("externalContent").style.display = "block"
  }

  const onClickExternalClose = () => {
    document.getElementById("externalContent").style.display = "none"
    document.getElementById("externalWebsite").src = ""
  }

  let clearOrdersModal

  const clearOrdersSubtext = isMobileViewPort
    ? "Enter manager PIN to clear all orders. Once cleared, the orders can not be recovered."
    : "Enter manager PIN to clear all orders.\nOnce cleared, the orders can not be\n recovered."

  if (showClearOrdersModal) {
    clearOrdersModal = <ConfirmModalV2
      onButtonOneClick={() => {
        setShowClearOrdersModal(false)
      }}
      onButtonTwoClick={() => {
        onClickClearOrders()
        setShowClearOrdersModal(false)
        }
      }
      headerText={'Clear Orders?'}
      subtext={clearOrdersSubtext}
      buttonOneText={'NO, NEVER MIND'}
      buttonTwoText={'YES, CLEAR'}
    />
  }

  let reregisterModal

  const registerDeviceSubtext = isMobileViewPort
    ? "Enter manager PIN to reset device and reregister in a new ecosystem, org or venue. Once reset, this action cannot be undone."
    : "Enter manager PIN to reset device and reregister in a new ecosystem, org or venue.\nOnce reset, this action cannot be undone."

  if (showReregisterModal) {
    reregisterModal = (
      <ConfirmModalV2
      onButtonOneClick={() => {
        setShowReregisterModal(false)
      }}
      onButtonTwoClick={() => {
        onClickReregisterDevice()
        setShowReregisterModal(false)
        }
      }
      headerText={'Reregister Device?'}
      subtext={registerDeviceSubtext}
      buttonOneText={'No, Never Mind'}
      buttonTwoText={'YES, Reregister'}
    />)
  }

  return (
    <div className={styles.debugInfoContainer}>
      <div id="externalContent" className={styles.externalModal}>
        <div className={styles.externalModalContent}>
          <div className={styles.externalHeader}>
            <span className={styles.externalClose} onClick={onClickExternalClose}>&times;</span>
          </div>
          <iframe id="externalWebsite" src="" className={styles.externalWebsite}></iframe>
        </div>
      </div>

       <VNConcessionsNavBar
          textDisplay={menu.name}
          onClick={() => {}}
          useHamburgerIcon={true}
          useSmallTitleFont={true}
      />
      {clearOrdersModal}
      {reregisterModal}

      <div className={styles.selectorContainer}>
        <SegmentedControlV2
          items={options}
          selectedValue={toggle}
          onSelect={onChange}
        />
      </div>

      {/* Hardware View */}
      {(toggle === DEBUG_TABS.HARDWARE) && (
        <div className={classNames}>
          <div className={styles.row}>
            <div className={styles.columnOne}>
                <div className={styles.categoryHeader}>Device</div>

                <div className={styles.category}>Signal Strength</div>
                <p className={styles.categoryInfo}>{signalSpeedDisplay}</p>

                <div className={styles.category}>Device ID</div>
                <p className={styles.categoryInfo}>{localStorage.getItem('device_id') || 'unknown'}</p>

                <div className={styles.category}>Secondary Device ID</div>
                <p className={styles.categoryInfo}>{secondaryDeviceId || 'unknown'}</p>

                <div className={styles.category}>Device Screen Size</div>
                <p className={styles.categoryInfo}>{screenSize}</p>
            </div>

            <div className={styles.columnTwo}>
                <div className={styles.categoryHeader}>Card Reader</div>
                {(deviceType === PAX_PAYMENT_TYPE) && (
                  <>
                    <div className={styles.category}>Payment Processing Mode</div>
                    <div className={styles.offline}>
                      <Box className={styles.safBox}>
                        <Typography className={styles.onlineFirst}>Online First</Typography>
                          <VNBlueSwitch
                            checked={safMode === 2}
                            onChange={handleOnSafToggle}
                          />
                        <Typography className={styles.offlineOnly}>Offline Only</Typography>
                      </Box>
                    </div>

                    <div>
                    <Typography className={styles.safModeInfo}>
                      {safMode === 2
                        ? 'All payments will be taken offline and processed once back online.'
                        : 'Payments will be processed online when possible.'}
                    </Typography>
                    </div>
                  </>
                )}

                <div className={styles.category}>Store ID </div>
                <p className={styles.categoryInfo}>{storeId}</p>
                <div className={styles.category}>Terminal ID</div>
                <p className={styles.categoryInfo}>{terminalId}</p>
                <div className={styles.category}>ES Key</div>
                <p className={styles.categoryInfo}>{esKey}</p>
                <div className={styles.category}>Service URL</div>
                <p className={styles.categoryInfo}>{serviceURL}</p>

                { deviceType === 'pax' &&
                  <div className={styles.category}>Full Card Reader Response</div>
                }
                { deviceType === 'pax' &&
                  <p className={styles.categoryInfo}>{JSON.stringify(safFullResponse, null, 2)}</p>
                }

              <Button
                className={styles.resetDeviceButton}
                onClick={() => { setShowReregisterModal(true) }}
              >
                Reregister Device
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Software View */}
      {toggle === DEBUG_TABS.SOFTWARE && (
        <div className={classNames}>
          <div className={styles.row}>
            <div className={styles.columnOne}>
                <div className={styles.categoryHeader}>Software</div>
                <div className={styles.category}>Organization</div>
                <p className={styles.categoryInfo}>{process.env.REACT_APP_ORG || 'unknown'}</p>

                <div className={styles.category}>App Version</div>
                <p className={styles.categoryInfo}>{process.env.REACT_APP_VERSION || 'unknown'}</p>

                <div className={styles.category}>App Build Info</div>
                <p className={styles.categoryInfo}>{buildInfo || 'unknown'}</p>

                <div className={styles.category}>Websocket Info</div>
                <p className={styles.websocketInfo}>{JSON.stringify(websocketInfo) || 'unknown'}</p>


                <div className={styles.category}>Clear Local Order Data</div>
                  <Button
                    className={styles.clearOrdersButton}
                    onClick={() => setShowClearOrdersModal(true)}
                  >
                    Clear All Orders
                  </Button>

              </div>

            <div className={styles.columnTwo}>
              <div>
                <div className={styles.categoryHeader}>Access Token</div>
                <div className={styles.category}>Subject</div>
                <p className={styles.categoryInfo}>{tokenPayload.sub}</p>

                <div className={styles.category}>Expiration Time</div>
                <p className={styles.categoryInfo}>{moment.unix(tokenPayload.exp).toString()}</p>

                <div className={styles.category}>Token ID (JTI)</div>
                <p className={styles.categoryInfo}>{tokenPayload.jti}</p>

                <div className={styles.category}>Audience</div>
                <p className={styles.categoryInfo}>{tokenHeader.aud}</p>

                <div className={styles.category}>Latest Error</div>
                <p className={styles.categoryInfo}>{JSON.stringify(errorInfo)}</p>
              </div>
            </div>
            </div>
          </div>
      )}

      {/* Network View */}
      {toggle === DEBUG_TABS.NETWORK && (
        <div className={classNames}>
          <div className={styles.row}>
            <div className={styles.columnOne}>
                <div className={styles.categoryHeader}>Performance Information</div>
                <div className={styles.category}>Signal Strength</div>
                <p className={styles.categoryInfo}>Signal strength is a measure of your connection to your network access point.</p>

                <div className={styles.categoryHeader}>Performance</div>

                <div className={styles.category}>Signal Strength</div>
                <p className={styles.categoryInfo}>{signalSpeedDisplay}</p>

                <div className={styles.category}>Internet Speed Test</div>
                <div className={styles.speedTestClickable} onClick={onClickSpeedTest}>Fast.com</div>
                <p className={styles.categoryInfo}>10 Mbps download and 5 Mbps upload recommended</p>
            </div>

            <div className={styles.columnTwo}>
                <div className={styles.categoryHeader}>Allow List Information</div>
                <div className={styles.category}>UNKNOWN</div>
                <p className={styles.categoryInfo}>Indicates waiting for a response.</p>
                <div className={styles.category}>SUCCESS</div>
                <p className={styles.categoryInfo}>Indicates the url is reachable within a reasonable time limit</p>
                <div className={styles.category}>FAILED</div>
                <p className={styles.categoryInfo}>Indicates the url is unreachable within a reasonable time limit.</p>

                <div className={styles.categoryHeader}>Allow List</div>
                {Object.keys(whitelistStatuses).map((url, index) => {
                  console.log('URL STATUS:   ', whitelistStatuses[url])
                  return (
                    <div>
                      <div className={styles.url}>{url}</div>
                      <p
                        className={
                          whitelistStatuses[url] === 'SUCCESS'
                            ? styles.urlStatusSuccess
                            : styles.urlStatusFailed
                        }
                      >
                        {whitelistStatuses[url]}
                      </p>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const presetToggle = get(ownProps, 'match.params.presetToggle', DEBUG_TABS.HARDWARE)

  const config = getCurrentConfiguration(state)?.configuration || {}
  const whitelist = getDeviceWhitelist(state)
  const buildInfo = getBuildInfo(state, { json: false })
  const secondaryDeviceId = getTerminalInfo(state).serialNumber
  const deviceType = getPaymentType(state)
  const errorInfo = getErrorInfo(state)
  const safFullResponse = getSafFullResponse(state)

  return { presetToggle, config, whitelist, buildInfo, secondaryDeviceId, deviceType,
    errorInfo, safFullResponse }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onBuildInfoReceived: (payload) => dispatch(onBuildInfoReceived(payload)),
    onClickClearAllOrders: () => dispatch(purgeAllOrders())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebugInfo)
