/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import { get, map, intersection, flatten, nth, some, isEmpty, reduce } from 'lodash'

import {
  removeFromCart,
  safelyIncrementCartItemQuantity,
  safelyDecrementCartItemQuantity,
  updateCartItemModifiers,
} from '../../actions/cart'

import { getCartLineItems } from '../../selectors/cart';
import { getCurrentCartItem } from '../../selectors/cart';
import { getItemsForCurrentMenu } from '../../selectors/items';

import quantityValidator from '../../utils/quantityValidator'
import quantityUpdater from '../../utils/quantityUpdater'
import sumUpModifiers from '../../utils/sumUpPrice'
import { formatPrice } from '../utils/formatters'

import Sidebar from '../../components/Sidebar'
import Loader from '../components/Loader'
import Button from '../../components/Button'
import Image from '../../kiosk/components/Image'
import { ReactComponent as CloseIcon } from '../../assets/icons/sidebar-modal-close.svg'
import ModifierGroup from '../../components/ModifierGroup'

import styles from '../../components/CartItemDetailsModal.module.scss'
import { KEY_CODES } from '../utils/constants'
import { modifierMenuMessage } from '../utils/messages'
import ImageVersioningContext from '../context/ImageVersioningContext';

const CartItemDetailsKiosk = ({
  item,
  dispatch,
  id,
  title,
  quantity,
  modifierGroups,
  selectedItemIds,
  onClose,
  onRemove,
  className,
  addToCart,
  subtractFromCart,
  updateModifiers,
  incrementQuantity,
  decrementQuantity,
  isSidebarOpen,
  toggleSidebar,
  isSidebarEditMode,
  resetEditedIndex,
  mergeQuantityWithIdenticalItemInCart,
  modifiers,
  backToMainSection,
  currentFocusedItemRef,
  setIsItemWithModifierAddedToCart,
  ...props
}) => {
  const [modifierGroupsSelections, setModifierGroupsSelections] = useState(selectedItemIds)
  const [isValid, setIsValid] = useState(false)
  const [buttonText, setButtonText] = useState('Add to cart')
  const [isLoading, setLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedModifierIndex, setSelectedModifierIndex] = useState(-1)
  const imageVersioningContext =useContext(ImageVersioningContext)

  const validators = map(modifierGroups, ({ min, max }) => quantityValidator(min, max))
  const imageSrc = imageVersioningContext.getUrlWithVersion(item?.images?.detail)

  const mainSectionRefs = useRef([])
  const mainSections = {'title': 0, 'modifiers': 1, 'done': 2, 'exit': 3}
  const modifiersRef = []
  const modifiersSectionsRefs = useRef([])

  const onSelect = (itemId, index) => {
    const modifierGroup = modifierGroups[index]
    const { min, max } = modifierGroup
    const selectedModifiers = selectedItemIds[index]

    const nextSelectedModifiers = [...modifierGroupsSelections]
    nextSelectedModifiers[index] = quantityUpdater(min, max, selectedModifiers, itemId)

    setModifierGroupsSelections(nextSelectedModifiers)
    updateModifiers(flatten(nextSelectedModifiers))
  }


  useEffect(() => {
    const validations = map(validators, (validator, index) => {
      const modifierGroupSelections = selectedItemIds[index]

      return validator.isValid(get(modifierGroupSelections, 'length', 0))
    })

    const isValid = !some(validations, (valid) => valid === false)
    setIsValid(isValid)

  }, [selectedItemIds])

  useEffect(() => {
    setModifierGroupsSelections(selectedItemIds)
  }, [modifierGroups])

  useEffect(() => {
    let timeout = null
    if (isSidebarEditMode) {
      setButtonText('Update')
    } else {
      const animationDuration = 1000
      timeout = setTimeout(() => setButtonText('Add to cart'), animationDuration)
    }
    return () => {
      clearTimeout(timeout)
    };
  }, [isSidebarEditMode])

  useEffect(() => {
    if (isSidebarOpen) {
      setLoading(false)
      setModifierGroupsSelections(selectedItemIds)
      modifiersSectionsRefs.current = [];
      if(mainSectionRefs.current.length > 0)
        mainSectionRefs.current[mainSections.title].focus()
    }
  }, [isSidebarOpen])

  const mainSectionKeyPress = (event) => {
    if (event.keyCode === KEY_CODES.UP) {
      handlePreviousSection(selectedIndex);
    } else if (event.keyCode === KEY_CODES.DOWN) {
      handleNextSection(selectedIndex);
    } else if (event.keyCode === KEY_CODES.ENTER) {
      if(selectedIndex === mainSections.modifiers)
      {
        modifiersRef[selectedModifierIndex][0].focus()
      }
      if(selectedIndex === mainSections.done){
        handleAdd()
      }
    }
  }

  const handlePreviousSection = (index) => {
    if(index === mainSections.modifiers || index - 1 === mainSections.modifiers) {
      if (selectedModifierIndex === -1) {
        const lastIndex = modifiersSectionsRefs.current.length - 1
        modifiersSectionsRefs.current[lastIndex].focus()
        setSelectedModifierIndex(lastIndex)
        setSelectedIndex(mainSections.modifiers)
      }
      else if (selectedModifierIndex === 0) {
        mainSectionRefs.current[mainSections.title].focus()
        setSelectedIndex(mainSections.title)
        setSelectedModifierIndex(-1)
      }
      else {
        modifiersSectionsRefs.current[selectedModifierIndex - 1].focus()
        setSelectedModifierIndex(selectedModifierIndex - 1)
      }
    }
    else{
     if(index === 0) {
        mainSectionRefs.current[mainSectionRefs.current.length -1].focus()
        setSelectedIndex(mainSectionRefs.current.length - 1)
      } else {
        mainSectionRefs.current[index - 1].focus()
        setSelectedIndex(index - 1)
      }
    }
  }

  const handleNextSection = (index) => {
    if(index === mainSections.modifiers || index + 1 === mainSections.modifiers) {
      if (selectedModifierIndex === -1) {
        modifiersSectionsRefs.current[0].focus()
        setSelectedModifierIndex(0)
        setSelectedIndex(mainSections.modifiers)
      }
      else if(selectedModifierIndex === modifiersSectionsRefs.current.length - 1){
        mainSectionRefs.current[mainSections.done].focus()
        setSelectedIndex(mainSections.done)
        setSelectedModifierIndex(-1)
      }
      else {
        modifiersSectionsRefs.current[selectedModifierIndex + 1].focus()
        setSelectedModifierIndex(selectedModifierIndex + 1)
      }
    }
    else if (index === mainSectionRefs.current.length - 1) {
      mainSectionRefs.current[0].focus()
      setSelectedIndex(0)
    } else {
      mainSectionRefs.current[index + 1].focus()
      setSelectedIndex(index + 1)
    }
  }

  const modifiersKeyPress = (event, index) => {
    if (event.keyCode === KEY_CODES.LEFT) {
      handlePreviousModifier(index);
    } else if (event.keyCode === KEY_CODES.RIGHT) {
      handleNextModifier(index);
    } else if (event.keyCode === KEY_CODES.ENTER) {
      event.stopPropagation()
      document.activeElement.click()
    }
  }

  const handleNextModifier = (index) => {
    if (index === modifiersRef[selectedModifierIndex].length - 1) {
      modifiersRef[selectedModifierIndex][0].focus()
    } else {
      modifiersRef[selectedModifierIndex][index + 1].focus()
    }
  }

  const handlePreviousModifier = (index) => {
    if(index === 0) {
      const lastModifierIndex = modifiersRef[selectedModifierIndex].length - 1
      modifiersRef[selectedModifierIndex][lastModifierIndex].focus()
    } else {
      modifiersRef[selectedModifierIndex][index - 1].focus()
    }
  }

  const hideSidebar = () => {
    toggleSidebar(false)
    resetEditedIndex()

    if(currentFocusedItemRef)
      currentFocusedItemRef.focus()
  }

  const handleClose = () => {
    setLoading(true)
    onRemove()
    hideSidebar()
  }

  const handleKeyPressCloseSidebar = e => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleClose()
    }
  }

  const handleAdd = () => {
    mergeQuantityWithIdenticalItemInCart()
    if (isValid) {
      hideSidebar()
      setIsItemWithModifierAddedToCart(true)
    }
  }

  const itemPrice = get(item, 'defaultPriceInCents')
  const price = sumUpModifiers(modifiers) + itemPrice

  return (
    <Sidebar isOpen={isSidebarOpen} onKeyDown={mainSectionKeyPress}>
      <section className={styles.cartItemHeader}>
        {!isEmpty(imageSrc) && <Image key={isSidebarOpen} url={imageSrc} className={styles.cartItemImg} />}
        <CloseIcon
          aria-label="Exit"
          onClick={handleClose}
          onKeyDown={e => handleKeyPressCloseSidebar(e)}
          className={styles.cartItemClose}
          tabindex="0"
          ref={el => mainSectionRefs.current[mainSections.exit] = el}
        >
        </CloseIcon>
      </section>
      <section className={styles.cartItemInfo}>
        <header>
          <h3 className={styles.cartItemInfoHeader}
            ref={el => mainSectionRefs.current[mainSections.title] = el}
            tabindex="0"
            aria-label={`${item.name}. ${modifierMenuMessage}`}
          >
            {item.name}
          </h3>
        </header>
        {!isEmpty(item.description) && <p className={styles.cartItemSubheader}>{item.description}</p>}
        {!isEmpty(validators) && modifierGroups.map((modifierGroup, index) => {
          return (
            <section key={index}
              ref={el => modifiersSectionsRefs.current[index] = el}
              tabindex="0"
            >
              <ModifierGroup
                theme="bright"
                className={styles.brightMode}
                title={modifierGroup.name}
                label={get(validators, `[${index}].label`)}
                items={modifierGroup.items}
                selectedItemIds={selectedItemIds[index]}
                isRequired={get(validators, `[${index}].validationType`) === quantityValidator.types.required}
                min={modifierGroup.min}
                max={modifierGroup.max}
                onSelect={(itemId) => onSelect(itemId, index)}
                uuid={modifierGroup.uuid}
                modiferRef={(el, modifierIndex) =>
                {
                  if(!modifiersRef[index])
                    modifiersRef[index] = []

                  modifiersRef[index][modifierIndex] = el
                }}
                modifiersKeyPress={modifiersKeyPress}
              />
              <div className={styles.separator}></div>
            </section>
          )
        })}
      </section>
      <footer
        className={styles.cartItemFooter}>
          <Button
            className={styles.addToCartBtn}
            disabled={!isValid}
          >
          <div
            onClick={handleAdd}
            role="button"
            aria-label={`${buttonText} ${formatPrice(price)}`}
            ref={el => mainSectionRefs.current[mainSections.done] = el}
            tabIndex="0"
          >
            <span>{buttonText}</span>
            <span className={styles.cartBtnPrice}>{formatPrice(price)}</span>
          </div>
        </Button>
      </footer>
      {isLoading && <Loader />}
    </Sidebar>
  );
}

const mapStateToProps = (state, ownProps) => {
  const cartItemIndex = get(ownProps, 'cartItemIndex', get(ownProps, 'match.params.cartItemIndex', -1))
  const cartItem = nth(getCartLineItems(state), cartItemIndex) || {}
  const item = get(getItemsForCurrentMenu(state), ['byId', get(cartItem, 'itemId', '')], {})
  const modifierGroups = get(item, 'modifierGroups', [])
  let selectedModifiers = get(cartItem, 'modifiers', [])
  const defaultModifiers = reduce(modifierGroups, (acc, modifierGroup) => {
    return [...acc, ...modifierGroup.defaults ]
  }, [])
  selectedModifiers = isEmpty(selectedModifiers) ? defaultModifiers : selectedModifiers;
 
  
  const modifierGroupsSelections = map(modifierGroups, (modifierGroup) =>
    intersection(map(get(modifierGroup, 'items'), (item) => `${item.id}|${modifierGroup.uuid}`), flatten(selectedModifiers)))

  const currentItem = getCurrentCartItem(state, item.id)

  return {
    modifiers: get(currentItem, 'modifiers'),
    item,
    modifierGroups,
    id: item.id,
    title: item.name,
    quantity: cartItem.quantity,
    selectedItemIds: modifierGroupsSelections
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const goBack = get(ownProps, 'history.goBack', () => {})
  const cartItemIndex = get(ownProps, 'cartItemIndex', get(ownProps, 'match.params.cartItemIndex', -1))

  return {
    onClose: (isValid) => {
      goBack()

      if (!isValid) {
        dispatch(removeFromCart({ index: cartItemIndex }))
      }
    },
    incrementQuantity: () => {
      dispatch(safelyIncrementCartItemQuantity({ index: cartItemIndex }))
    },
    decrementQuantity: () => {
      dispatch(safelyDecrementCartItemQuantity({ index: cartItemIndex }))
    },
    updateModifiers: (modifiers) => {
      dispatch(updateCartItemModifiers({ index: cartItemIndex, modifiers }))
    },
    onRemove: () => {
      dispatch(removeFromCart({ index: cartItemIndex }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemDetailsKiosk)
