/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { onFirmwareInfoReceived, onBuildInfoReceived, onTerminalInfoReceived } from '../actions/peripheral'
import { printerConnectionChanged, printerDiscovered } from '../actions/printer'
import { getPaymentType } from '../selectors/peripheral'

import PeripheralBridge from '../utils/peripheralBridge'
import { setWebSDKVersion } from '../VNAndroidSDK/ActionCreators'

const BridgeHandler = ({
  connected,
  firmwareInfo,
  buildInfo,
  terminalInfo,
  laneAttached,
  laneOpened,
  laneClosed,
  laneRemoved,
  handleResult,
  cancelTransaction,
  errorTransaction,
  voidTransaction,
  printerDiscovered,
  printerConnectionChanged,
  paymentType,
  webSDKVersion
}) => {
  const [intervalInstance, setIntervalInstance] = useState(undefined)

  const registerHandlers = () => {
    const bridgeInstance = PeripheralBridge.getBridge()
    bridgeInstance.callHandler('getBuildInfo', null, (response) => {
      buildInfo(response)

      // dispatch into newer AndroidSDK
      // applicationId - package name
      // paymentType - pax or fcc
      // posURL - pos url
      // versionName - the SEMVER
      webSDKVersion(response.versionName)
      
      // TODO (@westinschepper): Temp fix
      if (response.paymentType === 'pax') {
        printerDiscovered('Local Printer')
        printerConnectionChanged('connected')
      }
    })

    bridgeInstance.registerHandler('handleTerminalInfo', (data, callback) => {
      terminalInfo(data)
      var response = { 'message': 'terminal info received' }
      callback(response)
    })

    bridgeInstance.call('executeTerminalRequest', null)

    bridgeInstance.registerHandler('firmwareVersion', (data, callback) => {
      firmwareInfo(data)
      var response = { 'message': 'firmware version received' }
      callback(response)
    })

    bridgeInstance.registerHandler('laneAttached', function(data, callback) {
      toast('Lane Attached')
      laneAttached()
      var response = { 'message': 'Lane attached' }
      callback(response)
    })

    bridgeInstance.registerHandler('laneOpened', function(data, callback) {
      toast('Lane Opened')
      laneOpened()
      var response = { 'message': 'Lane opened' }
      callback(response)
    })

    bridgeInstance.registerHandler('laneClosed', function(data, callback) {
      toast('Lane Closed')
      laneClosed()
      var response = { 'message': 'Lane closed' }
      callback(response)
    })

    bridgeInstance.registerHandler('laneRemoved', function(data, callback) {
      toast('Lane Removed')
      laneRemoved()
      var response = { 'message': 'Lane closed' }
      callback(response)
    })

    bridgeInstance.registerHandler('handleResult', function(data, callback) {
      handleResult(data)
      var response = { 'message': data }
      callback(response)
    })

    bridgeInstance.registerHandler('voidTransaction', function(data, callback) {
      voidTransaction(data)
      var response = { 'message': data }
      callback(response)
    })

    bridgeInstance.registerHandler('cancelTransaction', function(data, callback) {
      cancelTransaction(data)
      var response = { 'message': 'Transaction cancelled' }
      console.error(response)
      callback(response)
    })

    bridgeInstance.registerHandler('errorTransaction', function(data, callback) {
      errorTransaction(data)
      var response = { 'message': 'Transaction error' }
      callback(response)
    })

    bridgeInstance.registerHandler('discoveredPrinters', function(data) {
      const printer = 'Local Printer'
      printerDiscovered(printer)
      console.log('%c discoveredPrinters:: message received', 'font-size: 16px; font-weight: bold;')
      console.log(data)
    })

    bridgeInstance.registerHandler('printerConnectionState', function(data) {
      printerConnectionChanged(data)
      console.log('%c printerConnectionState:: message received', 'font-size: 16px; font-weight: bold;')
      console.log(data)
    })

    bridgeInstance.call('connectDevice')
  }

  const getIsApk = () => {
    /*
      TODO(JEFF): update this function to use the below method
      when the summit apk changes are ready

      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('apk')
    */

    return window?.navigator?.userAgent?.includes('Android')
  }

  // Ensure that the bridge exists before registering general device bridge handlers
  // Check every second until the bridge exists for Android devices
  useEffect(() => {
    if (getIsApk()) {
      const intervalId = setInterval(() => {
        const bridgeInstance = PeripheralBridge.getBridge()
        if (bridgeInstance) {
          registerHandlers()
          clearInterval(intervalId)
        }
      }, 1000)
    } 
  }, [])

  useEffect(() => {
    if (!connected) {
      setIntervalInstance(setInterval(() => {
        const bridgeInstance = PeripheralBridge.getBridge()
        if (!bridgeInstance) return

        bridgeInstance.call('connectDevice')
      }, 5000))
    } else {
      clearInterval(intervalInstance)
    }
  }, [connected])

  return null
}

const mapStateToProps = (state) => {
  const paymentType = getPaymentType(state)
  return {
    connected: state.peripheral.peripheralConnected,
    paymentType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    buildInfo: (payload) => dispatch(onBuildInfoReceived(payload)),
    terminalInfo: (payload) => dispatch(onTerminalInfoReceived(payload)),
    firmwareInfo: (payload) => dispatch(onFirmwareInfoReceived(payload)),
    laneAttached: () => dispatch({ type: 'LANE_ATTACHED' }),
    laneOpened: () => dispatch({ type: 'LANE_OPENED' }),
    laneClosed: () => dispatch({ type: 'LANE_CLOSED' }),
    laneRemoved: () => dispatch({ type: 'LANE_REMOVED' }),
    handleResult: (data) => dispatch({ type: 'HANDLE_RESULT', payload: data }),
    cancelTransaction: (data) => dispatch({ type: 'CANCEL_TRANSACTION', payload: data }),
    errorTransaction: (data) => dispatch({ type: 'ERROR_TRANSACTION', payload: data }),
    voidTransaction: (data) => dispatch({ type: 'VOID_TRANSACTION', payload: data }),
    printerDiscovered: (printer) => dispatch(printerDiscovered(printer)),
    printerConnectionChanged: (printerState) => dispatch(printerConnectionChanged(printerState)),
    webSDKVersion: (payload) => dispatch(setWebSDKVersion(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BridgeHandler)
