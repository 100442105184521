/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'

import ScanQRGif from '../assets/gifs/scan-qr.gif'

import GratuityHeader from '../components/GratuityHeader'
import SegmentedControlV2 from '../components/segmentedControl/SegmentedControl'
import ConnectingIndicator from '../components/ConnectingIndicator'
import useCreateOrderInProgress from '../hooks/useCreateOrderInProgress'
import useGetQRBalance, { QR_PAY_ERROR_MESSAGE } from '../hooks/useGetQRBalance'
import { getOrder, getOrderInProgress, getQRPayAuthError } from '../selectors/order'
import { centsToDollars } from '../utils/formatters'
import PeripheralBridge from '../utils/peripheralBridge'
import { KEY_CODES } from '../kiosk/utils/constants'
import { addPaymentToOrderInProgress, clearOrderInProgress, closeNoOpTender, setOrderInProgress, setOrderInProgressWithId, removeQRPaymentandUnsyncableStatus, setQRPayAuthError, removePayment } from '../actions/order'
import styles from './ScanScreen.module.scss'
import { getDeviceMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import { sendCFDScreenNav } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../VNCustomerFacingDisplay/Reducer'
import VNConcessionsNavBar from '../components/VNConcessionsNavBar'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import { setCurrentPaymentFlow } from '../VNCustomerFacingDisplay/ActionCreators'
import { VNPPI_TENDERS } from '../VNPPI/Enums'
import { handleFullPpiFlow } from '../VNPPI/Utils'
import { MobileScanner } from '../VNMobileScanner/components/MobileScanner'
import { CameraAlt } from '@material-ui/icons'
import { CFD_POS_PAYMENT_FLOWS } from '../VNCustomerFacingDisplay/Enums'
import { getVNScannerInitialized, getVNScannerResults } from '../VNMobileScanner/Selectors'
import ConfirmModalV2 from '../components/ConfirmModalV2'
import { VNInjectablePpi } from '../VNPPI/containers/VNInjectablePpi'
import { codeAlreadyUsed } from '../VNUtil/VNUtils'
import { isTabbed } from '../utils/orderStates'
import { ToastManager } from '../utils/toastManager'

const QRChoices = [
  { label: 'Scan QR Code', value: 'scan' },
  { label: 'Type QR Code', value: 'entry' },
]

const toastError = (message, classSize, isMobile) => {
  const options = {
    autoClose: true,
  }
  if (!isMobile) {
    options.position = 'top-left'
    options.className = `toast-scan-screen-${classSize}`
  }
  ToastManager.error(message, options)
}

const QRPay = ({
  className,
  onGoBack,
  needCreateOrder,
  order,
  addPaymentToOrderInProgress,
  removePaymentFromTab,
  balanceDueInCents,
  placeFreeOrder,
  push,
  tabOrderInState,
}) => {
  const classNames = cn(styles.scanScreen, className)
  const isMobile = useGetIsMobileScreen()
  const [selectedSubmissionType, setSelectedSubmissionType] = useState(isMobile ? 'entry' : 'scan')
  const [isProcessing, setIsProcessing] = useState(false)
  const [qrCode, setQRCode] = useState('')
  const [createOrder, orderCreated] = useCreateOrderInProgress(isTabbed(order) && order?.uuid)
  const [getQRBalance, { qrBalance, vaultedCard, dataSucceeded, dataFailed, isExpired, affiliationUuids, userUuid }] = useGetQRBalance()
  const [keyboardIsActive, setKeyboardIsActive] = useState(false)
  const [retotalingOrder, setRetotalingOrder] = useState(false)
  const [mobileScannerEnabled, setMobileScannerEnabled] = useState(true)
  const [codeError, setCodeError] = useState(false)
  const hasNoBalance = +qrBalance === 0 && !vaultedCard
  const [scannerLive, setScannerLive] = useState(false)

  const ppiInjectableRef = useRef()

  const qrCodeRef = useRef()

  const currentOrder = useRef()

  const dispatch = useDispatch()
  const mobileScanResults = useSelector(state => getVNScannerResults(state))
  const isMobileScannerInitialized = useSelector(state => getVNScannerInitialized(state))
  const cfdMode = useSelector(state => getDeviceMode(state))
  let enterKeyPressed = false // because POS is sometimes slow, pressing enter twice will apply discounts twice and mess totaling up.

  const QRPayAuthError = useSelector(state => getQRPayAuthError(state))

  /**
   * This should be only called once, calling it more than one time causes lag.
   * Android has fixed this in APK 2.16.0 but legacy versions still have this issue so be careful.
  */
  const stopScanner = () => {
    const bridgeInstance = PeripheralBridge.getBridge()

    if (!bridgeInstance) return
    if (!scannerLive) return

    setScannerLive(false)
    bridgeInstance.call('stopScanner')
  }

  const closeKeyBoard = () => {
    if (keyboardIsActive) {
      qrCodeRef?.current?.blur()
    }
  }

  const handleFocusChange = () => {
    setTimeout(() => {
      setKeyboardIsActive(document.activeElement === qrCodeRef?.current)
    }, 150)
  }

  useEffect(() => {
    dispatch(setQRPayAuthError(false))

    if (needCreateOrder) {
      createOrder()
    }
    enterKeyPressed = false
    document.addEventListener('focusin', handleFocusChange)
    document.addEventListener('focusout', handleFocusChange)

    return () => {
      stopScanner()
      document.removeEventListener('focusin', handleFocusChange)
      document.removeEventListener('focusout', handleFocusChange)
    }
  }, [])

  useEffect(() => {
    if (!isTabbed(order)) return

    if (order.payments.length) {
      removePaymentFromTab({order})
    }
  }, [order])

  useEffect(() => {
    if (dataFailed) {
      setCodeError(true)
      setIsProcessing(false)
      if (isExpired) {
        toastError('QR code has expired. Please refresh your code and try again', 'lg', isMobile)
      } else {
        toastError(QR_PAY_ERROR_MESSAGE, 'sm', isMobile)
      }
    }
  }, [dataFailed])

  useEffect(() => {
    //In case a new order is created we need to store uuid and balanceDueInCents
    currentOrder.current = {
      uuid: order.uuid,
      balanceDueInCents: order.balanceDueInCents
    }

    if (orderCreated && order?.amountInCents === 0 && !retotalingOrder) {
      placeFreeOrder()
      return
    }
    if (retotalingOrder && orderCreated) {
      setRetotalingOrder(false)
      handleSuccess()
    }
  }, [orderCreated])

  useEffect(() => {
    if (dataSucceeded) {
      if (!isEmpty(affiliationUuids)) {
        createOrder(true, userUuid, affiliationUuids, order?.tipAmountInCents)
        setRetotalingOrder(true)
        return
      }

      handleSuccess()
    }
  }, [dataSucceeded])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (selectedSubmissionType === 'entry' && qrCodeRef.current) {
      setScannerLive(false)
      qrCodeRef.current?.focus()
    }

    if (selectedSubmissionType === 'scan') {
      if (!bridgeInstance) return
      setScannerLive(true)
      bridgeInstance.registerHandler('scanPayload', function (data, callback) {
        const scannedCode = data?.valueOf()
        if (codeAlreadyUsed(order, scannedCode)) {
          toastError('QR code already scanned and applied to order', 'md', isMobile)
          return
        }

        setQRCode(scannedCode)
        startBalanceCheck(scannedCode)
      })

      bridgeInstance.registerHandler('scanError', function (err, callback) {
        if (err?.toLowerCase()?.includes('no scanner detected')) {
          setSelectedSubmissionType('entry')
        }
        setCodeError(true)
        toastError(`QR code scan error, ERROR: ${JSON.stringify(err)}`, 'lg', isMobile)
      })
    }
  }, [selectedSubmissionType])

  useEffect(() => {
    if (mobileScanResults && mobileScanResults?.get('results')) {
      onMobileScan(mobileScanResults?.get("results"))
    }
  }, [mobileScanResults])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    if (isProcessing || selectedSubmissionType === 'entry') {
      stopScanner()
      setScannerLive(false)
    } else if (selectedSubmissionType === 'scan') {
      bridgeInstance.call('startScanner')
      setScannerLive(true)
    }
  }, [isProcessing, selectedSubmissionType])

  //Set current order with the first valid value once order in progress has been set
  useEffect(()=> {
    if (!currentOrder?.current?.uuid) {
      currentOrder.current = {
        uuid: order.uuid,
        balanceDueInCents: order.balanceDueInCents
      }
    }
  }, [order])

  /**
   * This code will be triggered when QRPayAuthError is set to true
   * then we asume that a QRPay failed for the order being processed
   */
  useEffect(() => {
    //if QRPayAuthError is false then return, this will prevent undesired re renders
    if (!QRPayAuthError) {
      return
    }

    setCodeError(true)
    resetTicket()
    toastError(QR_PAY_ERROR_MESSAGE, 'sm', isMobile)

    const orderId = currentOrder.current.uuid

    //dispatch action to set the order into syncable status
    dispatch(removeQRPaymentandUnsyncableStatus({orderId, balanceDueInCents: currentOrder.current.balanceDueInCents}))
    dispatch(setOrderInProgressWithId(orderId))
  }, [QRPayAuthError])

  const resetTicket = () => {
    setQRCode('')
    setIsProcessing(false)
  }

  const primaryText = centsToDollars(balanceDueInCents !== 0 ? balanceDueInCents : order?.amountInCents)
  const secondaryText =
    selectedSubmissionType === 'scan'
      ? 'Please scan QR code'
      : 'Please type QR Pay code'

  const handleSubmissionTypeChange = value => {
    if (selectedSubmissionType !== value) {
      setSelectedSubmissionType(value)
    }
  }

  const redirect = (tenderAmountInCents) => {

    if (cfdMode === MODES.POS) {
      sendCFDScreenNav(CFD_SCREEN.BALANCE_DUE, { balanceToBePaid: { amountPaid: (order?.amountInCents - tenderAmountInCents), balanceDue: tenderAmountInCents}})
    }

    push({
      pathname: '/tender-selection',
      state: {
        tenderAmountInCents,
        scanScreenType: 'qr code',
        fromSplit: true,
      }
    })
  }

  /**
   *
   * @param {*} code
   */
  const startBalanceCheck = async (code) => {

    setIsProcessing(true)

    const ppiInjectableData = ppiInjectableRef.current?.getData(VNPPI_TENDERS.QRPAY_TENDER)

    if (!isEmpty(ppiInjectableData.ppiTender)) {

      const externalFunctionality = {
        functions: {
          ...ppiInjectableData.functions,
          setError: setCodeError,
          redirect: redirect,
          placeFreeOrder: placeFreeOrder
        },
        balanceDueInCents: ppiInjectableData.order?.balanceDueInCents,
        promotions: ppiInjectableData.promotions,
        token: code,
        paymentType: "vnapi_qrpay",
        shortDescription: "QR Pay"
      }

      await handleFullPpiFlow(ppiInjectableData, externalFunctionality)

      resetTicket()

    } else {
      getQRBalance(code)
    }
  }

  const handleSuccess = () => {

    if (hasNoBalance && order?.amountInCents !== 0) {
      ToastManager.success('Scan QR code successful')
      redirect(balanceDueInCents)
      return
    }

    let tenderAmountInCents
    let redirectToSplit = false
    if (vaultedCard || qrBalance >= balanceDueInCents) {
      tenderAmountInCents = balanceDueInCents
    } else {
      tenderAmountInCents = qrBalance
      redirectToSplit = true;
    }

    addPaymentToOrderInProgress({
      tenderAmountInCents,
      paymentType: 'wallet_nonce',
      shortDescription: 'QR Pay',
      token: qrCode,
    })

    if (cfdMode === MODES.POS && !redirectToSplit) return
    if (redirectToSplit) {
      ToastManager.success('Scan QR code successful')
      redirect(balanceDueInCents - qrBalance)
    }
  }

  const handleKeyPress = e => {
    if ((e.keyCode === KEY_CODES.ENTER || e.keyCode === '9' || e.key === 'ENTER' || e.key === 'Tab') && !enterKeyPressed) {
      enterKeyPressed = true
      const isValid = /^[a-z|0-9\-]*?$/gi.test(qrCode);
      if (!isValid) {
        toastError('The code entered contains invalid characters', 'md', isMobile)
        return
      }
      if (codeAlreadyUsed(order, qrCode)) {
        toastError('QR Code already entered and applied to order', 'md', isMobile)
        return
      }

      closeKeyBoard()

      // Enforce a slight delay to prevent the on screen keyboard from freezing
      // due to device resources being used for network calls and order processing.
      setTimeout(() => {
        startBalanceCheck(qrCode)
      }, 300)
    }
  }

  const onMobileCameraClick = () => {
    setMobileScannerEnabled(true)
  }

  const onMobileScan = (scanResult) => {
    if (codeAlreadyUsed(order, scanResult)) {
      toastError('QR code already scanned and applied to order', 'md', isMobile)
      return
    }

    setQRCode(scanResult)
    startBalanceCheck(scanResult)
  }

  const handleTicketCodeChange = e => {
    setQRCode(e?.target?.value)
  }

  if (!orderCreated && needCreateOrder && isProcessing) {
    return <ConnectingIndicator />
  }

  const chooseTenderOptionButton = codeError &&
    !isProcessing &&
    !keyboardIsActive && (
      <div
        className={styles.chooseTenderOption}
        onClick={() => {
          redirect(balanceDueInCents)
        }
        }
      >
        Pay With Other Method
      </div>
    )

  const scanError = () => {
    if (codeError) {
      return (
        <ConfirmModalV2
          onButtonOneClick={() => {
            if (balanceDueInCents > 0) {
              redirect(balanceDueInCents)
            }
          }}
          onButtonTwoClick={() => {
            setCodeError(false)
          }}
          headerText={null}
          subtext={'There was an issue when scanning this QR code. Would you like to try again?'}
          isKiosk={false}
          buttonOneText={'OTHER TENDER'}
          buttonTwoText={'TRY AGAIN'}
        />
      )
    }

    return
  }

  const displayVNInjectablePpi = () => {
    return <VNInjectablePpi ref={ppiInjectableRef} />
  }

  const display = () => {
    if (mobileScannerEnabled && isMobileScannerInitialized) {
      return (
        <div className={classNames}>
          {displayVNInjectablePpi()}
          <MobileScanner
            onKeyboardClick={() => {
              setMobileScannerEnabled(false)
              // have to use a timeout or else the keyboard will not show up
              setTimeout(() => {
                qrCodeRef?.current?.focus()
              }, 150);
            }}
            goBack={() => {
              onGoBack(tabOrderInState, isTabbed(order))
            }}
            textDisplay={primaryText}
            toolTipText={isProcessing ? 'Processing...' : 'Please scan QR Code'}
          />
          {scanError()}
        </div>
      )
    }

    return (
      <div className={classNames} >
        {displayVNInjectablePpi()}
        <VNConcessionsNavBar
          onClick={() => {
            closeKeyBoard()
            setTimeout(() => {
              onGoBack(tabOrderInState, isTabbed(order))
            }, 200)
          }}
          rightAccessory={
            isMobileScannerInitialized &&
            <CameraAlt onClick={onMobileCameraClick}/>
          }
          textDisplay={isMobile && primaryText}
        />
        <GratuityHeader
          className={styles.gratuityHeader}
          primaryText={isMobile ? '' : primaryText}
          secondaryText={secondaryText}
        />
        {!isMobile && <div className={styles.scanScreen__submissionChoices}>
          <SegmentedControlV2
            className={styles.segmentedControl}
            items={QRChoices}
            selectedValue={selectedSubmissionType}
            onSelect={handleSubmissionTypeChange}
          />
        </div>}
        <div className={styles.scanScreen__body}>
          <div className={styles.choicesContent}>
            <div
              className={cn(styles.option, {
                [styles.isHidden]:
                  selectedSubmissionType === 'entry' || isProcessing
              })}
            >
              <img src={ScanQRGif} alt={"Scan QR"} className={styles.qrImage}/>
            </div>
            <div
              className={cn(styles.option, {
                [styles.isHidden]:
                  selectedSubmissionType === 'scan' || isProcessing
              })}
            >
              <input
                className={styles.manualEntry}
                ref={qrCodeRef}
                value={qrCode}
                onChange={handleTicketCodeChange}
                onKeyDown={handleKeyPress}
                autoFocus
              />
            </div>
            <div
              className={cn(styles.processing, {
                [styles.isHidden]: !isProcessing
              })}
            >
              <div className={styles.loadingSpinner}>
                <ConnectingIndicator position={'initial'}/>
              </div>
              <span className={styles.topText}>Processing...</span>
              <span className={styles.bottomText}>please wait.</span>
            </div>
          </div>
          {chooseTenderOptionButton}
        </div>
      </div>
    )
  }

  return display()

}

const mapStateToProps = (state, props) => {
  const order = getOrderInProgress(state)
  const balanceDueInCents = order?.balanceDueInCents
  const push = props?.history?.push ?? (() => {})

  // order would only exist in order list state if being a tabbed order
  const tabOrderInState = getOrder(state, order.uuid)

  return {
    order,
    needCreateOrder: isEmpty(order),
    balanceDueInCents,
    push,
    tabOrderInState,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const goBack = props?.history?.goBack ?? (() => {})

  return {
    onGoBack: (tabOrderInState, isOrderTabbed) => {
      if (isEmpty(tabOrderInState)) {
        dispatch(clearOrderInProgress())
      } else {
        dispatch(setOrderInProgress(tabOrderInState))
      }

      if (isOrderTabbed) {
        sendCFDScreenNav(CFD_SCREEN.IDLE)
      }
      dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true))
      goBack()
    },
    addPaymentToOrderInProgress: payment =>
      dispatch(addPaymentToOrderInProgress(payment)),
    removePaymentFromTab: order => dispatch(removePayment(order, 0, true)),
    placeFreeOrder: (payload) => {
      dispatch(closeNoOpTender(payload))
    },
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QRPay)
)

