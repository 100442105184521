import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { get, isEmpty, remove, cloneDeep, isEqual, reduce, last, sumBy, map } from 'lodash'
import moment from 'moment'

import { formatCartItemModifiers, centsToDollars } from '../../utils/formatters'

import styles from './ReceiptDetails.module.scss'

import { createOrderParams } from '../../utils/orderUtils'
import { resolvePaymentIcon } from '../../utils/icons'
import { getTenderInfo } from '../../utils/orderDisplay'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'

const getGroupedItems = (order) => {
  const itemModels = get(cloneDeep(order), 'itemModels', [])
  const result = reduce(itemModels, (accumulator, item) => {
    const idsMatch = isEqual((last(accumulator) || {}).id, item.id)
    const modifiersMatch = isEqual((last(accumulator) || {}).modifiers, item.modifiers)

    if (idsMatch && modifiersMatch) {
      last(accumulator).quantity += 1
    } else {
      accumulator.push(item)
    }

    return accumulator
  }, [])

  return result
}

const getTotalInCents = (order) => {
  const itemModels = get(cloneDeep(order), 'itemModels', [])

  const total = reduce(itemModels, (accumulator, item) => {
    const quantity = get(item, 'quantity', 0)
    const itemModifiers = get(item, 'modifiers', [])
    const itemSubtotal = get(item, 'priceInCents', 0) * quantity

    const modifiersSubtotal = reduce(itemModifiers, (acc, modifierItem) => {
      const priceInCents = get(modifierItem, 'defaultPriceInCents', 0)
      return acc + priceInCents * quantity
    }, 0)

    const lineItemTotal = itemSubtotal + modifiersSubtotal

    return accumulator + lineItemTotal
  }, 0)

  return total
}


const ReceiptDetails = ({ className, order, taxRate, serviceCharge, isKiosk, revenueCenter, payments, isMultiPayment }) => {
  const classNames = cn((isKiosk ? styles.receiptDetailsKiosk : styles.receiptDetailsConcessions), className)
  const itemModels = getGroupedItems(order)
  const totalInCents = order?.amountInCents
  const discountInCents = order?.discountAmountInCents
  const subtotalInCents = order?.subtotalAmountInCents
  const orderNumber = order?.orderNumber;
  const revenueCenterName = revenueCenter?.name
  const taxInCents = order?.taxAmountInCents ?? taxRate
  const serviceFee = order?.serviceFee ?? serviceCharge
  const tip = get(order, 'tipAmountInCents', 0)
  const isRichCheckoutOrder = () => get(order, 'isRichCheckout', false)
  const mediaQuery = window.matchMedia("(min-width: 400px)")
  const isMobileViewPort = useGetIsMobileScreen()
  const totalsMobileRowStyle = mediaQuery?.matches ? styles.totalRow : styles.mobileTotalsRow;
  const paymentMobileRowStyle = mediaQuery?.matches ? styles.totalRow : styles.paymentsRow;

  const formattedCardData = () => {
    const receiptText = get(order, 'cardReaderData.receipt')
    if (isEmpty(receiptText)) return []

    let byLine = receiptText.split(/\r?\n/)
    remove(byLine, (line) => isEmpty(line))

    // non-emv (e.g., debit) only has saleInfo
    const cardInfoIndex = byLine.findIndex((line) => line.includes("****") && line.includes("EMV SALE"))
    const saleInfo = byLine.slice(0, cardInfoIndex - 1)
    const cardInfo = byLine.slice(cardInfoIndex, byLine.length - 1)

    return [saleInfo, cardInfo]
  }

  let hasBalanceDue = order.balanceDueInCents && order.balanceDueInCents !== 0
  const tenderResponse = getTenderInfo(order)
  const iconType = tenderResponse?.cardIssuer ?? tenderResponse.displayText
  const icon = React.cloneElement(resolvePaymentIcon(iconType), { className: styles.icon })
  let date = moment(order?.submittedAt).format("MMM D, YYYY h:mm a")

  let paymentHeaderDisplayText = isKiosk ? (
    <>{iconType}</>
  ) : (
    <>
      {icon}
      <div className={styles.lastFour}>{tenderResponse.displayText}</div>
    </>
  )

  if (isMultiPayment) {
    paymentHeaderDisplayText = isKiosk ? (
      <div className={styles.bold}>Multiple</div>
    ) : (
      <div>Multiple Tenders</div>
    )
  }

  const paymentHeader = (
    <div className={styles.paymentHeader}>
      <span className={styles.totalItem}>{paymentHeaderDisplayText}</span>
      <span className={styles.date}>{date}</span>
    </div>
  )

  const getRemainingBalanceTitle = () => {
    const PAYMENT_TYPES = {
      gift_card: 'Gift Card',
      vnapi_giftcard: 'Gift Card',
      loaded_ticket: 'Ticket',
      vnapi_ticket: 'Ticket'
    }

    const createOrderParamsorder = createOrderParams(order)

    for (let index = 0; index < createOrderParams?.payments?.length; index++) {
      if (PAYMENT_TYPES.hasOwnProperty(createOrderParams?.payments[index]?.paymentType)) {
        return PAYMENT_TYPES[createOrderParams?.payments[index]?.paymentType]
      }
    }

    return 'Ticket'
  }

  const remainingTicketBalanceRow = (isKiosk && order.remainingTicketBalance !== undefined) && (
    <div className={styles.paymentHeader}>
      <span className={styles.bold}>
        {`${getRemainingBalanceTitle()} Balance`}
      </span>
      <span className={styles.bold}>
        {centsToDollars(order.remainingTicketBalance)}
      </span>
    </div>
  )

  return (
    <div className={classNames}>
      { isKiosk && (
        <div>
          <div className={styles.revenueCenter}>{revenueCenterName}</div>
          <div className={styles.orderNumber}>{`Order #${orderNumber}`}</div>
        </div>)
      }
      <div className={styles.lineItems}>
        {itemModels.map((item, idx) => {
          const name = item.name
          const quantity = item.quantity
          const price = item.price
          const hasModifiers = !isEmpty(item.modifiers)
          return (
            <div key={idx}>
              <div className={styles.rowContainer}>
                <div className={styles.row} key={item.id + item.categoryId}>
                  <div className={styles.columnContainer}>
                    <div className={styles.column}>
                      <span className={ hasModifiers ? styles.quantityMod : styles.quantity }>{quantity}</span>
                    </div>
                    <div className={styles.column}>
                      <span className={styles.itemName}>{name}</span>
                    {hasModifiers && <span className={styles.modifiers}>{formatCartItemModifiers(item.modifiers, item.notes)}</span>}
                    </div>
                  </div>
                  <span className={ hasModifiers ? styles.priceMod : styles.price }>{price}</span>
                </div>
                {!isKiosk && <span className={styles.itemsSeparator} />}
              </div>
            </div>
          )
        })}
      </div>
        <div className={styles.total}>
            {isKiosk ?
            (<div className={styles.row}>
              <span className={styles.subTotal}>
                Subtotal
              </span>
              <span className={styles.price}>{centsToDollars(subtotalInCents)}</span>
            </div>
            ) :
            (
            <div className={totalsMobileRowStyle}>
              <span>Subtotal</span>
              <span className={styles.price}>{centsToDollars(subtotalInCents)}</span>
            </div>
            )}


          {discountInCents !== 0 && (
            <div className={totalsMobileRowStyle}>
              <span className={styles.discountRow}>Discount</span>
              <span className={cn(styles.discountRow, styles.discount)}>{centsToDollars(discountInCents)}</span>
            </div>
          )}

          {taxInCents > 0 && (
            <div className={totalsMobileRowStyle}>
              <span className={styles.totalItem}>Sales Tax</span>
              <span className={styles.price}>{centsToDollars(taxInCents)}</span>
            </div>
          )}

          {!!serviceFee && !!serviceFee.total && (serviceFee.total) > 0 && (
            <div className={totalsMobileRowStyle}>
              <span className={styles.totalItem}>{serviceFee.name}</span>
              <span className={styles.price}>{centsToDollars(serviceFee.total)}</span>
            </div>
          )}

          {tip > 0 && (
            <div className={cn(totalsMobileRowStyle)}>
              <span className={styles.totalItem}>Gratuity</span>
              <span className={styles.price}>{centsToDollars(tip)}</span>
            </div>
          )}

          <div className={totalsMobileRowStyle}>
            <span className={styles.totalItem}>Total</span>
            <span className={styles.price}>{centsToDollars(totalInCents)}</span>
          </div>
        </div>

     <span className={styles.separator}/>
      <div className={styles.payment}>
        <div className={styles.total}>
           {isMultiPayment && map(payments, (payment, idx) => {
            const icon = resolvePaymentIcon(
              payment.cardType?.split(" ")[0] ?? payment.paymentType
            )

            // need to hide ppi payments that are $0 or a discount type
            // becuase we need discounts to act as payments so they hit
            // vnapi
            if (payment.amountInCents === 0) {
              return null
            }

            return (
              <div className={paymentMobileRowStyle} key={idx}>
                <span className={styles.totalItem}>
                  {!isKiosk ? <div className={styles.icon}>{icon}</div> : null}
                  {payment.shortDescription}
                </span>
                <span className={styles.price}>
                  {centsToDollars(payment.amountInCents)}
                </span>
              </div>
            )
        })}

        {isMultiPayment && <span className={styles.separator}/>}

      </div>
        {paymentHeader}
        {remainingTicketBalanceRow}
      </div>

        {isMobileViewPort && <span className={styles.separator}/>}

      {isKiosk && (
        <div className={styles.disclaimer}>
        Discounts and gratuity applied from mobile purchases are not included.
        Please check your mobile receipt for final total.
        </div>
      )}
    </div>
  )
}

ReceiptDetails.defaultProps = {
  menuName: '',
  order: {},
  isKiosk: false
}

ReceiptDetails.propTypes = {
  menuName: PropTypes.string,
  order: PropTypes.object,
  isKiosk: PropTypes.bool
}

export default ReceiptDetails
