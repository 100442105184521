import { all, fork } from 'redux-saga/effects'
import { watchGetMenus, watchGetMenusItems } from './menu'
import {
  watchReplayAuthStatus,
  watchCloseNoOpTender,
  watchStartATab,
  watchAddItemsToTab,
  watchAddItemsToTabOffline,
  watchCloseTabOrder,
  watchStartOfflineCardOrder,
  watchStartOnlineCardOrder,
  watchSyncOrder,
  watchSyncOfflineOrder,
  watchSaveRichCheckoutOrder,
  watchUpdateCardReaderDataSaga,
  watchSendOrderEmail,
  watchSendOrderText,
  watchSyncOrders,
  watchSyncReplayAuthOrders,
  watchAddPaymentToOrderInProgress,
  watchSyncAndSaveOrderInProgress,
  watchGetTabOrders,
  watchGetOrderDetails,
  watchClosePreAuthedRCOrder,
  watchAddNewPayments,
  watchRemovePayment,
  watchUpdateSavedPayment,
  watchAddDiscountsToTabOrder,
  watchDeleteDiscountsFromTabOrder
} from './order'
import { watchDeviceLogin } from './user'
import { watchGetConfig, watchCreateConfig, watchUpdateConfig, watchRefreshConfigSaga } from './config'
import { watchRefundOrder, watchSyncRefunds, watchRefundTabbedOrder, watchRefundTabbedOrderOffline } from './refund'
import { watchGetRevenueCenters } from './revenueCenter'
import {
  watchAddOrUpdateCartItemSaga,
  watchSafelyIncrementCartItemQuantitySaga,
  watchSafelyDecrementCartItemQuantitySaga,
  watchSafelyUpdateCartItemQuantitySaga,
  watchSafelyUpdateCartItemNotesSaga,
} from './cart'
import { watchGetCarouselImages } from './kiosk/carouselImages'

import {
  watchFetchAttendantsSaga,
  watchAttemptPinLoginSaga,
  watchRegisterLoginSaga,
  watchLogoutSaga,
} from "./attendant"
import { watchFetchEmployeeRolesSaga } from './employeeRole'
import { watchPerformMaintenanceSaga } from './performMaintenance'

import {
  watchPrintOrder,
} from './printer'

import {
  watchFetchRemoteOrderTotalSaga,
} from './orderTotalRemote'

import {
  watchFetchPromotionsSaga,
} from './promotion'

function* watchAll() {
  yield all([
    fork(watchReplayAuthStatus),
    fork(watchCloseNoOpTender),
    fork(watchStartATab),
    fork(watchCloseTabOrder),
    fork(watchAddItemsToTab),
    fork(watchAddItemsToTabOffline),
    fork(watchAddDiscountsToTabOrder),
    fork(watchDeleteDiscountsFromTabOrder),
    fork(watchStartOfflineCardOrder),
    fork(watchStartOnlineCardOrder),
    fork(watchSyncOrder),
    fork(watchSyncOfflineOrder),
    fork(watchGetMenus),
    fork(watchGetMenusItems),
    fork(watchDeviceLogin),
    fork(watchGetConfig),
    fork(watchCreateConfig),
    fork(watchUpdateConfig),
    fork(watchRefundOrder),
    fork(watchRefundTabbedOrder),
    fork(watchRefundTabbedOrderOffline),
    fork(watchSyncRefunds),
    fork(watchGetRevenueCenters),
    fork(watchAddOrUpdateCartItemSaga),
    fork(watchSafelyIncrementCartItemQuantitySaga),
    fork(watchSafelyDecrementCartItemQuantitySaga),
    fork(watchSafelyUpdateCartItemQuantitySaga),
    fork(watchGetCarouselImages),
    fork(watchSaveRichCheckoutOrder),
    fork(watchSafelyUpdateCartItemNotesSaga),
    fork(watchFetchRemoteOrderTotalSaga),
    fork(watchUpdateCardReaderDataSaga),
    fork(watchRefreshConfigSaga),
    fork(watchFetchAttendantsSaga),
    fork(watchFetchEmployeeRolesSaga),
    fork(watchAttemptPinLoginSaga),
    fork(watchRegisterLoginSaga),
    fork(watchLogoutSaga),
    fork(watchPrintOrder),
    fork(watchPerformMaintenanceSaga),
    fork(watchFetchPromotionsSaga),
    fork(watchSendOrderEmail),
    fork(watchSendOrderText),
    fork(watchSyncOrders),
    fork(watchSyncReplayAuthOrders),
    fork(watchAddPaymentToOrderInProgress),
    fork(watchSyncAndSaveOrderInProgress),
    fork(watchGetTabOrders),
    fork(watchGetOrderDetails),
    fork(watchClosePreAuthedRCOrder),
    fork(watchAddNewPayments),
    fork(watchRemovePayment),
    fork(watchUpdateSavedPayment),
  ])
}

export default watchAll
