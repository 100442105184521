import React, { createContext, useState } from 'react'

const SpinnerContext = createContext()

const SpinnerProvider = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false)

  const startSpinner = async () => {
    setShowSpinner(true)
  }

  const stopSpinner = () => {
    setShowSpinner(false)
  }

  return (
    <SpinnerContext.Provider
      value={{
        startSpinner,
        stopSpinner,
        showSpinner,
      }}
    >
      {children}
    </SpinnerContext.Provider>
  )
}

export { SpinnerProvider }
export default SpinnerContext
