import { keys, reduce } from 'lodash'

const ENVIRONMENTS = {
  development: ['dev', 'develop', 'development'],
  staging: ['stg', 'staging', 'uat'],
  production: ['prd', 'production', 'prod'],
  qa: ['qa'],
  test: ['test'],
}

const INVERTED_ENVIRONMENTS = reduce(keys(ENVIRONMENTS), (accumulator, key) => {
  const newKeys = reduce(ENVIRONMENTS[key], (accumulator, value) => {
    return { ...accumulator, [value]: key }
  }, {})

  return { ...accumulator, ...newKeys }
}, {})

const methods = (target) => {
  return {
    rawValue: target,
    current: INVERTED_ENVIRONMENTS[target],
    isDevelopment: ENVIRONMENTS.development.includes(target),
    isStaging: ENVIRONMENTS.staging.includes(target),
    isProduction: ENVIRONMENTS.production.includes(target),
    isQA: ENVIRONMENTS.qa.includes(target),
    isTest: ENVIRONMENTS.test.includes(target),
  }
}

class Environment {
  static app = methods(process.env.REACT_APP_APP_ENV)
  static node = methods(process.env.NODE_ENV)
}

export default Environment
