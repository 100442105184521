import { get, reduce, some, flatMap, isEmpty } from 'lodash'
import { getCurrentMenuId } from './appState'

const getItemCategoryUuids = (state) => {
    const currentMenuId = getCurrentMenuId(state)
    const itemIds = get(state, ['items', 'byMenuId', currentMenuId, 'allIds'], [])

    return flatMap(itemIds, (itemId) => {
      const item = get(state, ['items', 'byMenuId', currentMenuId, 'byId', itemId], {})
      const itemCategories = get(item, 'itemCategoryUuids')
      return isEmpty(itemCategories) ? ['n/a'] : itemCategories
    })
  }

const createIdLookUp = (idCollection) => reduce(idCollection, (idLookUp, id) => ({ ...idLookUp, [id]: id}), {})

const getMajorOrMinorCategories = (state, itemCategoryUuids) => {
  const currentMenuId = getCurrentMenuId(state)
  const majorCategoryIds = get(state, ["categories", "byMenuId", currentMenuId, "majorCategoryIds"])
  const majorIdsLookUp = createIdLookUp(majorCategoryIds)
  const itemsHaveMajorCategories = some(
    itemCategoryUuids,
    itemCategoryUuid => majorIdsLookUp[itemCategoryUuid]
  )

  if (itemsHaveMajorCategories) return majorIdsLookUp

  const minorCategoryIds = get(state, ["categories", "byMenuId", currentMenuId, "minorCategoryIds"])
  const minorIdsLookUp = createIdLookUp(minorCategoryIds)

  return minorIdsLookUp
}

/**
 * Loops thru all items byId and it's assigned categories and 
 * returns a map with the category id as a key and the number of items
 * assigned to that category
 * 
 * @param {*} state 
 * @returns categoriesMap 
 */
const getItemCategoryCount = (state) => {
  const currentMenuId = getCurrentMenuId(state)
  const itemIds = get(state, ['items', 'byMenuId', currentMenuId, 'allIds'], [])

  return reduce(itemIds ,(categoriesMap, itemId) => {
    const item = get(state, ['items', 'byMenuId', currentMenuId, 'byId', itemId], {})
    const itemCategories = get(item, 'itemCategoryUuids')

    itemCategories.forEach(cat => {
      if (!categoriesMap[cat]) {
        categoriesMap[cat] = 1
      } else {
        categoriesMap[cat]++
      }
    })

    return categoriesMap
  }, {})
}

export const getItemCategories = state => {
  const itemCategoryUuids = getItemCategoryUuids(state)
  const currentMenuId = getCurrentMenuId(state)
  const categoriesById = get(state, ["categories", "byMenuId", currentMenuId, "byId"])
  const categoryIdLookUp = getMajorOrMinorCategories(state, itemCategoryUuids)

  const categoriesMap = getItemCategoryCount(state)

  const itemCategories = reduce(
    itemCategoryUuids,
    (categoryTypes, itemCategoryUuid) => {
      const category = get(categoryIdLookUp, itemCategoryUuid, undefined)

      if (category) {
        return {
          ...categoryTypes,
          [itemCategoryUuid]: { 
            ...categoriesById[itemCategoryUuid], 
            itemCount: categoriesMap[itemCategoryUuid]
          }
        }
      }
      return categoryTypes
    },
    {}
  )

  return itemCategories
}
