import { createSelector } from 'reselect'
import { get, map, filter, last, sumBy, nth, findIndex } from 'lodash'

import { calculateTax, calculateServiceCharge, calculateSubtotal } from '../utils/totalUtils'

import { getItemsForCurrentMenu, getMenuServiceCharge } from './items'
import { getCurrentMenuCategories } from './categories'
import { makeGetCurrentMenu } from './menus'

export const getCurrentCartItem = (state, id) => last(filter(getCartItems(state), ['id', id]))

export const getCartItems = (state) => {
  const items = get(getItemsForCurrentMenu(state), 'byId', {})
  const cartItems = getCartLineItems(state)
  const categories = get(getCurrentMenuCategories(state), 'byId', {})

  const formattedCartItems = map(cartItems, (cartItem) => {
    const item = items[cartItem.itemId]

    return {
      ...item,
      ...cartItem,
      color: get(categories, `${get(item, 'itemCategoryUuid')}.color`, null),
      modifiers: map(cartItem.modifiers, (modifierItemId) => items[modifierItemId.split('|')[0]])
    }
  })

  return formattedCartItems
}

/**
 * This selector will return all items for the order in progress in a format 
 * that allow totals calculations to be performed
 * 
 * @param {*} state 
 * @returns all items from the current order in a format that allows totals calculation
 */
export const getCartItemsFromOrder = (state, orderId) => {
  const items = getItemsForCurrentMenu(state).byId ?? {}
  const cartItems = state.order.byId[orderId]?.itemModels
  const categories = getCurrentMenuCategories(state).byId ?? {}

  const formattedCartItems = map(cartItems, (cartItem) => {
    const item = items[cartItem.id]

    return {
      ...item,
      ...cartItem,
      color: get(categories, `${get(item, 'itemCategoryUuid')}.color`, null),
      modifiers: map(cartItem.modifiers, (modifierItem) => items[modifierItem.id])
    }
  })

  return formattedCartItems
}

export const makeGetCartItemByIndex = () => {
  return createSelector(
    getCartItems,
    (state, index) => index,
    (cartItems, index) => nth(cartItems, index)
  )
}

export const hasCartItems2 = (state) => get(state, 'cart.lineItems.length', 0) > 0

export const getTaxRate = createSelector(
  makeGetCurrentMenu(),
  (currentMenu) => {
    return currentMenu.taxRate ?? 0
  }
)

// TODO: Implement this when we know where to fetch the tax strategy metadata
export const getTaxByItem = createSelector(
  makeGetCurrentMenu(),
  (currentMenu) => {
    return currentMenu.taxByItem ?? true
  }
)

export const getSubtotal = createSelector(
  getCartItems,
  (cartItems) => {
    return calculateSubtotal(cartItems)
  }
)

export const getTax = createSelector(
  getCartItems, getTaxRate, getTaxByItem,
  (cartItems, taxRate, taxByItem) => {
    return calculateTax(cartItems, taxRate, taxByItem)
  }
)

export const getServiceCharge = (state) => {
  const subtotal = getSubtotal(state)
  const menuServiceCharge = getMenuServiceCharge(state)

  return calculateServiceCharge(menuServiceCharge, subtotal)
}

export const getTotal = createSelector(
  getSubtotal, getServiceCharge, getTax,
  (subtotal, serviceCharge, tax) => subtotal + serviceCharge + tax
)

export const getCartQuantity2 = createSelector(
  getCartItems,
  (cartItems) => sumBy(cartItems, 'quantity')
)

export const alcoholItemCountSelector = createSelector(getCartItems, items =>
  items.reduce((total, item) => total + (item.isAlcohol ? item.quantity : 0), 0),
)

export const getCartIndexByItemId = (store, itemId) =>
  findIndex(getCartLineItems(store), lineItem => lineItem.itemId === itemId)

export const getCartLineItems = (store) => get(store, 'cart.lineItems', [])

export const hasCartItems = (state) => get(state, 'cart.lineItems.length', 0) > 0

export const getNewItemFocus = (state) => state?.cart?.newItemFocus ?? false

export const getCartItemsCount = (state) => state?.cart?.lineItems?.length ?? 0

export const getCartNotes = (state) => state?.cart?.userNotes ?? null
