import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './RoundButton.module.scss'

const RoundButton = ({ text, className, disabled, ...props }) => {
  const classNames = cn(styles.roundButton, className)

  return (
    <button className={classNames} disabled={disabled} {...props}>{text}</button>
  )
}

RoundButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

export default RoundButton
