import get from 'lodash/get'

import {
  clearRemoteOrderTotal,
  fetchRemoteOrderTotal,
  fetchRemoteOrderTotalSucceeded,
  fetchRemoteOrderTotalFailed
} from '../actions/orderTotalRemote'

import { formatOrderTotals } from '../utils/orderTotalUtils'

const defaultState = {
  loading: true,
  failed: false,
  isAvailable: false,
}

const reducer = (state = defaultState, action) => {
  const clear = clearRemoteOrderTotal.type
  const requested = fetchRemoteOrderTotal.type
  const succeeded = fetchRemoteOrderTotalSucceeded.type
  const failed = fetchRemoteOrderTotalFailed.type

  switch(action.type) {
    case clear:
      return defaultState

    case requested: {
      const orderUuid = get(action, 'payload.orderUuid', '')

      return {
        orderUuid,
        loading: true,
        failed: false,
        updatedAt: undefined,
      }
    }

    case succeeded: {
      const data = action?.payload?.result?.data ?? {}
      const updatedAt = new Date()

      return {
        ...state,
        ...formatOrderTotals(data),
        updatedAt,
        loading: false,
        failed: false,
        isAvailable: true,
      }
    }

    case failed: {
      const data = get(action, 'payload.response.data', {})

      return {
        ...data,
        loading: false,
        failed: true,
        isAvailable: false,
        updatedAt: undefined,
      }
    }

    default:
      return state

  }
}

export default reducer
