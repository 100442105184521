const useGetEnvVars = () => {
  let allowEnableBackbar = false

  if (process.env.REACT_APP_ALLOW_ENABLE_BACKBAR === 'true') {
    allowEnableBackbar = true
  }

  return { allowEnableBackbar }
}

export default useGetEnvVars
