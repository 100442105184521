import { useState } from 'react'

/**
 * @param {string} initialValue The initial value
 * @param {function} formatter The method to format the raw value
 * @param {function} validator Validates the input
 * @param {number} maxLength The max length that is allowed
 * @param {string} defaultValue The fallback default value
 * @returns {[string, function, string, boolean]}
 */
const useNumberPad = (initialValue = 0, formatter = (value) => value, validator = () => true, maxLength = Infinity, defaultValue = '0') => {
  const sanitize = (value) => {
    const result = `${value}`.replace(/\D/g, '')

    if (result.length > 1) {
      result.replace(/^[0]{0,}/g, '')
    }

    return result
  }

  const [rawValue, setRawValue] = useState(sanitize(initialValue))
  const [formattedValue, setFormattedValue] = useState(formatter(rawValue))
  const [valid, setValid] = useState(validator(rawValue))

  const onChange = (value) => {
    setRawValue(value)
    setFormattedValue(formatter(value))
    setValid(validator(value))
  }

  const onInput = (value, replace) => {
    let next

    if (value === 'backspace') {
      next = rawValue.length === 1 ? defaultValue : rawValue.slice(0, -1)
    } else {
      next = replace || rawValue === defaultValue ? sanitize(value) : sanitize(rawValue + value)
    }

    if (next.length <= maxLength) {
      onChange(next)
    }
  }

  return [formattedValue, onInput, rawValue, valid]
}

export default useNumberPad
