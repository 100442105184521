export const VNCFD_POS_SET_PAIRED = 'VNCFD_POS_SET_PAIRED' // The pairing state of POS
export const VNCFD_CFD_SET_PAIRED = 'VNCFD_CFD_SET_PAIRED' // The pairing state of CFD
export const VNCFD_SET_CFD_INITIALIZED = 'VNCFD_SET_CFD_INITIALIZED' // The initialized values of the CFD
export const VNCFD_UPDATE_CART = 'VNCFD_UPDATE_CART' // The full cart from POS to CFD
export const VNCFD_SET_SCREEN = 'VNCFD_SET_SCREEN' // What screen the CFD should be on
export const VNCFD_EXECUTE_SALES_REQUEST = 'VNCFD_EXECUTE_SALES_REQUEST' // execute a sales request :)
export const VNCFD_TRANSACTION_RESULT = 'VNCFD_TRANSACTION_RESULT'
export const VNCFD_SET_CART_TOTALS = 'VNCFD_SET_CART_TOTALS'
export const VNCFD_RICH_CHECKOUT_ORDER_DETAILS = 'VNCFD_RICH_CHECKOUT_ORDER_DETAILS'
export const VNCFD_USER_PAYMENT_PROGRESS = 'VNCFD_USER_PAYMENT_PROGRESS' // send to POS from CFD or vice versa on card or rich checkout
export const VNCFD_CARD_ERROR = 'VNCFD_CARD_ERROR' // What error the CFD Card Reader had so we can display it
export const VNCFD_PAYMENT_DATA = 'VNCFD_PAYMENT_DATA' // for internal use of the cfd
export const VNCFD_TIPS_DATA = 'VNCFD_TIPS_DATA' // contains details from POS to CFD about tips being enabled and tip suggestions
export const VNCFD_DEBUGGER_DATA = 'VNCFD_DEBUGGER_DATA'
export const VN_ORDER_UPDATE = 'VN_ORDER_UPDATE'
export const VN_CAN_EXECUTE_SALES_REQUEST = 'VN_CAN_EXECUTE_SALES_REQUEST' // incase of qr pay where a sales request is not required
export const VNCFD_BALANCE_TO_BE_PAID = 'VNCFD_BALANCE_TO_BE_PAID'
export const VNCFD_CURRENT_PAYMENT_FLOW = 'VNCFD_CURRENT_PAYMENT_FLOW'

// TEMPORARY
export const VNCFD_CALCULATED_CURRENT_CART_VALUES = 'VNCFD_CALCULATED_CURRENT_CART_VALUES'

// NEW ARCHITECTURE
export const VNCFD_CURRENT_CART = 'VNCFD_CURRENT_CART'
export const VNCFD_UPDATE_TRANSACTION_LIFECYCLE = 'VNCFD_UPDATE_TRANSACTION_LIFECYCLE'
export const VNCFD_SET_CURRENT_ORDER = 'VNCFD_SET_CURRENT_ORDER'
export const VNCFD_UPDATE_FORMATTED_CART = 'VNCFD_UPDATE_FORMATTED_CART'