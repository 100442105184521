import React, { memo, useRef, useEffect, useState } from 'react'
import { delay } from 'lodash'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ReactComponent as AddItemIcon } from '../assets/icons/add-item.svg'

import styles from './Item.module.scss'

import { addPaddingAndScrollUpToElement, getElementScrollPosition, ScrollUpToElement, scrollingHasStopped } from '../utils/search'

const Item = ({ id, name, price, categoryId, emojis, status, maxReached, inventory, cartQuantity, color, onTap, className, highlighted, focusedItem, categoryBreakPoints, scrollPositions, setScrollPositions, selectedCategory, setSelectedCategory }) => {
  const available = status === 'available'
  let quantityElement = <AddItemIcon className={styles.add} />
  const itemRef = useRef();
  let classNames = cn(styles.item, className)
  let style = { backgroundColor: color, color }
  let _price = price
  let _onTap = onTap
  const previousCartQuantityRef = useRef()
  const [itemDisabled, setItemDisabled] = useState(false)

  if (inventory <= 10) {
    price = `${price} - ${inventory} LEFT!`
  }

  if (cartQuantity > 0) {
    quantityElement = <span className={styles.quantity} >{cartQuantity}</span>
  }

  if (maxReached) {
    style.opacity = 0.6
    _price = 'MAX REACHED'
    _onTap = () => {}
  }

  if (!available) {
    classNames = cn(classNames, styles.unavailable)
    style = {}
    _price = 'SOLD OUT'
    _onTap = () => {}
  }

  if (!highlighted) {
    style.opacity = ".1"
  }

  if (focusedItem) {
    classNames = cn(classNames, styles.active)
  }

  useEffect(() => {
    if (focusedItem && itemRef.current) {
      let setIntervalTimer
      if (!selectedCategory.search) {
        addPaddingAndScrollUpToElement(itemRef.current)
        setIntervalTimer = 10
      }
      if (categoryBreakPoints[categoryId] === id && selectedCategory.search) {
        ScrollUpToElement(itemRef.current)
        setIntervalTimer = 100
      }  
      const scrollTimer = setInterval(() => {
        if(!itemRef.current || scrollingHasStopped(itemRef.current)) {
          setSelectedCategory({ id: categoryId, search: false, highlight: true })
          clearInterval(scrollTimer)
        }
      }, setIntervalTimer)
      setTimeout(() => {
        clearInterval(scrollTimer)
      }, 1000)
    }
  }, [focusedItem])

  useEffect(() => {
    if ( itemRef.current && !scrollPositions[categoryId] && categoryBreakPoints[categoryId] === id) {
      const scrollPosition = getElementScrollPosition(itemRef.current)
      setScrollPositions({ [categoryId]: scrollPosition, ...scrollPositions })
    }
  }, [categoryBreakPoints, scrollPositions])

  // cart quantity throttling logic to improve performance on E700 devices

  const handleOnTap = (e) => {
      // return if cart is still updating
      if (itemDisabled) return
      previousCartQuantityRef.current = cartQuantity
      _onTap()

      // disable item taps until cart updates
      setItemDisabled(true)
  }

  // re-enables item taps after cart quantity is updated from last tap
  useEffect(() => {
    if (itemDisabled && previousCartQuantityRef?.current !== undefined && cartQuantity !== previousCartQuantityRef?.current) {
      // delay is necessary to break the native event queue on the E700 that registers/queues finger taps
      delay(() => {
        setItemDisabled(false)
      }, 100)
    }
  }, [cartQuantity])

  return (
    <div className={classNames} ref={itemRef} style={style} onClick={handleOnTap} >
      <div className={styles.title}>
        <span className={styles.name}>{name}</span>
      </div>
      <div className={styles.details}>
        <div className={styles.inventoryPrice}>
          <span className={styles.price}>{_price}</span>
        </div>
        {quantityElement}
      </div>
    </div>
  )
}

Item.defaultProps = {
  status: 'available',
  maxReached: false,
  emojis: [],
}

Item.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  emojis: PropTypes.array,
  status: PropTypes.oneOf(['unavailable', 'available']),
  maxReached: PropTypes.bool,
  inventory: PropTypes.number,
  cartQuantity: PropTypes.number,
  color: PropTypes.string,
  onTap: PropTypes.func,
  className: PropTypes.string,
  highlighted: PropTypes.bool,
  focusedItem: PropTypes.bool
}

export default memo(Item)
