import { useEffect, useRef } from "react"
import { setIsKeyboardActive } from "./ActionCreators"
import { isRunningOnAndroid } from "./Utils"
import { useDispatch } from "react-redux"

/*
  Noting here that in order for VNKeyboardActivity to work as expected
  engineers must pass in the data-allow-keyboard-activity attribute to 
  any new element that is keyboard enabled
*/

export const VNKeyboardActivity = () => {
  const isFocusedRef = useRef({
    isFocused: false,
    isKeyboardActive: false
  })
  const initialClientHeight = document.documentElement.clientHeight
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isRunningOnAndroid()) return

    const handleFocus = (event) => {
      if (event.target.getAttribute('data-allow-keyboard-activity')) {
        isFocusedRef.current.isFocused = true
        isFocusedRef.current.isKeyboardActive = true
        dispatch(setIsKeyboardActive(true))
      }
    }

    const handleBlur = (event) => {
      if (event.target.getAttribute('data-allow-keyboard-activity')) {
        isFocusedRef.current.isFocused = false
        isFocusedRef.current.isKeyboardActive = false
        dispatch(setIsKeyboardActive(false))
      }
    }

    // This is for special scenarios when the keyboard is lowered from the user on Android, if that
    // happens the component will still be focused.
    const handleResize = () => {
      if (isFocusedRef.current.isFocused && initialClientHeight === document.documentElement.clientHeight) {
        isFocusedRef.current.isKeyboardActive = false
        dispatch(setIsKeyboardActive(false))
      }
    }

    document.addEventListener('focus', handleFocus, true)
    document.addEventListener('blur', handleBlur, true)
    window.addEventListener('resize', handleResize, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
      document.removeEventListener('blur', handleBlur, true)
      window.removeEventListener('resize', handleResize, true)
    }
  }, [])

  return <></>
}