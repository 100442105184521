import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './CartDetails.module.scss'

const CartDetails = ({ tax, serviceCharge, total, lineItemPrefix, discountSelected, className }) => {
  const classNames = cn(styles.cartDetails, className)

  return (
    <div className={classNames}>
      {discountSelected && (
        <div className={styles.row}>
          {/* <span className={cn([styles.label, styles.discount])}>{"Discount applied upon checkout"}</span> */}
        </div>
      )}
    </div>
  )
}

CartDetails.defaultProps = {
  lineItemPrefix: '',
  tax: '--',
  total: '--',
}

CartDetails.propTypes = {
  tax: PropTypes.string,
  total: PropTypes.string,
  className: PropTypes.string,
  lineItemPrefix: PropTypes.string,
}

export default memo(CartDetails)
