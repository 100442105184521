import { head, reduce, some } from "lodash";
import { sendOrderEmail, sendOrderText, updateOrderInProgress } from "../../actions/order";
import { ToastManager } from '../../utils/toastManager'

/**
 * Takes an object of email information and an order, it then validates and sends an email with the order information.
 * @param {Object} recipientInfo contains the email information, including the emails to be sent.
 * @param {Object} order The order information.
 * @param {String} paymentId The payment id used.
 * @param {Function} setEmailInfo sets the email info state.
 */
export const submitEmail = (recipientInfo, order, paymentId, setEmailInfo) => {
  return (dispatch, getState) => {
    const emails = recipientInfo?.recipients?.replace(/\s/g, "").split(",")
    const emailRegex = /.+@.+\.+.+/i;
    const emailsAreValid = !(some(emails, (email) => !emailRegex.test(email))) || !recipientInfo.recipients.length

    setEmailInfo({ ...recipientInfo, isValid: emailsAreValid })

    if (!head(emails)?.length) {
      ToastManager.error("Email cannot be empty.")
      return
    }

    if (!emailsAreValid) {
      ToastManager.error("Can't send email.")
      return
    }

    if (order?.submittedAt) {
      dispatch(sendOrderEmail({ orderUuid: order.uuid, emails, paymentUuid: paymentId }))
      return
    }

    // if there is a paymentId, we save the emails to the payment attached to the order to be emailed upon order completion
    const payments = reduce(order.payments, (payments, payment) => {
      if (payment.paymentId === paymentId) {
        payment.emails = emails
      }
      return [
        ...payments,
        payment
      ]
    }, [])
    dispatch(updateOrderInProgress({
      payments,
    }))
    ToastManager.success('Receipts will be emailed upon order completion')
  }
}

/**
 * Takes an object of email information and an order, it then validates and sends an email with the order information.
 * @param {Object} recipientInfo contains the text information, including the phone numbers to be sent.
 * @param {Object} order The order information.
 * @param {String} paymentId The payment id used.
 * @param {Function} setEmailInfo sets the email info state.
 */
export const submitText = (recipientInfo, order, setRecipientInfo) => {
  return (dispatch, getState) => {
    const userPhone = recipientInfo?.recipients

    setRecipientInfo({ ...recipientInfo, isValid: true })

    if (order?.submittedAt) {
      dispatch(sendOrderText(order.uuid, userPhone))
      return
    }

    ToastManager.success('Receipts will be texted upon order completion')
  }
}
