/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { isEmpty } from 'lodash'
import Lottie from "lottie-react";

import { getOrderInProgress, getQRPayAuthError } from '../../selectors/order'
import { addPaymentToOrderInProgress, closeNoOpTender, removeQRPaymentandUnsyncableStatus, setOrderInProgressWithId, setQRPayAuthError } from '../../actions/order'

import { formatPrice } from '../utils/formatters'
import PeripheralBridge from '../../utils/peripheralBridge'
import UserIdleMonitor from '../utils/UserIdleMonitor'
import { KEY_CODES } from "../utils/contants"

import OfflineModal from '../OfflineModal'
import ConnectingIndicator from '../../components/ConnectingIndicator'
import BackButton from '../components/BackButton'
import ScanQRGif from '../assets/images/kiosk-qr-scan.gif'
import { ReactComponent as Alert } from '../assets/icons/alert.svg'

import useGetQRBalance from '../../hooks/useGetQRBalance'
import useCreateOrderInProgress from '../../hooks/useCreateOrderInProgress'

import loadingSpinnerAnimationData from '../assets/images/kiosk-loading.json'
import { handleFullPpiFlow } from '../../VNPPI/Utils'
import { VNPPI_TENDERS } from '../../VNPPI/Enums'
import { VNInjectablePpi } from '../../VNPPI/containers/VNInjectablePpi'
import { codeAlreadyUsed } from '../../VNUtil/VNUtils'
import { showFakeCreditCard } from '../../utils/fakeCreditCardResponse'

const QR_PAY_ERROR_MESSAGE = 'QR code charge declined. \nCheck payment and try again.'

export const KioskQRPay = ({
  onGoBack,
  order,
  orderExists,
  addPaymentToOrderInProgress,
  balanceDueInCents,
  placeFreeOrder,
  push
}) => {

  const [isProcessing, setIsProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [qrCode, setQRCode] = useState('')
  const [createOrder, orderCreated] = useCreateOrderInProgress()
  const [getQRBalance, { qrBalance, vaultedCard, dataSucceeded, dataFailed, isExpired, affiliationUuids, userUuid }] = useGetQRBalance()
  const [retotalingOrder, setRetotalingOrder] = useState(false)
  const [manualValue, setManualValue] = useState('')
  const isDevMode = useMemo(showFakeCreditCard, [])
  const noPaymentRequired = order?.amountInCents === 0
  const hasNoBalance = +qrBalance === 0 && !vaultedCard
  const backButtonRef = useRef()

  const ppiInjectableRef = useRef()

  const currentOrder = useRef()

  const dispatch = useDispatch()

  const scannedResultText = errorMessage !== '' ? errorMessage : QR_PAY_ERROR_MESSAGE
  let primaryText = 'SCAN QR CODE'
  let secondaryText = errorMessage ? scannedResultText : 'Please scan QR code.'

  const QRPayAuthError = useSelector(state => getQRPayAuthError(state))

  useEffect(() => {
    dispatch(setQRPayAuthError(false))

    if (!orderExists) {
      createOrder()
    }

    return () => {
      const bridgeInstance = PeripheralBridge.getBridge()
      if (!bridgeInstance) return
      bridgeInstance.call('stopScanner')
    }
  }, [])

  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.UP) {
      onGoBack()
    }
  }

  useEffect(() => {
    if (dataFailed) {
      setIsProcessing(false)
      setErrorMessage(
        isExpired
          ? 'QR code has expired. \nPlease refresh your code and try again'
          : QR_PAY_ERROR_MESSAGE
      )
    }
  }, [dataFailed])

  useEffect(() => {
    //In case a new order is created we need to store uuid and balanceDueInCents
    currentOrder.current = {
      uuid: order.uuid,
      balanceDueInCents: order.balanceDueInCents
    }
  
    if (orderCreated && noPaymentRequired && !retotalingOrder) {
      placeFreeOrder({ orderUuid: order?.uuid })
      return
    }
    if (retotalingOrder && orderCreated) {
      setRetotalingOrder(false)
      handleSuccess()
    }
  }, [orderCreated])

  useEffect(() => {
    if (dataSucceeded) {
      if (!isEmpty(affiliationUuids)) {
        createOrder(true, userUuid, affiliationUuids, order?.tipAmountInCents)
        setRetotalingOrder(true)
        return
      }
      handleSuccess()
    }
  }, [dataSucceeded])

  useEffect(() => {
    if (orderExists) {
      const bridgeInstance = PeripheralBridge.getBridge()
      if (!bridgeInstance) {
        return
      }
      bridgeInstance.registerHandler('scanPayload', function (data, callback) {
        const scannedCode = data?.valueOf()
        if (codeAlreadyUsed(order, scannedCode)) {
          setErrorMessage('QR code already scanned and applied to order')
          return
        }

        setQRCode(scannedCode)
        startBalanceCheck(scannedCode)
      })

      bridgeInstance.registerHandler('scanError', function (err, callback) {
        let message = 'QR code scan error. Try again.'
        if(err?.toLowerCase()?.includes('no scanner detected')) {
          message = 'No scanner detected'
        }
        setErrorMessage(message)
      })
    }
  }, [orderExists, order])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    if (isProcessing) {
      bridgeInstance.call('stopScanner')
    } else if (orderExists) {
      bridgeInstance.call('startScanner')
    }
  }, [isProcessing, orderExists])

  //Set current order with the first valid value once order in progress has been set
  useEffect(()=> {
    if (!currentOrder?.current?.uuid) {
      currentOrder.current = {
        uuid: order.uuid,
        balanceDueInCents: order.balanceDueInCents
      }
    }
  }, [order])

  /**
   * This code will be triggered when QRPayAuthError is set to true
   * then we asume that a QRPay failed for the order being processed
   */
  useEffect(() => {
    //if QRPayAuthError is false then return, this will prevent undesired re renders
    if (!QRPayAuthError) {
      return
    }

    resetTicket()
    setErrorMessage(QR_PAY_ERROR_MESSAGE)

    const orderId = currentOrder.current.uuid

    //dispatch action to set the order into syncable status
    dispatch(removeQRPaymentandUnsyncableStatus({orderId, balanceDueInCents: currentOrder.current.balanceDueInCents}))
    dispatch(setOrderInProgressWithId(orderId))
  }, [QRPayAuthError])

  const resetTicket = () => {
    setQRCode('')
    setIsProcessing(false)
  }

  const startBalanceCheck = async (code) => {

    setIsProcessing(true)
    setErrorMessage('')

    const ppiInjectableData = ppiInjectableRef.current?.getData(VNPPI_TENDERS.QRPAY_TENDER)

    if (!isEmpty(ppiInjectableData.ppiTender)) {

      const externalFunctionality = {
        functions: {
          ...ppiInjectableData.functions,
          setErrorMessage: setErrorMessage,
          redirect: redirect,
          push,
          placeFreeOrder: placeFreeOrder
        },
        balanceDueInCents: ppiInjectableData.order?.balanceDueInCents,
        promotions: ppiInjectableData.promotions,
        token: code,
        paymentType: "vnapi_qrpay",
        shortDescription: "QR Pay"
      }

      await handleFullPpiFlow(ppiInjectableData, externalFunctionality)

      resetTicket()

    } else {
      getQRBalance(code)
    }
  }

  const PaymentBackBtn = () => {
    return (
      <BackButton
        handleKeyDown={handleKeyDown}
        refProp={backButtonRef}
        className="payment__back"
        withIcon={true}
        onClick={onGoBack}
      >
        {!orderExists ? '-' : formatPrice(order?.balanceDueInCents)}
      </BackButton>
    )
  }

  const idleMonitor = new UserIdleMonitor(process.env.REACT_APP_DEV_IDLE_TIMEOUT || 60000)
  useEffect(() => {
    backButtonRef.current && backButtonRef.current.focus()

    idleMonitor.addEventListeners()
    return () => {
      idleMonitor.removeEventListeners()
    }

  }, [])

  const redirect = (tenderAmountInCents, paymentWasSuccessful) => {
    push({
      pathname: `/kiosk/order/payment`,
      state: {
        tenderAmountInCents,
        paymentWasSuccessful,
        scanScreenType: 'QR code',
      }
    })
  }

  const handleSuccess = () => {
    if (hasNoBalance && order?.amountInCents !== 0) {
      redirect(balanceDueInCents, true)
      return
    }
    let tenderAmountInCents
    let redirectToSplit = false
    if (vaultedCard || qrBalance >= balanceDueInCents) {
      tenderAmountInCents = balanceDueInCents
    } else {
      tenderAmountInCents = qrBalance
      redirectToSplit = true
    }
    addPaymentToOrderInProgress({
      tenderAmountInCents,
      paymentType: 'wallet_nonce',
      shortDescription: 'QR Pay',
      token: qrCode,
    })
    if (redirectToSplit) {
      redirect(balanceDueInCents - qrBalance, true)
    }
  }

  if (!orderExists) {
    return <ConnectingIndicator isKiosk={true} position={'initial'}/>
  }

  let footerElement = null

  if (errorMessage) {
    footerElement = <div className="payment__footer-button" onClick={() => {
      redirect(balanceDueInCents, false)
    }}>
      PAY WITH CREDIT CARD
    </div>
  }

  let content = (
    <>
      <PaymentBackBtn />
      <div className="payment__header">{primaryText}</div>
      <div className="payment__sub-header">{(errorMessage) && <Alert className="payment__alert-icon"/>}{secondaryText}</div>
      {isDevMode && (
        <div className="payment__sub-header">
          <input
            type='text'
            placeholder='Manual Entry (DEBUG)'
            value={manualValue}
            onChange={(e) => setManualValue(e?.target?.value)}
            onBlur={(e) => {
              setQRCode(manualValue)
              startBalanceCheck(manualValue)
            }}
          />
        </div>
      )}
      <div className="payment__ticket-wrapper">
      <img src={ScanQRGif} alt={"Scan QR"} className={"payment__card-reader-icon"}/>
      </div>
      <div className={`payment__footer-text${!isEmpty(footerElement) ? '-with-button' : ''}`}>
        Scanner is Ready
      </div>
        {footerElement}
    </>
    )

  if (isProcessing) {
    content = (
      <>
        <PaymentBackBtn />
        <div className="payment__header">Processing</div>
        <div className="payment__sub-header">Please wait.</div>
        <div className="payment__ticket-wrapper">
          <div className="payment__card-reader-icon">
            <Lottie animationData={loadingSpinnerAnimationData} />
          </div>
        </div>
      </>
    )
  }

  if (noPaymentRequired) {
    content = (
      <>
        <div className="payment__content">
          <PaymentBackBtn />
          <div className="payment__header">No Charge!</div>
          <div className="payment__sub-header">Submitting your order...</div>
        </div>
      </>
      )
  }

  return (
    <div className="payment">
      <VNInjectablePpi ref={ppiInjectableRef} />
      {content}
      <OfflineModal />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const order = getOrderInProgress(state)
  const balanceDueInCents = order?.balanceDueInCents ?? 0
  const push = props?.history?.push ?? (() => {})
  const orderExists = !isEmpty(order)

  return {
    order,
    orderExists,
    balanceDueInCents,
    push
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const push = props?.history?.push ?? (() => {})
  const goBack = props?.history?.goBack ?? (() => {})

  return {
    onGoBack: () => goBack(),
    addPaymentToOrderInProgress: (payment) => dispatch(addPaymentToOrderInProgress({ ...payment, push })),
    placeFreeOrder: (payload) => {
      dispatch(closeNoOpTender(payload))
      push(`/kiosk/order/summary/${payload.orderUuid}`)
    }
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(KioskQRPay)
