import React from 'react'
import { useHistory } from 'react-router-dom'

import BackChevron from './BackChevron'

import styles from './BackButtonChevron.module.scss'

const BackButtonChevron = ({ onClick, backButtonText = 'BACK', hidden }) => {
  const history = useHistory()
  const resolvedOnClick = onClick ?? history.goBack
  const style = hidden ? {visibility: 'hidden'} : {}

  return (
    <button onClick={resolvedOnClick} className={styles.backButton} style={style}>
      <BackChevron className={styles.backButtonIcon} />
      <span className={styles.backButtonText}>{backButtonText}</span>
    </button>
  )
}

export default BackButtonChevron
