import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { setDeviceMode, setPorticoAuthTokens, setSummitMode } from '../ActionCreators'
import { MODES } from '../Reducer'
import { useDispatch, useSelector } from 'react-redux'
import { bridge_getDeviceId } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { getDeviceId, getPorticoAuthTokens } from '../Selectors'
import Portico from '@ordernext/networking-stadium/portico'
import Stadium, { loadConfig } from '@ordernext/networking-stadium/stadium'

export const VNModeSelect = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const deviceId = useSelector(state => getDeviceId(state))
  const porticoAuthTokens = useSelector(state => getPorticoAuthTokens(state))

  const [isSummit, setIsSummit] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.get('cfd') === 'true') {
      dispatch(setDeviceMode(MODES.CFD))
      history.replace('/customer-facing-display')
    } else {
      
      if (urlParams.get('summit') === 'true') {
      
        setIsSummit(true)

        // set the summit mode in the full reducer so we can use it in other components
        dispatch(setSummitMode(true))
        
        // get the device ID from the attached Android SDK
        // this is async so we need to wait for a response before continueing
        bridge_getDeviceId()
  
      } else {
        history.replace('/device-login')
      }
    }
  }, [])

  // This is listening for the deviceId to be stored in redux
  useEffect(() => {

    if (isSummit && deviceId !== null) {

      // do an async portico device login so we don't need to do promises
      const doPorticoDeviceLogin = async () => {

        const appKey = process.env.REACT_APP_OIDC_CLIENT_ID
        const organizationName = process.env.REACT_APP_ORG

        let porticoDeviceLogin
        
        try {
          porticoDeviceLogin = await Portico.deviceLogin(deviceId, appKey, organizationName)
        } catch (e) {
          // this failed...
          // if it could not do a portico device login, we'll try again in 10 seconds
          setTimeout(() => {
            doPorticoDeviceLogin()
          }, 10000)
          return
        }

        const accessToken = porticoDeviceLogin?.data?.accessToken
        const expiresAt = porticoDeviceLogin?.data?.expiresAt

        if (accessToken && expiresAt) {

          // set the tokens in redux
          dispatch(setPorticoAuthTokens(accessToken, expiresAt))

          // also set them in local storage since old code gets them from there
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('expiresAt', expiresAt)

          // set it within this Stadium library
          Stadium.config = {
            ...Stadium.config,
            headers: {
              ...Stadium.config.headers,
              Authorization: `Bearer ${accessToken}`,
              'Cache-Control': 'no-cache, no-store',
              Pragma: 'no-cache',
              Expires: 0,
            }
          }
        } else {
          // if it could not do a portico device login, we'll try again in 10 seconds
          setTimeout(() => {
            doPorticoDeviceLogin()
          }, 10000)
          return
        }
      }

      doPorticoDeviceLogin()
      
    }
  }, [deviceId, isSummit])

  // Listening for when the tokens are set in redux
  useEffect(() => {

    if (isSummit && porticoAuthTokens && !porticoAuthTokens.isEmpty()) {

      // we want to also double check to see if they are stored and accessible in localstorage before moving on
      const interval = setInterval(() => {

        const tempToken = localStorage.getItem('accessToken')
        const tempExpiresAt = localStorage.getItem('expiresAt')

        // if we have them both, lets get going
        if (tempToken && tempExpiresAt) {
          clearInterval(interval)

          //loading config will automatically redirect depending on the fetched configuration
          dispatch(loadConfig())
        }

      }, 500)
    }
  }, [porticoAuthTokens, isSummit])

  return (
    <></>
  )
}
