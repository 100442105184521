import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import cn from 'classnames'
import { filter, reduce, get } from 'lodash'

import { formatPrice } from '../utils/formatters'
import Button from '../components/Button'
import AlcoholWarningModal from '../components/AlcoholWarningModal'
import IconAdd from '../components/icons/IconAdd'

import ImageVersioningContext from '../context/ImageVersioningContext'
import { clickToAddToCart, alcoholMessage } from '../utils/messages'
import { KEY_CODES } from "../utils/constants";

export default class Item extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    addedToCart: PropTypes.bool.isRequired,
    isAgeConfirmed: PropTypes.bool.isRequired,
    isAlcoholWarningConfirmed: PropTypes.bool.isRequired,
    confirmAge: PropTypes.func.isRequired,
    confirmAlcoholWarning: PropTypes.func.isRequired,
    alcoholItemCount: PropTypes.number.isRequired,
    alcoholLimit: PropTypes.number.isRequired,
    innerRef: PropTypes.func,
    handleKeyPress: PropTypes.func,
    itemHeight: PropTypes.string
  }

  static contextType = ImageVersioningContext

  confirmAgeButtonRef = React.createRef()
  confirmAlcoholWarningButtonRef = React.createRef()

  state = {
    imageExists: true,
    showAgeConfirmationModal: false,
    showAlcoholWarningModal: false,
  }

  componentDidUpdate() {
    const { showAgeConfirmationModal, showAlcoholWarningModal } = this.state

    if (showAgeConfirmationModal) {
      setTimeout(() => {
        this.confirmAgeButtonRef.current && this.confirmAgeButtonRef.current.focus()
      }, 0)
    }

    if (showAlcoholWarningModal) {
      setTimeout(() => {
        this.confirmAlcoholWarningButtonRef.current && this.confirmAlcoholWarningButtonRef.current.focus()
      }, 0)
    }
  }

  _updateCart = () => {
    const {
      addToCart,
      item,
      isAgeConfirmed,
      isAlcoholWarningConfirmed,
      alcoholItemCount,
      alcoholLimit,
    } = this.props

    const alcoholLimitExceeded = alcoholItemCount === alcoholLimit
    const addedAlcohol = item.isAlcohol

    if (addedAlcohol && !isAgeConfirmed) {
      this.setState({ showAgeConfirmationModal: true })
    } else {
      if (addedAlcohol && alcoholLimitExceeded && !isAlcoholWarningConfirmed) {
        this.setState({ showAlcoholWarningModal: true })
      }
      addToCart(item)
    }
  }

  _updateCartAndConfirmAge = () => {
    const { addToCart, item, confirmAge } = this.props
    confirmAge()
    addToCart(item)
    this.setState({ showAgeConfirmationModal: false })
  }

  _cancelAddItem = () => {
    this.setState({ showAgeConfirmationModal: false })
  }

  _confirmAlcoholWarning = () => {
    this.setState({ showAlcoholWarningModal: false })
    this.props.confirmAlcoholWarning()
  }

  _handleKeyPressOnItem = (event, index) => {
    event.stopPropagation()
    const {
      handleMainSectionKeyPress,
      handlePreviousItem,
      handleNextItem,
      setIsItemWithModifierAddedToCart
    } = this.props

    setIsItemWithModifierAddedToCart(false)

    switch (event.keyCode) {
      case KEY_CODES.LEFT:
        handlePreviousItem(index);
        break
      case KEY_CODES.RIGHT:
        handleNextItem(index)
        break
      case KEY_CODES.UP:
        handleMainSectionKeyPress(event, 2)
        break
      case KEY_CODES.DOWN:
        handleMainSectionKeyPress(event, 1)
        break
      case KEY_CODES.ENTER:
        this._updateCart()
        break
      default:
        return null
    }
  }

  _handleKeyPressOnConfirmAgeButton = event => {
    switch (event.keyCode) {
      case KEY_CODES.ENTER:
        this._updateCartAndConfirmAge()
        break;
      case KEY_CODES.UP:
        this._cancelAddItem()
        break;
      default:
        break;
    }
  }

  renderItemQuantity = () => {
    const { cartLineItems , item: { id }} = this.props

    const cartItem = filter(cartLineItems, ['itemId', id])
    const itemQuantity = reduce(cartItem, (acc, item) => {
      const quantity = get(item, 'quantity')
      return acc + quantity
    }, 0)
    const renderedItem = itemQuantity === 0 ? <IconAdd /> : itemQuantity

    return renderedItem
  }

  switchImageLoaded = () => this.setState({ imageExists: false })

  render() {
    const {
      item: {
        name,
        description,
        images: { detail },
        variants: [{ priceInCents, menuItemUuid }],
        orderMaxAmount,
      },
      addedToCart,
      innerRef,
      refProp,
      isItemWithModifierAddedToCart,
      itemHeight,
    } = this.props

    const maxItemAmountReached = orderMaxAmount === this.renderItemQuantity()
    const itemOpacity = maxItemAmountReached ? "0.6" : "1"
    const truncatedName = name.length > 35 ? name.substring(0, 28).concat(" ...") : name
    const { showAgeConfirmationModal, showAlcoholWarningModal, imageExists } = this.state
    const { getUrlWithVersion } = this.context

    const itemAriaLabel = isItemWithModifierAddedToCart ? '' : clickToAddToCart(name, formatPrice(priceInCents))
    return (
      <div className="item" style={{height: `${itemHeight}`, opacity: itemOpacity }} role="button" tabIndex="0" ref={innerRef}>
        <Modal className="modal" isOpen={showAgeConfirmationModal} overlayClassName="overlay">
          <div className="modal__copy">
            You must be 21 to order alcoholic beverages. All IDs will be checked. If you cannot
            produce valid ID, your transaction will not be processed, and you will not be served.
          </div>
          <div className="modal__actions">
            <Button className="btn -outline" onClick={this._cancelAddItem} title="Cancel" />
            <Button
              refProp={this.confirmAgeButtonRef}
              className="btn -primary"
              onClick={this._updateCartAndConfirmAge}
              onKeyPress={this._handleKeyPressOnConfirmAgeButton}
              title="Confirm 21+"
              tabIndex={0}
              ariaLabel={alcoholMessage}
            />
          </div>
        </Modal>
        <AlcoholWarningModal
          refProp={this.confirmAlcoholWarningButtonRef}
          cancel={this._confirmAlcoholWarning}
          isOpen={showAlcoholWarningModal}
        />
        <label
          htmlFor={menuItemUuid}
          tabIndex={0}
          ref={refProp}
          onKeyDown={this._handleKeyPressOnItem}
          aria-label={itemAriaLabel}
        >
          <div>
            {detail && <div className="item__image-anchor" style={{ backgroundImage: `url(${getUrlWithVersion(detail)})`}}/>}
            <div className={cn('item__name', { '-no-image': !imageExists })}>{truncatedName}</div>
          </div>
          <div className="item__details">
            <div>{description}</div>
          </div>
          <div className="item__order">
            <input
              checked={addedToCart}
              className="item__checkbox"
              id={menuItemUuid}
              onChange={this._updateCart}
              type="checkbox"
            />
            <div className="item__price">{formatPrice(priceInCents)}</div>
            <div className="item__quantity">
              {this.renderItemQuantity()}
            </div>
          </div>
        </label>
      </div>
    )
  }
}
