import { useEffect, useRef } from "react";
import { Box, makeStyles } from "@material-ui/core";
import VNConcessionsNavBar from "../../components/VNConcessionsNavBar";
import { useDispatch } from "react-redux";
import { Keyboard } from "@material-ui/icons";
import { setUsingVNScanner, setVNScannerResults } from "../ActionCreators";

/**
 * 
 * @param {string} textDisplay The text displayed on on the top of the navbar, usually the balance due in cents
 * @param {Function} onKeyboardClick On the top navbar on the right side, there is a keyboard button, this is the handler. Clicking on this will also pause scanning.
 * @param {String} toolTipText Text displayed at the bottom to guide the attendant.
 * @param {Function} goBack How the go back function is handled when clicked.
 */
export const MobileScanner = ({textDisplay, onKeyboardClick, toolTipText, goBack}) => {

  const styles = useStyles()
  const dispatch = useDispatch()

  const videoContainerRef = useRef()

  const videoGui = window.VNMobileBarcodePicker.gui

  useEffect(() => {
    if (window.VNMobileBarcodePicker) {
      resumeScanning()
      window.VNMobileBarcodePicker.reassignOriginElement(videoContainerRef.current)
      window.VNMobileBarcodePicker.on('scan', (scanResult) => {
        if (scanResult.barcodes[0]) {
          dispatch(setVNScannerResults(scanResult.barcodes[0].data))
          window.VNMobileBarcodePicker.pauseScanning()
          setTimeout(() => {
            window.VNMobileBarcodePicker.resumeScanning()
          }, 1000)
        }
      })

      return () => {
        pauseScanning()
      }
    }

  }, [])

  const pauseScanning = () => {
    dispatch(setUsingVNScanner(false))
    dispatch(setVNScannerResults(null))
    window.VNMobileBarcodePicker.removeAllListeners('scan')
    window.VNMobileBarcodePicker.pauseScanning()
    window.VNMobileBarcodePicker.pauseCameraAccess()
    videoGui.videoElement.style.display = "none"

  }

  const resumeScanning = () => {
    dispatch(setUsingVNScanner(true))
    window.VNMobileBarcodePicker?.accessCamera()
    window.VNMobileBarcodePicker?.resumeScanning()
    videoGui.videoElement.style.display = "block"
  }

  const onGoBack = () => {
    pauseScanning()
    goBack()
  }

  return (
    <Box className={styles.videoContainer}>
      <VNConcessionsNavBar 
        onClick={onGoBack}
        textDisplay={textDisplay}
        rightAccessory={<Keyboard onClick={() => {onKeyboardClick(); pauseScanning()}}/>}
        showBorder={false}
      />
      <Box ref={videoContainerRef} className={styles.video}/>
      <Box className={styles.mobileScanToolTip}>
        <span className={styles.topText}>{toolTipText ? `${toolTipText}` : 'Please scan'}</span>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  videoContainer: {
    width: '100%',
    height: '100%',
  },
  video: {
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0
  },
  mobileScanToolTip: {
    position: 'absolute',
    bottom: '48px',
    zIndex: 1,
    backgroundColor: theme.custom.colors.black,
    color: theme.custom.colors.black,
    width: '50%',
    left: '25%',
    padding: '8px 12px 8px 12px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topText: {
    color: theme.custom.colors.white,
    fontSize: '4vw',
    textAlign: 'center',
    alignSelf: 'center',
  }
}))
