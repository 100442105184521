export const DEVICE_NAMES = {
  POS: 'POS',
  CFD: 'CFD'
}

export const VN_BRIDGE_SEND_METHODS = {
  VN_CFD_SCREEN_NAV: 'VN_CFD_SCREEN_NAV',
  VN_BRIDGE_PASSTHROUGH: 'VN_BRIDGE_PASSTHROUGH',
  VN_UPDATE_TRANSACTION_LIFECYCLE: 'VN_UPDATE_TRANSACTION_LIFECYCLE',
  VN_SET_CURRENT_ORDER: 'VN_SET_CURRENT_ORDER',
  VN_SET_CURRENT_PAYMENT_FLOW: 'VN_SET_CURRENT_PAYMENT_FLOW',
  VN_UPDATE_CFD_VERSION: 'VN_UPDATE_CFD_VERSION',
  VN_ACKNOWLEDGEMENT: 'VN_ACKNOWLEDGEMENT'
}

export const VN_BRIDGE_RECEIVE_METHODS = {
  VN_BRIDGE_PASSTHROUGH: 'VN_BRIDGE_PASSTHROUGH',
  VN_UPDATE_TRANSACTION_LIFECYCLE: 'VN_UPDATE_TRANSACTION_LIFECYCLE',
  VN_SET_CURRENT_ORDER: 'VN_SET_CURRENT_ORDER',
  VN_SET_CURRENT_PAYMENT_FLOW: 'VN_SET_CURRENT_PAYMENT_FLOW',
  VN_UPDATE_CFD_VERSION: 'VN_UPDATE_CFD_VERSION',
  VN_ACKNOWLEDGEMENT: 'VN_ACKNOWLEDGEMENT'
}