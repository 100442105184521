export const getAndroidSDKDataReceived = (state) => {
  return state.VNAndroidSDK.get('latestReceived')
}

export const getAndroidSDKVersion = (state) => {
  return state.VNAndroidSDK.get('version')
}

export const getIsKeyboardActive = (state) => {
  return state.VNAndroidSDK.get('isKeyboardActive')
}