import * as ActionTypes from './ActionTypes'

/**
 * Open the Dialog (popup) that shows the 
 * @param {Boolean} open - True - opens it, False, closes it
 */
export function setConfirmPaymentDialog(open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CONFIRM_PAYMENT_READY,
      dialog: open
    })
  }
}

/**
 * Open the Dialog (popup) that shows an 
 * @param {Object} dialogOptions - True - opens it, False, closes it
 */
export function setOpenCustomerPaymentDialog(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CUSTOMER_PAYMENT,
      dialog: dialogOptions,
    })
  }
}

/**
 * Open the Dialog (popup) that shows an 
 * @param {Boolean} open - True - opens it, False, closes it
 */
export function setCustomerViewingReceiptDialog(open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CUSTOMER_VIEWING_RECEIPT,
      dialog: open
    })
  }
}

/**
 * Open the Dialog (popup) that shows an 
 * @param {Object} dialogOptions - True - opens it, False, closes it
 */
export function setCustomerPayingWithCard(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CUSTOMER_PAYING_WITH_CARD,
      dialog: dialogOptions,
    })
  }
}

/**
 * Open the Dialog (popup) that shows an 
 * @param {Boolean} open - True - opens it, False, closes it
 */
export function setTransactionCompleteDialog(open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_TRANSACTION_COMPLETE,
      dialog: open
    })
  }
}

/**
 * @param {Object} dialogOptions the options required for the dialog
 * dialogOptions = {
 *  open: Boolean,
 *  subTitle: JSX.Element or String,
 * }
 */
export function setTransactionErrorDialog(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_TRANSACTION_ERROR,
      dialog: dialogOptions,
    })
  }
}

/**
 * @param {Object} dialogOptions the options required for the dialog
 * dialogOptions = {
 *  open: Boolean,
 *  amount: Number,
 *  tipsEnabled: Boolean
 * }
 */
export function setOpenCustomerEnteringTipDialog(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CUSTOMER_ENTERING_TIP,
      dialog: dialogOptions,
    })
  }
}

/**
 * @param {Object} dialogOptions the options required for the dialog
 * dialogOptions = {
 *  open: Boolean,
 *  amount: String
 * }
 */
export function setCardAuthStepOne(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CARD_AUTH_STEP_ONE,
      dialog: dialogOptions,
    })
  }
}

/**
 * @param {Object} dialogOptions the options required for the dialog
 * dialogOptions = {
 *  open: Boolean,
 *  amount: String
 * }
 */
export function setCardAuthStepTwo(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_CARD_AUTH_STEP_TWO,
      dialog: dialogOptions,
    })
  }
}

/**
 * @param {Boolean} open whether to open or close the dialog
 */
export function setKitchenPrintErrorDialogOpen(open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNDIALOGS_KITCHEN_PRINTER_ERROR_DIALOG,
      dialog: open,
    })
  }
}