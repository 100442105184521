export const GET_CAROUSEL_IMAGES = 'GET_CAROUSEL_IMAGES'
export const GET_CAROUSEL_IMAGES_SUCCESS = 'GET_CAROUSEL_IMAGES_SUCCESS'

export const getCarouselImages = () => ({
  type: GET_CAROUSEL_IMAGES
})

export const getCarouselImagesSuccess = (payload) => ({
  type: GET_CAROUSEL_IMAGES_SUCCESS,
  payload,
})
