import { connect } from 'react-redux'
import { getItems } from '../selectors/items'
import { getSearchQuery, getHighlightedIds, getQuickSearchFlag } from '../selectors/search'
import { updateHighlightedItems, updateFocusedItemId, updateSelectedCategory, updateUseQuickSearchFlag } from '../actions/search'

import QuickMenuSearch from '../components/QuickMenuSearch'
import ItemsViewModel from '../viewmodels/ItemsViewModel'

const mapStateToProps = (state) => {
  const menuItems = ItemsViewModel(getItems(state))
  const highlightedIds = getHighlightedIds(state)
  const query = getSearchQuery(state)
  const useQuickSearchFlag = getQuickSearchFlag(state)
  return { menuItems, highlightedIds, query, useQuickSearchFlag }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHighlightedIds: (highlightedIds) => dispatch(updateHighlightedItems(highlightedIds)),
    setFocusedItemId: (itemId) => dispatch(updateFocusedItemId(itemId)),
    setSelectedCategory: (selectedCategory) => dispatch(updateSelectedCategory(selectedCategory)),
    updateUseQuickSearchFlag: (useQuickSearchFlag) => dispatch(updateUseQuickSearchFlag(useQuickSearchFlag))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickMenuSearch)
