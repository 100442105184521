import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'

// import analytics from '../utils/analytics'

import { getCurrentStand } from '../../selectors/menus'

import IconExit from '../components/icons/IconExit'
import { menuMessage } from '../utils/messages'

export class Menu extends Component {
  static propTypes = {
    standName: PropTypes.string.isRequired,
    redirectTo: PropTypes.string,
    mainSectionKeyPress: PropTypes.func,
  }

  static defaultProps = {
    redirectTo: '/kiosk/attract_loop'
  }

  welcomeMessage = () => {
      return `${this.props.standName}. Use the Right and Left Arrow keys to move to the next and previous items. Use the Up and Down Arrow keys to move to the next and previous sections. Use the Middle key to activate the current item.`;
  }

  goBackToAttractLoop = () => {
    const { history, redirectTo } = this.props
    history.push(redirectTo)
  }

  render() {
    const { standName, redirectTo, handleMenuClick, refPropTitle, refPropExit, onTitleKeyPress, onExitKeyPress } = this.props
    return (
      <Fragment>
        <button
          onClick={this.goBackToAttractLoop}
          type="button"
          className="menu__exit"
          to={redirectTo}
          tabIndex={0}
          ref={refPropExit}
          onKeyDown={onExitKeyPress}
        >
          <IconExit className="menu__icon" />
          Exit
        </button>
        <div className="menu__top">
          <button
            className="menu__brand-btn"
            onClick={handleMenuClick}
            aria-label={menuMessage(standName)}
            onKeyDown={onTitleKeyPress}
            ref={refPropTitle}
          >
            <div className="menu__brand">{standName}</div>
          </button>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  standName: getCurrentStand(state)?.name ?? '',
})

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Menu)
