import add_should_refresh_config from './v0_add_should_refresh_config.js'
import partition_refund_ids from './v1_partition_refund_ids.js'
import add_mode_configuration from './v2_add_mode_configuration.js'
import add_revenue_center_tree from './v3_add_revenue_center_tree.js'
import peripheral_connected_default_true from './v4_peripheral_connected_default_true.js'

const migrations = {
  ...add_should_refresh_config,
  ...partition_refund_ids,
  ...add_revenue_center_tree,
  ...add_mode_configuration,
  ...peripheral_connected_default_true,
}

export default migrations
