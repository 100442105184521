import { call, put, select, takeEvery } from 'redux-saga/effects'
import { isEmpty, some, partition } from 'lodash'

import Remote from '../remote'

import {
  fetchRemoteOrderTotal,
  fetchRemoteOrderTotalSucceeded,
  fetchRemoteOrderTotalFailed,
} from '../actions/orderTotalRemote'

import { getCartItems } from '../selectors/cart'
import { getIsKiosk, makeGetCurrentMenu } from '../selectors/menus'
import { getSelectedPromotions } from '../selectors/promotion'

import { makeLineItemData, parseModifiers } from '../utils/orderUtils'
import { centsToDollars } from '../utils/formatters'
import { ToastManager } from '../utils/toastManager'

import { addPromotionsToOrder } from '../actions/promotion'
import { APPLICATION_STRINGS } from '../strings'

const getCurrentMenu = makeGetCurrentMenu()

function* fetchRemoteOrderTotalSaga(params) {

  const items = yield select(getCartItems)

  const itemModels = makeLineItemData(items) ?? []
  const menu = yield select(getCurrentMenu)
  const isKiosk = yield select(state => getIsKiosk(state))
  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions)) {
    promotions = undefined
  }

  const orderMenuItems = itemModels.map((lineItem) => {
    return {
      menuItemUuid: lineItem?.menuItemUuid ?? lineItem?.id ?? '',
      quantity: lineItem.quantity,
      modifiers: parseModifiers(lineItem.modifiers, 1),
      price: centsToDollars(lineItem.priceInCents).slice(1),
      priceInCents: lineItem.priceInCents,
    }
  })

  try {
    const result = yield call(Remote.getRemoteOrderTotal, orderMenuItems, menu?.uuid, promotions, isKiosk)
    const [applied, unApplied] = partition(result?.data?.promotions, (promotion) => promotion.applied || promotion.applies)
    if (!isEmpty(unApplied) && some(unApplied, (promotion) => promotion.redemptionMethod === 'manual')) {
      ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
    }
    yield put(addPromotionsToOrder({ selectedPromotions: applied }))
    yield put(fetchRemoteOrderTotalSucceeded({ result }))
  } catch (err) {
    yield put(fetchRemoteOrderTotalFailed(err))
  }
}

export function* watchFetchRemoteOrderTotalSaga() {
  yield takeEvery(fetchRemoteOrderTotal.type, fetchRemoteOrderTotalSaga)
}
