import { head } from 'lodash'

import quantityValidator from './quantityValidator'

const updateQuantity = (min, max, validationType, currentRecords, selectedRecord) => {
  if (validationType === quantityValidator.types.required) {
    return head(currentRecords) === selectedRecord ? currentRecords : [selectedRecord]
  }

  const set = new Set(currentRecords)
  const recordNotSelected = set.has(selectedRecord) === false
  const willExceedMax = max !== null && max < set.size + 1

  if (recordNotSelected && willExceedMax) {
    return currentRecords
  }

  const action = recordNotSelected ? 'add' : 'delete'

  set[action](selectedRecord)
  return [...set]
}

const quantityUpdater = (min, max, currentRecords, selectedRecord) => {
  const validator = quantityValidator(min, max)

  return updateQuantity(min, max, validator.validationType, currentRecords, selectedRecord)
}

export default quantityUpdater
