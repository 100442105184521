import { get, keyBy } from 'lodash'

import {
  FETCH_ATTENDANTS,
  FETCH_ATTENDANTS_SUCCEEDED,
  FETCH_ATTENDANTS_FAILED,
  ATTEMPT_PIN_LOGIN,
  ATTEMPT_PIN_LOGIN_FAILED,
  ATTEMPT_PIN_LOGIN_SUCCEEDED,
  SET_ACTIVE_ATTENDANT_ID,
  SET_MANAGER_ID,
  CLEAR_ATTEMPT_PIN_LOGIN_STATUS,
  CLEAR_FETCH_ATTENDANTS_STATUS,
} from '../actions/attendant'

const initialState = {
  byId: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {

    case FETCH_ATTENDANTS: {
      return {
        ...state,
        fetchAttendantsStatus: 'loading',
      }
    }

    case FETCH_ATTENDANTS_SUCCEEDED: {
      const attendants = action?.payload ?? []

      return {
        ...state,
        byId: keyBy(attendants, 'uuid'),
        fetchAttendantsStatus: 'succeeded',
      }
    }

    case FETCH_ATTENDANTS_FAILED: {
      const errorMessage = get(action, 'payload.response.data.errorMessage', 'An Error Occurred')

      return {
        ...state,
        fetchAttendantsStatus: 'failed',
        errorMessage,
      }
    }

    case ATTEMPT_PIN_LOGIN:
      return {
        ...state,
        attemptPinLoginStatus: 'loading',
        errorMessage: undefined,
      }

    case ATTEMPT_PIN_LOGIN_FAILED:
      return {
        ...state,
        attemptPinLoginStatus: 'failed',
      }

    case ATTEMPT_PIN_LOGIN_SUCCEEDED:
      return {
        ...state,
        attemptPinLoginStatus: 'succeeded',
      }

    case CLEAR_ATTEMPT_PIN_LOGIN_STATUS:
      return {
        ...state,
        attemptPinLoginStatus: undefined,
      }

    case CLEAR_FETCH_ATTENDANTS_STATUS:
      return {
        ...state,
        fetchAttendantsStatus: undefined,
        errorMessage: undefined,
      }

    case SET_ACTIVE_ATTENDANT_ID:
      return {
        ...state,
        activeAttendantId: get(action, 'payload.attendantId'),
      }

    case SET_MANAGER_ID:
      return {
        ...state,
        managerId: get(action, 'payload.managerId'),
      }

    default:
      return state
  }
}
