import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import styles from './NotAuthorized.module.scss'

const NotAuthorized = ({ className }) => {
  const classNames = cn(styles.notAuthorized, className)

  return (
    <div className={classNames}>
      <div className={styles.container}>
        <h1>Authorization Failed</h1>
        <p>This device is not authorized to connect to the system.</p>
        <span className={styles.dataPoint}><span className={styles.dataPointLabel}>Device ID: </span><span className={styles.dataPointValue}>{localStorage.getItem('device_id') || 'unknown'}</span></span>
        <span className={styles.dataPoint}><span className={styles.dataPointLabel}>Version: </span><span className={styles.dataPointValue}>{process.env.REACT_APP_VERSION || 'unknown'}</span></span>
        <span className={styles.dataPoint}><span className={styles.dataPointLabel}>Organization: </span><span className={styles.dataPointValue}>{process.env.REACT_APP_ORG || 'unknown'}</span></span>
      </div>
      <Link to={'/device-login'} className={styles.doneButton}>Try Again</Link>
    </div>
  )
}

export default NotAuthorized
