import { last, pick, isEmpty } from 'lodash'

import PreOrdersItemViewModel from '../viewmodels/PreOrdersItemViewModel'

import { stringFloatToCents } from '../utils/formatters'
import { formatOrderDetails, makeClientPromotionParams } from '../utils/orderTotalUtils'

import PaymentTypes from '../utils/paymentTypes'

import { backendOrderModelKeys } from './order/attributes'
import { STADIUM_ORDER_STATES, isTabbed } from '../utils/orderStates'

// TODO - split payments?
const getPaymentData = (order) => {
  const paymentInfo = order?.paymentInfo ?? []

  return last(paymentInfo) || {}
}

const formatPaymentInfo = (order, paymentData) => {
  if (PaymentTypes.isCardRequired(order)) return 'Card Required'

  let { paymentType, shortDescription } = paymentData

  if (PaymentTypes.isMobileWalletPayment(paymentType)) shortDescription = shortDescription ?? 'Mobile Pay'

  if (PaymentTypes.isCash(paymentType)) return 'Cash'
  // NOTE (westinschepper): This is temporary
  if (PaymentTypes.isDirectBill(paymentType)) return 'Payment Pending'
  if (PaymentTypes.isHouseAccount(paymentType)) return 'House Account'
  if (PaymentTypes.isFnbCredit(paymentType)) return 'F&B Credit'

  if (shortDescription) return shortDescription

  return paymentType
}

const isRemotePayment = (paymentData) => {
  const { paymentType } = paymentData

  return PaymentTypes.isRemotePayment(paymentType)
}

const separateLineItemsAndFees = (allLineItems = []) => {
  let lineItems = []
  let serviceChargeLineItem, taxLineItem = undefined

  allLineItems.forEach((li) => {
    if (!li.itemSpecialType) {
      lineItems.push(li)
    } else if (li.itemSpecialType === 'service_charge' || li.itemSpecialType === 'taxable_service_charge') {
      serviceChargeLineItem = li
    } else if (li.itemSpecialType === 'tax') {
      taxLineItem = li
    } else {
      li.modifiersString = '+' + li.modifiers[0]?.length + ' Modifiers'
    }
  })

  return { lineItems, serviceChargeLineItem, taxLineItem }
}

const parseOrderPricing = (order, lineItems, serviceChargeLineItem, taxLineItem, tipAmountInCents) => {
  const discountString = order?.discountAmountInCents ?? '0' // don't be fooled, it's a string
  const totalString = order?.total ?? '0.00'
  const resolvedLineItems = lineItems
  const scFraction = serviceChargeLineItem?.fractionalQuantity ?? 1

  if (serviceChargeLineItem !== undefined) {
    resolvedLineItems.push(serviceChargeLineItem)
  }

  return formatOrderDetails({
    discountAmountInCents: parseInt(discountString),
    lineItems: resolvedLineItems,
    promotions: order.promotions,
    serviceChargeInCents: scFraction * stringFloatToCents(serviceChargeLineItem?.price ?? '0.0'),
    taxAmountInCents: stringFloatToCents(taxLineItem?.price ?? '0.0'),
    tipAmountInCents,
    totalAmountInCents: stringFloatToCents(totalString),
    updatedAt: order.updatedAt,
  })
}

const mapPayments = (paymentInfo, orderNumber) => {
  //payment schema
  return { 
    paymentId: paymentInfo.uuid,
    paymentType: paymentInfo.paymentType,
    amountInCents: paymentInfo.amountInCents,
    orderNumber,
    shortDescription: paymentInfo.shortDescription,
    userAttributes: paymentInfo.userAttributes,
    userName: "", //not present in stadium response
    cardType: "", //not present in stadium response
    label: "", //not present in stadium response
  }
}

export const mapOrderDetails = (backendOrder = {}, items = {}) => {
  let order = pick(backendOrder, backendOrderModelKeys)

  const { lineItems, serviceChargeLineItem, taxLineItem } = separateLineItemsAndFees(order?.lineItems ?? [])
  const paymentData = getPaymentData(order)
  const safeOrderTotal = order?.total ?? ''
  const totalAmountInCents = parseInt(safeOrderTotal.replace('.', ''))
  const tipAmountInCents = stringFloatToCents(order?.tip ?? '0.00')
  const balanceDueInCents = [ STADIUM_ORDER_STATES.COMPLETED, STADIUM_ORDER_STATES.COMPLETION_PENDING, STADIUM_ORDER_STATES.APPROVED_OFFLINE ].includes(order.state) ? 0 : totalAmountInCents
  const subtotalAmountInCents = stringFloatToCents(order?.subtotal ?? '0.00')
  const discountAmountInCents = stringFloatToCents(order?.discountAmountInCents)
  const serviceFee = serviceChargeLineItem
    ? {
        name: serviceChargeLineItem?.name,
        total: stringFloatToCents(serviceChargeLineItem?.price || '0.00')
      }
    : undefined;

  // The full JSON of the submission result does not need to be stored; only the order number.
  order.orderNumber = backendOrder?.orderSubmissionResult?.orderNumber ?? 'unknown'

  // Only a subset of the promotion is needed for follow-on totaling.
  order.promotions = makeClientPromotionParams(order.promotions)

  order.revenueName = order?.standName
  order.amountInCents = totalAmountInCents
  order.balanceDueInCents = balanceDueInCents

  /**
   * Currently Stadium is returning the subtotal including the service fee so
   * we need to subtract it from the subtotalAmountInCents
   */
  order.subtotalAmountInCents = subtotalAmountInCents - (serviceFee?.total || 0)

  order.discountAmountInCents = discountAmountInCents
  order.tipAmountInCents = tipAmountInCents
  order.isOnline = true
  order.isRemotePayment = isRemotePayment(paymentData)
  order.isSyncing = false
  order.modifiedAt = order.updatedAt
  order.frozen = !!order.frozen
  order.paymentDisplay = formatPaymentInfo(order, paymentData)
  order.submittedAt = order.createdAt
  order.syncedAt = order.updatedAt
  order.customerPaymentId = paymentData.customerPaymentId
  order.wasCreatedInStadium = true
  order.serviceFee = serviceFee
  order.taxAmountInCents = stringFloatToCents(taxLineItem?.price || '0.00')
  order.payments = order.paymentInfo.map((payment) => mapPayments(payment, order.orderNumber))

  if (!isEmpty(lineItems)) {
    order.itemModels = lineItems.map(lineItem => {
      const itemModel = PreOrdersItemViewModel(lineItem)
      const item = items[lineItem.menuItemUuid]

      /* Adding the variant from the local items state since it's not returned from stadium...
      Fallback to an emtpy productSku in case it's not found so it will not crash the app in PPI processing */
      itemModel.variant = item.variants?.length ? item.variants[0] : { productSku: ""}
    return itemModel
    })
    order.orderTotalFromOrder = parseOrderPricing(order, lineItems, serviceChargeLineItem, taxLineItem, tipAmountInCents)
  }

  if (isTabbed(order)) {
    order.tabName = order.userName
  }

  return order
}

export const mapOrder = (backendOrder = {}) => {
  let order = pick(backendOrder, backendOrderModelKeys)

  const paymentData = getPaymentData(order)
  const safeOrderTotal = order?.total ?? ''
  const totalAmountInCents = parseInt(safeOrderTotal.replace('.', ''))
  const balanceDueInCents = [ STADIUM_ORDER_STATES.COMPLETED, STADIUM_ORDER_STATES.COMPLETION_PENDING, STADIUM_ORDER_STATES.APPROVED_OFFLINE].includes(order.state) ? 0 : totalAmountInCents

  // The full JSON of the submission result does not need to be stored; only the order number.
  order.orderNumber = backendOrder?.orderSubmissionResult?.orderNumber ?? 'unknown'

  order.amountInCents = totalAmountInCents
  order.balanceDueInCents = balanceDueInCents
  order.isOnline = true
  order.isRemotePayment = isRemotePayment(paymentData)
  order.isSyncing = false
  order.modifiedAt = order.updatedAt
  order.paymentDisplay = formatPaymentInfo(order, paymentData)
  order.submittedAt = order.createdAt
  order.syncedAt = order.updatedAt
  order.customerPaymentId = paymentData.customerPaymentId
  order.wasCreatedInStadium = true
  order.payments = order.paymentInfo.map((payment) => mapPayments(payment, order.orderNumber))

  if (isTabbed(order)) {
    order.tabName = order.userName
  }

  return order
}