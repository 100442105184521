import * as ActionTypes from './ActionTypes'

/**
 * if the device is not going to be a CFD DONT USE.
 * @param {String} mode CFD | POS | UNKNOWN Modes are inside of the reducer. 
 */
export function setDeviceMode(mode) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMODE_SET_MODE,
      mode: mode
    })
  }
}

/**
 * If the device is in summit mode
 * @param {boolean} mode true or false
 */
export function setSummitMode(mode) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMODE_SET_SUMMIT_MODE,
      mode: mode
    })
  }
}

/**
 * Set the device ID into state
 * @param {String} id - the Device ID from Android
 * @returns 
 */
export function setDeviceId(id) {
  return (dispatch) => {

    window.localStorage.setItem('device_id', id)

    dispatch({
      type: ActionTypes.VNMODE_SET_DEVICE_ID,
      id: id
    })
  }
}

/**
 * Set the auth tokens into state
 * @param {String} token - The auth token given by Portico
 * @param {String} expiresAt - When the auth token expires
 * @returns 
 */
export function setPorticoAuthTokens(accessToken, expiresAt) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMODE_SET_PORTICO_AUTH_TOKENS,
      data: {
        accessToken: accessToken,
        expiresAt: expiresAt
      }
    })
  }
}
