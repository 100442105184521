import { get } from 'lodash'
import { decamelizeKeys } from 'humps'

import { instance } from './index'

import { store } from '../stores'
import { getActiveAttendantId } from '../selectors/attendant'

const generateUuid = require('uuid/v4')

export const MUTATION_TYPES = {
  identity: 'Order::Mutation::Identity',
  addLineItems: 'Order::Mutation::AddLineItems',
  addPayment: 'Order::Mutation::AddPayment',
  updatePayment: 'Order::Mutation::UpdatePayment',
  authorize: 'Order::Mutation::Authorize',
  cancel: 'Order::Mutation::Cancel',
  close: 'Order::Mutation::Close',
  combine: 'Order::Mutation::Combine',
  create: 'Order::Mutation::Create',
  refund: 'Order::Mutation::Refund',
  split: 'Order::Mutation::Split',
  stateChange: 'Order::Mutation::StateChange',
  transfer: 'Order::Mutation::Transfer',
  updateLineItems: 'Order::Mutation::UpdateLineItems',
  updateTotals: 'Order::Mutation::UpdateTotals',
  updatePromotions: 'Order::Mutation::UpdatePromotions',
  reopenOrder: 'Order::Mutation::Reopen',
  printOrder: 'Order::Mutation::Print',
  removePayment: 'Order::Mutation::RemovePayment',
}

const formatMutation = (mutation = {}) => {
  const activeAttendantId = getActiveAttendantId(store.getState())

  return {
    mutationPayload: mutation,
    objectUuid: mutation.orderUuid,
    orderUuid: mutation.orderUuid,
    reason: get(mutation, 'reason', 'from a POS'),
    sweep: get(mutation, 'sweep', false), // have it run right away so we get err responses
    type: mutation.type,
    uuid: generateUuid(),
    creatorId: activeAttendantId,
    approverId: activeAttendantId,
  }
}

const sendMutations = (mutations = []) => {
  const payload = decamelizeKeys({
    orderMutations: mutations,
  })

  return instance.post('/external/order_mutations', payload)
}

class MutationApi {

  static removePayment = ({orderId, paymentId}) => {
    if (!paymentId) {
      return Promise.resolve({
        error: "Payment ID cannot be empty",
      })
    }
    
    const mutation = formatMutation({
      orderUuid: orderId,
      paymentUuid: paymentId,
      type: MUTATION_TYPES.removePayment,
    })

    return sendMutations([ mutation ])
  }

  static addLineItems = (orderUuid, orderMenuItems, userNotes) => {
    const mutation = formatMutation({
      orderMenuItems,
      orderUuid,
      userNotes,
      type: MUTATION_TYPES.addLineItems,
    })

    return sendMutations([ mutation ])
  }

  static updateTotals = ({ orderUuid, promotions }) => {
    const mutation = formatMutation({
      orderUuid,
      onOrderCreation: true,
      promotions,
      taxExempt: false,
      addServiceCharge: true,
      excludeServiceCharges: false,
      type: MUTATION_TYPES.updateTotals,
    })

    return sendMutations([ mutation ])
  }

  static adjustLineItemQuantities = (orderUuid, lineItemsToAdd, lineItemsToRemove) => {
    let mutations = []

    if (lineItemsToAdd.length) {
      mutations.push(formatMutation({
        orderMenuItems: lineItemsToAdd,
        orderUuid,
        type: MUTATION_TYPES.addLineItems,
      }))
    }

    // TODO - we don't support removing at the moment. needs additional research when we do.
    // does this endpoint remove line items or put them in the refund app?
    // for restock orders do we change the quantity to 0 instead of removing?
    if (lineItemsToRemove.length) {
      mutations.push(formatMutation({
        lineItemUuids: lineItemsToRemove,
        orderUuid,
        reason: 'line item quantity adjustment from the POS',
        type: MUTATION_TYPES.refund,
      }))
    }

    return sendMutations(mutations)
  }

  static updateLineItems = (orderUuid, orderMenuItems) => {
    const mutation = formatMutation({
      orderMenuItems,
      orderUuid,
      type: MUTATION_TYPES.updateLineItems,
    })

    return sendMutations([ mutation ])
  }

  static reopenOrder = (orderUuid) => {
    const mutation = formatMutation({
      orderUuid,
      type: MUTATION_TYPES.reopenOrder,
    })

    return sendMutations([ mutation ])
  }

  static closeTabOrder = ({ exemptServiceFee = false, orderId, receiptSignature, taxExempt = false, tipAmountInCents, payments }) => {
    let mutationAttributes = {
      exemptServiceFee,
      orderUuid: orderId,
      receiptSignature,
      taxExempt,
      tipAmountInCents,
      payments,
      type: MUTATION_TYPES.close,
    }

    const mutation = formatMutation(mutationAttributes)

    return sendMutations([ mutation ])
  }

  static closeMultiPaymentOrder = (orderId) => {
    let mutationAttributes = {
      orderUuid: orderId,
      type: MUTATION_TYPES.close,
    }

    const mutation = formatMutation(mutationAttributes)

    return sendMutations([ mutation ])
  }

  static refundOrderItems = (orderUuid, reason, lineItemUuids, consumed) => {
    const mutation = formatMutation({
      lineItemUuids,
      orderUuid,
      reason,
      consumed,
      sweep: false, // this is what makes it show in the refund app!
      type: MUTATION_TYPES.refund,
      userDetails: {},
    })

    return sendMutations([ mutation ])
  }

  static splitOrder = ({ lineItemUuids, newOrderNumber, newOrderUuid, originalOrderUuid, payments, splittableAmount, userAttributes, userName, isFractionalSplit }) => {
    const mutationPayload = {
      lineItemUuids,
      orderUuid: originalOrderUuid,
      newOrderUuid,
      newOrderNumber,
      payments,
      amountInCents: splittableAmount,
      reason: `Split from order uuid ${originalOrderUuid} on a POS`,
      sweep: false,
      type: MUTATION_TYPES.split,
      userAttributes,
      userName,
    }

    if (isFractionalSplit) {
      mutationPayload.splitType = 'fractional'
    }

    const splitMutation = formatMutation(mutationPayload)

    const mutations = [ splitMutation ]

    return sendMutations(mutations)
  }

  static updatePayment = ({ orderId, paymentUuid, tip, amountInCents, shortDescription, signature }) => {
    let mutationAttributes = {
      orderUuid: orderId,
      paymentUuid,
      tip,
      amountInCents,
      shortDescription,
      reason: `updating payment for payment ${paymentUuid} of order ${orderId}`,
      sweep: false,
      type: MUTATION_TYPES.updatePayment,
    }

    const mutation = formatMutation(mutationAttributes)

    return sendMutations([mutation])
  }

  static addPayment = ({ orderId, payment, userAttributes, userName }) => {
    let mutationAttributes = {
      orderUuid: orderId,
      payment,
      reason: `Updating payments for ${orderId}`,
      sweep: false,
      type: MUTATION_TYPES.addPayment,
    }

    if (userAttributes) mutationAttributes = { ...mutationAttributes, userAttributes }
    if (userName) mutationAttributes = { ...mutationAttributes, userName }

    const mutation = formatMutation(mutationAttributes)

    return sendMutations([mutation])
  }

  static combineOrders = (destinationOrderUuid, combinedOrderUuid) => {
    const mutation = formatMutation({
      combinedOrderUuid,
      orderUuid: destinationOrderUuid,
      type: MUTATION_TYPES.combine,
    })

    return sendMutations([ mutation ])
  }

  static transferOrder = ({ orderUuid, customerId, payments, userAttributes, userName, promotions }) => {
    let mutationAttributes = {
      orderUuid,
      customerId,
      promotions,
      type: MUTATION_TYPES.transfer,
    }

    if (payments) mutationAttributes = { ...mutationAttributes, payments }
    if (userAttributes) mutationAttributes = { ...mutationAttributes, userAttributes }
    if (userName) mutationAttributes = { ...mutationAttributes, userName }

    const mutation = formatMutation(mutationAttributes)

    return sendMutations([ mutation ])
  }

  static printOrder = ({ orderUuid, printType, printerUuids, paymentUuid }) => {
    const mutation = formatMutation({
      orderUuid,
      printType,
      printerUuids,
      paymentUuid,
      type: MUTATION_TYPES.printOrder,
    })

    return sendMutations([ mutation ])
  }
}

export default MutationApi
