import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import generateUuid from 'uuid/v4'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty, map, reduce } from 'lodash'

import useManagerApproval from '../../../context/hooks/useManagerApproval'

import GratuityHeader from '../../../components/GratuityHeader'
import ConnectingIndicator from '../../../components/ConnectingIndicator'
import useRemoteOrderTotal from '../../../hooks/useRemoteOrderTotal'
import { ReactComponent as RadioSelected } from '../../../assets/icons/radio-selected.svg'
import { ReactComponent as RadioUnSelected } from '../../../assets/icons/radio-unselected.svg'
import { addPaymentToOrderInProgress, addTipOrderInProgress, clearOrderInProgress, closeNoOpTender, syncAndSaveOrderInProgress, restoreQRPaymentandUnsyncableStatus, startOnlineCardOrder } from '../../../actions/order'
import { getCashEnabled, getGiftCardEnabled, getMobilePaySetting, getTicketEnabled, makeGetNoOpTenders } from '../../../selectors/revenueCenter'
import { getTabsModeEnabled, getTipsEnabled, makeGetCurrentMenu } from '../../../selectors/menus'
import { getOrderInProgress } from '../../../selectors/order'
import { getTaxByItem, getTaxRate, getTotal, getCartItems } from '../../../selectors/cart'
import { centsToDollars } from '../../../utils/formatters'
import { tenderSelectionIcons } from '../../../utils/icons'
import { SIGNATURE_THRESHOLD_AMOUNT } from '../../../constants'
import styles from './TenderSelect.module.scss'
import ConfirmModalV2 from '../../../components/ConfirmModalV2'
import useOfflineModal from '../../../hooks/useOfflineModal'
import { getDeviceMode } from "../../../VNMode/Selectors"
import { MODES } from "../../../VNMode/Reducer"

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { CFD_SCREEN } from '../../../VNCustomerFacingDisplay/Reducer'
import { calculateCartTotals } from '../../../utils/orderTotalUtils'
import { getMenuServiceCharge } from '../../../selectors/items'
import { getCurrentCart, getCurrentPaymentFlow, getPaymentProgress } from '../../../VNCustomerFacingDisplay/Selectors'
import useCreateOrderInProgress from '../../../hooks/useCreateOrderInProgress'
import { bridge_hijackedExecuteSalesRequest, sendCFDScreenNav } from '../../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_POS_PAYMENT_FLOWS } from '../../../VNCustomerFacingDisplay/Enums'
import { setConfirmPaymentDialog, setCustomerPayingWithCard, setOpenCustomerPaymentDialog } from '../../../VNDialogs/ActionCreators'
import { resetCFD, setCurrentPaymentFlow, setUserProgress, updateCurrentOrder } from '../../../VNCustomerFacingDisplay/ActionCreators'
import { getCustomerPaymentDialogOpen } from '../../../VNDialogs/Selectors'
import { nextPosOrderNumber } from '../../../utils/orderNumbers'
import VNConcessionsNavBar from '../../../components/VNConcessionsNavBar'
import useGetIsMobileScreen from '../../../hooks/useGetIsMobileScreen'
import VNBottomButton from '../../../components/VNBottomButton'
import { INTERNAL_ORDER_STATES, isTabbed } from '../../../utils/orderStates'
import { getAndroidSDKVersion } from '../../../VNAndroidSDK/Selectors'
import { isSDKCompatible } from '../../../VNAndroidSDK/Utils'
import { clearCart } from '../../../actions/cart'
import { getIsPreAuthTab } from '../../../utils/orderUtils'
import { setBridgePaymentMethod } from '../../../actions/peripheral'
import { bridgePaymentMethods } from '../../../reducers/peripheral'
import { PAYMENT_TYPES_INTERNAL } from '../../../utils/paymentTypes'
import useSpinner from '../../../context/hooks/useSpinner'
import { clearPromotionsOnOrder } from '../../../actions/promotion'

const PAYMENT_TYPES = {
  CARD: 'card',
  CASH: 'cash',
  TICKET: 'loaded_ticket',
  VC: 'mobile_wallet',
  QRPAY: 'wallet_nonce',
  GIFT_CARD: 'gift_card',
  SPLIT: 'split',
  SAVED_CARD: 'savedCard',
  CUSTOM_TENDER: 'custom_tender'
}

const cancelModalTexts = {
  'ticket': 'Canceling this transaction will return\nall funds to your tickets.',
  'gift card': 'The gift card will not be charged.',
  'qr code': 'The mobile account will not be\ncharged.',
}

const TenderSelect = ({
  history,
  addPaymentToOrder,
  className,
  closeNoOpTender,
  goBack,
  isNewOrder,
  isTabOrder,
  modalSubtext,
  offlineTotal,
  order,
  orderAmountInCents,
  paymentTypes,
  push,
  tenderAmountInCents,
  tipsEnabled,
  scanScreenType,
  cartItems,
  taxRate,
  taxByItem,
  menuServiceCharge,
  tipSuggestions,
  paymentProgress,
  cfdMode,
  fromSplit,
  fromDiscounts
}) => {

  const dispatch = useDispatch()

  // SELECTORS
  const customerPaymentDialog = useSelector(state => getCustomerPaymentDialogOpen(state))
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const classNames = cn(styles.tenderSelect, className)
  const [paymentId] = useState(isNewOrder ? '' : generateUuid())
  const [selectedPaymentType, setSelectedPaymentType] = useState('')
  const [tenderDisplayName, setTenderDisplayName] = useState('')
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [nextButtonClicked, setNextButtonClicked] = useState(false)
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, loading, success, failed }] = useRemoteOrderTotal()
  const total = isNewOrder ? (remoteOrderTotal?.totalAmountInCents ?? offlineTotal) : tenderAmountInCents
  const tenderDisplay = centsToDollars(total)
  const threshHoldExceeded = (SIGNATURE_THRESHOLD_AMOUNT || SIGNATURE_THRESHOLD_AMOUNT === 0) && total > SIGNATURE_THRESHOLD_AMOUNT
  const signatureRequired = (tipsEnabled && !order.hasTipApplied) || threshHoldExceeded
  const [checkIfOfflineWithCallback, offlineModal] = useOfflineModal()
  const [createOrder, orderCreated] = useCreateOrderInProgress()
  const currentCart = useSelector(state => getCurrentCart(state))
  const [cartTotals, setCartTotals] = useState()
  const isMobileViewPort = useGetIsMobileScreen()
  const { openManagerApproval, setManagerApprovalOnSuccess, setManagerApprovalButtonText } = useManagerApproval()

  const { stopSpinner } = useSpinner()

  let modal = null

  useEffect(() => {
    if(paymentProgress?.tipAmount && customerPaymentDialog?.get('open') && !isTabOrder) {
      createOrder()
      return
    }
  }, [paymentProgress, customerPaymentDialog])

  useEffect(() => {
    if (fromDiscounts) {
      stopSpinner()
    }

    if (isNewOrder && !isTabOrder) {
      loadRemoteOrderTotal(true)
    }
  }, [])

  useEffect(() => {
    const tipAmount = parseInt(paymentProgress?.tipAmount)
    if(paymentProgress?.tipAmount && customerPaymentDialog?.get('open') && (orderCreated || isTabOrder)) {
      dispatch(setOpenCustomerPaymentDialog({open: false}))
      dispatch(addTipOrderInProgress(tipAmount))
      if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET) {
        checkIfOfflineWithCallback(() => push('/ticket'), offlineModalMessage, true)
      } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.GIFT_CARD) {
        checkIfOfflineWithCallback(() => push('/gift-card'), offlineModalMessage, true)
      } else {
        checkIfOfflineWithCallback(() => push('/qr-pay'), offlineModalMessage, true)
        sendCFDScreenNav(CFD_SCREEN.PRESENT_QR_PAY_CODE, {cartTotals: {...cartTotals, uuid: orderInProgress.uuid}})
      }
    }
  }, [paymentProgress, customerPaymentDialog, orderCreated])

  const handleCashSelection = () => {
    if (isNewOrder) {
      push('/change-calculator')
      return
    }
    push({
      pathname: `/change-calculator/${paymentId}`,
      state: {
        tenderAmountInCents,
      }
    })
  }

  const handleCardSelection = () => {

    // if we are using a CFD with the POS
    if (cfdMode === MODES.POS) {

      // that we have an order that is a not full amount
      if (tenderAmountInCents > 0) {

        dispatch(updateCurrentOrder({ amount: tenderAmountInCents }))

        const onlineCardOrder = {
          cardReaderData: { uuid: order?.uuid },
          orderState: INTERNAL_ORDER_STATES.PREAUTH,
          tipAmountInCents: order?.tipAmountInCents
        }

        // first payment will not have an order in progress and incrementing the POS orderNumber will just overwrite
        // the POS orderNumber from concessionsCartSidebar. get first orderNumber from current cart and every subsequent
        // payment will increment the order number.
        if ((orderInProgress && 'orderNumber' in orderInProgress) || !currentCart.get('orderNumber')) {
          const orderNumber = nextPosOrderNumber()
          onlineCardOrder.cardReaderData.orderNumber = orderNumber
        } else {
          const orderNumber = currentCart?.get('orderNumber')
          onlineCardOrder.cardReaderData.orderNumber = orderNumber
        }

        // Start pre auth submission if order does not have one.
        if (!isTabOrder) {
          dispatch(startOnlineCardOrder(onlineCardOrder))
        } else {
          if (!fromSplit) {
            initializeCFDPayment(true, {paymentType: PAYMENT_TYPES_INTERNAL.CREDIT_CARD})
            return
          }
        }

        // show modal on POS
        dispatch(setCustomerPayingWithCard({ open: true, amount: tenderAmountInCents}))
        // lite up the card reader immediately
        bridge_hijackedExecuteSalesRequest(
          tenderAmountInCents,
          onlineCardOrder.cardReaderData.orderNumber,
          order?.amountInCents,
          fromSplit && order?.balanceDueInCents - tenderAmountInCents === 0, // determines if this is the last payment
          order?.uuid
        )
      } else {
        history.push( "/concession-order?payment=card")
      }
    } else {
      if (signatureRequired) {
        if (isNewOrder) {
          push('/signature')
          return
        }
        push({
          pathname: `/signature/${paymentId}`,
          state: {
            order: order,
            tenderAmountInCents,
          },
        })
        return
      }

      if (tenderAmountInCents === 0 && isTabOrder) {
        addPaymentToOrder(total, paymentId, selectedPaymentType, tenderDisplayName)
        return
      }

      push({
        pathname: `/card-reader`,
        state: {
          orderUuid: order.uuid,
          paymentId: !isNewOrder && paymentId,
          tenderAmountInCents: !isNewOrder && total,
        },
      })
    }
  }

  const handleScanScreenSelection = (scanScreenPath) => {
    if (tipsEnabled && (isNewOrder || (isTabOrder && !fromSplit))) {
      push({
        pathname: '/signature',
        state: {
          addTipThenRedirectPath: scanScreenPath,
        }
      })
      return
    }
    push(scanScreenPath)
  }

  const handleCustomTender = () => {
    if (isNewOrder || (isTabOrder && !fromSplit)) {
      if (tipsEnabled) {
        if(cfdMode === MODES.POS) {
          initializeCFDPayment(true, {paymentType: PAYMENT_TYPES_INTERNAL.CUSTOM_TENDER, displayName: tenderDisplayName, name: selectedPaymentType})
          return
        }

        push({
          pathname: `/signature`,
          state: {
            customTender: { name: selectedPaymentType, displayName: tenderDisplayName },
            tenderAmountInCents: isTabOrder && tenderAmountInCents
          },
        })
        return
      }
      if (isTabOrder) {
        addPaymentToOrder(total, paymentId, selectedPaymentType, tenderDisplayName)
        return
      }
      closeNoOpTender({ name: selectedPaymentType, displayName: tenderDisplayName })
      return
    }
    addPaymentToOrder(total, paymentId, selectedPaymentType, tenderDisplayName)
  }

  const initializeCFDPayment = (isFinalizingTip = false, tenderData = {}) => {
    const _calculatedCartValues = calculateCartTotals(remoteOrderTotal, cartItems, taxRate, taxByItem, menuServiceCharge, order, isFinalizingTip, tenderData)
    // cant use tips enabled for tender select because it returns the wrong value.
    dispatch(setOpenCustomerPaymentDialog({open: true, tipsEnabled: !isEmpty(tipSuggestions), amount: _calculatedCartValues?.total}))
    setCartTotals(_calculatedCartValues)
    dispatch(setUserProgress({})) // reset the user progress incase of a tip already existing
    sendCFDScreenNav(CFD_SCREEN.CARD_PAYMENT, { cartTotals: _calculatedCartValues, executeSalesRequest: false, tips: { enabled: !isEmpty(tipSuggestions), suggestions: tipSuggestions } })
  }

  const offlineModalMessage = isMobileViewPort
    ? "This payment tender is not supported while the device is offline"
    : "This payment tender is not supported\nwhile the device is offline"

  const offlineGiftCardModalMessage = isMobileViewPort
    ? "Gift card tender is not supported while the device is offline"
    : "Gift card tender is not supported while\n the device is offline"

  const handleNext = () => {
    switch (selectedPaymentType) {
      case PAYMENT_TYPES.SPLIT:
        checkIfOfflineWithCallback(() => dispatch(setConfirmPaymentDialog(true)), offlineModalMessage, true)
        break

      case PAYMENT_TYPES.CARD:
        handleCardSelection()
        break

      case PAYMENT_TYPES.CASH:
        handleCashSelection()
        break

      case PAYMENT_TYPES.TICKET:
        checkIfOfflineWithCallback(() => {
          if (cfdMode === MODES.POS) {
            dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.TICKET, true))
            initializeCFDPayment()
          } else {
            handleScanScreenSelection('/ticket')
          }
        }, offlineModalMessage, true)
        break

      case PAYMENT_TYPES.VC:
        // if we are using a CFD with the POS
        if (cfdMode === MODES.POS) {
          history.push("/concession-order?payment=rich_checkout")
        } else {
          push('/rich-checkout')
        }
        break

      case PAYMENT_TYPES.QRPAY:
        if (cfdMode === MODES.POS) {
          checkIfOfflineWithCallback(() => {
            // set the current payment flow so we know what is happening
            dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.QR_PAY, true))
            initializeCFDPayment()
          }, offlineModalMessage, true)
        } else {
          checkIfOfflineWithCallback(() => handleScanScreenSelection('/qr-pay'), offlineModalMessage, true)
        }
        break

      case PAYMENT_TYPES.GIFT_CARD:
        checkIfOfflineWithCallback(() => {
          if (cfdMode === MODES.POS) {
            dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.GIFT_CARD, true))
            initializeCFDPayment()
          } else {
            handleScanScreenSelection('/gift-card')
          }
        }, offlineGiftCardModalMessage, true)
        break

      case PAYMENT_TYPES.SAVED_CARD:
        if (tipsEnabled) {
          if (cfdMode === MODES.POS) {
            initializeCFDPayment(true)
            return
          }
          push({
            pathname: `/signature`,
            state: {
              closeWithSavedCard: true,
              tenderAmountInCents,
            },
          })
          return
        }
        if (cfdMode === MODES.POS) {
          initializeCFDPayment(true)
          return
        }

        dispatch(syncAndSaveOrderInProgress())
        break

      default:
        // need to disable done button if finishing a payment with custom tender
        setNextButtonClicked(true)
        handleCustomTender()
    }
  }

  const onGoBack = () => {
    if (cfdMode === MODES.POS) {
      dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true))
      dispatch(resetCFD(false))
    }

    //if user came from the discounts screen order and cart are cleared in that screen
    if (isTabOrder && !fromSplit && !fromDiscounts) {
      dispatch(clearOrderInProgress())
      dispatch(clearCart())
    }

    goBack()
  }

  const makeTenderSelection = ([paymentType, displayName, managerApproval]) => {
    const selected = selectedPaymentType === paymentType
    let iconName = displayName

    if (paymentType === PAYMENT_TYPES.SAVED_CARD) {
      iconName = 'card'
    }

    const icon = React.cloneElement(tenderSelectionIcons(iconName), { className: styles.tenderIcon })
    return (
      <div
        className={cn([styles.tenderSelection, selected && styles.selected])}
        onClick={() => {
          if (managerApproval) {
            setManagerApprovalOnSuccess(() => {
              setSelectedPaymentType(paymentType)
              setTenderDisplayName(iconName)
            })
            setManagerApprovalButtonText("Sign In")
            openManagerApproval()
          } else {
            setSelectedPaymentType(paymentType)
            setTenderDisplayName(iconName)
          }
        }}
      >
        {selected ? (
          <RadioSelected className={styles.icon} />
        ) : (
          <RadioUnSelected className={styles.icon} />
        )}
        <div className={styles.column}>{displayName}</div>
        <div className={styles.column}>{icon}</div>
      </div>
    )
  }

  const tenderSelections = map(paymentTypes, makeTenderSelection)

  if (showCancelModal) {
    modal = (
      <ConfirmModalV2
        onButtonOneClick={() => setShowCancelModal(false)}
        onButtonTwoClick={() => {
          //Reset CFD if attendant chooses to abandon order
          if (cfdMode === MODES.POS) {
            dispatch(resetCFD(true))
          }
          if (isTabOrder) {
            dispatch(clearOrderInProgress())
            dispatch(clearCart())
            dispatch(clearPromotionsOnOrder())
          }

          /**
           * if order has previousUnsyncableStatus then that it's a failed attempt to pay with QR code
           * we set the order again to defective so it's in sync with Stadium
           */
          if(order.previousUnsyncableStatus) {
            dispatch(restoreQRPaymentandUnsyncableStatus(order.uuid))
          }
          push('/concession-order')
        }}
        headerText={`Cancel Transaction?`}
        subtext={modalSubtext}
        buttonOneText={'NO, NEVER MIND '}
        buttonTwoText={'YES, CANCEL'}
      />
    )
  }

  const primaryText = !isMobileViewPort ? centsToDollars(total) : ''
  const secondaryText = !scanScreenType ? 'Please select tender' : 'Please select a tender to pay balance due'
  const extraText = `Total: ${centsToDollars(orderAmountInCents)} • Balance Paid: ${centsToDollars(orderAmountInCents - order?.balanceDueInCents)}`

  if (!success && !failed && isNewOrder) {
    return <ConnectingIndicator />
  }

  const marginTopStyle = !isMobileViewPort ? { 'marginTop': !scanScreenType ? '6.111vh' : '3.703vh' } : {}

  return (
    <div className={classNames}>
      <VNConcessionsNavBar
        onClick={onGoBack}
        showIcon={!scanScreenType}
        textDisplay={isMobileViewPort && tenderDisplay}
      />
      <GratuityHeader
        className={styles.gratuityHeader}
        primaryText={primaryText}
        secondaryText={secondaryText}
        extraText={!scanScreenType ? '' : extraText}
        isSplitTender={true}
        isMobileViewPort={isMobileViewPort}
      />
      <div className={styles.tenderSelect__body} style={marginTopStyle}>
        <div className={styles.tenderSelections}>
        {tenderSelections}
        </div>
        <VNBottomButton
          text={'Next'}
          onClick={handleNext}
          disabled={!selectedPaymentType || nextButtonClicked}
          higherPosition={scanScreenType}
        />
        <div className={styles.actionButtons}>
          {scanScreenType &&
          <div className={cn(styles.button, styles.cancelTransaction)} onClick={() => setShowCancelModal(true)}>
            Cancel Transaction
          </div>}
        </div>
      {modal}
      {offlineModal}
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const push = props?.history?.push ?? (() => {})
  const offlineTotal = getTotal(state)
  const order = getOrderInProgress(state)
  const tenderAmountInCents = props?.history?.location?.state?.tenderAmountInCents ?? (order?.amountInCents ?? 0)
  
  const paymentsLength = order?.payments?.length || 0 
  const cardAuth = getIsPreAuthTab(order)
  const fromSplit = props?.history?.location?.state?.fromSplit ?? (paymentsLength > 1 || (paymentsLength > 0 && !cardAuth))
  const fromDiscounts = props?.history?.location?.state?.fromDiscounts
  
  const isNewOrder = isEmpty(order)
  const isTabOrder = isTabbed(order)
  const showAllPayments = isNewOrder || isTabOrder
  const scanScreenType = props?.history?.location?.state?.scanScreenType ?? ''
  const noOpTenders = makeGetNoOpTenders()(state)
  const cashEnabled = getCashEnabled(state)
  const isCfdCompanionPos = getDeviceMode(state) === MODES.POS
  // Tips are not enabled on a CFD POS for "Othe" tenders.
  let tipsEnabled = getTipsEnabled(state)
  const orderAmountInCents = order?.amountInCents
  const ticketEnabled = getTicketEnabled(state)
  const mobilePaySetting = getMobilePaySetting(state)
  const modalSubtext = cancelModalTexts[scanScreenType]
  const giftCardEnabled = getGiftCardEnabled(state)
  const cartItems = getCartItems(state)
  const taxRate = getTaxRate(state)
  const taxByItem = getTaxByItem(state)
  const menuServiceCharge = getMenuServiceCharge(state)
  const androidSDKVersion = getAndroidSDKVersion(state)
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const paymentProgress = getPaymentProgress(state)
  const tipSuggestions = menu?.tipSuggestions
  const cfdMode = getDeviceMode(state)
  const tabsModeEnabled = getTabsModeEnabled(state)
  let paymentTypes = []

  if (isCfdCompanionPos && !isTabOrder) {
    tipsEnabled = false
  }

  if (tabsModeEnabled && (cfdMode !== MODES.POS || isSDKCompatible(androidSDKVersion, '2.13.3')) && !fromSplit) {
    paymentTypes.push(['split', 'Split', false])
  }

  if (cardAuth && isTabbed(order) && !fromSplit) {
    paymentTypes.push([PAYMENT_TYPES.SAVED_CARD, `${order.paymentDisplay || order?.cardReaderData?.cardIssuer} ${order?.cardReaderData?.cardholderName ? `- ${order.cardReaderData.cardholderName}` : ''}`, false])
  }

  paymentTypes = reduce(noOpTenders, (payment, tender) => {
    return [
      ...payment,
      [tender.name, tender.displayName, tender.managerApproval],
    ]
  }, paymentTypes)

  paymentTypes.push(['card', 'Card'])

  if (cashEnabled) {
    paymentTypes.push(['cash', 'Cash', false])
  }

  if (showAllPayments && mobilePaySetting && !fromSplit) {
    if (mobilePaySetting === 'pos_shows_qr') {
      paymentTypes.push(['mobile_wallet', 'App', false])
    } else {
      paymentTypes.push(['wallet_nonce', 'QR Pay', false])
    }
  }

  if (ticketEnabled && (showAllPayments || scanScreenType === 'ticket') && !fromSplit) {
      paymentTypes.push(['loaded_ticket', 'Ticket', false])
  }

  if (giftCardEnabled && showAllPayments && !fromSplit) {
    paymentTypes.push(['gift_card', 'Gift Card', false])
  }

  return {
    tenderAmountInCents,
    paymentTypes,
    push,
    cashEnabled,
    order,
    isNewOrder,
    isTabOrder,
    offlineTotal,
    tipsEnabled,
    orderAmountInCents,
    scanScreenType,
    modalSubtext,
    cartItems,
    taxRate,
    taxByItem,
    menuServiceCharge,
    tipSuggestions,
    paymentProgress,
    cfdMode,
    fromSplit,
    fromDiscounts
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const goBack = props?.history?.goBack ?? (() => {})
  const push = props?.history?.push ?? (() => {})

  return {
    goBack: () => goBack(),
    closeNoOpTender: (tender) => dispatch(closeNoOpTender({ tender })),
    addPaymentToOrder: (tenderAmountInCents, paymentId, paymentType, tenderDisplayName) => {
      dispatch(addPaymentToOrderInProgress({ tenderAmountInCents, paymentId, paymentType, shortDescription: tenderDisplayName }))
    }
  }
}

TenderSelect.propTypes = {
  addPaymentToOrder: PropTypes.func,
  className: PropTypes.string,
  closeNoOpTender: PropTypes.func,
  goBack: PropTypes.func,
  isNewOrder: PropTypes.bool,
  offlineTotal: PropTypes.number,
  order: PropTypes.object,
  orderAmountInCents: PropTypes.number,
  paymentTypes: PropTypes.array,
  push: PropTypes.func,
  tenderAmountInCents: PropTypes.number,
  tipsEnabled: PropTypes.bool,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TenderSelect))
