import { connect } from 'react-redux'

import RevenueCenterTitle from '../components/RevenueCenterTitle'
import { makeGetCurrentMenu, getSelectedMenus } from '../selectors/menus'
import { currentMenuSelected } from '../actions/menus'
import { clearCart } from '../actions/cart'

const mapStateToProps = (state) => {
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const menus = getSelectedMenus(state)

  return {
    currentMenu: menu,
    isOffline: false,
    menus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentMenuId: (menuId) => dispatch(currentMenuSelected(menuId)),
    clearCart: () => dispatch(clearCart()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenueCenterTitle)
