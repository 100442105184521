export const VNDataBridgeReceiveType = {
  VN_UPDATE_CART: 'VN_UPDATE_CART',
  VN_CFD_SCREEN_NAV: 'VN_CFD_SCREEN_NAV',
  VN_EXECUTE_SALES_REQUEST: 'VN_EXECUTE_SALES_REQUEST',
  VN_EXECUTE_AUTH_REQUEST: 'VN_EXECUTE_AUTH_REQUEST',
  VN_PRINT_RECEIPT: 'VN_PRINT_RECEIPT',
  VN_INITIALIZE_CFD: 'VN_INITIALIZE_CFD',
  VN_RESET_CFD: 'VN_RESET_CFD',
  VN_TRANSACTION_RESULT: 'VN_TRANSACTION_RESULT',
  VNCFD_USER_PAYMENT_PROGRESS: 'VNCFD_USER_PAYMENT_PROGRESS',
  RETRY_PAYMENT: 'RETRY_PAYMENT', // POS -> CFD
  VN_ORDER_UPDATE: 'VN_ORDER_UPDATE', //POS -> CFD
  VN_DEVICE_ID: 'VN_DEVICE_ID'
}

export default VNDataBridgeReceiveType
