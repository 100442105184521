/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import cn from "classnames"
import { get, groupBy, isEmpty, map, filter, values } from "lodash"

import {
  createConfig,
  updateConfig,
} from "@ordernext/networking-stadium/stadium"

import Remote from "../remote"
import { BRANDING_COLOR } from "../constants"

import { ReactComponent as UnselectedRadioButton } from "../assets/icons/unselected-radio-button-icon.svg"
import { ReactComponent as SelectedRadioButton } from "../assets/icons/selected-radio-button-icon.svg"
import { ReactComponent as UnselectedCheckBox } from "../assets/icons/fill-unchecked.svg"
import { ReactComponent as SelectedCheckBox } from "../assets/icons/fill-checkmark.svg"

import usePrevious from "../hooks/usePrevious"
import useGetIsMobileScreen from "../hooks/useGetIsMobileScreen"

import { clearCart } from "../actions/cart"
import { currentMenuSelected, menusSucceeded } from "../actions/menus"
import { clearPromotionsOnOrder } from "../actions/promotion"
import { fetchAttendants } from "../actions/attendant"

import { getIsKiosk, getTextReceiptsEnabled } from "../selectors/menus"
import { getBackbarMode, getDeviceSubtype, getSelectedMenusIds } from "../selectors/config"
import { getAttendants, getFetchAttendantsStatus } from "../selectors/attendant"
import { getBuildInfoReceived, getPaymentType } from "../selectors/peripheral"
import { getCurrentConfiguration } from "../selectors/config"
import { getNetworkPrinter, calculatePrintingEnabled } from '../selectors/printer'

import { getAccessToken, getDeviceId } from "../utils/deviceData"
import DeviceSubtypes from "../utils/deviceSubtypes"
import { getNetworkAvailableValue } from "../utils/networkConnected"
import { DEFAULT_PRINTERS } from "../utils/printing"

import styles from "./RevenueCenterSetup.module.scss"
import { sendInitializeCFD } from "../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend"
import { getPOSPairingState } from "../VNCustomerFacingDisplay/Selectors"
import { setDeviceMode } from "../VNMode/ActionCreators"
import { MODES } from "../VNMode/Reducer"
import { getDeviceMode } from "../VNMode/Selectors"

import Button from "../components/Button"
import VNConcessionsNavBar from "../components/VNConcessionsNavBar"
import ConnectingIndicator from "../components/ConnectingIndicator"
import Record from '../components/Record'

import { disableBackbarMode, enableBackbarMode, loadConfig } from '../actions/config'
import { fetchEmployeeRoles } from "../actions/employeeRole"
import { currentNetworkPrinterSelected } from '../actions/printer'
import ConfirmModalV2 from "../components/ConfirmModalV2"
import useGetEnvVars from "../hooks/useGetEnvVars"

const menuTypes = {
  "concessions-pos": "pos",
  kiosk: "kiosk",
}

const RevenueCenterSetup = ({
  history,
  loading,
  failed,
  hasConfig,
  currentDeviceSubtype,
  currentNetworkPrinters,
  createConfig,
  updateConfig,
  className,
  setCurrentMenuId,
  setCurrentNetworkPrinterUuid,
  redirectToPin,
  onMount,
  attendantsExist,
  fetchAttendantsStatus,
  isKiosk,
  menuLoadSucceeded,
  paymentType,
  deviceId,
  accessToken,
  configurationUuid,
  previousSelectedIds,
  hasBuildInfo,
  currentBackbarMode,
  enableBackbarMode, 
  disableBackbarMode
}) => {
  const dispatch = useDispatch()

  const classNames = cn(styles.revenueCenterSetup, className)
  const [selectedMenuUuids, setSelectedMenuUuids] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [menuRequestStatus, setMenuRequestStatus] = useState('loading')
  const [printerRequestStatus, setPrinterRequestStatus] = useState('loading')
  const [menuData, setMenuData] = useState({ })
  const [printerData, setPrinterData] = useState({ })
  const [deviceSubtype, setDeviceSubtype] = useState(currentDeviceSubtype)
  const [deviceSubtypeSelected, setDeviceSubtypeSelected] = useState(
    !!deviceSubtype
  )
  const [menuSelected, setMenuSelected] = useState(false)

  const [closeTabMethodSelected, setCloseTabMethodSelected] = useState(false)
  const [backbarMode, setBackbarMode] = useState(currentBackbarMode)
  const [showNoPrinterModal, setShowNoPrinterModal] = useState(false)

  const isMobileViewPort = useGetIsMobileScreen()

  const scaleSvgIcon = (icon) => {
    return <div className={styles.svg}>{icon}</div>
  }

  const deviceMode = useSelector((state) => getDeviceMode(state))
  const unselectedIcon = DeviceSubtypes.isConcessions(deviceSubtype) ? (
    <UnselectedRadioButton />
  ) : (
    <UnselectedCheckBox />
  )
  const selectedIcon = DeviceSubtypes.isConcessions(deviceSubtype) ? (
    <SelectedRadioButton />
  ) : (
    <SelectedCheckBox />
  )
  const networkAvailable = useSelector((state) => getNetworkAvailableValue(state))
  const textReceiptsEnabled = useSelector(getTextReceiptsEnabled)
  const emailReceiptsEnabled = useSelector(getTextReceiptsEnabled)

  const stands = get(menuData, "stands", [])
  const menus = get(menuData, "menus", [])
  const filteredMenus = menus.filter(
    (menu) => menu?.serviceType?.toLowerCase() === menuTypes[deviceSubtype]
  ).sort((a, b) => a.standName > b.standName ? 1 : -1)
  const groupedMenus = groupBy(filteredMenus, "standUuid")
  const previousHasConfig = usePrevious(hasConfig)
  const attendantsFetchCompleted = ["succeeded", "failed"].includes(
    fetchAttendantsStatus
  )

  const selectedMenu = menus.find(menu => menu.uuid === selectedMenuUuids[0])
  const tipsEnabled = !isEmpty(selectedMenu?.tipSuggestions || [])

  const {allowEnableBackbar} = useGetEnvVars()
  const tabsModeEnabled = selectedMenu?.enableFullServiceInPos ?? false
  const canEnableBackbarMode = tabsModeEnabled && allowEnableBackbar

  const getMenuHeader = () => {
    if (!deviceSubtypeSelected) return 'Select Device Type'
    if (!menuSelected) return 'Select Menu'
    if (!closeTabMethodSelected) return 'Select Close Tab Method'
    return 'Select Receipt Printer'
  }

  const menuHeader = getMenuHeader()

  const getNoTipsMessage = () => {
    if (deviceSubtypeSelected && menuSelected && !closeTabMethodSelected && !tipsEnabled) {
      return (
        <div className={cn([displayHeader && styles.noTips, !displayHeader && styles.noTipsMobile])}>
          {`${selectedMenu?.name} does not have tips enabled`}
        </div>
      )
    }
    return null
  }

  const [selectedNetworkPrinterUuids, setSelectedNetworkPrinterUuids] = useState(currentNetworkPrinters)
  const defaultPrinterRecords = values(DEFAULT_PRINTERS)
  const networkPrinters = get(printerData, 'printers', [ ]).filter(
    (printer) => printer?.receiptPrinter)

  const fetchPrinters = () => {
    Remote.fetchPrinters().then((res) => {
      setPrinterRequestStatus('success')
      setPrinterData(res.data)
    }).catch((err) => {
      // if failed due to invalid token, add a small wait to allow
      // token to refresh before re-attempting a fetch.
      if (err?.response?.status === 401) {
        setTimeout(() => {
          setPrinterRequestStatus('failed')
        }, 1000)
      } else {
        setPrinterRequestStatus('failed')
      }
    })
  }

  const onSelectNetworkPrinter = (printerUuid) => {
    setSelectedNetworkPrinterUuids([ printerUuid ])
  }

  const handleNavigation = () => {
    if (isKiosk) {
      history.replace("/kiosk/attract_loop")
    } else {
      if (attendantsExist) {
        redirectToPin(fetchAttendantsStatus)
      } else {
        history.replace("/concession-order")
      }
    }
  }

  // the updated state from pairing with the CFD
  const devicePairingResult = useSelector((state) => getPOSPairingState(state))

  useEffect(() => {
    const hasConfigChanged = previousHasConfig === false && hasConfig === true
    const isValidConfigMode = ["concessions-pos", "kiosk", "cfd"].includes(
      currentDeviceSubtype
    )

    if (hasConfigChanged && isValidConfigMode && attendantsFetchCompleted) {
      handleNavigation()
    }
  }, [hasConfig, fetchAttendantsStatus])

  //TODO (JEFF) Possibly refactor this screen to pull menu data from redux
  const fetchMenus = () => {
    Remote.fetchMenus({ paymentType })
      .then((res) => {
        setMenuRequestStatus('success')
        setMenuData(res.data)
        if (isKiosk) {
          menuLoadSucceeded(res.data?.menus, res.data?.stands)
        }
        setCurrentMenu(res.data?.menus)
      })
      .catch((err) => {
        // if failed due to invalid token, add a small wait to allow
        // token to refresh before re-attempting a fetch.
        if (err?.response?.status === 401) {
          setTimeout(() => {
            setMenuRequestStatus('failed')
          }, 1000)
        } else {
          setMenuRequestStatus('failed')
        }
      })
  }

  useEffect(() => {
    onMount()
    fetchPrinters()
  }, [])

  // To ensure that the correct menus are fetched, wait until the device's build info is ready and available.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const isApk = urlParams.get('apk');
    if (hasBuildInfo || !isApk) {
      fetchMenus()
    }
  }, [hasBuildInfo])

  useEffect(() => {
    if (networkAvailable) {
      if (menuRequestStatus === "failed") fetchMenus()
      if (printerRequestStatus === "failed") fetchPrinters()
    }
  }, [networkAvailable, menuRequestStatus, printerRequestStatus])

  useEffect(() => {
    if (submitted && !loading && !failed && attendantsFetchCompleted) {
      handleNavigation()
    }
  }, [submitted, loading, failed, history, fetchAttendantsStatus])

  const setCurrentMenu = (menus) => {
    const filteredMenus = menus.filter(
      (menu) => menu?.serviceType?.toLowerCase() === menuTypes[deviceSubtype]
    )
    const menuUuids = filter(map(filteredMenus, "uuid"), (uuid) =>
      previousSelectedIds.includes(uuid)
    )
    if (!isEmpty(menuUuids)) {
      setSelectedMenuUuids(menuUuids)
    }
  }

  useEffect(() => {
    setSelectedMenuUuids([])
  }, [deviceSubtype])

  const renderRecords = (headerRecords, records, selectedUuids, onClick) => {
    return headerRecords.concat(records.sort((a,b) => a.name > b.name ? 1 : -1)).map((record) => {
      const isSelected = selectedUuids?.includes(record.uuid)
      const icon = isSelected ? selectedIcon : unselectedIcon
      const style = { backgroundColor: isSelected ? "#2253FF" : undefined }

      return (
        <Record
          key={record.uuid}
          icon={scaleSvgIcon(icon)}
          name={record.name}
          style={style}
          styles={styles}
          onClick={() => onClick(record.uuid, isSelected)}
        />
      )
    })
  }

  const renderCloseTabMethods = () => {
    const TIP_ON_DEVICE = 'Tip on Device'
    const TIP_ON_PRINTED_SLIP = 'Tip on Printed Slip'

    return (
      <div>
        <Record
          key={TIP_ON_DEVICE}
          disabled={!tipsEnabled}
          icon={scaleSvgIcon(!backbarMode && tipsEnabled ? selectedIcon : unselectedIcon)}
          name={TIP_ON_DEVICE}
          style={{ backgroundColor: !backbarMode && tipsEnabled ? "#2253FF" : undefined }}
          styles={styles}
          onClick={() => {
            if (tipsEnabled) setBackbarMode(false)
          }}
        />
        <Record
          key={TIP_ON_PRINTED_SLIP}
          disabled={!tipsEnabled}
          icon={scaleSvgIcon(backbarMode ? selectedIcon : unselectedIcon)}
          name={TIP_ON_PRINTED_SLIP}
          style={{ backgroundColor: backbarMode ? "#2253FF" : undefined }}
          styles={styles}
          onClick={() => {
            if (tipsEnabled) setBackbarMode(true)
          }}
        />
      </div>
    )
  }

  /**
   * onClick event for renderDeviceTypes
   * @param {string} subtype the new device subtype
   */
  const selectDeviceMode = (selectedSubtype) => {
    dispatch(setDeviceMode(selectedSubtype === 'cfd' ? MODES.POS : MODES.UNKNOWN))
    setDeviceSubtype(selectedSubtype === 'cfd' ? 'concessions-pos' : selectedSubtype)

  }

  const renderDeviceTypes = () => {
    const subtypeSelections = ['concessions-pos', 'kiosk', 'cfd']
    return subtypeSelections.map((subtype) => {
      // if the device mode is unknown, we want to select the device subtype.
      const isSelected = deviceMode === MODES.UNKNOWN ? deviceSubtype === subtype :
        (subtype === 'cfd' && deviceSubtype === 'concessions-pos')
      const style = { backgroundColor: isSelected ? '#2253FF' : undefined }

      const icon = isSelected ? (
        <SelectedRadioButton />
      ) : (
        <UnselectedRadioButton />
      )

      return (
        <Record
          key={subtype}
          icon={scaleSvgIcon(icon)}
          isSelected={isSelected}
          name={DeviceSubtypes.displayName(subtype)}
          style={style}
          styles={styles}
          onClick={() => selectDeviceMode(subtype)}
        />
      )
    })
  }

  const renderStatus = (requestStatus, groupedItems, itemName) => {
    if (requestStatus === "loading") return <ConnectingIndicator />
    if (requestStatus === "failed") return <p>An error occurred</p>
    if (requestStatus !== "loading" && isEmpty(groupedItems))
      return <p>No POS {itemName} are available!</p>
  }

  const onMenuSelected = () => {
    setMenuSelected(true)

    //if the user can't select backbar mode then we consider it selected and default to false
    if (!canEnableBackbarMode) {
      setBackbarMode(false)
      setCloseTabMethodSelected(true)
      return
    }

    //if a menu was selected and tips are not enabled then we need to default backbar mode to false
    if (!tipsEnabled) {
      setBackbarMode(false)
    }
  }

  const handleDone = () => {
    const isConcessions = DeviceSubtypes.isConcessions(deviceSubtype)
    const isKiosk = DeviceSubtypes.isKiosk(deviceSubtype)
    const selectedNetworkPrinter = isKiosk ? undefined : selectedNetworkPrinterUuids[0]
    const configParams = { menuUuids: selectedMenuUuids, deviceSubtype: deviceSubtype,
      deviceMode: deviceMode, receiptPrinterUuid: selectedNetworkPrinter }

    if (backbarMode && selectedNetworkPrinter === 'none') {
      setShowNoPrinterModal(true)
      return 
    }

    setCurrentNetworkPrinterUuid(selectedNetworkPrinter)
    setCurrentMenuId(selectedMenuUuids[0])

    let selectedMenus
    let selectedStands
    let currentStand

    if (isKiosk) {
      selectedMenus = filter(filteredMenus, (menu) =>
        selectedMenuUuids.includes(menu?.uuid)
      )
      const standMenuUuids = map(selectedMenus, (menu) => menu.standUuid)
      selectedStands = filter(stands, (stand) =>
        standMenuUuids.includes(stand?.uuid)
      )
    } else {
      selectedMenus = filter(
        filteredMenus,
        (menu) => menu?.uuid === selectedMenuUuids[0]
      )
      selectedStands = filter(
        stands,
        (stand) => stand?.uuid === selectedMenus[0]?.standUuid
      )
      currentStand = selectedStands?.[0]
    }

    menuLoadSucceeded(selectedMenus, selectedStands)
    hasConfig ? updateConfig(configParams) : createConfig(configParams)

    if (devicePairingResult && isConcessions && deviceMode === MODES.POS) {
      const printingEnabled = calculatePrintingEnabled(selectedNetworkPrinter)

      const initializationValues = {
        title: currentStand?.name,
        color: BRANDING_COLOR,
        deviceId: deviceId,
        appConfigurationDeviceId: configurationUuid,
        accessToken: accessToken,
        printingEnabled: printingEnabled,
        textReceiptsEnabled: textReceiptsEnabled,
        emailReceiptsEnabled: emailReceiptsEnabled,
      }

      initializationValues.menuImage = selectedMenus?.images?.detail
      initializationValues.image = currentStand?.images?.detail

      sendInitializeCFD(initializationValues)
    }

    //backbad mode setup
    if (isConcessions) {
      if (backbarMode) {
        enableBackbarMode()
      } else {
        disableBackbarMode()
      }
    }

    setSubmitted(true)
  }

  const goBack = () => {
    const prevLocation = history?.location?.state?.prevLocation ?? ""
    if (deviceSubtypeSelected && !menuSelected) {
      //pressed back from the select menu step
      setDeviceSubtypeSelected(false)
    } else if (deviceSubtypeSelected && menuSelected && !closeTabMethodSelected) {
      //pressed back from the Select close tab method step
      setMenuSelected(false)
    } else if (deviceSubtypeSelected && menuSelected && closeTabMethodSelected) {
      //pressed back from the Select Receipt Printer step
      //if the user can't select backbar mode then we need to skip the screen we do this by setting menu selected to false
      if (!canEnableBackbarMode) {
        setMenuSelected(false)
      } 
      setCloseTabMethodSelected(false)
    } else if (prevLocation === '/kiosk/order/new') {
      history.push('/kiosk/attract_loop')
    } else {
      history.goBack()
    }
  }

  const onSelectMenuConcessions = (menuUuid) => {
    setSelectedMenuUuids([menuUuid])
  }

  const onSelectMenuKiosk = (selectedMenuUuid, isSelected) => {
    const menuUuids = isSelected
      ? selectedMenuUuids.filter((id) => id !== selectedMenuUuid)
      : [...selectedMenuUuids, selectedMenuUuid]
    setSelectedMenuUuids(menuUuids)
  }

  const displayHeader = document?.body?.clientWidth >= 1000

  const onSelectMenuItem =
    deviceSubtype === "kiosk" ? onSelectMenuKiosk : onSelectMenuConcessions

  const noPrinterModal = (
    <ConfirmModalV2
      headerText={null}
      onButtonTwoClick={() => setShowNoPrinterModal(false)}
      subtext={'You must select a printer when “Tip on Printed Slip" is enabled'}
      singleButtonOnly={true}
      buttonTwoText={'OKAY'}
    />
  )

  return (
    <div>
      {showNoPrinterModal && noPrinterModal}
      <div className={classNames}>
        <VNConcessionsNavBar
          textDisplay={isMobileViewPort && menuHeader}
          useSmallTitleFont={true}
          useHamburgerIcon={true}
          />

        {displayHeader && (
          <>
            <div className={styles.header}>
              {menuHeader}
              {getNoTipsMessage()}
            </div>
          </>
        )}

        {renderStatus(menuRequestStatus, groupedMenus, 'menus')}
        <div className={styles.container}>
          {!deviceSubtypeSelected && (
            <div className={styles.standMenus}>{renderDeviceTypes()}</div>
          )}

          {deviceSubtypeSelected && !menuSelected && (
            <div className={styles.standMenus}>
              {Object.keys(groupedMenus).map((standUuid) => {
                const stand = stands.find((stand) => stand.uuid === standUuid)
                const menus = groupedMenus[standUuid]
                return (
                  <div className={styles.menuSelection} key={standUuid}>
                    <div className={styles.standName}>{stand?.name}</div>
                    {renderRecords([ ], menus, selectedMenuUuids, onSelectMenuItem)}
                  </div>
                )
              })}
            </div>
          )}
          
          {deviceSubtypeSelected && menuSelected && !closeTabMethodSelected && (
            <>
              <div className={styles.container}>
                {!displayHeader && getNoTipsMessage()}
                <div className={styles.standMenus}>
                    {renderCloseTabMethods()}
                </div>
              </div>
            </>
          )}  

          {closeTabMethodSelected && (
            <div className={styles.container}>
              <div className={styles.standMenus}>
                  {renderStatus(printerRequestStatus, networkPrinters, 'printers')}
                  {renderRecords(defaultPrinterRecords, networkPrinters, selectedNetworkPrinterUuids, onSelectNetworkPrinter)}
              </div>
            </div>
          )}
        </div>

        <div className={styles.buttonContainer}>
          {!deviceSubtypeSelected && (
            <Button
              disabled={loading || !deviceSubtype}
              className={styles.nextButton}
              onClick={() => setDeviceSubtypeSelected(true)}
            >
              {loading ? 'Loading...' : 'Next'}
            </Button>
          )}

          {deviceSubtypeSelected && !menuSelected && (
            <>
              <Button
                disabled={loading}
                className={styles.backButton}
                onClick={goBack}
              >
                Back
              </Button>
              <Button
                disabled={loading || isEmpty(selectedMenuUuids)}
                className={styles.doneButton}
                onClick={() => {
                  (deviceSubtype === 'kiosk') ? handleDone() : onMenuSelected()
                }}
              >
                {loading ? "Loading..." : ((deviceSubtype === 'kiosk') ? "Done" : "Next")}
              </Button>
            </>
          )}

          {menuSelected && !closeTabMethodSelected && (deviceSubtype !== 'kiosk') && (
            <>
              <Button
                disabled={loading}
                className={styles.backButton}
                onClick={goBack}
              >
                Back
              </Button>
              <Button
                disabled={loading}
                className={styles.doneButton}
                onClick={() => setCloseTabMethodSelected(true)}
              >
                {loading ? 'Loading...' : 'Next'}
              </Button>

            </>
          )}
          {closeTabMethodSelected && (deviceSubtype !== 'kiosk') && (
            <>
              <Button
                disabled={loading}
                className={styles.backButton}
                onClick={goBack}
              >
                Back
              </Button>
              <Button
                disabled={loading || isEmpty(selectedNetworkPrinterUuids)}
                className={styles.doneButton}
                onClick={() => handleDone()}
              >
                {loading ? 'Loading...' : 'Done'}
              </Button>

            </>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const currentDeviceSubtype = getDeviceSubtype(state)
  const failed = get(state, "config.remote.failed", false)
  const hasConfig = get(state, "config.hasConfig", false)
  const loading = get(state, "config.remote.loading", false)
  const attendantsExist = !isEmpty(getAttendants(state))
  const fetchAttendantsStatus = getFetchAttendantsStatus(state)
  const isKiosk = getIsKiosk(state)
  const paymentType = getPaymentType(state)
  const deviceId = getDeviceId()
  const accessToken = getAccessToken()
  const configurationUuid = getCurrentConfiguration(state)?.uuid
  const previousSelectedIds = getSelectedMenusIds(state)
  const selectedNetworkPrinter = getNetworkPrinter(state)
  const hasBuildInfo = getBuildInfoReceived(state)
  const currentBackbarMode = getBackbarMode(state)

  return {
    attendantsExist,
    currentDeviceSubtype,
    currentNetworkPrinters: selectedNetworkPrinter ? [ selectedNetworkPrinter ] : [],
    failed,
    hasConfig,
    loading,
    fetchAttendantsStatus,
    isKiosk,
    paymentType,
    deviceId,
    accessToken,
    configurationUuid,
    previousSelectedIds,
    hasBuildInfo,
    currentBackbarMode
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const replace = get(ownProps, "history.replace", () => {})

  return {
    createConfig: (payload) => dispatch(createConfig(payload)),
    updateConfig: (payload) => dispatch(updateConfig(payload)),
    setCurrentNetworkPrinterUuid: (uuid) => dispatch(currentNetworkPrinterSelected(uuid)),
    setCurrentMenuId: (menuId) => dispatch(currentMenuSelected(menuId)),
    menuLoadSucceeded: (menus, stands) =>
      dispatch(menusSucceeded({ data: { stands, menus } })),
    setSelectedMenu: (menu, stand) =>
      dispatch(menusSucceeded({ data: { stands: [stand], menus: [menu] } })),
    redirectToPin: (fetchAttendantsStatus) =>
      replace({
        pathname: "/user-login",
        state: {
          attendantsAlreadyFetched: fetchAttendantsStatus === "succeeded",
        },
      }),
    onMount: () => {
      dispatch(clearPromotionsOnOrder())
      dispatch(clearCart())
      dispatch(fetchAttendants())
      dispatch(fetchEmployeeRoles())
      dispatch(loadConfig({ redirect: false, shouldReloadMenus: false }))
    },
    enableBackbarMode: () => dispatch(enableBackbarMode()),
    disableBackbarMode: () => dispatch(disableBackbarMode()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevenueCenterSetup)
)
