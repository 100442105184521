import React, { memo, useState } from 'react'
import { get, keys, map } from 'lodash'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './RevenueCenterTitle.module.scss'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'

const RevenueCenterTitle = ({ className, currentMenu, menus, setCurrentMenuId, clearCart }) => {
  const classNames = cn(styles.revenueCenterTitle, className)
  const [showModal, toggleModal] = useState(false)
  const isMobileScreen = useGetIsMobileScreen()

  const setNewMenu = (menuUuid) => {
    setCurrentMenuId(menuUuid)
    toggleModal(!showModal)
    clearCart()
  }

  const hasMultipleMenus = keys(menus).length > 1

  const openMultiMenuSelectModal = () => {
    if (hasMultipleMenus) {
      toggleModal(!showModal)
    }
  }

  const downArrow = hasMultipleMenus ? (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M1.414 0h13.6a1 1 0 0 1 .708 1.707l-6.8 6.8a1 1 0 0 1-1.415 0l-6.8-6.8A1 1 0 0 1 1.414 0z"
      />
    </svg>
  ) : null

  const upArrow = (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M1.414 9.215h13.6a1 1 0 0 0 .708-1.708l-6.8-6.8a1 1 0 0 0-1.415 0l-6.8 6.8a1 1 0 0 0 .707 1.708z"
      />
    </svg>
  )

  let modalElement = null

  if (showModal) modalElement = (
    <div className={styles.menuSelectOverlay}>
      <div className={styles.menuSelectModal}>
        <div
          className={styles.modalHeader}
          onClick={() => toggleModal(!showModal)}
        >
          <div className={styles.currentSelectedMenu}>
            {currentMenu.name}
          </div>
          <div className={styles.arrow}>{upArrow}</div>
        </div>
        {map(menus, menu =>
          menu.uuid !== get(currentMenu, 'uuid') ? (
            <div
              key={menu.uuid}
              className={styles.menuSelect}
              onClick={() => setNewMenu(menu.uuid)}
            >
              {menu.name}
            </div>
          ) : null
        )}
      </div>
    </div>
  )

  const formatMenuName = (menuName) => {
    if (menuName.length <= 31 || !isMobileScreen) {
      return menuName
    }

    return `${menuName.substring(0, 29)}...`
  }

  return (
    <div className={classNames}>
      {modalElement}
      <div className={styles.titleHeader} onClick={openMultiMenuSelectModal}>
        <span className={styles.name}>{formatMenuName(currentMenu?.name ?? '')}</span>
        <div className={styles.arrow}>{downArrow}</div>
      </div>
    </div>
  )
}

RevenueCenterTitle.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

export default memo(RevenueCenterTitle)
