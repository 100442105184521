import React, { useEffect, useState, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import { isEmpty } from 'lodash'

import styles from './Main.module.scss'

import useInterval from '../hooks/useInterval'

import ConcessionsCartSidebar from '../containers/ConcessionsCartSidebar'
import CartModal from '../containers/CartModal'
import Items from '../containers/Items'
import Navbar from '../containers/Navbar'
import CategoryBar from '../containers/CategoryBar'

import HeaderBar from '../components/HeaderBar'

import DeviceSubtypes from '../utils/deviceSubtypes'
import { incrementNextOrderNumber } from '../utils/orderNumbers'
import { createOrderParams } from '../utils/orderUtils'

import { getCurrentMenuId, getPerformSafeUpdate } from '../selectors/appState'
import { getOrderInProgress, getUnsyncedOrderCounts } from '../selectors/order'
import { getActiveAttendantId, getAttendants } from '../selectors/attendant'
import { getDeviceSubtype } from '../selectors/config'

import { updateHighlightedItems } from '../actions/search'
import { menusItemsRequested } from '../actions/menus'
import { getCarouselImages } from '../actions/carouselImages';
import { clearRemoteOrderTotal } from '../actions/orderTotalRemote'
import { fetchPromotions } from '../actions/promotion'
import { clearOrderInProgress, resetMutationInProgress } from '../actions/order'
import { refreshCache } from '../utils/newAppVersionAvailable'
import { addNotesToCart } from '../actions/cart'
import { STADIUM_ORDER_STATES } from '../utils/orderStates'

const Main = ({
  className,
  currentMenuUuid,
  menusItemsRequested,
  getCarouselImages,
  clearOrderInProgress,
  clearTotal,
  fetchPromotions,
  attendantsExist,
  hasActiveAttendant,
  redirectToLogin,
  performSafeUpdate,
  clearItemsSearch,
  resetMutationInProgress
}) => {
  const classNames = cn(styles.main, className)
  const [screenResize, setScreenResize] = useState(window.innerHeight)
  const [initialScreenSize, setInitialScreenSize] = useState(window.innerHeight)

  const orderInProgress = useSelector((state) => getOrderInProgress(state))
  const deviceSubtype = useSelector((state) => getDeviceSubtype(state))

  const urlParams = new URLSearchParams(window.location.search)

  const [isTabbed, setIsTabbed] = useState(
    orderInProgress?.state === STADIUM_ORDER_STATES.TABBED ||
      createOrderParams(orderInProgress)?.state === STADIUM_ORDER_STATES.TABBED ||
      orderInProgress?.state === STADIUM_ORDER_STATES.TABBED_OFFLINE ||
      createOrderParams(orderInProgress)?.state === STADIUM_ORDER_STATES.TABBED_OFFLINE
  )

  useEffect(() => {
    if (!isTabbed) {
      incrementNextOrderNumber()
    }

    if (urlParams.get('from') === 'user-login') {
      menusItemsRequested()
      fetchPromotions()
    }

    if (DeviceSubtypes.isKiosk(deviceSubtype)) {
      getCarouselImages()
    }

    return () => {
      clearItemsSearch()
    }
  }, [])

  useEffect(()=> {
    resetMutationInProgress()
  }, [resetMutationInProgress])

  useEffect(() => {
    if (performSafeUpdate && isEmpty(orderInProgress)) {
      refreshCache()
    }
  }, [performSafeUpdate, orderInProgress])

  /**
   * first thing we need to do is prevent the main component to clear order in progress and total
   * for tabbed orders
   *
   * We can't use the isTabbed util here because it uses orderInProgress obj, if we add
   * orderInProgress to the dependency array we enter in a re render loop.
   * This way we follow the hooks rules and prevent endless renders loops
   */
  useEffect(()=> {
    if (isTabbed) {
      return
    }

    clearOrderInProgress()
    clearTotal()
  }, [orderInProgress?.state, createOrderParams(orderInProgress)?.state])

  const handleWindowResize = useCallback(e => {
    setScreenResize(window.innerHeight)
  }, [])

  //TODO: T32577 remove unused code
  useEffect(() => {
    setInitialScreenSize(window.innerHeight)
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  useEffect(() => {
    if (attendantsExist && !hasActiveAttendant) {
      redirectToLogin()
    }
  }, [hasActiveAttendant])

  useInterval(() => {
    if (currentMenuUuid && localStorage.getItem('accessToken')) menusItemsRequested()
  }, 600000)

  const [activeOfflineCardCount, unsyncedLocalOrderCount] = useSelector((state) =>
    getUnsyncedOrderCounts(state)
  )
  const shouldDisplayHeader = activeOfflineCardCount > 0 || unsyncedLocalOrderCount > 0

  const cart = document?.body?.clientWidth <= 800 ? <CartModal /> : <ConcessionsCartSidebar className={shouldDisplayHeader ? styles.shortenSidebar : styles.sidebar} />


  return (
    <div className={classNames}>
      <HeaderBar />
      <Navbar className={styles.navbar} />
      {cart}
      <div className={shouldDisplayHeader ? styles.contentWithHeaderBar : styles.content}>
        <CategoryBar className={styles.categoryHeaderContainer} />
        <Items className={styles.items} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const currentMenuUuid = getCurrentMenuId(state)
  const attendantsExist = !isEmpty(getAttendants(state))
  const hasActiveAttendant = getActiveAttendantId(state) !== undefined
  const performSafeUpdate = getPerformSafeUpdate(state)

  return {
    currentMenuUuid,
    attendantsExist,
    hasActiveAttendant,
    performSafeUpdate,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const replace = props?.history?.replace ?? (() => {})

  return {
    getCarouselImages: () => dispatch(getCarouselImages()),
    menusItemsRequested: () => dispatch(menusItemsRequested()),
    clearTotal: () => dispatch(clearRemoteOrderTotal()),
    fetchPromotions: () => dispatch(fetchPromotions()),
    redirectToLogin: () => {
      replace('/user-login')
    },
    clearOrderInProgress: () => dispatch(clearOrderInProgress()),
    clearItemsSearch: () => dispatch(updateHighlightedItems({query: '', highlightedIds: [], focusedItemId: undefined})),
    addNotesToCart: (userNotes) => dispatch(addNotesToCart(userNotes)),
    resetMutationInProgress: () => dispatch(resetMutationInProgress())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
