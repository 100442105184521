import { isDecimal } from '../../VNUtil/Number'
import { VN_BRIDGE_SEND_METHODS } from '../Enums'

/**
 * Post message to native bridge - Android only
 * @param {object} data
 */
const postMessageThroughBridge = async(data, asynchronous = false) => {
  if (window.VN_DATA_BRIDGE && window.VN_DATA_BRIDGE !== undefined) {
    window.VN_DATA_BRIDGE.jsBridgePostMessage(JSON.stringify(data, replacer))
  }

   // If Android, convert all values to string as this is a requirement
  // from the Android side
  function replacer(name, val) {
    if (Number.isInteger(val) || isDecimal(val) || typeof val == 'boolean') {
      return val.toString()
    } else {
      return val
    }
  }
}

// Keep these in time order.
export const VNDataBridgeSendType = {
  VN_INITIALIZE_CFD: 'VN_INITIALIZE_CFD', // POS -> CFD
  VN_CFD_SCREEN_NAV: 'VN_CFD_SCREEN_NAV', // POS -> CFD
  VN_UPDATE_CART: 'VN_UPDATE_CART', // POS -> CFD
  VN_EXECUTE_SALES_REQUEST: 'VN_EXECUTE_SALES_REQUEST', // CFD -> ANDROID
  VN_PRINT_RECEIPT: 'VN_PRINT_RECEIPT', // CFD -> ANDROID
  VNCFD_USER_PAYMENT_PROGRESS: 'VNCFD_USER_PAYMENT_PROGRESS', // CFD -> POS
  RETRY_PAYMENT: 'RETRY_PAYMENT', // POS -> CFD
  VN_ORDER_UPDATE: 'VN_ORDER_UPDATE', // CFD -> POS
  VN_GET_DEVICE_ID: 'VN_GET_DEVICE_ID',
  VN_RESET_DEVICE: 'VN_RESET_DEVICE',
  VN_EXECUTE_AUTH_REQUEST: 'VN_EXECUTE_AUTH_REQUEST'
}

export const bridge_setCurrentOrder = (tip) => {
  postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_BRIDGE_PASSTHROUGH,
    data: {
      method: VN_BRIDGE_SEND_METHODS.VN_SET_CURRENT_ORDER,
      data: { tip: tip }
    }
  })
}

export const bridge_updateTransactionLifecycle = (moment) => {
  postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_BRIDGE_PASSTHROUGH,
    data: {
      method: VN_BRIDGE_SEND_METHODS.VN_UPDATE_TRANSACTION_LIFECYCLE,
      data: { moment: moment }
    }
  })
}

export const bridge_sendAcknowledgement = (uuid) => {
  postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_BRIDGE_PASSTHROUGH,
    data: {
      method: VN_BRIDGE_SEND_METHODS.VN_ACKNOWLEDGEMENT,
      data: { uuid: uuid }
    }
  })
}

export const bridge_updateCurrentPaymentFlow = (flow) => {
  postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_BRIDGE_PASSTHROUGH,
    data: {
      method: VN_BRIDGE_SEND_METHODS.VN_SET_CURRENT_PAYMENT_FLOW,
      data: { flow: flow }
    }
  })
}

/**
 * Called when the POS has determined that an update is needed and the POS is going to refresh
 * And we need to refresh the CFD as well.
 */
export const bridge_updateCFDVersion = () => {
  postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_BRIDGE_PASSTHROUGH,
    data: {
      method: VN_BRIDGE_SEND_METHODS.VN_UPDATE_CFD_VERSION
    }
  })
}

/**
 *
 * @param {*} screen
 * @param {*} optionalData
 */
export const bridge_setCFDScreen = async (screen, optionalData, acknowledge = false) => {

  let data = {
    screen: screen,
    ...optionalData
  }

  await postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_CFD_SCREEN_NAV,
    data: data
  }, acknowledge)
}

/**
 * Get the device ID from the Android SDK
 */
export const bridge_getDeviceId = () => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_GET_DEVICE_ID
  })
}

/**
 * Requests to reset the device with Android and do the registration all over again.
 */
export const bridge_resetDevice = () => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_RESET_DEVICE
  })
}

export const bridge_sendExecuteAuthRequest = (data) => {
  postMessageThroughBridge({
    method: VN_BRIDGE_SEND_METHODS.VN_BRIDGE_PASSTHROUGH,
    data: {
      method: VNDataBridgeSendType.VN_EXECUTE_AUTH_REQUEST,
      data: data
    }
  })
}

export const sendUpdateCart = (items) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_UPDATE_CART,
    data: {
      cartItems: items
    }
  })
}

export const sendRetryPayment = (bridgePaymentMethod) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.RETRY_PAYMENT,
    data: {
      bridgePaymentMethod,
    }
  })
}

/**
 *
 * @param {String} screen specified CFD Screen, CFD_SCREEN Object in Reducer.js, VNCustomerFacingDisplay contains all of them
 * @param {Object} optionalData (OPTIONAL) Object with whatever data you need to pass with the keys you want.
 * {
 *  subtotal: '180',
 *  discount: '27',
 *  tax: '18',
 *  tip: '0',
 *  total: '196.65'
 * }
 */
export const sendCFDScreenNav = (screen, optionalData) => {

  let data = {
    screen: screen,
    ...optionalData
  }

  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_CFD_SCREEN_NAV,
    data: data
  })
}

export const sendUserPaymentProgress = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VNCFD_USER_PAYMENT_PROGRESS,
    data: data
  })
}

export const sendExecuteAuthRequest = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_EXECUTE_AUTH_REQUEST,
    data: data
  })
}

export const sendExecuteSalesRequest = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_EXECUTE_SALES_REQUEST,
    data: data
  })
}

/**
 * sent from cfd to pos in order to get the right order in state and stadium.
 * @param {Object} data
 */
export const sendOrderUpdate = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_ORDER_UPDATE,
    data: data
  })
}

export const sendPrintReceipt = (orderUuid, emails, phone) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_PRINT_RECEIPT,
    data: {
      orderUuid: orderUuid,
      emails: emails,
      phone: phone
    }
  })
}

export const bridge_hijackedExecuteSalesRequest = (total, orderNumber, orderTotal, isFinalPayment = false, orderUuid) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_PRINT_RECEIPT,
    data: {
      TEMP_activateCardReader: true,
      total: total,
      orderNumber: orderNumber,
      uuid: orderUuid,
      isFinalPayment: isFinalPayment, // This is used for split pay where sometimes the receipt doesn't pop up because the A3700 is still processing,
                                      // but the command to go to the receipt screen was already sent by the L1400.
      orderTotal: orderTotal, // in the case that total is not the full order total and the receipt needs to
                              // display the correct total
    }
  })
}

export const sendInitializeCFD = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.VN_INITIALIZE_CFD,
    data: data
  })
}
