import React from 'react'
import PropTypes from 'prop-types'

const IconOrderNext = ({ className }) => (
  <svg className={className} viewBox="0 0 371 60">
    <path d="M1 38.63c-.66-2.2-1-4.57-1-7.07C0 14.06 13.77 0 32.66 0c13.42 0 24.16 7.13 27.32 18.12L42.32 20.7c-1.95-3.53-5.75-5.78-10.65-5.78-8.55 0-14.52 7.4-14.52 15.8 0 6.73 4.84 11.67 12.1 11.67h.2a29.53 29.53 0 0 1-4.17 1.81C11.1 49.15.88 38.25.88 38.25l.11.38z" />
    <path d="M65.92 22.14l-.07 29.35c-.48.56-1.34.62-1.9.12l-6.88-5.99C53.69 50.31 44.86 60.8 29.15 59.7c-15.97-1.12-25.47-7.45-28.5-19 0 0 10.2 10.91 24.39 5.96a27.86 27.86 0 0 0 16.3-14.72l-6.68-5.8a1.37 1.37 0 0 1-.14-1.93l29.12-4.26c.82-.18 1.44-.45 2 .04.57.5.3 1.27.28 2.15zM273.92 44.67h-8.29l-7.26-14.78-3.25 14.78h-8.63l6.28-28.5h8.89l6.83 13.84 3.08-13.84h8.63zM303.14 44.67h-21.62l6.28-28.5h21.62l-1.66 7.52h-12.99l-.6 2.82h12.69l-1.67 7.52H292.5l-.68 3.12h12.99zM337.67 44.67h-9.75l-3.67-8.2-6.75 8.2h-10.6l13.12-15.13-6.2-13.37h9.7l3.21 7.61 5.94-7.61h10.64L331 30.53zM356.94 44.67h-8.64l4.62-20.98h-7.65l1.67-7.52h23.88l-1.66 7.52h-7.61zM96.04 46.53c-8.7 0-15.3-5.5-15.3-13.47 0-9.37 7.43-16.9 17.64-16.9 8.66 0 15.26 5.46 15.26 13.38 0 9.38-7.39 16.99-17.6 16.99zm.49-7.83c4.66 0 7.92-3.96 7.92-8.45 0-3.61-2.64-6.25-6.6-6.25-4.67 0-7.92 3.96-7.92 8.45 0 3.6 2.64 6.25 6.6 6.25zM142.47 46h-9.76l-2.03-9.24h-3.56L125.09 46h-8.88l6.46-29.35h14.26c5.32 0 9.86 3.48 9.86 8.89 0 5.2-3.4 9.2-7.22 10.56l2.9 9.9zm-8.22-16.98c1.93 0 3.34-.84 3.34-2.51 0-1.23-1.1-2.11-2.46-2.11h-5.28l-1.02 4.62h5.42zM162.1 46h-13.06l6.47-29.35h12.89c6.51 0 13.29 4.89 13.29 12.67 0 7.44-4.71 16.68-19.58 16.68zm.58-21.6l-3.04 13.86h4.1c5.54 0 8.75-3.79 8.75-8.28a5.47 5.47 0 0 0-5.72-5.58h-4.09zM206.69 46h-22.26l6.46-29.35h22.27l-1.72 7.75h-13.37l-.62 2.9h13.07l-1.72 7.74h-13.07l-.7 3.22h13.38zM239.92 46h-9.77l-2.02-9.24h-3.57L222.54 46h-8.89l6.47-29.35h14.26c5.32 0 9.85 3.48 9.85 8.89 0 5.2-3.39 9.2-7.21 10.56l2.9 9.9zm-8.23-16.98c1.94 0 3.35-.84 3.35-2.51 0-1.23-1.1-2.11-2.47-2.11h-5.28l-1 4.62h5.4z" />
  </svg>
)

IconOrderNext.propTypes = {
  className: PropTypes.string,
}

export default IconOrderNext
