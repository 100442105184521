export const KIOSK_PAYMENT_TYPES = {
  creditCard: "creditCard",
  mobileWallet: "mobileWallet",
  ticketTender: "loaded_ticket"
}

export const PAYMENT_TYPES = {
  CREDIT_CARD: 'freedompay_credit',
  CASH: 'cash',
  QR_PAY: 'wallet_nonce',
  TICKET: 'loaded_ticket',
  GIFT_CARD: 'gift_card',
  VN_API_QR_PAY: 'vnapi_qrpay',
  VN_API_TICKET: 'vnapi_ticket',
  VN_API_GIFT_CARD: 'vnapi_giftcard',
}

// FOR CFD these payment types can be used to determine what PaymentMethod
// is being used at the time of the transaction.
export const PAYMENT_TYPES_INTERNAL = {
  CREDIT_CARD: 'freedompay_credit',
  CASH: 'cash',
  QR_PAY: 'wallet_nonce',
  TICKET: 'loaded_ticket',
  GIFT_CARD: 'gift_card',
  VN_API_QR_PAY: 'vnapi_qrpay',
  VN_API_TICKET: 'vnapi_ticket',
  VN_API_GIFT_CARD: 'vnapi_giftcard',
  CUSTOM_TENDER: 'custom_tender',
  SAVED_CARD: 'savedCard',
}

// https://github.com/venuenext/venuenext_payments/blob/master/lib/venuenext_payments/payment_type.rb

const PAYMENT_TYPES_BY_INTEGER = {
  1: 'credit_card',
  3: 'apple_pay',
  4: 'android_pay',
  5: 'magic_money',
  6: 'visa_checkout',
  7: 'disney_app',
  8: 'disney_dining_plan',
  9: 'vn_bank',
  10: 'universal',
  11: 'asdp_snack',
  12: 'asdp_lunch',
  13: 'asdp_dinner',
  14: 'freedompay_credit',
  15: 'spreedly_credit_card',
  16: 'spreedly_google_pay',
  17: 'spreedly_apple_pay',
  19: 'cash',
  22: 'freedompay_token',
  23: 'freedompay_credit_pos',
  24: 'direct_bill',
  26: 'fnb_credit',
  27: 'house_account',
  28: 'manual_cc',
  29: 'shift4_token',
  30: 'shift4_credit',
}

const PAYMENT_TYPES_BY_NAME = {
  'credit_card': 1,
  'apple_pay': 3,
  'android_pay': 4,
  'magic_money': 5,
  'visa_checkout': 6,
  'disney_app': 7,
  'disney_dining_plan': 8,
  'vn_bank': 9,
  'universal': 10,
  'asdp_snack': 11,
  'asdp_lunch': 12,
  'asdp_dinner': 13,
  'freedompay_credit': 14,
  'spreedly_credit_card': 15,
  'spreedly_google_pay': 16,
  'spreedly_apple_pay': 17,
  'cash': 19,
  'freedompay_token': 22,
  'freedompay_credit_pos': 23,
  'direct_bill': 24,
  'fnb_credit': 26,
  'house_account': 27,
  'manual_cc': 28,
  'shift4_token': 29,
  'shift4_credit': 30,
}

const REMOTE_PAYMENT_METHODS = [
  'spreedly_credit_card', 15,
  'spreedly_google_pay', 16,
  'spreedly_apple_pay', 17,
  'freedompay_token', 22,
  'direct_bill', 24,
  'fnb_credit', 26,
  'house_account', 27,
  'manual_cc', 28,
  'shift4_token', 29,
]

const CARD_READER_TYPES = [
  'freedompay_credit', 14,
  'freedompay_credit_pos', 23,
  'shift4_credit', 30,
]

const MOBILE_WALLET_PAYMENT_METHODS = [
  'credit_card', 1,
  'apple_pay', 3,
  'android_pay', 4,
  'magic_money', 5,
  'visa_checkout', 6,
  'disney_app', 7,
  'disney_dining_plan', 8,
  'vn_bank', 9,
  'universal', 10,
  'asdp_snack', 11,
  'asdp_lunch', 12,
  'asdp_dinner', 13,
]

class PaymentTypes {
  static integerFromName = (name) => PAYMENT_TYPES_BY_NAME[name]

  static isCardReader = (identifier) => CARD_READER_TYPES.includes(identifier)

  // The definitive entry point for determining if an order is card required.
  static isCardRequired = (order) => {
    return !order?.paymentAuthorizedAt
  }

  static isCash = (identifier) => {
    return identifier === PAYMENT_TYPES_BY_NAME['cash'] || identifier === PAYMENT_TYPES_BY_INTEGER[19]
  }

  static isDirectBill = (identifier) => {
    return identifier === PAYMENT_TYPES_BY_NAME['direct_bill'] || identifier === PAYMENT_TYPES_BY_INTEGER[24]
  }

  static isFnbCredit = (identifier) => {
    return identifier === PAYMENT_TYPES_BY_NAME['fnb_credit'] || identifier === PAYMENT_TYPES_BY_INTEGER[26]
  }

  static isHouseAccount = (identifier) => {
    return identifier === PAYMENT_TYPES_BY_NAME['house_account'] || identifier === PAYMENT_TYPES_BY_INTEGER[27]
  }

  static isManualCc = (identifier) => {
    return identifier === PAYMENT_TYPES_BY_NAME['manual_cc'] || identifier === PAYMENT_TYPES_BY_INTEGER[28]
  }

  static isRemotePayment = (identifier) => !this.isCardReader(identifier) && !this.isMobileWalletPayment(identifier) && !this.isCash(identifier)

  static isMobileWalletPayment = (identifier) => MOBILE_WALLET_PAYMENT_METHODS.includes(identifier)

  static nameFromInteger = (integer) => PAYMENT_TYPES_BY_INTEGER[integer]

  // NOTE: (Jeff) temporary
  static isPaymentPending = (order) => order?.payment === 'Payment Pending'
}

export default PaymentTypes