import * as ActionTypes from './ActionTypes'

import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'latestReceived': Map({}),
    'version': "",
    'isKeyboardActive': false
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNANDROIDSDK_SET_DATA_RECIEVE:
      return state.set('latestReceived', Map(action.latestReceived))
    case ActionTypes.VNANDROIDSDK_SET_VERSION:
      return state.set('version', action.version)
    case ActionTypes.VNANDROIDSDK_IS_KEYBOARD_ACTIVE:
      return state.set('isKeyboardActive', action.isActive)
    default:
      return state
  }
}

export default reducer
