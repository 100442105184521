import React from 'react'
import PropTypes from 'prop-types'

const IconRemove = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <path d="M10 8.48L16.48 2 18 3.52 11.52 10 18 16.48 16.48 18 10 11.52 3.52 18 2 16.48 8.48 10 2 3.52 3.52 2z" />
  </svg>
)

IconRemove.propTypes = {
  className: PropTypes.string,
}

export default IconRemove
