/**
 * email field validation conforming to RFC 5322
 * @param {String} val - String value sent from field
 */
export const emailValidation = (val) => {
  
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (val.length <= 0 || !regex.test(val)) {
    return false
  }

  return true
}
