import moment from 'moment'
import { compact, filter, get, isFinite, join, map } from 'lodash'

import { TabOrderDetailsViewModel } from '../viewmodels/TabOrderViewModel'
import DeviceSubtypes from './deviceSubtypes'
import { centsToDollars } from './formatters'
import { createOrderParams } from './orderUtils'
import { getTenderInfo } from './orderDisplay'

const displayPriceToCents = (displayPrice) => {
  const chars = displayPrice.split('')
  const onlyNumbers = filter(chars, (char) => isFinite(parseInt(char)))

  return parseInt(join(onlyNumbers, ''))
}

const getDateAndTime = () => {
  const currentTime = moment()
  const date = currentTime.format('YYYY-MM-DD')
  const time = currentTime.format('hh:mm:ss A')

  return { date, time }
}

const makeLineItemsForPrinter = (order, hideFreeModifiers) => {
  const { groupedItems } = TabOrderDetailsViewModel(order)

  return map(groupedItems, (item) => {
    const { name, quantity } = item
    const modifiers = makeModifierItemsForPrinter(item, quantity, hideFreeModifiers)
    const priceInCents = displayPriceToCents(item.price) * quantity // item.price includes modifier pricing

    return {
      modifiers,
      name,
      quantity,
      total: priceInCents,
    }
  })
}

const makeModifierItemsForPrinter = (item, quantity, hideFreeModifiers) => {
  const modifiers = map(item.modifiers, (modifier) => {
    const modifierPriceInCents = get(modifier, 'defaultPriceInCents', 0)

    if (hideFreeModifiers && modifierPriceInCents === 0) return null

    return {
      name: modifier.name,
      total: modifierPriceInCents * quantity,
      quantity: quantity,
    }
  })

  return compact(modifiers)
}

const getUserName = (order) => {
  return createOrderParams(order)?.userName ?? order.userName
}

export const DEFAULT_PRINTERS = {
  NO_PRINTER: {
    uuid: 'none',
    name: 'None'
  },
  USB: {
    uuid: 'usb',
    name: 'USB/Device Printer'
  }
}

export const createPrinterData = ({ deviceSubtype, menu, order, serviceChargeLegal, venueName, attendantName, target = 'USB' }) => {
  const { date, time } = getDateAndTime()

  const userName = getUserName(order)
  const formatName = (name) => {
    // cash orders will not have a name associated with them
    if (!name) return ""

    const firstAndLast = name.split(" ")
    if(firstAndLast.length < 2) {
      return firstAndLast[0]
    }
    return `${firstAndLast[0]} ${firstAndLast[1].charAt(0)}.`
  }

  const deviceType = DeviceSubtypes.printerDeviceType(deviceSubtype)

  const lineItems = makeLineItemsForPrinter(order, false)
  const tenderInfo = getTenderInfo(order)
  const paymentDescription = tenderInfo?.cardIssuer ? `${tenderInfo?.cardIssuer} ${tenderInfo?.displayText}` : tenderInfo?.displayText

  const payload = {
    venueName,
    date,
    deviceType,
    lineItems,
    menuName: menu.name,
    orderNumber: order.orderNumber,
    receiptType: 'receipt',
    subtotal: order.subtotalAmountInCents,
    target,
    time,
    total: order.amountInCents,
    userNotes: order?.userNotes,
    paymentTip: centsToDollars(order.tipAmountInCents ?? 0),
    isMobileOrder: !!order?.isRichCheckout,
    attendantName: formatName(attendantName),
  }

  if (userName) {
    payload.userName = userName
  }

  if (order?.discountAmountInCents !== 0) {
    payload.discount = Math.abs(order.discountAmountInCents)
  }

  if (order.taxAmountInCents !== 0) {
    payload.taxTotal = order.taxAmountInCents
  }

  if (order?.serviceFee?.total > 0) {
    payload.serviceFees = [order.serviceFee]
    payload.postTotalNote = serviceChargeLegal
  }

  payload.payments = map(createOrderParams(order)?.payments, (payment) => {
    return {
      total: payment.amountInCents,
      description: payment?.shortDescription ?? paymentDescription,
    }
  })

  return payload
}

export default createPrinterData
