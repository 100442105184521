export const swapCaseFirstLetter = (inputString, capitalize = false) => {
  if (inputString.length > 0) {
    
    if (capitalize) {
      return inputString[0].toUpperCase() + inputString.substring(1)
    }

    return inputString[0].toLowerCase() + inputString.substring(1)
  } else {
    return inputString;
  }
}
