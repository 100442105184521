import React, { forwardRef, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Typography  from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'

import { useTheme } from "@material-ui/core/styles"
import { VNButton } from 'vn-react-components/VNComponents/VNButton'
import { VNTimeoutButton } from 'vn-react-components/VNComponents/VNTimeoutButton'

const useStyles = makeStyles(theme => ({
  title: theme.typography.h2,
  subTitle: theme.typography.h3,
  header: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

/**
 * A VN Dialog that pops up and provides an input field with cancel and save buttons.
 * @param {string} title - The title to display
 * @param {string} image - if title is not present use the image | SVG React Component
 * @param {string} subTitle - The subtitle to display
 * @param {string} label - The title to display above the input field
 * @param {bool} open - the state of the dialog if it is open or closed
 * @param {bool} showTextfield - If the input textfield should be shown
 * @param {object} rules -
 * - validation: (val) => {} - called when the dialog wants to validate the input field
 * - showHelperText: (val) => {} - what helper text to show beneath the input field
 * - onBlur: (val) => {} - Called when you blur the input field, returns a value to set the input field
 * @param {string} startAdornment - If there is an adornment to display at the front
 * @param {func} onSecondaryButtonClick - Called when the dialog wants to close
 * @param {func} onPrimaryButtonClick - Called when the dialog primary button is clicked
 * @param {boolean} primaryButtonDisabled - disabled primary button.
 * @param {boolean} secondaryButtonDisabled - disabled secondary button.
 * @param {string} type - The type of input field you want: default is 'text'
 * @param {string} secondaryButtonText -
 * @param {string} primaryButtonText -
 * @param {boolean} fullWidth - sets the VnDialog to the full width
 * @param {boolean} closeOnPrimaryButtonClicked - call close function on save
 * @param {number} primaryButtonCountdownTime add a countdown time for the primary button.
 * @param {number} width override default width
 * @param {number} height override default height
 * @param {string} subtitleVariant override subtitle variant with another IE theme.typography.h5
 */
export const VNDialog = forwardRef((props, ref) => {

  const theme = useTheme()
  const classes = useStyles()

  // INTERNAL STATE

  // the current input of the dialog text
  const [inputVal, setInputVal] = useState('')
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(false)
  const [secondaryButtonDisabled, setSecondaryButtonDisabled] = useState(false)

  // HANDLES

  // called on each character typed into the input field
  const onChange = (e) => {
    if (props.rules && props.rules.validation) {
      if (props.rules.validation(e.target.value)) {
        setInputVal(e.target.value)
      }
    } else {
      setInputVal(e.target.value)
    }
  }

  // called when the save button is clicked
  const handlePrimaryButtonClick = () => {
    setPrimaryButtonDisabled(true)
    // prevents double clicking
    setTimeout(() => setPrimaryButtonDisabled(false), 1000)
    if (props?.onPrimaryButtonClick) {
      props.onPrimaryButtonClick(inputVal)
    }
    if (!props.closeOnPrimaryButtonClicked) {
      return
    }
    if (props?.onSecondaryButtonClick) {
      props.onSecondaryButtonClick()
    }
  }

  // called when cancel button is clicked
  const handleSecondaryButtonClick = () => {
    setSecondaryButtonDisabled(true)
    // prevents double clicking
    setTimeout(() => setSecondaryButtonDisabled(false), 1000)
    if (props.onSecondaryButtonClick) {
      props.onSecondaryButtonClick()
    }
  }

  // called on input blur
  const handleBlur = (event) => {
    if (props.rules && props.rules.onBlur) {
      const newVal = props.rules.onBlur(event.target.value)
      setInputVal(newVal)
    }
  }

  // LOCAL FUNCTIONS

  // Get the limits for the text field
  const showHelperText = () => {
    if (props.rules && props.rules.showHelperText) {
      return props.rules.showHelperText(inputVal)
    }

    return ''
  }

  // returns an error if the validation doesn't pass
  const getInputError = () => {
    if (props.rules && props.rules.validation) {
      return !props.rules.validation(inputVal)
    }

    return false
  }

  const renderTitle = () => {
    if (props.title) {
      return (
        <Typography className={classes.title} align='center' id="form-dialog-title">
          {props.title.split("\n").map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
        </Typography>
      )
    }
  }

  const renderImage = () => {
    if (props.image) {
      return props.image
    }
  }

  const getStyle = () => {
    return {
      ...theme.typography[props.subtitleVariant],
      ...{flex: 0, textAlign: 'center', fontWeight: 500}
    }
  }

  const getPrimaryButton = () => {
    if (props.primaryButtonCountdownTime) {
      return (
        <VNTimeoutButton disabled={primaryButtonDisabled} buttonVariant={'button5'} text={props.primaryButtonText} onClick={handlePrimaryButtonClick} countdownTime={props.primaryButtonCountdownTime} color='primary'/>
      )
    }

    if (props?.primaryButtonText) {
      return (
        <VNButton disabled={primaryButtonDisabled || props.primaryButtonDisabled || (getInputError() ? true : false)} onClick={handlePrimaryButtonClick} buttonVariant={'button5'} color={"primary"} text={props.primaryButtonText}/>
      )
    }

    return
  }

  return (
    <Dialog fullWidth={props.fullWidth ? props.fullWidth : true} open={props.open} PaperProps={{style: {
        backgroundColor: theme.custom.colors.backgroundColor,
        width: props.width ? props.width : theme.custom.defaultDialog.width,
        height: props.height ? props.height : theme.custom.defaultDialog.height,
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column'
      }}}
      transitionDuration={{enter: 0, appear: 0, exit: 0}}
      transition
    >
      <Box className={classes.header}>
        <Box pb={3} pt={1} width={'100%'}>
          {renderTitle()}
          {renderImage()}
        </Box>
        <DialogContent style={{flex: 0, overflowY: 'inherit', width: '100%'}}>
          {
            props.subTitle &&
            <Typography className={classes.subTitle} style={getStyle()}>
              {props.subTitle.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          }
          {props.customContent}
          {
            props.showTextfield &&
            <TextField
              error={getInputError()}
              autoFocus
              multiline
              margin="dense"
              id="vndialog"
              label={props.label}
              type={props.type ? props.type : 'text'}
              fullWidth
              value={inputVal}
              onChange={onChange}
              helperText={showHelperText()}
              onBlur={handleBlur}
              style={{
                color: theme.custom.colors.mainTextColor,
                background: theme.custom.colors.greyMedium
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{props.startAdornment ? props.startAdornment : ''}</InputAdornment>,
              }}
            />
          }
        </DialogContent>
      </Box>
      <Box className={classes.buttonContainer}>
        <DialogActions style={{...theme.custom.buttonContainer}}>
          {
            props.secondaryButtonText &&
            <VNButton disabled={secondaryButtonDisabled || props.secondaryButtonDisabled ? true : false} onClick={handleSecondaryButtonClick} buttonVariant={'button5'} color="primary" text={props.secondaryButtonText}/>
          }
          {getPrimaryButton()}
        </DialogActions>
      </Box>
    </Dialog>
  )
})
