import { toast } from "react-toastify"
import PeripheralBridge from "./peripheralBridge"
import { codeAlreadyUsed } from "../VNUtil/VNUtils"
import { ToastManager } from "./toastManager"

export const toastError = (message, classSize, isMobile) => {
  const options = {
    autoClose: true,
  }
  if (!isMobile) {
    options.position = 'top-left'
    options.className = `toast-scan-screen-${classSize}`
  }
  ToastManager.error(message, options)
}

export const onMountScanScreen = (handleFocusChange) => {
  document.addEventListener('focusin', handleFocusChange)
  document.addEventListener('focusout', handleFocusChange)

  return () => {
    document.removeEventListener('focusin', handleFocusChange)
    document.removeEventListener('focusout', handleFocusChange)
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return
    bridgeInstance.call('stopScanner')
  }
}

export const makeSegmentChoices = (paymentType) => {
  return [
    { label: `Scan ${paymentType}`, value: 'scan' },
    { label: `Type ${paymentType}`, value: 'entry' },
  ]
}

export const registerScanListeners = ({ order, onSuccess, paymentType, setSelectedSubmissionType, isMobile, setScanError }) => {
  const bridgeInstance = PeripheralBridge.getBridge()
  bridgeInstance.registerHandler('scanPayload', function (data, callback) {
    const scannedCode = data?.valueOf()
    if (codeAlreadyUsed(order, scannedCode)) {
      toastError(`${paymentType} already scanned and applied to order`, 'md', isMobile)
      return
    }

    setScanError(false)
    onSuccess(scannedCode)
  })

  bridgeInstance.registerHandler('scanError', function (err, callback) {
    if (err?.toLowerCase()?.includes('no scanner detected')) {
      setSelectedSubmissionType('entry')
    }
    toastError(`${paymentType} scan error, ERROR: ${JSON.stringify(err)}`, 'lg', isMobile)
    setScanError(true)
  })
}
