import React from 'react'
import PropTypes from 'prop-types'

const IconExit = ({ className }) => (
  <svg className={className} viewBox="0 0 35 35">
    <path d="M22 23.4L19.07 26 12 18.44 18.87 10l3.1 2.4-4.78 5.86z" />
  </svg>
)

IconExit.propTypes = {
  className: PropTypes.string,
}

export default IconExit
