import { select } from 'redux-saga/effects'
import { get, set, isEmpty, map, forEach, reduce, times, cloneDeep } from 'lodash'

import CartItemViewModel from '../viewmodels/CartItemViewModel'
import { centsToDollars, centsToFloat } from './formatters'
import { STADIUM_ORDER_STATES } from './orderStates'

const generateUuid = require('uuid/v4')

// TODO(mkramerl): transform the existing order into what is needed here.
export const createOrderParams = (order) => {
  return order?.createOrderParams
}

// The definitive entry point for determining if an order is approved offline.
export const getOrderIsApprovedOffline = (order) => {
  if (createOrderParams(order)?.state === STADIUM_ORDER_STATES.TABBED_OFFLINE) {
    return false
  }

  return (createOrderParams(order)?.state === STADIUM_ORDER_STATES.APPROVED_OFFLINE) || (!order?.syncedAt && (createOrderParams(order)?.state !== STADIUM_ORDER_STATES.TABBED))
}

// The definitive entry point for determining if an order is unsyncable.
export const getOrderIsUnsyncable = (order) => {
  return !!order?.unsyncable
}

export const orderDate = (order) => {
  return order?.updatedAt || order?.modifiedAt || order?.submittedAt
}

const expandLineItems = (lineItems) => {
  const clonedLinedItems = cloneDeep(lineItems)

  const result = reduce(clonedLinedItems, (accumulator, lineItem) => {
    const clones = times((lineItem.quantity || 1), () => {
      const clone = cloneDeep(lineItem)
      clone.quantity = 1

      return clone
    })

    return [ ...accumulator, ...clones ]
  }, [])

  return result
}

const calculateTotal = (cartItems) => {
  const total = reduce(cartItems, (accumulator, item) => {
    const quantity = get(item, 'quantity', 1)
    const itemModifiers = get(item, 'modifiers', [])
    const itemSubtotal = get(item, 'defaultPriceInCents', 0) * quantity

    const modifiersSubtotal = reduce(itemModifiers, (acc, modifierItem) => {
      const priceInCents = get(modifierItem, 'defaultPriceInCents', 0)
      return acc + priceInCents * quantity
    }, 0)

    const lineItemTotal = itemSubtotal + modifiersSubtotal

    return accumulator + lineItemTotal
  }, 0)

  return total
}

export const parseModifiers = (modifiers) => {
  const mappedModifiers = map(modifiers, (modifier) => {
    if (!modifier.lineItemUuid) {
      const modUuid = generateUuid()
      set(modifier, 'lineItemUuid', modUuid)
      set(modifier, 'uuid', modUuid)
    }

    return {
      menuItemUuid: modifier.id,
      quantity: 1,
      uuid: modifier.lineItemUuid,
      price: centsToDollars(modifier.defaultPriceInCents).slice(1),
      priceInCents: modifier.defaultPriceInCents,
    }
  })

  return [mappedModifiers]
}

// Makes general order lineItem data to be used locally
// sets lineItemUuids on each item and item modifier used for refunds.
export const makeLineItemData = (lineItems) => {
  const expandedLineItems = expandLineItems(lineItems)

  const itemModels = expandedLineItems.map((lineItem, index) => {
    // since the view model is memoizing, passing in index as the first attribute
    // to make sure they are all treated uniquely
    return CartItemViewModel({ ...index, ...lineItem })
  })

  forEach(itemModels, (item) => {
    set(item, 'lineItemUuid', generateUuid())
    forEach(item?.modifiers, (modifier) => {
      set(modifier, 'lineItemUuid', generateUuid())
    })
  })

  return itemModels
}

// Create orderMenuItems data for platform order creation
export const makeCreateOrderLineItems = (itemModels = []) => {
  return itemModels.map((item) => {
    const modifiers = parseModifiers(item.modifiers)

    let obj = {
      uuid: item.lineItemUuid,
      menuItemUuid: item.id,
      quantity: 1,
      price: +centsToFloat(item.priceInCents),
      modifiers,
      notes: item.notes,
    }

    if (item.userAttributes) {
      obj.userAttributes = item.userAttributes
    }

    return obj
  })
}

export const getUserNameFromCard = (cardReaderData) => {
  const name = cardReaderData.cardholderName || "/"
  const invalidNameRegex = /\S*\d+\S*/g

  if (invalidNameRegex.test(name)) {
    return ''
  }

  // example: "OBERG/PETER               "
  // super defensive with the string methods below because I don't know much about different possibilities
  const split = name.split("/")
  const firstName = (split[1] || "").trim()
  const lastName = (split[0] || "").trim()
  const fullName = `${firstName} ${lastName}`
  const result = fullName.trim()

  return result
}

// called user_attributes in stadium, but it mostly contains credit card attributes
export const makeUserAttributes = (cardReaderData) => {
  const { authResponseData, receiptData } = cardReaderData

  const { authorizationCode, processorTransactionId, reconciliationId } = authResponseData || {}
  const { approvalCode, authStatus, maskedCardNumber, maskedPan } = receiptData || {}

  const safeFour = maskedCardNumber || ""
  const lastFour = safeFour.substring(safeFour.length - 4)

  return {
    last_4: lastFour,

    // needed in this format for refunds
    freedompayRequestId: cardReaderData.requestId,
    freedompayMerchantReferenceCode: cardReaderData.merchantReferenceCode || cardReaderData.uuid,
    freedompayInvoiceNumber: cardReaderData.invoiceNumber,
    freedompayIssuerName: cardReaderData.cardIssuer || cardReaderData.cardType,
    freedompayStoreId: cardReaderData.storeId,
    freedompayTerminalId: cardReaderData.terminalId,

    // extra data to help troubleshoot customer charge back requests
    authorizationCode,
    processorTransactionId,
    reconciliationId,
    approvalCode,
    authStatus,
    maskedCardNumber,
    maskedPan,
    token: cardReaderData?.tokenInformation?.token,
  }
}

export const getModifiersWithItems = (itemModels) => {
  let totalInCents = 0
  let allItems = []

  itemModels.forEach(item => {

    let itemCost = item.priceInCents

    allItems.push({ ...item, price: centsToDollars(item.priceInCents) })

    if (item.modifiers) {
      item.totalModifiersWithCost = 0
      item.modifiers.forEach(modifier => {
        itemCost += modifier.defaultPriceInCents
        modifier.priceInCents = modifier.defaultPriceInCents
        modifier.quantity = 1
        modifier.fractionalQuantity = 1
        item.totalModifiersWithCost++
        allItems.push({ ...modifier, price: centsToDollars(modifier.defaultPriceInCents) })
      })
    }

    totalInCents += itemCost
  })

  return {
    items: allItems,
    totalCostInCents: totalInCents
  }
}

export const nonZeroDisplayAmount = (displayAmount) => !!displayAmount && displayAmount !== '$0.00'

export const numberAsCurrency = (floatAmount) => floatAmount ? ('$' + floatAmount.toFixed(2).toString()) : '$0.00'

export const taxExemptable = (lineItem) => {
  // TODO(westinschepper): It seems dangerous to assume their is only one tax rule.
  const taxRule = lineItem?.taxRules?.[0] ?? {}

  return taxRule.exempt ?? true
}

export const getServiceChargeLineItem = (lineItems) => {
  return lineItems?.find?.((lineItem) => ['service_charge', 'taxable_service_charge'].includes(lineItem?.specialType))
}

export const cardReaderApproved = (cardReaderData) => {
  const status = cardReaderData?.status
  const approvalStatus = cardReaderData?.approvalStatus

  return status === 'ACCEPT' || status === 'APPROVED OFFLINE' || approvalStatus === 'ACCEPT'
}

export const orderSummaryKeys = ['gross', 'tips', 'discounts', 'refunds', 'net', 'pendingRefundCount', 'cash', 'creditCard', 'virtualCurrency', 'tax'];

export const inValidOrderStates = ['submitted', 'submission_failed', 'authorization_failed']

export const getIsPreAuthTab = (order) => order?.cardReaderData?.transType === "auth" || !!order?.paymentAuthorizedAt
