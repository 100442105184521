import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import cn from 'classnames'

import styles from './CreditCards.module.scss'

const CreditCards = ({ cards, className }) => {
  const classNames = cn(styles.creditCards, className)

  return (
    <div className={classNames}>
      {map(cards, ({ issuer, icon }) => (
        <img className={styles.creditCard} src={icon} alt={issuer} key={issuer} />
      ))}
    </div>
  )
}

CreditCards.defaultProps = {
  cards: [],
}

CreditCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    issuer: PropTypes.string,
    icon: PropTypes.string,
  })),
}

export default CreditCards
