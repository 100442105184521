import { connect } from 'react-redux'

import ConcessionsCartSidebar from '../components/ConcessionsCartSidebar'

import { centsToDollars } from '../utils/formatters'

import { startATab, addItemsToTab, addItemsToTabOffline, clearOrderInProgress } from '../actions/order'
import { menusSucceeded } from '../actions/menus'
import { clearCart } from '../actions/cart'
import { clearPromotionsOnOrder } from '../actions/promotion'

import { getTabsModeEnabled } from '../selectors/menus'
import { getTotal, hasCartItems2, getCartItems, getTaxByItem, getTaxRate, getSubtotal } from '../selectors/cart'
import { getMenuServiceCharge } from '../selectors/items'
import { getTipsEnabled, getStands, makeGetCurrentMenu  } from '../selectors/menus'
import { getDiscountsAvailable } from '../selectors/promotion'
import {
  getCashEnabled,
  getGiftCardEnabled,
  getMobilePaySetting,
  getTicketEnabled,
  makeGetNoOpTenders
} from '../selectors/revenueCenter'
import { getMutationInProgress } from '../selectors/order'

const mapStateToProps = (state) => {
  const noOpTenders = makeGetNoOpTenders()(state)
  const cashEnabled = getCashEnabled(state)
  const tipsEnabled = getTipsEnabled(state)
  const total = getSubtotal(state)
  const formattedTotal = centsToDollars(total)
  const offlineTotal = getTotal(state)
  const stands = getStands(state)
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const tipSuggestions = menu?.tipSuggestions
  const ticketEnabled = getTicketEnabled(state)
  const discountsAvailable = getDiscountsAvailable(state)
  const mobilePaySetting = getMobilePaySetting(state)
  const giftCardEnabled = getGiftCardEnabled(state)
  const cartItems = getCartItems(state)
  const taxRate = getTaxRate(state)
  const taxByItem = getTaxByItem(state)
  const menuServiceCharge = getMenuServiceCharge(state)
  const tabsModeEnabled = getTabsModeEnabled(state)

  return {
    tabsModeEnabled,
    hasCartItems: hasCartItems2(state),
    mutationInProgress: getMutationInProgress(state),
    discountsAvailable,
    noOpTenders,
    stands,
    menu,
    tipSuggestions,
    cashEnabled,
    ticketEnabled,
    mobilePaySetting,
    giftCardEnabled,
    tipsEnabled,
    cartItems,
    taxRate,
    taxByItem,
    menuServiceCharge,
    offlineTotal,
    total: formattedTotal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearCart: () => dispatch(clearCart()),
    clearPromotionsOnOrder: () => dispatch(clearPromotionsOnOrder()),
    setMenus: (stand, menu) => { dispatch(menusSucceeded({ data: {stands: [stand], menus: [menu]} })) },
    startATab: (payload) => {
      dispatch(startATab(payload))
    },
    addItemsToTab: () => {
      dispatch(addItemsToTab())
    },
    addItemsToTabOffline: () => {
      dispatch(addItemsToTabOffline())
    },
    clearOrderInProgress: () => {
      dispatch(clearOrderInProgress())
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConcessionsCartSidebar)
