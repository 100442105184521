
import * as ActionTypes from './ActionTypes'
import { List, Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'configurations': Map({}),
    'orders': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNPPI_SET_CONFIGURATIONS:
      return state.set('configurations', Map(action.configs))
    case ActionTypes.VNPPI_SET_ORDER_PAYMENTS:
      return state.setIn(['orders', action.orderUuid, 'payments'], List(action.payload))
    case ActionTypes.VNPPI_SET_PRE_ORDER_PAYMENTS:
      return state.setIn(['orders', action.orderUuid, 'preOrderPayments'], List(action.payload))
    case ActionTypes.VNPPI_ADD_SCANNED_TENDER_TO_ORDER: 

      let currentScannedTenders = state.getIn(['orders', action.orderUuid, 'scannedTenders'])
      
      if (!currentScannedTenders) {
        currentScannedTenders = List([])
      }

      const newList = currentScannedTenders.push(action.token)

      return state.setIn(['orders', action.orderUuid, 'scannedTenders'], newList)
    default:
      return state
  }
}

export default reducer
