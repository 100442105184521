export const LOAD_REVENUE_CENTER_REQUESTED = 'LOAD_REVENUE_CENTER_REQUESTED'
export const LOAD_REVENUE_CENTERS_SUCCEEDED = 'LOAD_REVENUE_CENTERS_SUCCEEDED'
export const LOAD_REVENUE_CENTER_FAILED = 'LOAD_REVENUE_CENTER_FAILED'
export const SET_ACTIVE_REVENUE_CENTER = 'SET_ACTIVE_REVENUE_CENTER'

export const loadRevenueCenters = (payload) => ({
  type: LOAD_REVENUE_CENTER_REQUESTED,
  payload,
})

export const loadRevenueCentersSucceeded = (payload) => ({
  type: LOAD_REVENUE_CENTERS_SUCCEEDED,
  payload,
})

export const loadRevenueCentersFailed = (payload) => ({
  type: LOAD_REVENUE_CENTER_FAILED,
  payload,
})

export const setActiveRevenueCenter = (revenueCenterId) => ({
  type: SET_ACTIVE_REVENUE_CENTER,
  payload: { revenueCenterId }
})
