import { put, select, takeLatest } from 'redux-saga/effects'
import { get, reduce, isEmpty, find, findIndex, isInteger, map } from 'lodash'

import { NOTES_CHARACTER_LIMIT } from '../constants'
import { makeGetItemWithCartQuantity } from '../selectors/items'
import { makeGetCartItemByIndex } from '../selectors/cart'
import {
  addToCart,
  addToCartOrIncrement,
  incrementCartItemQuantity,
  decrementCartItemQuantity,
  updateCartItemQuantity,
  updateCartItemNotes,
  safelyIncrementCartItemQuantity,
  safelyDecrementCartItemQuantity,
  safelyUpdateCartItemQuantity,
  safelyUpdateCartItemNotes,

} from '../actions/cart'

const getInteger = (subject, defaultValue = 1) => {
  return isInteger(subject) ? subject : defaultValue
}

const findCartItem = (state, itemId) => {
  const cartLineItems = get(state, 'cart.lineItems', [])

  return {
    cartItem: find(cartLineItems, ['itemId', itemId]),
    index: findIndex(cartLineItems, ['itemId', itemId])
  }
}

export function* addOrUpdateCartItemSaga(params = {}) {
  const getItem = makeGetItemWithCartQuantity()

  const itemId = get(params, 'payload.itemId', '')
  const item = yield select(getItem, itemId)
  const orderMaxAmount = getInteger(get(item, 'orderMaxAmount'), 999)
  const orderMinCount = getInteger(get(item, 'orderMinCount'), 1)
  const isAlcohol = get(item, 'isAlcohol')

  if (item.cartQuantity === orderMaxAmount) return

  if (isEmpty(item.modifierGroups)) {
    const { cartItem, index } = yield select(findCartItem, itemId)

    if (!isEmpty(cartItem)) {
      yield put(safelyIncrementCartItemQuantity({ index }))
    } else {
      let quantity = orderMinCount
      let modifiers = []

      if (item.cartQuantity >= orderMinCount) {
        quantity = 1
      }

      yield put(addToCart({ itemId, modifiers, quantity, isAlcohol }))
    }
  } else {
    const defaultModifiers = reduce(item.modifierGroups, (acc, modifierGroup) => {
      const modiferIdsPlusGroupId = map(modifierGroup.defaults, (id) => {
        return `${id}|${modifierGroup.uuid}`
      })
      return [...acc, ...modiferIdsPlusGroupId]
    }, [])
    let quantity = orderMinCount

    if (item.cartQuantity >= orderMinCount) {
      quantity = 1
    }

    yield put(addToCart({ itemId, modifiers: defaultModifiers, quantity, isAlcohol }))
  }
}

export function* watchAddOrUpdateCartItemSaga() {
  yield takeLatest(addToCartOrIncrement.type, addOrUpdateCartItemSaga)
}

export function* safelyIncrementCartItemQuantitySaga(params = {}) {
  const getCartItemByIndex = makeGetCartItemByIndex()
  const getItemWithCartQuantity = makeGetItemWithCartQuantity()

  const index = get(params, 'payload.index', -1)
  const cartItem = yield select(getCartItemByIndex, index)
  const itemId = get(cartItem, 'itemId')
  const item = yield select(getItemWithCartQuantity, itemId)
  const orderMaxAmount = getInteger(get(item, 'orderMaxAmount'), 999)
  if (item.cartQuantity + 1 <= orderMaxAmount) {
    yield put(incrementCartItemQuantity({ index }))
  }
}

export function* watchSafelyIncrementCartItemQuantitySaga() {
  yield takeLatest(safelyIncrementCartItemQuantity.type, safelyIncrementCartItemQuantitySaga)
}

export function* safelyDecrementCartItemQuantitySaga(params = {}) {
  const getCartItemByIndex = makeGetCartItemByIndex()
  const getItemWithCartQuantity = makeGetItemWithCartQuantity()

  const index = get(params, 'payload.index', -1)
  const cartItem = yield select(getCartItemByIndex, index)
  const itemId = get(cartItem, 'itemId')
  const item = yield select(getItemWithCartQuantity, itemId)
  const orderMinCount = getInteger(get(item, 'orderMinCount'), 1)

  if (item.cartQuantity - 1 >= orderMinCount) {
    yield put(decrementCartItemQuantity({ index }))
  }
}

export function* watchSafelyDecrementCartItemQuantitySaga() {
  yield takeLatest(safelyDecrementCartItemQuantity.type, safelyDecrementCartItemQuantitySaga)
}

export function* safelyUpdateCartItemQuantitySaga(params = {}) {
  const getCartItemByIndex = makeGetCartItemByIndex()
  const getItemWithCartQuantity = makeGetItemWithCartQuantity()

  const index = get(params, 'payload.index', -1)
  const quantity = get(params, 'payload.quantity', 1)

  const cartItem = yield select(getCartItemByIndex, index)
  const itemId = get(cartItem, 'itemId')
  const item = yield select(getItemWithCartQuantity, itemId)
  const trueCartQuantity = item.cartQuantity - cartItem.quantity
  const orderMinCount = getInteger(get(item, 'orderMinCount'), 1)
  const orderMaxAmount = getInteger(get(item, 'orderMaxAmount'), 999)
  const quantityChangeDirection = Math.sign(quantity - cartItem.quantity)

  if (quantityChangeDirection === 1) {
    const result = trueCartQuantity + quantity

    if (result <= orderMaxAmount) {
      yield put(updateCartItemQuantity({ index, quantity }))
    } else {
      const maxAllowedAmount = orderMaxAmount - trueCartQuantity
      yield put(updateCartItemQuantity({ index, quantity: maxAllowedAmount }))
    }
  }

  if (quantityChangeDirection === -1) {
    const result = trueCartQuantity - quantity

    if (result >= orderMinCount) {
      yield put(updateCartItemQuantity({ index, quantity }))
    } else {
      const minAllowedAmount = orderMinCount - trueCartQuantity
      yield put(updateCartItemQuantity({ index, quantity: minAllowedAmount }))
    }
  }
}

export function* watchSafelyUpdateCartItemQuantitySaga() {
  yield takeLatest(safelyUpdateCartItemQuantity.type, safelyUpdateCartItemQuantitySaga)
}

export function* safelyUpdateCartItemNotesSaga(params = {}) {
  const index = get(params, 'payload.index', -1)
  const notes = get(params, 'payload.notes', '')
  const trimmedNotes = notes.substring(0, NOTES_CHARACTER_LIMIT)

  yield put(updateCartItemNotes({ index, notes: trimmedNotes }))
}

export function* watchSafelyUpdateCartItemNotesSaga() {
  yield takeLatest(safelyUpdateCartItemNotes.type, safelyUpdateCartItemNotesSaga)
}
