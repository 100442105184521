import { map } from 'lodash'
import {
  ADD_PROMOTIONS_TO_ORDER,
  CLEAR_PROMOTIONS_ON_ORDER,
  FETCH_PROMOTIONS_SUCCEEDED,
  FETCH_PROMOTIONS_FAILED,
} from '../actions/promotion'

const initialState = {
  promotions: [],
  selectedPromotions: [],
}

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_PROMOTIONS_SUCCEEDED: {
    
      return {
        ...state,
        promotions: action?.payload?.promotions ?? []
      }
    }

    case FETCH_PROMOTIONS_FAILED: {

      return {
        ...state,
      }
    }

    case ADD_PROMOTIONS_TO_ORDER: {
      const selectedPromotions = map(
        action.payload?.selectedPromotions ?? [],
        (promotion) => ({
          uuid: promotion?.uuid,
          name: promotion?.name,
          redemptionMethod: promotion?.redemptionMethod ?? "manual",
        })
      );

      return {
        ...state,
        selectedPromotions,
      }
    }

    case CLEAR_PROMOTIONS_ON_ORDER: {

      return {
        ...state,
        selectedPromotions: [],
      }
    }

    default:
      return state
  }
}

export default promotionsReducer
