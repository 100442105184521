import { createSelector } from 'reselect';
import { filter, values, get, keys, sumBy, flatMap, includes, groupBy, map, isEmpty } from 'lodash'

import { getCurrentMenuId } from './appState'
import { getSelectedMenusIds } from './config'
import { getCurrentMenuCategories } from './categories'
import { getItemCategories } from './itemCategory'
import { getCartLineItems } from './cart'

const getKioskItems = state => state.items

export const getItemsForCurrentMenu = createSelector(
  getCurrentMenuId,
  getKioskItems,
  (currentMenuId, items) => get(items, ['byMenuId', currentMenuId], {})
)

const getItemsValuesForCurrentMenu = createSelector(
  getItemsForCurrentMenu,
  (currentMenuItems) => values(get(currentMenuItems, 'byId', {}))
)

const getItemsIdsForCurrentMenu = createSelector(
  getItemsForCurrentMenu,
  (currentMenuItems) => get(currentMenuItems, 'allIds', [])
)

export const getCurrentMenuItemsFilteredByCategory = (state, categoryId) => {
 return filter(getItemsValuesForCurrentMenu(state), item => includes(item.itemCategoryUuids, categoryId))
}

export const getItemsImages = createSelector(
  getKioskItems,
  getSelectedMenusIds,
  (items, selectedMenuIds) => flatMap(selectedMenuIds, id => {
    const menuItems = values(get(items, ['byMenuId', id, 'byId']))
    return menuItems
      .map(item => get(item, 'images.detail'))
      .filter(image => !!image)
  })
)

export const getAreItemsLoaded = createSelector(
  getKioskItems,
  getSelectedMenusIds,
  (items, selectedIds) => {
    const itemKeys = keys(items.byMenuId)
    return selectedIds.every(id => itemKeys.includes(id))
  }
)

export const getItems = (state) => {
  const itemIds = getItemsIdsForCurrentMenu(state)
  const getItem = makeGetItemWithCartQuantity()
  const categories = get(getCurrentMenuCategories(state), 'byId', {})
  const items = itemIds.map((itemId) => {
    let item = getItem(state, itemId)
    item = {
      ...item,
      color: get(categories, `${item.itemCategoryUuid}.color`, null)
    }

    return item
  })

  return items
}

export const getItemsWithQuantity = (state) => {
  const itemIds = getItemsIdsForCurrentMenu(state)
  const categories = getItemCategories(state)

  const items = itemIds.map((itemId) => {
    let item = getItemWithCartQuantity(state, itemId)
    item = {
      ...item,
      color: get(categories, `${item.itemCategoryUuid}.color`, null)
    }

    return item
  })

  const multiCategoryItems = flatMap(items, (item) => (item.itemCategoryUuids.length > 0 ? item.itemCategoryUuids : [null]).map((categoryId) => {
    return { ...item, itemCategoryUuid: categoryId, color: get(categories, `${categoryId}.color`, null) }
  }))

  const groups = groupBy(multiCategoryItems, 'itemCategoryUuid')
  const sortedItems = flatMap((map(categories, category => category.itemCategoryUuid)).concat(null), categoryId => groups[categoryId])

  return filter(sortedItems, ['modifier', false])
}

export const getMenuItems = createSelector(
  getItems,
  (items) => filter(items, ['modifier', false])
)

// Return : An object containing the service charge rate; whether the service charge is taxable;
// the name of the service charge; and the legal text for the service charge.
export const getMenuServiceCharge = createSelector(
  getItemsForCurrentMenu,
  (currentMenuItems) => {
    const serviceFeeItems = get(currentMenuItems, 'serviceFeeItems', [])

    // Favor taxable service charges over non-taxable service charges.
    let service_charges = filter(serviceFeeItems, ['specialType', 'taxable_service_charge'])

    if (service_charges && !isEmpty(service_charges)) {
      return {
        name: get(service_charges, '[0].name') || 'Service Charge',
        rate: get(service_charges, '[0].rate', 0),
        priceInCents: get(service_charges, '[0].variants[0].priceInCents', undefined),
        taxable: true,
        legal: null
      }
    }

    service_charges = filter(serviceFeeItems, ['specialType', 'service_charge'])

    if (service_charges && !isEmpty(service_charges)) {
      return {
        name: get(service_charges, '[0].name') || 'Service Charge',
        rate: get(service_charges, '[0].rate', 0),
        priceInCents: get(service_charges, '[0].variants[0].priceInCents', undefined),
        taxable: false,
        legal: null
      }
    }

    return { name: 'Service Charge', rate: 0, taxable: false, legal: null }
  }
)

export const makeGetMenuServiceCharge = () => createSelector(getMenuServiceCharge, (menuItems) => menuItems)

const getItemWithCartQuantity = (state, id) => {
  const item = get(getItemsForCurrentMenu(state), ['byId', id], {})
  const cartItems = getCartLineItems(state)
  const targetCartItems = filter(cartItems, ['itemId', id])
  const cartQuantity = sumBy(targetCartItems, 'quantity')

  return { ...item, cartQuantity }
}

export const makeGetItemWithCartQuantity = () => createSelector([getItemWithCartQuantity], (item) => item)
