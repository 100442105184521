import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isEmpty } from 'lodash'

import { ReactComponent as CloseIcon } from '../assets/icons/Close-Icon.svg'

import styles from './SidebarModal.module.scss'

const SidebarModal = ({ show, isShowClear, isRevenueSetUp, clearCart, title, subTitle, alignment, leftAccessory, rightAccessory, onClose, children, className }) => {
  const classNames = cn(styles.sidebarModal, className)
  let subTitleElement = null
  const mediaQueryMobile = window?.matchMedia("(max-width: 500px)")
  const centerTitle = mediaQueryMobile?.matches && leftAccessory
  const showCloseIcon = isRevenueSetUp ? false : true
  const overlayColor = { backgroundColor: !showCloseIcon ? 'black' : undefined }

  if (!show) return null

  if (!isEmpty(subTitle)) {
    subTitleElement = (
      <div className={styles.headerRow}>
        {subTitle}
      </div>
    )
  }

  return (
    <div className={classNames}>
      <div className={styles.overlay} style={overlayColor}></div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerRow}>
            {centerTitle ? (
              <>
                <div className={styles.column}>{leftAccessory}</div>
                <div className={styles.column}>{title}</div>
                <div className={styles.column}>
                  <div className={styles.clearContainer}>
                    {isShowClear && (
                      <span className={styles.clear} onClick={clearCart}>Clear</span>
                    )}
                    {showCloseIcon && (
                      <CloseIcon className={styles.closeIcon} onClick={onClose} />
                    )}
                  </div>
                </div>
              </>
            ):
              <>
                <div>
                  {leftAccessory}
                  <span className={leftAccessory? styles.title : null}>{title}</span>
                </div>
                <div className={styles.clearContainer}>
                  {showCloseIcon && (
                    <CloseIcon className={styles.closeIcon} onClick={onClose} />
                  )}
                </div>      
              </>
            }
      
          </div>
          {subTitleElement}
        </div>
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  )
}

SidebarModal.defaultProps = {
  show: false,
  title: '',
  subTitle: '',
  alignment: 'right',
  leftAccessory: null,
  rightAccessory: undefined,
  onClose: () => {},
  children: undefined,
}

SidebarModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'right']),
  leftAccessory: PropTypes.node,
  rightAccessory: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default SidebarModal
