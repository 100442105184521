import { connect } from 'react-redux'
import { getItemCategories } from '../selectors/itemCategory'
import { getQuickSearchFlag, getSelectedCategory } from '../selectors/search'
import { updateSelectedCategory, updateHighlightedItems, updateUseQuickSearchFlag } from '../actions/search'

import CategoryBar from '../components/CategoryBar'

const mapStateToProps = (state) => {
  const itemCategories = getItemCategories(state)
  const selectedCategory = getSelectedCategory(state)
  const useQuickSearchFlag = getQuickSearchFlag(state)

  return { itemCategories, selectedCategory, useQuickSearchFlag }
}

const mapDispatchToProps = (dispatch) => {
    return {
      setSelectedCategory: (selectedCategory) => dispatch(updateSelectedCategory(selectedCategory)),
      setHighlightedIds: (highlightedIds) => dispatch(updateHighlightedItems(highlightedIds)),
      updateUseQuickSearchFlag: (useQuickSearchFlag) => dispatch(updateUseQuickSearchFlag(useQuickSearchFlag))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBar)
