export const welcomeMessage = 'Welcome. The keypad on this kiosk has four arrow keys, a key with a circle on it in the middle of the arrow keys, and a square help button above the headphone jack. Use the Right and Left Arrow keys to move to the next and previous items. Use the Up and Down Arrow keys to move to the next and previous sections. Use the Middle Key to activate the current item. Use the help button to get more information.  Press any key to get started.'
export const menuWelcomeMessage = 'Use the Right and Left Arrow keys to move to the next and previous items. Use the Middle key to select the current menu.'
export const menuMessage = item => `${item}. Use the Right and Left Arrow keys to move to the next and previous items. Use the Up and Down Arrow keys to move to the next and previous sections. Use the Middle key to activate the current item.`
export const newOrderMessage = 'Use the Right and Left Arrow keys to move to the next and previous items. Use the Up and Down Arrow keys to move to the next and previous sections. Use the Middle key to activate the current item.'
export const clickToAddToCart = (item, price) => `${item}. ${price}. Click the Middle key to add to the cart.`
export const clickToRemoveFromCart = item => `${item.quantity} ${item.name}. ${item.price} each. Click the Middle key to remove from the cart.`
export const clickToDecrement = item => `Click the Middle key to remove 1 ${item} from the cart`
export const clickToDecrementLastItem = item => `There is last ${item} in the cart. Click the Middle key to remove from the cart.`
export const clickToIncrement = item => `Click the Middle key to add 1 ${item} to the cart.`
export const removedFromCartMessage = item =>`${item} removed from cart`
export const addedToCartMessage = (item) =>`${item} added to cart.`
export const decrementedQuantityMessage = (item, quantity) =>`Decremented ${item} quantity to ${quantity}`
export const incrementedQuantityMessage = (item, quantity) =>`Incremented ${item} quantity to ${quantity}.`
export const categoryMessage = 'Category list - choose'
export const alcoholMessage = 'You must be 21 to order alcoholic beverages. All IDs will be checked. If you cannot produce valid ID, your transaction will not be processed, and you will not be served. Click enter to confirm or press arrow up to leave'
export const modifierMenuMessage = 'Use the Up and Down Arrow keys to move to the next and previous sections. Use the Right and Left Arrow keys to move to the next and previous items. Use the Middle key to activate the current item.'    
export const payCardMessage = `Pay with card. All major credit cards accepted. Follow instructions on card reader.`
export const payAppAndCardMessage = richCheckoutPromptText => `Pay with the app or pay with a card.  All major credit cards accepted.  To pay with the app: ${richCheckoutPromptText}.  To pay with a card, follow instructions on the card reader.`
export const receiptDetailsMessage = (orderNumber, revenueCenterName) => `Order number ${orderNumber}. Pickup at ${revenueCenterName}`
