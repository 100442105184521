import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useState } from "react"
import { makeStyles } from "@material-ui/core"

import Button from "./Button";
import SidebarModal from "./SidebarModal"
import DropdownSelector from "./DropdownSelector"
import ConnectingIndicator from "./ConnectingIndicator"

import useGetSeats from "../hooks/useGetSeats"

import { getCurrentStand } from "../selectors/menus"

import { addNotesToCart } from "../actions/cart"

const useStyles = makeStyles(theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '3vw',
    fontWeight: 'bold'
  },
  selectSeats: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll'
  },
  seatContainer: {
    height: '85vh',
    overflowY: 'scroll',
    paddingTop: '1.250vw'
  },
  submitContainer: {
    position: 'absolute',
    bottom: '4.444vw',
    paddingLeft: '4.444vw',
    paddingTop: '2vw',
    backgroundColor: '#1F2021',
    width: '100%',
    left: 0,
  },
  submitButton: {
    width: '95%',
    height: '12.5vw',
    padding: 0,
    fontSize: '5.556vw',
    fontWeight: 700,
  }
}));

export const SelectLocation = (props) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const currentStand = useSelector(state => getCurrentStand(state))

  const [ getSections, getRows, getSeats, {
    sections, rows, seats,
    seatsLoading,
  } ] = useGetSeats()
  const localSections = useMemo(() => sections?.map((section) => section.name), [sections])
  const [section, setSection] = useState('')
  const [isSuite, setIsSuite] = useState(false)
  const [row, setRow] = useState('')
  const [seat, setSeat] = useState('')

  useEffect(() => {
    if (seatsLoading || !section || !sections || sections.length === 0) {
      return
    }

    const suite = sections.find((storedSection) => storedSection.name === section)?.isSuite

    if (suite) {
      setRow('')
      setSeat('')
    }

    setIsSuite(suite)
    getRows(section)
  }, [section])

  useEffect(() => {
    if (seatsLoading || !row || !rows || rows.length === 0) {
      return
    }

    getSeats(section, row)
  }, [row])

  useEffect(() => {
    getSections(currentStand?.uuid)
  }, [])

  const onClose = () => {
    props.history.replace('/concession-order')
  }

  const onDone = () => {
    if (isSuite && !section) {
      return
    } else if (!isSuite &&(!section || !row || !seat)) {
      return
    }

    if (isSuite) {
      dispatch(addNotesToCart(`Suite ${section}`))
    } else {
      dispatch(addNotesToCart(`Section ${section},\nRow ${row},\nSeat ${seat}`))
    }

    onClose()
  }

  const renderLocation = () => {
    return (
      <div>
        <DropdownSelector
          autocompleteLabel={'Section'}
          textInputVariant={'filled'}
          autocompleteOptions={localSections}
          onAutocompleteChange={(inputValue) => {
            setSection(inputValue)
          }}
          disabled={seatsLoading}
        />
        {
          !isSuite &&
          <>
            <DropdownSelector
              autocompleteLabel={isSuite ? 'No Row for Suite' : 'Row'}
              textInputVariant={'filled'}
              autocompleteOptions={rows}
              onAutocompleteChange={(inputValue) => {
                setRow(inputValue)
              }}
              disabled={isSuite || seatsLoading || !section}
            />
            <DropdownSelector
              autocompleteLabel={isSuite ? 'No Seat for Suite' : 'Seat'}
              textInputVariant={'filled'}
              autocompleteOptions={seats}
              onAutocompleteChange={(inputValue) => {
                setSeat(inputValue)
              }}
              disabled={isSuite || seatsLoading || !row}
            />
          </>
        }
      </div>
    )
  }

  return (
    <SidebarModal
      show
      title={'Location'}
      onClose={onClose}
    >
      <div className={styles.selectSeats}>
        <div className={styles.seatContainer}>
          {seatsLoading ? <ConnectingIndicator position='relative' isSidebarLoading={true} className={styles.loading}  /> : renderLocation()}
        </div>
      </div>
      <div className={styles.submitContainer}>
        <Button
          className={styles.submitButton}
          disabled={
            isSuite ?
            !section :
            !section || !seat || !row
          }
          onClick={onDone}>
          Done
        </Button>
      </div>
    </SidebarModal>
  )
}