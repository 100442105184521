import { isEmpty } from "lodash"

/**
 * @param {[string]} ids - An array of ids to pull from the records object
 * @param {object} records - An object of the records
 * @param {function} matcher - This method acts as a predicate, should return a boolean
 * @param {number} limit - The max result to return
 * @returns {[string]} - the array of ids that match the predicate
 */
const getMatchingRecords = (ids, records, matcher, limit) => {
  const result = []

  for (let id of ids) {
    if (!isEmpty(records[id]) && matcher(records[id])) result.push(id)
    if (result.length === limit) break
  }

  return result
}

export default getMatchingRecords
