import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { toggleAppState } from '../../actions/appState'
import Button from '../components/Button'
import Environment from '../../utils/environment'
// import analytics from '../utils/analytics'

if (!Environment.node.isTest) {
  Modal.setAppElement('#root')
}

export class IdleWarning extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    toggleAppState: PropTypes.func.isRequired,
  }

  state = {
    seconds: 10,
  }

  intervalID = null

  addEventListener = () => {
    document.addEventListener('keydown', this.continue)
  }

  removeEventListener = () => {
    document.removeEventListener('keydown', this.continue)
  }

  componentDidUpdate = prevProps => {
    this.addEventListener()
    if (prevProps.isActive && !this.props.isActive) {
      this.setTimer()
    }
    if (this.state.seconds === 0) {
      this.goIdle()
    }
  }

  componentWillUnmount() {
    this.removeEventListener()
    clearInterval(this.intervalID)
  }

  setTimer = () => {
    clearInterval(this.intervalID)
    this.intervalID = setInterval(this.updateTimer, 1000)
    this.setState({ seconds: 15 })
  }

  updateTimer = () => {
    if (this.state.seconds > 0) {
      this.setState(({ seconds }) => ({ seconds: seconds - 1 }))
    }
  }

  goIdle = () => {
    const { toggleAppState, history } = this.props

    // analytics.sessionIdleTimeoutEvent()
    toggleAppState({ isActive: true })
    history.push('/kiosk/attract_loop')
  }

  continue = () => {
    clearInterval(this.intervalID)
    this.props.toggleAppState({ isActive: true })
  }

  render() {
    const { isActive } = this.props
    const { seconds } = this.state

    return (
      <Modal className="modal-bottom" isOpen={!isActive} overlayClassName="overlay-modal-bottom -higher-layer">
        <div className="modal-bottom__copy -center" aria-label="Still there?">Still there?</div>
        <div className="modal-bottom__copy-hint -center" aria-label="This screen will close in 15 seconds and you will lose your shopping cart">
          This screen will close in 15 seconds and you will lose your shopping cart
        </div>
        <div className="modal-bottom__actions">
          <Button ariaLabel="Press any key if you need more time" className="rounded-btn -primary" onClick={this.continue} title="I need more time" />
          <Button className="rounded-btn -progress -grey" onClick={this.goIdle} title={`Close in ${seconds}...`} />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = ({ appState: { isActive } }) => ({ isActive })
const mapDispatchToProps = { toggleAppState }

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(IdleWarning)
