import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { menusItemsRequested, menusRequested } from '../actions/menus'

const THIRTY_MINS = 1_800_000

const useIdleRefresh = () => {
  const dispatch = useDispatch()

  // After 30 minutes of inactivity
  const handleOnIdle = () => {
    dispatch(menusRequested())
    dispatch(menusItemsRequested())
  }

  // When the user begins interacting again after 30 minutes has passed
  const handleOnActive = () => {
    dispatch(menusRequested())
    dispatch(menusItemsRequested())
  }

  useIdleTimer({
    timeout: THIRTY_MINS,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })
}

export default useIdleRefresh
