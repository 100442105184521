import { sumBy, filter } from "lodash"
import { isTabbed, STADIUM_ORDER_STATES } from "./orderStates"

import { createOrderParams } from './orderUtils'

const submittedOrFailedStates = [
  STADIUM_ORDER_STATES.SUBMITTED,
  STADIUM_ORDER_STATES.SUBMISSION_FAILED
]

// retrieves tender display information based on various flags
// or payment types set on the order
export const getTenderInfo = (order) => {
  const orderPayments = createOrderParams(order)?.payments ?? order?.payments ?? order.paymentInfo ?? []
  const validPayments = filter(orderPayments, (payment) => payment?.amountInCents > 0)
  const orderFirstPayment = validPayments?.[0] ?? {}
  const isRichCheckoutOrder = order?.isRichCheckout ?? false
  const isMultiPayment = order?.isMultiPayment ?? false
  const lastFour = createOrderParams(order)?.userAttributes?.last_4 ?? orderFirstPayment?.userAttributes?.last_4 ?? orderFirstPayment?.userAttributes?.last4
  const issuer = createOrderParams(order)?.userAttributes?.freedompayIssuerName ?? orderFirstPayment?.userAttributes?.freedompayIssuerName
  const hasCreditCardInfo = issuer && lastFour
  const isSubmittedOrFailed = submittedOrFailedStates.includes(createOrderParams(order)?.state)
  const orderFirstPaymentDescription = orderFirstPayment?.shortDescription ?? 'Custom'

  let displayText
  let cardIssuer
  if(isMultiPayment) {
    displayText = "Split"
  } else if (hasCreditCardInfo) {
    displayText = `*${lastFour}`
    cardIssuer = issuer?.split(' ')[0]
  } else if (isRichCheckoutOrder) {
    displayText = "App"
  } else if (isSubmittedOrFailed || isTabbed(order)) {
    displayText = "N/A"
  } else {
    displayText = orderFirstPaymentDescription
  }

  return { displayText, cardIssuer }
}

// returns a formatted total of the order.
export const totalDisplay = (order) => {
  if (order.hasOwnProperty('amountInCents')) return `$${(order.amountInCents / 100).toFixed(2)}`

  const totalInCents = sumBy(order.itemModels, 'priceInCents')
  return `$${(totalInCents / 100).toFixed(2)}`
}
