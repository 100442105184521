export const SET_CART_FROM_TAB = 'SET_CART_FROM_TAB'
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_OR_INCREMENT = 'ADD_TO_CART_OR_INCREMENT'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY'
export const SAFELY_UPDATE_CART_ITEM_QUANTITY = 'SAFELY_UPDATE_CART_ITEM_QUANTITY'
export const INCREMENT_CART_ITEM_QUANTITY = 'INCREMENT_CART_ITEM_QUANTITY'
export const SAFELY_INCREMENT_CART_ITEM_QUANTITY = 'SAFELY_INCREMENT_CART_ITEM_QUANTITY'
export const DECREMENT_CART_ITEM_QUANTITY = 'DECREMENT_CART_ITEM_QUANTITY'
export const SAFELY_DECREMENT_CART_ITEM_QUANTITY = 'SAFELY_DECREMENT_CART_ITEM_QUANTITY'
export const UPDATE_CART_ITEM_MODIFIERS = 'UPDATE_CART_ITEM_MODIFIERS'
export const SAFELY_UPDATE_CART_ITEM_NOTES = 'SAFELY_UPDATE_CART_ITEM_NOTES'
export const UPDATE_CART_ITEM_NOTES = 'UPDATE_CART_ITEM_NOTES'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_FOCUS = 'CLEAR_FOCUS'
export const ADD_NOTES_TO_CART = 'ADD_NOTES_TO_CART'

export const setCartFromTab = ({ lineItems }) => ({
  type: SET_CART_FROM_TAB,
  payload: { lineItems }
})

setCartFromTab.type = SET_CART_FROM_TAB

export const addToCart = ({ itemId, modifiers = [], quantity = 1, isAlcohol = false }) => ({
  type: ADD_TO_CART,
  payload: { itemId, modifiers, quantity, isAlcohol }
})

addToCart.type = ADD_TO_CART

export const addToCartOrIncrement = ({ itemId }) => ({
  type: ADD_TO_CART_OR_INCREMENT,
  payload: { itemId }
})

addToCartOrIncrement.type = ADD_TO_CART_OR_INCREMENT

export const removeFromCart = ({ index }) => ({
  type: REMOVE_FROM_CART,
  payload: { index }
})

removeFromCart.type = REMOVE_FROM_CART

export const updateCartItemQuantity = ({ index, quantity }) => ({
  type: UPDATE_CART_ITEM_QUANTITY,
  payload: { index, quantity }
})

updateCartItemQuantity.type = UPDATE_CART_ITEM_QUANTITY

export const safelyUpdateCartItemQuantity = ({ index, quantity }) => ({
  type: SAFELY_UPDATE_CART_ITEM_QUANTITY,
  payload: { index, quantity }
})

safelyUpdateCartItemQuantity.type = SAFELY_UPDATE_CART_ITEM_QUANTITY

export const incrementCartItemQuantity = ({ index }) => ({
  type: INCREMENT_CART_ITEM_QUANTITY,
  payload: { index }
})

incrementCartItemQuantity.type = INCREMENT_CART_ITEM_QUANTITY

export const safelyIncrementCartItemQuantity = ({ index }) => ({
  type: SAFELY_INCREMENT_CART_ITEM_QUANTITY,
  payload: { index }
})

safelyIncrementCartItemQuantity.type = SAFELY_INCREMENT_CART_ITEM_QUANTITY

export const decrementCartItemQuantity = ({ index }) => ({
  type: DECREMENT_CART_ITEM_QUANTITY,
  payload: { index }
})

decrementCartItemQuantity.type = DECREMENT_CART_ITEM_QUANTITY

export const safelyDecrementCartItemQuantity = ({ index }) => ({
  type: SAFELY_DECREMENT_CART_ITEM_QUANTITY,
  payload: { index }
})

safelyDecrementCartItemQuantity.type = SAFELY_DECREMENT_CART_ITEM_QUANTITY

export const updateCartItemModifiers = ({ index, modifiers = [] }) => ({
  type: UPDATE_CART_ITEM_MODIFIERS,
  payload: { index, modifiers }
})

updateCartItemModifiers.type = UPDATE_CART_ITEM_MODIFIERS

export const updateCartItemNotes = ({ index, notes = '' }) => ({
  type: UPDATE_CART_ITEM_NOTES,
  payload: { index, notes }
})

updateCartItemNotes.type = UPDATE_CART_ITEM_NOTES

export const safelyUpdateCartItemNotes = ({ index, notes = '' }) => ({
  type: SAFELY_UPDATE_CART_ITEM_NOTES,
  payload: { index, notes }
})

safelyUpdateCartItemNotes.type = SAFELY_UPDATE_CART_ITEM_NOTES

export const clearCart = () => ({
  type: CLEAR_CART,
})

clearCart.type = CLEAR_CART

export const clearFocus = () => ({
  type: CLEAR_FOCUS,
})

clearFocus.type = CLEAR_FOCUS

export const addNotesToCart = (userNotes) => ({
  type: ADD_NOTES_TO_CART,
  payload: { userNotes },
})

addNotesToCart.type = ADD_NOTES_TO_CART
