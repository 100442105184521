import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import styles from './Discounts.module.scss'
import { getOrderInProgress } from '../selectors/order'
import { getPromotions, getSelectedPromotions } from '../selectors/promotion'
import { addPromotionsToOrder, clearPromotionsOnOrder } from '../actions/promotion'
import VNConcessionsNavBar from '../components/VNConcessionsNavBar'
import GratuityHeader from '../components/GratuityHeader'
import VNBottomButton from '../components/VNBottomButton'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import { centsToDollars } from '../utils/formatters'
import { useHistory } from 'react-router-dom'
import { addDiscountsToTabOrder, clearOrderInProgress, deleteDiscountsFromTabOrder } from '../actions/order'
import { clearCart } from '../actions/cart'
import { isTabbed } from '../utils/orderStates'
import { getNetworkAvailableValue } from '../utils/networkConnected'
import ConfirmModalV2 from '../components/ConfirmModalV2'
import { ReactComponent as SignalStrength } from '../assets/icons/signal-strength.svg'
import { ReactComponent as SignalStrengthMobile } from '../assets/icons/signal-strength-mobile.svg'
import { getTotal } from '../selectors/cart'
import useSpinner from '../context/hooks/useSpinner'
import Discount from '../components/discounts/Discount'

const Discounts = ({ className }) => {
  const classNames = cn(styles.discounts, className)

  const dispatch = useDispatch()
  const history = useHistory()

  const discounts = useSelector((state) => getPromotions(state))
  const order = useSelector(getOrderInProgress)
  const offlineTotal = useSelector((state) => getTotal(state))
  const selectedPromotions = useSelector((state) => getSelectedPromotions(state))

  const [selectedDiscounts, setSelectedDiscounts] = useState([...selectedPromotions])
  const [showNoInternetModal, setShowNoInternetModal] = useState(false)

  const isTabOrder = isTabbed(order)
  const networkAvailable = getNetworkAvailableValue()

  const goBack = history.goBack ?? (() => {})

  const total = order?.amountInCents ?? offlineTotal
  const isMobileViewPort = useGetIsMobileScreen()
  const primaryText = !isMobileViewPort ? centsToDollars(total) : ''
  const marginTopStyle = !isMobileViewPort ? { marginTop: '6.111vh' } : {}

  const { startSpinner } = useSpinner()

  const onGoBack = () => {
    dispatch(clearPromotionsOnOrder())

    //if it's a tabbed order
    if (isTabOrder) {
      //No need to stop spinner since the the orders screens stops it.
      startSpinner()
      dispatch(deleteDiscountsFromTabOrder())
      dispatch(clearOrderInProgress())
      dispatch(clearCart())

      goBack()
      return
    }

    goBack()
  }

  const handleNext = () => {
    //no network
    if (!networkAvailable) {
      //no discounts
      if (!selectedDiscounts.length) {
        //ok to proceed
        return history.push({
          pathname: '/tender-selection',
          state: { fromDiscounts: true },
        })
      }
      //can't proceed if there are discounts
      setShowNoInternetModal(true)
      return
    }

    //network available
    dispatch(addPromotionsToOrder({ selectedPromotions: selectedDiscounts }))

    if (isTabOrder) {
      //no need to stop spinner since tender selection will do it
      startSpinner()
      dispatch(addDiscountsToTabOrder())
      return
    }

    //don't need to call addDiscountsToTabOrder when the order is not tabbed or there are no discounts
    return history.push({
      pathname: '/tender-selection',
      state: { fromDiscounts: true },
    })
  }

  return (
    <div className={classNames}>
      <VNConcessionsNavBar onClick={onGoBack} showIcon={true} textDisplay={isMobileViewPort && centsToDollars(total)} />
      <GratuityHeader
        className={styles.gratuityHeader}
        primaryText={primaryText}
        secondaryText="Please select discounts"
        isMobileViewPort={isMobileViewPort}
      />
      <div className={styles.discounts__body} style={marginTopStyle}>
        <div className={styles.discountSelections}>
          {discounts.map((discount, index) => {
            return (
              <Discount
                discount={discount}
                index={index}
                selectedDiscounts={selectedDiscounts}
                setSelectedDiscounts={setSelectedDiscounts}
              />
            )
          })}
        </div>
        <VNBottomButton text={'Next'} onClick={handleNext} higherPosition={true} />
      </div>
      {showNoInternetModal && (
        <ConfirmModalV2
          headerText={isMobileViewPort ? <SignalStrengthMobile /> : <SignalStrength />}
          onButtonTwoClick={() => setShowNoInternetModal(false)}
          subtext={
            'Discounts are not supported while the device is offline. Please find a stable network connection or de-select discounts to proceed'
          }
          singleButtonOnly={true}
          buttonTwoText={'OKAY'}
        />
      )}
    </div>
  )
}

export default Discounts
