import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { getCardError } from '../Selectors'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  subRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  topElement: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    alignContent: 'center'
  }
}))

/**
 * 
 * @param {HTMLElement} topElement The element that is displayed at the top.
 * @param {*} title the title element can be a string or an HTML Element.
 * @param {*} subtitle the subtitle element can be a string or an HTML Element.
 */
export const VNStatusScreen = (props) => {

  const classes = useStyles()
  const theme = useTheme()

  // SELECTORS
  const cardError = useSelector(state => getCardError(state))

  const displayTitleElements = (isSubtitle = false) => {
    let displayElement
    if (isSubtitle) {
      displayElement = props.subtitle || cardError.get('subTitle')
    } else {
      displayElement = props.title || cardError.get('title')
    }

    if (displayElement) {
      if (typeof(displayElement) === 'string') {
        return (
          <Box pt={isSubtitle ? 1 : 0}>
            <Typography variant={isSubtitle ? 'inherit' : 'h3'} style={isSubtitle ? theme.custom.subtitle3 : null}>
              {displayElement.split("\n").map((i, key) => {
                return <div style={{textAlign: 'center'}} key={key}>{i}</div>
              })}
            </Typography>
          </Box>
        )
      } else {
        return displayElement
      }
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.subRoot}>
        <Box className={classes.topElement}>
          {props.topElement}
        </Box>
        {displayTitleElements()}
        {displayTitleElements(true)}
      </Box>
    </Box>
  )
}
