import React from 'react'
import cn from 'classnames'

import GratuityHeader from '../components/GratuityHeader'
import QRCodePayment from '../components/QRCodePayment'
import CreditCardPayment from '../components/CreditCardPayment'

import styles from './PaymentMethod.module.scss'
import mastercard from '../assets/icons/mastercard.svg'
import visa from '../assets/icons/visa.svg'
import amex from '../assets/icons/amex.svg'

const cards = [
  { issuer: 'MasterCard', icon: mastercard },
  { issuer: 'Visa', icon: visa },
  { issuer: 'American Express', icon: amex },
]

const PaymentMethod = ({ className }) => {
  const classNames = cn(styles.paymentMethod, className)

  return (
    <div className={classNames}>
      <div className={styles.paymentMethodContent}>
        <GratuityHeader
          className={styles.gratuityHeader}
          primaryText="$60.00"
          secondaryText="$50.00 + $10.00 tip"
        />
        <QRCodePayment title="Pay with Swamp Bucks" qrCodeData="Hello, world!" className={styles.qrCodePayment} />
        <CreditCardPayment title="Pay with Credit Card" cards={cards} className={styles.creditCardPayment} />
      </div>
    </div>
  )
}

export default PaymentMethod
