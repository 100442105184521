/**
 * These Dialogs are part of the display for POS CFD
 */
import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { sendCFDScreenNav, sendRetryPayment } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../Reducer'
import { getCalculatedCurrentCartValues, getCurrentPaymentFlow, getOrderUpdate, getPaymentProgress, getTransactionResult } from '../Selectors'
import { VNPusher } from '../../pusher'
import { CFD_POS_PAYMENT_FLOWS, USER_PROGRESS } from '../Enums'

import { VNProgressCounter } from '../components/VNProgressCounter'
import { VNDialog } from '../components/VNDialog'

import { closeNoOpTender, startOfflineCardOrder, startOnlineCardOrder, updateSavedPayment } from '../../actions/order'
import { saveRichCheckoutOrderRequested } from '../../actions/richCheckout/order'
import { resetSafFullResponse } from '../../actions/peripheral'

import { resetCFD, setUserProgress } from '../ActionCreators'
import { getSafMode } from '../../selectors/appState'

import { getNetworkAvailableValue } from '../../utils/networkConnected'
import { centsToDollars } from '../../utils/formatters'
import { INTERNAL_ORDER_STATES, isTabbed } from '../../utils/orderStates'
import { getOrderInProgress } from '../../selectors/order'
import { getBridgePaymentMethod } from '../../selectors/peripheral'

const SAVE_TEXT = {
  RETRY: 'RETRY',
  CANCEL: 'YES, CANCEL',
  CONFIRM_ORDER: 'CONFIRM ORDER'
}

const CANCEL_TEXT = {
  CANCEL: "CANCEL",
  NEW_ORDER: 'NEW ORDER',
  DONT_CANCEL: 'NO, NEVER MIND',
}

export const CFDModalDialogs = ({
  clearCart,
  clearPromotionsOnOrder,
  CFDDialogDisplay,
  setCFDDialogDisplay,
  isRichCheckout,
  tipsEnabled,
  resetOrderTotals,
  resetCFDModalDialog,
  setResetCFDModalDialog
}) => {

  // total number of steps on the POS during the process
  const [totalSteps, setTotalSteps] = useState(3)
  // the time to move to second step in offline mode and rich checkout
  const offlineOrderTime = 45 * 1000

  // SELECTORS
  const paymentProgress = useSelector(state => getPaymentProgress(state))
  const transactionResult = useSelector(state => getTransactionResult(state))
  const orderUpdate = useSelector(state => getOrderUpdate(state))
  const safMode = useSelector(state => getSafMode(state))
  const calculatedCartValues = useSelector(state => getCalculatedCurrentCartValues(state))
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const bridgePaymentMethod = useSelector(state => getBridgePaymentMethod(state))

  const networkAvailable = getNetworkAvailableValue()
  const isTabOrder = isTabbed(orderInProgress)


  // LOCAL STATE
  // The error dialog display state
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  // the cancel dialog display state
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  // display offline buttons for rich checkout if attendant checking for receipt
  const [displayOfflineButtons, setDisplayOfflineButtons] = useState(false)

  const determineInitialTitleState = () => {
    if (isRichCheckout) {
      return USER_PROGRESS.RICH_CHECKOUT.CUSTOMER_SCANNING.title.replace('{AMOUNT}', centsToDollars(calculatedCartValues?.total || 0))
    }

    if (tipsEnabled) {
      return USER_PROGRESS.CARD_PAYMENT.CUSTOMER_TIP.title.replace('{AMOUNT}', centsToDollars(calculatedCartValues?.total || 0))
    }

    return "Customer Confirming Total"
  }

  // the title of the modal, changes depending on the situation
  const [modalTitle, setModalTitle] = useState(determineInitialTitleState())

  // what step of the progress are on we?
  const [currentStep, setCurrentStep] = useState(1)

  const Pusher = useRef()
  const dispatch = useDispatch()

  // USE EFFECTS

  useEffect(() => {
    if (resetCFDModalDialog === true) {
      setResetCFDModalDialog(false)
      setCurrentStep(1)
      setModalTitle(determineInitialTitleState())
    }
  }, [resetCFDModalDialog])

  // pusher socket for rich checkout
  useEffect(() => {
    if (isRichCheckout && networkAvailable) {
      Pusher.current = new VNPusher()
    }

    dispatch(resetSafFullResponse())
  }, [])

  useEffect(() => {
    if (isRichCheckout && CFDDialogDisplay && networkAvailable) {
      Pusher.current = new VNPusher()
      Pusher.current.subscribeDeviceChannel()
      Pusher.current.bindOrderTransfered(onBind)
      Pusher.current.bindOrderCreated(onOrderCreated)
    }

    if (isRichCheckout) {
      setTotalSteps(2)
    } else {
      setTotalSteps(3)
    }
  }, [CFDDialogDisplay])

  const onBind = () => {
    setModalTitle(USER_PROGRESS.RICH_CHECKOUT.CUSTOMER_PAYING.title.replace('{AMOUNT}', centsToDollars(calculatedCartValues?.total)))
    setCurrentStep(USER_PROGRESS.RICH_CHECKOUT.CUSTOMER_PAYING.index)
    sendCFDScreenNav(CFD_SCREEN.RICH_CHECKOUT_FINISH_TRANSACTION_ON_PHONE)
  }

  const onOrderCreated = () => {
    setModalTitle(USER_PROGRESS.RICH_CHECKOUT.PAYMENT_FINISHED.TRANSACTION_COMPLETE.title)
    setCurrentStep(USER_PROGRESS.RICH_CHECKOUT.PAYMENT_FINISHED.TRANSACTION_COMPLETE.index)
    sendCFDScreenNav(CFD_SCREEN.IDLE)

    dispatch(saveRichCheckoutOrderRequested({
      uuid: calculatedCartValues.uuid,
      orderNumber: calculatedCartValues.orderNumber,
    }))
  }
  const setOfflineRichModal = () => {
    setModalTitle("Please check guest's mobile receipt to confirm order.")
    setCurrentStep(2)
    setDisplayOfflineButtons(true)
    sendCFDScreenNav(CFD_SCREEN.RICH_CHECKOUT_SHOW_RECEIPT)
  }

  useEffect(() => {
    if (isRichCheckout && !networkAvailable && CFDDialogDisplay) {
      setTimeout(setOfflineRichModal, offlineOrderTime)
    }
  }, [isRichCheckout, networkAvailable, CFDDialogDisplay])

  useEffect(() => {
    setModalTitle(determineInitialTitleState())
  }, [isRichCheckout])

  useEffect(() => {
    if (!paymentProgress || paymentProgress?.size === 0) {
      return
    }

    if (paymentProgress?.error) {
      setShowErrorDialog(true)
      return
    }

    if (paymentProgress?.title || paymentProgress?.index) {
      let title = paymentProgress?.title

      if (paymentProgress.index === USER_PROGRESS.CARD_PAYMENT.CUSTOMER_TIP.index){
        title = determineInitialTitleState()
      }

      // an amount can be present on the paymentProgress, either use that or the calculatedCartValues
      setCurrentStep(parseInt(paymentProgress?.index))
      setModalTitle(title.replace('{AMOUNT}', paymentProgress?.amount || centsToDollars(calculatedCartValues?.total)))
    }
  }, [paymentProgress])

  useEffect(() => {
    // need to have this data before we can proceed
    // make sure we are only proceeding once per transaction
    if (orderUpdate && transactionResult && !orderUpdate.isEmpty() && !transactionResult.isEmpty()) {

      if (calculatedCartValues.isFinalizingTip) {
        dispatch(updateSavedPayment({tipAmountInCents: parseInt(orderUpdate.get('tipAmount'))}))
        return
      }

      let tempTransactionResult = transactionResult.toJS()
      delete tempTransactionResult.uuid

      const cardOrderObj = {
        cardReaderData: {
          ...calculatedCartValues,
          ...tempTransactionResult,
          authResponseData: {
            ...tempTransactionResult.authResponse
          }
        },
        tipAmountInCents: parseInt(orderUpdate.get('tipAmount'))
      }

      if (!isRichCheckout) {
        // checking everything so we can ensure we are in offline mode
        if (safMode === 2 ||
            !networkAvailable ||
            (transactionResult.receiptData?.approvalCode === "" && transactionResult.status === "APPROVED OFFLINE")) {
            dispatch(startOfflineCardOrder(cardOrderObj))
        } else {
          dispatch(startOnlineCardOrder(cardOrderObj))
        }
      }
    } else if (orderUpdate && !orderUpdate.isEmpty() && currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.UNSET) {

      // we are going to have a tip, so we need to start this order with the platform
      let cardOrderObj = {
        cardReaderData: {
          ...calculatedCartValues,
        },
        orderState: INTERNAL_ORDER_STATES.PREAUTH,
        tipAmountInCents: parseInt(orderUpdate.get('tip'))
      }

      // free order
      if (parseInt(orderUpdate.get('amount')) === 0) {
        dispatch(closeNoOpTender())
        return
      }

      dispatch(startOnlineCardOrder(cardOrderObj))
    }
  }, [transactionResult, orderUpdate])

  const clearItemsAndPromotions = () => {
    clearCart()
    clearPromotionsOnOrder()
  }

  const resetCFDModal = (screen) => {
    softReset(screen)
    clearItemsAndPromotions()
  }

  const softReset = (screen) => {
    setCFDDialogDisplay(false)
    dispatch(resetCFD(true))
    resetOrderTotals()

    if(screen) {
      sendCFDScreenNav(screen)
    }

    setShowCancelDialog(false)
    setShowErrorDialog(false)
    setDisplayOfflineButtons(false)
    dispatch(setUserProgress(USER_PROGRESS.CARD_PAYMENT.CUSTOMER_TIP))

    // This is required because MUI has a transition that will allow inputs to still happen.
    setTimeout(() => {
      setCurrentStep(1)
      setModalTitle(determineInitialTitleState())
    }, 1000);

    if(isRichCheckout && networkAvailable) {
      Pusher.current.unsubscribeDeviceChannel()
    }
  }

  const handleCFDProcessingSave = (screen) => {
    if ((!isRichCheckout && currentStep >= totalSteps)
      || (isRichCheckout && !networkAvailable && currentStep > 1)
      || (isRichCheckout && currentStep > totalSteps)) {
      resetCFDModal(CFD_SCREEN.IDLE)
    } else {
      setShowCancelDialog(true)
      setCFDDialogDisplay(false)
    }
  }

  const saveButtonText = () => {
    if (isRichCheckout) {
      return currentStep > totalSteps ? 'NEW ORDER' : "CANCEL"
    }

    if (currentStep === 2) {
      return ''
    }

    return currentStep >= totalSteps ? 'NEW ORDER' : "CANCEL"
  }

  const displayCFDModal = () => {
    if (isRichCheckout && !networkAvailable) {
      return (
        <VNDialog
          open={CFDDialogDisplay}
          subTitle={modalTitle}
          image={<VNProgressCounter count={currentStep} total={2} />}
          showTextfield={false}
          closeOnPrimaryButtonClicked={false}
          primaryButtonText={currentStep >= 2 ? SAVE_TEXT.CONFIRM_ORDER : "CANCEL"}
          secondaryButtonText={(displayOfflineButtons && currentStep === 2) ? CANCEL_TEXT.CANCEL : null}
          onSecondaryButtonClick={() => {
            softReset(CFD_SCREEN.CART)
          }}
          onPrimaryButtonClick={() => {
            dispatch(saveRichCheckoutOrderRequested({
              uuid: calculatedCartValues.uuid,
              orderNumber: calculatedCartValues.orderNumber,
            }))
            setDisplayOfflineButtons(false)
            handleCFDProcessingSave(null)
          }}
          fullWidth
        />
      )
    }

    return (
      <div>
        <VNDialog
          open={CFDDialogDisplay}
          subTitle={modalTitle}
          image={<VNProgressCounter count={currentStep} total={totalSteps} />}
          showTextfield={false}
          closeOnPrimaryButtonClicked={false}
          primaryButtonCountdownTime={currentStep > totalSteps ? 10 : undefined}
          primaryButtonText={saveButtonText()}
          onPrimaryButtonClick={(currentStep !== USER_PROGRESS.CARD_PAYMENT.PAYING_CARD.index || isRichCheckout) ? handleCFDProcessingSave : null}
          fullWidth
        />
      </div>
    )
  }

  return (
    <>
      {displayCFDModal()}
      <VNDialog
        open={showCancelDialog}
        subTitle={"This will navigate you and the customer back to the cart."}
        title={"Cancel Transaction?"}
        showTextfield={false}
        closeOnPrimaryButtonClicked={false}
        primaryButtonText={SAVE_TEXT.CANCEL}
        onPrimaryButtonClick={() => {
          softReset(CFD_SCREEN.CART)
          const carReaderObj = {
            cardReaderData: {
              ...calculatedCartValues,
            },
            orderState: INTERNAL_ORDER_STATES.CANCELLED,
            reason: 'User cancelled order backing out of payment amount confirmation'
          }
          if (!isTabOrder) {
            dispatch(startOnlineCardOrder(carReaderObj))
          }
        }}
        secondaryButtonText={CANCEL_TEXT.DONT_CANCEL}
        onSecondaryButtonClick={() => {
          setShowCancelDialog(false)
          setCFDDialogDisplay(true)
        }}
        fullWidth
      />
      <VNDialog
        open={showErrorDialog}
        title={paymentProgress?.title}
        subTitle={paymentProgress?.subTitle}
        showTextfield={false}
        primaryButtonText={SAVE_TEXT.RETRY}
        closeOnPrimaryButtonClicked={false}
        onPrimaryButtonClick={() => {
          setShowErrorDialog(false)
          sendRetryPayment(bridgePaymentMethod)
        }}
        secondaryButtonText={CANCEL_TEXT.CANCEL}
        onSecondaryButtonClick={() => {
          softReset(CFD_SCREEN.CART)
          dispatch(resetCFD())
          const carReaderObj = {
            cardReaderData: {
              ...calculatedCartValues,
            },
            orderState: INTERNAL_ORDER_STATES.CANCELLED,
            reason: 'User cancelled order transaction through CFD error modal'
          }
          if (!isTabOrder) {
            dispatch(startOnlineCardOrder(carReaderObj))
          }
        }}
        fullWidth
      />
    </>
  )
}