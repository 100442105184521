import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isEmpty } from 'lodash'

import BackChevron from '../BackChevron'
import CartDetails from '../CartDetails'

import styles from './TabOrderCartDetails.module.scss'

const TabOrderCartDetails = ({ history, order, className }) => {
  const classNames = cn(styles.tabOrderCartDetails, className)

  if (isEmpty(order)) return( <div/> )

  const centsAsDollars = (cents) => `$${((cents || 0) / 100).toFixed(2)}`

  const onBack = () => history.push(`/tabs/${order.uuid}`)

  return (
    <div className={classNames}>
      <div className={styles.backButtonRow} onClick={onBack}>
        <BackChevron className={styles.backChevron} />
        <div className={styles.orderNumber}>
          {order.orderNumber}
        </div>
      </div>
      <CartDetails
        lineItemPrefix='tab'
        subtotal={centsAsDollars(order.amountInCents)}
        tax={centsAsDollars(order.tax)}
        total={centsAsDollars(order.amountInCents)}
      />
    </div>
  )
}

TabOrderCartDetails.defaultProps = {
  order: {},
}

TabOrderCartDetails.propTypes = {
  order: PropTypes.object,
}

export default withRouter(TabOrderCartDetails)
