import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { VNDialog } from '../../VNCustomerFacingDisplay/components/VNDialog'
import { getCustomerViewingReceipt } from '../Selectors'
import { setCustomerViewingReceiptDialog } from '../ActionCreators'
import { clearCart } from '../../actions/cart'
import { clearPromotionsOnOrder } from '../../actions/promotion'
import { sendCFDScreenNav } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../../VNCustomerFacingDisplay/Reducer'
import { resetCFD } from '../../VNCustomerFacingDisplay/ActionCreators'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { isTabbed } from '../../utils/orderStates'
import { clearOrderInProgress } from '../../actions/order'
import { getOrderInProgress } from '../../selectors/order'

export const VNCustomerViewingReceiptDialog = () => {

  const dispatch = useDispatch()

  const history = useHistory()

  const open = useSelector(state => getCustomerViewingReceipt(state))

  const orderInProgress = useSelector(state => getOrderInProgress(state))

  const [loadRemoteOrderTotal, {}, resetOrderTotals] = useRemoteOrderTotal()

  return <VNDialog  open={open}
                    subTitle={"Customer Viewing Receipt"}
                    primaryButtonText={"NEW ORDER"}
                    onPrimaryButtonClick={() => {

                      // clear cart
                      dispatch(clearCart())

                      // clear promotions on order
                      dispatch(clearPromotionsOnOrder())

                      // reset order totals
                      resetOrderTotals()
                      
                      // navigate CFD back to IDLE
                      sendCFDScreenNav(CFD_SCREEN.IDLE)

                      // navigate POS back to 
                      history.push('/concession-order')

                      // reset the state locally
                      dispatch(resetCFD(true))

                      // close dialog
                      dispatch(setCustomerViewingReceiptDialog(false))

                      // clear order in progress if open tab
                      if (isTabbed(orderInProgress)) {
                        dispatch(clearOrderInProgress())
                      }
                    }} />
}
