import { useState, useEffect } from 'react'

// hook that dynamically returns whether the view port is below 700px (mobile) or not
const useGetIsMobileScreen = () => {
  const mediaWatcher = window.matchMedia('(max-width: 700px)')
  const [isMobileScreen, setIsMobileScreen] = useState(mediaWatcher?.matches)

  function updateIsMobileScreen (e) {
    setIsMobileScreen(e.matches)
  }

  useEffect(() => {
    setIsMobileScreen(mediaWatcher.matches)

    mediaWatcher.addEventListener('change', updateIsMobileScreen)

    return function cleanup () {
      mediaWatcher.removeEventListener('change', updateIsMobileScreen)
    }
  }, [])

  return isMobileScreen
}

export default useGetIsMobileScreen
