import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setWebSDKDataReceive } from '../ActionCreators'

export const VNWebSDKDataBridge = (props) => {

  // HOOKS
  const dispatch = useDispatch()

  // initialize the WEBSDK Bridge on mount
  useEffect(() => {

    window.VENUENEXT_WEBSDK = {
      DATA_BRIDGE: dataBridgeReceive
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // LOCAL FUNCS

  // called when WebSDK wants to make calls into react, this is open to the public
  // base64Parameters : String - This is the parameters being passed from native that is base64 encoded
  const dataBridgeReceive = (base64Parameters) => {

    // decode and parse the payload back to a JSON obj
    var decodedParams = window.atob(base64Parameters)
    var payload = JSON.parse(decodedParams)
    dispatch(setWebSDKDataReceive(payload))
  }

  return (
    <>
      {props.children}
    </>
  )
}
