import React, { useEffect } from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getNetworkAvailableValue } from '../../utils/networkConnected'

import Button from '../components/Button'

import { KEY_CODES } from "../utils/constants";

const OfflineModal = ({ refProp, history, onRenderChange }) => {

  const isAvailable = useSelector(getNetworkAvailableValue)

    useEffect(() => {
      onRenderChange && onRenderChange(!isAvailable)
    }, [isAvailable]);
  
  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleClick()
    }
  }

  const handleClick = (e) => {
    // if not attract loop path, then push
    if (window.location.pathname !== '/kiosk/attract_loop') {
      history.push('/kiosk/attract_loop')
    }
  }
  
  return (
    <Modal className="modal" isOpen={!isAvailable} overlayClassName="overlay">
      <div className="modal__copy">
        Sorry, this device is now offline. Please see attendant.
      </div>
      <div className="modal__actions">
        <Button
          className="btn -okay"
          refProp={refProp}
          onKeyPress={handleKeyDown}
          onClick={handleClick}
          title="OKAY"
          />
      </div>
    </Modal>
  )
}

export default withRouter(OfflineModal)
