import { makeStyles } from "@material-ui/core";
import {ReactComponent as Checkmark} from '../../assets/icons/checkmark-circle-solid.svg'
import { Fragment } from "react";

/**
 * 
 * @param {int} count - How far are you in the progress
 * @param {int} total - How many progress elements do you need total?
 * @returns 
 */
export const VNProgressCounter = ({count, total}) => {
  
  const useStyles = makeStyles(theme => ({
    root: {
      margin: 10,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20
    },
    circle: {
      width: 60,
      height: 60,
      minWidth: 60,
      backgroundColor: 'white',
      borderRadius: '50%',
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'inline-flex',
      justifyContent: 'center',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    text: {
      margin: 'auto',
      color: 'black'
    },
    line: {
      height: 2,
      background: 'white',
      width: '50%',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '0px 10px',
    }
  }))  

  const classes = useStyles()

  const displayCircle = (text, oppositeColor) => {
    if (typeof text === 'number') {
      return (
        <div className={classes.circle} style={{backgroundColor: oppositeColor ? 'black' : 'white', width: 60, resizeMode: "contain"}}>
          <p className={classes.text} style={{color: oppositeColor ? 'white' : 'black'}}>{text}</p>
        </div>
      )
    }
    
    return (
      <div className={classes.circle}>
        {text}
      </div>
    )
  }

  const displayProgress = () => {
    
    const array = Array(total).fill(0)
    
    return array.map((_, index) => {

      let textValue = index + 1
      let oppositeColor = true

      if (textValue < count) {
        textValue = <Checkmark />
      }

      if (index + 1 === count) {
        oppositeColor = false
      }

      if (index < total - 1) {
        return (
          <Fragment>
            {displayCircle(textValue, oppositeColor)}
            <div className={classes.line} />
          </Fragment>
        )
      }

      return displayCircle(textValue, oppositeColor)
    })
  }

  return (
    <div className={classes.root}>
      {displayProgress()}
    </div>
  )
}

