import { get, sumBy } from 'lodash'
import { formatCartItemModifiers } from '../utils/formatters'

const displayPrice = (cartItem, modifiers) => {
  const basePrice = get(cartItem, 'defaultPriceInCents', 0)
  const modifierPrice = sumBy(modifiers, (mod) => get(mod, 'defaultPriceInCents', 0))
  const price = basePrice + modifierPrice

  return `$${(price  / 100).toFixed(2)}`
}

const CartItemViewModel = (cartItem) => {
  const modifiers = get(cartItem, 'modifiers', [])
  const notes = get(cartItem, 'notes', null)
  const modifiersString = formatCartItemModifiers(modifiers, notes)

  return {
    orderMaxAmount: get(cartItem, 'orderMaxAmount', null),
    orderMinCount: get(cartItem, 'orderMinCount', null),
    isAlcohol: get(cartItem, 'isAlcohol', false),
    id: get(cartItem, 'id', ''),
    name: get(cartItem, 'name', ''),
    price: displayPrice(cartItem, modifiers),
    color: get(cartItem, 'color'),
    priceInCents: get(cartItem, 'defaultPriceInCents', 0),
    quantity: get(cartItem, 'quantity', 1),
    modifiers,
    notes,
    modifiersString,
    variant: cartItem.variants?.length ? cartItem.variants[0] : null
  }
}

export default CartItemViewModel
