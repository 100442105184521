import { connect } from 'react-redux'

import { makeGetCurrentMenu } from '../../selectors/menus'
import {
  makeGetAllRevenueInCents,
  makeGetOfflineCardIds,
  getActiveOfflineCardCount,
  makeGetOfflineLocalIds,
  makeGetOrderCounts,
  getUnsyncableIds,
  getUnsyncedOrderCounts,
} from '../../selectors/order'

import {
  makeGetSyncedRefundsCount,
  makeGetUnsyncedRefundsCount,
  makeGetUnsyncedRefundIds,
} from '../../selectors/refund'

import { getVenueName } from '../../selectors/venue'
import { getSafMode } from '../../selectors/appState'

import { syncRefundRequested } from '../../actions/refund'
import { onSafIsUploading, onDataIsUploading } from '../../actions/peripheral'

import OrdersSummary from '../../components/orders/Summary'
import { makeGetActiveAttendant } from '../../selectors/attendant'
import { getSafResponse } from '../../selectors/appState'
import { getPaymentType, getSafUploadComplete, getSafIsUploading, getDataUploadComplete, getDataIsUploading, getTerminalInfo } from '../../selectors/peripheral'

const mapStateToProps = (state) => {
  const getOrderCounts = makeGetOrderCounts()
  const orderCounts = getOrderCounts(state)

  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const menuName = menu.name

  const unsyncableIds = getUnsyncableIds(state)

  const getAllRevenueInCents = makeGetAllRevenueInCents()
  const allRevenueInCents = getAllRevenueInCents(state)

  const getSyncedRefundsCount = makeGetSyncedRefundsCount()
  const syncedRefundsCount = getSyncedRefundsCount(state)

  const getUnsyncedRefundsCount = makeGetUnsyncedRefundsCount()
  const unsyncedRefundsCount = getUnsyncedRefundsCount(state)

  const getUnsyncedRefundIds = makeGetUnsyncedRefundIds()
  const unsyncedRefundIds = getUnsyncedRefundIds(state)

  const venueName = getVenueName(state)
  const deviceUuid = localStorage.getItem('device_id')
  const secondaryDeviceId = getTerminalInfo(state).serialNumber ?? ''
  const attendant = makeGetActiveAttendant()(state) ?? {}

  const dataIsUploading = getDataIsUploading(state)
  const dataUploadComplete = getDataUploadComplete(state)

  const safIsUploading = getSafIsUploading(state)
  const safUploadComplete = getSafUploadComplete(state)
  const safResponse = getSafResponse(state)
  const safMode = getSafMode(state)

  const getOfflineCardIds = makeGetOfflineCardIds()
  const offlineCardIds = getOfflineCardIds(state)
  const [activeOfflineCardCount, unsyncedLocalOrderCount] = getUnsyncedOrderCounts(state)


  return {
    attendant,
    allRevenueInCents,
    deviceUuid,
    menuName,
    activeOfflineCardCount,
    unsyncedLocalOrderCount,
    unsyncableIds,
    orderCounts,
    syncedRefundsCount,
    unsyncedRefundsCount,
    unsyncedRefundIds,
    venueName,
    safMode,
    safResponse,
    safIsUploading,
    safUploadComplete,
    dataIsUploading,
    dataUploadComplete,
    secondaryDeviceId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const replace = ownProps?.history?.replace ?? function(){}

  return {
    onClose: () => replace('/orders'),
    onCheckNetworkClick: () => replace('/debug-info/network'),
    onRequestSyncRefund: (id) => { dispatch(syncRefundRequested(id)) },
    setSafIsUploading: (isUploading) => dispatch(onSafIsUploading(isUploading)),
    setDataIsUploading: (isUploading) => dispatch(onDataIsUploading(isUploading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersSummary)
