
export const getConfirmPaymentReadyDialogOpen = (state) => {
  return state.VNDialogs.get('confirmPaymentDialog')
}

export const getCustomerPaymentDialogOpen = (state) => {
  return state.VNDialogs.get('customerPaymentDialog')
}

export const getCustomerViewingReceipt = (state) => {
  return state.VNDialogs.get('customerViewingReceipt')
}

export const getCustomerPayingWithCard = (state) => {
  return state.VNDialogs.get('customerPayingWithCard')
}

export const getTransactionCompleteDialog = (state) => {
  return state.VNDialogs.get('transactionCompleteDialog')
}

export const getTransactionErrorDialog = (state) => {
  return state.VNDialogs.get('transactionErrorDialog')
}

export const getCustomerEnteringTipDialogOpen = (state) => {
  return state.VNDialogs.get('customerEnteringTipDialog')
}

export const getCardAuthStepOne = (state) => {
  return state.VNDialogs.get('cardAuthStepOne')
}

export const getCardAuthStepTwo = (state) => {
  return state.VNDialogs.get('cardAuthStepTwo')
}

export const getKitchenPrintErrorDialogOpen = (state) => {
  return state.VNDialogs.get('kitchenPrintErrorDialog')
}