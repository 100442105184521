export const VNDIALOGS_CONFIRM_PAYMENT_READY = 'VNDIALOGS_CONFIRM_PAYMENT_READY'
export const VNDIALOGS_CUSTOMER_PAYMENT = 'VNDIALOGS_CUSTOMER_PAYMENT'
export const VNDIALOGS_CUSTOMER_VIEWING_RECEIPT = 'VNDIALOGS_CUSTOMER_VIEWING_RECEIPT'
export const VNDIALOGS_CUSTOMER_PAYING_WITH_CARD = 'VNDIALOGS_CUSTOMER_PAYING_WITH_CARD'
export const VNDIALOGS_TRANSACTION_COMPLETE = 'VNDIALOGS_TRANSACTION_COMPLETE'
export const VNDIALOGS_TRANSACTION_ERROR = 'VNDIALOGS_TRANSACTION_ERROR'
export const VNDIALOGS_CUSTOMER_ENTERING_TIP = 'VNDIALOGS_CUSTOMER_ENTERING_TIP'
export const VNDIALOGS_CARD_AUTH_STEP_ONE = 'VNDIALOGS_CARD_AUTH_STEP_ONE'
export const VNDIALOGS_CARD_AUTH_STEP_TWO = 'VNDIALOGS_CARD_AUTH_STEP_TWO'
export const VNDIALOGS_KITCHEN_PRINTER_ERROR_DIALOG = 'VNDIALOGS_KITCHEN_PRINTER_ERROR_DIALOG'
