import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

export const VNIdle = (props) => {

  const [shouldDisplayMenuImage, setShouldDisplayMenuImage] = useState(props.menuImage)
  const [shouldDisplayStandImage, setShouldDisplayStandImage] = useState(props.standImage)

  const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      backgroundColor: props.color
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    title: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%);'
    },
    versionBox: {
      backgroundColor: '#000000',
      position: 'absolute',
      bottom: 10,
      left: 10,
      padding: 8
    }
  }))

  const classes = useStyles()

  // need to ensure that this forces a change if we do get it updated from state
  useEffect(() => {
    setShouldDisplayStandImage(props.standImage)
    setShouldDisplayMenuImage(props.menuImage)
  }, [props.standImage, props.menuImage])

  const display = () => {
    if (shouldDisplayMenuImage) {
      return <img src={props.menuImage} className={classes.image} onError={() => { setShouldDisplayMenuImage(false) }} />
    } else if(shouldDisplayStandImage) {
      return <img src={props.standImage} className={classes.image} onError={() => { setShouldDisplayStandImage(false) }} />
    } else {
      return <Typography variant="h1" className={classes.title}>{props.title}</Typography>
    }
  }

  return (
    <Box className={classes.root}>
      {display()}
      <Box className={classes.versionBox}>
        <Typography variant="h5">{process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </Box>
  )
}
