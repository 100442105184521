import React, { useEffect, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { VNQRCode } from 'vn-react-components/VNComponents/VNQRCode'
import { generateURL } from '../../utils/richCheckout'
import { getCartTotals, getFormattedCartItems, getInitializedValues } from '../Selectors'
import QrCodeBackground from '../../assets/QRCodeBackgroundImage.svg'
import { RICH_CHECKOUT_TEXT_PROMPT } from '../../constants'
import { isEmpty } from '../../VNUtil/VNUtils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  rootChild: {
    width: '50%',
    alignItems: 'center'
  }
}))

export const VNPayWithApp = (props) => {

  const classes = useStyles()
  const theme = useTheme()

  // SELECTORS

  const cartTotals = useSelector(state => getCartTotals(state))
  const cartItems = useSelector(state => getFormattedCartItems(state))
  const initialValues = useSelector(state => getInitializedValues(state))

  // LOCAL STATE

  const [qrCodeData, setQRCodeData] = useState(null)

  // USE EFFECTS

  useEffect(() => {
    if (!initialValues?.has('deviceId') || !cartTotals.has('uuid') || isEmpty(cartItems)) {
      return
    }

    setQRCodeData(generateURL(cartTotals?.get('uuid'), cartTotals?.get('orderNumber'), `${initialValues.get('appConfigurationDeviceId')}`, cartTotals?.get('total'), cartItems))
  }, [cartItems, initialValues, cartTotals])

  const displayQRCode = () => {
    if (qrCodeData) { 
      return (
        <Box pb={8}>
          <VNQRCode backgroundEnabled backgroundImage={QrCodeBackground} text={qrCodeData} />
        </Box>
      )
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.rootChild}>
        {displayQRCode()}
        <Box>
          <Box pb={1}>
            <Typography variant='h3' style={{textAlign: 'center'}}>Pay with App</Typography>
          </Box>
          <Typography style={theme.custom.subtitle4}>{RICH_CHECKOUT_TEXT_PROMPT}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
