import React, { useContext } from 'react'
import ManagerApprovalComponent from './ManagerApprovalComponent'
import SpinnerComponent from './SpinnerComponent'
import ManagerApprovalContext from './ManagerApprovalContext'
import useSpinner from './hooks/useSpinner'

const ContextualComponents = () => {
  const useManagerApprovalContext = () => useContext(ManagerApprovalContext)
  const { showSpinner } = useSpinner()

  const { showManagerApproval } = useManagerApprovalContext()

  return (
    <>
      {showManagerApproval && <ManagerApprovalComponent />}
      {showSpinner && <SpinnerComponent />}
    </>
  )
}

export default ContextualComponents
