import axios from 'axios'
import { reduce, some } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import Remote from '../remote'
import createDataDogLog from '../utils/datadog'

export const QR_PAY_ERROR_MESSAGE = 'QR code charge declined. Check payment and try again.'

const useGetQRBalance = () => {
  const [dataLoading, setLoading] = useState(false)
  const [dataSucceeded, setSuccess] = useState(false)
  const [dataFailed, setFailed] = useState(false)
  const [qrBalance, setQRBalance] = useState()
  const [vaultedCard, setVaultedCard] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [affiliationUuids, setAffiliationUuids] = useState([])
  const [userUuid, setUserUuid] = useState(null)
  const cancelTokenRef = useRef()

  useEffect(() => {
    cancelTokenRef.current = axios.CancelToken.source()

    return () => {
      cancelTokenRef.current.cancel()
    }
  }, [])

  const getQRBalance = (qrCode) => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)

    Remote.getQRBalance(qrCode).then((result) => {
      const qrBalance = reduce(result?.data, (total, vcAmount) => {
        const addedValue = typeof vcAmount !== 'number' ? 0 : vcAmount
        return total + addedValue
      }, 0)
      const vaultedCard = some(result?.data, (vcAmount) => vcAmount === null)
      const affiliationUuids = result?.data?.affiliationUuids ?? []

      setQRBalance(qrBalance)
      setVaultedCard(vaultedCard)
      setLoading(false)
      setSuccess(true)
      setAffiliationUuids(affiliationUuids)
      setUserUuid(result?.data?.userUuid)
    }).catch((err) => {
      if (axios.isCancel(err)) return

      const errorMessage = err?.response?.data?.errorMessage
      const isExpired = errorMessage?.includes('expired')

      createDataDogLog('error', QR_PAY_ERROR_MESSAGE, { qrCode, errorMessage })
      setLoading(false)
      setFailed(true)
      setIsExpired(isExpired)
    })
  }

  return [getQRBalance, { qrBalance, vaultedCard, dataLoading, dataSucceeded, dataFailed, isExpired, affiliationUuids, userUuid }]
}

export default useGetQRBalance