import React, { useRef, useEffect } from 'react'
import cn from 'classnames'
import { scrollToElementLeftRight } from '../utils/search'
import styles from './CategoryPill.module.scss'

const CategoryPill = ({ className, category, selectedCategory, setCategory}) => {
  const classNames = cn(styles.CategoryPill, className)
  const categoryPillRef = useRef()

  const style = {}
  if (category.itemCategoryUuid === selectedCategory.id && selectedCategory.highlight) {
      style.backgroundColor = category.color
      style.color = '#ffffff'
      style.zIndex = '6'
  }

  useEffect(() => {
    if (categoryPillRef.current && category.itemCategoryUuid === selectedCategory.id) {
      scrollToElementLeftRight(categoryPillRef.current)
    }
  },[selectedCategory.id])

  return (
    <div className={classNames} ref={categoryPillRef} style={style} onClick={(() => setCategory(category.itemCategoryUuid))}>
      <div className={styles.content}>{category.name}</div>
    </div>
  )
}

export default CategoryPill
