/**
 * Validation types explained:
 *
 * 1. required
 *    You are required to make a selection, and you can only choose one.
 *
 * 2. specificRequired
 *    You are requred to make a selection of the specified amount.
 *
 * 3. minRequired
 *    You are required to select at least the specified amount.
 *
 * 4. minMaxRequred
 *    You are required to select at least the specified minimum amount,
 *    but no more than the maximum specified amount.
 *
 * 5. maxOptional
 *    You are not required to make any selection, but you can not select more
 *    than the specified amount
 *
 * 6. optional
 *    Your selection is completely optional and has
 *    no minimum or maximum limitations.
 *
 * 7. unknown
 *    The validation type could not be determined with the values provided.
 */

const VALIDIDATION_TYPE = {
  required: 'required',
  specificRequired: 'specificRequired',
  minRequired: 'minRequired',
  minMaxRequired: 'minMaxRequired',
  maxOptional: 'maxOptional',
  optional: 'optional',
  unknown: 'unknown',
}

const validationTypeResolver = (min, max) => [
  (min === 1 && max === 1) ? VALIDIDATION_TYPE.required : false,
  (min > 0 && max > 0 && min === max) ? VALIDIDATION_TYPE.specificRequired : false,
  (min > 0 && (max === 0 || max === null)) ? VALIDIDATION_TYPE.minRequired : false,
  ((min === 0 || min === null) && max > 0) ? VALIDIDATION_TYPE.maxOptional : false,
  (min > 0 && max > 1) ? VALIDIDATION_TYPE.minMaxRequired : false,
  (min === null && max === null) ? VALIDIDATION_TYPE.optional : false,
  VALIDIDATION_TYPE.unknown,
]

const isValidResolver = (min, max, validationType, quantity) => {
  switch (validationType) {
    case VALIDIDATION_TYPE.required:
      return quantity === 1

    case VALIDIDATION_TYPE.specificRequired:
      return quantity === min

    case VALIDIDATION_TYPE.minRequired:
      return quantity >= min

    case VALIDIDATION_TYPE.maxOptional:
      return quantity <= max

    case VALIDIDATION_TYPE.minMaxRequired:
      return quantity >= min && quantity <= max

    case VALIDIDATION_TYPE.optional:
      return true

    default:
      return false
  }
}

const validationLabelResolver = (min, max, validationType) => {
  const label = {
    required: '(required)',
    specificRequired: `(${min} required)`,
    minRequired: `pick at least ${min} (required)`,
    minMaxRequired: `pick up to ${max} (${min} required)`,
    maxOptional: `pick up to ${max} (optional)`,
    optional: '',
    unknown: '',
  }

  return label[validationType]
}

const required = (field) => {
  console.error(`quantityValidator:: The \`${field}\` parameter is required`)
  return undefined
}

const quantityValidator = (min = required('min'), max = required('max')) => {
  const validationType = validationTypeResolver(min, max).find(resolver => resolver !== false)
  const label = validationLabelResolver(min, max, validationType)

  const isValid = (quantity = 0) => isValidResolver(min, max, validationType, quantity)

  return {
    min,
    max,
    isValid,
    label,
    validationType,
  }
}

quantityValidator.types = VALIDIDATION_TYPE

export default quantityValidator
