import { createSelector } from 'reselect'
import { isEmpty, values, find, get, some } from 'lodash'
import moment from 'moment'

import { getCurrentMenuId } from './appState'
import { getSelectedMenusIds, getDeviceSubtype } from './config'

import DeviceSubtypes from '../utils/deviceSubtypes'

export const getMenus = state => values(state.menus.byId)

export const getStands = state => values(state.stands.byId)

export const getIsMenusLoading = state => state?.menus?.isMenusLoading ?? false

export const getSelectedMenus = createSelector(
  getMenus,
  getSelectedMenusIds,
  (menus, selectedIds) => menus.filter(menu => selectedIds.includes(menu?.uuid))
)

export const getCurrentMenu = createSelector(
  getMenus,
  getCurrentMenuId,
  (menus, currentMenuId) => find(menus, ['uuid', currentMenuId]) || {}
)

export const makeGetCurrentMenu = () => createSelector(getCurrentMenu, menu => menu)

// FS > QS Developers : Change this to get your test build to use FS mode.

export const getTabsModeEnabled = createSelector(
  getCurrentMenu,
  (menu) => menu.enableFullServiceInPos ?? false
)

export const getKitchenPrintingEnabled = createSelector(
  getCurrentMenu,
  (menu) => menu?.enableQuickServiceKitchenPrinting
)

export const getTextReceiptsEnabled = createSelector(
  getCurrentMenu,
  (menu) => menu?.enablePosSmsReceipts
)

export const getRequireManagerPinForConfiguration = createSelector(
  getCurrentMenu,
  (menu) => menu?.requireManagerForPosConfig
)

export const getEmailReceiptsEnabled = createSelector(
  getCurrentMenu,
  (menu) => menu?.enablePosEmailReceipts
)

export const getRequirePaymentForTabs = createSelector(
  getCurrentMenu,
  (menu) => menu?.requirePaymentForPosTabs
)

export const getIsKiosk = createSelector(
  getDeviceSubtype, (deviceSubtype) => {
    return DeviceSubtypes.isKiosk(deviceSubtype)
  }
)

export const getTipsEnabled = createSelector(
  getCurrentMenu, getIsKiosk, (menu, isKiosk) => {
    return !isEmpty(menu.tipSuggestions)
  }
)

export const getCurrentStand = createSelector(
  getStands,
  getCurrentMenu,
  (stands, currentMenu) => find(stands, ['uuid', currentMenu.standUuid])
)

export const getIsStandAvailable = createSelector(
  getSelectedMenus,
  (menus) => {
      return some(menus, menus => menus?.isAvailable && menus?.orderNow)
  }
)

export const getSelectedMenusImages = createSelector(
  getSelectedMenus,
  (selectedMenus) =>
    selectedMenus
      .map(menu => get(menu, 'images.detail'))
      .filter(image => !!image)
)

export const getMenusRequestThreshold = () => 1

export const getMenusTimestamp = (state) => state?.menus?.timeStamp ?? moment().subtract(2, 'hours').toString()

export const getMenusNeedReload = createSelector(
  getMenus, getMenusTimestamp, getMenusRequestThreshold,
  (menus, timeStamp, threshold) => {
    const thresholdExceeded = moment().diff(moment(timeStamp), 'hours') > threshold
    const noRecordsAvailable = menus.length === 0

    if (thresholdExceeded || noRecordsAvailable) {
      return true
    } else {
      return false
    }
  }
)
