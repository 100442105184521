import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { makeGetOrder } from '../../selectors/order'

import ReceiptSelect from '../../components/receipt/ReceiptSelect'

const mapStateToProps = (state, ownProps) => {
  const orderId = ownProps?.match?.params?.orderUuid ?? ''
  const getOrder = makeGetOrder()
  const order = getOrder(state, orderId)
  const payments = order?.payments ?? []
  
  return {
    ...order,
    payments,
    orderId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const orderId = ownProps?.match?.params?.orderUuid ?? ''
  const replace = ownProps?.history?.replace ?? function(){}

  return {
    onClose: () => replace('/orders'),
    navigateToReceipt: (paymentId) => {
        replace(`/receipt/${orderId}/${paymentId}`)
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReceiptSelect))
