import { connect } from 'react-redux'

import RequestRefundModal from '../components/orders/RequestRefundModal'

import { requestRefund, requestTabbedRefund, requestTabbedRefundOffline } from '../actions/refund'
import { makeGetRefund } from '../selectors/refund'

const mapStateToProps = (state) => {
  const getSelectedRefund = makeGetRefund()
  const refund = getSelectedRefund(state)

  return {
    refund,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestRefund: ({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }) => {
      dispatch(requestRefund({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }))
    },
    requestTabbedRefund: ({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }) => {
      dispatch(requestTabbedRefund({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }))
    },
    requestTabbedRefundOffline: ({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }) => {
      dispatch(requestTabbedRefundOffline({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestRefundModal)
