import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

import NumberPadV2 from './NumberPadV2'
import ChangeCalculatorField from './ChangeCalculatorField'
import useRemoteOrderTotal from '../hooks/useRemoteOrderTotal'
import Button from '../components/Button'
import SegmentedControl from './SegmentedControl'

import styles from './ChangeCalculatorForm.module.scss'
import VNConcessionsNavBar from './VNConcessionsNavBar'

const ChangeCalculatorForm = ({ onComplete, onBack, total, paymentId, tenderAmountInCents, className, ...props }) => {
  const classNames = cn(styles.changeCalculatorForm, className)
  const [input, setInput] = useState('')
  const [change, setChange] = useState(0)
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, loading, success, failed }] = useRemoteOrderTotal()
  const totalInCents = remoteOrderTotal?.totalAmountInCents ?? total
  const noPaymentRequired = totalInCents === 0

  useEffect(() => {
    if (!paymentId) {
      loadRemoteOrderTotal()
    }
  }, [])

  const onTapNumberPad = (value) => {
    const newNumber = value === 'backspace' ? input.slice(0, -1) : input + value
    const change = parseInt(newNumber) - totalInCents
    const newChange = change > 0 ? change : 0

    setInput(newNumber)
    setChange(newChange)
  }

  const onTapShortcut = (value, isExact = false) => {
    if (isExact) {
      const amount = value
      setInput(`${amount}`)
      setChange(0)
      return
    }

    const newNumber = input === '' ? value : parseInt(input) + value 
    const change = parseInt(newNumber) - totalInCents
    const newChange = change > 0 ? change : 0

    setInput(`${newNumber}`)
    setChange(newChange)
  }

  const clearInput = () => {
    setChange(0)
    setInput('')
  }

  const totalLabel = loading ? '-' : `$${(parseInt(totalInCents) / 100).toFixed(2)}`
  const inputLabel = `$${(parseInt(input) / 100 || 0).toFixed(2)}`
  let changeLabel = '--'
  let changeLabelState = 'default'

  if (change > 0) {
    changeLabel = `$${(parseInt(change) / 100 || 0).toFixed(2)}`
    changeLabelState = 'prominent'
  }

  if (parseInt(input) === totalInCents) {
    changeLabel = '$0.00'
    changeLabelState = 'prominent'
  }

  useEffect(() => {
    if (noPaymentRequired && !loading) {
      onComplete(noPaymentRequired)
    }
  }, [totalInCents, loading])

  const [selectedAmountIndex, setSelectedAmountIndex] = useState(undefined)
  const isDoneButtonDisabled = parseInt(input) - totalInCents < 0 || !input
  const clientWidth = document?.body?.clientWidth

  const suggestedShortcutAmounts = ['$1', '$5', '$20', 'Exact']
  const suggestedShortcutAmountInCents = [100, 500, 2000, true]
  const history = useHistory()

  // for back button in cash modal, redircts directly to menu
  const onBackClick = () => {
    history.goBack()
  }

  const selectShortcutAmount = (index) => {
    let isExact = false
    let amount = suggestedShortcutAmountInCents[index]

    if (typeof amount !== 'number') {
      isExact = true
      amount = totalInCents
    }

    setSelectedAmountIndex(index)
    onTapShortcut(amount, isExact)
  }

  return (
    <>
      {clientWidth >= 500 ? (
        <div {...props} className={classNames}>
          <VNConcessionsNavBar onClick={onBackClick} showIcon={true} />

          <div className={styles.totalLabelWrapper}>
            <div className={styles.totalLabel}>{totalLabel}</div>
          </div>

          <div className={styles.changeCalculatorContainer}>
            <div className={styles.row}>
              <div className={styles.col1}>
                <div className={styles.numberPadContainer}>
                  <div className={styles.shortcutsContainer}>
                    <SegmentedControl
                      items={suggestedShortcutAmounts}
                      onChange={selectShortcutAmount}
                      selectedIndex={selectedAmountIndex}
                      className={styles.cashSuggestions}
                      color="primary"
                    />
                  </div>
                  <div className={styles.numberPad}>
                    <NumberPadV2
                      onTapButton={onTapNumberPad}
                      isCashTender={true}
                    />
                  </div>
                  <div className={styles.doneBttnContainer}>
                    <button
                      onClick={() => onComplete(noPaymentRequired)}
                      disabled={(parseInt(input) || 0) < totalInCents}
                      className={styles.doneButton}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>

              <div className={styles.col2}>
                <div className={styles.sideBar}>
                  <ChangeCalculatorField
                    className={styles.field}
                    label="amount paid"
                    text={inputLabel}
                    state="active"
                    canClear={input !== ""}
                    onClear={clearInput}
                  />
                  <ChangeCalculatorField
                    className={styles.field}
                    label="change due"
                    text={changeLabel}
                    state={changeLabelState}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <VNConcessionsNavBar
            textDisplay={totalLabel}
            onClick={onBack}
            showIcon={true}
          />
          <div className={styles.changeCalculatorBody}>
            <div className={styles.changeCalculatorTransaction}>
              <div className={styles.changeCalculatorTransactionItem}>
                <span className={styles.label}>Amount Paid:</span>
                <span className={styles.amount}>{inputLabel}</span>
              </div>
              <div className={styles.changeCalculatorTransactionItem}>
                <span className={styles.label}>Change Due:</span>
                <span className={styles.amount}>{changeLabel}</span>
              </div>
            </div>
            <div className={styles.changeCalculatorShortcuts}>
              <SegmentedControl
                items={suggestedShortcutAmounts}
                onChange={selectShortcutAmount}
                selectedIndex={selectedAmountIndex}
                className={styles.cashSuggestions}
              />
            </div>
            <div className={styles.numberPadContainer}>
              <div className={styles.numberPad}>
                <NumberPadV2 onTapButton={onTapNumberPad} isCashTender={true} />
              </div>
            </div>
          </div>
          <div className={styles.changeCalculatorFooter}>
            <Button
              disabled={isDoneButtonDisabled}
              onClick={() => onComplete(noPaymentRequired)}
              className={styles.completeButton}
            >
              Done
            </Button>
          </div>
        </>
      )}
    </>
  )
}

ChangeCalculatorForm.defaultProps = {
  onComplete: () => {},
  total: 0
}

ChangeCalculatorForm.propTypes = {
  onComplete: PropTypes.func,
  total: PropTypes.number
}

export default ChangeCalculatorForm
