import { createSelector } from 'reselect'
import { get, pick } from 'lodash'

const getRefunds = (state) => get(state, 'refund.byId', {})

const getRefund = (state, orderId) => {
  const selectedOrderId  = orderId ? orderId : state.order.selectedOrderId
  const refund = get(state, `refund.byId.${selectedOrderId}`, {})

  return { ...refund }
}

export const makeGetRefund = () => createSelector([getRefund], (refund) => refund)

const getSyncedRefundIds = (state) => get(state, 'refund.successRemoteIds', [])

export const makeGetSyncedRefundIds = () => createSelector([getSyncedRefundIds], (syncedRefundsIds) => syncedRefundsIds)

export const makeGetSyncedRefundsCount = () => createSelector(
  [makeGetSyncedRefundIds()],
  (syncedRefundIds) => syncedRefundIds.length
)

const getUnsyncedRefundIds = (state) => get(state, 'refund.failureRemoteIds', [])

export const makeGetUnsyncedRefundIds = () => createSelector([getUnsyncedRefundIds], (unsyncedRefundIds) => unsyncedRefundIds)

export const makeGetUnsyncedRefundsCount = () => createSelector(
  [makeGetUnsyncedRefundIds()],
  (unsyncedRefundIds) => unsyncedRefundIds.length
)

export const getUnsyncedRefunds = createSelector(
  [getRefunds, makeGetUnsyncedRefundIds()],
  (refunds, unsyncedRefundIds) => pick(refunds, unsyncedRefundIds)
)
