export const FETCH_EMPLOYEE_ROLES = 'FETCH_EMPLOYEE_ROLES'
export const FETCH_EMPLOYEE_ROLES_SUCCEEDED = 'FETCH_EMPLOYEE_ROLES_SUCCEEDED'
export const FETCH_EMPLOYEE_ROLES_FAILED = 'FETCH_EMPLOYEE_ROLES_FAILED'

export const fetchEmployeeRoles = () => ({
  type: FETCH_EMPLOYEE_ROLES,
})

export const fetchEmployeeRolesFailed = (payload) => ({
  type: FETCH_EMPLOYEE_ROLES_FAILED,
  payload,
})

export const fetchEmployeeRolesSucceeded = (payload) => ({
  type: FETCH_EMPLOYEE_ROLES_SUCCEEDED,
  payload,
})