import React, { useState } from "react"
import cn from "classnames"

import { ReactComponent as HamburgerIcon } from "../assets/icons/hamburgerIcon.svg"
import { ReactComponent as HamburgerIconMobile } from "../assets/icons/hamburgerIconMobile.svg"
import BackButtonChevron from "./BackButtonChevron"
import NetworkIndicator from "./NetworkIndicator"
import styles from "./VNConcessionsNavBar.module.scss"

import NavMenu from "../components/NavMenu"
import useGetIsMobileScreen from "../hooks/useGetIsMobileScreen"


const VNConcessionsNavBar = ({
  textDisplay = "",
  onClick = () => {},
  cardReaderInitialized,
  cardReaderScreen,
  className,
  useHamburgerIcon,
  showIcon = true,
  leftAccessory,
  rightAccessory,
  showBorder = true,
  useSmallTitleFont = false,
}) => {
  const classNames = cn(styles.vnConcessionsNavBar, className, showBorder && styles.showBorder)
  const isMobileViewPort = useGetIsMobileScreen()

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)
  const openHamburgerMenu = () => setIsHamburgerMenuOpen(true)
  const closeHamburgerMenu = () => setIsHamburgerMenuOpen(false)
  const hamburgerIcon = isMobileViewPort ? (
    <HamburgerIconMobile onClick={openHamburgerMenu} />
  ) : (
    <HamburgerIcon onClick={openHamburgerMenu} />
  )

  const backButtonIcon = (
    <BackButtonChevron
      onClick={onClick}
      backButtonText={isMobileViewPort ? null : "Go Back"}
    />
  )

  if (useHamburgerIcon) {
    leftAccessory = hamburgerIcon
  } else if (!leftAccessory) {
    leftAccessory = backButtonIcon
  }

  const rightIcon = rightAccessory && isMobileViewPort ? rightAccessory : <NetworkIndicator />

  const navBarCenterText = textDisplay && (
    <div
      className={
        useSmallTitleFont && isMobileViewPort ? styles.smallTitle : styles.title
      }
    >
      {textDisplay}
    </div>
  )

  const cardReaderIndicator = cardReaderScreen && (
  <>
    <div className={styles.cardReaderDots} />
    {cardReaderInitialized && (
      <div
        className={cn([styles.cardReaderDots, styles.cardReaderInitialized])}
      />
    )}
  </>
)

  const getBorder = () => {
    if (showBorder) {
      return {}
    }

    return {
      border: 'none'
    }
  }

  return (
    <section className={classNames} style={getBorder()}>
      <div className={styles.leftAccessory}>{showIcon && leftAccessory}</div>
      {navBarCenterText}
      <div className={styles.rightAccessory}>{rightIcon}</div>
      {isHamburgerMenuOpen && <NavMenu onToggle={closeHamburgerMenu} />}
      {cardReaderIndicator}
    </section>
  )
}

export default VNConcessionsNavBar
