import {
  FETCH_ATTENDANT_SETTINGS,
  FETCH_ATTENDANT_SETTINGS_SUCCEEDED,
  FETCH_ATTENDANT_SETTINGS_FAILED,
} from '../actions/attendantSettings'

import { CLEAR_ATTEMPT_PIN_LOGIN_STATUS } from '../actions/attendant'

const initialState = {
  byId: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANT_SETTINGS:
      return {
        ...state,
        errorMessage: undefined,
      }

    case FETCH_ATTENDANT_SETTINGS_SUCCEEDED:
      const attendantSettings = action?.payload?.data?.attendantSettings?.[0] ?? {}
      const attendantSettingId = attendantSettings?.uuid

      return {
        ...state,
        byId: {
          [attendantSettingId]: {
            ...attendantSettings,
          }
        }
      }

    case FETCH_ATTENDANT_SETTINGS_FAILED:
      return {
        ...state,
        errorMessage: action?.payload?.errorMessage

      }

    case CLEAR_ATTEMPT_PIN_LOGIN_STATUS:
      return {
        ...state,
        errorMessage: undefined,
      }

    default:
      return state
  }
}
