import { keys } from 'lodash'

import { DEFAULT_EXEMPTIONS } from '../../utils/orderTotalUtils'
import { ORDER_STATES } from '../../utils/orderStates'

const backendDefaults = {
  createdAt: undefined,
  customerId: '',
  discountAmountInCents: '0.00', // this is a string in Stadium
  email: '',
  frozen: false,
  lineItems: [],
  orderType: '',
  paymentAuthorizedAt: undefined,
  paymentInfo: {},
  promotions: [],
  salesEventId: '',
  standMenuUuid: '',
  standUuid: '',
  standName: '',
  state: ORDER_STATES['INCOMPLETE'],
  subtotal: '0.00',
  tip: '0.00',
  total: '0.00',
  updatedAt: undefined,
  userAttributes: {},
  userName: '',
  userNotes: '',
  uuid: '',
  remainingBalance: '0.0',
  employeeId: '',
}

export const backendOrderModelKeys = keys(backendDefaults)

const frontendDefaults = {
  amountInCents: 0,
  cardReaderData: {},
  createOrderParams: {},
  exemptionOverrides: DEFAULT_EXEMPTIONS,
  isRemotePayment: false,
  itemModels: [],
  remoteOrderTotal: {},
  orderTotalFromOrder: {},
  paymentCompleted: false,
  paymentDisplay: '',
  submittedAt: new Date(),
}

export const frontendOrderModelKeys = keys(frontendDefaults)

export const orderModelKeys = [ ...backendOrderModelKeys, ...frontendOrderModelKeys ]

export const defaultAttributes = {
  ...backendDefaults,
  ...frontendDefaults,
}