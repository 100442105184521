import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import cn from 'classnames'

import { ReactComponent as KioskCheckmarkIcon } from '../assets/icons/checkmark-icon.svg'
import { ReactComponent as UnselectedRadioButton } from "../assets/icons/unselected-radio-button-icon.svg"
import { ReactComponent as SelectedRadioButton } from "../assets/icons/selected-radio-button-icon.svg"
import { ReactComponent as UnselectedCheckBox } from "../assets/icons/fill-unchecked.svg"
import { ReactComponent as SelectedCheckBox } from "../assets/icons/fill-checkmark.svg"

import { getIsKiosk } from '../selectors/menus'
import styles from './ModifierGroup.module.scss'

const ModifierItem = ({
  id,
  isKiosk,
  name,
  price,
  mode,
  selected,
  onSelect,
  className,
  modiferRef,
  modifiersKeyPress,
  index,
}) => {
  const classNames = cn(styles.modifierItem, className)
  const formattedPrice = isEmpty(price)
    ? ""
    : isKiosk
    ? `+ ${price}`
    : `+${price}`

  let checkBox = selected ? <SelectedCheckBox /> : <UnselectedCheckBox />
  let radioSelect = selected ? <SelectedRadioButton /> : <UnselectedRadioButton />

  let selector = mode === "checkbox" ? checkBox : radioSelect

  return (
    <div
      className={classNames}
      tabindex="0"
      ref={modiferRef}
      aria-label={`${name}, ${price}`}
      onClick={() => onSelect(id)}
      onKeyDown={(e) => modifiersKeyPress(e, index)}
    >
      <input
        className={styles.input}
        type={mode}
        value={id}
        checked={selected}
        readOnly
      />
      <label className={styles.label}>
        {!isKiosk ? (
          <>
            <div className={styles.selectOption}>{selector}</div>
            <span className={styles.name}>
              {name} {formattedPrice}
            </span>
          </>
        ) : (
          <>
            <KioskCheckmarkIcon className={styles.checkedIcon} />
            <span className={styles.name}>{name}</span>
            <span> {formattedPrice}</span>
          </>
        )}
      </label>
    </div>
  )
}

ModifierItem.defaultProps = {
  id: '',
  name: 'Modifier',
  price: '',
  mode: 'checkbox',
  selected: false,
  onSelect: () => {},
}

ModifierItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  groupName: PropTypes.string,
  price: PropTypes.string,
  mode: PropTypes.oneOf(['checkbox', 'radio']),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}


const ModifierGroup = ({ theme, isKiosk, uuid, title, label, items, selectedItemIds, defaultItems, min, max, isRequired, onSelect, className, modiferRef, modifiersKeyPress, ...props }) => {
  const themeColors = theme === 'bright' ? styles.brightTheme : null
  const classNames = cn(styles.modifierGroup, className, themeColors)
  const mode = isRequired ? 'radio' : 'checkbox'

  const onSelectItem = (itemId) => {
    onSelect(itemId)
  }
  return (
    <div {...props} className={classNames} aria-label={title}>
      {isKiosk ? 
        (<div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subTitle}>{label}</span>
        </div>) :
      
        (<div className={styles.concessionsHeader}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{label}</div>
        </div>)
      }
      <div className={styles.items}>
        {items.map((item, index ) => {
          const uniqIdWithinModifierGroup = `${item.id}|${uuid}`
          return (
            <ModifierItem
              key={uniqIdWithinModifierGroup}
              isKiosk={isKiosk}
              id={uniqIdWithinModifierGroup}
              mode={mode}
              name={item.name}
              price={item.defaultPriceInCents > 0 ? `$${(item.defaultPriceInCents / 100).toFixed(2)}` : ''}
              selected={selectedItemIds.includes(uniqIdWithinModifierGroup)}
              onSelect={onSelectItem}
              modiferRef={el => modiferRef && modiferRef(el, index)}
              modifiersKeyPress={modifiersKeyPress}
              index={index}
            />
          )
        })}
      </div>
    </div>
  )
}

ModifierGroup.defaultProps = {
  title: '',
  label: '',
  items: [],
  selectedItemIds: [],
  defaultItems: [],
  min: null,
  max: null,
  isRequired: false,
  onSelect: () => {},
}

ModifierGroup.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  selectedItemIds: PropTypes.array,
  defaultItems: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => { 
  const isKiosk = getIsKiosk(state)

  return {
    isKiosk,
  }
}


export default withRouter(connect(mapStateToProps, undefined)(ModifierGroup)) 
