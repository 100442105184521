import { get } from 'lodash'

const add_revenue_center_tree = {
  3: (state) => {
    return {
      ...state,
      revenueCenter: {
        ...get(state, 'revenueCenter', {}),
        byId: {
          ...get(state, 'revenueCenter.byId', { 'unassigned': { uuid: 'unassigned', name: 'Unassigned Orders' } }),
        },
        allIds: [...get(state, 'revenueCenter.allIds', []), [ 'unassigned' ]],
        activeRevenueCenter: undefined,
      }
    }
  },
}

export default add_revenue_center_tree
