import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './Summary.module.scss'

import PeripheralBridge from '../../utils/peripheralBridge'
import useGetSummaryReport from '../../hooks/useGetSummaryReport'
import moment from 'moment'
import { map } from 'lodash'
import { centsToDollars } from '../../utils/formatters'
import { getNetworkAvailableValue } from '../../utils/networkConnected'
import SidebarModal from '../SidebarModal'

import { startSyncCycle, OFFLINE_SAF_MODE } from '../../hooks/useSafMode'

import { APPLICATION_STRINGS } from '../../strings'
import { getDeviceMode } from '../../VNMode/Selectors'
import { useSelector } from 'react-redux'
import ConfirmModalV2 from '../ConfirmModalV2'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'
import { getOrderReportsLastFetchedAt } from '../../selectors/order'

const OrderSummary = ({
  activeOfflineCardCount,
  attendant,
  allRevenueInCents,
  deviceUuid,
  className,
  menuName,
  unsyncedLocalOrderCount,
  onCheckNetworkClick,
  unsyncableIds,
  onClose,
  onRequestSyncRefund,
  unsyncedRefundIds,
  venueName,
  safMode,
  safResponse,
  safIsUploading,
  setSafIsUploading,
  safUploadComplete,
  dataIsUploading,
  setDataIsUploading,
  dataUploadComplete,
  secondaryDeviceId,
}) => {
  const classNames = cn(styles.orderSummary, className)

  const unsyncableOrders = unsyncableIds.length

  const [getSummaryReport, { data: totals }] = useGetSummaryReport()
  const isMobileViewPort = useGetIsMobileScreen()

  // TODO(mkramerl): Improve card reader disconnected error handling.
  const [showCardReaderConnectError, setShowCardReaderConnectError] = useState(false)
  const [syncRequestMade, setSyncRequestMade] = useState(false)
  const [stopSyncRequestMade, setStopSyncRequestMade] = useState(false)
  const [shouldSync, setShouldSync] = useState(false)
  const [syncRequestStarted, setSyncRequestStarted] = useState(false)
  const [insufficientNetwork, setInsufficientNetwork] = useState(false)
  const [showCompletionModal, setShowCompletionModal] = useState(false)
  const [showSyncOfflineModeModal, setShowSyncOfflineModeModal] = useState(false)

  const cfdMode = useSelector(state => getDeviceMode(state))
  const orderReportsLastFetchedAt = useSelector(state => getOrderReportsLastFetchedAt(state))

  const urlParams = new URLSearchParams(window.location.search)
  const fromIdleReplay = urlParams.get('from') === 'idleReplay'
  const todaysDate = useMemo(() => moment().format('MM/DD/YY'), [])
  const tomorrowsDate = useMemo(() => moment().add(1, 'days').format('MM/DD/YY'), [])

  const onContinueSyncClick = () => {
    setSyncRequestMade(false)
    setShouldSync(true)
    setSyncRequestStarted(true)
  }
  const onSyncOrdersClick = () => {
    // TODO(mkramerl): Convert all sync modals to use recognition within the click action.
    if (safMode === OFFLINE_SAF_MODE) {
      setShowSyncOfflineModeModal(true)
      return
    }

    setSyncRequestMade(true)

    if (!getNetworkAvailableValue()) setInsufficientNetwork(true)
  }

  const onStopSyncClick = () => {
    setShouldSync(false)

    setStopSyncRequestMade(false)
    setSyncRequestMade(false)

    setSafIsUploading(false)
    setDataIsUploading(false)
  }

  useEffect(() => {
    getSummaryReport()

    if (fromIdleReplay) {
      setShowSyncOfflineModeModal(true)
      onStopSyncClick()
    }
  }, [])

  useEffect(() => {
    if (safIsUploading && safMode === OFFLINE_SAF_MODE) {
      setShowSyncOfflineModeModal(true)
      onStopSyncClick()

      return
    }

    if (!syncRequestStarted) return 
    if (safIsUploading || dataIsUploading) return
    if (activeOfflineCardCount > 0 || unsyncedLocalOrderCount > 0) return

    setShowCompletionModal(true)
  }, [safIsUploading, dataIsUploading, activeOfflineCardCount, unsyncedLocalOrderCount, safMode, syncRequestStarted])

  useEffect(() => {
    if (!shouldSync) {
      return
    }

    const bridgeUnavailableCallback = () => {
      console.log('Unable to connect to peripheral. Please try again later.')
      setShowCardReaderConnectError(true)

      // Do not stop the sync if the bridge is unavailble. Instead, potentially proceed with the
      // platform sync.
      // onStopSyncClick()

      return
    }

    const bridgeInstance = PeripheralBridge.getBridge()
    startSyncCycle(false, bridgeUnavailableCallback, bridgeInstance, cfdMode)

    setShouldSync(false)
  }, [shouldSync])

  const renderFieldRow = (fields, index) => (
    <div
      className={styles.row}
      key={`${fields?.columnOne?.value}-${index}`}
    >
      {map(fields, (field) => (
        <div className={styles.column} style={field?.style}>
          {field?.value}
        </div>
      ))}
    </div>
  )

  const createField = (value = '', style = {}) => ({
    value,
    style,
  })

  const addWhiteTextStyle = {
    color: 'white',
  }

  const addBoldWhiteText = {
    color: 'white',
    fontWeight: '700',
  }

  const sectionOneRows = [
    [
      createField('Venue: ' + venueName),
    ],
    [
      createField(`Report Date: ${todaysDate}, 3:00AM - ${tomorrowsDate}, 3:00AM`),
    ],
    [
      createField(`Last Updated: ${orderReportsLastFetchedAt}`),
    ],
  ]

  let sectionTwoRows = [
    [
      createField(attendant?.name, addBoldWhiteText),
    ],
    [
      createField('ID: ' + attendant?.uuid, addWhiteTextStyle),
    ],
    [
      createField('Gross Sales'),
      createField(totals?.gross ?? centsToDollars(allRevenueInCents), addWhiteTextStyle),
    ],
    [
      createField('Discounts'),
      createField(totals?.discounts ?? '-', addWhiteTextStyle)
    ],
    [
      createField('Refunds'),
      createField(totals?.refunds ?? '-', addWhiteTextStyle)
    ],
    [
      createField('Net Sales'),
      createField(totals?.net ?? centsToDollars(allRevenueInCents), addWhiteTextStyle),
    ],
    [
      createField('Tax'),
      createField(totals?.tax ?? '_', addWhiteTextStyle),
    ],
    [
      createField('Service Charge'),
      createField(totals?.serviceCharge ?? '_', addWhiteTextStyle),
    ],
    [
      createField('Tips'),
      createField(totals?.tips ?? '-', addWhiteTextStyle)
    ],
    [
      createField('Open Tabs', totals?.tabsCount > 0 ? {color: '#EB628D'} : {}),
      createField( totals?.tabsCount ?? '0', {color: totals?.tabsCount > 0 ? '#EB628D' : '#999999'})
    ],
  ]

  const sectionThreeRows = [
    [
      createField('Device', addBoldWhiteText),
    ],
    [
      createField('ID: ' + deviceUuid, addWhiteTextStyle),
    ],
    [
      createField('Secondary ID: ' + secondaryDeviceId, addWhiteTextStyle),
    ],
    [
      createField('Offline Card Transactions', activeOfflineCardCount > 0 ? {color: '#EB628D'} : {}),
      createField(activeOfflineCardCount, {color: activeOfflineCardCount > 0 ? '#EB628D' : 'white'})
    ],
    [
      createField("Unsynced Order Details Data", unsyncedLocalOrderCount > 0 ? {color: '#EB628D'} : {}),
      createField(unsyncedLocalOrderCount ?? '0', {color: unsyncedLocalOrderCount > 0 ? '#EB628D' : '#999999'})
    ],
    // [
    //   createField('Unsynced Refund Requests', unsyncedRefundIds.length > 0 ? {color: '#EB628D'} : {}),
    //   createField(unsyncedRefundIds.length ?? '0', {color: unsyncedRefundIds.length > 0 ? '#EB628D' : '#999999'})
    // ],
    [
      createField('Defective Orders Unable to Sync', unsyncableOrders > 0 ? {color: '#EB628D'} : {}),
      createField(unsyncableOrders ?? '0', {color: unsyncableOrders > 0 ? '#EB628D' : '#999999'})
    ],
    // [
    //   createField('Cash Collected Today', {color: '#EB628D'}),
    //   createField('$123.00', {color: '#EB628D'})
    // ]
  ]

  const syncMessage = isMobileViewPort
    ? "If you continue, you will be blocked\n from any further actions on this\n device until the sync is complete.\n This process can take several\n minutes."
    : "If you continue, you will be blocked\n from any further actions on this device\n until the sync is complete. This\n process can take several minutes."

  const stopSyncMessage = isMobileViewPort
    ? "Stopping the sync will leave some orders unsynced."
    : "Stopping the sync will leave some\n orders unsynced."


  const noSyncMessage = 'Nothing to sync'
  const syncOfflineMessage = 'You can not sync while the card reader is in an offline mode. Please try again when the card reader is online.'

  const insufficientNetworkMessage = "Your network connection is\n insufficient. Please find a stable\n network connection and retry syncing\n orders."

  const sections = [sectionOneRows, sectionTwoRows, sectionThreeRows]
  const content = map(sections, (section) => {
    return (
      <>
        {map(section, renderFieldRow)}
        <div className={styles.borderRow}></div>

      </>
    )
  })

  return (
    <>
    <SidebarModal
      show
      title="Sales Report"
      onClose={onClose}
    >
      <div className={classNames}>
        <div className={styles.scrollable}>
          {!safResponse?.success && (activeOfflineCardCount > 0 || unsyncedLocalOrderCount > 0) &&
            (
              <div className={styles.syncDetailsContainer}>
                <div>
                  <div>{APPLICATION_STRINGS.SAF_ERROR}</div>
                </div>
              </div>
            )
          }

          {content}
        </div>

        {!safIsUploading && !dataIsUploading ? (
          <div className={styles.syncButtonContainer}>
            {/* <button className={styles.syncButton} onClick={syncRefunds}>
              Sync Refunds
            </button> */}
            <button className={cn([styles.syncButton, styles.shift4blue])} onClick={() => onSyncOrdersClick()}>
              {'Sync Orders'}
            </button>
          </div>
        ) :
          <div className={styles.syncButtonContainer}>
            <button className={styles.stopSyncButton} onClick={() => setStopSyncRequestMade(true)}>
              Stop Syncing
            </button>
          </div>
        }
      </div>
    </SidebarModal>

    {/* The below Confirm Dialogs need to be outside of the SidebarModal component */}
    {showSyncOfflineModeModal && (
      <ConfirmModalV2
        onButtonTwoClick={() => setShowSyncOfflineModeModal(false)}
        headerText={''}
        subtext={syncOfflineMessage}
        buttonTwoText={'OK'}
        singleButtonOnly={true}
      />
    )}
    {syncRequestMade && safMode !== OFFLINE_SAF_MODE && !insufficientNetwork && (activeOfflineCardCount > 0 || unsyncedLocalOrderCount > 0) && (
      <ConfirmModalV2
        onButtonOneClick={() => setSyncRequestMade(false)}
        onButtonTwoClick={onContinueSyncClick}
        headerText={''}
        subtext={syncMessage}
        buttonOneText={'Cancel Sync'}
        buttonTwoText={'Continue'}
      />
    )}
    {syncRequestMade && insufficientNetwork && (
      <ConfirmModalV2
        onButtonOneClick={() => setSyncRequestMade(false)}
        onButtonTwoClick={onCheckNetworkClick}
        headerText={''}
        subtext={insufficientNetworkMessage}
        buttonOneText={'Cancel Sync'}
        buttonTwoText={'Check Network'}
      />
    )}
    {stopSyncRequestMade && (
      <ConfirmModalV2
        onButtonOneClick={() => onStopSyncClick()}
        onButtonTwoClick={() => setStopSyncRequestMade(false)}
        headerText={''}
        subtext={stopSyncMessage}
        buttonOneText={'Stop Sync Now'}
        buttonTwoText={'Continue Sync'}
      />
    )}
    {syncRequestMade && activeOfflineCardCount === 0 && unsyncedLocalOrderCount === 0 && (
      <ConfirmModalV2
        onButtonTwoClick={() => setSyncRequestMade(false)}
        headerText={''}
        subtext={noSyncMessage}
        buttonTwoText={'OK'}
        singleButtonOnly={true}
      />
    )}
    {showCompletionModal && (
      <ConfirmModalV2
        onButtonTwoClick={() => setShowCompletionModal(false)}
        headerText={''}
        subtext={'All orders are now synced.'}
        buttonTwoText={'OK'}
        singleButtonOnly={true}
      />
    )}
    {showCardReaderConnectError && (
      <ConfirmModalV2
        onButtonTwoClick={() => setShowCardReaderConnectError(false)}
        headerText={''}
        subtext="No card reader connection. Try syncing again."
        buttonTwoText={'OK'}
        singleButtonOnly={true}
      />
    )}
    </>
  )
}

OrderSummary.defaultProps = {
  activeOfflineCardCount: 0,
  allRevenueInCents: 0,
  menuName: '',
  offlineLocalIds: [],
  cardReaderFailedCardCount: -1,
  cardReaderDeletedCardCount: -1,
  cardReaderTotalCardCount: -1,
  cardReaderUploadedCardCount: -1,
  unsyncableIds: [],
  orderCounts: {},
  safIsUploading: false,
  safUploadComplete: false,
  dataIsUploading: false,
  dataUploadComplete: false,
}

OrderSummary.propTypes = {
  activeOfflineCardCount: PropTypes.number,
  allRevenueInCents: PropTypes.number,
  menuName: PropTypes.string,
  offlineCardIds: PropTypes.array,
  offlineLocalIds: PropTypes.array,
  onCheckNetworkClick: PropTypes.func,
  isPaxDevice: PropTypes.bool,
  cardReaderFailedCardCount: PropTypes.number,
  cardReaderDeletedCardCount: PropTypes.number,
  cardReaderTotalCardCount: PropTypes.number,
  cardReaderUploadedCardCount: PropTypes.number,
  unsyncableIds: PropTypes.array,
  onInitializeSync: PropTypes.func,
  orderCounts: PropTypes.object,
  updateSafIsUploading: PropTypes.func,
  safIsUploading: PropTypes.bool,
  safUploadComplete: PropTypes.bool,
  dataIsUploading: PropTypes.bool,
  dataUploadComplete: PropTypes.bool,
}

export default OrderSummary
