import { useEffect, useRef, useState } from "react";
import { Barcode, BarcodePicker, ScanSettings, configure, CameraSettings } from "scandit-sdk";
import { SCANDIT_KEY } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setVNScannerInitialized } from "../ActionCreators";
import { getAndroidSDKVersion } from "../../VNAndroidSDK/Selectors"
import { isSDKCompatible } from "../../VNAndroidSDK/Utils"
import { getIsVNScannerBeingUsed } from "../Selectors";
import { ToastManager } from "../../utils/toastManager";

/**
 * This component is needed to initialize the scandit sdk. The scandit sdk initialization takes
 * 30 seconds on the A800. This component initializes the sdk in the background and therefore
 * when the user goes to scan it's instant.
 */
export const VNMobileScannerSetup = () => {

  const dispatch = useDispatch()
  const divRef = useRef()
  const scannerUsedRef = useRef(false)
  const usingVNScanner = useSelector(state => getIsVNScannerBeingUsed(state))
  const androidSDKVersion = useSelector(state => getAndroidSDKVersion(state))
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    scannerUsedRef.current = usingVNScanner
  }, [usingVNScanner])

  // useEffects
  useEffect(() => {
    if (isSDKCompatible(androidSDKVersion, '2.14.0') && !initialized) {
      if (navigator?.userAgent?.includes("A800")) {
        /** Configure SCANDIT */
        const configurationPromise = configure(
          SCANDIT_KEY
        ).catch((error) => {
          alert(error);
        })

        const scanSettings = new ScanSettings({
          enabledSymbologies: [
              Barcode.Symbology.CODE128,
              Barcode.Symbology.PDF417,
              Barcode.Symbology.QR
            ],
          codeDuplicateFilter: 5000, // don't scan the same code so quick
          searchArea: {x: 0.1, y: 0.25, width: 0.8, height: 0.5},
        })

        const barcodePickerSettings = {
          guiStyle: BarcodePicker.GuiStyle.VIEWFINDER,
          videoFit: BarcodePicker.ObjectFit.COVER,
          viewfinderArea: {x: 0.1, y: 0.25, width: 0.8, height: 0.5},
          scanSettings: scanSettings,
          enableCameraSwitcher: false,
          enableTorchToggle: false,
          cameraSettings: CameraSettings.ResolutionPreference.FULL_HD
        }

        startVideoDecode(configurationPromise, barcodePickerSettings)
      } else {
        dispatch(setVNScannerInitialized(false))
      }
    }
    // the useEffect needs the android sdk version because on first launch the sdk version is not present.
  }, [androidSDKVersion])

  const startVideoDecode = (configurationPromise, barcodePickerSettings) => {
    configurationPromise.then(() => {
      BarcodePicker.create(divRef.current, barcodePickerSettings).then((barcodePicker) => {
        dispatch(setVNScannerInitialized(true))
        setInitialized(true)
        // save the mobile scanner ref in state. Re-creating the mobile scanner causes another load time of 30 seconds.
        window.VNMobileBarcodePicker = barcodePicker

        barcodePicker.on('ready', () => {
          if (!scannerUsedRef.current) {
            // pause the camera access so performance on the A800 is not effected.
            barcodePicker.pauseScanning(true)
            barcodePicker.pauseCameraAccess()
          }
        })
      }).catch(error => {
        dispatch(setVNScannerInitialized(false))
        if (error.name === 'NotAllowedError') {
          ToastManager.error(`MOBILE SCANNER REQUIRES CAMERA PERMISSIONS`)
        } else {
          ToastManager.error(`MOBILE SCANNER INITIALIZE ERROR ${error}`)
        }
      })
    })
  }

  // the div needs a ref for the barcode picker to attach onto.
  return (
    // specific styling used to avoid having a white bar at the bottom of the screen.
    // this only affects the A800 device.
    // We originally tried having display: none, but that would not fully hide the scandit component, which would cause the white bar at the bottom of most screens,
    // this is a work around that issue. We have also tried a <span> but it also did not hide the white bar. This styling seems to be the only styling to fully hide
    // scandit.
    <div style={{ height: "1px", position: "absolute", bottom: "0"}} ref={divRef}></div>
  )
}