import { get, padStart, map, orderBy, filter, reduce, find } from 'lodash'

import { RICH_CHECKOUT_DEEPLINK_SCHEMA } from '../constants'

const removeHyper = str =>
  str
    .toString()
    .replace(/-/g, '')
    .trim()

const enc = s => {
  const b = s.match(/.{1,2}/g)
  return window.btoa(b.reduce((acc, v) => acc += String.fromCharCode('0x' + v), ''))
}

const lineItemString = (item, deepLevel) => {
  // for now, kiosk items do not track quantity on modifiers since it's always 1
  const quantity = get(item, 'quantity', 1)

  return `${removeHyper(item.id || item.uuid)}${deepLevel}${padStart(quantity, 2, '0')}`
}

const lineItemWithMods = (item, deepLevel) => {
  const level = deepLevel || 0
  const levelString = padStart(level, 2, '0')
  const mods = item.modifiers || []

  return lineItemString(item, levelString) + mods.map(mod => lineItemWithMods(mod, level + 1))
}

// returns : the deeplink schema URL prefix including an appropriate procotol separator
const deepLinkSchemaURLPrefix = () => {
  if (RICH_CHECKOUT_DEEPLINK_SCHEMA.includes('://')) {
    return `${RICH_CHECKOUT_DEEPLINK_SCHEMA}`
  }

  return `${RICH_CHECKOUT_DEEPLINK_SCHEMA}://`
}

export const generateURL = (orderId, orderNumber, deviceId, total, items) => {
  const version = '01'

  const orderItems = items.reduce(
    (string, item) => string + lineItemWithMods(item),
    '',
  ).replace(/,/g, '')

  const payload = enc(`${version}${removeHyper(orderId)}${removeHyper(deviceId)}${orderItems}`)

  return `${deepLinkSchemaURLPrefix()}vn/order/pay?t=${total}&p=${payload}&n=${orderNumber}`
}

export const generateURLWithoutTotal = (orderId, orderNumber, deviceId, items) => {
  const version = '01'

  const orderItems = items.reduce(
    (string, item) => string + lineItemWithMods(item),
    '',
  ).replace(/,/g, '')

  const payload = enc(`${version}${removeHyper(orderId)}${removeHyper(deviceId)}${orderItems}`)

  return `${deepLinkSchemaURLPrefix()}vn/order/pay?p=${payload}&n=${orderNumber}`
}

export const formatLocalItems = (items) => {
  return map(items, (item) => {
    return {
      id: item.id || item.uuid,
      quantity: item.quantity ?? 1,
      modifiers: map(item.modifiers, (modifier) => {
        return {
          id: modifier.id || modifier.uuid,
          quantity: 1
        }
      })
    }
  })
}

export const formatRemoteItems = (items) => {
  const sorted = orderBy(items, 'parentLineItemUuid', 'desc')
  const purged = filter(sorted, ['specialType', null])

  const formattedResult = reduce(
    purged,
    (result, item) => {
      if (item.parentLineItemUuid) {
        const parentItem = find(result, ['_lineItemId', item.parentLineItemUuid])

        parentItem.modifiers = [
          ...(parentItem.modifiers ?? []),
          {
            id: item.menuItemUuid,
            quantity: 1
          }
        ]

        return result
      }

      return [
        ...result,
        {
          id: item.menuItemUuid,
          _lineItemId: item.lineItemUuid,
          modifiers: [],
          quantity: item.quantity
        }
      ]
    },
    []
  )

  const parsedResult = map(formattedResult, (item) => {
    const { _lineItemId, ...rest } = item

    return rest
  })

  return parsedResult
}
