import { takeLatest, select } from "@redux-saga/core/effects"
import moment from "moment"
import { put } from "redux-saga/effects"
import { performMaintenance, REQUEST_PERFORM_MAINTENANCE } from "../actions/order"
import { DAYS_TO_FORCE_MAINTENANCE } from "../constants"

import { getMaintenancedAt, getPurgableOrders, getSafeToPerformMaintenance } from "../selectors/order"
import { getSafIsUploading, getPaymentType, PAX_PAYMENT_TYPE } from '../selectors/peripheral'

import { executePerformMaintenance } from "../utils/bridgeMethods"

// 24 hours in milliseconds
const MAINTENANCE_THRESHOLD = 86400000

const dateExceedsMs = (date, ms) => {
  const now = moment()
  const referencePoint = moment(date)
  const msSinceReference = now.diff(referencePoint)

  return msSinceReference >= ms
}

const getPreviousBusinessDate = () => {
  const now = moment()
  const previousDate = now.clone().set({ h: 5, m: 0, s: 0 })
  if (now.isBefore(previousDate)) previousDate.subtract(1, 'day')

  return previousDate
}

export function* performMaintenanceSaga() {
  const safIsUploading = yield select(getSafIsUploading)
  if (safIsUploading) return

  const paymentType = yield select(getPaymentType)
  if (paymentType === PAX_PAYMENT_TYPE) return

  // When was the last time we performed a maintenance request?
  const maintenancedAt = yield select(getMaintenancedAt)

  // If we have not done any maintenance, update maintenancedAt to the last business date.
  if (maintenancedAt === undefined) {
    const previousBusinessDate = getPreviousBusinessDate()
    yield put(performMaintenance([], previousBusinessDate))
    return
  }

  // Are all orders synced?
  const safeToPerformMaintenance = yield select(getSafeToPerformMaintenance)

  // If all orders are synced, perform maintenance
  if (safeToPerformMaintenance) {
    // Get all order ids from our local store older than 1 day old
    const maintenanceableOrderIds = yield select((state) => getPurgableOrders(state, MAINTENANCE_THRESHOLD))

    // Call the bridge method
    executePerformMaintenance()

    // Update our local store
    yield put(performMaintenance(maintenanceableOrderIds))
    return
  }

  // If it has been more than the configured amount of days since we did a maintenance call, force it.
  if (dateExceedsMs(maintenancedAt, DAYS_TO_FORCE_MAINTENANCE * MAINTENANCE_THRESHOLD)) {
    // Get all orders older than the configured amount of days regardless of if they have synced or not.
    const maintenanceableOrderIds = yield select((state) => getPurgableOrders(state, DAYS_TO_FORCE_MAINTENANCE * MAINTENANCE_THRESHOLD, { force: true }))

    // Call the bridge method
    executePerformMaintenance()

    // Update our local store
    yield put(performMaintenance(maintenanceableOrderIds))
    return
  }
}

export function* watchPerformMaintenanceSaga() {
  yield takeLatest(REQUEST_PERFORM_MAINTENANCE, performMaintenanceSaga)
}
