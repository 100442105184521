import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Button from '../Button'

import cn from 'classnames'
import styles from './TopNav.module.scss'
import { ReactComponent as YellowSummaryIcon } from '../../assets/icons/yellow-summary-icon.svg'
import { ReactComponent as GreySummaryIcon } from '../../assets/icons/grey-summary-icon.svg'
import { ReactComponent as GreyMobileSummaryIcon } from '../../assets/icons/summary-mobile.svg'
import { ReactComponent as YellowMobileSummaryIcon } from '../../assets/icons/yellow-summary-mobile.svg'
import { ReactComponent as InputSearchIcon } from '../../assets/input-search-icon.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/Close-Icon.svg'
import ConfirmDialog from '../dialog/ConfirmDialog'

import DropdownSelector from '../DropdownSelector'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'
import { ORDERS_STATUS_OPTIONS } from '../../utils/orderStates'
import { clearOrderInProgress } from '../../actions/order'
import { useDispatch } from 'react-redux'

const TopNav = ({ currentSearch, hasUnsyncedOrders, onClearSearch, onSearch, safIsUploading,
    setSafIsUploading, isKiosk, filterOrders, className, currentFilter, tabsModeEnabled }) => {
  const ordersStatusOptions = ["My Open Tabs", "My Closed Orders", "All Orders"]
  const [ordersStatus, setOrdersStatus] = useState(ordersStatusOptions[0])

  const classNames = cn(styles.TopNav, className)
  const mediaQueryMobile = window?.matchMedia("(max-width: 750px)")
  const history = useHistory()
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const isMobileViewPort = useGetIsMobileScreen()

  const dispatch = useDispatch()

  const stopSyncMessage = "Navigation is not supported while a sync is in progress.  Stopping the sync will leave some orders unsynced."
  let confirmNavigationModal
  if (showConfirm) {
    confirmNavigationModal = (
    <ConfirmDialog
      title="Stop Syncing Process?"
      message={stopSyncMessage}
      cancelButtonText="Continue Sync"
      confirmButtonText="Stop Sync"
      onCancel={() => {
        setShowConfirm(false)
      }}
      onConfirm={() => {
        dispatch(clearOrderInProgress())
        history.push('/concession-order')
        setSafIsUploading(false)
      }}
    />
    )
  }

  /**
   * Always set filter status to default (don't display tabbed orders)
   * when the component is mounted and tabs are disabled
   * Any update to the default filter here also needs to be done in the initial state 
   * in /src/stores/initialStates.js
   */
  useEffect(()=> {
    if (tabsModeEnabled) {
      filterOrders({ state: "tabbed", showOrders: ORDERS_STATUS_OPTIONS.OPEN_ORDERS })
      return
    }

    filterOrders({ state: "tabbed", showOrders: ORDERS_STATUS_OPTIONS.CLOSED_ORDERS })
  }, [tabsModeEnabled, filterOrders])


  useEffect(() => {
    if(!isSearchOpen){
      onClearSearch()
    }
  }, [isSearchOpen])

  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ]
  }

  const [inputFocus, setInputFocus] = useFocus() // sets focus to <input> using ref if search icon is clicked

  const onSummaryClick = () => {
    history.push('orders/device-summary')
  }

  const onNewOrderClick = () => {
    if (!safIsUploading) {
      dispatch(clearOrderInProgress())
      history.push('/concession-order')
    } else {
      setShowConfirm(true)
    }
  }

  const onSearchFocus = () =>  {
    setSearchOpen(true)
  }
  const onSearchBlur = () => {
    if(currentSearch !== ''){
      setSearchOpen(true)
    } else {
      setSearchOpen(false)
    }
  }

  const onClearClick = () => {
    onClearSearch()
    setSearchOpen(false)
  }

  //TODO: get all this styling in a single place
  const customStyling = {
    customAutocompleteStyles: {
      width: isMobileViewPort ? "86.5vw" : "12vw",
      height: isMobileViewPort ? "11.111vw" : "3.8vw",
      paddingTop: 0,
      paddingRight: 0,
      borderRadius: 6,
      backgroundColor: "#292A2B",
    },
    customInputLabelStyles: {
      color: "white",
      fontSize: isMobileViewPort ? "4.444vw" : "1.042vw",
      paddingBottom: isMobileViewPort ? "1.111vw" : 0,
      marginTop: isMobileViewPort ? "-0.8vh" : 0,
    },
    customTextFieldStyles: {
      height: isMobileViewPort ? "11.111vw" : "3.78vw",
      fontSize: isMobileViewPort ? "4.444vw" : "1.042vw",
      color: "white",
      border: "1px solid #383B3D",
      borderRadius: 6,
      backgroundColor: "#383B3D",
      paddingLeft: 5,
      paddingRight: 15,
      textAlign: "center",
    },
    customPaperStyles: {
      maxHeight: "auto",
      backgroundColor: "#383B3D",
      fontSize: isMobileViewPort ? "4.444vw" : "1.042vw",
    },
  }
  
  const handleFilterOrders = (inputValue) => {
    setOrdersStatus(inputValue)

    if (inputValue === "My Open Tabs") {
      return filterOrders({ showOrders: ORDERS_STATUS_OPTIONS.OPEN_ORDERS })
    }

    if (inputValue === "My Closed Orders") {
      return filterOrders({ showOrders: ORDERS_STATUS_OPTIONS.CLOSED_ORDERS })
    }

    if (inputValue === "All Orders") {
      return filterOrders({ showOrders: ORDERS_STATUS_OPTIONS.ALL_ORDERS })
    }
  }
  
  return (
    <div className={classNames}>
      {!mediaQueryMobile.matches ?
        <div className={styles.row}>
          <div className={styles.col}>
            {!isKiosk && tabsModeEnabled &&
            <div className={styles.ordersFilter}>
              <DropdownSelector
                value={ordersStatus}
                autocompleteOptions={ordersStatusOptions}
                onAutocompleteChange={(inputValue) => {
                  handleFilterOrders(inputValue)
                }}
                {...customStyling}
              />
            </div>}
          </div>

          <div className={styles.col}>
            <div className={styles.summaryIconContainer} onClick={onSummaryClick}>
              {hasUnsyncedOrders ?
                (<YellowSummaryIcon className={styles.summaryIcon} />) :
                (<GreySummaryIcon className={styles.summaryIcon} />)
              }
            </div>
          </div>

          <div className={styles.col}>
            {!isKiosk && 
              <Button 
                className={cn([styles.newOrderBtn, !tabsModeEnabled && styles.newOrderBtnNoTabs])} 
                onClick={onNewOrderClick}
              >
              New Order
            </Button>}
          </div>
        </div>

      :
        <>
          <div className={styles.row}>
            {!isSearchOpen &&
              <div className={styles.leftSummary}>
                {hasUnsyncedOrders ?
                  (<YellowMobileSummaryIcon onClick={onSummaryClick} className={styles.mobileSummaryIcon} />) :
                  (<GreyMobileSummaryIcon onClick={onSummaryClick} className={styles.mobileSummaryIcon} />)
                }
              </div>
            }

            <div className={styles.fieldContainer}>
              <div className={isSearchOpen ? styles.searchIconContainer : styles.searchCloseContainer}>
                <InputSearchIcon className={styles.searchIcon} onClick={setInputFocus} />
              </div>
              <input ref={inputFocus}
                type="number"
                style={isSearchOpen ? {width: '86.667vw', border: "1px solid #2253FF" } : {width:'148px'}}
                value={currentSearch}
                isSearchOpen={isSearchOpen}
                onFocus={onSearchFocus}
                onBlur={onSearchBlur}
                onChange={(e) => onSearch(e.target.value)}
              />
              {currentSearch &&
                <div className={styles.clearIconContainer} onClick={onClearClick}>
                  <div className={styles.clearButton}><CloseIcon /></div>
                </div>
              }
            </div>
          </div>
          <div className={cn([styles.mobileOrdersFilter, !tabsModeEnabled && styles.mobileOrdersFilterNoTabs ])}>
            {!isKiosk && tabsModeEnabled && (
              <DropdownSelector
                value={ordersStatus}
                autocompleteOptions={ordersStatusOptions}
                onAutocompleteChange={(inputValue) => {
                  handleFilterOrders(inputValue)
                }}
                {...customStyling}
              />
            )}
          </div>
        </>
        }

      {confirmNavigationModal}
    </div>
  )
}

TopNav.defaultTypes = {
  currentSearch: '',
}

TopNav.propTypes = {
  currentSearch: PropTypes.string,
  onClearSearch: PropTypes.func,
  onSearch: PropTypes.func,
  filterOrder: PropTypes.func,
}

export default memo(TopNav)
