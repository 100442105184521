import { head, get } from 'lodash'

const getMaxReached = (item) => {
  const cartQuantity = get(item, 'cartQuantity', 0)
  const orderMaxAmount = get(item, 'orderMaxAmount', 999)

  return cartQuantity === orderMaxAmount
}

const ItemViewModel = (item) => {
  return {
    id: item.id || new Date().getTime(),
    name: item.name || '',
    price: `$${(item.defaultPriceInCents / 100).toFixed(2)}` || '--',
    status: (head(item.variants) || {}).inventoryState || 'available',
    inventory: item.inventory || 100,
    cartQuantity: item.cartQuantity || 0,
    color: item.color,
    modifierGroups: item.modifierGroups || [],
    isModifier: item.isModifier,
    maxReached: getMaxReached(item),
    categoryId: item.itemCategoryUuid || 'n/a',
  }
}

export default ItemViewModel
