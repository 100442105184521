import { centsToDollars } from '../../../utils/formatters'

export const tenderSelection = (label, text, amountInCents, disabled) => {
  return { label, text, amountInCents, disabled }
}

const tenderSelectionByPercentage = (totalAmountInCents, balanceDueInCents, percentage) => {
  const amountInCents = balanceDueInCents * percentage
  const label = `${percentage * 100}%`
  const text = centsToDollars(amountInCents)
  const disabled = amountInCents === 0 || amountInCents > balanceDueInCents

  return tenderSelection(label, text, amountInCents, disabled)
}

const tenderSelectionEvenly = (totalAmountInCents, balanceDueInCents, percentage) => {

  const percentageAmount = percentage === 'balance' ? balanceDueInCents : totalAmountInCents / percentage
  const unEvenSplit = !!((balanceDueInCents || totalAmountInCents) % Math.floor(percentageAmount))
  const amountInCents = unEvenSplit ? Math.ceil(percentageAmount) : Math.floor(percentageAmount)
  const label = percentage === 'balance' ? "Balance" :`/ ${percentage}`
  const text = centsToDollars(amountInCents)
  const disabled = amountInCents > balanceDueInCents

  return tenderSelection(label, text, amountInCents, disabled)
}

const CALCULATION_TYPE_MAP = {
  percent: [0.2, 0.25, 0.33, 0.5, 0.67, 0.75, 1],
  evenly: ['balance', 2, 3, 4, 5, 6, 7, 8, 9]
}

const TENDER_FACTORY_MAP = {
  percent: tenderSelectionByPercentage,
  evenly: tenderSelectionEvenly
}

/**
 *
 * @param {'percent'|'evenly'} type the type of selections you want to generate
 * @param {*} totalAmountInCents the total amount in cents
 * @param {*} balanceDueInCents the balance due in cents
 */
const makeTenderSelections = (type, totalAmountInCents, balanceDueInCents) => {
  const targetSelections = CALCULATION_TYPE_MAP[type]
  const targetTenderFactory = TENDER_FACTORY_MAP[type]

  const selections = targetSelections.map((percentage, index) => {
    return targetTenderFactory(totalAmountInCents, balanceDueInCents, percentage, index)
  })

  return selections
}

export default makeTenderSelections
