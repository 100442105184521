import { cloneDeep, isEmpty, partition, pick, unset, values } from 'lodash'

import { PRINTING_ENABLED } from '../constants'
import { DEFAULT_PRINTERS } from '../utils/printing'

export const getPrinters = (state) => {
    const printers = cloneDeep(state?.printer?.byId)
    let localPrinter = pick(printers, 'local')?.local ?? {}
    let result = []

    if (!isEmpty(localPrinter) && localPrinter.state === 'connected') {
        result.push(localPrinter)
    }

    unset(printers, 'local')

    const [ receiptPrinters, nonReceiptPrinters ] = partition(printers, 'receiptPrinter')
    result = [ ...result, ...receiptPrinters, ...nonReceiptPrinters ]

    return result
}

export const getPrinter = (state, printerId) => {
    return state.printer.byId?.[printerId]
}

export const calculatePrintingEnabled = (printerId) => {
  return PRINTING_ENABLED || (!!printerId && printerId !== 'none')
}

export const getUsbPrintingEnabled = (state) => {
  return state.printer.networkPrinterUuid === 'usb'
}

export const getPrintingEnabled = (state) => {
  return calculatePrintingEnabled(state.printer.networkPrinterUuid)
}

export const getNetworkPrinter = (state) => {
  return state.printer.networkPrinterUuid
}

export const getCloudPrintingEnabled = (state) => {
  return !values(DEFAULT_PRINTERS).map((printer) => printer.uuid).includes(state.printer.networkPrinterUuid)
}
