import { useEffect, useRef, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"

import { logout } from '../actions/attendant'

import { shouldInitiateIdleSync } from '../selectors/order'

import networkConnected from '../utils/networkConnected'
import PeripheralBridge from '../utils/peripheralBridge'
import { ToastManager } from '../utils/toastManager'

import { startSyncCycle } from './useSafMode'
import { getDeviceMode } from '../VNMode/Selectors'

const THIRTY_MINS = 1_800_000

const useIdleReplay = () => {
  const [shouldReplay, setShouldReplay] = useState(false)

  const intervalRef = useRef()

  const cfdMode = useSelector(state => getDeviceMode(state))
  const shouldInitiateSync = useSelector(state => shouldInitiateIdleSync(state))

  const history = useHistory()
  const dispatch = useDispatch()

  async function executeReplay() {
    try { await networkConnected() } catch { return }

    if (!shouldInitiateSync) {
      ToastManager.info('No offline orders to auto-sync.', { autoClose: 2000 })

      // Automatically logout after 30 minutes of inactivity if there are no offline orders.
      dispatch(logout())

      return
    }

    // replay orders with offline credit card approvals
    const bridgeInstance = PeripheralBridge.getBridge()

    const bridgeUnavailableCallback = () => {
      ToastManager.error('Unable to connect to peripheral. Please try again later.')
    }

    history.push('/orders/device-summary?from=idleReplay')

    // response is boolean "OK" or "ERROR"
    startSyncCycle(true, bridgeUnavailableCallback, bridgeInstance, cfdMode)
  }

  // After 30 minutes of inactivity
  const handleOnIdle = () => {
    setShouldReplay(true)
  }

  // When the user begins interacting again after 30 minutes has passed
  const handleOnActive = () => {
    executeReplay()
    setShouldReplay(false)
  }

  useEffect(() => {
    clearInterval(intervalRef.current)
    intervalRef.current = null

    if (shouldReplay) {
      intervalRef.current = setInterval(executeReplay, THIRTY_MINS)
    }
  }, [shouldReplay])

  useIdleTimer({
    timeout: THIRTY_MINS,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })
}

export default useIdleReplay
