import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import './styles/application.sass'

import ImageVersioning from './ImageVersioning'
import Routes from './Routes'
import { toggleAccessibilityRequired } from '../actions/appState'
import { KEY_CODES } from './utils/constants'
import { BRANDING_COLOR } from '../constants'

const KioskApp = ({ setAccessibilityRequired }) => {
  const keyDown = (event) => {
    if (event.keyCode === KEY_CODES.JACK_IN || event.keyCode === KEY_CODES.JACK_IN_TEST)
      setAccessibilityRequired(true)
    if (event.keyCode === KEY_CODES.JACK_OUT || event.keyCode === KEY_CODES.JACK_OUT_TEST)
      setAccessibilityRequired(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDown, false)
    return () => {
      document.removeEventListener('keydown', keyDown, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let htmlRoot = document.getElementsByTagName('html')[0]
    htmlRoot.style.setProperty('--brand-primary', BRANDING_COLOR)
  })

  return (
    <div className='main'>
      <ImageVersioning>
        <React.Fragment>
          <Routes />
        </React.Fragment>
      </ImageVersioning>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAccessibilityRequired: (accessibilityRequired) =>
      dispatch(toggleAccessibilityRequired(accessibilityRequired))
  }
}

export default connect(null, mapDispatchToProps)(KioskApp)
