import { createSelector } from 'reselect'
import { toPairs } from 'lodash'

export const PAX_PAYMENT_TYPE = 'pax'

export const getPeripheralConnected = (state) => state?.peripheral?.peripheralConnected ?? true

export const getPeripheralIsBusy = (state) => state?.peripheral?.peripheralIsBusy ?? false

export const getSafUploadComplete = (state) => state?.peripheral?.safUploadComplete ?? false
export const getSafIsUploading = (state) => state?.peripheral?.safIsUploading ?? false
export const getDataUploadComplete = (state) => state?.peripheral?.dataUploadComplete ?? false
export const getDataIsUploading = (state) => state?.peripheral?.dataIsUploading ?? false

export const getNumericSafValue = (value) => {
  if (value === null || value === undefined) return -1
  if (typeof value === 'number') return value

  if (value === '') return -1
  if (typeof value === 'string') return Number(value)

  return -1
}

const getInfo = (state, options = { target: '', json: true }) => {
  const targetInfoReceived = state?.peripheral?.[`${options?.target}Received`] ?? false
  const targetInfo = state?.peripheral?.[options?.target] ?? {}

  if (options?.json) return targetInfo

  let infoString = toPairs(targetInfo).map((pair) => pair.join(': ')).join(', ')

  if (targetInfoReceived && infoString !== '') {
    return infoString
  } else if (targetInfoReceived === false) {
    return 'Not Received'
  } else if (infoString === '') {
    return 'Not Found'
  }
}

export const getFirmwareInfo = (state, options = { json: true }) => {
  return getInfo(state, { target: 'firmwareInfo', ...options })
}

export const getBuildInfo = (state, options = { json: true }) => {
  return getInfo(state, { target: 'buildInfo', ...options })
}

export const getTerminalInfo = (state, options = { json: true }) => {
  return getInfo(state, { target: 'terminalInfo', ...options })
}

export const getPaymentType = (state) => {
  const buildInfo = getBuildInfo(state)

  // For web testing reasons, default to the pax payment type.
  return buildInfo?.paymentType || PAX_PAYMENT_TYPE
}

// We always use 'executeSaleRequest' for globalpay.
export const makeGetInitialBridgeMethod = () => createSelector(
  () => 'executeSaleRequest'
)

// Returns : The number of deleted cards from the batch on the card reader during the last sync.
//     -1 if the number of deleted cards is not available.
export const getCardReaderDeletedCardCount = (state) => {
  return getNumericSafValue(getSafFullResponse(state)["SAFDeletedCount"])
}

// Returns : The number of failed cards from the batch on the card reader during the last sync.
//     -1 if the number of failed cards is not available.
export const getCardReaderFailedCardCount = (state) => {
  return getNumericSafValue(getSafFullResponse(state)["SAFFailedCount"])
}

// Returns : The total number of cards from the batch on the card reader during the last sync.
//     -1 if the total number of cards is not available.
export const getCardReaderTotalCardCount = (state) => {
  return getNumericSafValue(getSafFullResponse(state)["SAFTotalCount"])
}

// Returns : The number of uploaded cards from the batch on the card reader during the last sync.
//     -1 if the number of uploaded cards is not available.
export const getCardReaderUploadedCardCount = (state) => {
  return getNumericSafValue(getSafFullResponse(state)["SAFUploadedCount"])
}

export const getUnsyncedCardCount = (state) => {
  const totalCardCount = getCardReaderTotalCardCount(state)

  if (totalCardCount === -1) {
    return undefined
  }

  const uploadedCardCount = getCardReaderUploadedCardCount(state)

  if (uploadedCardCount === -1) {
    return undefined
  }

  const failedCardCount = getCardReaderFailedCardCount(state)

  const actualRemainingCardCount = uploadedCardCount > 0 ? totalCardCount - uploadedCardCount : totalCardCount

  return actualRemainingCardCount <= failedCardCount ? 0 : actualRemainingCardCount
}

export const getSafFullResponse = (state) => {
  return state?.peripheral?.safFullResponse ?? {}
}

export const getBuildInfoReceived = (state) => {
  return state?.peripheral?.buildInfoReceived ?? false
}

export const getBridgePaymentMethod = (state) => state?.peripheral?.bridgeMethod ?? 'executeSaleRequest'