import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import IconBack from '../icons/IconBack'

const BackButton = props => {
  const { className, children, withIcon, refProp, ariaLabel, handleKeyDown, iconStyle, ...rest } = props

  return (
    <button onKeyDown={handleKeyDown} ref={refProp} aria-label={ariaLabel} tabIndex={0} type="button" className={cn(['btn', '-round', className], {
      '-with-icon': withIcon
    })} {...rest}>
      {withIcon && <IconBack className={iconStyle ? iconStyle : "btn__icon"} />}
          <span className="btn__content">
              {children}
          </span>
    </button>
  )
}

BackButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  withIcon: PropTypes.bool,
}

export default BackButton
