import { call, put, takeLatest } from 'redux-saga/effects'
import Portico, {
  deviceLogin as _deviceLogin,
  deviceLoginSucceeded,
  deviceLoginFailed,
} from '@ordernext/networking-stadium/portico'

export function* deviceLogin(params = {}) {
  try {
    const deviceId = localStorage.getItem('device_id')
    const appKey = process.env.REACT_APP_OIDC_CLIENT_ID
    const organizationName = process.env.REACT_APP_ORG

    const result = yield call(Portico.deviceLogin, deviceId, appKey, organizationName)

    yield put(deviceLoginSucceeded(result))
  } catch (err) {
    yield put(deviceLoginFailed(err))
  }
}

export function* watchDeviceLogin() {
  yield takeLatest(_deviceLogin.type, deviceLogin)
}
