import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SegmentedControl.module.scss'

const SegmentedControlV2 = ({ items, selectedValue, onSelect, className, disabled }) => {
  return (
    <div
      className={classNames(className, styles.SegmentedControl, { [styles.disabled]: disabled })}
    >
      {items?.map((item, index) => {
        return (
          <div
            key={item.label + index}
            className={classNames(styles.SegmentedControl__item, {
              [styles.isSelected]: selectedValue === item.value
            })}
            onClick={() => onSelect(item.value)}
          >
            {item.label}
          </div>
        )
      })}
    </div>
  )
}

export default SegmentedControlV2

SegmentedControlV2.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func
}
