import { apiMakeChainServicesPpiProxyCalls, apiMakePosOrdersTotal, apiPostPpiProxy } from './Api'
import { VNPPI_REPORT, VNPPI_SERVICES } from './Enums'
import { toast } from 'react-toastify'
import { swapCaseFirstLetter } from '../VNUtil/Strings'
import { calculateDistributionOfPayment, vn_getEntries, isEmpty } from '../VNUtil/VNUtils'
import { centsToDollars, centsToFloat, stringToCents } from '../utils/formatters'
import { formatItems } from '../hooks/useRemoteOrderTotal'
import moment from 'moment'
import { isTabbed } from '../utils/orderStates'

import { ToastManager } from '../utils/toastManager'

/**
 * Calculate how much you would have left from the tender and the remaining balance
 * @param {int} amountOnTender - How much is on the tender you want to subtract
 * @param {int} balanceDueInCents - How much is the total balance due
 * @param {boolean} autoReplenish - True if the integration allows for a high payment amount than what is on the balance. Expects the user
 * to have a card on file.
 * @returns {Object} - { tenderAmountInCents, remainingTicketBalance }
 */
const determineIfPaymentAppliesWithBalance = (amountOnTender, balanceDueInCents, autoReplenish) => {
  let tenderAmountInCents
  let remainingTicketBalance
  let remainingBalance = 0

  if (amountOnTender >= balanceDueInCents) {
    tenderAmountInCents = balanceDueInCents
    remainingTicketBalance = amountOnTender - balanceDueInCents
  } else {

    if (autoReplenish) {
      tenderAmountInCents = balanceDueInCents
    } else {
      tenderAmountInCents = amountOnTender
      remainingBalance = balanceDueInCents - amountOnTender
    }

    remainingTicketBalance = 0
  }

  return {
    tenderAmountInCents,
    remainingTicketBalance,
    remainingBalance
  }
}

/**
 * Need to inject over the decamelized keys with any special variables that we can't have that happen to.
 * Performs this swap in pass by reference.
 * @param {Object} original - The original data that has NOT been decamelized
 * @param {Object} reference - The data that has been decamelized
 */
export const injectNonDecamelizedPaymentsKeysForPpi = (original, reference) => {
  if (reference?.payments && original) {
    for (let i = 0; i < reference.payments.length; i++) {
      let payment = reference.payments[i]

      // only over write these attributes IFF there exists ppi_extras that are decamalized in the first place
      // otherwise, this will cause API calls to not actually go out.
      if (payment.user_attributes?.ppi_extras && original.payments[i].userAttributes?.ppiExtras) {
        payment.user_attributes.ppi_extras = original.payments[i].userAttributes.ppiExtras
      }

    }
  }
}

/**
 * Given tenderType, get all details of the tender as configured in VNConfigs
 * @param {ImmutableMap} ppiConfigurations - Returned by VNAPI PPI /configurations and injected into Redux immutable state.
 * @param {String} tenderType - The specific tender you want to retrieve configs for. Available: QRPAY_TENDER, TICKET_TENDER, GIFTCARD_TENDER
 * @returns {Object} - Returns the configs if found, null otherwise. Example: { integration: FANMAKER, services: ['getAmenities'] }
 */
export const getTender = (ppiConfigurations, tenderType) => {
  if (!isEmpty(ppiConfigurations)) {
    const tenders = ppiConfigurations.get('tenders')
    if (tenders && tenders[tenderType]) {
      return tenders[tenderType]
    }
  }

  return null
}

/**
 * Given a paymentType, get all details of the tender as configured in VNConfigs
 * @param {ImmutableMap} ppiConfigurations - Returned by VNAPI PPI /configurations and injected into Redux immutable state.
 * @param {String} paymentType - Available: vnapi_ticket, vnapi_giftcard, vnapi_qrpay
 * @returns {Object} - Returns the configs if found, null otherwise. Example: { integration: FANMAKER, services: ['getAmenities'] }
 */
export const getTenderByPaymentType = (ppiConfigurations, paymentType) => {

  if (!isEmpty(ppiConfigurations)) {

    const tenders = ppiConfigurations.get('tenders')
    const strippedPaymentType = paymentType.split('_')
    const tenderType = `${strippedPaymentType[1].toUpperCase()}_TENDER`

    if (tenders && tenders[tenderType]) {
      return tenders[tenderType]
    }
  }
  return null
}

/**
 * Checks if the payment type is a valid PPI type.
 * @param {String} paymentType - Available: vnapi_ticket, vnapi_giftcard, vnapi_qrpay
 * @returns {Boolean} Returns true if it is, false otherwise.
 */
export const paymentTypeIsPpi = (paymentType) => {
  switch(paymentType) {
    case 'vnapi_ticket':
    case 'vnapi_qrpay':
    case 'vnapi_giftcard':
      return true
  }
  return false
}

/**
 * Helper function to extrapolate error message and toast...ing.
 * @param {Object} response - The body response from the API call
 * @param {Function} errorFunc - The setError function to call - This calls a true/false error function
 * @param {Function} errorMessageFunc - The setError function to call - This expects a string of the error
 * @return {Boolean} Returns true if an error was handled, false otherwise
 */
const handleError = (response, errorFunc, errorMessageFunc) => {

  if (response instanceof Error) {

    if (errorFunc) {

      errorFunc(true)
      ToastManager.error(response.message)

    } else if (errorMessageFunc) {
      errorMessageFunc(response.message)
    }

    return true
  }
  return false
}

/**
 * Handle an array of responses from VNAPI PPI to determine what we should do within POS
 * @param {Object} ppiTender - From getTender above, all the details of a tender as configured in VNConfigs - Example: { integration: FANMAKER, services: ['getAmenities'] }
 * @param {Array} responses - An array of responses from each API call to VNAPI proxy in order, this is the body response.
 * @param {Object} posInfo - The POS Information about this current POS - Format: { userId: '', vendorName: '', venueId: '' }
 * @param {Object} externalDataAndFunctionality - Functionality and data that is external to this Utils, required format:
 * { addPaymentToOrderInProgress: <FUNCTION>, balanceDueInCents: 3202, updateOrderInProgress: <FUNCTION>, token: '<TOKEN>', paymentType: 'vnapi_ticket', tenderDislayName: 'Ticket' }
 */
export const handleServiceResponses = async (ppiTender, responses, posInfo, externalDataAndFunctionality, order, ppiConfigurations) => {

  // do we need to redirect after we are complete?
  let redirectEnabled = false

  // the total balance that is remaining after all the calls, used for redirects
  let remainingBalance = 0

  // the full response from apiFunc: getContextualDiscounts
  let contextualDiscountTransactionRequired = null

  // we only want to record the scanned tender, if it didn't have an error and only once regardless of services
  // once per tender scanned
  let recordedScannedTender = false

  for (let i = 0; i < ppiTender.services.length; i++) {

    if (handleError(responses[i], externalDataAndFunctionality.functions.setError, externalDataAndFunctionality.functions.setErrorMessage)) {
      continue
    }

    // need to save off the last tender token that was scanned
    if (!recordedScannedTender) {  
      recordedScannedTender = true
      externalDataAndFunctionality.functions.setScannedTenderForOrder(order.uuid, externalDataAndFunctionality.token)
    }

    // We want these separated out so that you can combine services together.
    // Some may have the same execution code, like GET_BALANCE & GET_CONTEXTUAL_BALANCE and they can't be combo'ed
    // since that wouldn't make any sense

    if (ppiTender.services[i] === VNPPI_SERVICES.GET_CONTEXTUAL_DISCOUNTS) {

      // Expectation
      // {
      //   "discounts": [{
      //     "sku": "1234554321",
      //     "promotion": "BRAVES_1025",
      //     "amount": 3938
      //      "originalPricePerItem": 1000,
      //      "quantity": 3
      //   }, {
      //      "sku": "1122334455",
      //      "promotion": "BRAVES_1025",
      //       "amount": 500,
      //      "originalPricePerItem": 1000,
      //      "quantity": 1
      //   }],
          // "transactionRequired": true // this may not exist, but for implementations where it does, this means that
          // you must tag this for a payment even if there is no balance! It has to go through the normal payment flow.
      // }

      let updatedOrder = {}
      Object.assign(updatedOrder, order)

      // if we have no discounts, no reason to process anything
      if (responses[i].discounts.length <= 0) {
        continue
      }

      let discountCart = []
      let totalCartPriceAfterDiscountsApplied = 0
      let totalDiscount = 0

      responses[i].discounts.forEach(discount => {

        // save off and calculate the total discount being applied
        totalCartPriceAfterDiscountsApplied += (discount.originalPricePerItem * discount.quantity - discount.amount)
        totalDiscount += discount.amount
        discountCart.push({
          sku: discount.sku,
          itemCost: centsToFloat(discount.originalPricePerItem),
          quantity: discount.quantity
        })

        let distributionArray = []

        // if quantity is greater than 1, we need to distribute
        if (discount.quantity > 1) {
          distributionArray = calculateDistributionOfPayment(discount.amount, order.amountInCents, discount.quantity)
        }

        for (let j = 0; j < updatedOrder.itemModels.length; j++) {

          const itemModel = updatedOrder.itemModels[j]

          if (itemModel.variant?.productSku === discount.sku) {

            if (distributionArray.length > 0) {
              itemModel.priceInCents -= distributionArray.pop()
            } else {
              itemModel.priceInCents -= discount.amount
            }

            itemModel.price = centsToDollars(itemModel.priceInCents)

          }
        }
      })

      if (responses[i].transactionRequired) {
        contextualDiscountTransactionRequired = {
          totalCartPriceAfterDiscountsApplied: totalCartPriceAfterDiscountsApplied
        }

        // This is how we show the discount line item; keeping this commented out since this may come back later
        // updatedOrder.discountAmountInCents = totalDiscount
      }

      // need to generate the order menu items

      // need to make a new orders total call becuase the price of the items have changed, thus the service charge could be different
      let stadiumResponse

      try {
        stadiumResponse = await apiMakePosOrdersTotal(null,
                                                      formatItems(updatedOrder.itemModels, false),
                                                      externalDataAndFunctionality.promotions.menu?.uuid,
                                                      externalDataAndFunctionality.promotions.isKiosk,
                                                      order.uuid,
                                                      isTabbed(order))
      } catch (e) {
        // silenting failing because it is possible that the integration has codes that are NOT valid for Moneyball
        // which cause Stadium to return a 422. In this scenario, we don't care if it fails, so we ignore
        // and fall through to other ppi responses.
        continue
      }

      let serviceCharge = 0

      // need to loop through the line_items and determine service charge
      stadiumResponse.data?.line_items.forEach(lineItem => {

        if (lineItem.special_type === "service_charge") {
          serviceCharge = lineItem.total_amount_in_cents
        }
      })

      // update the service charge on the order.
      if (serviceCharge >= 0) {
        updatedOrder.serviceFee = {
          ...updatedOrder.serviceFee,
          total: serviceCharge
        }
      }

      updatedOrder.taxAmountInCents = stadiumResponse.data.tax_amount_in_cents

      updatedOrder.balanceDueInCents =  stadiumResponse.data.total_amount_in_cents
                                      + order.tipAmountInCents

      updatedOrder.amountInCents =  stadiumResponse.data.total_amount_in_cents
                                  + order.tipAmountInCents

      updatedOrder.subtotalAmountInCents = stadiumResponse.data.total_amount_in_cents - stadiumResponse.data.service_charge_in_cents

      // since we re-calculated the total with the discounts, we also need to loop through the payments
      // to determine if we have paid anything so far and subtract that from the total.
      order.payments.forEach(payment => {
        updatedOrder.balanceDueInCents -= payment.amountInCents
      })

      // need to update this local param state so we can use it on subsequent checks.
      // The next set of checks can't use redux state.
      externalDataAndFunctionality.balanceDueInCents = updatedOrder.balanceDueInCents

      remainingBalance = updatedOrder.balanceDueInCents

      ToastManager.success('Discount successfully applied.')

      externalDataAndFunctionality.functions.updateOrderInProgress(updatedOrder)

      if (!isEmpty(contextualDiscountTransactionRequired)) {

        let amountInCentsOnPayment = 0
        let remainingTicketBalance = responses[i].balance
        if (responses[i].balance && responses[i].balance > 0) {
          let dif = responses[i].balance - contextualDiscountTransactionRequired.totalCartPriceAfterDiscountsApplied
          if (dif > 0) {
            amountInCentsOnPayment = contextualDiscountTransactionRequired.totalCartPriceAfterDiscountsApplied
            remainingTicketBalance = dif
          } else {
            amountInCentsOnPayment = responses[i].balance
            remainingTicketBalance = 0
          }
        }

        let paymentObj = {
          paymentType: externalDataAndFunctionality.paymentType,
          shortDescription: externalDataAndFunctionality.shortDescription,
          token: externalDataAndFunctionality.token,
          userAttributes: {
            posInfo: posInfo
          },
          push: externalDataAndFunctionality.functions.push,
          tenderAmountInCents: amountInCentsOnPayment,
          remainingTicketBalance: remainingTicketBalance,
          userAttributes: {
            ppiExtras: {}
          }
        }


        paymentObj.userAttributes.ppiExtras[ppiTender.integration] = {
          isDiscount: true,
          discountCart: discountCart
        }

        // this requires a "payment" transaction to hit Stadium so it hits VNAPI
        externalDataAndFunctionality.functions.addPaymentToOrderInProgress(paymentObj)

      }

      if (remainingBalance > 0 && externalDataAndFunctionality.functions.redirect) {
        redirectEnabled = true
      }

    }

    if (ppiTender.services[i] === VNPPI_SERVICES.GET_DISCOUNTS) {

      // Expectation of Payload
      //   {
      //     "discounts": {
      //         "c3531579-cf87-4db6-a097-083afa50b815": {
      //             "id": "c3531579-cf87-4db6-a097-083afa50b815",
      //             "quantity": 48,
      //             "description": "Free Sticker",
      //             "ppiExtras": {
      //                 "FORTRESS": {
      //                     "categoryCode": "Free Item",
      //                     "discountType": 2,
      //                     "productCode": "c3531579-cf87-4db6-a097-083afa50b815"
      //                 }
      //             }
      //         }
      //     }
      // }

      // if there are no discounts, we can just move on to the next response.
      if (isEmpty(responses[i].discounts)) {
        continue
      }

      let updatedOrder = {}
      Object.assign(updatedOrder, order)

      // [] of existing promotions
      let currentPromotions = [...externalDataAndFunctionality.promotions?.selectedPromotions]

      // trackUseablePromotions = {
      //   <PROMOTION_UUID> = [
      //     {
      //       token: '',
      //       ppiExtras: {
      //         "FORTRESS": {
      //           "categoryCode": "Free Item",
      //           "discountType": 2,
      //           "productCode": "ec8ca640-977d-4302-8da9-3b36debf76ad",
      //           "voucherCode": "NCV-VN-02-2024"
      //         }
      //       }
      //     }
      //   ]
      // }
      let trackUseablePromotions = {}

      const paymentTender = getTenderByPaymentType(ppiConfigurations, externalDataAndFunctionality.paymentType)

      for (const [discountId, discount] of vn_getEntries(responses[i].discounts)) {

        if (!trackUseablePromotions[discountId]) {
          trackUseablePromotions[discountId] = []
        }

        let newTrackablePromo = {
          token: externalDataAndFunctionality.token,
          paymentType: externalDataAndFunctionality.paymentType,
          shortDescription: externalDataAndFunctionality.shortDescription,
          description: discount.description,
          ppiExtras: {}
        }

        newTrackablePromo.ppiExtras[paymentTender.integration] = {
          discounts: {}
        }

        newTrackablePromo.ppiExtras[paymentTender.integration].discounts[discountId] = {
          ...discount.ppiExtras[paymentTender.integration],
          quantity: 1
        }

        trackUseablePromotions[discountId].push(newTrackablePromo)

        // quantity will always be 1 at this time (from product)

        currentPromotions.push({
          name: discount.description,
          uuid: discountId
        })

      }

      let stadiumResponse

      try {
        stadiumResponse = await apiMakePosOrdersTotal(currentPromotions,
                                                      formatItems(externalDataAndFunctionality.promotions.orderMenuItems),
                                                      externalDataAndFunctionality.promotions.menu?.uuid,
                                                      externalDataAndFunctionality.promotions.isKiosk,
                                                      order.uuid,
                                                      isTabbed(order))
      } catch (e) {
        // silenting failing because it is possible that the integration has codes that are NOT valid for Moneyball
        // which cause Stadium to return a 422. In this scenario, we don't care if it fails, so we ignore
        // and fall through to other ppi responses.
        continue
      }

      // does there exist a previous payments for a discount?
      // let previousDiscountPayments = []

      // does there exist a previous payments for a balance?
      let previousBalancePayments = []

      let index = updatedOrder.payments.length
      while (index--) {

        const payment = updatedOrder.payments[index]

        // we need to find and strip ALL VNAPI Payments, because we need to re-apply all payments in order of scanned

        if (paymentTypeIsPpi(payment.paymentType)) {

          if (payment.amountInCents === 0) {

            const oldPaymentTender = getTenderByPaymentType(ppiConfigurations, payment.paymentType)

            for (const [discountId, _] of vn_getEntries(payment.userAttributes.ppiExtras[oldPaymentTender.integration].discounts)) {
              if (!trackUseablePromotions[discountId]) {
                trackUseablePromotions[discountId] = []
              }

              trackUseablePromotions[discountId].push({
                token: payment.token,
                paymentType: payment.paymentType,
                shortDescription: payment.shortDescription,
                ppiExtras: {
                  ...payment.userAttributes.ppiExtras
                }
              })
            }

            // need to remove this payment from the array because we need to rebuild them
            updatedOrder.payments.splice(index, 1)
          } else if (payment.amountInCents > 0) {

            previousBalancePayments.push({
              ...payment
            })

            // need to remove this payment from the array because we need to rebuild them
            updatedOrder.payments.splice(index, 1)

          }
        }

      }

      // need to make the promotions used array - since redux expects an array
      const promotionsUsedArray = []

      const promotionsUsedFromTotaling = []

      let serviceCharge = 0

      // need to loop through the line_items and determine which promotions we need to use
      stadiumResponse.data?.line_items.forEach(lineItem => {
        if (lineItem.special_type === "service_charge") {
          serviceCharge = lineItem.total_amount_in_cents
        }
      })

      if (!isEmpty(trackUseablePromotions)) {
        stadiumResponse.data?.promotions.forEach(promo => {
          if (promo.applies && trackUseablePromotions[promo.uuid]) {

            const poppedTicket = trackUseablePromotions[promo.uuid].pop()

            if (poppedTicket) {
              let newPayment = {
                amountInCents: 0,
                paymentType: poppedTicket.paymentType,
                shortDescription: poppedTicket.shortDescription,
                token: poppedTicket.token,
                userAttributes: {
                  posInfo: posInfo,
                  ppiExtras: poppedTicket.ppiExtras
                }
              }

              // add the amount that was discounted to the discount
              // this will be a nagative value in cents
              newPayment.userAttributes.ppiExtras[paymentTender.integration].discounts[promo.uuid].discountAmountInCents = promo.discount_amount_in_cents

              promotionsUsedFromTotaling.push(newPayment)

              promotionsUsedArray.push({
                name: poppedTicket.description,
                uuid: promo.uuid
              })
            }
          }
        })
      }


      // update the service charge on the order.
      if (serviceCharge >= 0) {
        updatedOrder.serviceFee = {
          ...updatedOrder.serviceFee,
          total: serviceCharge
        }
      }

      // it is possible that moneyball attempted to process all the promotions, but none of them applied successfully.
      // therefore, we don't want to handle a payment and ignore and contiue to the next ppi response
      if (promotionsUsedFromTotaling.length === 0) {
        continue
      }

      // All old payments that need to be re-added
      const oldTicketNewPayments = []

      // need to add this so the saga can pick it up later during submission
      if (externalDataAndFunctionality.functions.addPromotionsToOrder) {
        externalDataAndFunctionality.functions.addPromotionsToOrder({ selectedPromotions: promotionsUsedArray})
      }

      if (!isEmpty(promotionsUsedFromTotaling)) {
        updatedOrder.payments.push(...promotionsUsedFromTotaling)
      }

      updatedOrder.taxAmountInCents = stadiumResponse.data.tax_amount_in_cents

      // discount is a negative number
      updatedOrder.balanceDueInCents =  order.subtotalAmountInCents
                                      + updatedOrder.taxAmountInCents
                                      + order.tipAmountInCents
                                      + serviceCharge
                                      + stadiumResponse.data.discount_amount_in_cents
      updatedOrder.amountInCents =  order.subtotalAmountInCents
                                  + updatedOrder.taxAmountInCents
                                  + order.tipAmountInCents
                                  + serviceCharge
                                  + stadiumResponse.data.discount_amount_in_cents

      if (!isEmpty(previousBalancePayments) && previousBalancePayments.length > 0) {

        for (let i = 0; i <= previousBalancePayments.length; i++) {

          const previousPayment = previousBalancePayments[i]

          const paymentResults = determineIfPaymentAppliesWithBalance(previousPayment.amountInCents, updatedOrder.balanceDueInCents)

          if (paymentResults.tenderAmountInCents > 0) {

            const oldTicketNewPayment = {
              amountInCents: paymentResults.tenderAmountInCents,
              paymentType: previousPayment.paymentType,
              shortDescription: previousPayment.shortDescription,
              token: previousPayment.token,
              remainingTicketBalance: paymentResults.remainingTicketBalance,
              userAttributes: {
                posInfo: posInfo
              },
              push: externalDataAndFunctionality.functions.push
            }

            oldTicketNewPayments.push(oldTicketNewPayment)

            // if we don't need any more money to cover the balance, don't loop through any more old payments
            if (updatedOrder.balanceDueInCents - oldTicketNewPayment.amountInCents <= 0) {
              break
            }

          }
        }
      }

      oldTicketNewPayments.forEach(payment => {
        updatedOrder.payments.push(payment)
      })

      let totalAlreadyPaid = 0
      updatedOrder.payments.forEach(payment => {
        totalAlreadyPaid += payment.amountInCents
      })

      // discount is a negative number
      updatedOrder.balanceDueInCents =  order.subtotalAmountInCents
                                      + updatedOrder.taxAmountInCents
                                      + order.tipAmountInCents
                                      - totalAlreadyPaid
                                      + serviceCharge
                                      + stadiumResponse.data.discount_amount_in_cents

      updatedOrder.discountAmountInCents = stadiumResponse.data.discount_amount_in_cents

      updatedOrder.orderUuid = stadiumResponse.data.uuid

      // need to update this local param state so we can use it on subsequent checks.
      // The next set of checks can't use redux state.
      externalDataAndFunctionality.balanceDueInCents = updatedOrder.balanceDueInCents

      remainingBalance = externalDataAndFunctionality.balanceDueInCents

      ToastManager.success('Discount successfully applied.')

      externalDataAndFunctionality.functions.updateOrderInProgress(updatedOrder)

      if (remainingBalance > 0 && externalDataAndFunctionality.functions.redirect) {
        redirectEnabled = true
      }

      // if the discount covered all the balance, get out of this and need to have the
      // screen place the free order becuase it requires the order state to update
      // prior to making the call. This is why you can't place the free order inside
      // this utility function.
      if (remainingBalance <= 0) {
        const paymentData = {
          amountInCents: 0,
          discountAmountInCents: updatedOrder.discountAmountInCents,
          subtotalAmountInCents: Math.abs(updatedOrder.discountAmountInCents),
          payments: updatedOrder.payments,
          taxAmountInCents: updatedOrder.taxAmountInCents,
          serviceFee: updatedOrder.serviceFee,
          orderUuid: updatedOrder.orderUuid
        }
        externalDataAndFunctionality.functions.placeFreeOrder(paymentData)
        return
      }

    }

    if (ppiTender.services[i] === VNPPI_SERVICES.GET_BALANCE ||
        ppiTender.services[i] === VNPPI_SERVICES.GET_CONTEXTUAL_BALANCE) {

      let balanceInCents = Math.ceil(responses[i].balance * 100)

      // some PPI integrations require separate transactions for monetary values that aren't part of the items
      // in the cart, such as tip or service fees.
      const separateNonCartItemsTransactionRequired = responses[i].separateNonCartItemsTransactionRequired ? responses[i].separateNonCartItemsTransactionRequired : false

      if (balanceInCents > 0 && separateNonCartItemsTransactionRequired && !isEmpty(contextualDiscountTransactionRequired)) {

        let dif = balanceInCents - contextualDiscountTransactionRequired.totalCartPriceAfterDiscountsApplied
        if (dif > 0) {
          externalDataAndFunctionality.balanceDueInCents -= contextualDiscountTransactionRequired.totalCartPriceAfterDiscountsApplied
          balanceInCents -= contextualDiscountTransactionRequired.totalCartPriceAfterDiscountsApplied
        } else {
          balanceInCents = 0
          externalDataAndFunctionality.balanceDueInCents -= Math.abs(dif)
        }

      }

      const autoReplenish = responses[i].autoReplenish
      const paymentResults = determineIfPaymentAppliesWithBalance(balanceInCents, externalDataAndFunctionality.balanceDueInCents, autoReplenish)

      remainingBalance = paymentResults.remainingBalance

      let paymentObj = {
        paymentType: externalDataAndFunctionality.paymentType,
        shortDescription: externalDataAndFunctionality.shortDescription,
        token: externalDataAndFunctionality.token,
        userAttributes: {
          posInfo: posInfo
        },
        push: externalDataAndFunctionality.functions.push
      }

      if (separateNonCartItemsTransactionRequired) {
        paymentObj.userAttributes = {
          ppiExtras: {}
        }

        paymentObj.userAttributes.ppiExtras[ppiTender.integration] = {
          isStoredValue: true
        }
      }

      if (paymentResults.tenderAmountInCents > 0) {
        paymentObj.tenderAmountInCents = paymentResults.tenderAmountInCents
        paymentObj.remainingTicketBalance = paymentResults.remainingTicketBalance

        externalDataAndFunctionality.functions.addPaymentToOrderInProgress(paymentObj)
      }

      if (remainingBalance > 0 && externalDataAndFunctionality.functions.redirect && !autoReplenish) {
        redirectEnabled = true
      }

    }
  }

  // handle the final redirect if necessary
  if (redirectEnabled) {
    externalDataAndFunctionality.functions.redirect(remainingBalance, true)
  }
}

/**
 * Format the order from internal state to what VNAPI needs.
 * @param {Object} order - The current order for the user, from state getOrderInProgress(state)
 * @returns {Object} - Returns a formatted orderInfo object that VNAPI expects.
 */
export const formatOrder = (order) => {

  let cart = []
  let orderInfo = {}

  if (!isEmpty(order)) {

    if (order.itemModels) {
      order.itemModels.forEach(itemModel => {
        let item = {
          quantity: itemModel.quantity,
          id: itemModel.id,
          sku: itemModel.variant.productSku,
          itemName: itemModel.name,
          itemCost: itemModel.priceInCents / 100,
          type: itemModel.category
        }

        cart.push(item)
      })
    }

    orderInfo = {
      orderId: order.uuid, // we want this to be unique globally, and out actual orderNumber is not
      orderUuid: order.uuid,
      cart: cart,
      total: order.amountInCents / 100,
      subTotal: order.subtotalAmountInCents / 100,
      currency: "USD",
      timestamp: moment().format('YYYY-MM-DDTHH:mm:ss') // 2023-05-01T19:07:31
    }
  }

  return orderInfo
}

/**
 *
 * @param {Object} order - The current order for the user, from state getOrderInProgress(state)
 * @param {ImmutableMap} ppiConfigurations - Returned by VNAPI PPI /configurations and injected into Redux immutable state.
 * @param {ImmutableMap} ppiOrderDetails - 
 * @param {Object} posInfo - The POS Information about this current POS - Format: { userId: '', vendorName: '', venueId: '' }
 * @param {Object} standPpiExtras - all ppiExtras that have been stored on the stand that is reporting the order - Example: { <INTEGRATION_NAME>: { tillPosID: '123' } }
 */
export const handlePpiReportOrder = (order, ppiConfigurations, ppiOrderDetails, posInfo, standPpiExtras) => {

  const paymentTransactions = ppiOrderDetails?.get('payments')

  // preOrderPayments are the payments array BEFORE being sent to Stadium for the order
  // we only want to use this if the above paymentTransactions is empty. 
  // We use this one when we want to report orders when there was NO PPI payments in the array
  // Since Stadium doesn't return the order details when there was NOT a PPI payment, so we use
  // what we have in local state
  const preorderPaymentTransactions = ppiOrderDetails?.get('preOrderPayments')
  const scannedTenders = ppiOrderDetails?.get('scannedTenders')

  let needToReportOrder = {}
  let allPayments = []

  // how much are we reporting? If nothing configured, then we will fall back to PARTIAL, which is just
  // PPI payment types.
  // ALL - Report all orders
  // Example: FORTRESS#ALL
  const report = ppiConfigurations.get('report')
  let defaultReportIntegration
  let defaultReportSystem

  if (report) {
    const reportSplit = report.split('#')
    defaultReportIntegration = reportSplit[0] // Integration - LAVA
    defaultReportSystem = reportSplit[1] // System - ALL or PARTIAL
  }

  // loop through payments to see if we need to support
  // sending the reportOrder to VNAPI PPI
  if (paymentTransactions) {

    paymentTransactions.map((paymentTransaction, index) => {

      let payment = {
        paymentType: paymentTransaction.paymentTypeName,
        spent: stringToCents(paymentTransaction.amount),
        credentialInfo: {
          credential: paymentTransaction.creditCardDetails?.paymentId,
        }
      }

      // we only want to submit the data once per integration
      if (paymentTypeIsPpi(paymentTransaction.paymentTypeName)) {

        const tender = getTenderByPaymentType(ppiConfigurations, paymentTransaction.paymentTypeName)

        // ensure that only 1 of each integration is tagged for inclusion for reporting
        if (tender?.integration) {
          needToReportOrder[tender.integration] = tender.integration
        }

        // This is required due to the POS axios response transforming everything!
        // it turns FANMAKER for an example into fANMAKER as the key!!!
        // ay caramba!!!! Mi cabeza!
        const integrationUnCapitalized = swapCaseFirstLetter(tender?.integration)

        if (paymentTransaction?.creditCardDetails?.ppiExtras[integrationUnCapitalized]) {
          payment[tender.integration] = {
            ...paymentTransaction.creditCardDetails.ppiExtras[integrationUnCapitalized]
          }
        }

        allPayments.push(payment)
      } else {
        if (defaultReportSystem === VNPPI_REPORT.ALL) {
          allPayments.push(payment)
        }
      }
    })
  } else if (preorderPaymentTransactions && defaultReportSystem === VNPPI_REPORT.ALL) {
    preorderPaymentTransactions.map((paymentTransaction, index) => {

      let payment = {
        paymentType: paymentTransaction.paymentType,
        spent: paymentTransaction.amountInCents
      }

      allPayments.push(payment)
    })
  }

  if (allPayments.length > 0 && defaultReportIntegration) {
    needToReportOrder[defaultReportIntegration] = defaultReportIntegration
  }

  let transaction = {
    orderInfo: formatOrder(order),
    posInfo: {
      tracerId: order.revenueCenterUuid,
      scannedTenders: scannedTenders?.toArray(),
      ...posInfo
    }
  }

  if (!isEmpty(needToReportOrder)) {

    transaction.orderInfo.payments = allPayments

    // report an order for each integration type
    for (const [integration, _] of vn_getEntries(needToReportOrder)) {

      // inject any custom key/value pairs if found on the stand
      if (standPpiExtras) {

        for (const [standIntegration, _] of vn_getEntries(standPpiExtras)) {

          const integrationCapitalized = swapCaseFirstLetter(standIntegration, true)

          if (integrationCapitalized === integration) {
            for (const [key, value] of vn_getEntries(standPpiExtras[standIntegration])) {
              transaction.posInfo[key] = value
            }
          }
        }
      }

      apiPostPpiProxy(VNPPI_SERVICES.REPORT_ORDER, integration, transaction)
    }
  }
}

/**
 * Generate the specific transaction object that is sent to VNAPI for the PPI proxy, this contextual specific to each service.
 * @param {Array} services - All services that have been being used. Example: getBalance, getContextualBalance
 * @param {String} credentialValue - The value that is being checked with PPI
 * @param {Object} order - The current order for the user, from state getOrderInProgress(state)
 * @param {Object} posInfo - The POS Information about this current POS - Format: { userId: '', vendorName: '', venueId: '' }
 * @returns {Object keyed by service} - The object will contain keys for each service enabled with the matching transaction needed to make the API call.
 */
export const generateTransactions = (services, credentialValue, order, posInfo) => {

  let transactions = {}

  services.forEach(service => {

    let baseTransaction = {}

    if (credentialValue) {
      baseTransaction = {
        credentialInfo: {
          credential: credentialValue
        },
        posInfo: posInfo
      }
    }

    let orderInfo

    switch(service) {
      case VNPPI_SERVICES.GET_BALANCE:
        break;
      case VNPPI_SERVICES.GET_DISCOUNTS:
        // Nothing to do atm
        break
      case VNPPI_SERVICES.GET_CONTEXTUAL_BALANCE:
        orderInfo = formatOrder(order)

        baseTransaction = {
          ...baseTransaction,
          orderInfo
        }

        if (order) {
          baseTransaction.amount = order.amountInCents / 100
        }

        break
      case VNPPI_SERVICES.GET_CONTEXTUAL_DISCOUNTS:
        orderInfo = formatOrder(order)

        baseTransaction = {
          ...baseTransaction,
          orderInfo
        }

        break;
      default:
        break;
    }

    transactions[service] = baseTransaction

  })

  return transactions
}

/**
 * Use this to dynamically generate the appropriate transactions and make all the ppi proxy calls in order and gather
 * responses. This is handy to use in one go instead of having to manage and track each individually. VOID Function, returns nothing.
 * @param {Object} ppiInjectableData - Contains the 3 parameters below.
 * - ppiTender - From getTender above, all the details of a tender as configured in VNConfigs - Example: { integration: FANMAKER, services: ['getAmenities'] }
 * - order - The current order for the user, from state getOrderInProgress(state)
 * - posInfo - The POS Information about this current POS - Format: { userId: '', vendorName: '', venueId: '' }
 * @param {Object} externalDataAndFunctionality - Functionality and data that is external to this Utils, required format:
 * { addPaymentToOrderInProgress: <FUNCTION>, balanceDueInCents: 3202, updateOrderInProgress: <FUNCTION>, token: '<TOKEN>', paymentType: 'vnapi_ticket', tenderDislayName: 'Ticket' }
 */
export const handleFullPpiFlow = async (ppiInjectableData, externalDataAndFunctionality) => {

  const ppiTender = ppiInjectableData.ppiTender
  const order = ppiInjectableData.order
  const posInfo = ppiInjectableData.posInfo
  const ppiConfigurations = ppiInjectableData.ppiConfigurations

  const transactions = generateTransactions(ppiTender.services, externalDataAndFunctionality.token, order, posInfo)

  const responses = await apiMakeChainServicesPpiProxyCalls(ppiTender.services, ppiTender.integration, transactions)

  await handleServiceResponses(ppiTender, responses, posInfo, externalDataAndFunctionality, order, ppiConfigurations)
}

/**
 * Used to determine if the order in Stadium has a PPI payment type in it
 * @param {Object} orderTransaction - Response from Stadium /pos/order
 * @returns {Boolean} - Returns true if the order has a PPI payment type, false otherwise
 */
export const isOrderPpi = (orderTransaction) => {
  if (orderTransaction && orderTransaction.paymentTransactions) {
    for (let i = 0; i < orderTransaction.paymentTransactions.length; i++) {
      if (paymentTypeIsPpi(orderTransaction.paymentTransactions[i].paymentTypeName)) {
        return true
      }
    }
  }
  return false
}