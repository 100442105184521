import axios from 'axios'
import moment from 'moment'

import { store } from '../stores'
import { setAutomaticUpdate, setPerformSafeUpdate, setVersionInfo } from '../actions/appState'
import networkConnected from './networkConnected'
import { bridge_updateCFDVersion } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'

const INTERVAL_TIMER = 900000
const AUTOMATIC_UPDATE_TIMER = 1800000

const getRemoveVersion = (data) => {
  const versionRegex = /(v(\d+|\.{1})*)-[a-z0-9]*/
  const version = data?.match?.(versionRegex)
  return version?.[0]
}

const versionDidChange = (remoteVersion) => {
  return [remoteVersion !== process.env.REACT_APP_VERSION, remoteVersion]
}

export const refreshCache = () => {
  networkConnected().then(() => {
    
    // need to tell the CFD to update its version, we really don't care if there isn't a CFD attached
    // since if there is nothing, the bridge just silently fails
    bridge_updateCFDVersion()

    window.location = `${window.location.origin}/?cacheBuster=${new Date().toISOString()}`
  })
}

export const newAppVersionAvailable = () => {
  return new Promise((resolve, reject) => {
    axios.get('/version.txt', {
      headers: { 'Cache-Control': 'no-cache' }
    }).then((response) => {
      resolve(versionDidChange(getRemoveVersion(response?.data)))
    }).catch((err) => {
      reject(err)
    })
  })
}

const msUntilNextRefresh = () => {
  const now = moment()
  const nextRefresh = now.clone().set({ h: 3, m: 0, s: 0 })
  if (now.isAfter(nextRefresh)) nextRefresh.add(1, 'day')
  return nextRefresh.diff(now)
}

export const morningUpdate = () => {
  setTimeout(() => {
    refreshCache()
  }, msUntilNextRefresh())
}

// When the automatic update timer commences, if the user does not choose to update
// immediately or the delayed morning update option within 30 minutes, the pos will  
// perform a safe update on the next encounter of the concessions screen
// or the attract loop screen.

export const automaticUpdate = () => {
  store.dispatch(setAutomaticUpdate({ automaticallyUpdate: true }))
  setTimeout(() => {
    const state = store.getState()
    const automaticallyUpdate = state?.appState?.automaticallyUpdate
    if (automaticallyUpdate) {
      store.dispatch(setPerformSafeUpdate(true))
    }
  }, AUTOMATIC_UPDATE_TIMER)
}

export const configureNewAppVersionAvailable = () => {
  let useOnce = true
  setInterval(async () => {
    try {
      const [hasNewVersion, remoteVersion] = await newAppVersionAvailable()
      if (hasNewVersion) {
        const state = store.getState()
        const automaticallyUpdate = state?.appState?.automaticallyUpdate
        store.dispatch(setVersionInfo({ updateAvailable: hasNewVersion, remoteVersion }))
        if (!automaticallyUpdate && useOnce) {
          useOnce = false
          automaticUpdate()
        }
      }
    } catch (err) {
      console.error('error fetching version: check your network connectivity.', err)
    }
  }, INTERVAL_TIMER)
}
