import React from 'react'
import PropTypes from 'prop-types'

const IconMinus = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <path d="M2 11.5h16v-3H2z" />
  </svg>
)

IconMinus.propTypes = {
  className: PropTypes.string,
}

export default IconMinus
