import { connect } from 'react-redux'

import OrdersSidebar from '../../components/orders/Sidebar'

const mapStateToProps = (state) => {
  const selectedOrderId = state.order.selectedOrderId
  const isOrderSelected = !!selectedOrderId

  return {
    isOrderSelected,
  }
}

export default connect(mapStateToProps, undefined)(OrdersSidebar)
