import { get, without, omit, difference } from 'lodash'
import { requestRefund, requestRefundSucceeded, requestRefundFailed, 
  REQUEST_TABBED_REFUND, REQUEST_REFUND_FAILED, REQUEST_TABBED_REFUND_SUCCEEDED } from '../actions/refund'
import { PURGE_ORDERS, PURGE_ALL_ORDERS } from '../actions/order'
import moment from 'moment'

const defaultState = {}

function refundReducer (state = defaultState, action) {
  switch (action.type) {
    case REQUEST_TABBED_REFUND:
    case requestRefund.type: {
      const orderId = get(action, 'payload.orderId', '')
      const itemIds = get(action, 'payload.itemIds', [])
      const lineItemIds = get(action, 'payload.lineItemIds', [])
      const reason = get(action, 'payload.reason', '')
      const amountInCents = action?.payload?.amountInCents ?? 0

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            orderId,
            itemIds,
            lineItemIds,
            reason,
            amountInCents,
            timeStamp: moment(),
          }
        },
      }
    }

    case REQUEST_TABBED_REFUND_SUCCEEDED:
    case requestRefundSucceeded.type: {
      const orderId = get(action, 'payload.orderId', '')
      const newFailureRemoteIds = without(state.failureRemoteIds, orderId)

      return {
        ...state,
        byId: {
          ...state.byId,
        },
        successRemoteIds: [ ...new Set([ ...state.successRemoteIds, orderId ]) ],
        failureRemoteIds: [ ...new Set([ ...newFailureRemoteIds ]) ],
        syncedAt: new Date(),
      }
    }

    case REQUEST_REFUND_FAILED:
    case requestRefundFailed.type: {
      const orderId = get(action, 'payload.orderId', '')
      const newSuccessRemoteIds = without(state.successRemoteIds, orderId)

      return {
        ...state,
        byId: {
          ...state.byId,
        },
        successRemoteIds: [ ...new Set([ ...newSuccessRemoteIds ]) ],
        failureRemoteIds: [ ...new Set([ ...state.failureRemoteIds, orderId]) ],
      }
    }

    case PURGE_ORDERS: {
      return {
        ...state,
        byId: {
          ...omit(state.byId, action.payload.orderIds),
        },
        successRemoteIds: difference(state.successRemoteIds, action.payload.orderIds),
      }
    }

    case PURGE_ALL_ORDERS: {
      return {
        ...state,
        byId: { },
        successRemoteIds: [ ],
      }
    }

    default:
      return state
  }
}

export default refundReducer
