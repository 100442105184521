import { actionChannel, call, delay, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects'
import { get, map, isEmpty, isString, isPlainObject, isNil, flatMap } from 'lodash'
import { toast } from 'react-toastify'

import { APPLICATION_STRINGS } from '../strings'

import moment from 'moment'

import {
  createOfflineOrderSucceeded,
  createOfflineOrderFailed,
  REPLAY_AUTH_REQUESTED,
  replayAuthRequestSucceeded,
  replayAuthRequestFailed,
  saveOrder,
  saveOfflineOrder,
  START_A_TAB,
  START_OFFLINE_CARD_ORDER,
  START_ONLINE_CARD_ORDER,
  SYNC_ORDER_REQUESTED,
  syncOrderInitiated,
  syncOrderRequested,
  syncOrderSucceeded,
  syncOrderFailed,
  SYNC_OFFLINE_ORDER_REQUESTED,
  syncOfflineOrderRequested,
  updateOrderSucceeded,
  updateOrderFailed,
  UPDATE_CARD_READER_DATA,
  CLOSE_NO_OP_TENDER,
  SEND_ORDER_EMAIL,
  SEND_ORDER_TEXT,
  SYNC_ORDERS_REQUESTED,
  SYNC_REPLAY_AUTH_ORDERS_REQUESTED,
  replayAuthRequested,
  updateOrderInProgress,
  ADD_PAYMENT_TO_ORDER_IN_PROGRESS,
  loadTabOrdersSucceeded,
  loadTabOrdersFailed,
  TAB_ORDERS_REQUESTED,
  ORDER_DETAILS_REQUESTED,
  loadOrderDetailsSucceeded,
  loadOrderDetailsFailed,
  orderDetailsRequested,
  CLOSE_TAB_ORDER,
  ADD_ITEMS_TO_TAB,
  ADD_ITEMS_TO_TAB_OFFLINE,
  ADD_DISCOUNTS_TO_TAB_ORDER,
  DELETE_DISCOUNTS_FROM_TAB_ORDER,
  addItemsToOrder,
  addItemsToTabFailed,
  addItemsToTabSucceeded,
  clearOrderInProgress,
  SYNC_AND_SAVE_ORDER_IN_PROGRESS,
  setQRPayAuthError,
  duplicateOrderWithNewIds,
  setOrderInProgressWithId,
  restoreQRPaymentandUnsyncableStatus,
  CLOSE_PRE_AUTHED_RC_ORDER,
  addNewPayments,
  ADD_NEW_PAYMENTS,
  removePayment,
  REMOVE_PAYMENT,
  UPDATE_SAVED_PAYMENT,
  closeTabOrder,
} from '../actions/order'

import { SAVE_RICH_CHECKOUT_ORDER_REQUESTED, saveRichCheckoutOrder } from '../actions/richCheckout/order'
import { clearRemoteOrderTotal } from '../actions/orderTotalRemote'
import { clearCart } from '../actions/cart'
import { onSafIsUploading, onDataIsUploading } from '../actions/peripheral'
import { addPromotionsToOrder, clearPromotionsOnOrder } from '../actions/promotion'
import { setUpdatedSince } from '../actions/order'

import MutationApi from '../remote/mutationApi'
import Remote from '../remote'

import { getItemsForCurrentMenu, getMenuServiceCharge } from '../selectors/items'
import { getSafIsUploading, getDataIsUploading } from '../selectors/peripheral'
import { getCartItems, getTaxRate, getTaxByItem, getCartNotes } from '../selectors/cart'
import { makeGetOrder, getOfflineLocalIds, getOfflineCardIds, getOrderInProgress, getUpdatedSince, getQRPayAuthError } from '../selectors/order'
import { getIsKiosk, getCurrentMenu, getKitchenPrintingEnabled } from '../selectors/menus'
import { getDeviceSubtype } from '../selectors/config'
import { getActiveAttendantId, getAttendants } from '../selectors/attendant'
import { getOrderSubumissionUuid, getRemoteOrderTotal } from '../selectors/orderTotalRemote'
import { getSelectedPromotions } from '../selectors/promotion'

import { setSafUploadComplete, setDataUploadComplete } from '../actions/peripheral'

import { customHistory } from '../stores'

import { incrementNextOrderNumber, nextPosOrderNumber } from '../utils/orderNumbers'
import { calculateTax, calculateSubtotal, calculateServiceCharge, calculateIndividualTaxAmount } from '../utils/totalUtils'
import { getNetworkAvailableValue } from '../utils/networkConnected'
import createDataDogLog from '../utils/datadog'
import DataMapper from '../utils/dataMapper'
import PeripheralBridge from '../utils/peripheralBridge'
import { approvedOfflineThenDeclined, isOfflineApproval, responseStatusString, statusCheckComplete } from '../utils/offlineReplayStatuses'
import { STADIUM_ORDER_STATES, INTERNAL_ORDER_STATES, isFinalState, isTabbed, isQRPay } from '../utils/orderStates'
import { PAYMENT_TYPES } from '../utils/paymentTypes'
import { getIsPreAuthTab, makeCreateOrderLineItems, makeLineItemData, createOrderParams } from '../utils/orderUtils'
import DeviceSubtypes from '../utils/deviceSubtypes'
import { ToastManager } from '../utils/toastManager'

import { getDeviceMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import { getCurrentPaymentFlow } from '../VNCustomerFacingDisplay/Selectors'
import { CFD_POS_PAYMENT_FLOWS, CFD_SCREEN } from '../VNCustomerFacingDisplay/Enums'
import { paymentTypeIsPpi } from '../VNPPI/Utils'
import { setOrderPayments, setPreOrderPayments } from '../VNPPI/ActionCreators'
import { setCustomerViewingReceiptDialog } from '../VNDialogs/ActionCreators'
import { bridge_setCFDScreen } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { createOrder } from '@ordernext/networking-stadium/stadium/actions'

// Gateway timeouts from platform show ~30s in DataDog
// FCC default timeout is 35s, PAX is 60s, split the difference to 45
const MS_GATEWAY_FORCE_CONTINUE = 45_000
const STADIUM_CREATE_ENDPOINT = '/pos/orders'

const generateUuid = require('uuid/v4')
const getOrder = makeGetOrder()
const getCreateOrderParams = (order) => createOrderParams(order)

function presentOrderToast(kitchenPrintingEnabled, response) {
  const errorMessage = response?.data?.errorMessage
  const friendlyMessage = response?.data?.friendlyMessage

  if (response?.config?.method !== 'post') return response
  if (response?.config?.url !== STADIUM_CREATE_ENDPOINT) return response

  // If this is a create request, toast the user appropriately with the response.
  if (errorMessage) {
    ToastManager.error(`${errorMessage}`, { autoClose: true })
  } else if (friendlyMessage) {
    ToastManager.success(`${friendlyMessage}`, { autoClose: true })
  } else if (kitchenPrintingEnabled) {
    ToastManager.success('Success. Order placed and kitchen chits printed.', { autoClose: true })
  }
}

function* verifyCurrentAttendant(isKiosk) {
  const attendantsExist = !isEmpty(yield select(getAttendants))

  if (!attendantsExist || isKiosk) {
    return null
  }

  const employeeId = yield select(getActiveAttendantId)

  if (employeeId) {
    return employeeId
  }

  yield call(directTo, 'push', '/user-login')
  ToastManager.error(APPLICATION_STRINGS.PLEASE_LOGIN)
  createDataDogLog('error', APPLICATION_STRINGS.PLEASE_LOGIN)
  return undefined
}

// TODO(mkramerl): This is disgusting. I'm so angry right now.
const makeCreateOrderParams = ({ amountInCents, tipAmountInCents, signature, itemModels, menuUuid,
    orderNumber, currentOrderState, paymentType, submittedAt, uuid, orderState, employeeId,
    affiliations, promotions, userNotes, payments, shortDescription, originalClientCreatedAt }) => {

  let shape = {
    clientCreatedAt: originalClientCreatedAt || moment(submittedAt).toISOString(),
    standMenuUuid: menuUuid,
    orderMenuItems: makeCreateOrderLineItems(itemModels),
    orderNumber,
    payments: [
      {
        paymentType,
        amountInCents,
        shortDescription,
      }
    ],
    webOrder: true,
    uuid,
    affiliations,
    promotions,
    tipAmountInCents,
    userNotes,
  }

  if (payments) {
    shape.payments = payments
  }

  if (signature) {
    shape.receiptSignature = signature
  }

  if (employeeId) {
    shape.employeeId = employeeId
  }

  // TODO(mkramerl): Utilize our actual platform order states across the entire POS rather than
  // 'open', 'closed', 'failed'.

  if (orderState === INTERNAL_ORDER_STATES.FAILED) {
    shape.state = STADIUM_ORDER_STATES.AUTHORIZATION_FAILED
  } else if (orderState === INTERNAL_ORDER_STATES.PREAUTH) {
    shape.state = STADIUM_ORDER_STATES.SUBMITTED
  } else if (orderState === INTERNAL_ORDER_STATES.CANCELLED) {
    shape.state = STADIUM_ORDER_STATES.CANCELLED
  }

  return shape
}

const getUserNameFromCard = (cardReaderData) => {
  // example: "OBERG/PETER               "
  // super defensive with the string methods below because I don't know much about different possibilities
  const name = cardReaderData.cardholderName || "/"
  const split = name.split("/")
  const firstName = (split[1] || "").trim()
  const lastName = (split[0] || "").trim()

  return `${firstName} ${lastName}`
}

// called user_attributes in stadium, but it mostly contains credit card attributes
const makeUserAttributes = (cardReaderData) => {
  const { authResponseData, receiptData } = cardReaderData

  const { authorizationCode, processorTransactionId, reconciliationId } = authResponseData || {}
  const { approvalCode, authStatus, maskedCardNumber, maskedPan } = receiptData || {}

  const safeFour = maskedCardNumber || ""
  const lastFour = safeFour.substring(safeFour.length - 4)

  const token = cardReaderData?.tokenInformation?.token
  const deviceId = localStorage.getItem('device_id')
  const isOfflineApproval = cardReaderData?.isOfflineApproval

  return {
    last_4: lastFour,

    // needed in this format for refunds
    freedompayRequestId: cardReaderData.requestId,
    freedompayMerchantReferenceCode: cardReaderData.merchantReferenceCode || cardReaderData.uuid,
    freedompayInvoiceNumber: cardReaderData.invoiceNumber,
    freedompayIssuerName: cardReaderData.cardIssuer || cardReaderData.cardType,
    freedompayStoreId: cardReaderData.storeId,
    freedompayTerminalId: cardReaderData.terminalId,

    // extra data to help troubleshoot customer charge back requests
    authorizationCode,
    processorTransactionId,
    reconciliationId,
    approvalCode,
    authStatus,
    maskedCardNumber,
    maskedPan,
    token,
    deviceId,
    isOfflineApproval,
  }
}

export function* closeNoOpTenderSaga(params = {}) {
  const deviceSubtype = yield select(getDeviceSubtype)
  const isKiosk = DeviceSubtypes.isKiosk(deviceSubtype)
  const employeeId = yield verifyCurrentAttendant(isKiosk)
  if (employeeId === undefined) return

  const tender = params?.payload?.tender ?? { name: 'cash', displayName: 'Cash' }
  const tipAmountInCents = params?.payload?.tipAmountInCents ?? 0
  const submittedAt = new Date()

  const paymentCompleted = true

  const { menuUuid, revenueCenterUuid, revenueName } = yield menuAndRvcUuids(isKiosk)

  const lineItems = yield select(getCartItems)
  const userNotes = yield select(getCartNotes)

  const uuid = yield select((state) => getOrderSubumissionUuid(state, params.payload?.orderUuid))

  // Perhaps this is another attempt to trigger the creation of an order already in our DB.
  const order = yield select(getOrder, uuid)

  const orderNumber = createOrder(order)?.orderNumber ?? params?.payload?.orderNumber ?? nextPosOrderNumber()
  const itemModels = order?.itemModels ?? makeLineItemData(lineItems)

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)

  const remoteOrderTotal = yield select(getRemoteOrderTotal)
  let discountAmountInCents = remoteOrderTotal?.discountAmountInCents ?? 0

  if (params?.payload?.discountAmountInCents) {
    discountAmountInCents = params.payload.discountAmountInCents
  }

  const affiliations = remoteOrderTotal?.isAvailable ? [] : null

  let taxAmountInCents

  if(params?.payload?.hasOwnProperty('taxAmountInCents')) {
    taxAmountInCents = params?.payload?.taxAmountInCents
  } else if (remoteOrderTotal?.isAvailable) {
    taxAmountInCents = remoteOrderTotal?.taxAmountInCents ?? 0
  } else {
    taxAmountInCents = calculateTax(lineItems, taxRate, taxByItem)
  }

  let subtotalAmountInCents = remoteOrderTotal?.subtotalInCents ?? calculateSubtotal(lineItems)
  let amountInCents = (remoteOrderTotal?.totalAmountInCents ?? subtotalAmountInCents + taxAmountInCents) + tipAmountInCents
  let serviceFee = { name: 'Service Charge', total: 0, legal: null }

  if (params?.payload?.serviceFee) {
    serviceFee = { name: remoteOrderTotal?.serviceChargeName, total: params?.payload?.serviceFee?.total ?? 0, legal: remoteOrderTotal.serviceChargeLegal }

    taxAmountInCents += calculateIndividualTaxAmount(taxRate, serviceFee.total)
    amountInCents = amountInCents + serviceFee.total
  } else if (!isNil(remoteOrderTotal?.totalAmountInCents)) { // Recalibrate the subtotal and total based on whether or not there is a remote total.
    // When there is a remote total, the subtotal is present and properly calculated.
    serviceFee = { name: remoteOrderTotal?.serviceChargeName, total: remoteOrderTotal?.serviceChargeInCents ?? 0, legal: remoteOrderTotal.serviceChargeLegal }
  } else {
    // When there is no remote total, the service fee needs to be calculated and added into the
    // total but not the subtotal.
    const serviceCharge = yield select((state) => getMenuServiceCharge(state))
    serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal }

    taxAmountInCents += calculateIndividualTaxAmount(taxRate, serviceFee.total, serviceCharge.taxable)
    amountInCents = amountInCents + serviceFee.total
  }

  if (isEmpty(lineItems) && !isKiosk) {
    yield call(directTo, 'push', '/concession-order')
    createDataDogLog('error', APPLICATION_STRINGS.ORDER_ONE_ITEM)
    return
  }

  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions) || !remoteOrderTotal.isAvailable) {
    promotions = undefined
  }

  const createOrderParams = makeCreateOrderParams({
    amountInCents: params?.payload?.hasOwnProperty('amountInCents') ? params.payload.amountInCents : amountInCents,
    itemModels,
    menuUuid,
    orderNumber: getCreateOrderParams(order)?.orderNumber ?? orderNumber,
    paymentType: tender.name,
    submittedAt,
    orderState: INTERNAL_ORDER_STATES.CLOSED,
    uuid,
    employeeId,
    affiliations,
    promotions,
    tipAmountInCents,
    userNotes,
    payments: params?.payload?.hasOwnProperty('payments') ? params.payload.payments : null,
    shortDescription: tender.displayName,
    originalClientCreatedAt: getCreateOrderParams(order)?.clientCreatedAt,
  })

  const orderData = {
    amountInCents: params?.payload?.hasOwnProperty('amountInCents') ? params.payload.amountInCents : amountInCents,
    displayName: tender.displayName,
    subtotalAmountInCents: params?.payload?.hasOwnProperty('subtotalAmountInCents') ? params.payload.subtotalAmountInCents : subtotalAmountInCents,
    taxAmountInCents,
    discountAmountInCents,
    tipAmountInCents,
    balanceDueInCents: 0,
    serviceFee,
    createOrderParams,
    itemModels,
    paymentCompleted,
    orderNumber,
    submittedAt,
    orderState: 'closed',
    uuid,
    revenueCenterUuid,
    revenueName,
    userNotes,
  }

  yield put(saveOrder(orderData))
  yield put(syncOrderRequested(uuid))
  yield put(clearRemoteOrderTotal())
  yield put(clearOrderInProgress())

  if (!isKiosk) {
    yield call(directTo, 'push', `/receipt/${uuid}`)
  }
}

function* menuAndRvcUuids(isKiosk) {
  let menuUuid = yield select((state) => get(state, 'config.configuration.menuUuids[0]', ''))
  let revenueCenterUuid = yield select((state) => get(state, 'revenueCenter.activeRevenueCenter', 'unassigned'))
  let revenueName = yield select((state) => get(state, `menus.byId.${menuUuid}.standName`, 'unassigned'))

  if (isKiosk) {
    menuUuid = yield select((state) => get(state, 'appState.currentMenuId'))
    revenueCenterUuid = yield select((state) => get(state, `menus.byId.${menuUuid}.standUuid`, 'unassigned'))
    revenueName = yield select((state) => get(state, `menus.byId.${menuUuid}.standName`, 'unassigned'))
  }

  return { menuUuid, revenueCenterUuid, revenueName }
}

export function* sendOrderText(params = { }) {
  const orderUuid = params.payload?.orderUuid
  const userPhone = params.payload?.userPhone

  try {
    yield call(Remote.updatePosOrder, orderUuid, { userPhone }, false)
    toast(APPLICATION_STRINGS.TEXT_SENT)
  } catch (err) {
    toast(APPLICATION_STRINGS.TEXT_FAILED + err)
  }
  return
}

export function* watchSendOrderText() {
  yield takeEvery(SEND_ORDER_TEXT, sendOrderText)
}

export function* sendOrderEmail(params = {}) {
  const payload = params.payload

  try {
    yield call(Remote.sendEmailJobToStadium, payload)
    toast(APPLICATION_STRINGS.EMAIL_SENT)
  } catch (err) {
    toast(APPLICATION_STRINGS.EMAIL_FAILED + err)
  }
  return
}

export function* watchSendOrderEmail() {
  yield takeEvery(SEND_ORDER_EMAIL, sendOrderEmail)
}

export function* startOnlineCardOrder(params = {}) {
  const isKiosk = get(params, 'payload.isKiosk', false)
  const employeeId = yield verifyCurrentAttendant(isKiosk)
  if (employeeId === undefined) return
  const orderInProgress = yield select(getOrderInProgress)

  const cardReaderData = params?.payload?.cardReaderData ?? {}
  const signature = params?.payload?.signature

  // TODO We should make a separate saga for handling preAuth and failed split orders that send a more accurate representation of the order.
  // orderInProgress data used only for preauth order submission accuracy
  const tipAmountInCents = orderInProgress?.tipAmountInCents ?? params?.payload?.tipAmountInCents ?? 0
  const affiliations = orderInProgress?.affiliations ?? []

  const submittedAt = new Date()
  const paymentCompleted = cardReaderData.transType === 'sale'
  const isTab = false
  const orderState = get(params, 'payload.orderState', INTERNAL_ORDER_STATES.CLOSED)

  const { menuUuid, revenueCenterUuid, revenueName } = yield menuAndRvcUuids(isKiosk)
  const menu = yield select((state) => getCurrentMenu(state))
  const menuSubtype = get(menu, 'subtype')
  const uuid = yield select((state) => getOrderSubumissionUuid(state, cardReaderData?.uuid))
  const order = yield select(getOrder, uuid)

  // for kiosk orders, we're passing in line items to avoid race condition of cart being cleared
  // when redirecting to the attract loop
  let lineItems = get(params, 'payload.lineItems')
  if (!lineItems) lineItems = yield select(getCartItems)

  let userNotes = get(params, 'payload.userNotes')
  if (!userNotes) userNotes = yield select(getCartNotes)

  if (isEmpty(lineItems) && !isKiosk && !isTabbed(order) && !order.previousUnsyncableStatus) {
    yield call(directTo, 'push', '/concession-order')
    createDataDogLog('error', APPLICATION_STRINGS.ORDER_ONE_ITEM)
    return
  }

  // Perhaps this is another attempt to trigger the creation of an order already in our DB.

  // To ensure using the correct order number, the hiarchy system goes as follows
  // 1. if the order has been previously submitted to the platform, use the order number on the submitted order
  // 2. if there is a current order in progress, use that order number
  // 3. otherwise, use the order number attached to the card reader response or passed in through the dispatch
  const orderNumber =
  getCreateOrderParams(order)?.orderNumber ??
    orderInProgress?.orderNumber ??
    cardReaderData?.orderNumber ??
    params?.payload?.orderNumber

  const itemModels = orderInProgress?.itemModels ?? order?.itemModels ?? makeLineItemData(lineItems)

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)
  const remoteOrderTotal = yield select(getRemoteOrderTotal)
  const discountAmountInCents = remoteOrderTotal?.discountAmountInCents ?? 0

  let taxAmountInCents =  remoteOrderTotal?.isAvailable ? remoteOrderTotal?.taxAmountInCents ?? 0 : calculateTax(lineItems, taxRate, taxByItem)
  let subtotalAmountInCents = remoteOrderTotal?.subtotalInCents ?? calculateSubtotal(lineItems)
  let amountInCents = (remoteOrderTotal?.totalAmountInCents ?? subtotalAmountInCents + parseInt(taxAmountInCents)) + parseInt(tipAmountInCents)
  let serviceFee = { name: 'Service Charge', total: 0, legal: null }

  // Recalibrate the subtotal and total based on whether or not there is a remote total.
  if (!isNil(remoteOrderTotal?.totalAmountInCents)) {
    // When there is a remote total, the subtotal is present and properly calculated.
    serviceFee = { name: remoteOrderTotal?.serviceChargeName, total: remoteOrderTotal?.serviceChargeInCents ?? 0, legal: remoteOrderTotal.serviceChargeLegal }
  } else {
    // When there is no remote total, the service fee needs to be calculated and added into the
    // total but not the subtotal.
    const serviceCharge = yield select((state) => getMenuServiceCharge(state))
    serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal }

    taxAmountInCents += calculateIndividualTaxAmount(taxRate, serviceFee.total, serviceCharge.taxable)
    amountInCents = amountInCents + serviceFee.total
  }

  const userAttributes = makeUserAttributes(cardReaderData)

  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions) || !remoteOrderTotal.isAvailable) {
    promotions = undefined
  }

  const shortDescription = userAttributes?.freedompayIssuerName ? `${userAttributes?.freedompayIssuerName} ${userAttributes?.last_4}` : undefined

  const createOrderParams = {
    ...makeCreateOrderParams({
      amountInCents,
      tipAmountInCents,
      signature,
      employeeId,
      itemModels,
      menuUuid,
      orderNumber: getCreateOrderParams(order)?.orderNumber ?? orderNumber,
      currentOrderState: getCreateOrderParams(order)?.state,
      paymentType: 'freedompay_credit',
      submittedAt,
      isTab,
      orderState,
      uuid,
      affiliations,
      promotions,
      userNotes,
      shortDescription,
      originalClientCreatedAt: getCreateOrderParams(order)?.clientCreatedAt,
    }),
    userAttributes: userAttributes,
    userName: getUserNameFromCard(cardReaderData),
    reason: params?.payload?.reason,
  }

  const orderData = {
    amountInCents,
    tipAmountInCents,
    subtotalAmountInCents,
    discountAmountInCents,
    taxAmountInCents,
    balanceDueInCents: 0,
    serviceFee,
    cardReaderData,
    createOrderParams,
    isTab,
    itemModels,
    menuSubtype,
    orderNumber,
    orderState,
    paymentCompleted,
    revenueCenterUuid,
    revenueName,
    signature,
    submittedAt,
    uuid,
    invoiceNumber: userAttributes.freedompayInvoiceNumber,
    userNotes,
  }

  if (orderState === INTERNAL_ORDER_STATES.CLOSED) {
    yield put(clearRemoteOrderTotal())
  }
  yield put(saveOrder(orderData))
  yield put(syncOrderRequested(uuid))
}

const directTo = (method = 'push', pathOrOptions) => {
  if (pathOrOptions === undefined) return

  if (isString(method) && isString(pathOrOptions)) {
    customHistory[method](pathOrOptions)
  } else if (isString(method) && isPlainObject(pathOrOptions)) {
    customHistory[method](pathOrOptions)
  }
}

export function* updateCardReaderDataSaga(params) {
  const orderId = get(params, 'payload.orderId')
  const order = yield select(getOrder, orderId)
  const newCardReaderData = get(params, 'payload.cardReaderData', {})
  const userAttributes = makeUserAttributes(newCardReaderData)
  const newCreateOrderParams = {
    ...getCreateOrderParams(order),
    userAttributes: userAttributes,
    userName: getUserNameFromCard(newCardReaderData),
    payments: [
      {
        paymentType: get(order, 'createOrderParams.payments[0]', {}).paymentType,
        amountInCents: order.amountInCents,
      }
    ]
  }


  yield put(saveOrder({
    uuid: order.uuid,
    cardReaderData: newCardReaderData,
    createOrderParams: newCreateOrderParams,
    paymentCompleted: true,
    invoiceNumber: userAttributes.freedompayInvoiceNumber
  }))
}

export function* startATab(params = {}) {
  const isKiosk = get(params, 'payload.isKiosk', false)
  const tabName = params?.payload?.tabName ?? ''
  const employeeId = yield verifyCurrentAttendant(isKiosk)
  if (employeeId === undefined) return

  const uuid = generateUuid()
  const paymentId = params?.payload?.paymentId ?? ''
  const paymentType = params?.payload?.paymentType
  const cardReaderData = params?.payload?.cardReaderData
  const orderNumber = cardReaderData?.orderNumber ?? params?.payload?.orderNumber ?? nextPosOrderNumber()
  const submittedAt = Date.now()
  const orderState = get(params, 'payload.orderState', 'tabbed')

  const { menuUuid, revenueCenterUuid, revenueName } = yield menuAndRvcUuids(isKiosk)
  const menu = yield select((state) => getCurrentMenu(state))
  const menuSubtype = get(menu, 'subtype')

  // for kiosk orders, we're passing in line items to avoid race condition of cart being cleared
  // when redirecting to the attract loop
  let lineItems = get(params, 'payload.lineItems')
  if (!lineItems) lineItems = yield select(getCartItems)

  let userNotes = get(params, 'payload.userNotes')
  if (!userNotes) userNotes = yield select(getCartNotes)

  if (isEmpty(lineItems) && !isKiosk) {
    yield call(directTo, 'push', '/concession-order')

    ToastManager.error(APPLICATION_STRINGS.ORDER_ONE_ITEM)

    createDataDogLog('error', APPLICATION_STRINGS.ORDER_ONE_ITEM)
    return
  }

  // Perhaps this is another attempt to trigger the creation of an order already in our DB.
  const order = yield select(getOrder, uuid)
  const itemModels = order?.itemModels ?? makeLineItemData(lineItems)

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)
  const serviceCharge = yield select((state) => getMenuServiceCharge(state))
  const originalTransactionId = cardReaderData?.uuid || ''
  // for tabbed orders, inital totals are offline calculated
  const subtotalAmountInCents = calculateSubtotal(lineItems)
  const serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal, taxable: serviceCharge.taxable }
  const taxAmountInCents = calculateTax(lineItems, taxRate, taxByItem) + calculateIndividualTaxAmount(taxRate, serviceFee, serviceCharge.taxable)
  const amountInCents = subtotalAmountInCents + taxAmountInCents + serviceFee.total
  const balanceDueInCents = amountInCents
  const tipAmountInCents = 0

  let userAttributes
  let userName
  if (cardReaderData) {
    userAttributes = makeUserAttributes(cardReaderData)
    userName = getUserNameFromCard(cardReaderData)
  }

  const payment = {
    paymentId,
    paymentType,
    amountInCents,
    orderNumber,
    tipAmountInCents,
    shortDescription: 'N/A',
  }


  if (paymentType === PAYMENT_TYPES.CREDIT_CARD) {
    payment.userAttributes = userAttributes
    payment.userName = userName
    payment.cardType = userAttributes.freedompayIssuerName
    payment.label = cardReaderData?.cardType
    payment.shortDescription = `${userAttributes?.freedompayIssuerName} ${userAttributes?.last_4}`
  }

  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions)) {
    promotions = undefined
  }

  yield put(setSafUploadComplete(false))

  const createOrderParams = {
    ...makeCreateOrderParams({
      employeeId,
      isTab: false,
      itemModels,
      menuUuid,
      orderNumber,
      orderState,
      submittedAt,
      uuid,
      affiliations: null,
      promotions,
      userNotes,
      shortDescription: payment.shortDescription,
      paymentType,
      amountInCents,
      originalClientCreatedAt: getCreateOrderParams(order)?.clientCreatedAt,
    }),
    state: STADIUM_ORDER_STATES.TABBED,
    userAttributes,
    userName: tabName,
  }

  const orderData = {
    createOrderParams,
    itemModels,
    menuSubtype,
    orderNumber,
    revenueCenterUuid,
    revenueName,
    submittedAt,
    tabName,
    uuid,
    payments: [payment],
    subtotalAmountInCents,
    cardReaderData,
    originalTransactionId,
    taxAmountInCents,
    amountInCents,
    balanceDueInCents,
    tipAmountInCents,
    discountAmountInCents: 0,
    state: STADIUM_ORDER_STATES.TABBED,
    invoiceNumber: userAttributes?.freedompayInvoiceNumber,
    userNotes,
  }

  if (!cardReaderData) {
    createOrderParams.payments = null
    orderData.payments = null
    delete orderData.invoiceNumber
  }

  if (cardReaderData && isOfflineApproval({ cardReaderData })) {
    createOrderParams.state = STADIUM_ORDER_STATES.TABBED_OFFLINE
    orderData.state = STADIUM_ORDER_STATES.TABBED_OFFLINE
    yield put(saveOfflineOrder(orderData))
  } else {
    yield put(saveOrder(orderData))
  }

  yield put(clearRemoteOrderTotal())
  yield put(clearPromotionsOnOrder())
  yield put(clearCart())
  yield put(syncOrderRequested(uuid))

  ToastManager.success(APPLICATION_STRINGS.TAB_STARTED)
  yield call(incrementNextOrderNumber)
}

export function* addItemsToTab (params = {}) {
  const order = yield select(getOrderInProgress)
  const cartItems = yield select(getCartItems)
  const newLineItems = makeCreateOrderLineItems(makeLineItemData(cartItems))

  let userNotes = get(params, 'payload.userNotes')

  if (!userNotes) userNotes = yield select(getCartNotes)

  try {
    const result = yield call(MutationApi.addLineItems, order.uuid, newLineItems, userNotes)

    const applied = result?.data?.orderMutations?.[0]?.applied

    if (applied) {
      yield put(addItemsToTabSucceeded({orderId: order.uuid, userNotes}))
      yield put(orderDetailsRequested(order.uuid))
      yield put(clearCart())
      yield put(clearOrderInProgress())
      ToastManager.success(APPLICATION_STRINGS.TAB_UPDATED)
    } else {
      yield put(addItemsToTabFailed())
      ToastManager.error(APPLICATION_STRINGS.TAB_UPDATE_FAILED)
    }
  } catch (e) {
    yield put(addItemsToTabFailed())
    ToastManager.error(APPLICATION_STRINGS.TAB_UPDATE_FAILED)
  }
}

export function* addDiscountsToTabOrderSaga (params = {}) {
  const order = yield select(getOrderInProgress)
  const promotions = yield select(getSelectedPromotions)

  //if we're adding discounts then we need to send auto applied discounts to the updateTotals calls or we'll lose them
  const autoAppliedPromotions = order.promotions?.filter(promotion => promotion.redemptionMethod === 'auto') || []

  try {
    const result = yield call(MutationApi.updateTotals, {orderUuid: order.uuid, promotions: [...promotions, ...autoAppliedPromotions]})
    const applied = result?.data?.orderMutations?.[0]?.applied

    if (applied) {
      yield call(getOrderDetails, { payload: { orderUuid: order.uuid }})
      yield put(setOrderInProgressWithId(order.uuid))

      //get applied promotions from lineItems and push their uuids in a single array
      const allAppliedPromotions = result?.data?.orderMutations?.[0]?.lineItems
        .map((item) => item.promotions)
        .filter((promotions) => promotions !== null)
        .reduce((acc, promotions) => {
          return acc.concat(promotions.map((promotion) => promotion.uuid))
        }, [])

      //check if some promotion was not applied
      const notAllPromotionsApplied = promotions.some(promotion => !allAppliedPromotions.includes(promotion.uuid))

      if (notAllPromotionsApplied) {
        ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
      } else {
        if (allAppliedPromotions.length) {
          ToastManager.success(APPLICATION_STRINGS.DISCOUNTS_APPLIED)
        }
      }

      //filter local state applied promotions and update state
      const selectedPromotions = promotions.filter(promotion => allAppliedPromotions.includes(promotion.uuid))

      yield put(addPromotionsToOrder({ selectedPromotions }))

      yield call(directTo, 'push', { pathname: '/tender-selection', state: { fromDiscounts: true }})
      return
    }

    ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
    yield call(directTo, 'push', { pathname: '/tender-selection', state: { fromDiscounts: true }})
  } catch (e) {
    ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
    yield put(clearCart())
    yield put(clearOrderInProgress())
    yield call(directTo, 'push', '/orders')
  }
}

export function* deleteDiscountsFromTabOrderSaga (params = {}) {
  const order = yield select(getOrderInProgress)

  //if we're deleting discounts then we need to send auto applied discounts to the updateTotals calls or we'll lose them
  const autoAppliedPromotions = order.promotions?.filter(promotion => promotion.redemptionMethod === 'auto') || []

  try {
    const result = yield call(MutationApi.updateTotals, {orderUuid: order.uuid, promotions: [...autoAppliedPromotions]})
    const applied = result?.data?.orderMutations?.[0]?.applied

    if (applied) {
      yield put(orderDetailsRequested(order.uuid))
      return
    }
    ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
  } catch (e) {
    ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
  }
}

export function* addItemsToTabOffline (params = {}) {
  const order = yield select(getOrderInProgress)
  const orderId = order.uuid
  const lineItems = yield select(getCartItems)
  const itemModels = makeLineItemData(lineItems)
  const orderMenuItems = makeCreateOrderLineItems(itemModels)
  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions)) {
    promotions = undefined
  }

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)
  const serviceCharge = yield select((state) => getMenuServiceCharge(state))

  // for offline tabbed orders we need to update calculations
  const subtotalAmountInCents = calculateSubtotal(lineItems)
  const serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal }
  const taxAmountInCents = calculateTax(lineItems, taxRate, taxByItem) + calculateIndividualTaxAmount(taxRate, serviceFee.total, serviceCharge.taxable)
  const amountInCents = subtotalAmountInCents + taxAmountInCents + serviceFee.total
  const balanceDueInCents = amountInCents
  const payments = getCreateOrderParams(order)?.payments
  if (!isEmpty(payments)) {
    payments[0].amountInCents = amountInCents + order?.amountInCents
  }

  yield put(addItemsToOrder({ orderMenuItems, itemModels, orderId, subtotalAmountInCents, taxAmountInCents, amountInCents, balanceDueInCents, promotions, payments }))
  yield put(clearCart())
  yield put(clearOrderInProgress())
  yield put(syncOrderRequested(orderId))

  ToastManager.success(APPLICATION_STRINGS.TAB_UPDATED)
}

export function* startOfflineCardOrder(params = {}) {
  const isKiosk = get(params, 'payload.isKiosk', false)
  const employeeId = yield verifyCurrentAttendant(isKiosk)
  if (employeeId === undefined) return

  const cardReaderData = params?.payload?.cardReaderData ?? {}
  const signature = params?.payload?.signature
  const tipAmountInCents = params?.payload?.tipAmountInCents ?? 0
  const orderNumber = cardReaderData?.orderNumber ?? params?.payload?.orderNumber
  const submittedAt = Date.now()
  const paymentCompleted = cardReaderData.transType === 'sale'
  const orderState = get(params, 'payload.orderState', INTERNAL_ORDER_STATES.CLOSED)

  const { menuUuid, revenueCenterUuid, revenueName } = yield menuAndRvcUuids(isKiosk)
  const menu = yield select((state) => getCurrentMenu(state))
  const menuSubtype = get(menu, 'subtype')

  // for kiosk orders, we're passing in line items to avoid race condition of cart being cleared
  // when redirecting to the attract loop
  let lineItems = get(params, 'payload.lineItems')
  if (!lineItems) lineItems = yield select(getCartItems)

  let userNotes = get(params, 'payload.userNotes')
  if (!userNotes) userNotes = yield select(getCartNotes)

  if (isEmpty(lineItems) && !isKiosk) {
    yield call(directTo, 'push', '/concession-order')
    createDataDogLog('error', APPLICATION_STRINGS.ORDER_ONE_ITEM)
    return
  }

  const uuid = yield select((state) => getOrderSubumissionUuid(state, cardReaderData?.uuid))

  // Perhaps this is another attempt to trigger the creation of an order already in our DB.
  const order = yield select(getOrder, uuid)
  const itemModels = order?.itemModels ?? makeLineItemData(lineItems)

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)
  const remoteOrderTotal = yield select(getRemoteOrderTotal)
  const discountAmountInCents = remoteOrderTotal?.discountAmountInCents ?? 0

  let taxAmountInCents = remoteOrderTotal?.isAvailable ? remoteOrderTotal?.taxAmountInCents ?? 0 : calculateTax(lineItems, taxRate, taxByItem)
  let subtotalAmountInCents = remoteOrderTotal?.subtotalInCents ?? calculateSubtotal(lineItems)
  let amountInCents = (remoteOrderTotal?.totalAmountInCents ?? subtotalAmountInCents + taxAmountInCents) + tipAmountInCents
  let serviceFee = { name: 'Service Charge', total: 0, legal: null }

  // Recalibrate the subtotal and total based on whether or not there is a remote total.
  if (!isNil(remoteOrderTotal?.totalAmountInCents)) {
    // When there is a remote total, the subtotal is present and properly calculated.
    serviceFee = { name: remoteOrderTotal?.serviceChargeName, total: remoteOrderTotal?.serviceChargeInCents ?? 0, legal: remoteOrderTotal.serviceChargeLegal }
  } else {
    // When there is no remote total, the service fee needs to be calculated and added into the
    // total but not the subtotal.
    const serviceCharge = yield select((state) => getMenuServiceCharge(state))
    serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal }

    taxAmountInCents += calculateIndividualTaxAmount(taxRate, serviceFee.total, serviceCharge.taxable)
    amountInCents = amountInCents + serviceFee.total
  }

  const userAttributes = makeUserAttributes(cardReaderData)
  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions) || !remoteOrderTotal.isAvailable) {
    promotions = undefined
  }

  const shortDescription = userAttributes?.freedompayIssuerName ? `${userAttributes?.freedompayIssuerName} ${userAttributes?.last_4}` : undefined

  yield put(setSafUploadComplete(false))
  yield put(setDataUploadComplete(false))

  const createOrderParams = {
    ...makeCreateOrderParams({
      amountInCents,
      tipAmountInCents,
      employeeId,
      isTab: false,
      itemModels,
      menuUuid,
      orderNumber,
      orderState,
      paymentType: 'freedompay_credit',
      submittedAt,
      uuid,
      affiliations: null,
      signature,
      promotions,
      userNotes,
      shortDescription,
      originalClientCreatedAt: getCreateOrderParams(order)?.clientCreatedAt,
    }),
    state: STADIUM_ORDER_STATES.APPROVED_OFFLINE,
    userAttributes: userAttributes,
    userName: getUserNameFromCard(cardReaderData),
  }

  const orderData = {
    amountInCents,
    tipAmountInCents,
    taxAmountInCents,
    subtotalAmountInCents,
    discountAmountInCents,
    balanceDueInCents: 0,
    serviceFee,
    cardReaderData,
    createOrderParams,
    itemModels,
    menuSubtype,
    orderNumber,
    paymentCompleted,
    revenueCenterUuid,
    revenueName,
    signature,
    submittedAt,
    uuid,
    invoiceNumber: userAttributes.freedompayInvoiceNumber,
    userNotes,
  }

  yield put(clearRemoteOrderTotal())
  yield put(saveOfflineOrder(orderData))
  if (remoteOrderTotal?.isAvailable) {
    yield put(syncOrderInitiated(uuid))
    yield put(syncOfflineOrderRequested(uuid))
  }
}

// We just sent a request to FreedomPay to replay all offline approvals fro mthe card reader.
// Here we check the status for particular orders after that is initiated.
export function* getReplayAuthStatus(params = {}) {
  // wait 10 seconds before starting to give replayRequests some time to run first
  // we should re-do this logic when we can get a response from the replayRequests call

  const id = params.orderId
  const order = yield select(getOrder, id)
  const cfdMode = yield select(getDeviceMode)

  const offlineRequestId = get(order, 'cardReaderData.requestId')
  if (!offlineRequestId) {
    yield put(replayAuthRequestFailed({ order, replayStatus: { statusDisplay: 'No request ID for card reader' } }))
    createDataDogLog('error', 'No request ID for card reader')
    return
  }

  const bridgeInstance = PeripheralBridge.getBridge()
  if (!bridgeInstance) {
    yield put(replayAuthRequestFailed({ order, replayStatus: { statusDisplay: 'Could not reach the card reader' } }))
    return
  }

  try {
    const checkStatusPromise = new Promise((resolve, reject) => {
      // we only run to turn on replayAuthStatus if the connected device has a CFD
      // due to the remote connection and having broadPOS on the other device
      if (cfdMode === MODES.POS) {
        bridgeInstance.registerHandler('replayAuthStatus', (data) => resolve(data))
        bridgeInstance.callHandler( 'checkStatus', { 'requestId': offlineRequestId })
      } else {
        bridgeInstance.callHandler( 'checkStatus', { 'requestId': offlineRequestId }, (data) => resolve(data))
      }

      setTimeout(() => {
        reject()
      }, MS_GATEWAY_FORCE_CONTINUE)
    })

    const statusData = yield checkStatusPromise
 
    // If an order's platform state is in completed status, it might mean that the order
    // was completed on a different device and we should abort this process to avoid overwriting
    // any changes that might not have synced/updated correctly to this device (tips, discounts, affiliations etc) on the order's createOrderParams
    // there may be a better mitigation for this in the future but would require more data such as promotions being retrieved by the fetch orders request
    if (
      order?.state === STADIUM_ORDER_STATES.COMPLETION_PENDING ||
      order?.state === STADIUM_ORDER_STATES.COMPLETED
    ) {
      yield put(saveOrder(order, false, true))
      return
    }

    if (!statusData) {
      yield put(replayAuthRequestFailed({ order, replayStatus: { statusDisplay: 'Request ID does not exists' } }))
      createDataDogLog('error', 'Request ID does not exists')
      return
    }

    const replayStatus = {
      ...statusData,
      statusDisplay: responseStatusString(statusData.responseStatus),
    }

    if (!statusCheckComplete(statusData)) {
      yield put(replayAuthRequestFailed({ order, replayStatus }))
      createDataDogLog('error', 'Replay Status Check Failed', { order, replayStatus })
      return
    }

    let createOrderParams = getCreateOrderParams(order)

    // update order state for stadium based on freedompay status
    const isDeclined = approvedOfflineThenDeclined(statusData)
    createOrderParams.state = isDeclined ? STADIUM_ORDER_STATES.AUTHORIZATION_FAILED : STADIUM_ORDER_STATES.COMPLETED

    if (
      isTabbed(order) &&
      getIsPreAuthTab(order) &&
      isOfflineApproval(order)
    ) {
      createOrderParams.state = STADIUM_ORDER_STATES.TABBED

      if (isDeclined)
        createOrderParams.state = STADIUM_ORDER_STATES.AUTHORIZATION_FAILED
    }
    // Need to do the below checks verbose-ly so that we don't mess up any
    // existing offline functionality with FreedomPay as this is maybe
    // PAX specific

    if (!isEmpty(statusData.authResponseData?.authorizationCode)) {
      createOrderParams.userAttributes.authorizationCode = statusData.authResponseData.authorizationCode
    }

    if (!isEmpty(statusData.receiptData?.approvalCode)) {
      createOrderParams.userAttributes.approvalCode = statusData.receiptData.approvalCode
    }

    if (!isEmpty(statusData.receiptData?.authStatus)) {
      createOrderParams.userAttributes.authStatus = statusData.receiptData.authStatus
    }

    if (!isEmpty(statusData.tokenInformation?.token)) {
      createOrderParams.userAttributes.token = statusData.tokenInformation.token
    }

    // need to update the freedom pay request id for refunds to work
    createOrderParams.userAttributes.freedompayRequestId = statusData.realRequestId
    createOrderParams.userAttributes.isOfflineApproval = false
    const cardReaderData = { ...order.cardReaderData, requestId: statusData.realRequestId, isOfflineApproval: false }

    yield put(saveOrder({ ...order, createOrderParams, cardReaderData, isDeclined }))
    yield put(replayAuthRequestSucceeded({ replayStatus, order }))
    if (!isTabbed(order)) {
      yield put(syncOfflineOrderRequested(id))
    } else {
      yield put(syncOrderRequested(id))
    }
  } catch (err) {
    yield put(replayAuthRequestFailed({ order, replayStatus: { statusDisplay: 'Check Status for order timed out' } }))
    createDataDogLog('error', 'Replay Auth Failed', { order, replayStatus: { statusDisplay: 'Check Status for order timed out' } })
    return
  }
}

// Update the unsyncability of an order if it has exceeded the maximum number of retries.
const updateNextSync = (order) => {
  const syncAttempt = order.syncAttempts ?? 0
  if (syncAttempt > 7) {
    order.unsyncable = true
  }
  order.syncAttempts = syncAttempt + 1

  return order
}

export function* syncOrders() {
  const orderIds = yield select(state => getOfflineLocalIds(state))

  if (orderIds.length > 0) {
    createDataDogLog('info', 'Sync Offline Local IDs Started', { offlineLocalIds: orderIds })
    ToastManager.info('A sync to VenueNext is beginning.', { autoClose: 2000 })
  }

  for (var i = 0; i < orderIds.length; i++) {
    var dataIsUploading = yield select(getDataIsUploading)
    if (!dataIsUploading) {
      return
    }

    try {
      yield put(syncOrderRequested(orderIds[i], undefined, undefined, false))
      yield delay(5000)
    } catch (error) {
      // TODO(mkramerl): Turn these into set error actions and display them in the UI.
      ToastManager.error(`Error platform syncing ${orderIds[i]} : ${error}`, { autoClose: 1500 })
      createDataDogLog('error', `Error platform syncing ${orderIds[i]} : ${error}`)
    }
  }

  yield put(setDataUploadComplete(true))
  yield put(onDataIsUploading(false))
}

export function* syncOrdersReplayAuth() {
  var orderIds = yield select(state => getOfflineCardIds(state))

  if (orderIds.length) {
    createDataDogLog('info', 'Sync Offline Card IDs Started', { offlineCardIds: orderIds })
  }

  for (var i = 0; i < orderIds.length; i++) {
    var safIsUploading = yield select(getSafIsUploading)
    if (!safIsUploading) {
      return
    }

    try {
      yield put(replayAuthRequested(orderIds[i], i === orderIds.length - 1))
      yield delay(5000)
    } catch (error) {
      // TODO(mkramerl): Turn these into set error actions and display them in the UI.
      ToastManager.error(`Error processor syncing ${orderIds[i]} : ${error}`, { autoClose: 1500 })
      createDataDogLog('error', `Error processor syncing ${orderIds[i]} : ${error}`)
    }
  }

  yield put(onSafIsUploading(false))
}

function* handleSyncSuccessRedirect (order, kioskDirectTo, isKiosk, isCFD) {
  const currentPaymentFlow = yield select(state => getCurrentPaymentFlow(state))

  if (kioskDirectTo && isKiosk) {
    yield call(kioskDirectTo, `/kiosk/order/summary/${order?.uuid}`)
  } else {
    if (isQRPay(order)) {
      ToastManager.success('Scan QR code successful')
    }
    if(!isCFD ||
      currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET ||
      currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.QR_PAY ||
      currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.GIFT_CARD) {
      yield call(directTo, 'push', `/receipt/${order?.uuid}`)
    }
  }
}

function* handleSyncFailedRedirect (order, kioskDirectTo, isKiosk, isQrPay) {
  if (kioskDirectTo && isKiosk) {
    yield call(kioskDirectTo, '/kiosk/order/new')
  } else {
    ToastManager.error(`Order ${order?.orderNumber} failed to sync successfully`)
    if (isQrPay) return
    yield call(directTo, 'push', `/concession-order`)
  }
}

// Create orders in stadium. Payment is completed (online approval or paid with cash).
export function* syncOrder(params = {}) {
  const redirect = params?.redirect ?? false
  const kioskDirectTo = params?.kioskDirectTo ?? (() => {})
  const displayOrderToast = params?.displayOrderToast
  let order = yield select(state => getOrder(state, params.id))

  if (!order || order.isPreOrder || order.unsyncable) return

  //if the order has no items at this point, the order is unsyncable and this process should be aborted
  if (isEmpty(getCreateOrderParams(order)?.orderMenuItems)) {
    order.unsyncable = true
    if (redirect) {
      yield call(handleSyncFailedRedirect, order, kioskDirectTo, isKiosk)
    } else {
      yield put(syncOrderFailed({ message: "This order has no line items." }, order))
    }
  }
  
  // close auth tab for
  // 1. start with auth, closed with same auth'd card
  // 2. start with auth, closed with a different payment
  if (
    (order.state === STADIUM_ORDER_STATES.COMPLETION_PENDING || getCreateOrderParams(order)?.state === STADIUM_ORDER_STATES.COMPLETED) &&
    !isQRPay(order) &&
    (
      getIsPreAuthTab(order) ||
      order.neededToBeVoidedAuthPayment
    ))
  {
    yield put(closeTabOrder({order, redirect}))
    return
  }

  // close auth tab for
  // 1. start with auth, closed with QR Pay
  // TODO [T35338]: use closeTabOrder above once T35336 is implemented
  if (order.neededToBeVoidedAuthPayment && isQRPay(order) && order.state === STADIUM_ORDER_STATES.COMPLETED) {
    // attempt to close to QR Pay
    const result = yield call(Remote.updatePosOrder, order.uuid, order)

    if (result.data?.state === STADIUM_ORDER_STATES.AUTHORIZATION_FAILED) {
      const failedOrder = {
        ...order,
        state: STADIUM_ORDER_STATES.AUTHORIZATION_FAILED
      }

      yield put(setQRPayAuthError(true))
      yield put(updateOrderFailed(order.uuid, failedOrder));
      yield call(handleSyncFailedRedirect, order, false, false, isQRPay(order))
      return
    }

    yield put(updateOrderSucceeded(result, order.uuid))
    yield call(directTo, 'push', `/receipt/${order?.uuid}`)

    return
  }

  const isKiosk = yield select(state => getIsKiosk(state))
  const cfdMode = yield select(getDeviceMode)
  const employeeId = getCreateOrderParams(order)?.employeeId || (yield verifyCurrentAttendant(isKiosk))
  if (employeeId === undefined) return

  let syncedOrder = {
    ...order,
  }

  // By the time the flow has gotten here, the assumption is we are online for Kiosk.
  const networkOnline = getNetworkAvailableValue()
  if (!isKiosk && !networkOnline) {
    yield put(syncOrderFailed({ message: "This device was offline while trying to sync." }, syncedOrder))
    return
  }
  // Order was created in stadium with another state (e.g. submitted, tabbed)
  if (order.wasCreatedInStadium && order.orderState !== INTERNAL_ORDER_STATES.FAILED) {
    try {
      let createOrderParams = getCreateOrderParams(order)

      if (
        order.orderState === INTERNAL_ORDER_STATES.CLOSED &&
        (order.state !== STADIUM_ORDER_STATES.TABBED_OFFLINE ||
          getCreateOrderParams(order)?.state !==
            STADIUM_ORDER_STATES.TABBED_OFFLINE)
      ) {
        createOrderParams.state = STADIUM_ORDER_STATES.COMPLETED
      }

      if (order.orderState === INTERNAL_ORDER_STATES.CANCELLED) {

        // Orders should not transition from completed to cancelled
        if (getCreateOrderParams(order)?.state === STADIUM_ORDER_STATES.COMPLETED) return

        createOrderParams.state = STADIUM_ORDER_STATES.CANCELLED
      }

      const result = yield call(Remote.updatePosOrder, order.uuid, createOrderParams, isKiosk)

      //Check for QR pay auth error and set flag to true
      if (
        result.data?.state === STADIUM_ORDER_STATES.AUTHORIZATION_FAILED &&
        order.payments?.[0].paymentType === PAYMENT_TYPES.QR_PAY
      ) {
        const updatedOrder = updateNextSync(order);

        yield put(syncOrderFailed(result, updatedOrder));
        yield put(setQRPayAuthError(true))

        return
      }

      yield put(updateOrderSucceeded(result, order.uuid))
      if (redirect) {
        yield call(handleSyncSuccessRedirect, order, kioskDirectTo, isKiosk, cfdMode === MODES.POS)
      }
      return
    } catch (err) {
      const applicationMessage = err?.response?.data?.errorMessage;
      const friendlyMessage = err?.response?.data?.friendlyMessage;
      const errorMessage = friendlyMessage ?? applicationMessage;
      const updatedOrder = updateNextSync(order);
      yield put(updateOrderFailed(order.uuid, updatedOrder, err));
      if (errorMessage && err?.message !== "Network Error") {
        ToastManager.error(`${errorMessage}`, { autoClose: false })
        createDataDogLog('error', errorMessage)
      }
      if (redirect) {
        yield call(handleSyncFailedRedirect, order, kioskDirectTo, isKiosk, isQRPay(order))
      }
    }

    if (order.orderState !== INTERNAL_ORDER_STATES.CLOSED) {
      return
    }
  }

  try {
    /**
     * Since platform for a kiosk order that already had a payment wont let us update it
     * we need to clone the existing order with new IDs in order for the POST endpoint can
     * create a new order with the new payment type. Right now the PUT endpoint for Kiosk orders
     * is not updating the payment for an order which already has a QR payment applied.
     */
     if (isKiosk && order.previousUnsyncableStatus) {
      //Generate new ID's
      const oldOrderId = order.uuid
      const newOrderId = generateUuid()

      //We need to increment the order for the new order
      incrementNextOrderNumber()
      const newOrderNumber = nextPosOrderNumber()

      //create new order
      yield put(duplicateOrderWithNewIds({oldOrderId, newOrderId, newOrderNumber}))
      yield put(setOrderInProgressWithId(newOrderId))

      //restore failed values to old order
      yield put(restoreQRPaymentandUnsyncableStatus(oldOrderId))

      const newOrder = yield select(state => getOrder(state, newOrderId))

      order = newOrder
    }

    // TODO(mkramerl): Use a more sophisticated method of detecting whether this was a tabbed order
    // that never made it to the core service.
    // If this was an already completed order that never made it to the core service, and it was
    // known to be a tabbed order, we need to create it in the core service in a tabbed state first.
    // After the order is created in a tabbed state, we save, exit, and restart the sync order process 
    // so that the order can be updated via PUT request. 
    const tabState = isOfflineApproval(order) ? STADIUM_ORDER_STATES.TABBED_OFFLINE : STADIUM_ORDER_STATES.TABBED
    if (!order.wasCreatedInStadium && order.state == STADIUM_ORDER_STATES.COMPLETED &&
        order.tabName !== null && order.tabName !== undefined) {
      const result = yield call(Remote.createPosOrder, {
        ...getCreateOrderParams(order),
        state: tabState,
        employeeId
      }, isKiosk)
      yield put(saveOrder({...order, wasCreatedInStadium: true}))
      yield put(syncOrderRequested(order.uuid, redirect, kioskDirectTo))
      return
    }

    // PPI
    yield put(setPreOrderPayments(order.uuid, getCreateOrderParams(order).payments))

    const result = yield call(Remote.createPosOrder, {
      ...getCreateOrderParams(order),
      employeeId
    }, isKiosk)
    const kitchenPrintingEnabled = yield select(state => getKitchenPrintingEnabled(state))

    // If the order is in pre-auth don't show the order toast.
    if(displayOrderToast && order.orderState !== INTERNAL_ORDER_STATES.PREAUTH &&
        order.orderState !== INTERNAL_ORDER_STATES.FAILED &&
        order.state !== STADIUM_ORDER_STATES.CANCELLED &&
        getCreateOrderParams(order)?.state !== STADIUM_ORDER_STATES.CANCELLED) {
      presentOrderToast(kitchenPrintingEnabled, result)
    }

    // PPI
    if (result?.data?.order) {
      yield put(setOrderPayments(result.data.order.uuid, result.data.order.paymentTransactions))
    }

    const latestOrder = yield select(state => getOrder(state, params.id))
    syncedOrder = { ...latestOrder }

    if (redirect) {
      yield call(handleSyncSuccessRedirect, order, kioskDirectTo, isKiosk, cfdMode === MODES.POS)
    }
    yield put(syncOrderSucceeded(result, syncedOrder))
  } catch (err) {
    const applicationMessage = err?.response?.data?.errorMessage ?? ''

    const QRPayFailedTransaction =
      applicationMessage?.includes('Nonce charge failed.') ||
      applicationMessage?.includes('Wallet charge failed.')

    if (applicationMessage?.includes("You must login to create orders!")) {
      yield call(directTo, 'push', '/user-login')
      ToastManager.error(APPLICATION_STRINGS.PLEASE_LOGIN)
      createDataDogLog('error', APPLICATION_STRINGS.PLEASE_LOGIN)
    }

    const friendlyMessage = err?.response?.data?.friendlyMessage;
    const errorMessage = friendlyMessage ?? applicationMessage;

    syncedOrder = updateNextSync(syncedOrder)
    yield put(syncOrderFailed(err, syncedOrder));

    if (errorMessage && err?.message !== "Network Error" && !QRPayFailedTransaction) {
      ToastManager.error(`${errorMessage}`, { autoClose: false })
      createDataDogLog('error', errorMessage)
    }

    /**
     * if we have a transaction declined from QRPay we want to stay in the same screen and let
     * the user apply another payment, then we need to stop the redirect to the menu from happening
     */
    if (QRPayFailedTransaction) {
      yield put(setQRPayAuthError(true))
      return
    }
    if (redirect) {
      yield call(handleSyncFailedRedirect, order, kioskDirectTo, isKiosk, isQRPay(order))
    }
  }
}

// Create order in stadium if payment is approved offline, or if the initial create request failed.
// Update order in stadium if payment was approved offline and is now finished (either failed or
// approved from card reader)
// For updates, we need to make sure the order is first created in Stadium so we have an
// offline_approval state transition
export function* syncOfflineOrder(params = {}) {
  // TODO (mkramerl): Do something here to indicate that the order can not be updated due to
  // network status.
  if (!getNetworkAvailableValue()) return

  const order = yield select(state => getOrder(state, params.id))

  if (isTabbed(order)) return

  if (!order || order.unsyncable) return

  const isKiosk = yield select(state => getIsKiosk(state))

  // Order was created in stadium with offline_approval state; need to update it
  if (order.wasCreatedInStadium) {
    try {
      const result = yield call(Remote.updatePosOrder, order.uuid, getCreateOrderParams(order), isKiosk)
      yield put(updateOrderSucceeded(result, order.uuid))
    } catch (err) {
      const applicationMessage = err?.response?.data?.errorMessage
      const friendlyMessage = err?.response?.data?.friendlyMessage
      const errorMessage = friendlyMessage ?? applicationMessage
      const updatedOrder = updateNextSync(order)

      yield put(updateOrderFailed(order.uuid, updatedOrder, err))

      if (errorMessage && err?.message !== "Network Error") {
        ToastManager.error(`${errorMessage}`, { autoClose: false })
        createDataDogLog('error', errorMessage)
      }
    }

    return
  }

  // If the order was not created in stadium, we need to create it; otherwise, this is a second
  // attempt at creation and should be ignored.
  if (order.wasCreatedInStadium) return
  const finished = isFinalState(getCreateOrderParams(order).state)
  if (finished) return

  // Only verify the employee if this is actually a create action.
  const employeeId = getCreateOrderParams(order)?.employeeId || (yield verifyCurrentAttendant(isKiosk))
  if (employeeId === undefined) return

  // PPI
  yield put(setPreOrderPayments(order.uuid, getCreateOrderParams(order).payments))

  try {
    const result = yield call(Remote.createPosOrder, {
      ...getCreateOrderParams(order),
      employeeId,
      state: STADIUM_ORDER_STATES.APPROVED_OFFLINE
    }, isKiosk)
    const kitchenPrintingEnabled = yield select(state => getKitchenPrintingEnabled(state))
    presentOrderToast(kitchenPrintingEnabled, result)

    // PPI
    if (result?.data?.order) {
      yield put(setOrderPayments(result.data.order.uuid, result.data.order.paymentTransactions))
    }

    yield put(createOfflineOrderSucceeded(result, order.uuid))
  } catch (err) {
    const applicationMessage = err?.response?.data?.errorMessage ?? ''

    if (applicationMessage?.includes("You must login to create orders!")) {
      yield call(directTo, 'push', '/user-login')
      ToastManager.error(APPLICATION_STRINGS.PLEASE_LOGIN)
      createDataDogLog('error', APPLICATION_STRINGS.PLEASE_LOGIN)
    }

    const friendlyMessage = err?.response?.data?.friendlyMessage
    const errorMessage = friendlyMessage ?? applicationMessage
    const updatedOrder = updateNextSync(order)

    yield put(createOfflineOrderFailed(order.uuid, updatedOrder, err))

    if (errorMessage && err?.message !== "Network Error") {
      ToastManager.error(`${errorMessage}`, { autoClose: false })
      createDataDogLog('error', errorMessage)
    }
  }
}

export function* _saveRichCheckoutOrder(params = {}) {
  const isKiosk = get(params, 'payload.isKiosk', false)
  const employeeId = yield verifyCurrentAttendant(isKiosk)
  if (employeeId === undefined) return

  const uuid = yield select((state) => getOrderSubumissionUuid(state, params?.payload.uuid))
  const orderNumber = get(params, 'payload.orderNumber', nextPosOrderNumber())

  const { menuUuid, revenueCenterUuid, revenueName } = yield menuAndRvcUuids(isKiosk)

  const lineItems = yield select(getCartItems)
  const userNotes = yield select(getCartNotes)
  const itemModels = makeLineItemData(lineItems)

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)

  const remoteOrderTotal = yield select(getRemoteOrderTotal)
  const discountAmountInCents = remoteOrderTotal?.discountAmountInCents ?? 0

  let taxAmountInCents =  remoteOrderTotal?.isAvailable ? remoteOrderTotal?.taxAmountInCents ?? 0 : calculateTax(lineItems, taxRate, taxByItem)
  let subtotalAmountInCents = remoteOrderTotal?.subtotalInCents ?? calculateSubtotal(lineItems)
  let amountInCents =  remoteOrderTotal?.totalAmountInCents ?? subtotalAmountInCents + taxAmountInCents
  let serviceFee = { name: 'Service Charge', total: 0, legal: null }

  // Recalibrate the subtotal and total based on whether or not there is a remote total.
  if (!isNil(remoteOrderTotal?.totalAmountInCents)) {
    // When there is a remote total, the subtotal is present and properly calculated.
    serviceFee = { name: remoteOrderTotal?.serviceChargeName, total: remoteOrderTotal?.serviceChargeInCents ?? 0, legal: remoteOrderTotal.serviceChargeLegal }
  } else {
    // When there is no remote total, the service fee needs to be calculated and added into the
    // total but not the subtotal.
    const serviceCharge = yield select((state) => getMenuServiceCharge(state))
    serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal }

    taxAmountInCents += calculateIndividualTaxAmount(taxRate, serviceFee.total, serviceCharge.taxable)
    amountInCents = amountInCents + serviceFee.total
  }

  const affiliations = remoteOrderTotal?.isAvailable ? [] : null
  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions) || !remoteOrderTotal.isAvailable) {
    promotions = undefined
  }

  const createOrderParams = {
    ...makeCreateOrderParams({
      amountInCents,
      employeeId,
      itemModels,
      menuUuid,
      orderNumber,
      paymentType: 'mobile_app',
      submittedAt: new Date(),
      uuid,
      affiliations,
      promotions,
      userNotes,
    })
  }

  const orderData = {
    amountInCents,
    subtotalAmountInCents,
    discountAmountInCents,
    taxAmountInCents,
    balanceDueInCents: 0,
    serviceFee,
    createOrderParams,
    itemModels,
    orderNumber,
    paymentCompleted: true,
    revenueCenterUuid,
    revenueName,
    submittedAt: new Date(),
    uuid,
    userNotes,
  }

  yield put(saveRichCheckoutOrder(orderData))
  yield put(clearRemoteOrderTotal())
}

export function* addPaymentToOrderInProgressSaga(params = {}) {
  const paymentId = params?.payload?.paymentId ?? ''
  const paymentType = params?.payload?.paymentType
  const tenderAmountInCents = params?.payload?.tenderAmountInCents
  const signature = params?.payload?.signature
  const tipAmountInCents = params?.payload?.tipAmountInCents ?? 0
  const cardReaderData = params?.payload?.cardReaderData
  const shortDescription = params?.payload?.shortDescription
  const remainingTicketBalance = params?.payload?.remainingTicketBalance
  const isApprovedOffline = params?.payload?.isApprovedOffline
  const token = params?.payload?.token
  const push = params?.payload?.push
  const navigateHistory = params?.payload?.navigateHistory ?? true

  let userAttributes = { isOverageCard: false }
  let userName
  if (cardReaderData) {
    userAttributes = makeUserAttributes(cardReaderData)
    userName = getUserNameFromCard(cardReaderData)
  }

  const currentPaymentFlow = yield select(state => getCurrentPaymentFlow(state))
  const order = yield select(state => getOrderInProgress(state))

  // if the order has an auth payment, reset the payments array to empty and store the auth'd payment to be voided later at close
  const isPreAuthTab = getIsPreAuthTab(order)
  const neededToBeVoidedAuthPayment =
  order.neededToBeVoidedAuthPayment ||
  (isPreAuthTab &&
    !isOfflineApproval(order) &&
    order.payments)
  const orderPayments = isPreAuthTab ? [] : order.payments || []
  const newCardReaderData = isPreAuthTab ? null : order.cardReaderData

  const deviceMode = yield select(state => getDeviceMode(state))
  const balanceDueInCents = order?.balanceDueInCents - tenderAmountInCents
  const orderNumber = cardReaderData?.orderNumber ?? params?.payload?.orderNumber ?? nextPosOrderNumber()
  const orderUpdate = {
    balanceDueInCents,
    tipAmountInCents: order?.tipAmountInCents + tipAmountInCents,
    amountInCents: order?.amountInCents + tipAmountInCents,
    neededToBeVoidedAuthPayment,
    cardReaderData: newCardReaderData,
  }

  if (isPreAuthTab) {
    orderUpdate.paymentAuthorizedAt = null
  }

  let newPayment = {
    paymentId,
    paymentType,
    amountInCents: tenderAmountInCents + tipAmountInCents,
    orderNumber,
    shortDescription,
    tipAmountInCents,
    token,
    userAttributes,
  }

  if (paymentType === PAYMENT_TYPES.CREDIT_CARD) {
    newPayment.userName = userName
    newPayment.signature = signature
    newPayment.cardType = userAttributes.freedompayIssuerName
    newPayment.label = cardReaderData?.cardType
    newPayment.shortDescription = `${userAttributes?.freedompayIssuerName} ${userAttributes?.last_4}`
    orderUpdate.receiptSignature = signature
    orderUpdate.isApprovedOffline = isApprovedOffline
    orderUpdate.cardReaderData = cardReaderData
  }

  if (paymentType === PAYMENT_TYPES.TICKET || paymentTypeIsPpi(paymentType)) {
    orderUpdate.remainingTicketBalance = remainingTicketBalance
  }

  if (paymentType === PAYMENT_TYPES.CASH && isOfflineApproval(order?.cardReaderData)) {
    orderUpdate.isApprovedOffline = true
  }

  if (paymentTypeIsPpi(paymentType) && params?.payload?.userAttributes) {
    newPayment.userAttributes = params.payload.userAttributes
  }

  if (params?.payload?.isRichCheckout) {
    orderUpdate.isRichCheckout = true
  }

  //tab orders from other devices will not have the payments array initialized, then we need to handle that scenario.
  orderUpdate.payments = [
    ...orderPayments,
    newPayment,
  ]

  // Always keep the in-state order number in sync with the creation parameters. In the case that
  // the order hasn't been created, it can be assumed the current order number is the most accurate.
  orderUpdate.orderNumber = getCreateOrderParams(order)?.orderNumber ?? order?.orderNumber ?? orderNumber

  if (orderUpdate.payments.length > 1) {
    orderUpdate.isMultiPayment = true
    orderUpdate.displayName = "Multiple Tenders"
  } else {
    orderUpdate.displayName = shortDescription
  }

  yield put(updateOrderInProgress(orderUpdate))
  if (balanceDueInCents === 0) {
    yield call(syncAndSaveOrderInProgress, push)

    // this must be done before syncAndSaveOrder because it causes Android to slow down and nothing to render
    if(deviceMode === MODES.POS && (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.QR_PAY ||
      currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET ||
      currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.GIFT_CARD)) {
      bridge_setCFDScreen(
        CFD_SCREEN.RECEIPT,
        { cartTotals: { uuid: order?.uuid },
        transaction: { amount: order?.amountInCents ? (order?.amountInCents / 100) : 0 } }
      )
      yield put(setCustomerViewingReceiptDialog(true))
    }
    // doubling this up since this only seems to be needed with a CFD attached
    if (deviceMode === MODES.POS && currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
      if (navigateHistory) {
        yield call(directTo, 'push', `/receipt/split-tender/${paymentId}`)
        return
      }
    }
  } else
  if (paymentId) {
    if (navigateHistory) {
      yield call(directTo, 'push', `/receipt/split-tender/${paymentId}`)
    }
  }
}

export function* syncAndSaveOrderInProgress(push) {
  const isKiosk = yield select(state => getIsKiosk(state))
  const { menuUuid, revenueCenterUuid, revenueName } = yield menuAndRvcUuids(isKiosk)
  const cfdMode = yield select(state => getDeviceMode(state))
  const isNetworkAvailable = getNetworkAvailableValue()

  const order = yield select(state => getOrderInProgress(state))

  const employeeId = yield verifyCurrentAttendant(isKiosk)

  if (employeeId === undefined) return

  const payments = map(order?.payments, (payment) => {
    const tender = {
      paymentType: payment.paymentType,
      amountInCents: payment.amountInCents,
      shortDescription: payment.shortDescription,
      token: payment.token,
    }
    if (payment.userAttributes) {
      tender.userAttributes = payment.userAttributes
      tender.userName = payment.userName
    }

    return tender
  })

  const submittedAt = new Date()
  let promotions = yield select(getSelectedPromotions)
  if (isEmpty(promotions) || !order.hasRemoteTotal) {
    promotions = undefined
  }

  // Perhaps this is another attempt to trigger the creation of an order already in our DB.
  const existingOrder = yield select(getOrder, order.uuid)
  // If createOrderParams already exists, maintain the integrity of the order number from the
  // database.
  const orderNumber = getCreateOrderParams(existingOrder)?.orderNumber ?? order.orderNumber

  let userNotes = order?.userNotes
  if (!userNotes) {
    userNotes = yield select(getCartNotes)
  }
  let newOrderState = INTERNAL_ORDER_STATES.CLOSED
  let orderParamsState = STADIUM_ORDER_STATES.COMPLETED

  const removeUuidFromOfflineCardSync = !isOfflineApproval(order)

  if (order?.isRichCheckout) {
    newOrderState = undefined
  }

  // [T35327] Should not update order states for Kiosk
  if (isKiosk) {
    newOrderState = getCreateOrderParams(order)?.state
    orderParamsState = getCreateOrderParams(order)?.state
  }

  const createOrderParams = {
    ...makeCreateOrderParams({
      amountInCents: order.amountInCents,
      tipAmountInCents: order.tipAmountInCents,
      discountAmountInCents: order.discountAmountInCents,
      employeeId,
      itemModels: order.itemModels,
      menuUuid,
      // If createOrderParams already exists, maintain the integrity of the order number from the
      // database.
      orderNumber: getCreateOrderParams(existingOrder)?.orderNumber ?? order.orderNumber,
      submittedAt,
      uuid: order.uuid,
      affiliations: order?.affiliations,
      promotions,
      userNotes,
      orderState: newOrderState,
      originalClientCreatedAt: getCreateOrderParams(order)?.clientCreatedAt,
    }),
    payments,
    state: orderParamsState,
    userName: order?.tabName || payments?.[0]?.userName,
  }

  if (!order?.isMultiPayment && payments?.[0]?.userAttributes) {
    if (getIsPreAuthTab(order)) {
      createOrderParams.userAttributes = {
        ...(getCreateOrderParams(order)?.userAttributes ??
          order?.userAttributes ??
          {}),
      }
    } else {
      createOrderParams.userAttributes = { ...payments?.[0]?.userAttributes }
    }
    payments[0].userAttributes = null
  }

  const orderData = {
    ...order,
    createOrderParams,
    paymentCompleted: true,
    submittedAt,
    revenueName,
    revenueCenterUuid,
    orderNumber,
    state: createOrderParams?.state,
    orderState: newOrderState,
    balanceDueInCents: 0,
  }

  if (order?.payments?.length === 1) {
    orderData.displayName = order?.payments?.[0]?.shortDescription
  }

  // if a tabbed order is approved offline, needs to be saved as approved offline order
  if (order?.isApprovedOffline) {
    yield put(saveOfflineOrder(orderData))
  } else {
    yield put(saveOrder(orderData, true, removeUuidFromOfflineCardSync))
  }

  yield put(clearRemoteOrderTotal())
  yield put(clearCart())

  if (!order?.isRichCheckout) {
    yield put(syncOrderInitiated(order?.uuid))
    yield call(syncOrder, {id: order?.uuid, redirect: true, kioskDirectTo: push})
  }

  const QRpayError = yield select(getQRPayAuthError)

  if (QRpayError && order.payments?.[0].paymentType === "wallet_nonce") return

  if (cfdMode !== MODES.POS && !isKiosk) {
    yield put(clearOrderInProgress())
  }

  // if tabbed order has not been created in stadium, in case device is offline,
  // redirect to receipt to show that the tab has been completed
  // Question Pending.
  if (isTabbed(order) && (!isNetworkAvailable || cfdMode === MODES.POS)) {
    yield call(directTo, 'push', `/receipt/${order?.uuid}`)
  }
}

// removes payment from an order order
export function* removePaymentSaga(params = {}) {
  const paymentIdx = params?.paymentIndex
  const silentFail = params?.silentFail
  const order = params?.order
  const payment = order?.neededToBeVoidedAuthPayment?.[paymentIdx] ??  order?.payments?.[paymentIdx]

  const payload = {
    orderId: order?.uuid,
    paymentId: payment?.paymentId,
  }

  const result = yield call(MutationApi.removePayment, payload)
  const curMutation = result?.data?.orderMutations?.[0] ?? {applied: false}
  const applied = curMutation.applied

  if (applied) {
    const updatedOrder = {
      ...order,
      payments: order.payments.filter((_, idx) => idx !== paymentIdx),
    }
    if (payment.paymentType.search('credit') !== -1) {
      updatedOrder.cardReaderData = null
      updatedOrder.paymentAuthorizedAt = null
    }
    yield put(saveOrder(updatedOrder))
    return curMutation.uuid
  }

  if (!silentFail) {
    const failedOrder = {
      ...order,
      state: STADIUM_ORDER_STATES.AUTHORIZATION_FAILED
    }
    ToastManager.error(APPLICATION_STRINGS.VOID_PAYMENT_FAILED)
    createDataDogLog('error', curMutation.application_message, { orderUuid: order?.uuid })
    yield put(syncOrderFailed({message: 'Remove Payment Mutation Not Applied'}, failedOrder))
    yield put(updateOrderFailed(order.uuid, failedOrder));
    return
  }

  return curMutation.uuid
}

// This method for voiding payments might be temporary until platform adjustments have been made to improve
// the closeTab mutation to accept multiple payments, allocates tip amounts, and deducts VC appropriately.
export function* addNewPaymentsSaga(params = {}) {
  const orderUuid = params?.payload?.uuid
  const order = yield select(state => getOrder(state, orderUuid))
  const employeeId = getCreateOrderParams(order)?.employeeId

  if (!order.wasCreatedInStadium) {
    try {
      yield call(Remote.createPosOrder, { ...getCreateOrderParams(order), payments: order?.voidedCardReaderPayment, employeeId }, false)
      yield put(saveOrder({...order, wasCreatedInStadium: true}))
    } catch {
      return
    }
  }

  let newOrderData = order
  try {
    const payload = {
      orderId: orderUuid,
      payment: getCreateOrderParams(order)?.payments[0] ?? order?.payments[0],
    }

    const result = yield call(MutationApi.addPayment, payload)
    const applied = result?.data?.orderMutations?.[0]?.applied
    if (!applied) {
      ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
      yield call(directTo, 'push', `/orders`)
      return
    }

    // call order details to retrieve recently voided payment (will be now be for 0 amount in cents)
    const resultOrderDetails = yield call(Remote.getOrderDetails, orderUuid)
    getCreateOrderParams(newOrderData).payments = [
      resultOrderDetails?.data?.paymentInfo?.[0],
      ...getCreateOrderParams(order).payments,
    ]
    newOrderData.neededToBeVoidedAuthPayment = undefined

  } catch {
    ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
    yield call(directTo, 'push', `/orders`)
    return
  }

  yield put(saveOrder(newOrderData))
  if (!order?.isRichCheckout) {
    yield put(syncOrderRequested(orderUuid, true, (location) => call(directTo, 'push', location)))
  }
}

export function* updateSavedPaymentSaga(params = {}) {
  const tipAmountInCents = params?.payload?.tipAmountInCents ?? 0
  const signature = params?.payload?.signature
  const order = yield select(state => getOrderInProgress(state))
  const amountInCents = order?.amountInCents + tipAmountInCents
  const orderUuid = order?.uuid
  const isNetworkAvailable = getNetworkAvailableValue()

  if (isNetworkAvailable && order?.wasCreatedInStadium && tipAmountInCents > 0) {
    try {

      let paymentUuid = order?.paymentInfo?.[0]?.uuid
      if (!paymentUuid) {
        const resultOrderDetails = yield call(Remote.getOrderDetails, orderUuid)
        paymentUuid = resultOrderDetails?.data?.paymentInfo?.[0]
      }

      const payload = {
        orderId: orderUuid,
        paymentUuid,
        tip: tipAmountInCents,
        amountInCents,
        signature,
      }

      const result = yield call(MutationApi.updatePayment, payload)
      const applied = result?.data?.orderMutations?.[0]?.applied
      if (!applied) {
        ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
        yield call(directTo, 'push', `/orders`)
        return
      }
    } catch {
      ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
      yield call(directTo, 'push', `/orders`)
      return
    }
  }

  const payments = [{
    ...order?.payments?.[0],
    amountInCents,
    tip: tipAmountInCents,
    signature,
  }]

  const createOrderParams = {
    ...getCreateOrderParams(order),
    payments,
  }

  yield put(updateOrderInProgress({ ...order, balanceDueInCents: 0, tipAmountInCents, amountInCents, signature, createOrderParams, payments }))
  yield call(syncAndSaveOrderInProgress)
}

// closeTabOrderSaga currently disabled, possibly to deprecate in the near future TODO (JEFF)
export function* closeTabOrderSaga(params = {}) {
  const order = params?.payload?.order
  const redirect = params?.payload.redirect ?? false
  const orderUuid = order?.uuid
  const cfdMode = yield select(state => getDeviceMode(state))
  const employeeId = getCreateOrderParams(order)?.employeeId

  const authPayment = order?.neededToBeVoidedAuthPayment?.[0] ?? getCreateOrderParams(order)?.payments?.[0] ?? {}
  if (!order.wasCreatedInStadium) {
    const state = isOfflineApproval(order) ? STADIUM_ORDER_STATES.TABBED_OFFLINE : STADIUM_ORDER_STATES.TABBED
    try {
      yield call(Remote.createPosOrder, { ...getCreateOrderParams(order), payments: [authPayment], employeeId, state, amountInCents: authPayment?.amountInCents }, false)
      yield put(saveOrder({...order, wasCreatedInStadium: true}))
    } catch (err) {
      yield put(syncOrderFailed( err, order))
      return
    }
  }

  /**
   * Should call addPayment mutation when tabbed_offline
   *
   * 'tabbed' - is online or started without payment
   * 'tabbed_offline' - started with a payment
   */
  const hasNewPayments = order.state === STADIUM_ORDER_STATES.TABBED_OFFLINE && order?.originalTransactionId && order.originalTransactionId !== order?.cardReaderData?.uuid
  const payments = order?.payments || getCreateOrderParams(order)?.payments

  // TODO: refactor with explicit flags
  // ONLINE transactions - neededToBeVoidedAuthPayment - should removePayment and addPayument
  // OFFLINE transactions - hasNewPayments - should addPayment, but not remove
  if (order?.neededToBeVoidedAuthPayment || hasNewPayments) {
    try {
      if (order?.neededToBeVoidedAuthPayment) {
        const payload = {
          orderId: order?.uuid,
          paymentId: order?.neededToBeVoidedAuthPayment?.[0]?.paymentId
        }

        const result = yield call(MutationApi.removePayment, payload)
        const applied = result?.data?.orderMutations?.[0]?.applied

        if (!applied) {
          ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
          yield put(syncOrderFailed({message: 'Remove Payment Mutation Not Applied'}, order))
          if (redirect) {
            yield call(directTo, 'push', `/concession-order`)
          }
          return
        }
      }
    } catch (error) {
      ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
      yield put(syncOrderFailed(error, order))
      if (redirect) {
        yield call(directTo, 'push', `/concession-order`)
      }
      return
    }
    let tipAmountInCentsRemaining = order?.tipAmountInCents
    for (let index = 0; index < payments.length; index++) {
      const payment = payments[index];
      try {
        const payload = {
          orderId: order?.uuid,
          payment: payment
        }

        if (payment.amountInCents > tipAmountInCentsRemaining) {
          payload.payment.tip = tipAmountInCentsRemaining
          tipAmountInCentsRemaining = 0
        } else if (payment.amountInCents <= tipAmountInCentsRemaining) {
          // we need to add 1 cent to the tip to avoid the platform rejecting the payment because
          // of fraud alerts
          payload.payment.tip = (payment.amountInCents) - 1
          tipAmountInCentsRemaining = (tipAmountInCentsRemaining - payment.amountInCents) + 1
        }

        const result = yield call(MutationApi.addPayment, payload)
        const applied = result?.data?.orderMutations?.[0]?.applied

        if (!applied) {
          ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
          yield put(syncOrderFailed({message: 'Add Payment Mutation Not Applied'}, order))
          if (redirect) {
            yield call(directTo, 'push', `/concession-order`)
          }
          return
        }
        order.neededToBeVoidedAuthPayment = undefined
      } catch (err) {
        ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
        yield put(syncOrderFailed( err, order))
        if (redirect) {
          yield call(directTo, 'push', `/concession-order`)
        }
        return
      }
    }
  }

  let closePayload = {
    orderId: orderUuid,
    receiptSignature: order?.receiptSignature,
    tipAmountInCents: order?.tipAmountInCents,
    userName: getCreateOrderParams(order)?.userName,
  }

  try {
    const result = yield call(MutationApi.closeTabOrder, closePayload)
    const applied = result?.data?.orderMutations?.[0]?.applied

    if (applied) {
      yield put(saveOrder(order, false))
      yield put(syncOrderSucceeded(result, order))

      ToastManager.success(APPLICATION_STRINGS.CLOSE_TAB_SUCCEEDED)
      yield put(orderDetailsRequested(orderUuid))
      if (redirect) {
        yield call(directTo, 'push', `/receipt/${orderUuid}`)
      }
    } else {
      ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
      yield put(syncOrderFailed({message: 'Close Tab Mutation Not Applied'}, order))
      if (redirect) {
        yield call(directTo, 'push', `/concession-order`)
      }
    }
  } catch (err) {
    ToastManager.error(APPLICATION_STRINGS.CLOSE_TAB_FAILED)
    yield put(syncOrderFailed( err, order))
    if (redirect) {
      yield call(directTo, 'push', `/concession-order`)
    }
  }

  if (cfdMode !== MODES.POS) {
    yield put(clearOrderInProgress())
  }
}

export function* closePreAuthedRCOrderSaga(params = {}) {
  const uuid = params?.payload?.uuid
  const cfdMode = yield select(state => getDeviceMode(state))
  /**
   * We assume that the auth payment is dropped in the backend when the RC payment
   * is applied. Then we just fetch order details which should update all our order local state.
   */

  yield put(orderDetailsRequested(uuid))
  yield call(directTo, 'push', `/receipt/${uuid}`)

  if (cfdMode !== MODES.POS) {
    yield put(clearOrderInProgress())
}}

export function* watchSyncReplayAuthOrders() {
  yield takeLeading(SYNC_REPLAY_AUTH_ORDERS_REQUESTED, syncOrdersReplayAuth)
}

export function* watchReplayAuthStatus() {
  const queue = yield actionChannel(REPLAY_AUTH_REQUESTED)

  while (true) {
    const payload = yield take(queue)

    yield put(syncOrderInitiated(payload.orderId))
    yield call(getReplayAuthStatus, payload)
  }
}

export function* watchStartATab() {
  yield takeEvery(START_A_TAB, startATab)
}

export function* watchCloseTabOrder() {
  yield takeEvery(CLOSE_TAB_ORDER, closeTabOrderSaga)
}

export function* watchAddItemsToTab() {
  yield takeEvery(ADD_ITEMS_TO_TAB, addItemsToTab)
}

export function* watchAddItemsToTabOffline() {
  yield takeEvery(ADD_ITEMS_TO_TAB_OFFLINE, addItemsToTabOffline)
}

export function* watchAddDiscountsToTabOrder() {
  yield takeEvery(ADD_DISCOUNTS_TO_TAB_ORDER, addDiscountsToTabOrderSaga)
}

export function* watchDeleteDiscountsFromTabOrder() {
  yield takeEvery(DELETE_DISCOUNTS_FROM_TAB_ORDER, deleteDiscountsFromTabOrderSaga)
}


export function* watchStartOfflineCardOrder() {
  yield takeEvery(START_OFFLINE_CARD_ORDER, startOfflineCardOrder)
}

export function* watchStartOnlineCardOrder() {
  yield takeEvery(START_ONLINE_CARD_ORDER, startOnlineCardOrder)
}

export function* watchSaveRichCheckoutOrder() {
  yield takeEvery(SAVE_RICH_CHECKOUT_ORDER_REQUESTED, _saveRichCheckoutOrder)
}

export function* watchCloseNoOpTender() {
  yield takeEvery(CLOSE_NO_OP_TENDER, closeNoOpTenderSaga)
}

export function* watchSyncOrder() {
  const queue = yield actionChannel(SYNC_ORDER_REQUESTED)

  while (true) {
    const payload = yield take(queue)

    yield put(syncOrderInitiated(payload.id))
    yield call(syncOrder, payload)
  }
}

export function* watchSyncOfflineOrder() {
  const queue = yield actionChannel(SYNC_OFFLINE_ORDER_REQUESTED)

  while (true) {
    const payload = yield take(queue)

    yield put(syncOrderInitiated(payload.id))
    yield call(syncOfflineOrder, payload)
  }
}

export function* watchSyncAndSaveOrderInProgress() {
  yield takeLeading(SYNC_AND_SAVE_ORDER_IN_PROGRESS, syncAndSaveOrderInProgress)
}

export function* watchUpdateCardReaderDataSaga() {
  yield takeEvery(UPDATE_CARD_READER_DATA, updateCardReaderDataSaga)
}

export function* watchSyncOrders() {
  yield takeLeading(SYNC_ORDERS_REQUESTED, syncOrders)
}

export function* watchAddPaymentToOrderInProgress() {
  yield takeLeading(ADD_PAYMENT_TO_ORDER_IN_PROGRESS, addPaymentToOrderInProgressSaga)
}

export function* watchAddNewPayments() {
  yield takeLeading(ADD_NEW_PAYMENTS, addNewPaymentsSaga)
}

export function* watchRemovePayment() {
  yield takeLeading(REMOVE_PAYMENT, removePaymentSaga)
}

export function* watchUpdateSavedPayment() {
  yield takeLeading(UPDATE_SAVED_PAYMENT, updateSavedPaymentSaga)
}

export function* getTabOrders() {
  if (!getNetworkAvailableValue()) return

  const venueUuid = yield select((state) => get(state, 'config.venueUuid', ''))
  const standMenuUuids = yield select((state) => get(state, 'config.configuration.menuUuids', []))

  const updatedSince = yield select(state => getUpdatedSince(state))
  yield put(setUpdatedSince())

  let requestPayload = { venueUuid, standMenuUuids, updatedSince }

  try {
    const result = yield call(Remote.getOrders, requestPayload)
    const mappedResult = DataMapper.ordersMapper(get(result, 'data', []))

    yield put(loadTabOrdersSucceeded(mappedResult))
  } catch (err) {
    yield put(loadTabOrdersFailed(err))
    ToastManager.error("Orders failed to load")
  }
}

export function* watchGetTabOrders() {
  yield takeLeading(TAB_ORDERS_REQUESTED, getTabOrders)
}

export function* getOrderDetails(params = {}) {
  let order = undefined

  try {
    order = yield select(getOrder, params.payload.orderUuid)

    const result = yield call(Remote.getOrderDetails, params.payload.orderUuid)

    const items = yield select((state) => getItemsForCurrentMenu(state))
    const mappedResult = DataMapper.orderDetailsMapper(get(result, 'data', {}), items?.byId ?? {})

    yield put(loadOrderDetailsSucceeded(mappedResult))
  } catch (err) {
    if (order?.errorMessage) {
      ToastManager.error(`Order Number ${order?.orderNumber}: ${order.errorMessage}`)
    }
    yield put(loadOrderDetailsFailed({ orderUuid: params.payload.orderUuid }))
  }
}

export function* watchGetOrderDetails() {
  const queue = yield actionChannel(ORDER_DETAILS_REQUESTED)

  while (true) {
    const payload = yield take(queue)

    yield call(getOrderDetails, payload)
  }
}

export function* watchClosePreAuthedRCOrder() {
  yield takeEvery(CLOSE_PRE_AUTHED_RC_ORDER, closePreAuthedRCOrderSaga)
}
