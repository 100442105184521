import React from 'react'
import cn from 'classnames'

import { ReactComponent as CloseIcon } from './assets/close-dialog-icon.svg'

import styles from './Dialog.module.scss'

const Dialog = ({ title, message, children, onClose, classNames, isKiosk }) => {
  const className = cn(styles.dialog, classNames?.dialog)
  let titleElement = null
  let messageElement = null
  let onCloseElement = null

  if (title) {
    titleElement = <h1>{title}</h1>
  }

  if (message) {
    messageElement = <p>{message}</p>
  }

  if (onClose) {
    onCloseElement = <CloseIcon className={styles.closeIcon} onClick={onClose} />
  }

  return (
    <div className={className}>
      <div className={cn(isKiosk? styles.kioskDialogContainer : styles.dialogContainer, classNames?.dialogContainer)}>
        {titleElement}
        {onCloseElement}
        {messageElement}
        {children}
      </div>
    </div>
  )
}

export default Dialog
