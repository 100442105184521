import { GET_CAROUSEL_IMAGES_SUCCESS } from '../actions/carouselImages';

const initialState = []

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CAROUSEL_IMAGES_SUCCESS: {
      return payload
    }

    default: {
      return state
    }
  }
}
