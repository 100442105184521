import { createSelector } from 'reselect'
import { get, orderBy, sumBy, values, filter, map, isFinite, isEmpty } from 'lodash'
import moment from 'moment'

import getMatchingRecords from '../utils/getMatchingRecords'
import { createOrderParams, getOrderIsApprovedOffline, getOrderIsUnsyncable, inValidOrderStates } from '../utils/orderUtils'
import { STADIUM_ORDER_STATES } from '../utils/orderStates'

import { PAX_PAYMENT_TYPE, getPaymentType } from './peripheral'

export const getUpdatedSince = (state) => moment(get(state, 'order.updatedSince', moment())).toISOString()

export const getPurgedAt = (state) => get(state, 'order.purgedAt', undefined)

export const getKioskPayment = (state) => get(state, 'order.kioskPayment', {})

export const getPurgableOrders = (state, msThreshold, options) => {
  const ordersById = get(state, 'order.byId', {})
  const conditionalAttribute = options?.force ? 'submittedAt' : 'syncedAt'

  // An order is purgable if
  //   * it was synced to the platform; AND
  //   * it was synced the processor; AND
  //   * it is older than the specified threshold
  const isPurgable = (order) => order?.wasCreatedInStadium &&
    (createOrderParams(order)?.state !== STADIUM_ORDER_STATES.APPROVED_OFFLINE ||
    createOrderParams(order)?.state !== STADIUM_ORDER_STATES.TABBED ||
    createOrderParams(order)?.state !== STADIUM_ORDER_STATES.TABBED_OFFLINE) &&
    moment().diff(moment(order[conditionalAttribute])) >= msThreshold
  const syncedOrdersIds = map(filter(values(ordersById), isPurgable), 'uuid')

  return syncedOrdersIds
}

export const getMaintenancedAt = (state) => {
  return state?.order?.maintenancedAt
}

export const getMaintenanceEnabled = (state) => {
  return (state?.order?.maintenanceEnabled === undefined) ? true : state?.order?.maintenanceEnabled
}

export const getSafeToPerformMaintenance = (state) => {
  const offlineLocalIds = state?.order?.offlineLocalIds ?? []
  const offlineCardIds = state?.order?.offlineCardIds ?? []
  const failureRemoteIds = state?.order?.failureRemoteIds ?? []

  return [offlineLocalIds, offlineCardIds, failureRemoteIds].every(isEmpty)
}

export const getOrders = (state) => {
  const byId = get(state, 'order.byId', {})

  let orders = []
  orders = values(byId)

  const excludedStates = [
    STADIUM_ORDER_STATES.SUBMISSION_FAILED,
    STADIUM_ORDER_STATES.SUBMITTED,
    STADIUM_ORDER_STATES.AUTHORIZATION_FAILED,
    STADIUM_ORDER_STATES.CANCELLED
  ]

  // Filter orders by state.
  orders = orders?.filter(o => !excludedStates.includes(createOrderParams(o)?.state))

  const search = get(state, 'order.ordersSearch')
  if (search) {
    orders = orders?.filter(o => (o.orderNumber || '').toString().includes(search))
  }

  const sortedOrders = orderBy(orders, (o) => moment(o?.updatedAt || o?.modifiedAt || o?.submittedAt), ['desc'])
  const nonRefundable = []
  const refundable = []

  sortedOrders.forEach((order) => {
    const collection = order?.refundable ? refundable : nonRefundable
    collection.push(order)
  })

  return [...nonRefundable, ...refundable]
}

export const getUnsyncedOrderCounts = (state) => {
  const isPaxDevice = getPaymentType(state) === PAX_PAYMENT_TYPE
  const unsyncedLocalOrderCount = makeGetOfflineLocalIds()(state)?.length ?? 0
  let activeOfflineCardCount = state?.order?.offlineCardIds?.length ?? 0

  if (isPaxDevice) {
    activeOfflineCardCount = getActiveOfflineCardCount(state)
  }
  return [activeOfflineCardCount, unsyncedLocalOrderCount]
}

// returns true if there are non defective orders to sync
export const shouldInitiateIdleSync = (state) => {
  const byId = get(state, 'order.byId', {})
  const orders = values(byId)

  const syncableOrdersCount = orders.filter(
    order => !order.wasCreatedInStadium ||
      (getOrderIsApprovedOffline(order) && !getOrderIsUnsyncable(order))
  ).length

  return syncableOrdersCount > 0
}

const getTabOrders = (state) => {
  const byId = get(state, 'order.byId', {})
  let tabOrders = values(byId)

  const sortedOrders = orderBy(tabOrders, (order) => moment(order?.submittedAt), ['desc'])
  const closed = []
  const open = []

  sortedOrders.forEach((order) => {
    const collection = order?.orderState === 'open' ? open : closed
    collection.push(order)
  })

  return [...open, ...closed]
}

export const makeGetTabOrders = () => createSelector(
  [getTabOrders],
  (tabOrders) => tabOrders
)

const getTabOrder = (state, orderId) => get(state, `order.byId.${orderId}`, {})

export const makeGetTabOrder = () => createSelector(
  getTabOrder,
  (tabOrder) => tabOrder
)

export const makeGetOrders = () => createSelector([getOrders], (orders) => orders)

export const getOrder = (state, id) => get(state, `order.byId.${id}`, {})

export const makeGetOrder = () => createSelector([getOrder], (order) => order)

export const getOrderCounts = (state) => {
  const failure = get(state, 'order.failureRemoteIds', [])
  const offline = get(state, 'order.offlineLocalIds', [])
  const offlineCardIds = get(state, 'order.offlineCardIds', [])
  const success = get(state, 'order.successRemoteIds', [])
  const byId = get(state, 'order.byId', {})
  const filterByDate = orders => {
    let syncedOrders = [];
    orders.forEach(id => {
      if (!isEmpty(byId[id])) {
        syncedOrders.push(byId[id])
      }
    })
    return syncedOrders.filter(order => moment(order?.syncedAt).isSame(moment(), "day"))
  }

  return {
    failure: filterByDate(failure).length,
    offline: offline.length + offlineCardIds.length,
    success: filterByDate(success).length,
  }
}

export const makeGetOrderCounts = () => createSelector([getOrderCounts], (counts) => counts)

export const getSelectedOrder = (state) => {
  const orderId = get(state, 'order.selectedOrderId')
  return get(state, `order.byId[${orderId}]`)
}

export const getUnsyncableIds = (state) => state?.order?.unsyncableIds ?? []

export const makeGetSelectedOrder = () => createSelector([getSelectedOrder], (order) => order)

export const getOfflineLocalIds = (state, limit) => {
  const orders = state?.order?.byId ?? {}

  const matcher = (order) => ((order?.uuid || order?.id) && !order?.wasCreatedInStadium)
  const result = getMatchingRecords(Object.keys(orders), orders, matcher, limit)

  return result

  // Legacy Code
  // const orders = state?.order?.byId ?? {}
  // const orderIds = state?.order?.offlineLocalIds ?? []

  // if (!isFinite(limit)) {
  //   return orderIds
  // } else {
  //   const matcher = (order) => (order?.uuid || order?.id) !== null && (order?.uuid || order?.id) !== undefined
  //   const result = getMatchingRecords(orderIds, orders, matcher, limit)

  //   return result
  // }
}

export const makeGetOfflineLocalIds = () => createSelector([getOfflineLocalIds], (ids) => ids)

export const getOfflineCardIds = (state, limit) => {
  const orders = state?.order?.byId ?? {}
  const orderIds = state?.order?.offlineCardIds ?? []

  if (!isFinite(limit)) {
    return orderIds
  } else {
    const matcher = (order) => (order?.uuid || order?.id) !== null && (order?.uuid || order?.id) !== undefined
    const result = getMatchingRecords(orderIds, orders, matcher, limit)

    return result
  }
}

export const makeGetOfflineCardIds = () => createSelector([getOfflineCardIds], (ids) => ids)

export const getActiveOfflineCardCount = (state) => {
  return state?.order?.activeOfflineCardCount ?? 0
}

export const getAllRevenueInCents = (state) => {
  const orders = values(get(state, 'order.byId', {}))
  const validOrders = filter(orders, (order) => !inValidOrderStates.includes(createOrderParams(order)?.state))
  return sumBy(validOrders, 'amountInCents')
}

export const makeGetAllRevenueInCents = () => createSelector([getAllRevenueInCents], (cents) => cents)

export const getStoredRickCheckoutOrderUuid = state => get(state, 'order.storedOrderUuid', '')

export const getStoredRickCheckoutOrderNumber = state => get(state, 'order.storedOrderNumber', '')

export const getOrderInProgress = (state) => state?.order?.orderInProgress ?? {}

export const getOrdersFilter = (state) => state?.order?.ordersFilter ?? { }

export const getMutationInProgress = (state) => state.order.mutationInProgress || false

export const getQRPayAuthError = (state) => state.order.QRPayAuthError || false

export const getOrderReportsLastFetchedAt = (state) => state.order.orderReportsLastFetchedAt ?? ''
