import React from 'react'
import { VNProgressCounter } from '../../../VNCustomerFacingDisplay/components/VNProgressCounter'
import { VNDialog } from '../../../VNCustomerFacingDisplay/components/VNDialog'
import { useSelector } from 'react-redux'
import { getCardAuthStepOne } from '../../Selectors'

export const VNCardAuthStepOne = () => {

  const dialogData = useSelector(state => getCardAuthStepOne(state))

  const getImage = () => {
    return (
      <VNProgressCounter count={1} total={2} />
    )
  }

  const getTitle = () => {
    return `Customer Authorizing Card for ${dialogData?.get('amount')}`
  }

  return (
    <VNDialog 
      open={dialogData?.get('open')}
      image={getImage()}
      title={getTitle()}
    />
  )
}