import { select, takeLatest, all,  put } from 'redux-saga/effects'
import { flatMap } from 'lodash'

import { GET_CAROUSEL_IMAGES, getCarouselImagesSuccess } from '../../actions/carouselImages'
import { getImagePath } from '../../kiosk/utils/carousel'
import { getSelectedMenusIds } from '../../selectors/config'


export function* getCarouselImages() {
  try {
    const imageVersion = Date.now()
    const selectedMenuIds = yield select(getSelectedMenusIds)
    const imagesAmount = 5

    const imageNumbers = [...Array(imagesAmount).keys()].map(x => x + 1)
    const imagePaths = yield all(flatMap(selectedMenuIds, (menuUuid) => {
      return imageNumbers.map(imageNumber => getImagePath(menuUuid, imageNumber, imageVersion))
    }))
    const existingImages = imagePaths.filter(path => !!path)

    yield put(getCarouselImagesSuccess(existingImages))
  } catch (error) {
    console.error(error)
  }
}

export function* watchGetCarouselImages() {
  yield takeLatest(GET_CAROUSEL_IMAGES, getCarouselImages)
}
