import moment from 'moment'
import { keyBy } from 'lodash'

import { MENUS_SUCCEEDED, MENUS_REQUESTED, MENUS_FAILED } from '../actions/menus'

const initialState = {
  byId: {},
  isMenusLoading: false
}
const menusReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MENUS_REQUESTED: {
      return {
        ...state,
        isMenusLoading: true
      }
    }

    case MENUS_SUCCEEDED: {
      const menus = payload?.data?.menus ?? []
      const menusById = keyBy(menus, 'uuid') ?? []
      return {
        ...state,
        byId: {
          ...state.byId,
          ...menusById
        },
        isMenusLoading: false,
        timeStamp: moment().toString(),
      }
    }

    case MENUS_FAILED: {
      return {
        ...state,
        isMenusLoading: false,
      }
    }

    default:
      return state
  }
}

export default menusReducer
