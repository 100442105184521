
    export const BRANDING_COLOR = "#000000"
    export const SIGNATURE_THRESHOLD_AMOUNT = undefined
    export const USE_RICH_CHECKOUT = "false"
    export const RICH_CHECKOUT_DEEPLINK_SCHEMA = "https://metlifestadium.ordernext.com/"
    export const RICH_CHECKOUT_TEXT_PROMPT = "To pay with Virtual Currency or Loaded Value, scan the QR code and complete the order."
    export const PUSHER_KEY = "1a713dd51f69a0de4b4b"
    export const PUSHER_CLUSTER = "us2"
    export const TEAM_LOGO_EXISTS = false
    export const PRINTING_ENABLED = false
    export const CLOUDINARY_NAME = 'venuenext'
    export const CLOUDINARY_PRESET_NAME = 'venuenext'
    export const NOTES_CHARACTER_LIMIT = 255
    export const PIN_CODE_CONFIGURATION_ACCESS = '100710'
    export const DAYS_TO_FORCE_MAINTENANCE = 7
    export const CLEAR_SAF_DECLINES = false
    export const API_ENDPOINT = "https://metlife.principal.prd.api.vnops.net"
    export const SCANDIT_KEY = "AZZ0FLiBLFl3Dzf1QSF1g1lDibIoOP1nb2H7qm5TPBHsUO88EiIxMldGAiHXXnI2w2BURdZiqUKDTrHwklHP/NZw9Lu5APsvvFyQ7qptATddUolsx3lZSzRg/+9bYfy5ZXrPGENYVXEGIsaAKx8LgV4gznhxI9FDwZwdSb0mcXq87qxd/nvIImDFqcOI8zJDlXxisxii7bPdU3xtQ1A58gAhLFnY7oTl+ykTTPJiAdmZ6YgJ8L5rFV7avyRiC3okymj4JNs/zNHQ+Naskgvdmt87KobnQOpb+fBZxMXJy/aVqM1RXPEWOX0hdG1Ud0jcweF0czclbEltYqcm1Hw/axNw/q98BHT+DIKQNLNmIEsxZYdA0gdq3LZo4g61LhyZ/aQUFmR8SDD0zianqLHJ3lKoClHA5bZa+SGwdXJnoD3wEsSNWmcGytfWYGl5ZtlVl1dG2hYujPH2hga9gRMO6Du2XhvoluKuyFPVcgbSdCU58BPgPIkZqsEFKh5Q+qZVAlxsvWBK33YLbXuWBFGqqN1zWlPH4rzwBLBNVfrEnSPzGSmWUJzUcCEbKa8avrFVSaRTwVeXaGf0DCr3kol23s/vfBJnMQNVj39Oc7lQlyyPKk0MdIZnpEzfpNa3Im4GJA9gygBnh3FdGkUVCdkQhPRDEaHEoeZX3gD84FC4a6F+BfpVa7yq4gN6Zk1F1EsJpf/rHokBEL92E7zMzQOAE2YJRdvrpjJbmO+/CR8ajRmxVf8BZQC36YN2iqtOr3h5NJCAXcrsXj9lGAXY6C/vtFXKpRY6FJughRO+iYogKHwPMowRDfeQz/MTAu8DNHlNfqeNwGT0GQ=="