/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect }  from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'

import Portico from '@ordernext/networking-stadium/portico'
import Stadium, { loadConfig } from '@ordernext/networking-stadium/stadium'
import useInterval from '../hooks/useInterval'
import ConnectingIndicator from '../components/ConnectingIndicator'
import { getIsKiosk } from '../selectors/menus'
import { clearPromotionsOnOrder } from '../actions/promotion'

import PeripheralBridge from '../utils/peripheralBridge'

const DeviceLogin = ({ loadConfig, clearPromotionsOnOrder, history }) => {
  const ONE_MINUTE = 60000
  const ONE_HOUR = ONE_MINUTE * 60

  const updateTokens = (response) => {
    const accessToken = get(response, 'data.accessToken', undefined)
    const expiresAt = get(response, 'data.expiresAt', undefined)

    if (accessToken && expiresAt) {
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('expiresAt', expiresAt)

      Stadium.config = {
        ...Stadium.config,
        headers: {
          ...Stadium.config.headers,
          Authorization: `Bearer ${accessToken}`,
          'Cache-Control': 'no-cache, no-store',
          Pragma: 'no-cache',
          Expires: 0,
        }
      }
    }
  }

  const onLoginSuccess = (response) => {
    updateTokens(response)

    /**
     * FIXME:
     * Without this timeout, the revenue center setup screen gets a 500 from stadium because the
     * the updateTokens() method (above) hasn't completed yet, so the token doesn't get passed when
     * fetching the app device configuration.
     * This is a good excuse/reason for us to move everything that uses
     * localStorages getItem() and setItem() methods into the global redux state.
    */
    setTimeout(() => {
      loadConfig()
    }, 1000)
  }

  const onLoginFailure = (err) => {
    if (localStorage.getItem('accessToken') === null) {
      history.replace('/register-device')
    } else {
      history.replace('/revenue-center-setup')
    }
  }

  const nonRedirectFailureResponse = (err) => {
    console.warn(err, "Portico failed to authorize request")
  }

  const login = (onSuccess, onFailure) => {
    const deviceId = window.localStorage.device_id
    const appKey = process.env.REACT_APP_OIDC_CLIENT_ID
    const organizationName = process.env.REACT_APP_ORG

    Portico.deviceLogin(deviceId, appKey, organizationName).then(onSuccess).catch(onFailure)
  }

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    const urlParams = new URLSearchParams(window.location.search)
    const isApk = urlParams.get('apk');

    if (isApk && bridgeInstance) {
      bridgeInstance.callHandler('getSerialNumber', null, (uuid) => {
        window.localStorage.setItem('device_id', uuid)
      })
    }

    login(onLoginSuccess, onLoginFailure);
  }, [])

  useEffect(() => {
    clearPromotionsOnOrder()
  }, [])

  useInterval(() => {
    const rawExpiresAt = localStorage.getItem('expiresAt')
    if (rawExpiresAt === null || rawExpiresAt === undefined) {
      login(updateTokens, nonRedirectFailureResponse)
      return
    }

    const expiresAt = moment(rawExpiresAt)
    const tokenMillisecondsRemaining = expiresAt.diff(moment())
    if (tokenMillisecondsRemaining < ONE_HOUR) {
      login(updateTokens, nonRedirectFailureResponse)
    }
  }, ONE_MINUTE)

  return <ConnectingIndicator />
}

const mapStateToProps = (state) => {
  const hasConfig = get(state, 'config.hasConfig', false)
  const isKiosk = getIsKiosk(state)

  return { hasConfig, isKiosk }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadConfig: () => dispatch(loadConfig()),
    clearPromotionsOnOrder: () => dispatch(clearPromotionsOnOrder()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceLogin))
