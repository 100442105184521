export default class PeripheralBridge {

  static setupWebViewJavascriptBridge = (callback) => {
    if (window.WebViewJavascriptBridge) { return callback(window.WebViewJavascriptBridge) }
    if (window.VNCallbacks) { return window.VNCallbacks.push(callback) }
    window.VNCallbacks = [callback]
    var VNIframe = document.createElement('iframe')
    VNIframe.style.display = 'none'
    VNIframe.src = 'vnscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(VNIframe)
    setTimeout(function() { document.documentElement.removeChild(VNIframe) }, 0)
  }

  static getBridge() {
    return window?.WebViewJavascriptBridge
  }
}
