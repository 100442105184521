import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import moment from 'moment'
import { get } from 'lodash'

import { resolvePaymentIcon } from '../../utils/icons'
import { createOrderParams } from '../../utils/orderUtils'

import { ReactComponent as ErrorIcon } from '../../assets/icons/defective.svg'
import { ReactComponent as AbortedIcon } from '../../assets/icons/aborted.svg'
import { ReactComponent as FailedIcon } from '../../assets/icons/failed.svg'
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/approved.svg'

import Button from '../Button'

import { getTenderInfo, totalDisplay } from '../../utils/orderDisplay'
import { INTERNAL_ORDER_STATES, STADIUM_ORDER_STATES, isTabbed } from '../../utils/orderStates'
import { getOrderIsApprovedOffline, getOrderIsUnsyncable, orderDate } from '../../utils/orderUtils'

import styles from './OrderRow.module.scss'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'

// List of possible display icons and text used for getStatusDisplay
// Icons and text should be based on platform order state with the exception
// of "unsyncable" which isn't a true order state
const statusDisplays = {
  [STADIUM_ORDER_STATES.SUBMISSION_FAILED]: {
    icon: <FailedIcon className={styles.statusIcon} />,
    text: 'Aborted'
  },
  [STADIUM_ORDER_STATES.SUBMITTED]: {
    icon: <AbortedIcon className={styles.statusIcon} />,
    text: 'Aborted'
  },
  [STADIUM_ORDER_STATES.CANCELLED]: {
    icon: <AbortedIcon className={styles.statusIcon} />,
    text: 'Aborted'
  },
  [STADIUM_ORDER_STATES.COMPLETED]: {
    icon: <CheckmarkIcon className={styles.statusIcon} />,
    text: 'Approved'
  },
  [STADIUM_ORDER_STATES.COMPLETION_PENDING]: {
    icon: <CheckmarkIcon className={styles.statusIcon} />,
    text: 'Approved'
  },
  [STADIUM_ORDER_STATES.PROCESSING]: {
    icon: <CheckmarkIcon className={styles.statusIcon} />,
    text: 'Approved'
  },
  [STADIUM_ORDER_STATES.APPROVED_OFFLINE]: {
    icon: <CheckmarkIcon className={styles.statusIcon} />,
    text: 'Approved'
  },
  [STADIUM_ORDER_STATES.TABBED]: {
    icon: <CheckmarkIcon className={styles.statusIcon} />,
    text: 'Tabbed'
  },
  [STADIUM_ORDER_STATES.TABBED_OFFLINE]: {
    icon: <CheckmarkIcon className={styles.statusIcon} />,
    text: 'Tabbed Offline'
  },
  'unsyncable': {
    icon: <ErrorIcon className={styles.statusIcon} />,
    text: 'Defective',
  },
}

// returns icon and text based on the current order state
const getStatusDisplay = (orderStatus) => {
  const icon = statusDisplays[orderStatus]?.icon
  const text = statusDisplays[orderStatus]?.text
  return (
    <span>
      {icon} {text}
    </span>
  )
}

const displayCreated = (created) => {
  const date = moment(created).format('M/D/YY')
  const time = moment(created).format('h:mm a')

  return date + ", " + time
}

const OrderRow = ({ onSelect, order, isKiosk, className }) => {
  const authFailed = order?.isDeclined || order?.orderState === INTERNAL_ORDER_STATES.FAILED
  const errorClass = authFailed ? styles.error : null
  const unsyncableClass = order?.unsyncable || (createOrderParams(order)?.state === STADIUM_ORDER_STATES.SUBMITTED)  ? styles.unsyncable  : null
  const redButtonClass =  order?.unsyncable ? styles.errorButton : null
  const classNames = cn(styles.orderRow, errorClass, unsyncableClass, className)

  const tenderResponse = getTenderInfo(order)
  const iconType = tenderResponse?.cardIssuer ?? tenderResponse.displayText
  const icon = React.cloneElement(resolvePaymentIcon(iconType), { className: styles.icon })
  const isMobileViewPort = useGetIsMobileScreen()

  const orderNumber = (order) => {
    // Favor the order number that was actually created in the DB, if it exists.
    return createOrderParams(order)?.orderNumber ?? get(order, 'orderNumber', '-')
  }

  // TODO(mkramerl): Make this dynamic so text value can reflec the sync button being pressed in the detail.
  let initialSyncStatusText = ''

  if (getOrderIsUnsyncable(order)) {
    initialSyncStatusText = getStatusDisplay('unsyncable')
  } else if (order.isSyncing) {
    initialSyncStatusText = 'syncing'
  } else if (isTabbed(order) && order.orderState === INTERNAL_ORDER_STATES.CLOSED) {
    initialSyncStatusText = getStatusDisplay('completion_pending')
  } else if (getOrderIsApprovedOffline(order)) {
    initialSyncStatusText = getStatusDisplay('approved_offline')
  } else {
    initialSyncStatusText = getStatusDisplay((order?.state || createOrderParams(order)?.state) ?? 'completed')
  }

  const mobileText = isMobileViewPort ? ' • ' : ' '
  const orderRowDisplayText = order?.tabName && !isMobileViewPort ? `${tenderResponse?.displayText} • ${order?.tabName}` : tenderResponse?.displayText

  return (
    <>
      {!isMobileViewPort && (
        <div className={classNames} onClick={() => onSelect(order.uuid)}>
          <div className={styles.centerContainer}>
            {icon}
            {!isKiosk && (
              <span className={styles.tenderName}>{orderRowDisplayText}</span>
            )}
          </div>
          <div>
            {mobileText}
            {totalDisplay(order)}
          </div>
          <div>{orderNumber(order)}</div>
          <div>{displayCreated(orderDate(order))}</div>
          <div>{initialSyncStatusText}</div>
          <div>
            <Button
              className={cn(styles.detailsButton, redButtonClass)}
              onClick={() => onSelect(order.uuid)}
            >
              Details
            </Button>
          </div>
        </div>
      )}
      {isMobileViewPort && (
        <>
          <div className={styles.orderRow}>
            <div className={styles.centerContainer}>
              <span className={styles.mobileIcon}>{icon}</span>
              <span className={styles.mobileTabName}>{order?.tabName}</span>
            </div>
          </div>

          <div className={classNames} onClick={() => onSelect(order.uuid)}>
            <div className={styles.centerContainer}>
              {!isKiosk && (
                <span className={styles.tenderName}>{orderRowDisplayText}</span>
              )}
            </div>
            <div>
              {mobileText}
              {totalDisplay(order)}
            </div>
            <div>{orderNumber(order)}</div>
            <div>{displayCreated(orderDate(order))}</div>
            <div>{initialSyncStatusText}</div>
          </div>
        </>
      )}
    </>
  )
}

OrderRow.defaultProps = {
  order: {},
}

OrderRow.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  order: PropTypes.object,
}

export default OrderRow
