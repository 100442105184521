import { filter, sortBy } from 'lodash'
import { call, put, takeEvery, select} from 'redux-saga/effects'

import Remote from '../remote'

import {
  fetchPromotions,
  fetchPromotionsSucceeded,
  fetchPromotionsFailed,
} from '../actions/promotion'
import { getCurrentMenu } from '../selectors/menus'


function* fetchPromotionsSaga(params) {

  const venueUuid = process.env.REACT_APP_VENUE_UUID
  const menu = yield select(getCurrentMenu)

  try {
    const result = yield call(Remote.getPromotions, venueUuid, [ menu?.uuid ])
    const activePromotions = filter(result?.data?.promotions, (promotion) => !!promotion?.active)
    const promotions = sortBy(activePromotions, ['name'])

    yield put(fetchPromotionsSucceeded({ promotions }))
  } catch (err) {
    yield put(fetchPromotionsFailed())
  }
}

export function* watchFetchPromotionsSaga() {
  yield takeEvery(fetchPromotions.type, fetchPromotionsSaga)
}
