export default {
  items: {
    byMenuId: {},
  },
  menus: {
    byId: {},
  },
  cart: {
    allIds: [],
    byId: {},
  },
  config: {
    hasConfig: false,
    shouldRefreshConfig: true,
    networkAvailable: true,
    backbarMode: false,
  },
  order: {
    byId: {},
    offlineCardIds: [],
    failureRemoteIds: [],
    offlineLocalIds: [],
    successRemoteIds: [],
    purgedAt: undefined,
    syncMultiplicandInMinutes: 4,
    orderInProgress: {},
    ordersFilter: {
      state: 'tabbed',
      showOrders: 'all orders'
    }
  },
  peripheral: {
    peripheralConnected: true
  },
  refund: {
    byId: {},
    failureRemoteIds: [],
    successRemoteIds: [],
  },
  revenueCenter: {
    byId: {
      'unassigned': {
        uuid: 'unassigned',
        name: 'Unassigned Orders'
      }
    },
    allIds: ['unassigned'],
    activeRevenueCenter: undefined,
  },
  salesEvent: {
    byId: {},
    requestFailed: false,
  },
  categories: {
    byMenuId: {},
  },
}
