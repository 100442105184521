import React, { forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPpiConfigurations } from '../Selectors'
import { getActiveAttendantId } from '../../selectors/attendant'
import { getActiveRevenueCenter } from '../../selectors/revenueCenter'
import { getSelectedPromotions } from '../../selectors/promotion'
import { getOrderInProgress } from '../../selectors/order'
import { getCartItems } from '../../selectors/cart'
import { getCurrentMenu, getIsKiosk } from '../../selectors/menus'
import { getTender } from '../Utils'
import { addPaymentToOrderInProgress, updateOrderInProgress } from '../../actions/order'
import { addPromotionsToOrder } from '../../actions/promotion'
import { isEmpty, vn_getEntries } from '../../VNUtil/VNUtils'
import { swapCaseFirstLetter } from '../../VNUtil/Strings'
import { isTabbed } from '../../utils/orderStates'
import { setScannedTenderForOrder } from '../ActionCreators'

/**
 * A single place to get all state and handle dispatches without having to copy and paste it everywhere
 */
export const VNInjectablePpi = forwardRef((props, ref) => {

  const dispatch = useDispatch()

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    // get all the data used for PPI
    getData(tender) {

      const ppiTender = getTender(ppiConfigurations, tender)
      let customAttributesPosInfo = {}
      const standPpiExtras = activeRevenueCenter.customerAttributes?.ppiExtras

      if (ppiTender?.integration) {
        // swap case becuase of root api camel case swap...ugh
        const integrationUnCapitalized = swapCaseFirstLetter(ppiTender.integration)
        
        if (standPpiExtras && !isEmpty(standPpiExtras[integrationUnCapitalized])) {
          
          for (const [key, value] of vn_getEntries(standPpiExtras[integrationUnCapitalized])) {
            customAttributesPosInfo[key] = value
          }

        }
      }

      return {
        functions: {
          addPromotionsToOrder: (payload) => {
            dispatch(addPromotionsToOrder(payload))
          },
          updateOrderInProgress: (updatedOrder) => {
            dispatch(updateOrderInProgress(updatedOrder))
          },
          addPaymentToOrderInProgress: (payment) => {
            dispatch(addPaymentToOrderInProgress(payment))
          },
          setScannedTenderForOrder: (orderUuid, token) => {
            dispatch(setScannedTenderForOrder(orderUuid, token))
          }
        },
        posInfo: {
          userId: activeAttendantId,
          vendorName: activeRevenueCenter.name,
          venueId: process.env.REACT_APP_VENUE_UUID,
          tracerId: isTabbed(order) ? order.standUuid : order.standMenuUuid,
          ...customAttributesPosInfo
        },
        promotions: {
          selectedPromotions,
          orderMenuItems,
          menu,
          isKiosk
        },
        order,
        ppiTender,
        ppiConfigurations
      }
    }

  }))

  const ppiConfigurations = useSelector(state => getPpiConfigurations(state))
  const activeAttendantId = useSelector(state => getActiveAttendantId(state))
  const activeRevenueCenter = useSelector(state => getActiveRevenueCenter(state))
  const selectedPromotions = useSelector(state => getSelectedPromotions(state))
  const order = useSelector(state => getOrderInProgress(state))
  const orderMenuItems = useSelector(getCartItems)
  const menu = useSelector(getCurrentMenu)
  const isKiosk = useSelector(getIsKiosk)

  return <></>
})