import { createSelector } from 'reselect';
import { values, some, filter, keys, uniqBy } from 'lodash'

import { getCurrentMenuItemsFilteredByCategory } from './items'
import { getCurrentMenuId } from './appState'
import { getSelectedMenusIds } from './config'

const getCategories = state => state.categories.byMenuId

export const getIsMenuItemsLoading = state => state?.categories?.isMenuItemsLoading ?? false

export const getCurrentMenuCategories = createSelector(
  getCategories,
  getCurrentMenuId,
  (categories, currentMenuId) => categories[currentMenuId]
)

export const getCategoriesWithExistingItems = state => filter(
  values(getCurrentMenuCategories(state)?.byId),
  (category) => some(getCurrentMenuItemsFilteredByCategory(state, category.itemCategoryUuid))
)

export const getUniqCategoriesWithExistingItems = createSelector(
  getCategoriesWithExistingItems,
  categories => uniqBy(categories, 'name')
)

export const getAreCategoriesLoaded = createSelector(
  getCategories,
  getSelectedMenusIds,
  (categories, selectedIds) => {
    const categoryKeys = keys(categories)
    return selectedIds.every(id => categoryKeys.includes(id))
  }
)
