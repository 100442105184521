import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Loader = ({ container = true, center = true }) => (
  <div
    className={cn('loader', {
      '-center': center,
      '-container': container,
    })}
  >
    <span className="rect-1" />
    <span className="rect-2" />
    <span className="rect-3" />
    <span className="rect-4" />
    <span className="rect-5" />
  </div>
)

Loader.propTypes = {
  center: PropTypes.bool,
  container: PropTypes.bool,
}

export default Loader
