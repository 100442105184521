export const UPDATE_HIGHLIGHTED_ITEMS = 'UPDATE_HIGHLIGHTED_ITEMS'
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY'
export const UPDATE_FOCUSED_ITEM_ID = 'UPDATE_FOCUSED_ITEM_ID'
export const UPDATE_SELECTED_CATEGORY = 'UPDATE_SELECTED_CATEGORY'
export const UPDATE_QUICK_SEARCH_FLAG ='UPDATE_QUICK_SEARCH_FLAG'

export const updateHighlightedItems = (searchParams) => ({
    type: UPDATE_HIGHLIGHTED_ITEMS,
    payload: { searchParams },
  })
  
export const updateSearchQuery = (searchQuery) => ({
    type: UPDATE_SEARCH_QUERY,
    payload: { searchQuery },
})

export const updateFocusedItemId = (focusedItemId) => ({
    type: UPDATE_FOCUSED_ITEM_ID,
    payload: { focusedItemId },
})

export const updateSelectedCategory = (selectedCategory) => ({
    type: UPDATE_SELECTED_CATEGORY,
    payload: { selectedCategory },
})

export const updateUseQuickSearchFlag = (useQuickSearchFlag) => ({
    type: UPDATE_QUICK_SEARCH_FLAG,
    payload: { useQuickSearchFlag }
})