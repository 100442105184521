import React, { useEffect, useState } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Paper, Popper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  autocomplete: (props) => ({
    paddingTop: 16,
    paddingRight: 16,
    ...(props?.customAutocompleteStyles ?? {})
  }),
  inputLabel: (props) => ({
    color: theme.custom.colors.disabledGrey,
    '&.Mui-disabled': { // This is the class applied when the input is disabled, so it will override the color inherited from the input label
      color: theme.custom.colors.disabledGrey
    },
    ...(props?.customInputLabelStyles ?? {})
  }),
  textField: (props) => ({
    color: props.disabled ? theme.custom.colors.disabledGrey : theme.custom.colors.mainTextColor,
    paddingLeft: 8,
    paddingRight: 8,
    '&.Mui-disabled': {
      color: theme.custom.colors.disabledGrey
    },
    backgroundColor: props.disabled ? '#292A2B' : theme.custom.colors.greyMedium,
    ...(props?.customTextFieldStyles ?? {})
  }),
  paper: (props) => ({
    backgroundColor: theme.custom.colors.greyMedium,
    color: theme.custom.colors.mainTextColor,
    '&.MuiAutocomplete-listbox' :{
      maxHeight: 200
    },
    ...(props?.customPaperStyles ?? {})
  }),
  popper: (props) => ({
    fontSize: '0.95em',
    zIndex: 100000,
    ...(props?.style ?? {})
  })
}))

const DropdownSelector = ({ ...props } ) => {

  const theme = useTheme()
  const classes = useStyles(props)

  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [dropDownActive, setDropDownActive] = useState(false)

  const getAdornment = () => {
    if (dropDownActive) {
      return <KeyboardArrowUpIcon onClick={() => setDropDownActive(false)}/>
    }

    return <KeyboardArrowDownIcon onClick={activateDropdown}/>
  }

  const activateDropdown = () => {
    if (props.disabled) return
    setDropDownActive(true)
  }

  const handleClick = () => {
    if (props.disabled) return
    setDropDownActive(!dropDownActive)
  }

  useEffect(() => {
    setAutocompleteValue('')
  }, [props.disabled])

  useEffect(() => {
    setAutocompleteValue('')
  }, [props.autocompleteOptions])
  
  useEffect(()=> {
    if (props.value) {
      setAutocompleteValue(props.value)
    }
  }, [props.value, props.autocompleteOptions])

  return (
    <Autocomplete
      freeSolo
      open={dropDownActive}
      onOpen={activateDropdown}
      onClose={() => setDropDownActive(false)}
      onClick={handleClick}
      id='vndialog-autocomplete'
      value={autocompleteValue}
      inputValue={autocompleteValue}
      onInputChange={(event, newInputValue) => {
        props.onAutocompleteChange(newInputValue)
        setAutocompleteValue(newInputValue)
        setDropDownActive(false);
      }}
      popupIcon={true}
      disabled={props.disabled}
      options={props.autocompleteOptions}
      className={classes.autocomplete}
      PaperComponent={({ children }) => (
        <Paper className={classes.paper}>
          {children}
        </Paper>
      )}
      PopperComponent={(props) => <Popper {...props} className={classes.popper}/>}
      renderInput={(params) =>
        <TextField {...params} label={props.autocompleteLabel}
            InputProps={{
              ...params.InputProps,
              ...theme.typography.h5,
              className: classes.textField,
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {getAdornment()}
                </InputAdornment>
              )
            }}
            variant= {props.textInputVariant ? props.textInputVariant : "outlined"}
            InputLabelProps={{
              className: classes.inputLabel
            }}
        />
      }
    />
  )
}

export default DropdownSelector
