import React from 'react'
import cn from 'classnames'

import GratuityHeader from '../components/GratuityHeader'
import GratuityCards from '../components/GratuityCards'
import GradientButton from '../components/GradientButton'

import styles from './Gratuity.module.scss'

const options = [
  { percentage: '15%', amount: '$7.50' },
  { percentage: '20%', amount: '$10.00' },
  { percentage: '25%', amount: '$12.50' },
]

const Gratuity = ({ className }) => {
  const classNames = cn(styles.gratuity, className)

  return (
    <div className={classNames}>
      <div className={styles.gratuityContent}>
        <GratuityHeader className={styles.gratuityHeader} primaryText="$50.00" secondaryText="Add a Tip" />
        <GratuityCards className={styles.gratuityCards} options={options} />
        <GradientButton className={styles.gradientButton} text="Custom Tip Amount" />
        <GradientButton className={styles.gradientButton} text="No Tip" />
      </div>
    </div>
  )
}

export default Gratuity
