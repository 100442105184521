import { call, put, select, takeLatest } from 'redux-saga/effects'
import { filter} from 'lodash'

import createDataDogLog from '../utils/datadog';

import { makeGetAttendantByPin } from '../selectors/attendant'
import { getEmployeeApprovalCodes } from '../selectors/employeeRole'

import { setActiveAttendantId, clearAttemptPinLoginStatus, clearFetchAttendantsStatus, LOGOUT, REGISTER_LOGIN } from '../actions/attendant'

import {
  ATTEMPT_PIN_LOGIN,
  FETCH_ATTENDANTS,
  fetchAttendantsSucceeded,
  fetchAttendantsFailed,
  attemptPinLoginSucceeded,
  attemptPinLoginFailed,
} from '../actions/attendant'

import {
  fetchAttendantSettingsFailed,
} from '../actions/attendantSettings'

import Remote from '../remote'
import { clearPromotionsOnOrder } from '../actions/promotion'
import { clearCart } from '../actions/cart'

const getAttendantByPin = makeGetAttendantByPin()

const filterAttendants = (attendants = []) =>
  filter(attendants, (attendant) =>
    attendant?.deactivatedAt === null
  )

export function* fetchAttendantsSaga() {
  const organizationName = yield select((state) => state?.config?.organizationName ?? '')
  try {
    const result = yield call(Remote.getAttendants, organizationName)
    const activeAttendants = filterAttendants(result?.data?.attendants)
    yield put(fetchAttendantsSucceeded(activeAttendants))
  } catch (err) {
    yield put(fetchAttendantsFailed(err))
  }
}

export function* watchFetchAttendantsSaga() {
  yield takeLatest(FETCH_ATTENDANTS, fetchAttendantsSaga)
}

export function* watchAttemptPinLoginSaga() {
  yield takeLatest(ATTEMPT_PIN_LOGIN, attemptPinLoginSaga)
}

export function* watchRegisterLoginSaga() {
  yield takeLatest(REGISTER_LOGIN, registerLoginSaga)
}

function* registerLoginSaga(action) {
  try {
    yield call(Remote.getAttendantSettings, action?.payload?.pin)
  } catch (error) {
    createDataDogLog('error', 'An error occurred while logging in.')
  }
}

function* attemptPinLoginSaga(action) {
  const pin = action?.payload?.pin

  const approvalCodes = yield select(getEmployeeApprovalCodes)
  if (approvalCodes?.includes(pin)){
    const attendant = yield select(getAttendantByPin, pin)
    const attendantUuid = attendant?.uuid

    yield put(setActiveAttendantId(attendantUuid))
    yield put(attemptPinLoginSucceeded())
  } else {
    yield put(fetchAttendantSettingsFailed({ errorMessage: "Attendant not found" }))
    yield put(attemptPinLoginFailed())
  }
}

function* logoutSaga() {
  yield put(setActiveAttendantId(undefined))
  yield put(clearAttemptPinLoginStatus())
  yield put(clearFetchAttendantsStatus())
  yield put(clearPromotionsOnOrder())
  yield put(clearCart())
}

export function* watchLogoutSaga() {
  yield takeLatest(LOGOUT, logoutSaga)
}
