import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from './Button'
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg'
import { ReactComponent as KeyboardIcon } from '../assets/icons/keyboard-button.svg'
import styles from './NumberPadV2.module.scss'

const numbers = [
  [{ 1: 1 }, { 2: 2 }, { 3: 3 }],
  [{ 4: 4 }, { 5: 5 }, { 6: 6 }],
  [{ 7: 7 }, { 8: 8 }, { 9: 9 }],
  [{ 0: 0 }, { backspace: <DeleteIcon className={styles.backspaceIcon} key='backspace' /> }],
]

const numbersWithKeyboard = [
  [{ 1: 1 }, { 2: 2 }, { 3: 3 }],
  [{ 4: 4 }, { 5: 5 }, { 6: 6 }],
  [{ 7: 7 }, { 8: 8 }, { 9: 9 }],
  [{ keyboard: <KeyboardIcon className={styles.keyboardIcon} key='keyboard' />}, { 0: 0 }, { backspace: <DeleteIcon className={styles.backspaceIcon} key='backspace' /> }],
]

const NumberPadV2 = ({ onTapButton, keyboardOn, isCashTender, onPressButton, className, ...props }) => {

  const pinPad = keyboardOn ? numbersWithKeyboard : numbers

  return (
    <div {...props} className={cn(styles.numberPad, className)}>
      
      {pinPad.map((row, idx) => {
        return (
          <div className={keyboardOn ? styles.numberPadRow2 : isCashTender ? styles.numberPadCashTender : styles.numberPadRow} key={idx}>
            {row.map((column) => {
              const value = Object.keys(column)[0]
              const label = column[value]
              return (
                <Button
                  key={value}
                  onClick={() => onTapButton(value)}
                  onMouseDown={() => onPressButton(value)}
                  className={isCashTender ? styles.cashNumberPadButton : styles.numberPadButton}
                >
                  {label}
                </Button>
              )
            })}
          </div>
        )
      })}

    </div>
  )
}

NumberPadV2.defaultProps = {
  onTapButton: () => {},
  onPressButton: () => {}
}

NumberPadV2.propTypes = {
  onTapButton: PropTypes.func,
  onPressButton: PropTypes.func
}

export default NumberPadV2
