import React from 'react'
import PropTypes from 'prop-types'

const IconBack = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <path d="M15.27 17.5L12.85 20 3 10.33 13.52 0l2.42 2.5-7.97 7.83z" />
  </svg>
)

IconBack.propTypes = {
  className: PropTypes.string,
}

export default IconBack
