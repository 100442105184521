import { get, keyBy } from 'lodash'

import { MENUS_SUCCEEDED } from '../actions/menus'

const initialState = { byId: {} }

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case MENUS_SUCCEEDED: {
      const stands = get(payload, 'data.stands')

      return {
        byId: keyBy(stands, 'uuid'),
      }
    }

    default:
      return state
  }
}
