import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './GratuityCard.module.scss'

const GratuityCard = ({ percentage, amount, onTap, className }) => {
  const classNames = cn(styles.gratuityCard, className)

  return (
    <div className={classNames} onClick={onTap}>
      <span className={styles.percentage}>{percentage}</span>
			<span className={styles.amount}>{amount}</span>
    </div>
  )
}

GratuityCard.defaultProps = {
  percentage: '',
  amount: '',
  onTap: () => {},
}

GratuityCard.propTypes = {
  percentage: PropTypes.string,
  amount: PropTypes.string,
  onTap: PropTypes.func,
}

export default memo(GratuityCard)
