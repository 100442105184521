import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isEmpty } from 'lodash'

import { ReactComponent as ShoppingCartOutlinedIcon } from '../assets/icons/Cart.svg'
import { ReactComponent as MobileShoppingCartOutlinedIcon } from '../assets/icons/mobileCart.svg'

import ConfirmModalV2 from '../components/ConfirmModalV2'

import useManagerApproval from '../context/hooks/useManagerApproval'

import networkConnected from '../utils/networkConnected'
import DeviceSubtypes from '../utils/deviceSubtypes'

import { refreshConfig } from '../actions/config'
import { logout } from '../actions/attendant'
import { onSafIsUploading } from '../actions/peripheral'
import { clearCart } from '../actions/cart'
import { clearOrderInProgress } from '../actions/order'

import { getOrderInProgress } from '../selectors/order'
import { getDeviceSubtype } from '../selectors/config'
import { hasCartItems } from '../selectors/cart'
import { getAttendants, makeGetActiveAttendant } from '../selectors/attendant'
import { getIsKiosk, getRequireManagerPinForConfiguration } from '../selectors/menus'
import { getSafIsUploading } from '../selectors/peripheral'

import styles from './NavMenu.module.scss'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import { bridge_updateCFDVersion } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'

import { isTabbed } from '../utils/orderStates'
import useSpinner from '../context/hooks/useSpinner'
import { pushAfterNextRerender } from '../utils/history'

const NavMenu = ({ history, deviceSubtype, cartItemsExist, onToggle, logout, attendantName,
    attendantsExist, refreshConfig, className, isKiosk, safIsUploading, setSafIsUploading,
    clearCart, clearOrderInProgress }) => {
  const classNames = cn(styles.navMenu, className)
  const [confirmLogout, setConfirmLogout] = useState(false)
  const [hamburgerClickedWhileSyncing, setHamburgerClickedWhileSyncing] = useState(false)
  const [showConfirmAbandonCartModal, setShowConfirmAbandonCartModal] = useState(false)
  const isMobileViewPort = useGetIsMobileScreen()
  const orderInProgress = useSelector((state) => getOrderInProgress(state))
  const requireManagerPinForConfiguration = useSelector((state) => getRequireManagerPinForConfiguration(state))

  let confirmAbandonCartElement = null
  const abandonCartAction = useRef(() => {})

  const { startSpinner } = useSpinner()

  const {
    openManagerApproval,
    setManagerApprovalOnSuccess,
    setManagerApprovalButtonText,
  } = useManagerApproval()

  let logoutContent = (
    <div className={styles.loggedInContainer}>
      <span className={styles.name}>{attendantName}</span>
      <span className={styles.logoutText}>{attendantName ? 'Logout' : 'Login'}</span>
    </div>
  )

  const checkLocationAndStartSpinner = (to) => {
    //this avoids starting the spinner if user is already in the screen being routed
    if (history.location.pathname !== to) {
      startSpinner()
    }
  }

  const onClickLogout = () => {
      logout()
      history.replace('/user-login')
  }

  const handleLoginLogout = () => {
    if (!confirmLogout && attendantName) {
      setConfirmLogout(true)
    } else if (!attendantName) {
        logout()
        history.replace('/user-login')
    }
  }

  if (confirmLogout) {
    logoutContent = (
      <ConfirmModalV2
        headerText={'Logout?'}
        subtext={'Are you sure you would like to logout?'}
        buttonOneText={'CANCEL'}
        buttonTwoText={'LOGOUT'}
        onButtonOneClick={() => setConfirmLogout(false)}
        onButtonTwoClick={onClickLogout}
      />
    )
  }

  let logoutContainer = null

  if (attendantsExist) {
    logoutContainer = (
      <div className={styles.logout} onClick={handleLoginLogout}>
        {logoutContent}
      </div>
    )
  }

  const onClickMenu = () => {
    if (!isTabbed(orderInProgress)) {
      history.push('/concession-order')
      return onToggle()
    }

    //we're in a tab and have items
    if(cartItemsExist) {
      setShowConfirmAbandonCartModal(true)
      abandonCartAction.current = () => {
        clearCart()
        clearOrderInProgress()
        history.push('/concession-order')
      }
      return
    }

    //tab and no items
    clearOrderInProgress()
    history.push('/concession-order')
    return onToggle()
  }

  const onClickOrders = () => {
    if(isKiosk) {
      history.push('/menuSelection')
      return onToggle()
    }

    const toOrders = '/orders'

    if (!isTabbed(orderInProgress) || !cartItemsExist) {
      checkLocationAndStartSpinner(toOrders)
      clearOrderInProgress()
      pushAfterNextRerender(toOrders)
      return onToggle()
    }

    //tabbed order with items
    setShowConfirmAbandonCartModal(true)
    abandonCartAction.current = () => {
      checkLocationAndStartSpinner(toOrders)
      clearCart()
      clearOrderInProgress()
      pushAfterNextRerender(toOrders)
    }
  }

  const navigateToConfiguration = () => {
    clearOrderInProgress()
    history.push({
      pathname: '/revenue-center-setup',
      state: { prevLocation: history.location.pathname}
    })

    return onToggle()
  }

  const onClickConfiguration = () => {
    const action = () => { 
      if (!requireManagerPinForConfiguration) {
        return navigateToConfiguration()
      }
      
      setManagerApprovalOnSuccess(() => {
        return navigateToConfiguration()
      })
      setManagerApprovalButtonText('Configuration')
      openManagerApproval()
    }

    if (!cartItemsExist) {
      action()
      return
    }

    setShowConfirmAbandonCartModal(true)
    abandonCartAction.current = action
  }

  const onClickSyncNetworkData = () => {
    const action = () => {
        networkConnected().then(() => {

        // need to tell the CFD to update its version, we really don't care if there isn't a CFD attached
        // since if there is nothing, the bridge just silently fails
        bridge_updateCFDVersion()

        window.location = `${window.location.href}?cacheBuster=${new Date().toISOString()}`
      })
    }

    if (!cartItemsExist) {
      action()
      return
    }

    setShowConfirmAbandonCartModal(true)
    abandonCartAction.current = action
  }

  const onClickDebugInfo = () => {
    //need to check if we're in a tab with items before exiting
    if (isTabbed(orderInProgress) && cartItemsExist) {
      setShowConfirmAbandonCartModal(true)
      abandonCartAction.current = () => {
        clearCart()
        clearOrderInProgress()
        history.replace('/debug-info')
      }
      return
    }

    history.replace('/debug-info')
    return onToggle()
  }

  const onClickKiosk = () => {
    history.replace('/kiosk/attract_loop')
    return onToggle()
  }

  const shoppingCartIcon = isMobileViewPort ? (
    <MobileShoppingCartOutlinedIcon />
  ) : (
    <ShoppingCartOutlinedIcon />
  )

  const abandonCartMessage = isMobileViewPort
    ? "Items in cart will be removed upon syncing or navigating away from the menu. Do you wish to continue?"
    : "Items in cart will be removed upon\n syncing or navigating away from the\n menu. Do you wish to continue?"

  //TODO T32579 Create a common abandon cart modal
  if(showConfirmAbandonCartModal) {
    confirmAbandonCartElement = (
      <ConfirmModalV2
        onButtonOneClick={() => {
          setShowConfirmAbandonCartModal(false)
          onToggle()
        }}
        onButtonTwoClick={() => {
          setShowConfirmAbandonCartModal(false)
          abandonCartAction.current()
          onToggle()
        }}
        headerText={shoppingCartIcon}
        subtext={abandonCartMessage}
        isKiosk={isKiosk}
        buttonOneText={'Cancel'}
        buttonTwoText={'Abandon Cart'}/>
    )
  }

  const onClickRefreshConfig = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (cartItemsExist) {
      setShowConfirmAbandonCartModal(true)
      abandonCartAction.current = () => {
        clearOrderInProgress()
        refreshConfig()
      }
    } else {
      clearOrderInProgress()
      refreshConfig()
      onToggle()
    }
  }

  /**
   * @deprecated marked for deletion
   */
  // eslint-disable-next-line no-unused-vars
  const onHamburgerClick = () => {
    if (safIsUploading) {
      setHamburgerClickedWhileSyncing(true)
      return
    }

    onToggle()
  }

  const onStopSyncClick = () => {
    setSafIsUploading(false)
    setHamburgerClickedWhileSyncing(false)
  }

  const stopSyncMessage = "These functions are not supported while a sync is in progress.  Stopping the sync will leave some orders unsynced."

  return (
    <div className={classNames}>
      <div className={styles.sidebarContainer}>
        <div className={styles.header}>
          <div className={styles.headerText}>Quick Service</div>
        </div>
        {hamburgerClickedWhileSyncing && (
          <ConfirmModalV2
          headerText={''}
          subtext={stopSyncMessage}
          buttonOneText={'Stop Sync'}
          buttonTwoText={'Continue Sync'}
          onButtonOneClick={onStopSyncClick}
          onButtonTwoClick={() => setHamburgerClickedWhileSyncing(false)}
          />
        )}
        <div className={styles.links}>
          {DeviceSubtypes.isConcessions(deviceSubtype) && <div onClick={onClickMenu} title='Menu'>Menu</div>}
          {isKiosk && <div onClick={onClickKiosk} title='Kiosk'>Kiosk</div>}

          <div onClick={onClickOrders} title='Orders'>Orders</div>
          <div onClick={onClickConfiguration} title='Configuration'>Configuration</div>
          <div onClick={onClickRefreshConfig} title='Refresh Config'>Refresh Config</div>
          <div onClick={onClickSyncNetworkData} title='Sync Network Data'>Sync Network Data</div>
          <div onClick={onClickDebugInfo} title='Debug Info'>Debug Info</div>
        </div>
        {logoutContainer}
        {confirmAbandonCartElement}
        <div className={styles.appVerContainer} >
          <div className={styles.appVersion}>
            <span>{process.env.REACT_APP_VERSION || 'unknown'}</span>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => onToggle()} />
    </div>
  )
}

NavMenu.propTypes = {
  onToggle: PropTypes.func,
}

const mapStateToProps = (state) => {
  const deviceSubtype = getDeviceSubtype(state)
  const cartItemsExist = hasCartItems(state)
  const attendantsExist = !isEmpty(getAttendants(state))
  const getActiveAttendant = makeGetActiveAttendant()
  const activeAttendant = getActiveAttendant(state)
  const isKiosk = getIsKiosk(state)
  const safIsUploading = getSafIsUploading(state)

  return {
    deviceSubtype,
    cartItemsExist,
    attendantName: activeAttendant?.name ?? '',
    attendantsExist,
    isKiosk,
    safIsUploading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshConfig: () => dispatch(refreshConfig()),
    logout: () => dispatch(logout()),
    setSafIsUploading: (isUploading) => dispatch(onSafIsUploading(isUploading)),
    clearCart: () => dispatch(clearCart()),
    clearOrderInProgress: () => dispatch(clearOrderInProgress())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavMenu))
