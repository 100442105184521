import { get, isEmpty } from 'lodash'

export const getCurrentConfiguration = (state) => get(state, 'config')

export const getDeviceSubtype = (state) => get(state, 'config.deviceSubtype')

export const getIsPrintController = (state) => {
    return !isEmpty(get(state, 'config.configuration.controlledPrinterUuids', [ ]))
}

export const getSelectedMenusIds = (state) => state?.config?.configuration?.menuUuids ?? []

export const getDeviceWhitelist = (state) => state?.config?.whitelist ?? [ 'https://www.google.com', 'https://fast.com' ]

export const getShouldWaitForRedirect = (state) => state?.config?.shouldWaitForRedirect ?? false

export const getBackbarMode = (state) => state?.config?.backbarMode ?? false