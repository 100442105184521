const CartDetailsViewModel = ({ subtotal, tax, serviceCharge, total, discountSelected }) => {
  return {
    subtotal: `$${(subtotal / 100).toFixed(2)}`,
    tax: `$${(tax / 100).toFixed(2)}`,
    serviceCharge: `$${(serviceCharge / 100).toFixed(2)}`,
    total: `$${(total / 100).toFixed(2)}`,
    discountSelected,
  }
}

export default CartDetailsViewModel
