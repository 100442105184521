import React, { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isEmpty } from 'lodash'

import styles from './CartItem.module.scss'
import { ReactComponent as CloseIcon } from '../assets/icons/Close-Icon.svg'

const CartItem = ({ id, name, modifiers, price, quantity, color, onRemove, onSelect, focusItem, clearFocus, isDiscount, isLocation, className, remoteOrderTotalLoading }) => {
  const classNames = cn(styles.cartItem, className)
  let modifiersElement = null
  const mods = modifiers.split(", ").join("\n")


  const cartItemRef = useRef()

  if (!isEmpty(modifiers)) {
    modifiersElement = <span className={styles.modifiers}>{mods}</span>
  }

  const handleOnRemove = (event) => {
    if(remoteOrderTotalLoading) return
    event.preventDefault()
    event.stopPropagation()

    onRemove(id)
  }

  useEffect(() => {
    if (cartItemRef.current && focusItem) {
      cartItemRef.current.scrollIntoView()
      clearFocus()
    }
  }, [])

  return (
    <div className={classNames} onClick={onSelect} ref={cartItemRef}>
      {!isDiscount && !isLocation && <div className={styles.column}>
        <span className={styles.quantity}>{quantity}</span>
      </div>}
      <div className={styles.column}>
        <span className={cn([styles.name, isDiscount && styles.isDiscount])}>{name} {price}</span>
        {modifiersElement}
      </div>
      <div className={styles.column}>
        <CloseIcon className={styles.closeIcon} onClick={handleOnRemove} />
      </div>
    </div>
  )
}

CartItem.defaultProps = {
  id: '',
  name: '',
  modifiers: '',
  price: '',
  quantity: 1,
  color: '',
  onRemove: () => {},
  onSelect: () => {},
}

CartItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  modifiers: PropTypes.string,
  price: PropTypes.string,
  quantity: PropTypes.number,
  color: PropTypes.string,
  onRemove: PropTypes.func,
  className: PropTypes.string,
}

export default memo(CartItem)
