import { get, keys, map, isEmpty, compact, flatMap, cloneDeep } from 'lodash'
import DataMapper from '../utils/dataMapper'
import ModifierGroupsViewModel from '../viewmodels/ModifierGroupsViewModel'

import { MENUS_ITEMS_SUCCEEDED } from '../actions/menus'

const defaultState = { byMenuId: {} }

const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case MENUS_ITEMS_SUCCEEDED:
      const byMenuId = payload.reduce((result, response) => {
        const menuUuid = get(response, 'data.menu.uuid')
        const items = get(response, 'data.items', [])
        const serviceFeeItems = get(response, 'data.serviceFeeItems', [])

        const mappedItems = map(cloneDeep(items), (item) => {
          if (isEmpty(item.modifierGroups)) return item

          item.modifierGroups = ModifierGroupsViewModel(item.modifierGroups)
          return item
        })

        const itemsById = DataMapper.objectsById(mappedItems)

        const mappedModifierGroups = compact(map(items, DataMapper.itemModifiersMapper))
        const modifierItemsById = DataMapper.objectsById(flatMap(mappedModifierGroups, (modifierGroup) => {
          return flatMap(modifierGroup, (modifier) => modifier.items)
        }))

        const byId = { ...itemsById, ...modifierItemsById }
        const allIds = [ ...keys(itemsById), ...keys(modifierItemsById) ]

        return {
          ...result,
          [menuUuid]: {
            byId: byId,
            allIds: allIds,
            serviceFeeItems
          }
        }
      }, {})

      return { byMenuId }

    default:
      return state

  }
}

export default reducer

