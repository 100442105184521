import cn from "classnames"

const Record = ({ name, icon, styles, disabled, ...props }) => {
  return (
    <div {...props} className={cn([styles.record, disabled && styles.disabled])}>
      <div className={styles.menuDetails}>
        <div className={styles.svgIconContainer}>{icon}</div>
        <div>{name}</div>
      </div>
    </div>
  )
}

export default Record