import numeral from 'numeral'

export const floatToDollars = (amount) => {
  return numeral(amount).format('0.00')
}

export const centsToFloat = (amountInCents) => {
  return (numeral(amountInCents / 100)).format('0.00')
}

export const stringFloatToCents = (amount) => {
  return parseFloat(amount) * 100
}