/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import { APPLICATION_STRINGS } from "../strings"

import usePrevious from "../hooks/usePrevious"

import SidebarModal from "./SidebarModal"
import Button from "./Button"
import ConfirmSplitPaymentDialog from "./dialog/ConfirmSplitPaymentDialog"
import { setKitchenPrintErrorDialogOpen } from "../VNDialogs/ActionCreators"
import { getIsKeyboardActive } from "../VNAndroidSDK/Selectors"

import styles from "./CartModal.module.scss"

import { setBridgePaymentMethod } from "../actions/peripheral"

import CartItems from "../containers/CartItems"

import ConfirmModalV2 from '../components/ConfirmModalV2'

import useOfflineModal from "../hooks/useOfflineModal"
import useRemoteOrderTotal from "../hooks/useRemoteOrderTotal"
import useGetIsMobileScreen from "../hooks/useGetIsMobileScreen"

import { getOrderInProgress } from "../selectors/order"
import { getRequirePaymentForTabs } from "../selectors/menus"
import { getKitchenPrintingEnabled } from "../selectors/menus"

import { bridgePaymentMethods } from "../reducers/peripheral"

import { ToastManager } from '../utils/toastManager'
import { isTabbed } from '../utils/orderStates'
import { getNetworkAvailableValue } from "../utils/networkConnected"

const CartModal = ({
  history,
  hasCartItems,
  clearCart,
  className,
  cartItems,
  total,
  itemCount,
  discountsAvailable,
  noOpPayments,
  signatureRequired,
  tipSuggestions,
  cashEnabled,
  networkAvailable,
  ticketEnabled,
  mobilePaySetting,
  clearPromotionsOnOrder,
  giftCardEnabled,
  tabsModeEnabled,
  mutationInProgress,
  startATab,
  addItemsToTab,
  addItemsToTabOffline
}) => {
  const classNames = cn(styles.cartModal, className);
  const [showCart, setShowCart] = useState(false);
  const [showSplitPayDialog, setShowSplitPayDialog] = useState(false);
  const [showStartTabModal, setShowStartTabModal] = useState(history?.location?.state?.tabNameRequired ?? false);
  const [showSelectTabPaymentMethod, setShowSelectTabPaymentMethod] = useState(false)
  const [updatingTabOrder, setUpdatingTabOrder] = useState(false);
  const [checkIfOfflineWithCallback, offlineModal] = useOfflineModal()
  const [loadRemoteOrderTotal, { data: success, failed, loading }] = useRemoteOrderTotal()
  const isKeyboardActive = useSelector(state => getIsKeyboardActive(state))
  const cloudPrintingEnabled = useSelector(state => getKitchenPrintingEnabled(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const prevCartItems = usePrevious(cartItems);
  const prevOrderInProgress = usePrevious(orderInProgress);
  const requirePaymentForTabs = useSelector(state => getRequirePaymentForTabs(state))

  const cardReaderData = history?.location?.state?.cardReaderData ?? null;
  const dispatch = useDispatch()
  const processingPromotions = useRef(false)

  const buttonsDisabled = !hasCartItems;
  const tipEnabled = !isEmpty(tipSuggestions)
  const isMobileViewPort = useGetIsMobileScreen()
  const isTabOrder = isTabbed(prevOrderInProgress)
  let modal = null;

  const clearItemsAndPromotions = () => {
    clearCart()
    clearPromotionsOnOrder()
  }

  useEffect(() => {
    if (prevCartItems && !isEmpty(prevCartItems) && isEmpty(cartItems)) {
      setShowCart(false);
      if (!updatingTabOrder) {
        ToastManager.success('Your cart has been cleared.')
      }
      setUpdatingTabOrder(false)
    }
  }, [cartItems]);

  useEffect(() => {
    if ((success || failed) && !processingPromotions.current) {
      handleCardTransaction()
    }
    processingPromotions.current = false
  }, [success, failed]);

  const processPromotions = async () => {
    try {
      processingPromotions.current = true
      await loadRemoteOrderTotal()
    } catch (e) {
      processingPromotions.current = false
      ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
      //continue processing if orderTotal fails and we can't apply promos
      return Promise.resolve()
    }
  }

  const initiateTab = async (tabName) => {
    if (!tabName) return

    if (getNetworkAvailableValue()) {
      await processPromotions()
    }

    const tabData = {
      tabName
    }

    if(cardReaderData) {
      tabData.cardReaderData = cardReaderData
      tabData.paymentType = history?.location?.state?.paymentType
      tabData.paymentId = history?.location?.state?.paymentId
    }

    startATab(tabData)
    setShowStartTabModal(false)
    setUpdatingTabOrder(true)

    if (cloudPrintingEnabled && !getNetworkAvailableValue()) {
      dispatch(setKitchenPrintErrorDialogOpen(true))
    }

    history.replace(history.location.pathname, {})
  }

  if (showSelectTabPaymentMethod) {
    modal = (
      <ConfirmModalV2
        headerText={'Tab Payment Method'}
        emptyButtonContainer
        displayCloseButton
        onCloseButtonClick={() => setShowSelectTabPaymentMethod(false)}
        classNames={{
          confirmModalV2: styles.ConfirmModalV2,
          dialogContainer: styles.dialogContainer,
          container: styles.confirmModalContainer,
          textBox: styles.textBox,
        }}
        subtext={
          <div style={{display: "flex", flexDirection: 'column'}}>
            <Button onClick={() => {
              dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeAuthRequest))
              history.push("/card-reader", {redirectPath: '/concession-order', authTabs: true});
            }} style={{height: 72}}>
              <span>Authorize Card Now</span>
            </Button>
            <Button onClick={() => {
              setShowStartTabModal(true)
              setShowSelectTabPaymentMethod(false)
            }} style={{marginTop: 24, height: 72}}>
              <span>Add Payment Later</span>
            </Button>
          </div>
        }
      />
    );
  }

  if (showStartTabModal) {
    modal = (
      <ConfirmModalV2
        headerText={'Tab Name'}
        singleButtonOnly
        initialInputValue={cardReaderData?.cardholderName || ''}
        buttonTwoText={'SAVE'}
        onButtonTwoClick={initiateTab}
        useInput={true}
      />
    );
  }

  const handleCardTransaction = () => {
    if (signatureRequired || tipEnabled) {
      history.push("/signature");
    } else {
      history.push("/card-reader");
    }
  };

  // TODO (ZAID) send to android

  const handleScanScreenSelection = (scanScreenPath) => {
    if (tipEnabled) {
      history.push({
        pathname: '/signature',
        state: {
          addTipThenRedirectPath: scanScreenPath,
        }
      })
      return
    }
    history.push(scanScreenPath)
  }

  /**
   * addToTab logic:
   *
   *          order in Stadium   | order not in Stadium
   *         +-------------------+---------------------+
   * online  | call mutation api | add items locally   |
   *         +-------------------+---------------------+
   * offline | can't add items   | add items locally   |
   *         +-------------------+---------------------+
   *
   */
  const addToTab = async () => {
    setUpdatingTabOrder(true)
    const networkConnected = getNetworkAvailableValue()

    //Online processing
    if (networkConnected) {
      //Order aready in Stadium then we can mutate the order
      if (orderInProgress.wasCreatedInStadium) {
        await processPromotions()

        addItemsToTab()
        return
      }
      //Order is not in Stadium so even we're online we can't mutate we can just do a local update
      addItemsToTabOffline()
      return
    }

    //offline and order already in stadium then we need to error off
    if (orderInProgress.wasCreatedInStadium) {
      ToastManager.error("Items can't be added offline for this order")
      return
    }

    if (cloudPrintingEnabled) {
      dispatch(setKitchenPrintErrorDialogOpen(true))
    }

    //offline and order is not in stadium
    addItemsToTabOffline()
  }

  const offlineModalScanScreenSubtext = isMobileViewPort
    ? "This payment tender is not\n supported while the device is offline"
    : "This payment tender is not supported\nwhile the device is offline"

  const offlineGiftCardModalSubtext = isMobileViewPort
    ? "Gift card tender is not supported\n while the device is offine"
    : "Gift card tender is not supported while\n the device is offine"

  const deviceIsOfflineSubtext = "Sorry, this device is now offline. Please choose another method of payment."

  const trueButtonCount =
    !!cashEnabled +
    !isEmpty(noOpPayments) +
    !!mobilePaySetting +
    !!ticketEnabled +
    !!giftCardEnabled + 1

  let buttonCount;
  if (trueButtonCount > 4) {
    buttonCount = 4;
  } else {
    buttonCount = trueButtonCount;
  }

  const oddButtonCount = buttonCount % 2 === 1;

  const showOtherButton = trueButtonCount > 3;

  const buttons = []
  if (!tabsModeEnabled) {
    buttons.push(
      <Button
        className={cn([
          styles.checkout,
          styles.short,
        ])}
        disabled={buttonsDisabled}
        onClick={() => loadRemoteOrderTotal()}
      >
        {"Card"}
      </Button>
    )
  } else {
    if (!isTabOrder) {
      buttons.push(
        <Button
          className={cn([
            styles.checkout,
            styles.short,
          ])}
          disabled={!hasCartItems}
          onClick={() => {
            if (discountsAvailable) {
              return history.push('/discounts')
            }
            history.push('/tender-selection')
          }}
        >
          {"Pay Now"}
        </Button>
      )
    }
  }
  // Quick Service Only Buttons
  if (!tabsModeEnabled) {
    if (cashEnabled) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          onClick={() => history.push("/change-calculator")}
        >
          {"Cash"}
        </Button>
      );
    }

    if (
      (buttons.length < 3 || (buttons.length < 4 && !showOtherButton)) &&
      mobilePaySetting
    ) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          onClick={() => {
            if (mobilePaySetting === "pos_shows_qr") {
              history.push("/rich-checkout");
              return;
            }
            checkIfOfflineWithCallback(() => handleScanScreenSelection('/qr-pay'), offlineModalScanScreenSubtext, true)
          }}
        >
          {mobilePaySetting === "pos_shows_qr" ? "App" : "QR Pay"}
        </Button>
      );
    }

    if (
      (buttons.length < 3 || (buttons.length < 4 && !showOtherButton)) &&
      ticketEnabled
    ) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          onClick={() => checkIfOfflineWithCallback(() => handleScanScreenSelection('/ticket'), offlineModalScanScreenSubtext, true)}
        >
          Ticket
        </Button>
      );
    }

    if (
      (buttons.length < 3 || (buttons.length < 4 && !showOtherButton)) &&
      giftCardEnabled
    ) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          onClick={() => checkIfOfflineWithCallback(() => handleScanScreenSelection('/gift-card'), offlineGiftCardModalSubtext, true)}
        >
          Gift Card
        </Button>
      );
    }

    if (showOtherButton || (buttons.length < 4 && !isEmpty(noOpPayments))) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          onClick={() => history.push("/tender-selection")}
        >
          Other
        </Button>
      );
    }

    buttons.push(
      <Button
        className={cn([styles.checkout, trueButtonCount >= 2 ? styles.long : styles.short])}
        onClick={() => checkIfOfflineWithCallback(() => setShowSplitPayDialog(true), deviceIsOfflineSubtext, true)}
      >
        Split
      </Button>
    );
  }

  const handleStartTab = () => {
    if (requirePaymentForTabs) {
      dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeAuthRequest))
      history.push("/card-reader", {redirectPath: '/concession-order', authTabs: true})

      return
    }
    setShowSelectTabPaymentMethod(true)
  }

  if (tabsModeEnabled) {
    if (isTabOrder) {
      buttons.push(
        <Button
          className={cn([styles.checkout, styles.long])}
          disabled={!hasCartItems || mutationInProgress}
          onClick={addToTab}
        >
          Add to Tab
        </Button>
      );
    } else {
      buttons.push(
        <Button
          className={cn([styles.checkout, styles.short])}
          disabled={!hasCartItems || mutationInProgress}
          onClick={handleStartTab}
        >
          Start a Tab
        </Button>
      );
    }
  }

  if (showSplitPayDialog) {
    modal = (
      <ConfirmSplitPaymentDialog
        onClose={() => setShowSplitPayDialog(false)}
        fromConcessions={true}
      />
    );
  }

  const headerText = isTabOrder ? orderInProgress.tabName : "New Order"

  return (
    hasCartItems && (
      <div className={classNames}>
        {!isKeyboardActive ?
          <div className={styles.cartButtonContainer} >
            <Button
              className={styles.showCartButton}
              onClick={() => setShowCart(true)}
            >
              <span className={styles.quantity}>{itemCount}</span>
              <span className={styles.title}>View Cart</span>
              <span className={styles.total}>{total}</span>
            </Button>
          </div>
        : null
        }
        <SidebarModal
          show={showCart}
          title={headerText}
          className={styles.cart}
          onClose={() => setShowCart(false)}
          isShowClear={true}
          clearCart={clearItemsAndPromotions}
        >
          <div className={styles.bodyContainer}>
            <div className={styles.scrollable}>
              <CartItems
                className={styles.cartItems}
                discountsAvailable={discountsAvailable}
                networkAvailable={networkAvailable}
                total={total}
                clearItemsAndPromotions={clearItemsAndPromotions}
                remoteOrderTotalLoading={loading}
              />
            </div>
            <div className={styles.buttonContainer}>{buttons}</div>
          </div>
        </SidebarModal>
        {modal}
        {offlineModal}
      </div>
    )
  );
};

export default withRouter(CartModal);
