import React from 'react'
import cn from 'classnames'

import styles from './SelectionGroup.module.scss'

const SelectionButton = ({ label, text, onClick, className }) => {
  const classNames = cn(styles.selectionButton, className)

  return (
    <div className={styles.selectionButtonWrapper}>
      <div onClick={onClick} className={classNames}>
        <span>{label}</span>
        <span>{text}</span>
      </div>
    </div>
  )
}

const SelectionGroup = ({ items, selectedIndex, onChange, className }) => {
  const classNames = cn(styles.selectionGroup, className)

  return (
    <div className={classNames}>
      {items?.map?.((item, index) => {
        const { label, text, disabled } = item
        const selected = selectedIndex === index

        const handleOnChange = () => {
          if (!disabled) onChange(item, index)
        }

        return (
          <SelectionButton
            key={index}
            label={label}
            text={text}
            onClick={handleOnChange}
            className={cn({ [styles.selected]: selected, [styles.disabled]: disabled })}
          />
        )
      })}
    </div>
  )
}

export default SelectionGroup
