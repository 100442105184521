import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import IdleWarning from './IdleWarning'
import Payment from '../Payment'
import ReceiptSelection from '../ReceiptSelection'
import NewOrder from '../NewOrder'
import ScannedScreen from '../ScannedScreen'
import ReceiptDetails from '../ReceiptDetails'
import KioskTicket from '../Ticket'
import KioskQRPay from '../QRPay'
import KioskGiftCard from '../GiftCard'

class Main extends Component {
  render() {
    return (
      <div className="kiosk-main">
        <IdleWarning />
        <Switch>
          <Route component={Payment} path="/kiosk/order/payment" />
          <Route component={KioskTicket} path="/kiosk/order/ticket" />
          <Route component={KioskQRPay} path="/kiosk/order/qr-pay" />
          <Route component={KioskGiftCard} path="/kiosk/order/gift-card" />
          <Route component={ReceiptSelection} path="/kiosk/order/receipt_selection" />
          <Route component={NewOrder} path='/kiosk/order/new' />
          <Route component={ScannedScreen} path="/kiosk/order/scanned" />
          <Route component={ReceiptDetails} path="/kiosk/order/summary/:orderUuid" />
        </Switch>
      </div>
    )
  }
}

export default Main
