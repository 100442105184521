import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import generateUuid from 'uuid/v4'

import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg'
import { ReactComponent as CreditCardIconMobile } from '../../assets/icons/credit-card-mobile.svg'

import { closeNoOpTender } from '../../actions/order'
import { clearRemoteOrderTotal } from '../../actions/orderTotalRemote'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { getTotal } from '../../selectors/cart'
import { getTipsEnabled } from '../../selectors/menus'
import ConfirmModalV2 from '../ConfirmModalV2'

const ConfirmSplitPayment = ({ placeFreeOrder, className, onClose, navigateToNextSteps, offlineTotal, tipsEnabled, clearRemoteOrderTotal, fromConcessions }) => {
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, success, failed }] = useRemoteOrderTotal()
  const noPaymentRequired = (remoteOrderTotal?.totalAmountInCents ?? offlineTotal) === 0
  const dialogText = "Please have all forms of payment ready before proceeding. Once used, you may not cancel a tender.";
  const isMobileViewPort = document?.body?.clientWidth < 500
  const creditCardIcon = isMobileViewPort ? <CreditCardIconMobile /> : <CreditCardIcon />

  useEffect(() => {
    loadRemoteOrderTotal()
  }, [])

  useEffect(() => {
    if (noPaymentRequired && (success || failed)) {
      placeFreeOrder(remoteOrderTotal?.uuid ?? generateUuid())
    }
  }, [success, failed])

  return (
    <ConfirmModalV2
      onButtonOneClick={() => {
        onClose()
        if (fromConcessions) {
          clearRemoteOrderTotal()
        }
      }}
      onButtonTwoClick={() => navigateToNextSteps(tipsEnabled)}
      headerText={creditCardIcon}
      subtext={dialogText}
      buttonOneText={'GO BACK'}
      buttonTwoText={'PROCEED'}
    />
  )
}

const mapStateToProps = (state, props) => {
  const offlineTotal = getTotal(state)
  const tipsEnabled = getTipsEnabled(state)

  return {
    offlineTotal,
    tipsEnabled,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const push = props?.history?.push ?? function () {}

  return {
    navigateToNextSteps: (tipsEnabled) => {
      if (tipsEnabled) {
        push({
          pathname: '/signature',
          state: {
            addTipThenRedirectPath: '/split-tender'
          }
        })
        return
      } 

      push('/split-tender')
    },
    placeFreeOrder: (orderUuid) => {
      dispatch(closeNoOpTender({ orderUuid }))
    },
    clearRemoteOrderTotal: () => dispatch(clearRemoteOrderTotal()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmSplitPayment))
