import React, { useState } from 'react'
import cn from 'classnames'
import { head } from 'lodash'

import SidebarModal from '../SidebarModal'
import BackChevron from '../BackChevron'
import Button from '../Button'
import { resolvePaymentIcon } from '../../utils/icons'
import { centsToDollars } from '../../utils/formatters'

import styles from './ReceiptSelect.module.scss'

const Payment = ({ selected, cardType, onSelect, paymentName, amount, className }) => {
  const classNames = cn(styles.payment, { [styles.selected]: selected }, className)
  const icon = React.cloneElement(resolvePaymentIcon(cardType), { className: styles.icon })
  
  const totalPaid = centsToDollars(amount)

  let selectionIcon = (
    <span className={styles.selectionIcon}>
      <span className={styles.selectionIconInner}></span>
    </span>
  )
  
  return (
    <li className={classNames} onClick={onSelect}>
      {selectionIcon}
      {icon} {paymentName} - {totalPaid}
    </li>
  )
}

const PaymentsList = ({ payments, onSelect, selectedPaymentId, className }) => {
  const classNames = cn(styles.paymentsList, className)

  return (
    <ul className={classNames}>
      {payments?.map?.((payment, index) => {
        const isSelected = selectedPaymentId === payment?.paymentId

        const handleOnSelect = () => {
          onSelect(payment?.paymentId, index)
        }

        return (
          <Payment
            selected={isSelected}
            key={payment?.paymentId ?? index}
            cardType={ head((payment?.tenderDisplayName ?? '').split(' '))}
            paymentName={payment?.tenderDisplayName ?? ''}
            amount={payment?.amountInCents}
            onSelect={handleOnSelect}
          />
        )
      })}
    </ul>
  )
}

const ReceiptSelect = ({
  onClose,
  className,
  payments,
  navigateToReceipt,
}) => {
  const classNames = cn(styles.details, className)
  const [selectedPayment, setSelectedPayment] = useState('')
  
  return (
    <SidebarModal
      show
      title="Receipt"
      onClose={onClose}
      leftAccessory={<BackChevron onClick={onClose} />}
      className={classNames}
    >
      <div className={styles.bodyContainer}>
        <p className={styles.selectTenderHeader}>Select a Receipt</p>
        <PaymentsList
          payments={payments}
          selectedPaymentId={selectedPayment?.paymentId}
          onSelect={(_, index) => setSelectedPayment(payments[index])}
        />
        <div className={styles.buttonContainer}>
          <Button
            key="view receipt"
            onClick={() => navigateToReceipt(selectedPayment?.paymentId)}
            className={cn(styles.actionButton)}
            disabled={!selectedPayment}
          >
            View Receipt
          </Button>
        </div>
      </div>
    </SidebarModal>
  )
}

export default ReceiptSelect
