const EXTRA_SPACE_TO_SEE_PARTIAL_ITEM_ABOVE = 28

exports.addPaddingAndScrollUpToElement = (el) => {
  const parent = el.parentElement.parentElement
  const defaultPadding = (getComputedStyle(parent).getPropertyValue('padding-top')).slice(0, -2)
  const scrollUpValue = el.offsetTop - parent.offsetTop - (defaultPadding * 2) - EXTRA_SPACE_TO_SEE_PARTIAL_ITEM_ABOVE
  const extraPadding = (parent.clientHeight - (parent.lastChild.offsetTop + parent.lastChild.offsetHeight - el.offsetTop))
  if (extraPadding > parent.style.paddingBottom.slice(0, -2)) {
    parent.style.paddingBottom = `${extraPadding}px`
  }
  parent.scrollTop = scrollUpValue
}

exports.ScrollUpToElement = (el) => {
  const parent = el.parentElement.parentElement
  const scrollUpValue = exports.getElementScrollPosition(el)
  parent.scroll({top: scrollUpValue, left: 0, behavior: 'smooth' }) 
}

exports.getElementScrollPosition = (el) => {
  const parent = el.parentElement.parentElement
  const defaultPadding = (getComputedStyle(parent).getPropertyValue('padding-top')).slice(0, -2)
  const scrollUpValue = el.offsetTop - parent.offsetTop - (defaultPadding * 2) - EXTRA_SPACE_TO_SEE_PARTIAL_ITEM_ABOVE
  return scrollUpValue
}

exports.addExtraPadding = (el) => {
  const parent = el.parentElement.parentElement
  const defaultPadding = (getComputedStyle(parent).getPropertyValue('padding-top')).slice(0, -2)
  const extraSpaceNeeded = (parent.clientHeight - (parent.lastChild.offsetTop + parent.lastChild.offsetHeight - el.offsetTop))
  const extraPadding = extraSpaceNeeded > defaultPadding ? extraSpaceNeeded : defaultPadding
  parent.style.paddingBottom = `${extraPadding}px`
}

exports.scrollToElementLeftRight = (el) => {
  const parent = el.parentElement
  let scrollToValue;
  if (el.offsetLeft + el.offsetWidth >= parent.offsetLeft + parent.clientWidth + parent.scrollLeft) {
    scrollToValue = el.offsetLeft - parent.offsetLeft
  } else if (el.offsetLeft - parent.offsetLeft >= parent.scrollLeft){
    scrollToValue = parent.scrollLeft
  } else if (el.offsetLeft + el.offsetWidth >= parent.offsetLeft + parent.clientWidth) {
    scrollToValue = parent.scrollLeft - parent.clientWidth
  } else {
    scrollToValue = 0
  }
  parent.scrollLeft = scrollToValue
}

exports.scrollingHasStopped = (el) => {
  const parent = el.parentElement.parentElement
  const elScrollPosition = exports.getElementScrollPosition(el)
  return Math.round(elScrollPosition) === Math.round(parent.scrollTop) || Math.floor(parent.scrollTop) === 0
}
