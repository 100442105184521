import { ReactComponent as Unchecked } from '../../assets/icons/fill-unchecked.svg'
import { ReactComponent as Checkmark } from '../../assets/icons/fill-checkmark.svg'
import cn from 'classnames'
import styles from './Discount.module.scss'
import { useMemo } from 'react'

const Discount = ({ discount, index, selectedDiscounts, setSelectedDiscounts }) => {
  const isSelected = useMemo(
    () => !!selectedDiscounts.find((selectedDiscount) => selectedDiscount.uuid === discount.uuid),
    [selectedDiscounts, discount.uuid],
  )

  const onSelect = (discount) => {
    const discountIndex = selectedDiscounts.findIndex((selectedDiscount) => selectedDiscount.uuid === discount.uuid)

    if (discountIndex > -1) {
      setSelectedDiscounts(() => selectedDiscounts.filter((_, index) => index !== discountIndex))
      return
    }
    setSelectedDiscounts(() => [...selectedDiscounts, discount])
  }

  const checkmarkElement = isSelected ? (
    <Checkmark className={styles.checkmark} />
  ) : (
    <Unchecked className={cn([styles.checkmark, styles.unchecked])} />
  )

  return (
    <div
      className={cn([styles.discount, isSelected && styles.selected])}
      key={`${discount.uuid}-${index}`}
      onClick={() => onSelect(discount)}
    >
      <div className={styles.column}>{checkmarkElement}</div>
      <div className={styles.column}>{discount.name}</div>
    </div>
  )
}

export default Discount
