import semver from 'semver'

/**
 * Determine if a feature meets the minimum android SDK version.
 * @param {String} androidSDKVersion - the Android SDK Version from VNAndroidSDK - version state
 * @param {Strings} minimumVersion - The minimum android sdk sem version.
 * @returns {Boolean} - Returns ture if compatible, false otherwise (ignores pre-releases)
 */
export const isSDKCompatible = (androidSDKVersion, minimumVersion) => {
  return androidSDKVersion && semver.gte(androidSDKVersion, minimumVersion, {includePrerelease: true})
}

export const isRunningOnAndroid = () => {
  if (window.VN_DATA_BRIDGE !== undefined) return true

  const urlParams = new URLSearchParams(window.location.search)
  const isApk = urlParams.get('apk')
  if (isApk) return true

  return false
}