import get from 'lodash/get'
import { UPDATE_HIGHLIGHTED_ITEMS, UPDATE_SEARCH_QUERY, UPDATE_FOCUSED_ITEM_ID, UPDATE_SELECTED_CATEGORY, UPDATE_QUICK_SEARCH_FLAG } from '../actions/search'

const defaultState = {
  useQuickSearchFlag: false
}

const reducer = (state = defaultState, action) => {

  switch(action.type) {
    case UPDATE_HIGHLIGHTED_ITEMS:
      const searchParams = get(action.payload, 'searchParams', {})
      const hasQuickSearchResults = !!(get(searchParams, 'highlightedIds', []).length)
        
      return {
        ...state,
        ...searchParams,
        hasQuickSearchResults,
      }

    case UPDATE_SEARCH_QUERY:
      
      const searchQuery = get(action.payload, 'searchQuery', '')
      return {
        ...state,
        searchQuery,
      }
    
    case UPDATE_FOCUSED_ITEM_ID:

        const focusedItemId = get(action.payload, 'focusedItemId', undefined)
        return { 
          ...state,
          focusedItemId,
        }

    case UPDATE_SELECTED_CATEGORY: 

        const selectedCategory = get(action.payload, 'selectedCategory')
        return {
          ...state,
          selectedCategory,
        }

    case UPDATE_QUICK_SEARCH_FLAG: 

        const useQuickSearchFlag = get(action.payload, 'useQuickSearchFlag')
        return {
          ...state,
          useQuickSearchFlag,
        }

    default:
      return state

  }
}

export default reducer
