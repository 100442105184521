export const UPDATE_MODE = 'UPDATE_MODE'
export const REFRESH_CONFIG = 'REFRESH_CONFIG'
export const SET_NETWORK_AVAILABLE = 'SET_NETWORK_AVAILABLE'
export const GET_CONFIG_REQUESTED = 'GET_CONFIG_REQUESTED'
export const SHOULD_WAIT_FOR_REDIRECT = 'SHOULD_WAIT_FOR_REDIRECT'
export const ENABLE_BACKBAR_MODE = 'ENABLE_BACKBAR_MODE'
export const DISABLE_BACKBAR_MODE = 'DISABLE_BACKBAR_MODE'

export const updateMode = (mode = 'concession') => ({
  type: UPDATE_MODE,
  payload: { mode }
})

export const refreshConfig = () => ({
  type: REFRESH_CONFIG,
})

export const setNetworkAvailable = (payload) => ({
  type: SET_NETWORK_AVAILABLE,
  payload,
})

export const shouldWaitForRedirect = (payload) => ({
  type: SHOULD_WAIT_FOR_REDIRECT,
  payload,
})
export function loadConfig(payload) {
  return {
    type: GET_CONFIG_REQUESTED,
    payload,
  }
}

loadConfig.type = GET_CONFIG_REQUESTED

export const enableBackbarMode = () => ({
  type: ENABLE_BACKBAR_MODE
})

export const disableBackbarMode = () => ({
  type: DISABLE_BACKBAR_MODE
})
