import React, { useState } from "react"
import PropTypes from "prop-types"

import ImageVersioningContext from "../context/ImageVersioningContext"
import useInterval from "../../hooks/useInterval"

const ONE_HOUR = 3600000

const ImageVersioning = ({ children }) => {
  const [version, setVersion] = useState(Date?.now())
  const getUrlWithVersion = (url) => {
    return `${url}?v=${version}`
  }

  useInterval(() => {
    setVersion(Date.now())
  }, [ONE_HOUR])

  return (
    <ImageVersioningContext.Provider
      value={{
        version,
        getUrlWithVersion,
      }}
    >
      {children}
    </ImageVersioningContext.Provider>
  )
}

ImageVersioning.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ImageVersioning
