import React from 'react'
import cn from 'classnames'

import Dialog from './Dialog'

import styles from './ConfirmDialog.module.scss'

const ConfirmDialog = ({ title, message, cancelButtonText, confirmButtonText, onCancel, onConfirm, className }) => {
  const classNames = cn(styles.confirmDialog, className)

  return (
    <Dialog title={title} message={message} className={classNames}>
      <div className={styles.buttonGroup}>
        <button onClick={onConfirm}>{confirmButtonText}</button>
        <button onClick={onCancel}>{cancelButtonText}</button>
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
