import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import Remote from '../remote'
import createDataDogLog from '../utils/datadog'
import { useDispatch } from 'react-redux'
import { setIsLocationPickerActive } from '../VNLocationPicker/ActionCreators'
import { getNetworkAvailableValue } from '../utils/networkConnected'

const useGetSeats = () => {
  const [dataLoading, setLoading] = useState(false)
  const [dataSucceeded, setSuccess] = useState(false)
  const [dataFailed, setFailed] = useState(false)
  const [sections, setSections] = useState([])
  const [rows, setRows] = useState([])
  const [seats, setSeats] = useState([])
  const dispatch = useDispatch()
  const networkAvailable = getNetworkAvailableValue()

  const cancelTokenRef = useRef()
  useEffect(() => {
    cancelTokenRef.current = axios.CancelToken.source()

    return () => {
      cancelTokenRef.current.cancel()
    }
  }, [])

  const getSections = (standUuid) => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)

    Remote.getSections(standUuid).then((result) => {
      const returnedSections = result?.data?.map((section) => {
        return {
          name: section?.name,
          isSuite: section?.isSuite
        }
      })

      if (returnedSections && returnedSections.length) {
        dispatch(setIsLocationPickerActive(true))
      } else if (networkAvailable){
        dispatch(setIsLocationPickerActive(false))
      }

      setSections(returnedSections)
      setLoading(false)
      setSuccess(true)
    }).catch((err) => {
      if (axios.isCancel(err)) return

      const errorMessage = err?.response?.data?.errorMessage ?? ''

      if (networkAvailable) {
        dispatch(setIsLocationPickerActive(false))
      }
      createDataDogLog('error', `There was an error in retrieving sections, ERROR: ${err ?? 'No Error Sent'}`, { errorMessage })
      setLoading(false)
      setFailed(true)
    })
  }

  const getRows = (sectionName) => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)
    setRows([])
    setSeats([])

    Remote.getRows(sectionName).then((result) => {
      setRows(result?.data)
      setLoading(false)
      setSuccess(true)
    }).catch((err) => {
      if (axios.isCancel(err)) return

      const errorMessage = err?.response?.data?.errorMessage ?? ''

      createDataDogLog('error', `There was an error in retrieving rows, ERROR: ${err ?? 'No Error Sent'}`, { errorMessage })
      setLoading(false)
      setFailed(true)
    })
  }

  const getSeats = (sectionName, row) => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)
    setSeats([])

    Remote.getSeats(sectionName, row).then((result) => {
      setSeats(result?.data?.map((seat) => {
        return seat?.seat
      }))
      setLoading(false)
      setSuccess(true)
    }).catch((err) => {
      if (axios.isCancel(err)) return

      const errorMessage = err?.response?.data?.errorMessage ?? ''

      createDataDogLog('error', `There was an error in retrieving seats, ERROR: ${err ?? 'No Error Sent'}`, { errorMessage })
      setLoading(false)
      setFailed(true)
    })
  }

  return [
    getSections, getRows, getSeats,
    {
        sections, rows, seats,
        dataLoading, dataSucceeded, dataFailed
    }
  ]
}

export default useGetSeats