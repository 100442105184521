import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from './Button'
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg'

import styles from './NumberPad.module.scss'

const NumberPad = ({ decimal, backspace, onTapButton, onPressButton, className, ...props }) => {
  const classNames = cn(styles.numberPad, className)

  const buttons = [
    <Button key="1" onClick={() => onTapButton('1')} onMouseDown={() => onPressButton('1')} className={styles.numberPadButton}>1</Button>,
    <Button key="2" onClick={() => onTapButton('2')} onMouseDown={() => onPressButton('2')} className={styles.numberPadButton}>2</Button>,
    <Button key="3" onClick={() => onTapButton('3')} onMouseDown={() => onPressButton('3')} className={styles.numberPadButton}>3</Button>,
    <Button key="4" onClick={() => onTapButton('4')} onMouseDown={() => onPressButton('4')} className={styles.numberPadButton}>4</Button>,
    <Button key="5" onClick={() => onTapButton('5')} onMouseDown={() => onPressButton('5')} className={styles.numberPadButton}>5</Button>,
    <Button key="6" onClick={() => onTapButton('6')} onMouseDown={() => onPressButton('6')} className={styles.numberPadButton}>6</Button>,
    <Button key="7" onClick={() => onTapButton('7')} onMouseDown={() => onPressButton('7')} className={styles.numberPadButton}>7</Button>,
    <Button key="8" onClick={() => onTapButton('8')} onMouseDown={() => onPressButton('8')} className={styles.numberPadButton}>8</Button>,
    <Button key="9" onClick={() => onTapButton('9')} onMouseDown={() => onPressButton('9')} className={styles.numberPadButton}>9</Button>,
    <Button key="0" onClick={() => onTapButton('0')} onMouseDown={() => onPressButton('0')} className={cn(styles.numberPadButton, styles.zeroButton)}>0</Button>,
    <Button onClick={() => onTapButton('backspace')} onMouseDown={() => onPressButton('backspace')} className={styles.numberPadButton}><DeleteIcon className={styles.backspaceIcon} key="backspace" /></Button>
  ]

  return (
    <div {...props} className={classNames}>
      {buttons}
    </div>
  )
}

NumberPad.defaultProps = {
  onTapButton: () => {},
  onPressButton: () => {},
}

NumberPad.propTypes = {
  onTapButton: PropTypes.func,
  onPressButton: PropTypes.func,
}

export default NumberPad
