import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getUnsyncedOrderCounts } from '../selectors/order'
import { ReactComponent as BackButtonCircle } from '../assets/icons/backArrowCircle.svg'

import styles from './HeaderBar.module.scss'

const HeaderBar = ({ history }) => {
  const onHeaderTap = () => {
    history.push("/orders/device-summary")
  }
  
  const [activeOfflineCardCount, unsyncedLocalOrderCount] = useSelector((state) =>
    getUnsyncedOrderCounts(state)
  )

  const shouldDisplayHeader = activeOfflineCardCount > 0 || unsyncedLocalOrderCount > 0
  let displayText = `${activeOfflineCardCount} Offline Card Transactions`
  if (shouldDisplayHeader && activeOfflineCardCount <= 0) {
    displayText = `${unsyncedLocalOrderCount} Unsynced Orders`
  }

  return shouldDisplayHeader ? (
    <div className={styles.headerBar}>
      <div className={styles.text}>
        {displayText}
      </div>
      <BackButtonCircle className={styles.arrow} onClick={onHeaderTap} />
    </div>
  ) : null
}

HeaderBar.propTypes = { }

export default withRouter(HeaderBar)
