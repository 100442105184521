import { createOrderParams } from './orderUtils'
import { PAYMENT_TYPES } from './paymentTypes'

// Not all states listed here are (currently) used. There are also more states in Stadium not listed here.
// https://github.com/venuenext/stadium/blob/master/app/models/order/states.rb
export const ORDER_STATES = {
  'APPROVED_OFFLINE': 'approved_offline',
  'AUTHORIZATION_FAILED': 'authorization_failed',
  'CANCELLED': 'cancelled',
  'COMPLETED': 'completed',
  'COMPLETION_PENDING': 'completion_pending',
  'INCOMPLETE': 'incomplete',
  'PARTIALLY_REFUNDED': 'partially_refunded',
  'REFUNDED': 'refunded',
  'SETTLEMENT_FAILED': 'settlement_failed',
  'TABBED': 'tabbed',
  'TABBED_OFFLINE': 'tabbed_offline',
}

export const STADIUM_ORDER_STATES = {
  PROCESSING: 'processing',
  SUBMITTED: 'submitted',
  SUBMISSION_FAILED: 'submission_failed',
  AUTHORIZATION_FAILED: 'authorization_failed',
  COMPLETED: 'completed',
  COMPLETION_PENDING: 'completion_pending',
  APPROVED_OFFLINE: 'approved_offline',
  CANCELLED: 'cancelled',
  TABBED: 'tabbed',
  TABBED_OFFLINE: 'tabbed_offline',
}

export const INTERNAL_ORDER_STATES = {
  PREAUTH: 'preauth',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
}

export const isFinalState = (state) => {
  return [
    STADIUM_ORDER_STATES.AUTHORIZATION_FAILED,
    STADIUM_ORDER_STATES.COMPLETED
  ].includes(state)
}

export const isQRPay = (order) => {
  if (order?.displayName === 'QR Pay') {
    return true
  }
  
  for (let i = 0; i < order?.payments?.length; i++) {
    if (order.payments[i].paymentType === PAYMENT_TYPES.QR_PAY) {
      return true
    }
  }

  return false
}

export const isTabbed = (order) => {
  return order?.state === STADIUM_ORDER_STATES.TABBED ||
    createOrderParams(order)?.state === STADIUM_ORDER_STATES.TABBED ||
    order?.state === STADIUM_ORDER_STATES.TABBED_OFFLINE ||
    createOrderParams(order)?.state === STADIUM_ORDER_STATES.TABBED_OFFLINE
}

export const wasTabbed = (order) => {
  return order?.state === STADIUM_ORDER_STATES.COMPLETION_PENDING ||
    createOrderParams(order)?.state === STADIUM_ORDER_STATES.COMPLETION_PENDING
}

export const ORDERS_STATUS_OPTIONS = {
  OPEN_ORDERS: 'open orders',
  CLOSED_ORDERS: 'closed orders',
  ALL_ORDERS: 'all orders'
}

const CLOSED_ORDER_STATES = [
  ORDER_STATES['CANCELLED'],
  ORDER_STATES['COMPLETED'],
  ORDER_STATES['COMPLETION_PENDING'],
  ORDER_STATES['REFUNDED'],
  ORDER_STATES['SETTLEMENT_FAILED'],
]

const REMOVABLE_STATES = [
  ORDER_STATES['CANCELLED'],
]

class OrderStates {
  static isClosed = (orderState) => CLOSED_ORDER_STATES.includes(orderState)
  static isOpen = (orderState) => !CLOSED_ORDER_STATES.includes(orderState)
  static isRemovable = (orderState) => REMOVABLE_STATES.includes(orderState)
}

export default OrderStates
