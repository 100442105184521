import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './SectionCard.module.scss'

const SectionCard = ({ title, children, className }) => {
  const classNames = cn(styles.sectionCard, className)

  return (
    <div className={classNames}>
      <h2>{title}</h2>
			{children}
    </div>
  )
}

SectionCard.defaultProps = {
  title: '',
	children: undefined,
}

SectionCard.propTypes = {
  title: PropTypes.string,
	children: PropTypes.node,
}

export default SectionCard
