import { useContext, useEffect } from 'react'
import ManagerApprovalContext from '../ManagerApprovalContext'
import { useDispatch, useSelector } from 'react-redux'
import { getFetchAttendantsStatus } from '../../selectors/attendant'
import { cleanupState } from '../../actions/shared'
import { clearAttemptPinLoginStatus, fetchAttendants } from '../../actions/attendant'
import { fetchEmployeeRoles } from '../../actions/employeeRole'

const useManagerApproval = () => {
  const fetchAttendantsStatus = useSelector(getFetchAttendantsStatus)

  const dispatch = useDispatch()
  const useManagerApprovalContext = () => useContext(ManagerApprovalContext)

  const {
    openManagerApproval,
    closeManagerApproval,
    showManagerApproval,
    setManagerApprovalOnSuccess,
    setManagerApprovalOnFailure,
    setManagerApprovalButtonText,
  } = useManagerApprovalContext()

  useEffect(() => {
    dispatch(clearAttemptPinLoginStatus())
    dispatch(cleanupState())
    if (fetchAttendantsStatus !== 'success') {
      dispatch(fetchAttendants())
      dispatch(fetchEmployeeRoles())
    }
    
    //clear values to default as soon as hook is used
    setManagerApprovalOnSuccess(() => {})
    setManagerApprovalOnFailure(() => {})
    setManagerApprovalButtonText("")
  }, [])

  return {
    openManagerApproval,
    closeManagerApproval,
    showManagerApproval,
    setManagerApprovalOnSuccess,
    setManagerApprovalOnFailure,
    setManagerApprovalButtonText,
  }
}

export default useManagerApproval
