import React, { memo } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

const QRCodeImage = ({ data, color, size = 300 }) => {
  return (
    <QRCode includeMargin value={data} renderAs="svg" color={color} size={size}/>
  )
}

QRCodeImage.defaultProps = {
  data: 'error',
  color: '#000000',
}

QRCodeImage.propTypes = {
  data: PropTypes.string,
  color: PropTypes.string,
}

export default memo(QRCodeImage)
