import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import QRCodeReceipt from '../../components/receipt/QRCodeReceipt'

import { bridge_setCFDScreen } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../../VNCustomerFacingDisplay/Enums'
import { handlePpiReportOrder } from '../../VNPPI/Utils'
import { isEmpty } from '../../VNUtil/VNUtils'

import { clearCart } from '../../actions/cart'
import { printOrder } from '../../actions/printer'
import { clearPromotionsOnOrder } from '../../actions/promotion'

import { centsToDollars } from '../../utils/formatters'

import { getActiveAttendantId } from '../../selectors/attendant'
import { getTax } from '../../selectors/cart'
import { makeGetCurrentMenu } from '../../selectors/menus'
import { getOrderInProgress, makeGetOrder  } from '../../selectors/order'
import { getCloudPrintingEnabled } from '../../selectors/printer'
import { getActiveRevenueCenter, getMobilePaySetting } from '../../selectors/revenueCenter'

const mapStateToProps = (state, ownProps) => {
  const orderUuid = get(ownProps, 'match.params.orderUuid', undefined)
  let order = {}

  if (orderUuid === 'split-tender') {
    order = getOrderInProgress (state)
  } else {
    const getOrder = makeGetOrder()
    order = orderUuid ? getOrder(state, orderUuid) : getOrder(state, state.order.currentOrderId)
  }

  const paymentId = ownProps?.match?.params?.paymentId
  let balanceDueDisplay
  let hasBalanceDueInCents = false
  if (paymentId) {
    hasBalanceDueInCents = order?.balanceDueInCents !== 0
    balanceDueDisplay = centsToDollars(order?.balanceDueInCents ?? 0)
  }

  const isNewOrder = !order?.submittedAt
  const mobilePaySetting = getMobilePaySetting(state)
  const isRichCheckout = mobilePaySetting === 'pos_shows_qr'
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const taxRate = getTax(state)

  const activeAttendantId = getActiveAttendantId(state)
  const activeRevenueCenter = getActiveRevenueCenter(state)

  const cloudPrintingEnabled = getCloudPrintingEnabled(state)

  return {
    menu,
    order,
    taxRate,
    isRichCheckout,
    paymentId,
    hasBalanceDueInCents,
    balanceDueDisplay,
    isNewOrder,
    activeAttendantId,
    activeRevenueCenter,
    cloudPrintingEnabled,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const push = ownProps?.history?.push ?? (() => {})

  return {
    onDone: (hasBalanceDueInCents, order, ppiConfigurations, ppiOrderDetails, posInfo, activeRevenueCenter) => {
        if (hasBalanceDueInCents) {
          push({
            pathname: '/split-tender',
            state: { order }
          })
        } else {

          if (!isEmpty(order) && !isEmpty(ppiOrderDetails) && !isEmpty(ppiConfigurations) && !isEmpty(activeRevenueCenter)) {
            handlePpiReportOrder(order, ppiConfigurations, ppiOrderDetails, posInfo, activeRevenueCenter.customerAttributes?.ppiExtras)
          }

          bridge_setCFDScreen(CFD_SCREEN.IDLE)
          dispatch(clearCart())
          dispatch(clearPromotionsOnOrder())
          push('/concession-order')
      }
    },
    goToDeviceSummary: () => push('/orders/device-summary'),
    printOrder: (params) => dispatch(printOrder(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QRCodeReceipt))
