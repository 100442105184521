import React, { useMemo, useState, useEffect, useContext, useCallback } from 'react'
import { useSelector } from "react-redux"
import PropTypes from 'prop-types'
import cn from 'classnames'
import { get, map, findIndex, isEmpty, reduce, forEach } from 'lodash'

import VNBlueSwitch from '../VNBlueSwitch'
import SidebarModal from '../SidebarModal'
import BackChevron from '../BackChevron'
import Button from '../Button'
import DropdownSelector from '../DropdownSelector'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'

import { ReactComponent as Unchecked } from '../../assets/icons/fill-unchecked.svg'
import { ReactComponent as Checkmark } from '../../assets/icons/fill-checkmark.svg'

import { getOrderInProgress } from '../../selectors/order'
import { isTabbed } from '../../utils/orderStates'
import { ToastManager } from '../../utils/toastManager'

import styles from './RequestRefundModal.module.scss'
import { getNetworkAvailableValue } from '../../utils/networkConnected'
import useManagerApproval from '../../context/hooks/useManagerApproval'

const centsToDollars = (cents) => `$${(cents / 100).toFixed(2)}`

const refundReasons = [
  'Incorrectly entered order',
  'Insufficient inventory',
  'Item returned, quality related',
  'Guest changed their mind',
  'Long wait for item'
]

const RequestRefundModal = ({ refund, order, edit, requestRefund, requestTabbedRefund, requestTabbedRefundOffline, onClose, className, ...props }) => {
  const classNames = cn(styles.requestRefundModal, className)
  const items = get(order, 'itemModels', [])
  const totalInCents = order?.amountInCents ?? 0
  const refundableItemIdIndexes = items.map((_, index) => index).filter(index => !items[index].refund)

  const [selectedItems, setSelectedItems] = useState([])
  const [selectedModifiers, setSelectedModifiers] = useState({})
  const [refundAmount, setRefundAmount] = useState(0)
  const [refundReason, setRefundReason] = useState(refund?.reason)
  const editMode = useMemo(() => edit, [edit])
  const isEntireOrder = (refundableItemIdIndexes.length > 0 && refundableItemIdIndexes.length === selectedItems.length) || refundableItemIdIndexes.length === 0
  const isMobileViewPort = useGetIsMobileScreen()

  const orderInProgress = useSelector(state => getOrderInProgress(state))

  const {
    openManagerApproval,
    setManagerApprovalOnSuccess,
    setManagerApprovalButtonText,
  } = useManagerApproval()

  const isOrderTabbed = isTabbed(orderInProgress)
  const dollarAmount = centsToDollars(refundAmount)
  const buttonTitle = `Void ${dollarAmount}`


  /**
   * refundTabbedOrder logic:
   *
   *          order in Stadium   | order not in Stadium
   *         +-------------------+---------------------+
   * online  | call mutation api | void items locally  |
   *         +-------------------+---------------------+
   * offline | can't void items  | void items locally  |
   *         +-------------------+---------------------+
   *
   */
  const refundTabbedOrder = useCallback(({ orderId, itemIndexes, itemIds, lineItemIds, reason: refundReason, amountInCents: refundAmount }) => {
    const networkConnected = getNetworkAvailableValue()

    //Online processing
    if (networkConnected) {
      //Order aready in Stadium then we can mutate the order
      if (order.wasCreatedInStadium) {
        requestTabbedRefund({ orderId, itemIndexes, itemIds, lineItemIds, reason: refundReason, amountInCents: refundAmount })
        return
      }
      //Order is not in Stadium so even we're online we can't mutate we can just do a local update
      requestTabbedRefundOffline({ orderId, itemIndexes, itemIds, lineItemIds, reason: refundReason, amountInCents: refundAmount })
      return
    }

    //offline and order already in stadium then we need to error off
    if (order.wasCreatedInStadium) {
      ToastManager.error("Items can't be voided offline for this order")
      return
    }

    //offline and order is not in stadium
    requestTabbedRefundOffline({ orderId, itemIndexes, itemIds, lineItemIds, reason: refundReason, amountInCents: refundAmount })


  }, [requestTabbedRefund, requestTabbedRefundOffline, order.wasCreatedInStadium])

  const handleOnComplete = useCallback(() => {
    const orderId = order.uuid
    const itemIndexes = selectedItems
    const itemIds = map(selectedItems, (itemIndex) => items[itemIndex].id)

    const lineItemIds = reduce(
      selectedItems,
      (allIds, itemIndex) => {
        return [...allIds, items[itemIndex]?.lineItemUuid]
      },
      Object.keys(selectedModifiers)
    )

    forEach(selectedModifiers, (modifier) => (modifier.refund = true))

    if (isOrderTabbed){
      refundTabbedOrder({ orderId, itemIndexes, itemIds, lineItemIds, reason: refundReason, amountInCents: refundAmount })
      return
    }

    requestRefund({ orderId, itemIndexes, itemIds, lineItemIds, reason: refundReason,
      amountInCents: refundAmount })
  }, [items, requestRefund, isOrderTabbed, order.uuid, refundAmount, refundReason, selectedItems, selectedModifiers, refundTabbedOrder])

  const setManagerApprovalValues = useCallback(() => {
    setManagerApprovalButtonText(buttonTitle)
    setManagerApprovalOnSuccess(() => {
      handleOnComplete()
      onClose()
    })
  }, [setManagerApprovalButtonText, setManagerApprovalOnSuccess, onClose, buttonTitle, handleOnComplete])

  useEffect(() => {
    setManagerApprovalValues()
  }, [setManagerApprovalValues])

  useEffect(() => {
    const sumSelectedItems = (accumulator, itemIndex) => accumulator + items[itemIndex]?.priceInCents ?? 0
    const sumSelectedModifiers = (accumulator, modifier) => accumulator + modifier?.defaultPriceInCents ?? 0
    const itemAmountToRefundInCents = selectedItems.reduce(sumSelectedItems, 0)
    const modifierAmountToRefundInCents = reduce(selectedModifiers, sumSelectedModifiers, 0)
    let amountToRefundInCents = itemAmountToRefundInCents + modifierAmountToRefundInCents
    if (amountToRefundInCents > totalInCents) {
      amountToRefundInCents = totalInCents
    }
    setRefundAmount(amountToRefundInCents)
  }, [selectedItems, selectedModifiers, items])

  useEffect(() => {
    if (refund && refund.reason) {
      const reason = refund.reason
      setRefundReason(reason)
    }
  }, [items])

  const toggleItem = (index, item) => {
    //once refunded... don't let the user change the toggle
    if (item.refund) return
    const isChecked = selectedItems.includes(index)
    if (isChecked) {
      selectedItems.splice(findIndex(selectedItems, (item) => item === index), 1)
      setSelectedItems([ ...selectedItems ])
    } else {
      setSelectedItems([index, ...selectedItems])
      forEach(item.modifiers, (modifier) => {
        if (!modifier.refund) {
          selectedModifiers[modifier.lineItemUuid] = modifier
        }
      })
      setSelectedModifiers({ ...selectedModifiers })
    }
  }

  const toggleModifier = (modifier) => {
    //once refunded... don't let the user change the toggle
    if (modifier.refund) return
    const isChecked = selectedModifiers[modifier.lineItemUuid]
    if (isChecked) {
      delete selectedModifiers[modifier.lineItemUuid]
      setSelectedModifiers({ ...selectedModifiers })
    } else {
      selectedModifiers[modifier.lineItemUuid] = modifier
      setSelectedModifiers({...selectedModifiers})
    }
  }

  const onSelectEntireOrder = () => {
    if (!editMode) return
    if (isEntireOrder) {
      setSelectedItems([])
      setSelectedModifiers({})
      return
    }

    const newSelectedModifiers = reduce(
      items,
      (modifiers, item) => {
        return {
          ...modifiers,
          ...reduce(item.modifiers, (itemModifiers, modifier) => {
            return !modifier?.refund ? { ...itemModifiers, [modifier?.lineItemUuid]: modifier} : itemModifiers
          }, {})
        }
      },
      {}
    )
    setSelectedItems([...refundableItemIdIndexes])
    setSelectedModifiers(newSelectedModifiers)
  }

  const getCheckboxStyle = (refund, isItemChecked, isModifierChecked) => {
    return isModifierChecked || (isItemChecked && !isModifierChecked) ? (
      <Checkmark
        className={
          isItemChecked && isModifierChecked || refund
            ? styles.checkBoxIconUnSelectable
            : styles.checkBoxIcon
        }
      />
    ) : (
      <Unchecked className={styles.checkBoxIcon} />
    )
  }

  const renderItems = (item, index) => {
    const isItemChecked = selectedItems.includes(index) || item.refund

    return (
      <>
      <div className={styles.selectableItem} key={`${item.name}-${index}`} onClick={() => toggleItem(index, item)}>
        <div className={cn([styles.item, isItemChecked && styles.selected])}>
          <div className={styles.column}>
            {getCheckboxStyle(item.refund, isItemChecked)}
          </div>
          <div className={styles.column}>
            <span className={styles.name}>{`(1) ${item.name} - ${centsToDollars(item.priceInCents)}`}</span>
          </div>
        </div>
      </div>

      {map(item.modifiers, (modifier) => {
          const isModifierChecked = selectedModifiers[modifier.lineItemUuid] || modifier.refund

          return(
            <div className={styles.selectableItem} key={`${modifier?.name}-${index}`}
              onClick={() => {
                if (isItemChecked) return
                toggleModifier(modifier)}
            }>
              <div className={cn([styles.item, isModifierChecked && styles.selected])}>
                <div className={styles.modColumn}>
                  {getCheckboxStyle(modifier.refund, isItemChecked, isModifierChecked)}
                </div>
                <div className={styles.column}>
                  <span className={styles.name}>{`(1) ${modifier.name} - $${(modifier.defaultPriceInCents/100).toFixed(2)}`}</span>
                </div>
              </div>
            </div>
          )
        }
      )}
      </>
    )
  }

    const renderRefundReasons = () => {
      const customStyling = {
        customAutocompleteStyles: {
          width: isMobileViewPort ? "89.861vw" : "26.900vw",
          height: isMobileViewPort ? "11.111vw" : "3.333vw",
          paddingTop: 0,
          paddingRight: 0,
          borderRadius: 6,
          backgroundColor: "#292A2B",
        },
        customInputLabelStyles: {
          color: "white",
          fontSize: isMobileViewPort ? "4.444vw" : "1.042vw",
          paddingBottom: isMobileViewPort ? "1.111vw" : 0,
          marginTop: isMobileViewPort ? "-0.8vh" : 0,
        },
        customTextFieldStyles: {
          height: isMobileViewPort ? "11.111vw" : "3.333vw",
          fontSize: isMobileViewPort ? "4.444vw" : "1.042vw",
          color: "white",
          border: "1px solid #383B3D",
          borderRadius: 6,
          backgroundColor: "#292A2B",
          paddingLeft: 5,
          paddingRight: 15,
          textAlign: "center",
        },
        customPaperStyles: {
          maxHeight: "auto",
          backgroundColor: "#292A2B",
          fontSize: isMobileViewPort ? "4.444vw" : "1.042vw",
        },
      }

      const label = refund?.reason ? refundReason : "Refund Reason"

      return (
        <div>
          <DropdownSelector
            autocompleteLabel={label}
            textInputVariant={'filled'}
            autocompleteOptions={refundReasons}
            onAutocompleteChange={(refundReason) => {
              if (!editMode) return
              setRefundReason(refundReason)
            }}
            disabled={false}
            {...customStyling}
          />
        </div>
      )
    }

  return (
    <SidebarModal
      show
      title='Void Items'
      onClose={onClose}
      leftAccessory={<BackChevron onClick={onClose} />}
      className={classNames} {...props}
    >
    <div className={styles.requestRefundModal}>
        <div className={styles.orderNumber}>
         {`Order # ${order.orderNumber}`}
        </div>
          {renderRefundReasons()}
        <div className={styles.requiredField}>
           * Required Field
        </div>
      <div className={styles.refundContainer}>
        <span className={styles.border} />
        <div className={styles.selectItems}>
          Select items
        </div>
        <div className={styles.selectableItem} key={"Entire Order"} onClick={onSelectEntireOrder}>
          <div className={cn([styles.item, styles.entireOrder])}>
            <div className={styles.column}>
              <span className={styles.name}>{`Entire Order - ${centsToDollars(totalInCents)}`}</span>
            </div>
            <div className={cn([styles.colomn, styles.entireOrderToggle])}>
              <VNBlueSwitch disableRipple checked={isEntireOrder} />
            </div>
          </div>
        </div>
        {map(items, renderItems)}
      </div>
      <div className={styles.submitContainer}>
        { editMode && <Button
          className={styles.submitButton}
          disabled = {(isEmpty(selectedItems) && isEmpty(selectedModifiers)) || refundReason === undefined}
          onClick={() => {openManagerApproval()}}>
          {buttonTitle}
        </Button> }
      </div>
    </div>
  </SidebarModal>
  )

}

RequestRefundModal.defaultProps = {
  order: {},
	requestRefund: () => {},
  requestTabbedRefund: () => {},
  onClose: () => {},
  edit: true,
}

RequestRefundModal.propTypes = {
  order: PropTypes.object,
	requestRefund: PropTypes.func,
  requestTabbedRefund: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
}

export default RequestRefundModal
