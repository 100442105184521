import { createSelector } from 'reselect'
import { get, values, find } from 'lodash'

export const getAttendantSettings = (state) => values(get(state, 'attendantSettings.byId'))

export const getAttendantSettingsError = (state) => get(state, 'attendantSettings.errorMessage', '')

export const makeGetAttendantSetting = () => createSelector(
  getAttendantSettings, (_, settingsId) => settingsId,
  (settings, settingsId) => find(settings, ['uuid', settingsId])
)
