import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import cn from 'classnames'

import GratuityCard from './GratuityCard'

import styles from './GratuityCards.module.scss'

const GratuityCards = ({ options, onTap, className }) => {
  const classNames = cn(styles.gratuityCards, className)

  return (
    <div className={classNames}>
      {map(options, (gratuity, index) => (
        <GratuityCard {...gratuity} key={index} onTap={onTap} className={styles.gratuityCard} />
      ))}
    </div>
  )
}

GratuityCards.defaultProps = {
  options: [],
	onTap: () => {},
}

GratuityCards.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    percentage: PropTypes.string,
    amount: PropTypes.string,
  })),
	onTap: PropTypes.func,
}

export default GratuityCards
