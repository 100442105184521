import { toggleAppState } from '../../actions/appState'
import { store } from '../../stores'

export default class UserIdleMonitor {
  constructor(idleTime) {
    this.idleTime = idleTime
    this.timer = 0
  }

  _activate = this.activate.bind(this)

  addEventListeners() {
    document.addEventListener('scroll', this._activate)
    document.addEventListener('keypress', this._activate)
    document.addEventListener('click', this._activate)
    this.activate()
  }

  removeEventListeners() {
    document.removeEventListener('scroll', this._activate)
    document.removeEventListener('keypress', this._activate)
    document.removeEventListener('click', this._activate)
    clearTimeout(this.timer)
  }

  activate() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.toggleIdle(false), this.idleTime)
  }

  toggleIdle(isActive) {
    store.dispatch(toggleAppState({ isActive }))
  }
}
