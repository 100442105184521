import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import SectionCard from './SectionCard'
import CreditCards from './CreditCards'

import styles from './CreditCardPayment.module.scss'

const CreditCardPayment = ({ title, cards, className }) => {
  const classNames = cn(styles.creditCardPayment, className)

  return (
    <SectionCard title={title} className={classNames}>
      <CreditCards cards={cards} className={styles.creditCards} />
    </SectionCard>
  )
}

CreditCardPayment.defaultProps = {
  title: '',
	cards: [],
}

CreditCardPayment.propTypes = {
  title: PropTypes.string,
	cards: PropTypes.arrayOf(PropTypes.shape({
    issuer: PropTypes.string,
    icon: PropTypes.string,
  })),
}

export default CreditCardPayment
