import { call, put, select, takeLatest } from 'redux-saga/effects'
import { get } from 'lodash'
import Remote from '../remote'

import {
  LOAD_REVENUE_CENTER_REQUESTED,
  loadRevenueCentersSucceeded,
  loadRevenueCentersFailed,
} from '../actions/revenueCenter'

export function* getRevenueCenters(params = {}) {
  const venueUuid = yield select((state) => get(state, 'config.venueUuid', ''))

  try {
    const result = yield call(Remote.getRevenueCenters, venueUuid)
    yield put(loadRevenueCentersSucceeded(result))
  } catch (err) {
    yield put(loadRevenueCentersFailed(err))
  }
}

export function* watchGetRevenueCenters() {
  yield takeLatest(LOAD_REVENUE_CENTER_REQUESTED, getRevenueCenters)
}
