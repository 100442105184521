import React, { memo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './Header.module.scss'
import { ReactComponent as ClearIcon } from '../../assets/clear-icon.svg'
import NumberPadV2 from '../NumberPadV2'

const OrdersHeader = ({ currentFilter, currentSearch, onFilter, onClearSearch, onSearch, className }) => {
  const classNames = cn(styles.ordersHeader, className)
  const [inputText, setInput] = useState('')

  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
  }

  const [keyboardFocus, setKeyboardFocus] = useFocus()

  const onTapNumberPad = (value) => {
    let newValue = value === 'backspace' ? inputText.slice(0, inputText.length-1): value === 'keyboard' ? setKeyboardFocus : inputText + value

    setInput(newValue)
    if(newValue === '') {
      onClearSearch()
    }
  }

  const clearInput = () => {
    setInput('')
    onClearSearch()
  }

  const handleOnChange = (e) => {
    setInput(e.target.value)
    if(keyboardFocus) {
      onSearch(e.target.value)
    }
  }

  if(inputText) {
    onSearch(inputText)
  }   

  return (
    <div className={classNames}>
      <div className={styles.fieldContainer}>        
        <input placeHolder='Enter an order number' value={currentSearch} ref={keyboardFocus} onChange={handleOnChange} />
        {(currentSearch ?? inputText) && (
          <div className={styles.clearIconContainer} onClick={clearInput}>
            <ClearIcon className={styles.clearIcon} />
          </div>
        )}
        <div className={styles.numberPad}>
          <NumberPadV2 onTapButton={onTapNumberPad} keyboardOn={true} />
        </div>
        <div className={styles.appVerContainer} >
          <div className={styles.appVersion}>
            <span>{process.env.REACT_APP_VERSION || 'unknown'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

OrdersHeader.defaultTypes = {
  currentFilter: '',
  currentSearch: '',
}

OrdersHeader.propTypes = {
  currentFilter: PropTypes.string,
  currentSearch: PropTypes.string,
  onClearSearch: PropTypes.func,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
}

export default memo(OrdersHeader)
