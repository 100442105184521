import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { some, isEqual } from 'lodash'

export default class Image extends Component {
  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
    onError: PropTypes.func,
  }

  state = {
    imageExists: some(this.props.url),
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.url, this.props.url)) {
      this.setState({ imageExists: some(this.props.url) })
    }
  }

  _onError = () => {
    this.setState({ imageExists: false })
    this.props.onError && this.props.onError()
  }

  render() {
    const { alt, className, url } = this.props
    const { imageExists } = this.state

    if (!imageExists) return null
    return <img alt={alt} className={className} onError={this._onError} src={url} />
  }
}
