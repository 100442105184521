import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { closeNoOpTender } from '../actions/order'
import { makeGetNoOpTenders } from '../selectors/revenueCenter'
import SelectNoOpPayment from '../components/SelectNoOpPayment'

const mapStateToProps = (state) => {
  const noOpTenders = makeGetNoOpTenders()(state)

  return {
    noOpTenders,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const replace = props?.history?.replace ?? (() => {})

  return {
    onClose: () => {
      replace('/concession-order') 
    },
    closeNoOpTender: (tender) => dispatch(closeNoOpTender({ tender })),
    routeToChangeCalculator: () => replace('/change-calculator'),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectNoOpPayment))
