import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import VNAndroidSDK from '../VNAndroidSDK/Reducer'
import VNCustomerFacingDisplay from '../VNCustomerFacingDisplay/Reducer'
import VNCustomerFacingDisplayPersisted from '../VNCustomerFacingDisplay/ReducerPersisted'
import VNMode from '../VNMode/Reducer'
import VNDialogs from '../VNDialogs/Reducer'
import VNPPI from '../VNPPI/Reducer'
import VNMobileScanner from '../VNMobileScanner/Reducer'
import VNLocationPicker from '../VNLocationPicker/Reducer'

// KIOSK
import appState from './appState'
import categories from './categories'
import items from './items'
import stands from './stands'
import menus from './menus'
import kioskCarouselImages from './carouselImages'

// POS
import cart from './cart'
import order from './order'
import revenueCenter from './revenueCenter'
import search from './search'
import attendant from './attendant'
import attendantSettings from './attendantSettings'
import employeeRole from './employeeRole'
import venue from './venue'
import printer from './printer'
import orderTotalRemote from './orderTotalRemote'
import promotion from './promotion'

// SHARED
import refund from './refund'
import config from './config'
import peripheral from './peripheral'

const configPersistConfig = {
  key: 'config',
  storage: storage,
  blacklist: ['shouldRefreshConfig', 'remote']
}

const revenueCenterPersistConfig = {
  key: 'revenueCenter',
  storage: storage,
  blacklist: ['activeRevenueCenter']
}

const attendantPersistConfig = {
  key: 'attendant',
  storage: storage,
  blacklist: ['activeAttendantId']
}

const peripheralPersistConfig = {
  key: 'peripheral',
  storage: storage,
  blacklist: ['peripheralIsBusy', 'safIsUploading']
}

const orderPersistConfig = {
  key: 'order',
  storage: storage,
  blacklist: ['orderInProgress']
}

const appStatePersistConfig = {
  key: 'appState',
  storage: storage,
  blacklist: ['automaticallyUpdate', 'performSafeUpdate', 'updateAvailable']
}

export default combineReducers({
  appState: persistReducer(appStatePersistConfig, appState),
  categories,
  items,
  stands,
  menus,
  kioskCarouselImages,
  revenueCenter: persistReducer(revenueCenterPersistConfig, revenueCenter),
  cart,
  order: persistReducer(orderPersistConfig, order),
  orderTotalRemote,
  refund,
  peripheral: persistReducer(peripheralPersistConfig, peripheral),
  config: persistReducer(configPersistConfig, config),
  search,
  attendant: persistReducer(attendantPersistConfig, attendant),
  attendantSettings,
  employeeRole,
  venue,
  printer,
  promotion,
  VNAndroidSDK,
  VNCustomerFacingDisplay,
  VNCustomerFacingDisplayPersisted,
  VNMode,
  VNDialogs,
  VNPPI,
  VNMobileScanner,
  VNLocationPicker
})
