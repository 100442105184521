import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { get } from 'lodash'

import { receiptDetailsMessage } from '../../kiosk/utils/messages'

import { printOrder } from '../../actions/printer'

import { makeGetOrder } from '../../selectors/order'
import { makeGetRevenueCenter } from '../../selectors/revenueCenter'
import { getIsKiosk } from '../../selectors/menus'

import ReceiptCard from '../../components/receipt/ReceiptCard'
import styles from '../../components/receipt/ReceiptCard.module.scss'

import Button from '../components/Button'
import { clearPromotionsOnOrder } from '../../actions/promotion'
import { isQRPay } from '../../utils/orderStates'

export const ReceiptDetails = ({ revenueCenter, order, printOrder, history, isKiosk, clearPromotionsOnOrder }) => {

  const timeRef = useRef(15)
  const closeButtonRef = useRef(null)

  const [seconds, setSeconds] = useState(15)

  useEffect(() => {
    let timer
      timer = setTimeout(() => {
        timeRef.current = timeRef.current - 1
        setSeconds(timeRef.current)
      }, 1000)

    if (seconds === 0) {
      goIdle()
    }

    return () => clearInterval(timer)
  // eslint-disable-next-line
  }, [seconds])

  useEffect(() => {
    printReceipt()
    closeButtonRef.current.focus()
    // eslint-disable-next-line
  }, [])

  const printReceipt = () => {
    printOrder({
      deviceType: 'kiosk',
      orderUuid: order?.uuid,
    })
  }

  const backButtonTitle = () => seconds > 10 ? 'Close' : `Close in ${seconds}...`
  const goIdle = () => {
    clearPromotionsOnOrder()
    history.push('/kiosk/attract_loop')
  }

  let label = 'Thanks!'
  if (order?.displayName === 'Ticket') {
    label = 'Ticket Scan Success! \n'
  }

  if (isQRPay(order)) {
    label = 'QR Scan Success! \n'
  }

  return (
    <div className={`payment ${isKiosk ? `-card-only-kiosk` : `-card-only`} receipt-details`}>
      <div className="receipt-details__header">{order.orderNumber}</div>
      <div className="receipt-details__label">{`${label} Pickup at ${revenueCenter.name}`}</div>
      <div className="receipt-details__card">
        <ReceiptCard className={styles.receiptCardKiosk}/>
      </div>
      <div className="payment__actions">
        <Button
          refProp={closeButtonRef}
          tabIndex={0}
          ariaLabel={receiptDetailsMessage(order.orderNumber, revenueCenter.name)}
          className="rounded-btn -progress -progress-delayed -dark"
          onClick={goIdle}
          title={backButtonTitle()}
        />
      </div>
    </div>
  )
}

ReceiptDetails.propTypes = {
  order: PropTypes.object,
  revenueCenter: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }),
  isKiosk: PropTypes.bool
}

ReceiptDetails.defaultProps = {
  isKiosk: false
}

const mapStateToProps = (state, ownProps) => {
  const orderUuid = get(ownProps, 'match.params.orderUuid')
  const getOrder = makeGetOrder()
  const isKiosk = getIsKiosk(state)
  let order = {}
  order = orderUuid ? getOrder(state, orderUuid) : getOrder(state, state.order.currentOrderId)

  const getRevenueCenter = makeGetRevenueCenter()
  const revenueCenter = getRevenueCenter(state, order.revenueCenterUuid)

  return {
    order,
    revenueCenter,
    isKiosk
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    printOrder: (params) => dispatch(printOrder(params)),
    clearPromotionsOnOrder: () => dispatch(clearPromotionsOnOrder())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReceiptDetails))
