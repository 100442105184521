const peripheral_connected_default_true = {
  4: (state) => {
    return {
      ...state,
      peripheral: {
        ...(state.peripheral ?? {}),
        peripheralConnected: true
      }
    }
  },
}

export default peripheral_connected_default_true
