import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import cn from 'classnames'
import styles from './QRCodeReceipt.module.scss'

import Button from '../Button'

import { APPLICATION_STRINGS } from '../../strings'

import ReceiptModal from './ReceiptModal'
import { RECEIPT_TYPES } from './ReceiptModal'
import ConfirmModalV2 from '../ConfirmModalV2'

import { getPrintingEnabled, getUsbPrintingEnabled } from '../../selectors/printer'
import { getKitchenPrintingEnabled, getTextReceiptsEnabled, getEmailReceiptsEnabled } from '../../selectors/menus'

import { getPpiConfigurations, getPpiOrderDetails } from '../../VNPPI/Selectors'

import { createOrderParams } from '../../utils/orderUtils'
import { centsToDollars } from '../../utils/formatters'
import { getNetworkAvailableValue } from '../../utils/networkConnected'

import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'

const QRCodeReceipt = ({ onDone, order, printOrder, className, paymentId, hasBalanceDueInCents,
    balanceDueDisplay, isNewOrder, activeAttendantId, activeRevenueCenter, goToDeviceSummary }) => {
  const printingEnabled = useSelector(state => getPrintingEnabled(state))
  const usbPrintingEnabled = useSelector(state => getUsbPrintingEnabled(state))
  const cloudPrintingEnabled = useSelector(state => getKitchenPrintingEnabled(state))
  const enableTextReceipts = useSelector(state => getTextReceiptsEnabled(state))
  const enableEmailReceipts = useSelector(state => getEmailReceiptsEnabled(state))

  const ppiConfigurations = useSelector(state => getPpiConfigurations(state))
  const ppiOrderDetails = useSelector(state => getPpiOrderDetails(state, order?.uuid))

  const networkAvailable = getNetworkAvailableValue()
  const urlParams = new URLSearchParams(window.location.search)
  const fromOrderDetails = urlParams.get('origin') === 'orderDetails'

  const [showSendEmailReceiptModal, setShowSendEmailReceiptModal] = useState(false)
  const [showSendTextReceiptModal, setShowSendTextReceiptModal] = useState(false)
  const [showPrintErrorDialog, setShowPrintErrorDialog] = useState(false)
  const [showKitchenPrintErrorDialog, setShowKitchenPrintErrorDialog] =
    useState(
      fromOrderDetails
        ? false
        : !networkAvailable && cloudPrintingEnabled
    )

  const classNames = cn(styles.qrCodeReceipt, className)
  const mediaQuery = window.matchMedia("(max-width: 450px)")
  const isMobileViewPort = useGetIsMobileScreen()

  let emailButton = isMobileViewPort ? APPLICATION_STRINGS.EMAIL_BUTTON_SHORT_TEXT : APPLICATION_STRINGS.EMAIL_BUTTON_LONG_TEXT
  let textButton = isMobileViewPort ? APPLICATION_STRINGS.TEXT_BUTTON_SHORT_TEXT : APPLICATION_STRINGS.TEXT_BUTTON_LONG_TEXT
  let printButton = isMobileViewPort ? APPLICATION_STRINGS.PRINT_BUTTON_SHORT_TEXT : APPLICATION_STRINGS.PRINT_BUTTON_LONG_TEXT
  let nextText = APPLICATION_STRINGS.NEXT_BUTTON_NO_BALANCE

  if (hasBalanceDueInCents) {
    nextText = APPLICATION_STRINGS.NEXT_BUTTON_BALANCE
  }

  const payments = createOrderParams(order)?.payments ?? []
  const wasCreatedInStadium = order?.wasCreatedInStadium

  const printReceipt = () => {
    // One major situation merits showing the print error dialog for receipt printing:
    //     Printing is enabled, AND it's not USB printing (i.e. it's cloud), AND the order wasn't
    //     created in Stadium.
    if (printingEnabled && !usbPrintingEnabled && !wasCreatedInStadium) {
      setShowPrintErrorDialog(true)
      return
    }

    printOrder({
      deviceType: 'concessions',
      orderUuid: order?.uuid,
      isNewOrder,
    })
  }

  let footerTextElement
  if (order.remainingTicketBalance !== undefined) {
    footerTextElement = <div className={styles.balanceDue}> {`Remaining Ticket Balance: ${centsToDollars(order.remainingTicketBalance)}`}</div>
  } else if (hasBalanceDueInCents) {
    footerTextElement = <div className={styles.balanceDue}> {`Balance Due: ${balanceDueDisplay}`}</div>
  }

  const handleSendTextClick = () => {
    setShowSendTextReceiptModal(true)
  }

  const handleSendEmailClick = () => {
    setShowSendEmailReceiptModal(true)
  }

  const receiptModalComponent = (receiptType, allowMultiple) => {
    const onModalClose = () => {
      setShowSendEmailReceiptModal(false)
      setShowSendTextReceiptModal(false)
    }

    return <ReceiptModal
      onClose={() => onModalClose()}
      order={order}
      receiptType={receiptType}
      allowMultiple={allowMultiple}
      paymentId={paymentId}
    />
  }

  const receiptButtonComponent = (title, buttonText, onClick, modalComponent) => {
    return <div className={styles.receiptContainer}>
      <button
        className={cn([styles.receiptButton, !printingEnabled && isMobileViewPort && styles.longButton])}
        onClick={onClick}
        title={title}
      >
        {buttonText}
      </button>
      {modalComponent}
    </div>
  }

  return (
    <div className={classNames}>
      {showPrintErrorDialog && (
        <ConfirmModalV2
          onButtonOneClick={() => setShowPrintErrorDialog(false)}
          onButtonTwoClick={() => goToDeviceSummary()}
          headerText={APPLICATION_STRINGS.NETWORK_PRINTER_ERROR_TITLE}
          subtext={APPLICATION_STRINGS.ORDER_MUST_BE_SYNCED_BEFORE_PRINTING}
          buttonOneText={APPLICATION_STRINGS.OK_BUTTON_TEXT}
          buttonTwoText={'SYNC NOW'}
        />
      )}
      {showKitchenPrintErrorDialog && (
        <ConfirmModalV2
          onButtonOneClick={() => setShowKitchenPrintErrorDialog(false)}
          onButtonTwoClick={() => setShowKitchenPrintErrorDialog(false)}
          headerText={APPLICATION_STRINGS.KITCHEN_PRINTER_ERROR_TITLE}
          subtext={APPLICATION_STRINGS.OFFLINE_KITCHEN_PRINTER_ERROR}
          buttonOneText={APPLICATION_STRINGS.OK_BUTTON_TEXT}
          buttonTwoText={APPLICATION_STRINGS.OK_BUTTON_TEXT}
          singleButtonOnly={true}
        />
      )}
      <div className={cn([styles.footer, (hasBalanceDueInCents || order.remainingTicketBalance) && styles.flexColumn])}>
        <div className={isMobileViewPort ? styles.mobileButtons : null}>
        {!(payments[0]?.paymentType === "mobile_pay") && (!hasBalanceDueInCents) && networkAvailable &&
          <div>
            {enableEmailReceipts && receiptButtonComponent(APPLICATION_STRINGS.EMAIL_BUTTON_LONG_TEXT, emailButton, handleSendEmailClick)}
            {enableTextReceipts && receiptButtonComponent(APPLICATION_STRINGS.TEXT_BUTTON_LONG_TEXT, textButton, handleSendTextClick)}
            {(showSendEmailReceiptModal || showSendTextReceiptModal) &&
              receiptModalComponent(showSendEmailReceiptModal ? RECEIPT_TYPES.EMAIL : RECEIPT_TYPES.TEXT, showSendEmailReceiptModal)}
          </div>
        }
        {printingEnabled && !hasBalanceDueInCents && (
          <Button
            className={cn([styles.printReceiptButton, !networkAvailable && isMobileViewPort && styles.longButton])}
            onClick={printReceipt}
            disabled={!usbPrintingEnabled && !wasCreatedInStadium}
            title={APPLICATION_STRINGS.PRINT_BUTTON_LONG_TEXT}
          >
            {printButton}
          </Button>
        )}
        {(!mediaQuery?.matches || (hasBalanceDueInCents)) &&
          <button
            className={styles.doneButton}
            onClick={() => onDone(hasBalanceDueInCents, order, ppiConfigurations, ppiOrderDetails, { venueId: process.env.REACT_APP_VENUE_UUID, userId: activeAttendantId, vendorName: activeRevenueCenter.name }, activeRevenueCenter)}
          >
            {nextText}
          </button>
        }
      </div>
      {footerTextElement}
    </div>
    </div>
  )
}

QRCodeReceipt.defaultProps = {
  title: '',
  menu: {},
  order: {},
  onDone: () => {},
  goToDeviceSummary: () => {},
  cloudPrintingEnabled: false,
  isRichCheckout: false
}

QRCodeReceipt.propTypes = {
  title: PropTypes.string,
  menu: PropTypes.object,
  order: PropTypes.object,
  onDone: PropTypes.func,
  goToDeviceSummary: PropTypes.func,
  cloudPrintingEnabled: PropTypes.bool,
  isRichCheckout: PropTypes.bool
}

export default QRCodeReceipt
