import React, { useEffect, useState }from 'react'

import usePurgeOrders from '../hooks/usePurgeOrders'
import useIdleReplay from '../hooks/useIdleReplay'
import useIdleRefresh from '../hooks/useIdleRefresh'
import useSafMode from '../hooks/useSafMode'
import useSetNetworkAvailable from '../hooks/useSetNetworkAvailable'

import OfflineOnDemandDialog from './dialog/OfflineOnDemandDialog'
import useCheckConfig from '../hooks/useCheckConfig'

const Hooks = () => {
  const [showOfflineDialog, setShowOfflineDialog] = useState(false)
  const [initiateSafBridgeCommunication, updateSafMode, getSafModeFromBridge, { safMode }] = useSafMode()

  // on mount of the app, re-apply the last saved saf mode that was previously saved to app state
  useEffect(() => {
    updateSafMode(safMode)
  }, [])

  usePurgeOrders()
  useIdleRefresh()
  useIdleReplay()
  useSetNetworkAvailable()

  const connectingIndicator = useCheckConfig()

  if (connectingIndicator) {
    return connectingIndicator
  }

  return (
    <>
      {showOfflineDialog &&
        <OfflineOnDemandDialog
          onCancel={() => {
            setShowOfflineDialog(false)
            updateSafMode(2) }
          }
          onConfirm={() => {
            setShowOfflineDialog(false)
            updateSafMode(0) }
          }
        />
      }
    </>
  )
}

export default Hooks
