import React, { useState } from 'react'
import { ReactComponent as SignalStrength } from '../assets/icons/signal-strength.svg'
import { ReactComponent as SignalStrengthMobile } from '../assets/icons/signal-strength-mobile.svg'

import { primaryNetworkConnectedWithCallbacks } from '../utils/networkConnected'
import ConfirmModalV2 from '../components/ConfirmModalV2'
import { useSelector } from 'react-redux'
import { getSafMode } from '../selectors/appState'
import useGetIsMobileScreen from './useGetIsMobileScreen'


const useOfflineModal = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [offlineMessage, setOfflineMessage] = useState()
    const safMode = useSelector(getSafMode)
    const isMobileViewPort = useGetIsMobileScreen()

    const checkIfOfflineWithCallback = (ifOnlineCallback, offlineMessage = 'Sorry, this device is now offline.', checkSaf) => {
      primaryNetworkConnectedWithCallbacks(() => {
        if (!checkSaf || safMode !== 2) {
          ifOnlineCallback()
        } else {
          setModalOpen(true)
          setOfflineMessage(offlineMessage)
        }
      }, () => {
        setModalOpen(true)
        setOfflineMessage(offlineMessage)
      })
    }
    let offlineModal = null

    if (modalOpen) {
      offlineModal = (
        <ConfirmModalV2
          onButtonTwoClick={() => setModalOpen(false)}
          headerText={isMobileViewPort ? <SignalStrengthMobile /> : <SignalStrength />}
          subtext={offlineMessage}
          singleButtonOnly={true}
          buttonTwoText={'Okay'}
        />
      )
    }
    return [checkIfOfflineWithCallback, offlineModal]
  }

export default useOfflineModal
