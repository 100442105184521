import React, { createContext, useState, useRef } from "react";

/**
 * Usage: This context exposes objects and functions used to handle and customize the Manager Approval Component
 *   openManagerApproval: a function to display the manager approval screen
 *   closeManagerApproval: a function to display the manager approval screen
 *   showManagerApproval: mostly used by the ManagerApprovalComponent indicates if it needs to be displayed
 *   managerApprovalOnSuccess: mostly used by the ManagerApprovalComponent, this ref is the function performed upon a valid pin entered
 *   managerApprovalOnFailure: mostly used by the ManagerApprovalComponent, this ref is the function performed upon a invalid pin entered
 *   managerApprovalButtonText: text displayed by the manager approval button
 *   setManagerApprovalOnSuccess: This setter is used to set the function on success
 *   setManagerApprovalOnFailure: This setter is used to set the function on failure
 *   setManagerApprovalButtonText: This setter is used to set the button text
 * 
 * See RequestRefundModal.js as an example
 */

const ManagerApprovalContext = createContext();

const ManagerApprovalProvider = ({ children }) => {
  const [showManagerApproval, setShowManagerApproval] = useState(false);

  const managerApprovalOnSuccess = useRef(() => {});
  const managerApprovalOnFailure = useRef(() => {});
  const managerApprovalButtonText = useRef("");

  const openManagerApproval = () => {
    setShowManagerApproval(true);
  };

  const closeManagerApproval = () => {
    setShowManagerApproval(false);
  };

  const setManagerApprovalOnSuccess = (func) => {
    managerApprovalOnSuccess.current = func;
  };

  const setManagerApprovalOnFailure = (func) => {
    managerApprovalOnFailure.current = func;
  };

  const setManagerApprovalButtonText = (text) => {
    managerApprovalButtonText.current = text;
  };

  return (
    <ManagerApprovalContext.Provider
      value={{
        openManagerApproval,
        closeManagerApproval,
        showManagerApproval,
        managerApprovalOnSuccess: managerApprovalOnSuccess.current,
        managerApprovalOnFailure: managerApprovalOnFailure.current,
        managerApprovalButtonText: managerApprovalButtonText.current,
        setManagerApprovalOnSuccess,
        setManagerApprovalOnFailure,
        setManagerApprovalButtonText
      }}
    >
      {children}
    </ManagerApprovalContext.Provider>
  );
};

export { ManagerApprovalProvider };
export default ManagerApprovalContext;
