export const FETCH_ATTENDANT_SETTINGS = 'FETCH_ATTENDANT_SETTINGS'
export const FETCH_ATTENDANT_SETTINGS_SUCCEEDED = 'FETCH_ATTENDANT_SETTINGS_SUCCEEDED'
export const FETCH_ATTENDANT_SETTINGS_FAILED = 'FETCH_ATTENDANT_SETTINGS_FAILED'

export const fetchAttendantSettings = () => ({
  type: FETCH_ATTENDANT_SETTINGS,
})

export const fetchAttendantSettingsFailed = (payload) => ({
  type: FETCH_ATTENDANT_SETTINGS_FAILED,
  payload,
})

export const fetchAttendantSettingsSucceeded = (payload) => ({
  type: FETCH_ATTENDANT_SETTINGS_SUCCEEDED,
  payload,
})
