import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { getCFDPairingState, getInitializedValues, getCFDScreen } from '../Selectors'
import { VNIdle } from './VNIdle'
import { VNCart } from './VNCart'
import { VNPayment } from './VNPayment'
import { VNProcessing } from './VNProcessing'
import { VNPayWithApp } from './VNPayWithApp'
import { VNStatusScreen } from './VNStatusScreen'
import { VNBalanceDue } from './VNBalanceDue'
import { VNReceipt } from './VNReceipt'
import { Fragment } from 'react'
import { CFD_SCREEN } from '../Reducer'
import { ReactComponent as CardDeclined } from '../../assets/CFDCardDeclined.svg'
import { ReactComponent as CFDShowReceipt } from '../../assets/CFDShowReceipt.svg'
import { ReactComponent as CFDFinishTransactionOnPhone } from '../../assets/CFDFinishTransactionOnPhone.svg'
import { ReactComponent as CFDShowQRCode } from '../../assets/CFDShowQRCode.svg'
import { ReactComponent as CFDWaitForAttendant } from '../../assets/CFDWaitForAttendant.svg'
import ScanTicketGif from '../../assets/gifs/ticket.gif'
import ScanGiftCardGif from '../../assets/gifs/gift-card.gif'

import { VNCFD_SET_SCREEN } from '../ActionTypes'
import { Typography } from '@material-ui/core'

export const VNCustomerFacingDisplay = () => {

  const useStyles = makeStyles(theme => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#000000',
      overflowY: 'scroll'
    },
    s4Logo: {
      marginBottom: 20,
      marginRight: 20,
      height: 45
    },
    imgBox: {
      position: 'absolute',
      bottom: 0,
      right: 0
    },
    debugger: {
      backgroundColor: "#FFFF00",
      height: 1000,
      width: '100%',
      position: 'absolute',
      top: 600,
      right: 24,
      left: 24
    }
  }))

  const classes = useStyles()
  const dispatch = useDispatch()

  // SELECTORS
  const isPaired = useSelector(state => getCFDPairingState(state))
  const isInitialized = useSelector(state => getInitializedValues(state))
  const screen = useSelector(state => getCFDScreen(state))
  // const debuggerText = useSelector(state => getDebugger(state))

  // LOCAL STATE

  // USE EFFECTS

  // ensures that we always go back to the idle screen
  // no matter where we are at, if we get another initialized call from the bridge
  // we need to go back to the idle screen.
  useEffect(() => {
    dispatch({
      type: VNCFD_SET_SCREEN,
      screen: CFD_SCREEN.IDLE
    })
  }, [isInitialized])

  const displayScreen = () => {

    if (screen === CFD_SCREEN.IDLE) {
      return (
        <VNIdle title={isInitialized.get('title')} standImage={isInitialized?.get('image')} menuImage={isInitialized?.get('menuImage')} color={isInitialized.get('color')} />
      )
    } else if (screen === CFD_SCREEN.CART) {
      return (
        <VNCart />
      )
    } else if (screen === CFD_SCREEN.CARD_PAYMENT || screen === CFD_SCREEN.TRANSACTION_SUMMARY || screen === CFD_SCREEN.RICH_CHECKOUT) {
      return (
        <VNPayment type={screen} />
      )
    } else if (screen === CFD_SCREEN.RECEIPT) {
      return (
        <VNReceipt />
      )
    } else if (screen === CFD_SCREEN.PROCESSING) {
      return (
        <VNProcessing />
      )
    } else if (screen === CFD_SCREEN.CARD_DECLINED) {
      return (
        <VNStatusScreen topElement={<CardDeclined />} type={screen} />
      )
    } else if (screen === CFD_SCREEN.RICH_CHECKOUT_FINISH_TRANSACTION_ON_PHONE) {
      return (
        <VNStatusScreen topElement={<CFDFinishTransactionOnPhone />} title={'Please finish this transaction on your phone'} type={screen} />
      )
    } else if (screen === CFD_SCREEN.RICH_CHECKOUT_SHOW_RECEIPT) {
      return (
        <VNStatusScreen topElement={<CFDShowReceipt />} title={'Please show your mobile receipt to the attendant to confirm your order.'} type={screen} />
      )
    } else if (screen === CFD_SCREEN.RICH_CHECKOUT_QR_CODE) {
      return (
        <VNPayWithApp />
      )
    } else if (screen === CFD_SCREEN.SPLIT_PAY_WAITING_FOR_ATTENDANT) {
      return (
        <VNStatusScreen topElement={<CFDWaitForAttendant />} title={'Waiting For Attendant'} type={screen} />
      )
    } else if (screen === CFD_SCREEN.PRESENT_QR_PAY_CODE) {
      return (
        <VNStatusScreen topElement={<CFDShowQRCode />} title={'Please present your QR Pay code to the attendant.'} type={screen} />
      )
    } else if (screen === CFD_SCREEN.TICKET_PAYMENT) {
      return (
        <VNStatusScreen 
          topElement={<img src={ScanTicketGif}
            style={{width: 200, height: 200}}
            alt={"Scan Ticket"}/>
          }
          title={'Please present your tickets to the attendant.'}
          type={screen} 
        />
      )
    } else if (screen === CFD_SCREEN.GIFT_CARD_PAYMENT) {
      return (
        <VNStatusScreen 
          topElement={<img src={ScanGiftCardGif}
            style={{width: 200, height: 200}}
            alt={"Scan Gift Card"}/>
          }
          title={'Please present your gift card to the attendant.'}
          type={screen} 
        />
      )
    } else if (screen === CFD_SCREEN.BALANCE_DUE) {
      return <VNBalanceDue />
    } else if (screen === CFD_SCREEN.TICKET_PAYMENT_BALANCE) {
      return (
        <VNStatusScreen 
          topElement={<VNBalanceDue displayHorizontal={true} />}
          title={<img src={ScanTicketGif} style={{width: 200, height: 200}} alt={"Scan Ticket"}/>}
          subtitle={<Typography style={{paddingTop: 24, width: 523, textAlign: 'center'}} variant='h3'>Please present your ticket to the attendant.</Typography>}
          type={screen}
        />
      )
    }
  }

  const display = () => {
    if (isPaired && !isInitialized.isEmpty() && screen) {
      return (displayScreen())
    }
  }

  return (
    <Fragment>
      <Box className={classes.root}>
        {display()}
      </Box>
      <Box className={classes.imgBox}>
        <img src='./imgs/powered_by_shift4.png' className={classes.s4Logo} />
      </Box>
      {/* <Box className={classes.debugger}>
        <div>DEBUGGER</div>
        {debuggerText}
      </Box> */}
    </Fragment>
  )
}
