import { CFD_POS_PAYMENT_FLOWS, CFD_SCREEN, USER_PROGRESS } from "../../VNCustomerFacingDisplay/Enums"
import { getCurrentPaymentFlow } from "../../VNCustomerFacingDisplay/Selectors"
import { setCardAuthStepOne, setCardAuthStepTwo, setConfirmPaymentDialog, setCustomerPayingWithCard, setCustomerViewingReceiptDialog, setOpenCustomerEnteringTipDialog, setTransactionCompleteDialog, setTransactionErrorDialog } from "../../VNDialogs/ActionCreators"
import { MODES } from "../../VNMode/Reducer"
import { getDeviceMode } from "../../VNMode/Selectors"
import * as VNCFD_ActionTypes from '../../VNCustomerFacingDisplay/ActionTypes'
import { addPaymentToOrderInProgress, addTipOrderInProgress, updateSavedPayment } from "../../actions/order"
import { bridge_hijackedExecuteSalesRequest, bridge_setCFDScreen } from "../bridgeCalls/VNWebSDKDataSend"
import { getOrderInProgress } from "../../selectors/order"
import { setCurrentOrder } from "../../VNCustomerFacingDisplay/ActionCreators"
import { PAYMENT_TYPES_INTERNAL } from "../../utils/paymentTypes"
import { getCustomerPayingWithCard } from "../../VNDialogs/Selectors"

const TRANSACTION_ERROR_DELAY = 3000

export const userPaymentProgressHandler = (state, dispatch, data) => {
  const currentPaymentFlow = getCurrentPaymentFlow(state)
  const deviceMode = getDeviceMode(state)
  const order = getOrderInProgress(state)
  const customerPayingWithCard = getCustomerPayingWithCard(state)

  if (data.flow === 'VIEWING_RECEIPT' && currentPaymentFlow !== CFD_POS_PAYMENT_FLOWS.UNSET && data.freeItem === "true") {
    dispatch(setOpenCustomerEnteringTipDialog({open: false}))
    dispatch(setConfirmPaymentDialog(false))
    dispatch(setCustomerViewingReceiptDialog(true))
    dispatch(setTransactionCompleteDialog(false))
  }

  if (data.flow === 'VIEWING_RECEIPT' && data?.tabbed === "true") {
    if (data?.tenderData?.paymentType === PAYMENT_TYPES_INTERNAL.CREDIT_CARD) { // tabbed payment
      // dispatch is too slow to update the order so we are manually getting the amount in cents
      const amountInCents = parseInt(order.amountInCents) + parseInt(data.tipInCents ?? 0)
      bridge_hijackedExecuteSalesRequest(amountInCents, order.orderNumber, amountInCents)
      dispatch(setCustomerPayingWithCard({ open: true, amount: amountInCents}))
      dispatch(addTipOrderInProgress(parseInt(data.tipInCents ?? 0)))
      // For transaction errors
      dispatch(setCurrentOrder({amount: amountInCents}))
    } else { // tabbed saved card
      if (data?.tenderData?.paymentType === PAYMENT_TYPES_INTERNAL.CUSTOM_TENDER) {
        const amountInCents = parseInt(order.amountInCents) + parseInt(data.tipInCents ?? 0)

        dispatch(addPaymentToOrderInProgress({
          tenderAmountInCents: parseInt(order.amountInCents),
          paymentType: data.tenderData.name,
          shortDescription: data.tenderData.displayName,
          tipAmountInCents: parseInt(data.tipInCents ?? 0)
        }))
        
        bridge_setCFDScreen(
          CFD_SCREEN.RECEIPT,
          { cartTotals: { uuid: order?.uuid },
          transaction: { amount: amountInCents ? (amountInCents / 100) : 0 } }
        )
      } else {
        dispatch(updateSavedPayment({tipAmountInCents: parseInt(data.tipInCents ?? 0)}))
        bridge_setCFDScreen(CFD_SCREEN.RECEIPT)
      }
      dispatch(setConfirmPaymentDialog(false))
      dispatch(setCustomerViewingReceiptDialog(true))
      dispatch(setTransactionCompleteDialog(false))
    }
    dispatch(setOpenCustomerEnteringTipDialog({open: false}))
  }

  if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.QR_PAY ||
    currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.GIFT_CARD ||
    currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET) {
    if (data.flow === 'TRANSACTION_COMPLETE') {
        dispatch(setCustomerViewingReceiptDialog(false))
        dispatch(setTransactionCompleteDialog(true))
    } else if (data.flow === 'TRANSACTION_ERROR') {
      setTimeout(() => {
        dispatch(setCustomerPayingWithCard({open: false}))
        dispatch(setTransactionErrorDialog({ open: true, subTitle: data.subTitle }))
      }, TRANSACTION_ERROR_DELAY);
      return
    }
  } else if (deviceMode === MODES.POS && currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
    if (data.flow === 'TRANSACTION_ERROR') {
      setTimeout(() => {
        dispatch(setCustomerPayingWithCard({open: false}))
        dispatch(setTransactionErrorDialog({ open: true, subTitle: data.subTitle }))
      }, TRANSACTION_ERROR_DELAY);
      return
    }
  } else {
    if (data.flow === 'TRANSACTION_ERROR') {
      // legacy cfd payment flow has its own transaction error dialog so we need to check if the new dialog is open first.
      if (customerPayingWithCard.get('open')) {
        setTimeout(() => {
          dispatch(setCustomerPayingWithCard({open: false}))
          dispatch(setTransactionErrorDialog({ open: true, subTitle: data.subTitle }))
        }, TRANSACTION_ERROR_DELAY);
        return
      }
    }
  }

  switch (data.title) {
    case USER_PROGRESS.CARD_PAYMENT.AUTHORIZED_CARD.title:
      setCardAuthStepTwo({open: true, amount: data.amount})
      setCardAuthStepOne({open: false})
      break;
  
    default:
      break;
  }

  dispatch({
    type: VNCFD_ActionTypes.VNCFD_USER_PAYMENT_PROGRESS,
    data: data
  })
}