function isRequired (argumentName) {
  throw new Error(`${argumentName} is a required argument.`)
}

const Printer = ({ uuid = isRequired('uuid'), name = isRequired('name'), ...rest }) => {
  return {
    uuid,
    name,
    ...rest,
  }
}

export default Printer
