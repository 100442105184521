import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import generateUuid from 'uuid/v4'
import cn from 'classnames'
import styles from './RichCheckout.module.scss'

import VNConcessionsNavBar from '../components/VNConcessionsNavBar'
import GratuityHeader from '../components/GratuityHeader'
import QRCode from '../components/QRCode'
import Dialog from '../components/dialog/Dialog'
import ConfirmModalV2 from '../components/ConfirmModalV2'

import { saveRichCheckoutOrderRequested } from '../actions/richCheckout/order'
import { nextPosOrderNumber } from '../utils/orderNumbers'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { formatLocalItems, formatRemoteItems, generateURL } from '../utils/richCheckout'
import { VNPusher as VNPusherPrototype } from '../pusher'
import { RICH_CHECKOUT_TEXT_PROMPT, TEAM_LOGO_EXISTS } from '../constants'

import useRemoteOrderTotal from '../hooks/useRemoteOrderTotal'
import { formatOrderTotals } from '../utils/orderTotalUtils'
import { clearCart } from '../actions/cart'
import { clearPromotionsOnOrder } from '../actions/promotion'
import { getTotal, getCartItems } from '../selectors/cart'
import { centsToDollars } from '../utils/formatters'
import { addPaymentToOrderInProgress, closeNoOpTender, closePreAuthedRCOrder } from '../actions/order'

import { ReactComponent as MobilePhoneIcon } from '../assets/icons/MobilePhoneIcon.svg'
import { ReactComponent as MobileReceiptIcon } from '../assets/icons/MobileReceiptIcon.svg'
import { ReactComponent as QRCornersTop} from '../assets/qr-corners-top.svg'
import { ReactComponent as QRCornersBottom} from '../assets/qr-corners-bottom.svg'
import { ReactComponent as QRCornersTopMobile} from '../assets/qr-corners-top-mobile.svg'
import { ReactComponent as QRCornersBottomMobile} from '../assets/qr-corners-bottom-mobile.svg'
import { getOrderInProgress } from '../selectors/order'
import { isTabbed } from '../utils/orderStates'
import { isEmpty } from '../VNUtil/VNUtils'
import { getIsPreAuthTab } from '../utils/orderUtils'

const RichCheckout = ({
  deviceId,
  clearOrder,
  orderDidComplete,
  placeFreeOrder,
  goBack,
  networkAvailable,
  total,
  cartItems,
  className
}) => {
  const classNames = cn(styles.richCheckout, className)
  const VNPusher = useRef()
  const dispatch = useDispatch()

  const [orderId, setOrderId] = useState(generateUuid())
  const [isScanned, setIsScanned] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [orderNumber] = useState(nextPosOrderNumber())
  const [promptHeader, setPromptHeader] = useState('Pay With App')
  const [promptMessage, setPromptMessage] = useState(RICH_CHECKOUT_TEXT_PROMPT)
  const [loadRemoteOrderTotal, { data, loading, success, failed }] = useRemoteOrderTotal()
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const tabbed = isTabbed(orderInProgress)
  const remoteOrderTotal = formatOrderTotals(data)
  const totalInCents = getTotal()
  let items = formatLocalItems(cartItems)

  if (success) {
    items = formatRemoteItems(remoteOrderTotal?.lineItems)
  }
  
  if (!isEmpty(orderInProgress)) {
    items = formatLocalItems(orderInProgress.itemModels)
  }
  
  const totalDisplay = tabbed ? centsToDollars(totalInCents) : (success ? remoteOrderTotal?.totalDisplay : centsToDollars(total))
  const noPaymentRequired = totalInCents === 0
  const isMobileViewPort = window.matchMedia("(max-width: 500px)").matches
  const [qrCodeData, setQRCodeData] = useState()
  const [confirmWithoutSignal, setConfirmWithoutSignal] = useState(false)
  const [showGoBack, setShowGoBack] = useState(true)
  const primaryText = loading ? 'Loading...' : totalDisplay
  const topCorners = isMobileViewPort ? <QRCornersTopMobile /> : <QRCornersTop />
  const bottomCorners = isMobileViewPort ? <QRCornersBottomMobile /> : <QRCornersBottom />

  const finishTransactionSubtext = "Please finish this\n transaction on your phone."

  const showMobileReceiptSubtext = isMobileViewPort
    ? "Please show your mobile receipt to the attendant to confirm your order."
    : "Please show your mobile receipt to the\n attendant to confirm your order."

  useEffect(() => {
    VNPusher.current = new VNPusherPrototype()
    if (!tabbed) {
      loadRemoteOrderTotal()
    }

    if (!isEmpty(orderInProgress)) {
      setOrderId(orderInProgress.uuid)
    }
  }, [])

  useEffect(() => {
    if (!networkAvailable) {
      setShowGoBack(false)
      setTimeout(() => {
        setConfirmWithoutSignal(true)
      }, 45000)
    }
}, [networkAvailable])

  useEffect(() => {
    if (isScanned) {
      setPromptHeader('Processing...')
      setPromptMessage('please wait')
    } else {
      setPromptHeader('Pay With App')
      setPromptMessage(RICH_CHECKOUT_TEXT_PROMPT)
    }
  }, [isScanned])

  useEffect(() => {
    if (success) {
      setOrderId(remoteOrderTotal.uuid)
    }
  }, [success])

  useEffect(() => {
    if (noPaymentRequired && !loading && (success || failed)) {
      placeFreeOrder(orderId)
    }
  }, [totalInCents, loading, success, failed])

  useEffect(() => {
    if (isSuccess) {
      setPromptHeader('Success!')
      onConfirmOrder()
    } else {
      setPromptHeader('Pay with App')
      setPromptMessage(RICH_CHECKOUT_TEXT_PROMPT)
    }
  }, [isSuccess])

  useDeepCompareEffect(() => {
    if (tabbed || (!loading && (success || failed) )) {
      // tabbed order already has a orderNumber
      if (isTabbed(orderInProgress)) {
        setQRCodeData(generateURL(orderId, orderInProgress.orderNumber, deviceId, totalInCents, items))
      } else {
        setQRCodeData(generateURL(orderId, orderNumber, deviceId, totalInCents, items))
      }
    }
  }, [orderId, orderNumber, deviceId, totalInCents, items, loading, success, failed])

  const onBind = () => {
    setIsScanned(true)
  }

  function getTotal() {
    if (tabbed) {
      return orderInProgress?.amountInCents
    }

    return remoteOrderTotal?.totalAmountInCents ?? total
  }

  const onOrderCreated = () => {
    setIsSuccess(true)
  }

  const onConfirmOrder = () => {
    if (isTabbed(orderInProgress)) {
      const tenderAmountInCents = getTotal()
      const paymentType = 'mobile_app'
      const shortDescription = 'App'

      if (getIsPreAuthTab(orderInProgress)) {
        dispatch(closePreAuthedRCOrder({ uuid: orderId }))
        return
      }
      
      dispatch(addPaymentToOrderInProgress({ tenderAmountInCents, paymentType, shortDescription, isRichCheckout: true }))
    } else {
      dispatch(saveRichCheckoutOrderRequested({ uuid: orderId, orderNumber }))
    }

    clearOrder()
    orderDidComplete(orderId)
  }

  // pusher socket for rich checkout
  useEffect(() => {
    VNPusher.current.subscribeDeviceChannel()
    VNPusher.current.bindOrderTransfered(onBind)
    VNPusher.current.bindOrderCreated(onOrderCreated)

    // we'll want to use the below when the ios sdk persists the order uuid from the qr code
    // const orderChannel = socket.subscribe(`private-order-${uuid}`)
    // orderChannel.bind('order-created', (data) => {
    //   saveRichCheckoutOrder({ uuid, orderNumber })
    //   history.replace(`/rich-checkout/receipt/${uuid}`)
    // })
    return () => {
      VNPusher.current.unsubscribeDeviceChannel()
    }
    // TODO: We need to audit the exhaustive dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames}>
      <VNConcessionsNavBar 
        onClick={goBack}
        textDisplay={isMobileViewPort && primaryText}
        showIcon={networkAvailable}
      />
     {!isMobileViewPort && <GratuityHeader
          className={cn(styles.gratuityHeader)}
          primaryText={primaryText}
     />}
      <div className={styles.qrCodeContainer}>
          {!isScanned && qrCodeData && (
          <div className={styles.qrCode}>
            {topCorners}
            <QRCode
              data={qrCodeData}
              size={isMobileViewPort ? 159 : 289}
            />
            {bottomCorners}
          </div>
          )}   
      </div>
      <div className={styles.footer}>
        {TEAM_LOGO_EXISTS && <img className={styles.teamLogo} src='/teamLogo.png' />}
        <p>{promptHeader}</p>
        <p>{promptMessage}</p>
      </div>
      {isScanned && !isSuccess && (
        <Dialog>
          <ConfirmModalV2 
            headerText={<MobilePhoneIcon className={styles.icon} />}
            subtext={finishTransactionSubtext}
            buttonTwoText={'GO BACK'}
            onButtonTwoClick={goBack}
            singleButtonOnly={true}
          />
        </Dialog>
      )}
      {confirmWithoutSignal && (
        <Dialog>
          <ConfirmModalV2 
            headerText={<MobileReceiptIcon className={styles.icon} />}
            subtext={showMobileReceiptSubtext}
            buttonOneText={'GO BACK'}
            onButtonOneClick={goBack}
            buttonTwoText={'CONFIRM ORDER'}
            onButtonTwoClick={onConfirmOrder}
          />
        </Dialog>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const deviceId = state?.config?.uuid ?? localStorage.getItem('device_id')
  const networkAvailable = state?.config?.networkAvailable ?? false
  const total = getTotal(state)
  const cartItems = getCartItems(state)


  return {
    deviceId,
    total,
    cartItems,
    networkAvailable,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const goBack = props?.history?.goBack ?? function () {}
  const replace = props?.history?.replace ?? function () {}

  return {
    goBack,
    clearOrder: () => {
      dispatch(clearCart())
      dispatch(clearPromotionsOnOrder())
    },
    orderDidComplete: (orderId) => {
      replace(`/receipt/${orderId}`)
    },
    placeFreeOrder: (orderUuid) => {
      dispatch(closeNoOpTender({ orderUuid }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RichCheckout)
