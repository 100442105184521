import { useEffect, useState } from 'react'
import moment from 'moment'

import { store } from '../stores'
import { purgeOrders, requestPerformMaintenance } from '../actions/order'
import { getPurgedAt, getPurgableOrders, getMaintenancedAt } from '../selectors/order'
import useInterval from './useInterval'
import useTimeout from './useTimeout'

// 24 hours in milliseconds
const THRESHOLD = 86400000

const dateUntilNextEvent = (offset = 0) => {
  const now = moment()
  const nextEvent = now.clone().set({ h: 4 + offset, m: 0, s: 0 })
  if (now.isAfter(nextEvent)) nextEvent.add(1, 'day')

  return nextEvent
}

const msUntilNextEvent = (offset = 0) => {
  const now = moment()
  const nextEvent = dateUntilNextEvent(offset)
  return nextEvent.diff(now)
}

const needsPurging = () => {
  const purgedAt = getPurgedAt(store.getState())
  if (purgedAt === undefined) return true

  const now = moment()
  const lastPurged = moment(purgedAt)
  const msSincePurge = now.diff(lastPurged)

  return msSincePurge >= THRESHOLD
}

const needsMaintenance = () => {
  const maintenancedAt = getMaintenancedAt(store.getState())
  if (maintenancedAt === undefined) return true

  const now = moment()
  const lastPurged = moment(maintenancedAt)
  const msSincePurge = now.diff(lastPurged)

  return msSincePurge >= THRESHOLD
}

const beginPurgingOrders = () => {
  const purgableOrders = getPurgableOrders(store.getState(), THRESHOLD)
  store.dispatch(purgeOrders(purgableOrders))
}

const beginMaintenance = () => {
  store.dispatch(requestPerformMaintenance())
}

const usePurgeOrders = () => {
  const [purgeTime, setPurgeTime] = useState(null)
  const [maintenanceTime, setMaintenanceTime] = useState(null)

  useEffect(() => {
    if (needsPurging()) beginPurgingOrders()
    // delay 1 hour
    const timer = setTimeout(() => {
      if (needsMaintenance()) beginMaintenance()
    }, 3600000)
    return () => clearTimeout(timer)
  }, [])

  useTimeout(() => {
    setPurgeTime(THRESHOLD)
  }, msUntilNextEvent())

  useTimeout(() => {
    setMaintenanceTime(THRESHOLD)
  }, msUntilNextEvent(1))

  useInterval(() => {
    beginPurgingOrders()
  }, purgeTime)

  useInterval(() => {
    beginMaintenance()
  }, maintenanceTime)
}

export default usePurgeOrders
