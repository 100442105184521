import axios from 'axios'

import PeripheralBridge from '../utils/peripheralBridge'

import { store } from '../stores'

import { setNetworkAvailable } from '../actions/config'

const minimumRecommendedSpeed = 10

// TODO(mkramerl): Refactor this so that getNetworkAvailableValue is used instead of convenience
// functions covering the same functionality.

export const setNetworkAvailableValue = (available) => {
  store.dispatch(setNetworkAvailable(available))
}

export const getNetworkAvailableValue = () => {
  const state = store.getState()
  return state?.config?.networkAvailable
}

const networkConnectedSynchronous = (cancelToken, onSuccess, onFailure) => {
  axios.get('/blank.png', {
    headers: { 'Cache-Control': 'no-cache' },
    cancelToken: cancelToken.token
  }).then(() => onSuccess()).catch(() => onFailure())
}

// This function is the "primary" method used to check if the network is connected. It runs
// callbacks on success and failure. This should be the main entry point for network connectivity
// checks utilized only by the application's network indicator.
export const primaryNetworkConnectedWithCallbacks = (onSuccess, onFailure) => {
  return new Promise((resolve, reject) => {
    const cancelToken = axios.CancelToken.source()
    var hasResponse = false
    const actualSuccess = () => {
      hasResponse = true

      onSuccess()
      resolve()
    }

    const actualFailure = () => {
      hasResponse = true

      if (!onFailure) reject()
      onFailure()
    }

    networkConnectedSynchronous(cancelToken, actualSuccess, actualFailure)

    setTimeout(() => {
      if (cancelToken) cancelToken.cancel()

      if (!hasResponse) {
        if (!onFailure) reject()
        onFailure()
      }
    }, 5000)
  })
}

// A cover method for use by non-indicator components. This method will run callbacks passed to it
// based on the current network connection state. The current state should be pulled from the active
// state for consistency.
export const networkConnectedWithCallbacks = (onSuccess, onFailure) => {
  getNetworkAvailableValue() ? onSuccess() : onFailure()
}

// A cover method for use by non-indicator components. This method will return a promise that will
// resolve if the network is connected and reject if it is not. The result should be pulled from
// the active state for consistency.
export const networkConnected = () => {
  return new Promise((resolve, reject) => {
    getNetworkAvailableValue() ? resolve() : reject()
  })
}

// The cover method for the best signal strength algorithm
export const networkSignal = (setSignal) => {
  signalAlgorithm(setSignal)
}

// A network signal algorithm takes a signal strength setter and sets the signal to an integer from
// 0 to 4.

// The APK's signal strength algorithm.
export const networkSignalApk = (setSignal) => {
  // TODO(mkramerl): Implement APK bridge callback.
  const bridgeInstance = PeripheralBridge.getBridge()

  if (bridgeInstance) {
    bridgeInstance.registerHandler('networkStatusInfo', function(data, callback) {
      setSignal(Math.max((data?.calculatedSignalStrength || 0) - 1, 0))
    })

    bridgeInstance.call('getNetworkStatusInfo')
  } else {
    setSignal(4)
  }
}

// The default signal strength algorithm.
export const networkSignalDefault = (setSignal) => {
  // TODO(mkramerl): Implement APK bridge callback.
  setSignal(4)
}

// The file-based speed test algorithm.
export const networkSignalSpeed = (setSignal) => {
  // TODO(mkramerl): Replace with actual speed test.
  return getNetworkAvailableValue() ? setSignal(4) : setSignal(0)

  // Let's initialize the primitives
  var startTime, endTime, fileSize;

  // Set up the AJAX to perform
  var xhr = new XMLHttpRequest();

  // Rig the call-back... THE important part
  xhr.onreadystatechange = function () {
    // we only need to know when the request has completed
    if (xhr.readyState === 4 && xhr.status === 200) {

      // Here we stop the timer & register end time
      endTime = (new Date()).getTime();

      // Also, calculate the file-size which has transferred
      fileSize = xhr.responseText.length;
      // N.B: fileSize reports number of Bytes

      // Calculate the connection-speed
      var speed = 8 * fileSize / ((endTime - startTime) / 1000) / 1024 / 1024;

      if (speed < minimumRecommendedSpeed / 4) {
        setSignal(1)
      } else if (speed < minimumRecommendedSpeed / 2) {
        setSignal(2)
      } else if (speed < minimumRecommendedSpeed) {
        setSignal(3)
      } else {
        setSignal(4)
      }
    } else {
      setSignal(0)
    }
  }

  // Snap back; here's where we start the timer
  startTime = (new Date()).getTime();

  // All set, let's hit it! Add Math random to avoid caching depending on header setup.
  xhr.open("GET", "net_test?" + Math.random(), true);
  xhr.send();
}

// This is for easy algorithm switching.
export const signalAlgorithm = networkSignalApk

export default networkConnected
