import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { getOrderInProgress, makeGetOrder } from '../../selectors/order'
import { getTax, getServiceCharge } from '../../selectors/cart'
import { makeGetCurrentMenu, getIsKiosk } from '../../selectors/menus'
import ReceiptDetails from '../../components/receipt/ReceiptDetails'
import { makeGetRevenueCenter } from '../../selectors/revenueCenter'

const mapStateToProps = (state, ownProps) => {
  const orderUuid = get(ownProps, 'match.params.orderUuid', undefined)
  let order = {}

  if (orderUuid === 'split-tender') {
    order = getOrderInProgress(state)
  } else {
    const getOrder = makeGetOrder()
    order = orderUuid ? getOrder(state, orderUuid) : getOrder(state, state.order.currentOrderId)
  }

  const isMultiPayment = order?.isMultiPayment
  const payments = order?.payments ?? []

  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const taxRate = getTax(state)
  const serviceCharge = getServiceCharge(state)
  const isKiosk = getIsKiosk(state)
  const getRevenueCenter = makeGetRevenueCenter()
  const revenueCenter = getRevenueCenter(state, order.revenueCenterUuid)

  return {
    taxRate,
    serviceCharge,
    order,
    menuName: menu.name,
    isKiosk,
    revenueCenter,
    payments,
    isMultiPayment
  }
}

export default withRouter(connect(mapStateToProps, undefined)(ReceiptDetails))
