import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VNDialog } from '../../VNCustomerFacingDisplay/components/VNDialog'
import { getCustomerEnteringTipDialogOpen } from '../Selectors'
import { centsToDollars } from '../../utils/formatters'
import { setOpenCustomerEnteringTipDialog } from '../ActionCreators'
import { bridge_setCFDScreen } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../../VNCustomerFacingDisplay/Enums'
import { clearOrderInProgress } from '../../actions/order'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { clearRemoteOrderTotal } from '../../actions/orderTotalRemote'
import { getOrderInProgress } from '../../selectors/order'
import { isTabbed } from '../../utils/orderStates'

export const VNCustomerEnteringTipDialog = () => {

  const dispatch = useDispatch()

  const dialogOptions = useSelector(state => getCustomerEnteringTipDialogOpen(state))
  
  // eslint-disable-next-line
  const [loadRemoteOrderTotal, {}, resetOrderTotals] = useRemoteOrderTotal()

  const orderInProgress = useSelector(state => getOrderInProgress(state))

  return <VNDialog  open={dialogOptions?.get('open')}
                    subTitle={dialogOptions?.get('tipsEnabled') ? "Customer Entering Tip on {{AMOUNT}}".replace('{{AMOUNT}}', centsToDollars(dialogOptions?.get('amount'))) : "Customer Confirming Total"}
                    primaryButtonText={"GO BACK"}
                    onPrimaryButtonClick={() => {

                      // send cfd back to cart screen
                      bridge_setCFDScreen(CFD_SCREEN.CART)

                      if (!isTabbed(orderInProgress)) {
                        // reset order totals
                        resetOrderTotals()
  
                        clearRemoteOrderTotal()
  
                        dispatch(clearOrderInProgress())
                      }

                      dispatch(setOpenCustomerEnteringTipDialog({open: false}))
                      
                    }} />
}