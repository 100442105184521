/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { map } from 'lodash'

import SidebarModal from './SidebarModal'
import BackChevron from './BackChevron'
import { ReactComponent as Unchecked } from '../assets/icons/fill-unchecked.svg'
import { ReactComponent as Checkmark } from '../assets/icons/fill-checkmark.svg'

import useRemoteOrderTotal from '../hooks/useRemoteOrderTotal'

import styles from './SelectNoOpPayment.module.scss'
import Button from './Button'

const SelectNoOpPayment = ({ promotions, onClose, noOpTenders, closeNoOpTender, routeToChangeCalculator, className, ...props }) => {
  const classNames = cn(styles.selectNoOpPayment, className)
  const [ selectedTender, setSelectedTender ] = useState()
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, loading }] = useRemoteOrderTotal()
  const noPaymentRequired = remoteOrderTotal?.totalAmountInCents === 0
  const title = "Other"

  useEffect(() => {
    loadRemoteOrderTotal()
  }, [])

  useEffect(() => {
    if (noPaymentRequired && !loading) {
      handleNext({ name: 'cash' })
    }
  }, [loading])

  const renderTenderSelections = (tender, index) => {
    const isSelected = tender.name === selectedTender?.name
    let checkmarkElement = isSelected ? <Checkmark /> : <Unchecked />

    return (
      <div className={styles.selectableItem} key={`${tender.name}-${index}`} onClick={() => setSelectedTender(tender)}>
        <div className={cn([styles.tender, isSelected && styles.selected])}>
          <div className={styles.column}>
            {checkmarkElement}
          </div>
          <div className={styles.column}>
            <span className={styles.name}>{tender.displayName}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNext = (tender) => {
    if (tender.name === 'cash') {
      routeToChangeCalculator()
    } else {
      closeNoOpTender(tender)
    }
  }

  return (
    <SidebarModal
      show
      title={title}
      onClose={onClose}
      leftAccessory={<BackChevron onClick={onClose} />}
      className={classNames} {...props}
    >
      <div className={styles.selectNoOpPayment}>
        <div className={styles.paymentContainer}>
          {map(noOpTenders, renderTenderSelections)}
        </div>
        <div className={styles.submitContainer}>
          { selectedTender && <Button
            disabled={loading}
            className={styles.submitButton}
            onClick={
              () => {
                handleNext(selectedTender)
              }
            }>
            Next
          </Button> }
        </div>
      </div>
    </SidebarModal>
  )
}

export default SelectNoOpPayment
