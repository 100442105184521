import { toast } from 'react-toastify'

const crypto = require('crypto')

const stringToUUID = (str) => {
  // Validate input
  if (typeof str !== 'string') {
    throw new Error('Input must be a string')
  }

  // Create a SHA-256 hash of the string
  const hash = crypto.createHash('sha256').update(str).digest('hex')

  // Use the first 16 bytes of the hash to create a UUID
  let i = 0 // Initialize index
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = parseInt(hash[i++], 16) % 16 // Use the current index of the hash
    const v = c === 'x' ? r : (r & 0x3 | 0x8) // Set the version to 4
    return v.toString(16)
  })

  return uuid
}

// A simple wrapper around the toast library to make it easier to adapt in the future.
export class ToastManager {

  static error(message, options) {
    if (!message) return

    const toastId = stringToUUID(message)
    if (toast.isActive(toastId)) { return }

    const toastOptions = {
      ...(options || {}),
      toastId: toastId
    }
    toast.error(message, toastOptions)
  }

  static success(message, options) {
    if (!message) return

    const toastId = stringToUUID(message)
    if (toast.isActive(toastId)) { return }

    const toastOptions = {
      ...(options || {}),
      toastId: toastId
    }
    toast.success(message, toastOptions)
  }

  static info(message, options) {
    if (!message) return

    const toastId = stringToUUID(message)
    if (toast.isActive(toastId)) { return }

    const toastOptions = {
      ...(options || {}),
      toastId: toastId
    }
    toast.info(message, toastOptions)
  }
}

