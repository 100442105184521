import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { VNDialog } from '../../VNCustomerFacingDisplay/components/VNDialog'
import { getCustomerPaymentDialogOpen } from '../Selectors'
import { centsToDollars } from '../../utils/formatters'
import { setOpenCustomerPaymentDialog } from '../ActionCreators'
import { sendCFDScreenNav } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../../VNCustomerFacingDisplay/Reducer'
import { clearOrderInProgress } from '../../actions/order'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { clearRemoteOrderTotal } from '../../actions/orderTotalRemote'
import { getOrderInProgress } from '../../selectors/order'
import { isTabbed } from '../../utils/orderStates'

const useStyles = makeStyles(theme => ({
  
}))
/**
 * 
 * @returns a dialog that either contains the title customer entering tip or confirming total depending if the tips are enabled
 */
export const VNCustomerPaymentDialog = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const dialogOptions = useSelector(state => getCustomerPaymentDialogOpen(state))

  const orderInProgress = useSelector(state => getOrderInProgress(state))

  const [loadRemoteOrderTotal, {}, resetOrderTotals] = useRemoteOrderTotal()

  return <VNDialog  open={dialogOptions?.get('open')}
                    subTitle={dialogOptions?.get('tipsEnabled') ? "Customer Entering Tip on {{AMOUNT}}".replace('{{AMOUNT}}', centsToDollars(dialogOptions?.get('amount'))) : "Customer Confirming Total"}
                    primaryButtonText={"CANCEL"}
                    onPrimaryButtonClick={() => {

                      // send cfd back to cart screen
                      sendCFDScreenNav(CFD_SCREEN.CART)

                      // reset order totals
                      resetOrderTotals()

                      clearRemoteOrderTotal()

                      if (!isTabbed(orderInProgress)) {
                        dispatch(clearOrderInProgress())
                      }

                      dispatch(setOpenCustomerPaymentDialog({open: false}))
                    }} />
}
