import React from 'react'
import {
  Router,
  Route,
} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import KioskApp from './kiosk/App'

import { customHistory } from './stores'

import AppRoutes from './AppRoutes'
import ErrorBoundary from './ErrorBoundary'

import { setErrorInfo } from './actions/appState'

import { VNWebSDKDataBridge } from './VNAndroidSDK/containers/VNWebSDKDataBridge'
import { VNCustomerFacingDisplay } from './VNCustomerFacingDisplay/containers/VNCustomerFacingDisplay'
import { VNModeSelect } from './VNMode/containers/VNModeSelect'
import { VNThemes, THEMES } from 'vn-react-components/VNThemes'
import { VNSnackbarSystem } from 'vn-react-components/VNSnackbarSystem/containers/VNSnackbarSystem'
import { getDeviceMode } from './VNMode/Selectors'

import createDataDogLog from './utils/datadog'
import { ToastManager } from './utils/toastManager'

import { VNDebugger } from './VNCustomerFacingDisplay/containers/VNDebugger';
import { VNDialogsContainer } from './VNDialogs/containers/VNDialogsContainer'
import { VNPOSListeners } from './VNCustomerFacingDisplay/containers/VNPOSListeners'
import { VNBalanceDue } from './VNCustomerFacingDisplay/containers/VNBalanceDue'
import { MODES } from './VNMode/Reducer'
import { VNPPI } from './VNPPI/containers/VNPPI'
import { VNMobileScannerSetup } from './VNMobileScanner/containers/VNMobileScannerSetup'
import { VNKeyboardActivity } from './VNAndroidSDK/VNKeyboardActivity'
import AppProviders from './context/AppProviders'

const App = () => {
  const dispatch = useDispatch()

  const cfdMode = useSelector(state => getDeviceMode(state))

  const handleError = (error) => {
    // Show a toast to the user and log event in data dog
    ToastManager.error('An unexpected error occurred. Please try again later.', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    createDataDogLog('error', `An unexpected error occurred: ${error?.message ?? 'N/A error message'}`, {
      error,
    })

    if (error && typeof error.preventDefault === 'function') {
      dispatch(setErrorInfo({
        message: error.message,
        stack: error.stack
      }))
      error.preventDefault();
    }

    /*
    Navigate to /device-login which will
    ensure a fresh valid token, fetch the app device config and
    route accordingly based on the device's last saved configuration.
     *** if CFD, navigate to root with the correct params that identify it as CFD
    */

   if (cfdMode === MODES.CFD) {
    customHistory.push('/?apk=true&cfd=true')
    return
   }

   customHistory.push('/device-login');
  }

  window.onerror = handleError;
  window.addEventListener('error', handleError);

  return (
      <Router history={customHistory}>
        <ToastContainer
          id="toast-container"
          position="bottom-left"
          draggable={false}
          hideProgressBar={true}
          closeOnClick={true}
          autoClose={2500}
          newestOnTop={false}
          limit={3}
          />

        <ErrorBoundary handleError={handleError} cfdMode={cfdMode}>
          <VNWebSDKDataBridge>
            <VNThemes theme={THEMES.INTERNAL}>
              <AppProviders>
                {/* <VNDebugger /> */}
                <Route exact path="/" render={(props) => <VNModeSelect {...props} />} />
                <Route path="/customer-facing-display" render={(props) => <VNCustomerFacingDisplay {...props} />} />
                <Route path="/pos/balance-due" render={(props) => <VNBalanceDue {...props} />} />
                <Route path="/kiosk" component={KioskApp} />
                <VNPPI />
                <AppRoutes />
                <VNDialogsContainer />
                <VNPOSListeners />
                <VNMobileScannerSetup/>
                <VNSnackbarSystem />
                <VNKeyboardActivity />
              </AppProviders>
            </VNThemes>
          </VNWebSDKDataBridge>
        </ErrorBoundary>
      </Router>
  )
}

export default App
