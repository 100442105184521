export const MENUS_REQUESTED = 'MENUS_REQUESTED'
export const MENUS_SUCCEEDED = 'MENUS_SUCCEEDED'
export const MENUS_FAILED = 'MENUS_FAILED'
export const MENUS_ITEMS_REQUESTED = 'MENUS_ITEMS_REQUESTED'
export const MENUS_ITEMS_SUCCEEDED = 'MENUS_ITEMS_SUCCEEDED'
export const MENUS_ITEMS_FAILED = 'MENUS_ITEMS_SUCCEEDED'
export const CURRENT_MENU_SELECTED = 'CURRENT_MENU_SELECTED'

export const menusRequested = (payload) => ({
  type: MENUS_REQUESTED,
  payload,
})

export const menusFailed = () => ({
  type: MENUS_FAILED,
})

export const menusItemsRequested = () => ({
  type: MENUS_ITEMS_REQUESTED,
})

export const menusItemsFailed = () => ({
  type: MENUS_ITEMS_FAILED,
})

export const menusItemsSucceeded = data => ({
  type: MENUS_ITEMS_SUCCEEDED,
  payload: data,
})

export const menusSucceeded = data => ({
  type: MENUS_SUCCEEDED,
  payload: data,
})

export const currentMenuSelected = id => ({
  type: CURRENT_MENU_SELECTED,
  payload: id,
})
