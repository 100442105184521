import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import Remote from '../remote'
import createDataDogLog from '../utils/datadog'
import { ToastManager } from '../utils/toastManager'

const useGetTicketBalance = () => {
  const [dataLoading, setLoading] = useState(false)
  const [dataSucceeded, setSuccess] = useState(false)
  const [dataFailed, setFailed] = useState(false)
  const [ticketBalance, setTicketBalance] = useState()
  const [directAuthEnabled, setDirectAuthEnabled] = useState(false)
  const cancelTokenRef = useRef()

  useEffect(() => {
    cancelTokenRef.current = axios.CancelToken.source()

    return () => {
      cancelTokenRef.current.cancel()
    }
  }, [])

  const getTicketBalance = (ticketId) => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)

    Remote.getTicketBalance(ticketId).then((result) => {
      setTicketBalance(result?.data?.balance ?? 0)
      setDirectAuthEnabled(result?.data?.directAuthEnabled ?? false)
      setLoading(false)
      setSuccess(true)
    }).catch((err) => {
      if (axios.isCancel(err)) return

      ToastManager.error(`There was an error in retrieving this ticket balance amount, ERROR: ${err ?? 'No Error Sent'}`)
      createDataDogLog('error', `There was an error in retrieving this ticket balance amount, ERROR: ${err ?? 'No Error Sent'}`, { ticketId })
      setLoading(false)
      setFailed(true)
    })
  }

  return [getTicketBalance, { ticketBalance, directAuthEnabled, dataLoading, dataSucceeded, dataFailed }]
}

export default useGetTicketBalance
