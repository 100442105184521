import * as ActionTypes from './ActionTypes'
import { apiGetPpiConfigurations, apiPostPpiProxy } from './Api'

/**
 * 
 * @returns 
 */
export function getPpiConfigurations() {
  return (dispatch, getState) => {
    apiGetPpiConfigurations().then(response => {
      dispatch({
        type: ActionTypes.VNPPI_SET_CONFIGURATIONS,
        configs: response.data
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

/**
 * 
 * @param {*} apiFunc 
 * @param {*} integration 
 * @param {*} transaction 
 * @returns 
 */
export function postPpiProxy(apiFunc, integration, transaction) {
  return (dispatch, getState) => {
    apiPostPpiProxy(apiFunc, integration, transaction).then(response => {
      dispatch({
        type: ActionTypes.VNPPI_SET_CONFIGURATIONS,
        configs: response.data
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

/**
 * Set the order payments that Stadium returned
 * @param {String} orderUuid - The order UUID
 * @param {Array} payments - An array of payments 
 * @returns 
 */
export function setOrderPayments(orderUuid, payments) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNPPI_SET_ORDER_PAYMENTS,
      orderUuid: orderUuid,
      payload: payments
    })
  }
}

/**
 * These are orders that are generated on POS but haven't been submited to Stadium
 * 
 * @param {String} orderUuid - The order UUID
 * @param {Array} payments - An array of payments 
 * @returns 
 */
export function setPreOrderPayments(orderUuid, payments) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNPPI_SET_PRE_ORDER_PAYMENTS,
      orderUuid: orderUuid,
      payload: payments
    })
  }
}

/**
 * When a tender is scanned, the token or value needs to be stored for that order specifically so we can reference it later
 * @param {String} orderUuid The current order uuid
 * @param {String} token - The scanned token or value
 * @returns 
 */
export function setScannedTenderForOrder(orderUuid, token) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNPPI_ADD_SCANNED_TENDER_TO_ORDER,
      orderUuid: orderUuid,
      token: token
    })
  }
}