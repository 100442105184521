import { setBridgePaymentMethod } from '../actions/peripheral'
import { bridgePaymentMethods } from '../reducers/peripheral'
import { bridge_updateCurrentPaymentFlow } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { setCardAuthStepOne, setCardAuthStepTwo, setConfirmPaymentDialog, setCustomerPayingWithCard, setCustomerViewingReceiptDialog, setOpenCustomerEnteringTipDialog, setOpenCustomerPaymentDialog, setTransactionCompleteDialog, setTransactionErrorDialog } from '../VNDialogs/ActionCreators'
import * as ActionTypes from './ActionTypes'
import { CFD_EVENTS, CFD_POS_PAYMENT_FLOWS } from './Enums'
import { CFD_SCREEN } from './Reducer'
import { getCurrentOrder } from './Selectors'

export function setCartTotals(totals) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_SET_CART_TOTALS,
      totals: totals
    })
  }
}

export function setCFDScreen(screen) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_SET_SCREEN,
      screen: screen
    })
  }
}

export function setPaymentData(paymentData) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_PAYMENT_DATA,
      data: paymentData
    })
  }
}

// reset user progress on POS.
export function setUserProgress(paymentProgress) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_USER_PAYMENT_PROGRESS,
      data: paymentProgress
    })
  }
}

// an update on an order from CFD to POS
export function setOrderUpdate(orderUpdate) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VN_ORDER_UPDATE,
      data: orderUpdate
    })
  }
}

// if false will send a message to pos with payment progress
export function setCanExecuteSalesRequest(executeSalesRequest) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VN_CAN_EXECUTE_SALES_REQUEST,
      data: executeSalesRequest
    })
  }
}

export function setOrderDetails(orderDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_RICH_CHECKOUT_ORDER_DETAILS,
      data: orderDetails
    })
  }
}

export function setBalanceToBePaid(balanceToBePaid) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_BALANCE_TO_BE_PAID,
      data: balanceToBePaid
    })
  }
}

export function setCurrentPaymentFlow(currentFlow, syncAcrossBridge) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_CURRENT_PAYMENT_FLOW,
      currentFlow: currentFlow
    })

    if (syncAcrossBridge) {
      // we also need to keep the CFD in line, so we need to pass it over the bridge
      bridge_updateCurrentPaymentFlow(currentFlow)
    }
  }
}

export function resetCFD(syncAcrossBridge = true) {
  return (dispatch) => {

    dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, syncAcrossBridge))
    dispatch(setBalanceToBePaid({}))
    dispatch(updateTransactionLifecycle(CFD_EVENTS.IDLE))
    dispatch(setUserProgress({}))

    dispatch(setCFDScreen(CFD_SCREEN.IDLE))

    dispatch(setCardAuthStepOne({open: false}))
    dispatch(setCardAuthStepTwo({open: false}))
    dispatch(setConfirmPaymentDialog(false))
    dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeSaleRequest))
    dispatch(setCustomerViewingReceiptDialog(false))
    dispatch(setTransactionCompleteDialog(false))
    dispatch(setOpenCustomerPaymentDialog({open: false}))
    dispatch(setCustomerPayingWithCard({open: false}))
    dispatch(setOpenCustomerEnteringTipDialog({open: false}))
    dispatch(setTransactionErrorDialog({open: false}))

    dispatch({
      type: ActionTypes.VNCFD_EXECUTE_SALES_REQUEST,
      data: {}
    })

    dispatch({
      type: ActionTypes.VNCFD_UPDATE_CART,
      cartItems: []
    })

    dispatch({
      type: ActionTypes.VNCFD_SET_CART_TOTALS,
      totals: {}
    })

    dispatch({
      type: ActionTypes.VNCFD_TRANSACTION_RESULT,
      data: {}
    })

    dispatch({
      type: ActionTypes.VN_ORDER_UPDATE,
      data: {}
    })
  }
}

export function setCurrentCart(cart) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_CURRENT_CART,
      cart: cart
    })
  }
}

export function updateTransactionLifecycle(moment) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_UPDATE_TRANSACTION_LIFECYCLE,
      moment: moment
    })
  }
}

export function setCurrentOrder(order) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_SET_CURRENT_ORDER,
      order: order
    })
  }
}

export function updateCurrentOrder(order) {
  return (dispatch, getState) => {

    let currentOrder = getCurrentOrder(getState())
    
    const updatedOrder = {
      ...currentOrder,
      ...order
    }

    dispatch({
      type: ActionTypes.VNCFD_SET_CURRENT_ORDER,
      order: updatedOrder
    })
  }
}

export function setCalculatedCurrentCartValues(cart) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNCFD_CALCULATED_CURRENT_CART_VALUES,
      cart: cart
    })
  }
}