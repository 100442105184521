import React from 'react'
import cn from 'classnames'

import Dialog from './Dialog'

import styles from './OfflineOnDemandDialog.module.scss'

import { CloudOff } from '@material-ui/icons'

const OfflineOnDemandDialog = ({ onCancel, onConfirm, className }) => {
  const classNames = cn(styles.offlineOnDemandDialog, className)

  return (
    <Dialog title={<CloudOff fontSize='large'/>} message={"All payments are being processed offline"} className={classNames}>
      <div className={styles.buttonGroup}>
        <button onClick={onCancel}>{"Stay Offline"}</button>
        <button onClick={onConfirm}>{"Go Online"}</button>
      </div>
    </Dialog>
  )
}

export default OfflineOnDemandDialog
