import { toast } from 'react-toastify'

import { call, put, select, takeLatest } from 'redux-saga/effects'

import { get, set, cloneDeep, isEmpty, isEqual, filter } from 'lodash'

import {
  loadConfig,
  loadConfigSucceeded,
  loadConfigFailed,
  createConfig,
  createConfigSucceeded,
  createConfigFailed,
  updateConfig,
  updateConfigSucceeded,
  updateConfigFailed,
} from '@ordernext/networking-stadium/stadium'

import { customHistory } from '../stores'
import Remote from '../remote'
import { fetchAttendantsSaga } from './attendant'

import { clearCart } from '../actions/cart'
import { REFRESH_CONFIG, shouldWaitForRedirect } from '../actions/config'
import { currentMenuSelected, menusRequested, menusItemsRequested, menusSucceeded } from '../actions/menus'
import { clearPromotionsOnOrder } from '../actions/promotion'

import { getAttendants } from '../selectors/attendant'
import { getCurrentConfiguration } from '../selectors/config'
import { getPaymentType } from '../selectors/peripheral'
import { getCurrentMenu, getEmailReceiptsEnabled, getTextReceiptsEnabled } from '../selectors/menus'

import { deviceAuthenticated, saveConfig } from '../utils/bridgeMethods'
import createDataDogLog from '../utils/datadog'
import DeviceSubtypes from '../utils/deviceSubtypes'
import Environment from '../utils/environment'
import { ToastManager } from '../utils/toastManager'
import { getAccessToken, getDeviceId } from '../utils/deviceData'


import { getDeviceMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import { BRANDING_COLOR } from '../constants'
import { getPrintingEnabled } from '../selectors/printer'
import { sendInitializeCFD } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'

const directTo = (method = 'push', path) => {
  if (path === undefined) return

  customHistory[method](path)
}

const formatDeviceSubtype = (deviceSubtype) => {
  if (deviceSubtype === 'kiosk') return 'Kiosk'

  return 'Concessions'
}

const configsAreEqual = (config, newParams) => {
  const propertyAssertions = [
    ['deviceSubtype', 'payload.deviceSubtype'],
    ['configuration.menuUuids', 'payload.menuUuids'],
    ['configuration.terminalId', 'payload.terminalId'],
    ['configuration.storeId', 'payload.storeId'],
    ['configuration.esKey', 'payload.esKey'],
    ['configuration.receiptPrinterUuid', 'payload.receiptPrinterUuid'],
    ['configuration.deviceMode', 'payload.deviceMode'],
  ]

  return propertyAssertions.every(([pathOne, pathTwo]) => {
    return isEqual(get(config, pathOne), get(newParams, pathTwo))
  })
}

const syncPrintControllerWithBridge = (response) => {
  let isPrintController = get(response, 'data.appDeviceConfigurations[0].isPrintController', false)
  if (!isPrintController) isPrintController = get(response, 'data.appDeviceConfiguration.isPrintController', false)

  deviceAuthenticated({ isPrintController })
}

export function* reloadMenus(ignoreThrottle) {
  yield put(menusRequested({ ignoreThrottle }))
  yield put(menusItemsRequested())

  const currentConfig = yield select(getCurrentConfiguration)
  const menuUuids = get(currentConfig, 'configuration.menuUuids', [])

  if (menuUuids.length === 1) { yield put(currentMenuSelected(menuUuids[0])) }
}

export function* initialStandAndMenuSetup(menuUuids, venueUuid, deviceSubtype, redirect = true) {
  try {
    const paymentType = yield select((state) => getPaymentType(state))
    const result = yield call(Remote.fetchMenus, { paymentType })
    const { menus, stands } = result?.data ?? {}
    const filteredMenus = filter(menus, (menu) => menuUuids.includes(menu?.uuid))
    if (isEmpty(filteredMenus)) {
      yield call(directTo, 'push', '/revenue-center-setup')
      yield put(shouldWaitForRedirect(false))
      return
    }
    const standUuids = [...new Set(filteredMenus.map(menu => menu.standUuid))]
    const filteredStands = filter(stands, (stand) => standUuids.includes(stand?.uuid))
    yield put(menusSucceeded({ data: { stands: filteredStands, menus: filteredMenus } }))
    yield put(menusItemsRequested())
    yield put(currentMenuSelected(menuUuids[0]))

    const currentMenu = yield select(getCurrentMenu)
    const deviceConfiguration = yield select(getCurrentConfiguration)
    const deviceId = getDeviceId()
    const accessToken = getAccessToken()
    const cfdMode = yield select(getDeviceMode)
    const printingEnabled = yield select(getPrintingEnabled)
    const textReceiptsEnabled = yield select(getTextReceiptsEnabled)
    const emailReceiptsEnabled = yield select(getEmailReceiptsEnabled)

    // if the device is in CFD mode resend the initialization values incase they have changed.
    if (cfdMode === MODES.POS) {
      const initializationValues = {
        title: filteredStands?.[0]?.name,
        color: BRANDING_COLOR,
        deviceId: deviceId,
        appConfigurationDeviceId: deviceConfiguration?.uuid,
        accessToken: accessToken,
        printingEnabled: printingEnabled,
        textReceiptsEnabled: textReceiptsEnabled,
        emailReceiptsEnabled: emailReceiptsEnabled,
      }

      initializationValues.menuImage = currentMenu?.images?.detail
      initializationValues.image = filteredStands?.[0]?.images?.detail
      sendInitializeCFD(initializationValues)
    }
    if (DeviceSubtypes.isKiosk(deviceSubtype)) {
      const pathname = filteredMenus.length > 1 ? '/kiosk/menu_select' : '/kiosk/order/new'
      yield call(directTo, 'push', pathname)
      yield put(shouldWaitForRedirect(false))
    } else {
      yield call(fetchAttendantsSaga)
      const attendants = yield select(getAttendants)
      if (!redirect) {
        yield put(shouldWaitForRedirect(false))
        return
      }
      const pathname = isEmpty(attendants) ? '/concession-order' : '/user-login'
      yield call(directTo, 'push', pathname)
      yield put(shouldWaitForRedirect(false))
    }
  } catch {
    yield call(directTo, 'push', '/revenue-center-setup')
    yield put(shouldWaitForRedirect(false))
  }
}

let errorToast = undefined

function* getConfigRequest(redirect = true, shouldReloadMenus = true) {
  if (redirect) {
    yield put(shouldWaitForRedirect(true))
  }

  try {
    const result = yield call(Remote.getConfig)
    let deviceSubtype = get(result, 'data.appDeviceConfigurations[0].deviceSubtype')

    if (!deviceSubtype) {
      deviceSubtype = get(result, 'data.appDeviceConfiguration.deviceSubtype')
    }

    if (!['concessions-pos', 'kiosk'].includes(deviceSubtype)) {
      yield put(loadConfigSucceeded(result))
      yield call(directTo, 'push', '/revenue-center-setup')
      if (!toast.isActive(errorToast)) {
        errorToast = ToastManager.error(`Expected Concessions or Kiosk device type but received: ${formatDeviceSubtype(deviceSubtype ?? 'No subtype selected')}`)
        createDataDogLog('error', `Expected Concessions or Kiosk device type but received: ${formatDeviceSubtype(deviceSubtype ?? 'No subtype selected')}`)
      }

      return false
    }

    syncPrintControllerWithBridge(result)

    // TODO: (@westinschepper): Use this when we opt in to passing all data to the APK
    // const appDeviceConfig = get(result, 'data.appDeviceConfiguration', get(result, 'data.appDeviceConfigurations[0]', {}))
    // const flattenedAppDeviceConfig = {
    //   ...appDeviceConfig,
    //   ...appDeviceConfig.configuration,
    // }

    let max = get(result, 'data.appDeviceConfigurations[0].orderNumberEnd')
    let min = get(result, 'data.appDeviceConfigurations[0].orderNumberStart')
    let configuration = get(result, 'data.appDeviceConfigurations[0].configuration', {})
    let { terminalId, storeId, esKey, prodUrl, uatUrl, host, menuUuids, venueUuid } = configuration

    /**
     * TODO:
     * We should ask about getting this changed.
     * if there is only one configuration, Stadium returns a singular key name.
     */
    if (!max) max = get(result, 'data.appDeviceConfiguration.orderNumberEnd')
    if (!min) min = get(result, 'data.appDeviceConfiguration.orderNumberStart')
    if (!host) host = get(result, 'data.appDeviceConfiguration.configuration.host')
    if (!storeId) storeId = get(result, 'data.appDeviceConfiguration.configuration.storeId')
    if (!terminalId) terminalId = get(result, 'data.appDeviceConfiguration.configuration.terminalId')
    if (!esKey) esKey = get(result, 'data.appDeviceConfiguration.configuration.esKey')
    if (!prodUrl) prodUrl = get(result, 'data.appDeviceConfiguration.configuration.prodUrl', 'https://cs.freedompay.us/Fasta/')
    if (!uatUrl) uatUrl = get(result, 'data.appDeviceConfiguration.configuration.uatUrl', 'https://cs.uat.freedompay.com/Fasta/')
    if (isEmpty(menuUuids)) menuUuids = result?.data?.appDeviceConfigurations?.configuration?.menuUuids ?? []

    localStorage.setItem('orderNumberMax', max)
    localStorage.setItem('orderNumberMin', min)

    // FIXME: Update this when the URL's are coming back as expected from Stadium
    let url = uatUrl

    if (Environment.app.isProduction) {
      url = prodUrl
    }

    const deviceConfig = {
      storeID: storeId,
      terminalID: terminalId,
      esKey,
      host,
      url,
    }

    saveConfig(deviceConfig)

    if (!isEmpty(menuUuids)) {
      yield initialStandAndMenuSetup(menuUuids, venueUuid, deviceSubtype, redirect)
    }

    if (redirect) {
      if (isEmpty(menuUuids)) {
        yield call(directTo, 'push', '/revenue-center-setup')
        yield put(shouldWaitForRedirect(false))
      }
    } else if (shouldReloadMenus) {
      yield reloadMenus(true)
    }
    yield put(loadConfigSucceeded(result))
    return result
  } catch (err) {
    yield put(loadConfigFailed(err))
    yield put(shouldWaitForRedirect(false))
    if (redirect) {
      yield call(directTo, 'push', '/revenue-center-setup')
    }
  }
}

export function* getConfig(params = {}) {
  // If the app reloads (should refresh config) or is navigated to root (device login) "redirect" flag will be true and will navigate
  // the user based on the device's fetched app device configuration
  const redirect = params?.payload?.redirect
  const shouldReloadMenus = params?.payload?.shouldReloadMenus
  yield call(getConfigRequest, redirect, shouldReloadMenus)
}

function* refreshConfigSaga() {
  try {
    yield put(clearCart())
    yield put(clearPromotionsOnOrder())
    let result = yield call(getConfigRequest, false)

    if (result) {
      ToastManager.success('Config refreshed successfully')
    }
  } catch (err) {
    ToastManager.error('Failed to refresh config')
  }
}

export function* watchGetConfig() {
  yield takeLatest(loadConfig.type, getConfig)
}

export function* watchRefreshConfigSaga() {
  yield takeLatest(REFRESH_CONFIG, refreshConfigSaga)
}

export function* _createConfig(params = {}) {
  try {
    const menuUuids = get(params, 'payload.menuUuids', [])
    const printerUuid = get(params, 'payload.receiptPrinterUuid')
    const deviceSubtype = get(params, 'payload.deviceSubtype')
    const deviceMode = get(params, 'payload.deviceMode')

    const body = {
      appDeviceConfigurations: [
        {
          configuration: {
            menuUuids: menuUuids,
            deviceMode: deviceMode,
            receiptPrinterUuid: printerUuid,
          },
          deviceSubtype,
          organizationName: process.env.REACT_APP_ORG,
          venueUuid: process.env.REACT_APP_VENUE_UUID,
        },
      ]
    }

    const result = yield call(Remote.createConfig, body)

    syncPrintControllerWithBridge(result)

    const max = get(result, 'data.appDeviceConfigurations[0].orderNumberEnd')
    const min = get(result, 'data.appDeviceConfigurations[0].orderNumberStart')
    localStorage.setItem('orderNumberMax', max)
    localStorage.setItem('orderNumberMin', min)

    yield put(createConfigSucceeded(result))
    yield reloadMenus()
    yield call(getConfigRequest, undefined, false)
  } catch (err) {
    yield put(createConfigFailed(err))
  }
}

export function* watchCreateConfig() {
  yield takeLatest(createConfig.type, _createConfig)
}

export function* _updateConfig(params = {}) {
  try {
    const menuUuids = get(params, 'payload.menuUuids')
    const terminalId = get(params, 'payload.terminalId')
    const storeId = get(params, 'payload.storeId')
    const esKey = get(params, 'payload.esKey')
    const deviceSubtype = get(params, 'payload.deviceSubtype')
    const deviceMode = get(params, 'payload.deviceMode')
    const printerUuid = get(params, 'payload.receiptPrinterUuid')
    const currentConfiguration = yield select((state) => get(state, 'config'))
    const clone = cloneDeep(currentConfiguration)

    if (!isEmpty(menuUuids)) {
      set(clone, 'configuration.menuUuids', menuUuids)
    }

    if (deviceSubtype) {
      set(clone, 'deviceSubtype', deviceSubtype)
    }

    if (deviceMode) {
      set(clone, 'configuration.deviceMode', deviceMode)
    }

    if (terminalId && storeId && esKey) {
      set(clone, 'configuration.terminalId', terminalId)
      set(clone, 'configuration.storeId', storeId)
      set(clone, 'configuration.esKey', esKey)
    }

    if (printerUuid) {
      set(clone, 'configuration.receiptPrinterUuid', printerUuid)
    }

    delete clone.hasConfig
    delete clone.menuUuid
    delete clone.remote

    const body = {
      appDeviceConfigurations: [clone],
      uuid: get(currentConfiguration, 'uuid')
    }

    if (configsAreEqual(currentConfiguration, params)) {
      // This success action just merges, so it is safe to pass an empty object.
      yield put(updateConfigSucceeded({}))
      yield call(getConfigRequest, false)
      yield reloadMenus()
      return
    }

    const result = yield call(Remote.updateConfig, body)

    syncPrintControllerWithBridge(result)

    const max = get(result, 'data.appDeviceConfigurations[0].orderNumberEnd')
    const min = get(result, 'data.appDeviceConfigurations[0].orderNumberStart')
    localStorage.setItem('orderNumberMax', max)
    localStorage.setItem('orderNumberMin', min)

    yield put(updateConfigSucceeded(result))
    yield call(getConfigRequest, false)
    yield reloadMenus()
  } catch (err) {
    yield put(updateConfigFailed(err))
    if (err?.response?.data?.errorMessage?.includes("Validation failed")) {
      yield call(getConfigRequest, false, false)
    }
  }
}

export function* watchUpdateConfig() {
  yield takeLatest(updateConfig.type, _updateConfig)
}
