import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { VNDialog } from '../../VNCustomerFacingDisplay/components/VNDialog'
import { getTransactionErrorDialog } from '../Selectors'
import { setCustomerPayingWithCard, setTransactionErrorDialog } from '../ActionCreators'
import { sendRetryPayment } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { clearCart } from '../../actions/cart'
import { clearPromotionsOnOrder } from '../../actions/promotion'
import { sendCFDScreenNav } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../../VNCustomerFacingDisplay/Reducer'
import { resetCFD } from '../../VNCustomerFacingDisplay/ActionCreators'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { getCurrentOrder, getOrderUpdate } from '../../VNCustomerFacingDisplay/Selectors'
import { isTabbed } from '../../utils/orderStates'
import { clearOrderInProgress } from '../../actions/order'
import { getOrderInProgress } from '../../selectors/order'
import { getBridgePaymentMethod } from '../../selectors/peripheral'
import { setBridgePaymentMethod } from '../../actions/peripheral'
import { bridgePaymentMethods } from '../../reducers/peripheral'

export const VNTransactionErrorDialog = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dialogOptions = useSelector(state => getTransactionErrorDialog(state))
  const currentOrder = useSelector(state => getCurrentOrder(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const [loadRemoteOrderTotal, {}, resetOrderTotals] = useRemoteOrderTotal()
  const bridgePaymentMethod = useSelector(state => getBridgePaymentMethod(state))
  const orderData = useSelector(state => getOrderUpdate(state))

  return <VNDialog
    open={dialogOptions?.get('open')}
    title={"Card Reader Status"}
    subTitle={dialogOptions?.get('subTitle')}
    primaryButtonText={"RETRY"}
    secondaryButtonText={"CANCEL"}
    onPrimaryButtonClick={() => {
      sendRetryPayment(bridgePaymentMethod)
      dispatch(setTransactionErrorDialog({open: false}))

      if (orderData.get('amount')) {
        dispatch(setCustomerPayingWithCard({open: true, amount: orderData.get('amount')}))
        return
      }

      if (currentOrder) {
        dispatch(setCustomerPayingWithCard({open: true, amount: currentOrder.get('amount')}))
      }

    }}
    onSecondaryButtonClick={() => {
      // clear cart
      dispatch(clearCart())

      // clear promotions on order
      dispatch(clearPromotionsOnOrder())

      // reset order totals
      resetOrderTotals()

      // navigate CFD back to IDLE
      sendCFDScreenNav(CFD_SCREEN.IDLE)

      // navigate POS back to
      history.push('/concession-order')

      // reset the state locally
      dispatch(resetCFD(true))

      dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeSaleRequest))

      // close dialog
      dispatch(setTransactionErrorDialog({open: false}))

      if (isTabbed(orderInProgress)) {
        dispatch(clearOrderInProgress())
      }
    }}
  />
}
