import React from 'react'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux';
import { connect } from 'react-redux'

import { incrementNextOrderNumber } from '../utils/orderNumbers'

import Navbar from '../containers/Navbar'
import HeaderBar from '../components/HeaderBar'
import BackChevron from '../components/BackChevron'
import { getSelectedMenus } from '../selectors/menus';
import { currentMenuSelected } from '../actions/menus'

import styles from './OrdersMenuSelection.module.scss';

const OrderMenuSelection = ({ history, selectedMenus, currentMenuSelected }) => {
  const handleClick = (menu) => {
    currentMenuSelected(menu.uuid)
    history.push('/orders')
  }

  useEffect(() => {
    incrementNextOrderNumber()
  }, [])

  return (
    <>
      <HeaderBar />
      <Navbar />
      <div className={styles.ordersMenuSelection}>
        <BackChevron className={styles.backChevron} onClick={() => history.push('/kiosk/attract_loop')} />
        <div className={styles.container}>
          <div>
            <h1 className={styles.header}>Select Menu</h1>
          </div>
          {selectedMenus.map(menu => (
            <div key={menu.uuid} className={styles.button} onClick={() => handleClick(menu)}>
              <span>{menu.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedMenus: getSelectedMenus(state)
})

const mapDispatchToProps = {
  currentMenuSelected
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderMenuSelection)
