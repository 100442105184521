import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import OrderDetails from '../../containers/orders/Details'
import OrdersHeader from '../../containers/orders/Header'
import TopNav from '../../containers/orders/TopNav'

import styles from './Sidebar.module.scss'

const OrdersSidebar = ({ isOrderSelected, className }) => {
  // This is only applicable to mobile resolutions.
  const toggleClassName = isOrderSelected ? styles.show : styles.hide
  const classNames = cn(styles.ordersSidebar, toggleClassName, className)

  return (
    <div className={classNames}>
      <TopNav className={styles.header} />
      <OrdersHeader className={styles.header} />
      {isOrderSelected && <OrderDetails/>}
    </div>
  )
}

OrdersSidebar.defaultProps = {
  isOrderSelected: false,
}

OrdersSidebar.propTypes = {
  isOrderSelected: PropTypes.bool,
}

export default OrdersSidebar
