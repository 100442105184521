import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import IconArrow from '../icons/IconExit'

class Slider extends Component {
  state = {
    isSliderArrows: false,
    disabledArrow: 'prev',
  }

  sliderRef = createRef()
  sliderInnerRef = createRef()

  get slider() {
    return this.sliderRef.current
  }

  get sliderInner() {
    return this.sliderInnerRef.current
  }

  get isOverflow() {
    return this.sliderInner.scrollWidth > this.slider.offsetWidth
  }

  componentDidMount() {
    this.sliderResizeObs = new ResizeObserver(this.handleResize)
    this.sliderResizeObs.observe(this.sliderInner)
  }

  componentWillUnmount() {
    this.sliderResizeObs.disconnect()
  }

  handleResize = () =>
    this.setState({
      isSliderArrows: this.isOverflow,
    })

  scrollSliderByPage = direction => {
    const { width } = this.sliderInner.getBoundingClientRect()
    const scrollLeft = direction === 'prev' ? -width : width

    this.sliderInner.scrollTo({
      left: this.sliderInner.scrollLeft + scrollLeft,
      behavior: 'smooth',
    })
  }

  handleSliderScroll = () =>
    this.setState(() => {
      const { scrollWidth, clientWidth, scrollLeft } = this.sliderInner
      const maxScroll = scrollWidth - clientWidth
      let disabledArrow = ''

      if (scrollLeft === maxScroll) {
        disabledArrow = 'next'
      } else if (scrollLeft === 0) {
        disabledArrow = 'prev'
      }

      return {
        disabledArrow,
      }
    })

  render() {
    const { children, className, ...rest } = this.props
    const { isSliderArrows, disabledArrow } = this.state

    return (
      <div className={cn(['slider', className])} ref={this.sliderRef} {...rest}>
        {isSliderArrows && (
          <button
            className="slider__control -prev"
            disabled={disabledArrow === 'prev'}
            onClick={() => this.scrollSliderByPage('prev')}
          >
            <IconArrow className="slider__control-icon" />
          </button>
        )}
        <div className="slider__inner" onScroll={this.handleSliderScroll} ref={this.sliderInnerRef}>
          {children}
        </div>
        {isSliderArrows && (
          <button
            className="slider__control -next"
            disabled={disabledArrow === 'next'}
            onClick={() => this.scrollSliderByPage('next')}
          >
            <IconArrow className="slider__control-icon" />
          </button>
        )}
      </div>
    )
  }
}

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
}

export default Slider