export const FETCH_ATTENDANTS = 'FETCH_ATTENDANTS'
export const FETCH_ATTENDANTS_SUCCEEDED = 'FETCH_ATTENDANTS_SUCCEEDED'
export const FETCH_ATTENDANTS_FAILED = 'FETCH_ATTENDANTS_FAILED'

export const ATTEMPT_PIN_LOGIN = 'ATTEMPT_PIN_LOGIN'
export const ATTEMPT_PIN_LOGIN_FAILED = 'ATTEMPT_PIN_LOGIN_FAILED'
export const ATTEMPT_PIN_LOGIN_SUCCEEDED = 'ATTEMPT_PIN_LOGIN_SUCCEEDED'

export const REGISTER_LOGIN = 'REGISTER_LOGIN'

export const SET_ACTIVE_ATTENDANT_ID = 'SET_ACTIVE_ATTENDANT_ID'
export const SET_MANAGER_ID = 'SET_MANAGER_ID'

export const CLEAR_ATTEMPT_PIN_LOGIN_STATUS = 'CLEAR_ATTEMPT_PIN_LOGIN_STATUS'
export const CLEAR_FETCH_ATTENDANTS_STATUS = 'CLEAR_FETCH_ATTENDANTS_STATUS'

export const LOGOUT = 'LOGOUT'

export const fetchAttendants = () => ({
  type: FETCH_ATTENDANTS,
})

export const fetchAttendantsFailed = (payload) => ({
  type: FETCH_ATTENDANTS_FAILED,
  payload,
})

export const fetchAttendantsSucceeded = (payload) => ({
  type: FETCH_ATTENDANTS_SUCCEEDED,
  payload,
})

export const attemptPinLogin = (pin) => ({
  type: ATTEMPT_PIN_LOGIN,
  payload: { pin },
})

export const attemptPinLoginFailed = () => ({
  type: ATTEMPT_PIN_LOGIN_FAILED,
})

export const attemptPinLoginSucceeded = () => ({
  type: ATTEMPT_PIN_LOGIN_SUCCEEDED,
})

export const registerLogin = (pin) => ({
  type: REGISTER_LOGIN,
  payload: { pin },
})

export const setActiveAttendantId = (attendantId) => ({
  type: SET_ACTIVE_ATTENDANT_ID,
  payload: { attendantId },
})

export const setManagerId = (managerId) => ({
  type: SET_MANAGER_ID,
  payload: { managerId },
})

export const clearAttemptPinLoginStatus = () => ({
  type: CLEAR_ATTEMPT_PIN_LOGIN_STATUS,
})

export const clearFetchAttendantsStatus = () => ({
  type: CLEAR_FETCH_ATTENDANTS_STATUS,
})

export const logout = () => ({
  type: LOGOUT,
})
