import { useDispatch, useSelector } from 'react-redux'
import { store } from '../stores'
import { pick } from 'lodash'

import { CLEAR_SAF_DECLINES } from '../constants'

import { setSafMode } from '../actions/appState'

import { getSafMode } from '../selectors/appState'

import { onSafIsUploading, setSafUploadComplete, onDataIsUploading, setDataUploadComplete, setSafFullResponse } from '../actions/peripheral'
import { syncOrdersRequested, syncReplayAuthOrdersRequested } from '../actions/order'

import { getNumericSafValue, getSafIsUploading, getSafUploadComplete, getDataIsUploading, getDataUploadComplete } from '../selectors/peripheral'

import PeripheralBridge from '../utils/peripheralBridge'
import createDataDogLog from '../utils/datadog'
import { executePerformMaintenance } from "../utils/bridgeMethods"
import { ToastManager } from '../utils/toastManager'

import { MODES } from '../VNMode/Reducer'
import { makeGetOfflineCardIds, makeGetOfflineLocalIds } from '../selectors/order'

const terminalResultStates = [ 'OK', 'NOT FOUND' ]

export const OFFLINE_SAF_MODE = 2

const SAF_MODE_STATES = {
  2: 'Offline',
  3: 'Online',
}

const getUnsyncedOrders = (orderIds) => {
  const state = store.getState()
  const orders = state?.order?.byId ?? {}
  return pick(orders, orderIds)
}

const getSafUploadCompleteForSync = () => {
  const state = store.getState()
  return getSafUploadComplete(state)
}

const getSafIsUploadingForSync = () => {
  const state = store.getState()
  return getSafIsUploading(state)
}

const getDataUploadCompleteForSync = () => {
  const state = store.getState()
  return getDataUploadComplete(state)
}

const getDataIsUploadingForSync = () => {
  const state = store.getState()
  return getDataIsUploading(state)
}

const getOfflineCardIdsForSync = () => {
  const state = store.getState()
  return makeGetOfflineCardIds()(state)
}

const getOfflineLocalIdsForSync = () => {
  const state = store.getState()
  return makeGetOfflineLocalIds()(state)
}

const getSafModeForSync = () => {
  const state = store.getState()
  return getSafMode(state)
}

// Sleep for a few milliseconds.
const sleep = ms => new Promise(r => setTimeout(r, ms))

const processorSyncIsComplete = (response) => {
  const { resultText, status, rawResult, fullResponse } = response
  const translatedResultStatus = (rawResult === null || rawResult === undefined) ? resultText : rawResult

  // Values expected in a standard PAX SAF response.
  const totalCount = getNumericSafValue(fullResponse?.SAFTotalCount)
  const uploadedCount = getNumericSafValue(fullResponse?.SAFUploadedCount)
  const failedCount = getNumericSafValue(fullResponse?.SAFFailedCount)

  // The response is a PAX response if it has the following non-negative integral properties.
  const isPaxResponse = (totalCount >= 0) && (uploadedCount >= 0) && (failedCount >= 0)
  if (!isPaxResponse) {
    return terminalResultStates.includes(translatedResultStatus) || terminalResultStates.includes(status)
  }

  // When the total failed cards PLUS the total uploaded cards is more or equal to the total cards,
  // then what is left on the reader can only be failed.
  return totalCount <= uploadedCount + failedCount
}

const shouldFinishProcesssorSync = (response) => {
  if (!response) return false

  // When a terminal state is reached from the processor sync results, begin syncing statuses from
  // the card reader.
  if (!processorSyncIsComplete(response)) return false

  const { fullResponse } = response
  maybeUpdateSafResponse(fullResponse)

  return true
}

async function maybeUpdateSafResponse(fullResponse) {
  const { dispatch } = store

  if (!fullResponse) {
    ToastManager.error('SAF upload statuses not received. Sync statuses may be inaccurate.')
    createDataDogLog('error', 'SAF upload statuses not received. Sync statuses may be inaccurate.')
    return
  }

  dispatch(setSafFullResponse(fullResponse))
}

async function startStatusSyncCycle(dispatch, maximumIterations = 10) {
  var offlineCardIds = getOfflineCardIdsForSync()
  var safIsUploading = getSafIsUploadingForSync()
  var iteration = 0

  // Purge only failures. A status sync should only trigger when we know that the device counts are
  // "balanced". In that case, we will use the purge to help determine state. Anything that is NOT
  // FOUND or returns as a decline is clearly a decline, since we have cleared ONLY declines.
  /// Anything else is a success.
  if (CLEAR_SAF_DECLINES === true || CLEAR_SAF_DECLINES === 'true') {
    executePerformMaintenance()

  }

  // need to wait a little while for the pax device to ensure that is finished "doing" whatever pax
  // does to unclog itself.
  await sleep(10000)

  do {
    dispatch(syncReplayAuthOrdersRequested())

    safIsUploading = getSafIsUploadingForSync()
    offlineCardIds = getOfflineCardIdsForSync()

    iteration++
  } while (safIsUploading && offlineCardIds.length > 0 && iteration < maximumIterations)

  const unsyncedOrders = getUnsyncedOrders(getOfflineCardIdsForSync())
  createDataDogLog('info', 'Sync Offline Card IDs Finished', { offlineCardIds, iterationCount: iteration, unsyncedCardIdOrders: unsyncedOrders, deviceStillHasUnsyncedOrders: !!unsyncedOrders?.length, safIsUploading })
}

// A callback for the SAF upload process. This is called when the SAF upload is complete.
async function startProcessorSyncCycle(isInitial, isAutomatic, dispatch, bridgeUnavailableCallback, bridgeInstance, response, cfdMode) {
  if (response && shouldFinishProcesssorSync(response)) {
    createDataDogLog('info', 'SAF upload complete. Syncing statuses from card reader.', {offlineCardIds: getOfflineCardIdsForSync()})

    dispatch(setSafUploadComplete(true))

    bridgeInstance.registerHandler('handleSAFResult', (responseJson) => { })

    startStatusSyncCycle(dispatch, 1)
    return
  }

  if (!bridgeInstance) {
    bridgeUnavailableCallback()

    return
  }

  const offlineCardIds = getOfflineCardIdsForSync()
  const safIsUploading = getSafIsUploadingForSync()

  // TODO(mkramerl): Refactor this so it is not using the "approximation" of offline card IDs.
  if ((!isInitial && !safIsUploading) || offlineCardIds.length === 0) return
  if (!isInitial) await sleep(30000)

  const syncCycleCallbackWrapper = (response) => {
    startProcessorSyncCycle(false, isAutomatic, dispatch,
      bridgeUnavailableCallback, bridgeInstance, response, cfdMode)
  }

  if (cfdMode === MODES.POS) {
    bridgeInstance.registerHandler('handleSAFResult', (responseJson) => {
      syncCycleCallbackWrapper(responseJson)
    })
  }

  bridgeInstance?.callHandler('replayRequests', null, syncCycleCallbackWrapper)
}

async function startPlatformSyncCycle(dispatch) {
  var offlineLocalIds = getOfflineLocalIdsForSync()
  var dataIsUploading = getDataIsUploadingForSync()

  do {
    dispatch(syncOrdersRequested())

    await sleep(30000)

    dataIsUploading = getDataIsUploadingForSync()
    offlineLocalIds = getOfflineLocalIdsForSync()
  } while (dataIsUploading && offlineLocalIds.length > 0)

  const unsyncedOrders = getUnsyncedOrders(offlineLocalIds)
  createDataDogLog('info', 'Sync Offline Local IDs Finished', { offlineLocalIds, unsyncedLocalIdOrders: unsyncedOrders, deviceStillHasUnsyncedOrders: !!unsyncedOrders?.length, dataIsUploading })
}

export const startSyncCycle = (isAutomatic, bridgeUnavailableCallback, bridgeInstance, cfdMode) => {
  const { dispatch } = store

  if (getSafModeForSync() === OFFLINE_SAF_MODE) {
    return
  }

  // Don't re-sync if this is an idle sync and we are currently in a sync.
  if (isAutomatic && getSafIsUploadingForSync()) {
    return
  }

  const offlineLocalIds = getOfflineLocalIdsForSync()

  // If this is an automatically triggered sync cycle and the sync cycle has already been completed,
  // and there are no orders unsynced to the platform do not start a new sync cycle.
  if (isAutomatic && getSafUploadCompleteForSync() && getDataUploadCompleteForSync()) {
    dispatch(onSafIsUploading(false))
    return
  }

  dispatch(setSafUploadComplete(false))
  dispatch(onSafIsUploading(true))

  dispatch(setDataUploadComplete(false))
  dispatch(onDataIsUploading(true))

  createDataDogLog('info', 'Sync Cycle Started', { offlineLocalIds, offlineCardIds: getOfflineCardIdsForSync(), isAutomatic })

  startPlatformSyncCycle(dispatch)
  startProcessorSyncCycle(true, isAutomatic, dispatch, bridgeUnavailableCallback, bridgeInstance, null, cfdMode)
}

/**
 * Hook to manage SAF mode for both AFCC and PAX
 * @returns functions to manage and report SAF modes through the bridge to the APK
 */
const useSafMode = () => {
  const dispatch = useDispatch()

  // 0 - Online
  // 2 - Offline Only (Batch)
  const safMode = useSelector((state) => getSafMode(state))

  // Handle messages coming from the APK
  const registerBridgeHandler = () => {
    const bridgeInstance = PeripheralBridge.getBridge()

    if (bridgeInstance?.callHandler) {
      bridgeInstance.registerHandler('handleSafMode', (safInteger) => {
        dispatch(setSafMode(safInteger))
      })
    }
  }

  const getSafModeFromBridge = () => {
    const bridgeInstance = PeripheralBridge.getBridge()

    if (bridgeInstance?.callHandler) {
      bridgeInstance.callHandler('getSafMode', (safString) => {
        dispatch(setSafMode(safString))
      })
    }
  }

  const initiateSafBridgeCommunication = () => {
    registerBridgeHandler()
    getSafModeFromBridge()
  }

  // Send one way message to change SAF mode
  // yes - turn SAF mode to 0 or 3, logic in the APK
  // no - do not move away from SAF mode 1, APK will start coroutine to check again in 30 minutes via safOnDemandPrompt bridge message
  // offline_only - tell APK to go to offline only mode, typically only used after a manager pin approval
  const updateSafMode = (safMode) => {
    const bridgeInstance = PeripheralBridge.getBridge()

    if (bridgeInstance?.callHandler) {
      bridgeInstance.callHandler('handleSafOfflinePrompt', {
        newSafMode: safMode,
        isConstant: safMode === 2
      })
      store.dispatch(setSafMode(safMode))

      ToastManager.info(`You are now in ${SAF_MODE_STATES[safMode]} mode.`, { autoClose: true, closeOnClick: true })
    } else {
      ToastManager.error('Unable to change SAF mode!', { autoClose: true, closeOnClick: true })
    }
  }

  return [initiateSafBridgeCommunication, updateSafMode, getSafModeFromBridge, { safMode }]
}

export default useSafMode
