import React from 'react'
import cn from 'classnames'

import styles from './Button.module.scss'

const Button = ({ children, className, onClick, onKeyPress, disabled, ariaLabel = "", tabIndex, refProp,  ...props } ) => {
  const classNames = cn(styles.button, className)

  return (
    <button {...props} onKeyDown={onKeyPress} ref={refProp} className={classNames} disabled={disabled} tabindex={tabIndex} onClick={onClick} aria-label={ariaLabel}>
    {children}
    </button>
  )
}

export default Button
