import {
  FETCH_EMPLOYEE_ROLES,
  FETCH_EMPLOYEE_ROLES_SUCCEEDED,
  FETCH_EMPLOYEE_ROLES_FAILED,
} from '../actions/employeeRole'

const initialState = {
  managerEmployeeIds: [],
  rolesLoadingComplete: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_ROLES: {
      return {
        ...state,
        rolesLoadingComplete: false,
      }
    }

    case FETCH_EMPLOYEE_ROLES_SUCCEEDED:
      return {
        ...state,
        managerEmployeeIds: action.payload,
        rolesLoadingComplete: true,
      }

    case FETCH_EMPLOYEE_ROLES_FAILED:
      return {
        ...state,
        rolesLoadingComplete: true,
      }

    default:
      return {
        ...state
      }
  }
}