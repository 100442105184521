import { createSelector } from 'reselect'

export const getCurrentVenue = state => state.venue?.currentVenue

export const getVenueName = state => getCurrentVenue(state)?.displayName ?? 'Arena'

export const makeGetServiceChargeLegalText = () => createSelector(
    getCurrentVenue,
    (currentVenue) => {
      return currentVenue?.serviceChargeLegal
    }
  )