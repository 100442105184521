// PERSISTED

export const getCFDPairingState = (state) => {
  return state.VNCustomerFacingDisplayPersisted.get('CFDisPaired')
}

export const getPOSPairingState = (state) => {
  return state.VNCustomerFacingDisplayPersisted.get('POSisPaired')
}

export const getInitializedValues = (state) => {
  return state.VNCustomerFacingDisplayPersisted.get('base')
}

export const getDebugger = (state) => {
  return state.VNCustomerFacingDisplayPersisted.get('debugger')
}

// NON PERSISTED

export const getCartItems = (state) => {
  return state.VNCustomerFacingDisplay.get('cartItems')
}

export const getOrderDetails = (state) => {
  return state.VNCustomerFacingDisplay.get('orderDetails')
}

export const getCFDScreen = (state) => {
  return state.VNCustomerFacingDisplay.get('screen')
}

export const getSalesRequestExecution = (state) => {
  return state.VNCustomerFacingDisplay.get('executeSalesRequest')
}

export const getTransactionResult = (state) => {
  return state.VNCustomerFacingDisplay.get('transactionResult')
}

export const getCanExecuteSalesRequest = (state) => {
  return state.VNCustomerFacingDisplay.get('canExecuteSalesRequest')
}

export const getCardError = (state) => {
  return state.VNCustomerFacingDisplay.get('cardError')
}

export const getPaymentData = (state) => {
  return state.VNCustomerFacingDisplay.get('paymentData')
}

export const getOrderUpdate = (state) => {
  return state.VNCustomerFacingDisplay.get('orderUpdate')
}

export const getCartTotals = (state) => {
  return state.VNCustomerFacingDisplay.get('cartTotals')
}

export const getPaymentProgress = (state) => {
  return state.VNCustomerFacingDisplay.get('paymentProgress')
}

export const getTips = (state) => {
  return state.VNCustomerFacingDisplay.get('tips')
}

export const getBalanceToBePaid = (state) => {
  return state.VNCustomerFacingDisplay.get('balanceToBePaid')
}

export const getCurrentPaymentFlow = (state) => {
  return state.VNCustomerFacingDisplay.get('currentPaymentFlow')
}

export const getCurrentCart = (state) => {
  return state.VNCustomerFacingDisplay.get('currentCart')
}

export const getCurrentTransactionLifecycleMoment = (state) => {
  return state.VNCustomerFacingDisplay.get('currentTransactionLifecycle')
}

export const getCurrentOrder = (state) => {
  return state.VNCustomerFacingDisplay.get('currentOrder')
}

export const getCalculatedCurrentCartValues = (state) => {
  return state.VNCustomerFacingDisplay.get('calculatedCurrentCartValues')
}

export const getFormattedCartItems = (state) => {
  return state.VNCustomerFacingDisplay.get('formattedCart')
}