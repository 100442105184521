import React from 'react'
import cn from 'classnames'

import ChangeCalculatorForm from '../containers/ChangeCalculatorForm'

import styles from './ChangeCalculator.module.scss'

const ChangeCalculator = ({ className }) => {
  const classNames = cn(styles.changeCalculator, className)

  return (
    <div className={classNames}>
      <div className={styles.changeCalculatorContent}>
        <ChangeCalculatorForm />
      </div>
    </div>
  )
}

export default ChangeCalculator
