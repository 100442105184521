import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

export const MODES = {
  UNKNOWN: 'UNKNOWN',
  CFD: 'CFD',
  POS: 'POS'
}

const getInitialState = () => {
  return Map({
    'mode': MODES.UNKNOWN,
    'deviceId': null,
    'porticoAuthTokens': Map({}),
    'summit': false
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNMODE_SET_MODE:
      return state.set('mode', action.mode)
    case ActionTypes.VNMODE_SET_DEVICE_ID:
      return state.set('deviceId', action.id)
    case ActionTypes.VNMODE_SET_PORTICO_AUTH_TOKENS:
      return state.set('porticoAuthTokens', Map(action.data))
    case ActionTypes.VNMODE_SET_SUMMIT_MODE:
      return state.set('summit', action.mode)
    default:
      return state
  }
}

export default reducer
