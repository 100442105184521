import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { get, map, debounce, head, values, each } from 'lodash'

import styles from './CategoryBar.module.scss'
import dropDownStyles from './DropDown.module.scss'
import QuickMenuSearch from '../containers/QuickMenuSearch'
import CategoryPill from './CategoryPill'
import Button from './Button'

import { ReactComponent as CloseIcon } from '../assets/icons/Close-Icon.svg'
import { ReactComponent as InputSearchIcon } from '../assets/input-search-icon.svg'
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg'
import { ReactComponent as DropdownArrowIcon } from '../assets/icons/dropdown_arrow.svg'

const CategoryBar = ({ className, menuItems, itemCategories, setSelectedCategory, selectedCategory, setHighlightedIds, useQuickSearchFlag, updateUseQuickSearchFlag }) => {
  const classNames = cn(styles.CategoryBar, className)
  const classNamesDropDown = cn(dropDownStyles.dropDown, className)
  const [showModal, toggleModal] = useState(false)
  const [scrollIntervals, setScrollIntervals] = useState([])
  const quickSearchRef = useRef()
  const categorySliderRef = useRef()
  let showSliderArrows = categorySliderRef.current && categorySliderRef.current.offsetWidth < categorySliderRef.current.scrollWidth - 10
  const SCROLL_FORWARD = 250
  const SCROLL_BACK = -250
  const SCROLL_FORWARD_HOLD = 40
  const SCROLL_BACK_HOLD = -40

  const renderQuickSearch = () => {
    if (useQuickSearchFlag) {
      const focus = get(quickSearchRef, 'current.focus', () => {}).bind(get(quickSearchRef, 'current', () => {}))
      focus()
    } else {
      updateUseQuickSearchFlag(true)
    }
  }

  const enableSelectedCategory = (categoryUuid) => {
    if (useQuickSearchFlag) {
      updateUseQuickSearchFlag(false)
      setHighlightedIds({query: '', highlightedIds: [], focusedItemId: undefined})
    }
    if (categoryUuid === selectedCategory.id) {
      setSelectedCategory({ id: '', search: false, highlight: false })
    } else {
      setSelectedCategory({ id: categoryUuid, search: true, highlight: true })
    }
    if (showModal) {
      toggleModal(false)
    }
  }

  const scrollTap = (scrollValue) => {
    categorySliderRef?.current?.scrollBy(scrollValue, 0)
  }

  const scrollHoldInterval = (scrollValue) => {
    const startScrolling = debounce(() => {
      categorySliderRef?.current?.scrollBy(scrollValue, 0)
    }, 10)
    const scrollInterval = setInterval(startScrolling, 70)
    setScrollIntervals([...scrollIntervals, scrollInterval])
  }
  const endScrollIntervals = () => {
    if (scrollIntervals.length) {
      each(scrollIntervals, (scrollInterval) => clearInterval(scrollInterval))
      setScrollIntervals([])
    }
  }

  const quickMenuSearch = useQuickSearchFlag ? <QuickMenuSearch menuItems={menuItems} quickSearchRef={quickSearchRef} /> : null

  useEffect(() => {
    if (!selectedCategory?.id) {
      setSelectedCategory({ id: head(values(itemCategories))?.itemCategoryUuid, search: false, highlight: false })
    }
  }, [])

  useEffect(() => {
    return () => {
      each(scrollIntervals, (scrollInterval) => clearInterval(scrollInterval))
    }
  }, [scrollIntervals])

  let modalElement = null

  if (showModal) modalElement = (
    <div className={dropDownStyles.menuSelectOverlay}>
      <div className={dropDownStyles.categorySelectModal}>
        <div className={dropDownStyles.categoryModalHeader}>
          <CloseIcon onClick={() => toggleModal(!showModal)} />
        </div>
        {map(itemCategories, category =>
          category.itemCategoryUuid !== selectedCategory?.id ? (
            <div
              key={category.itemCategoryUuid}
              className={dropDownStyles.categorySelect}
              onClick={() => enableSelectedCategory(category.itemCategoryUuid)}
            >
              <div>{category.name}</div>
              <div>{category.itemCount}</div>
            </div>
          ) : <div className={dropDownStyles.categorySelect} style={{backgroundColor: "rgba(0, 0, 0, 0.25)"}}>
                <div>{itemCategories?.[selectedCategory?.id]?.name}</div>
                <div>{category.itemCount}</div>
              </div>
        )}
      </div>
    </div>
  )

  const categoryDropDown = (
    <>
      <QuickMenuSearch menuItems={menuItems} quickSearchRef={quickSearchRef} />
      <div className={classNamesDropDown}>
        {modalElement}
        <div className={dropDownStyles.titleHeader}>
          <span className={dropDownStyles.name}>{itemCategories?.[selectedCategory?.id]?.name}</span>
        </div>
        <div className={dropDownStyles.categoryArrow} onClick={() => toggleModal(!showModal)}><DropdownArrowIcon /></div>
      </div>
    </>
    
  )

  const categoryBar = (
    <div className={classNames} style={!showSliderArrows ? {marginLeft: "0.7vw", width: "83vw"} : null}>
      {!useQuickSearchFlag &&
        <>
          <div className={styles.categorySlider}>
            {showSliderArrows ? <Button className={styles.shiftLeft} onClick={() => scrollTap(SCROLL_BACK)} onTouchStart={() => scrollHoldInterval(SCROLL_BACK_HOLD)} onTouchEnd={() => endScrollIntervals()}><div className={styles.chevronL}><ArrowIcon /></div></Button> : null}
            <div className={styles.categoryPills} ref={categorySliderRef}>
              {map(itemCategories, category => (
                <CategoryPill
                  className={className}
                  category={category}
                  selectedCategory={selectedCategory}
                  setCategory={enableSelectedCategory}
                  key={category.itemCategoryUuid}
                />
              ))}
            </div>
            {showSliderArrows ? <Button className={styles.shiftRight}  onClick={() => scrollTap(SCROLL_FORWARD)} onTouchStart={() => scrollHoldInterval(SCROLL_FORWARD_HOLD)} onTouchEnd={() =>  endScrollIntervals()}> <div className={styles.chevronR}><ArrowIcon /></div></Button> : null }
          </div>
          <div className={styles.quickSearchContainer} style={!showSliderArrows ? {borderRadius: "6px"} : null}>
            <InputSearchIcon onClick={() => renderQuickSearch()} />
          </div>
        </>
      }
      {quickMenuSearch}
    </div>
  )

  return document?.body?.clientWidth > 800 ? categoryBar : categoryDropDown
}

export default CategoryBar
