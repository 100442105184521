import { get, partition, map } from 'lodash'

import { MENUS_ITEMS_SUCCEEDED, MENUS_ITEMS_REQUESTED, MENUS_ITEMS_FAILED } from '../actions/menus'
import DataMapper from '../utils/dataMapper';

const initialState = {
  byId: {},
  majorCategoryIds: [],
  minorCategoryIds: [],
  isMenuItemsLoading: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MENUS_ITEMS_REQUESTED : {
      return {
        ...state,
        isMenuItemsLoading: true
      }
    }

    case MENUS_ITEMS_SUCCEEDED: {
      const categoriesByMenuId = payload.reduce((result, response) => {
        const uuid = get(response, ['data', 'menu', 'uuid'])
        const categories = get(response, ['data', 'categories'], [])

        const byId = DataMapper.objectsById(categories)
        const allIds = categories.map(category => category.itemCategoryUuid)
        const [majorCategories, minorCategories] = partition(categories, (category) => !!category.majorCategory)
        const majorCategoryIds = map(majorCategories, (category) => category.itemCategoryUuid)
        const minorCategoryIds = map(minorCategories, (category) => category.itemCategoryUuid)

        return {
          ...result,
          [uuid]: {
            byId,
            allIds,
            majorCategoryIds,
            minorCategoryIds,
          }
        }
      }, {})

      return {
        byMenuId: categoriesByMenuId,
        isMenuItemsLoading: false
      }
    }

    case MENUS_ITEMS_FAILED: {
      return {
        ...state,
        isMenuItemsLoading: false
      }
    }

    default:
      return state
  }
}
