import { customHistory } from "../stores"
/**
   * Since the way React stacks state changes and re renders,
   * We need to add the history.push into a setTimeout or the spinner wont
   * be displayed inmediatly
   * 
   * @param {*} to path the user is being set to
   */

  export const pushAfterNextRerender = (to) =>  {
    setTimeout(() => customHistory.push(to), 1)
  }