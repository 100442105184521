import { call, put, take, select, actionChannel, takeEvery } from 'redux-saga/effects'
import { get } from 'lodash'

import {
  requestRefund,
  requestRefundSucceeded,
  requestRefundFailed,
  SYNC_REFUND_REQUESTED,
  requestTabbedRefundFailed,
  requestTabbedRefundSucceeded,
} from '../actions/refund'

import { makeGetRefund } from '../selectors/refund'
import MutationApi from '../remote/mutationApi'
import { clearSelectedOrder, replaceItemsFromOrder, orderDetailsRequested, removeOrder, updateOrderTotals, syncOrderRequested } from '../actions/order'
import { getOrder } from '../selectors/order'

import { REQUEST_TABBED_REFUND, REQUEST_TABBED_REFUND_OFFLINE } from '../actions/refund'

import { APPLICATION_STRINGS } from '../strings'
import { getCartItemsFromOrder, getTaxByItem, getTaxRate } from '../selectors/cart'
import { getMenuServiceCharge } from '../selectors/items'

import { createOrderParams } from '../utils/orderUtils'
import { calculateIndividualTaxAmount, calculateServiceCharge, calculateSubtotal, calculateTax } from '../utils/totalUtils'
import { formatCartItemModifiers, displayPrice } from '../utils/formatters'
import { ToastManager } from '../utils/toastManager'

export function* refundOrder(params = {}) {
  const orderId = get(params, 'payload.orderId', '')
  const lineItemUuids = get(params, 'payload.lineItemIds', [])
  const reason = get(params, 'payload.reason', 'unknown')

  try {
    const result = yield call(MutationApi.refundOrderItems, orderId, reason, lineItemUuids, false)

    yield put(requestRefundSucceeded(orderId, result))
  } catch (err) {
    yield put(requestRefundFailed(orderId, err))
  }
}

export function* watchRefundOrder() {
  yield takeEvery(requestRefund.type, refundOrder)
}

export function* refundTabbedOrder(params = {}) {
  const orderId = get(params, 'payload.orderId', '')
  const lineItemUuids = get(params, 'payload.lineItemIds', [])
  const reason = get(params, 'payload.reason', 'unknown')

  try {
    const result = yield call(MutationApi.refundOrderItems, orderId, reason, lineItemUuids, false)
    const orderMutation = result?.data?.orderMutations?.[0]

    if(!orderMutation?.applied) {
      yield put(requestTabbedRefundFailed(orderId, result))
      ToastManager.error(APPLICATION_STRINGS.REFUND_TABBED_ORDER_FAILED)
      return
    }

    yield put(orderDetailsRequested(orderId))
    yield put(requestTabbedRefundSucceeded(orderId, result))

    if(!orderMutation?.lineItems?.length) {
      yield put(clearSelectedOrder())
      ToastManager.success(APPLICATION_STRINGS.REFUND_TABBED_ORDER_CANCELLED)
      return
    }

    ToastManager.success(APPLICATION_STRINGS.REFUND_TABBED_ORDER_SUCCEEDED)
  } catch (err) {
    ToastManager.error(APPLICATION_STRINGS.REFUND_TABBED_ORDER_FAILED)
    yield put(requestTabbedRefundFailed(orderId, err))
  }
}

export function* refundTabbedOrderOffline(params = {}) {
  const orderId = params.payload.orderId
  const currentOrder = yield select(state => getOrder(state, orderId))
  const orderMenuItems = [...createOrderParams(currentOrder).orderMenuItems]
  const refundItemIndexes = params.payload.itemIndexes

  //Filter all items from current order removing the voided items
  const newOrderMenuItems = orderMenuItems.filter((e, index) => !refundItemIndexes.includes(index))

  //all items removed then we just delete the order
  if(!newOrderMenuItems.length) {
    yield put(clearSelectedOrder())
    yield put(removeOrder(orderId))
    ToastManager.success(APPLICATION_STRINGS.REFUND_TABBED_ORDER_CANCELLED)
    return
  }

  //there are items remaining in the order
  //we also need to filter itemModels
  const itemModels = [...currentOrder.itemModels]
  const newItemModels = itemModels.filter((e, index) => !refundItemIndexes.includes(index))

  //filter refunded modifiers
  newItemModels.forEach((item, index) => {
    //filter in itemModel
    item.modifiers = item.modifiers.filter(modifier => !modifier.refund)

    item.price = displayPrice(item, item.modifiers)
    item.modifiersString = formatCartItemModifiers(item.modifiers, item.notes)

    //filter in createOrderParams
    newOrderMenuItems[index].modifiers[0] = newOrderMenuItems[index].modifiers[0].filter(paramsModifier =>
      !!item.modifiers.find(modelModifier => modelModifier.lineItemUuid === paramsModifier.uuid))
  })

  //Update items in order
  yield put(replaceItemsFromOrder({ orderId, newOrderMenuItems, newItemModels }))

  //once the items orders are updated we need to calculate totals for the order

  //select all items from the updated order formatted to calculate offline totals
  const updatedCartItems = yield select(state => getCartItemsFromOrder(state, orderId))

  const taxRate = yield select(getTaxRate)
  const taxByItem = yield select(getTaxByItem)
  const serviceCharge = yield select((state) => getMenuServiceCharge(state))

  const subtotalAmountInCents = calculateSubtotal(updatedCartItems)
  const serviceFee = { name: serviceCharge.name, total: calculateServiceCharge(serviceCharge, subtotalAmountInCents) ?? 0, legal: serviceCharge.legal }
  const taxAmountInCents = calculateTax(updatedCartItems, taxRate, taxByItem) + calculateIndividualTaxAmount(taxRate, serviceFee.total, serviceCharge.taxable)
  const amountInCents = subtotalAmountInCents + taxAmountInCents + serviceFee.total
  const balanceDueInCents = amountInCents

  yield put(updateOrderTotals({ orderId, subtotalAmountInCents, taxAmountInCents, amountInCents, balanceDueInCents }))
  yield put(syncOrderRequested(orderId))
  ToastManager.success(APPLICATION_STRINGS.REFUND_TABBED_ORDER_SUCCEEDED)
}

export function* watchRefundTabbedOrder() {
  yield takeEvery(REQUEST_TABBED_REFUND, refundTabbedOrder)
}

export function* watchRefundTabbedOrderOffline() {
  yield takeEvery(REQUEST_TABBED_REFUND_OFFLINE, refundTabbedOrderOffline)
}

function* syncRefundOrder(action) {
  const orderId = get(action, 'payload.orderId', '')
  const getRefund = makeGetRefund()
  const refund = yield select(getRefund, orderId)

  yield put(requestRefund(refund))
}

export function* watchSyncRefunds() {
  const queue = yield actionChannel(SYNC_REFUND_REQUESTED)

  while (true) {
    const payload = yield take(queue)

    yield call(syncRefundOrder, payload)
  }
}
