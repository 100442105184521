/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import Modal from 'react-modal'
import { withRouter } from 'react-router'
import { isEmpty } from 'lodash'

import { getCartItems } from '../../selectors/cart'
import { getKioskPayment, getOrderInProgress } from '../../selectors/order'
import { KIOSK_PAYMENT_TYPES} from '../../utils/paymentTypes'

import { toggleAppState } from '../../actions/appState'
import { startOfflineCardOrder, startOnlineCardOrder, storeOrderUuidAndOrderNumber, closeNoOpTender, addPaymentToOrderInProgress, restoreQRPaymentandUnsyncableStatus } from '../../actions/order'
import { resetSafFullResponse } from '../../actions/peripheral'

// import analytics from '../utils/analytics'
import { formatPrice } from '../utils/formatters'
import { centsToDollars, getDeviceSerial } from '../../utils/formatters'
import PeripheralBridge from '../../utils/peripheralBridge'
import { nextPosOrderNumber, invoiceNumber } from '../../utils/orderNumbers'
import { SIGNATURE_THRESHOLD_AMOUNT } from '../../constants'
import OfflineModal from '../OfflineModal'

import { fakeCreditCardResponse, showFakeCreditCard } from '../../utils/fakeCreditCardResponse'
import { KEY_CODES } from "../utils/contants"

import Button from '../components/Button'
import BackButton from '../components/BackButton'
import ConfirmModalV2 from '../../components/ConfirmModalV2'
import { ReactComponent as IconCardReader } from '../assets/icons/card-reader.svg'
import { ReactComponent as IconCard } from '../assets/icons/card.svg'
import { ReactComponent as IconArrow } from '../assets/icons/arrow.svg'

import UserIdleMonitor from '../utils/UserIdleMonitor'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { isOfflineApproval } from '../../utils/offlineReplayStatuses'
import { cardReaderApproved } from '../../utils/orderUtils'
import { getTipsEnabled, makeGetCurrentMenu } from '../../selectors/menus'

const cancelModalTexts = {
  'Ticket': 'Canceling this transaction\nwill return all funds to your\ntickets.',
  'Gift card': 'Cancel Transaction? The gift\ncard will not be charged.',
  'QR code': 'Cancel Transaction? The\nmobile account will not be\ncharged.' ,
}

export const Payment = ({
  clearSafResults,
  getNextOrderNumber,
  history,
  kioskLineItems,
  onSuccess,
  onOfflineSuccess,
  placeFreeOrder,
  kioskPayment,
  tipAmountInCents,
  totalInCents,
  tipSuggestions,
  tipsEnabled,
  tenderAmountInCents,
  completeTicketOrder,
  uuid,
  paymentWasSuccessful,
  modalSubtext,
  scanScreenType,
  orderInProgress
}) => {
  const [orderNumber] = useState(getNextOrderNumber)
  const [orderUuid, setOrderUuid] = useState(uuid)
  const [showPaymentCancelledByUserWarning, setShowPaymentCancelledByUserWarning] = useState(false)
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, loading, success, failed }] = useRemoteOrderTotal()
  const backButtonRef = useRef()

  const totalOrderPrice = tenderAmountInCents ?? (remoteOrderTotal?.totalAmountInCents ?? 0) + tipAmountInCents
  const threshHoldExceeded =  SIGNATURE_THRESHOLD_AMOUNT === 0 || (SIGNATURE_THRESHOLD_AMOUNT && totalOrderPrice >= SIGNATURE_THRESHOLD_AMOUNT)
  const signatureRequired = !isEmpty(tipSuggestions) || threshHoldExceeded

  const noPaymentRequired = totalOrderPrice === 0
  const formattedTotal = ((totalOrderPrice || 0) / 100).toFixed(2)
  const doneLoading = tenderAmountInCents || ((success && !!orderUuid) || failed)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!tenderAmountInCents) {
      loadRemoteOrderTotal()
    }
    clearSafResults()
  }, [])

  useEffect(() => {
    if (success) {
      setOrderUuid(remoteOrderTotal?.uuid)
    }
  }, [success])

  const getCardReaderReady = () => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (bridgeInstance) bridgeInstance.call('executeSaleRequest', {
      'uuid': orderUuid, orderNumber,
      'hRef': invoiceNumber(orderNumber),
      'amount': formattedTotal,
      'deviceSerial': getDeviceSerial(),
      'tipEnabled': tipsEnabled,
    })
  }

  const idleMonitor = new UserIdleMonitor(process.env.REACT_APP_DEV_IDLE_TIMEOUT || 60000)

  useEffect(() => {
    // analytics.paymentEvent(cart)
    document.title = 'Order payment'

    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance || noPaymentRequired) return

    if ((success && orderUuid) || tenderAmountInCents) {
      bridgeInstance.registerHandler('handleResult', (data) => {
        if (cardReaderApproved(data)) {
          const dataWithUuid = { ...data, uuid: orderUuid, orderNumber }

          // TODO (JEFF): Discuss whether to remove onOfflineSuccess from kiosk mode and what possible ramifications are.
          if (tenderAmountInCents) {
            completeTicketOrder(dataWithUuid, orderNumber, tipAmountInCents, tenderAmountInCents, orderUuid)
            return
          }
          isOfflineApproval({cardReaderData: data}) ? onOfflineSuccess(dataWithUuid, kioskLineItems, orderNumber) : onSuccess(dataWithUuid, kioskLineItems, orderNumber)
          redirectToNextStep()
        } else {
          setShowPaymentCancelledByUserWarning(true)
        }
      })

      getCardReaderReady()
    }

    return () => {
      bridgeInstance.call('cancelTransactionAttempt')
    }
  }, [formattedTotal, success, orderUuid])

  useEffect(() => {
    backButtonRef.current && backButtonRef.current.focus()

    idleMonitor.addEventListeners()
    return () => {
      idleMonitor.removeEventListeners()
    }

  }, [])

  useEffect(() => {
    if (noPaymentRequired && success && orderUuid) {
      placeFreeOrder(orderUuid)
    }
  }, [success, orderUuid])

  const redirectToNextStep = () => {
      history.push(`/kiosk/order/summary/${orderUuid}`)
  }

  const onFakeCreditCardSwipe = () => {
    if (tenderAmountInCents) {
      completeTicketOrder(fakeCreditCardResponse(orderUuid, formattedTotal), orderNumber, tipAmountInCents, tenderAmountInCents, orderUuid)
      return
    } else {
      onSuccess({ ...fakeCreditCardResponse(orderUuid, formattedTotal), orderNumber }, kioskLineItems, orderNumber)
      redirectToNextStep()
    }
  }

  const retryPaymentFlow = () => {
    setShowPaymentCancelledByUserWarning(false)
    getCardReaderReady()
  }

  const goBack = () => {
    if (scanScreenType) return
    const bridgeInstance = PeripheralBridge.getBridge()
    if (bridgeInstance) bridgeInstance.call('cancelTransactionAttempt')
    if (kioskPayment === KIOSK_PAYMENT_TYPES.creditCard && (((SIGNATURE_THRESHOLD_AMOUNT === 0 || SIGNATURE_THRESHOLD_AMOUNT) && signatureRequired) || !isEmpty(tipSuggestions)) ) {
      history.push('/kiosk/signature')
    } else {
      history.push('/kiosk/order/new')
    }
  }

  const handleKeyDown = e => {
    if (scanScreenType) return
    if (e.keyCode === KEY_CODES.UP) {
      goBack()
    }
  }

  const PaymentBackBtn = () => {
    return (
      <BackButton
        handleKeyDown={handleKeyDown}
        refProp={backButtonRef}
        className="payment__back"
        withIcon={!scanScreenType}
        onClick={goBack}
      >
        {loading ? '-' : formatPrice(totalOrderPrice)}
      </BackButton>
    )
  }

  let modal
  if (showCancelModal) {
    modal = (
      <ConfirmModalV2
        onButtonOneClick={() => setShowCancelModal(false)}
        onButtonTwoClick={() => {
        /**
         * if order has previousUnsyncableStatus then that it's a failed attempt to pay with QR code
         * we set the order again to defective so it's in sync with Stadium
         */
          if(orderInProgress?.previousUnsyncableStatus) {
            dispatch(restoreQRPaymentandUnsyncableStatus(orderUuid))
          }
          history.push('/kiosk/order/new')}
        }
        headerText={''}
        subtext={modalSubtext}
        buttonOneText={'NO, NEVER MIND '}
        buttonTwoText={'YES, CANCEL'}
        isKiosk={true}
      />
    )
  }

  let content
  const subHeaderText = paymentWasSuccessful
    ? `${scanScreenType} scanned! Pay the remaining\nbalance of ${centsToDollars(
        tenderAmountInCents
      )} with card \n\n`
    : "All major cards accepted\nFollow instructions on card reader"

if (doneLoading) {
    content = (
      <>
        <PaymentBackBtn />
        <div className="payment__header">Pay with card</div>
        <div className="payment__sub-header">{subHeaderText}</div>
        <div className="payment__methods -top" />
        <div className="payment__card-wrapper">
          <IconCardReader className="payment__card-reader-icon" />
          <IconArrow className="payment__arrow-icon" />
          <IconCard className="payment__card-icon" />
        </div>
        <div className="payment__methods">
          {scanScreenType ? (
            <Button
              className="btn -cancel"
              onClick={() => {
                setShowCancelModal(true)
              }}
              title="Cancel Transaction"
            />
          ) : (
            <div>Card Reader is Ready</div>
          )}
        </div>
        {modal}
        {showFakeCreditCard() && (
          <div className="fake-credit-card">
            <Button
              className="btn -primary"
              onClick={onFakeCreditCardSwipe}
              title="Pretend Credit Card was swiped"
            />
          </div>
        )}
      </>
    )
  } else if (failed) {
    content = (
      <>
        <PaymentBackBtn />
        <div className="payment__header">Totaling Failed, please back out and try again</div>
      </>
    )
  } else if (noPaymentRequired && doneLoading) {
    content = (
      <>
        <div className="payment__content">
          <PaymentBackBtn />
          <div className="payment__header">No Charge!</div>
          <div className="payment__sub-header">Submitting your order...</div>
        </div>
      </>
      )
  }

  if (loading) {
    content = (
      <>
      <div className="payment__content-loading">
        <PaymentBackBtn />
        <div className="payment__header">Loading...</div>
      </div>
    </>
    )
  }

  return (
    <div className="payment">
      {content}
      <OfflineModal />
      <Modal
        className="modal"
        isOpen={showPaymentCancelledByUserWarning}
        overlayClassName="overlay"
      >
        <div className="modal__copy">Would you like to try another card?</div>
        <div className="modal__actions">
          <Button
            className="btn -outline"
            onClick={() => history.push('/kiosk/order/new')}
            title="No"
          />
          <Button className="btn -primary" onClick={retryPaymentFlow} title="Yes" />
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const kioskPayment = getKioskPayment(state)
  const tipAmountInCents = ownProps?.history.location?.state?.tipAmountInCents ?? 0
  const tenderAmountInCents = ownProps?.history.location?.state?.tenderAmountInCents
  const paymentWasSuccessful = ownProps?.history.location?.state?.paymentWasSuccessful
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const tipSuggestions = menu?.tipSuggestions
  const orderInProgress = getOrderInProgress(state)
  const uuid = orderInProgress?.uuid
  const scanScreenType = ownProps?.history.location?.state?.scanScreenType
  const modalSubtext = cancelModalTexts[scanScreenType]
  const tipsEnabled = getTipsEnabled(state)

  return {
    getNextOrderNumber: nextPosOrderNumber,
    kioskLineItems: getCartItems(state),
    kioskPayment,
    tipAmountInCents,
    tipSuggestions,
    tipsEnabled,
    tenderAmountInCents,
    uuid,
    paymentWasSuccessful,
    modalSubtext,
    scanScreenType,
    orderInProgress
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const receiptSignature = props?.history?.location?.state?.receiptSignature ?? 0;
  const tipAmountInCents = props?.history.location?.state?.tipAmountInCents ?? 0
  const push = props?.history?.push ?? (() => {})

  return ({
    onOfflineSuccess: (cardReaderData, lineItems, orderNumber) => {
      dispatch(startOfflineCardOrder({ cardReaderData, signature: receiptSignature, lineItems, tipAmountInCents, isKiosk: true, orderNumber }))
    },
    onSuccess: (cardReaderData, lineItems, orderNumber) => {
      dispatch(startOnlineCardOrder({ cardReaderData, signature: receiptSignature, lineItems, tipAmountInCents, isKiosk: true, orderNumber }))
    },
    toggleAppState: (data) => {
      dispatch(toggleAppState({ data }))
    },
    storeOrderUuidAndOrderNumber: (orderUuid, orderNumber) => {
      dispatch(storeOrderUuidAndOrderNumber({ orderUuid, orderNumber }))
    },
    placeFreeOrder: (orderUuid) => {
      dispatch(closeNoOpTender({ orderUuid }))
      props.history.push(`/kiosk/order/summary/${orderUuid}`)
    },
    completeTicketOrder: (cardReaderData, orderNumber, tipAmountInCents, tenderAmountInCents, orderUuid) => {
      dispatch(addPaymentToOrderInProgress({ cardReaderData, orderNumber, tipAmountInCents, tenderAmountInCents, paymentType: 'freedompay_credit', push }))
    },
    clearSafResults: () => {
      // Clear the SAF results so that the next time the user goes to the card reader screen, the
      // results are not stale.
      dispatch(resetSafFullResponse())
    }
  })
}

Payment.defaultProps = {
  cart: [],
  kioskLineItems: [],
  totalOrderPrice: 0,
}

Payment.propTypes = {
  deviceId: PropTypes.string,
  getNextOrderNumber: PropTypes.func,
  kioskLineItems: PropTypes.array,
  onOfflineSuccess: PropTypes.func,
  onSuccess: PropTypes.func,
  toggleAppState: PropTypes.func,
  totalOrderPrice: PropTypes.number,
  kioskPayment: PropTypes.string
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(Payment)
