import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Dialog from './dialog/Dialog'
import styles from './ConfirmModalV2.module.scss'
import { Button } from '@material-ui/core'
import { KEY_CODES } from '../kiosk/utils/constants'
import { ReactComponent as CloseIcon } from '../assets/icons/Close-Icon.svg'

//TODO (JEFF) document the params and use cases for this component

const ConfirmModalV2 = ({
  classNames,
  headerText,
  icon,
  subtext,
  buttonOneText,
  buttonTwoText,
  onButtonOneClick,
  onButtonTwoClick,
  singleButtonOnly,
  emptyButtonContainer,
  isKiosk,
  useInput,
  initialInputValue,
  displayCloseButton,
  onCloseButtonClick,
}) => {
  const dialogClassNames = {
    dialog: cn(styles.confirmModalV2, classNames?.confirmModalV2),
    dialogContainer: classNames?.dialogContainer
  }
  const singleButtonStyle = singleButtonOnly ? { width: '100%' } : {}
  const [inputValue, setInputValue] = useState(initialInputValue || '')
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const handleKeyPress = e => {
    if (buttonsDisabled) return
    if (inputValue && e.keyCode === KEY_CODES.ENTER) {
      setButtonsDisabled(true)
      onButtonTwoClick(inputValue)
    }
  }

  useEffect(() => {
    if (useInput) {
      // TODO: if ever displaying more than one input we will need to update this to be more dynamic
      setTimeout(() => {
        document?.getElementById('confirmModalV2Input')?.focus()
      }, 300)
    }
  }, [])

  return (
    <Dialog classNames={dialogClassNames} isKiosk={isKiosk}>
      <div
        className={cn(
          isKiosk ? styles.kioskContainer : styles.container,
          classNames?.container
        )}
      >
        {displayCloseButton && (
          <div
            className={styles.closeIcon}
            onClick={() => onCloseButtonClick(inputValue)}
          >
            <CloseIcon />
          </div>
        )}
        {isKiosk ? (
          <div className={styles.textBox}>
            {icon && <div className={styles.icon}>{icon}</div>}
            <h1 className={styles.headerText}>{headerText}</h1>
            {subtext && <span className={styles.subtext}>{subtext}</span>}
          </div>
        ) : (
          <div className={cn(styles.textBox, classNames?.textBox)}>
            {icon && <div className={styles.icon}>{icon}</div>}
            {headerText && (
              <div className={styles.headerText}>{headerText}</div>
            )}
            {subtext && (
              <div className={subtext?.length < 100 ? styles.subtext : styles.smallSubtext}>{subtext}</div>
            )}
            {useInput && (
              <div className={subtext && styles.addMargin}>
                <input
                  type="text"
                  id='confirmModalV2Input'
                  value={inputValue}
                  onChange={(e) => setInputValue(e?.target?.value)}
                  onKeyDown={handleKeyPress}
                  autoFocus
                />
              </div>
            )}
          </div>
        )}
        {!emptyButtonContainer && (
          <div className={styles.buttonContainer}>
            {!singleButtonOnly && (
              <Button
                className={isKiosk ? styles.button2 : styles.button}
                onClick={() => {
                  setButtonsDisabled(true)
                  onButtonOneClick(inputValue)
                }}
                disabled={buttonsDisabled}
              >
                {buttonOneText}
              </Button>
            )}
            <Button
              className={isKiosk ? styles.button2 : styles.button}
              onClick={() => {
                setButtonsDisabled(true)
                onButtonTwoClick(inputValue)
              }}
              style={singleButtonStyle}
              disabled={(useInput && !inputValue) || buttonsDisabled}
            >
              {buttonTwoText}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  )
}

ConfirmModalV2.defaultProps = {
  buttonOneText: 'CANCEL',
  buttonTwoText: 'CONFIRM',
  headerText: "Confirm before proceeding",
  isKiosk: false,
  onButtonOneClick: () => {},
  onButtonTwoClick: () => {},
  singleButtonOnly: false,
  emptyButtonContainer: false,
  subtext: '',
  onCloseButtonClick: () => {},
  displayCloseButton: false,
}

ConfirmModalV2.propTypes = {
  buttonOneText: PropTypes.string,
  buttonTwoText: PropTypes.string,
  isKiosk: PropTypes.bool,
  onButtonOneClick: PropTypes.func,
  onButtonTwoClick: PropTypes.func,
  singleButtonOnly: PropTypes.bool,
  emptyButtonContainer: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
}

export default ConfirmModalV2
