import { get, values, orderBy, sortBy, isString } from 'lodash'
import { createSelector } from 'reselect'
import { USE_RICH_CHECKOUT } from '../constants'

export const getRevenueCenters = (state) => {
  const revenueCenters = values(get(state, 'revenueCenter.byId', []))
  const sortedByName = orderBy(revenueCenters, 'name', 'asc')
  const sortedByUuid = sortBy(sortedByName, (revenueCenter) => isString(revenueCenter.uuid) && revenueCenter.uuid === 'unassigned')

  return sortedByUuid
}

export const getRevenueCenterIds = (state) => get(state, 'revenueCenter.allIds', [])

const getRevenueCenter = (state, revenueCenterId) => {
  const revenueCenter = state?.stands?.byId?.[revenueCenterId] ?? {}

  return revenueCenter
}

export const makeGetRevenueCenter = () => createSelector(
  [getRevenueCenter],
  (revenueCenter) => revenueCenter
)

export const getActiveRevenueCenter = (state) => {
  const activeRevenueCenter = state?.revenueCenter?.activeRevenueCenter
  return makeGetRevenueCenter()(state, activeRevenueCenter)
}

export const makeGetActiveRevenueCenter = () => createSelector(
  [getActiveRevenueCenter],
  (revenueCenter) => revenueCenter
)

const getNoOpTenders = (state) => {
  const activeRevenueCenter = makeGetActiveRevenueCenter()(state)
  const noOpTenders = sortBy(activeRevenueCenter?.customPaymentTypes, ['name'])

  return noOpTenders
}

export const makeGetNoOpTenders = () => createSelector(
  [getNoOpTenders],
  (noOpTenders) => noOpTenders
)

export const getCashEnabled = (state) => {
  const activeRevenueCenter = makeGetActiveRevenueCenter()(state)
  return activeRevenueCenter?.allowCashPaymentsOnPos ?? false
}

export const getTicketEnabled = (state) => {
  const activeRevenueCenter = makeGetActiveRevenueCenter()(state)
  return activeRevenueCenter?.allowTicketPaymentsOnPos ?? false
}

export const getGiftCardEnabled = (state) => {
  const activeRevenueCenter = makeGetActiveRevenueCenter()(state)
  return activeRevenueCenter?.allowGiftCardPaymentsOnPos ?? false
}

export const getDefaultTipEnabled = (state) => {
  const activeRevenueCenter = makeGetActiveRevenueCenter()(state)
  return activeRevenueCenter?.confirmDefaultTip ?? false
}
export const getMobilePaySetting = state => {
  const activeRevenueCenter = makeGetActiveRevenueCenter()(state)
  const qrTenderSetting = activeRevenueCenter?.qrTender
  if (qrTenderSetting && qrTenderSetting !== 'nil') {
    return qrTenderSetting
  }
  return USE_RICH_CHECKOUT === 'true' ? 'pos_shows_qr' : ''
}

export const getActiveRevenueCenterId = (state) => state?.revenueCenter?.activeRevenueCenter ?? 'unassigned'
