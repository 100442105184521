import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { map, filter, get, head, reduce, isEmpty, groupBy, values } from 'lodash'

import CartItem from './CartItem'
import { formatPrice } from '../utils/formatters'
import {
  removeFromCart,
  safelyIncrementCartItemQuantity,
  safelyDecrementCartItemQuantity,
} from '../../actions/cart';
import { setKioskPayment, clearOrderInProgress } from '../../actions/order'
import Button from '../components/Button'
import {
  getTotal,
  getSubtotal,
  getTax,
  alcoholItemCountSelector,
  getCartItems,
} from '../../selectors/cart'
import { toggleAppState } from '../../actions/appState'
import { getCurrentMenu } from '../../selectors/menus'
import CartItemsViewModel from '../../viewmodels/CartItemsViewModel'
import { KEY_CODES } from "../utils/constants";
import OfflineModal from '../OfflineModal'
import { removedFromCartMessage, decrementedQuantityMessage, incrementedQuantityMessage } from "../utils/messages";
import { SIGNATURE_THRESHOLD_AMOUNT } from '../../constants'
import { KIOSK_PAYMENT_TYPES} from '../../utils/paymentTypes'
import PeripheralBridge from '../../utils/peripheralBridge'
import { getGiftCardEnabled, getMobilePaySetting, getTicketEnabled } from '../../selectors/revenueCenter'
import { clearRemoteOrderTotal } from '../../actions/orderTotalRemote'
import ConfirmModalV2 from '../../components/ConfirmModalV2'
import { getSafMode } from '../../selectors/appState'

export class Cart extends Component {
  static propTypes = {
    cart: PropTypes.array.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    safelyIncrementCartItemQuantity: PropTypes.func.isRequired,
    safelyDecrementCartItemQuantity: PropTypes.func.isRequired,
    alcoholLimit: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    totalPrice: PropTypes.number.isRequired,
    alcoholItemCount: PropTypes.number.isRequired,
    isAlcoholWarningConfirmed: PropTypes.bool.isRequired,
    isSidebarOpen: PropTypes.bool.isRequired,
    toggleAppState: PropTypes.func.isRequired,
    setItemsSummary: PropTypes.func,
    cartItemsSummary: PropTypes.array,
    refProp: PropTypes.func,
    mainSectionKeyPress: PropTypes.func,
    handleBackToSection: PropTypes.func,
    mobilePaySetting: PropTypes.string,
    setKioskPayment: PropTypes.func
  }

  state = {
    showEmptyCartWarning: false,
    selectedIndex: -1,
    selectedButtonIndex: -1,
    cartItemsRefs: [],
    voiceInstructions: '',
    offlineModeModalOpen: false,
    noScannerModalOpen: false,
    isScannerDetached: false,
  }

  constructor(props) {
    super();
    this.cartItemsRefs = [];
  }

  payNowRef = React.createRef()
  readSectionRef = React.createRef()
  bridgeInstance = PeripheralBridge.getBridge()
  cartItemsEndRef = React.createRef();

  cartSummaryRef = React.createRef()

  componentDidMount() {
    this.props.clearOrderData()
    if(!this.bridgeInstance) {
      return
    }
    this.bridgeInstance.registerHandler('scanError', this.handleScanError)
  }
  
  componentDidUpdate(prevProps) {
    const {cart} = this.props;
    if (prevProps.cart.length !== cart.length && cart.length < this.cartItemsRefs.length) {
      this.cartItemsRefs.pop()
      this.setState({...this.state, selectedButtonIndex: -1, selectedIndex: -1})
    }
    this.scrollToBottom()
  }

  handleScanError = (err) => {
    if (err && err.toLowerCase().includes('no scanner detected')) {
      this.setState({...this.state, isScannerDetached: true});
    }
  }
  
  scrollToBottom() {
    this.cartItemsEndRef.current?.scrollIntoView( { behavior: 'smooth'} )
  }
  
  addCartItemRef(node, cartButtonIndex, cartItemIndex) {
    if (this.cartItemsRefs[cartItemIndex] === undefined) {
      this.cartItemsRefs[cartItemIndex] = []
    }
    this.cartItemsRefs[cartItemIndex][cartButtonIndex] = node
  }


  //TODO (JEFF): consolidate the logic of all of these payment options
  _payMobileWallet = () => {
    const { cart, history, setItemsSummary, setKioskPayment, tipSuggestions, mobilePaySetting } = this.props
    const { mobileWallet } = KIOSK_PAYMENT_TYPES
    const itemsWithMinAmount = filter(cart, item => item.orderMinCount !== null)
    const sameItems = map(groupBy(itemsWithMinAmount, 'id'), group => values(group))

    const cartItemsSummary = sameItems.map(items => {
      const sum = reduce(items, (acc, item) => {
        const quantity = get(item, 'quantity')
        return acc + quantity
      }, 0)
      const name = get(head(items), 'name')
      const minAmount = get(head(items), 'orderMinCount')
      const diff = minAmount - sum

      return {
        minAmount,
        name,
        diff
      }
    })

    const cartItemsSummaryWithNotEnoughAmount = cartItemsSummary.filter(item => item.diff > 0)
    if (cart.length > 0 && cartItemsSummaryWithNotEnoughAmount.length === 0) {
      setKioskPayment(mobileWallet)
      if (mobilePaySetting === 'pos_shows_qr') {
        history.push('/kiosk/order/scanned')
        return
      }

      if (!isEmpty(tipSuggestions)) {
        history.push({
          pathname: '/signature',
          state: {
            addTipThenRedirectPath: '/kiosk/order/qr-pay'
          }
        })
      } else {
        history.push('/kiosk/order/qr-pay')
      }
    } else {
      if (!this.state.showEmptyCartWarning) {
        this.setState({ showEmptyCartWarning: true })
        // make sure the same time value is on .cart__empty CSS animation
        setTimeout(() => this.setState({ showEmptyCartWarning: false }), 8000)
      }
      setItemsSummary(cartItemsSummaryWithNotEnoughAmount)
    }
  }

  _payTicketTender = () => {
    const { cart, history, setItemsSummary, setKioskPayment, tipSuggestions } = this.props
    const { ticketTender } = KIOSK_PAYMENT_TYPES
    const itemsWithMinAmount = filter(cart, item => item.orderMinCount !== null)
    const sameItems = map(groupBy(itemsWithMinAmount, 'id'), group => values(group))

    const cartItemsSummary = sameItems.map(items => {
      const sum = reduce(items, (acc, item) => {
        const quantity = get(item, 'quantity')
        return acc + quantity
      }, 0)
      const name = get(head(items), 'name')
      const minAmount = get(head(items), 'orderMinCount')
      const diff = minAmount - sum

      return {
        minAmount,
        name,
        diff
      }
    })

    const cartItemsSummaryWithNotEnoughAmount = cartItemsSummary.filter(item => item.diff > 0)
    if (cart.length > 0 && cartItemsSummaryWithNotEnoughAmount.length === 0) {
      setKioskPayment(ticketTender)
      if (!isEmpty(tipSuggestions)) {
        history.push({
          pathname: '/signature',
          state: {
            addTipThenRedirectPath: '/kiosk/order/ticket'
          }
        })
      } else {
        history.push('/kiosk/order/ticket')
      }
    } else {
      if (!this.state.showEmptyCartWarning) {
        this.setState({ showEmptyCartWarning: true })
        // make sure the same time value is on .cart__empty CSS animation
        setTimeout(() => this.setState({ showEmptyCartWarning: false }), 8000)
      }
      setItemsSummary(cartItemsSummaryWithNotEnoughAmount)
    }
  }

  _payGiftCard = () => {
    const { cart, history, setItemsSummary, setKioskPayment, tipSuggestions } = this.props
    const itemsWithMinAmount = filter(cart, item => item.orderMinCount !== null)
    const sameItems = map(groupBy(itemsWithMinAmount, 'id'), group => values(group))

    const cartItemsSummary = sameItems.map(items => {
      const sum = reduce(items, (acc, item) => {
        const quantity = get(item, 'quantity')
        return acc + quantity
      }, 0)
      const name = get(head(items), 'name')
      const minAmount = get(head(items), 'orderMinCount')
      const diff = minAmount - sum

      return {
        minAmount,
        name,
        diff
      }
    })

    const cartItemsSummaryWithNotEnoughAmount = cartItemsSummary.filter(item => item.diff > 0)
    if (cart.length > 0 && cartItemsSummaryWithNotEnoughAmount.length === 0) {
      setKioskPayment('gift card')
      if (!isEmpty(tipSuggestions)) {
        history.push({
          pathname: '/signature',
          state: {
            addTipThenRedirectPath: '/kiosk/order/gift-card'
          }
        })
      } else {
        history.push('/kiosk/order/gift-card')
      }
    } else {
      if (!this.state.showEmptyCartWarning) {
        this.setState({ showEmptyCartWarning: true })
        // make sure the same time value is on .cart__empty CSS animation
        setTimeout(() => this.setState({ showEmptyCartWarning: false }), 8000)
      }
      setItemsSummary(cartItemsSummaryWithNotEnoughAmount)
    }
  }

 
  _payNow = () => {
    const { creditCard } = KIOSK_PAYMENT_TYPES
    const { history, setKioskPayment, cart, totalPrice, tipSuggestions} = this.props
    if (cart.length > 0) {
      setKioskPayment(creditCard)
      // if threshhold is not met and no tipSuggestions, then go straight to /payment
      if (((SIGNATURE_THRESHOLD_AMOUNT !== 0 && !SIGNATURE_THRESHOLD_AMOUNT) || (SIGNATURE_THRESHOLD_AMOUNT && totalPrice < SIGNATURE_THRESHOLD_AMOUNT)) && isEmpty(tipSuggestions)) {
        history.push('/kiosk/order/payment')
      } else {
        history.push(`/kiosk/signature`)
      }
    } else {
      if (!this.state.showEmptyCartWarning) {
        this.setState({ showEmptyCartWarning: true })
        // make sure the same time value is on .cart__empty CSS animation
        setTimeout(() => this.setState({ showEmptyCartWarning: false }), 8000)
      }
    }
  }

  handleKeyPress = (event) => {
    event.stopPropagation();
    const {mainSectionKeyPress} = this.props;
    switch (event.keyCode) {
      case KEY_CODES.LEFT:
        if (this.cartItemsRefs.length) {
          this.handlePreviousItem();
        }
        break;
      case KEY_CODES.RIGHT:
        if (this.cartItemsRefs.length) {
          this.handleNextItem();
        }
        break;
      case KEY_CODES.UP:
      case KEY_CODES.DOWN:
        mainSectionKeyPress(event);
        break;
    }
  }

  focusNextItem = () => {
    const { selectedIndex } = this.state;
    if (selectedIndex === this.cartItemsRefs.length -1) {
      this.setState({...this.state, selectedIndex: -1, selectedButtonIndex: -1})
      this.payNowRef.current && this.payNowRef.current.focus();
    } else {
      this.setState({...this.state, selectedIndex: selectedIndex+1, selectedButtonIndex: 0})
      this.cartItemsRefs[selectedIndex+1][0].focus();
    }
  }

  handlePreviousItem = () => {
    const {selectedIndex, selectedButtonIndex, focusedIndex} = this.state;

    if (focusedIndex){
      this.setState({ selectedIndex, focusedIndex: null, selectedButtonIndex: focusedIndex - 1})
      this.cartItemsRefs[selectedIndex][focusedIndex -1].focus()
      return
    }

    const getCartItemLastButtonIndex = (cartItemRef) => {
      return cartItemRef.length -1;
    }

    if (selectedButtonIndex === -1) {
      const lastCartItem = this.cartItemsRefs[this.cartItemsRefs.length -1];
      this.setState({...this.state, selectedButtonIndex: getCartItemLastButtonIndex(lastCartItem), selectedIndex: this.cartItemsRefs.length -1} )
      lastCartItem[getCartItemLastButtonIndex(lastCartItem)].focus();
    } else if (selectedButtonIndex === 0) {
      if (selectedIndex === 0) {
        this.setState({...this.state, selectedButtonIndex: -1, selectedIndex: -1})
        this.payNowRef.current && this.payNowRef.current.focus();
      } else {
        const lastButtonInPreviousItem = getCartItemLastButtonIndex(this.cartItemsRefs[selectedIndex - 1])
        this.setState({...this.state, selectedButtonIndex: lastButtonInPreviousItem, selectedIndex: selectedIndex-1});
        this.cartItemsRefs[selectedIndex - 1][lastButtonInPreviousItem].focus();
      }
    } else {
      this.setState({...this.state, selectedButtonIndex: selectedButtonIndex -1})
      this.cartItemsRefs[selectedIndex][selectedButtonIndex -1].focus();
    }
  }

  handleNextItem = () => {
    const {selectedIndex, selectedButtonIndex, focusedIndex} = this.state;
    if (focusedIndex){
      if (focusedIndex === this.cartItemsRefs[selectedIndex].length - 1) {
        this.focusNextItem()
      } else {
        this.setState({ selectedIndex, selectedButtonIndex: focusedIndex + 1})
        this.cartItemsRefs[selectedIndex][focusedIndex+1].focus()
      }
      this.setState({ focusedIndex: null})
    }
    else {
      if (selectedIndex === -1) {
        this.setState({...this.state, selectedIndex: 0, selectedButtonIndex: 0});
        this.cartItemsRefs[0][0].focus();
      } else if (selectedButtonIndex === this.cartItemsRefs[selectedIndex].length - 1) {
        this.focusNextItem()
      } else {
        this.setState({...this.state, selectedIndex: selectedIndex, selectedButtonIndex: selectedButtonIndex + 1})
        this.cartItemsRefs[selectedIndex][selectedButtonIndex+1].focus()
      }
    }
  }

  handleMainSectionKeyPress = (event) => {
    const { mainSectionKeyPress } = this.props;
    if (event.keyCode === KEY_CODES.ENTER) {
      event.stopPropagation()
      event.preventDefault()
      this.payNowRef.current && this.payNowRef.current.focus()
    } else {
      mainSectionKeyPress(event);
    }
  }

  incrementCartItemQuantity = (index, item) => {
    
    const {
      safelyIncrementCartItemQuantity
    } = this.props
    const { selectedButtonIndex } = this.state;

    this.props.setVoiceInstructions(incrementedQuantityMessage(item.name, item.quantity + 1))
    safelyIncrementCartItemQuantity(index)
    this.setState({ focusedIndex: selectedButtonIndex})
    this.readSectionRef.current && this.readSectionRef.current.focus()
  }

  decrementQuantityForItem = (index, item) => {
    const {
      safelyDecrementCartItemQuantity
    } = this.props
    const { selectedButtonIndex } = this.state;

    if (item.quantity === 1) {
      this.focusNextItem()
    } else {
      this.setState({ focusedIndex: selectedButtonIndex })
    }

    this.props.setVoiceInstructions(decrementedQuantityMessage(item.name, item.quantity - 1))
    this.readSectionRef.current && this.readSectionRef.current.focus()
    safelyDecrementCartItemQuantity(index)
  }

  removeItem = (index, cartItem) => {
    this.props.setVoiceInstructions(removedFromCartMessage(cartItem.name))
    this.readSectionRef.current && this.readSectionRef.current.focus()
    this.props.removeFromCart(index)

    setTimeout(() => {
      const { selectedIndex } = this.state
      if (this.cartItemsRefs.length === 0) {
        this.setState({...this.state, selectedIndex: -1, selectedButtonIndex: -1})
        this.payNowRef.current && this.payNowRef.current.focus()
      } else if (selectedIndex === this.cartItemsRefs.length) {
        this.setState({...this.state, selectedIndex: selectedIndex - 1, selectedButtonIndex: 0})
        this.cartItemsRefs[selectedIndex-1][0].focus()
      } else {
        this.setState({...this.state, selectedIndex: 0, selectedButtonIndex: 0})
        this.cartItemsRefs[0][0].focus()
      }
    }, 300)
  }

  render() {
    const {
      cart,
      totalPrice,
      alcoholItemCount,
      alcoholLimit,
      isAlcoholWarningConfirmed,
      toggleAppState,
      onItemClick,
      cartItemsSummary,
      setItemsSummary,
      refProp,
      subTotalPrice,
      taxRate,
      isSidebarOpen,
      voiceInstructions,
      mobilePaySetting,
      ticketEnabled,
      giftCardEnabled,
      safMode,
    } = this.props
    const { showEmptyCartWarning, offlineModeModalOpen, noScannerModalOpen, isScannerDetached } = this.state
    const changeItemsNumberAriaLabel = !isSidebarOpen ? `${voiceInstructions}. Cart total is ${(totalPrice/100).toFixed(2)}` : ''
    const extraPaymentOptions = mobilePaySetting || ticketEnabled || giftCardEnabled


    //Subtract EXTRA_SPACE and cartSummaryHeight from viewportHeight to determine the cartItemsMaxHeight
    const viewportHeight = window.innerHeight
    const EXTRA_SPACE = 45
    const cartSummaryHeight = this.cartSummaryRef?.current?.clientHeight
    const cartItemsMaxHeight = viewportHeight - cartSummaryHeight - EXTRA_SPACE + 'px'

    const checkIfOfflineModeWithCallback = (callback) => {
      if (safMode === 2) {
        this.setState({ ...this.state, offlineModeModalOpen: true })
        return
      } 

      if(isScannerDetached) {
        this.setState({ ...this.state, noScannerModalOpen: true })
        return
      }
      
      callback()
    }

    const NoScannerAttachedModal = () => {
      if(!noScannerModalOpen) {
        return
      }
      
      return <ConfirmModalV2
              onButtonTwoClick={() => this.setState({ ...this.state, noScannerModalOpen: false })}
              headerText={''}
              subtext={'Sorry, the ticket scanner is offline. Please choose another tender.'}
              singleButtonOnly={true}
              buttonTwoText={'Okay'}
              isKiosk={true}
            />
    }
    
    let offlineModeModal = null
    if (offlineModeModalOpen) {
      offlineModeModal = (
        <ConfirmModalV2
          onButtonTwoClick={() => this.setState({ ...this.state, offlineModeModalOpen: false })}
          headerText={''}
          subtext={'This payment tender is not\nsupported while the device\nis offline'}
          singleButtonOnly={true}
          buttonTwoText={'Okay'}
          isKiosk={true}
        />
      )
    }

    return (
      <div className="cart" tabIndex={0} ref={refProp} aria-label="cart" onKeyDown={this.handleMainSectionKeyPress}>
        <OfflineModal />
        {offlineModeModal}
        <NoScannerAttachedModal />
        <div tabIndex={0} onKeyDown={this.handleKeyPress}>
        <div
          className="sr-only"
          aria-live="assertive"
          ref={this.readSectionRef}
          onKeyDown={this.handleKeyPress}
          aria-label={changeItemsNumberAriaLabel}
        >
          {changeItemsNumberAriaLabel}
        </div>

          {!extraPaymentOptions &&
            <>
              <Button
                className="cart__pay btn -primary"
                disabled={!cart.length}
                onClick={this._payNow}
                title="Pay now"
                refProp={node => {
                  this.payNowRef = node
                }}
                ariaLabel="Your cart is empty"
              />
                {showEmptyCartWarning && <div className="cart__empty">Your Cart is Empty</div>}
            </>
          }
          <>
              {!isEmpty(cartItemsSummary) && cartItemsSummary.map((item, index) => (
                <p key={index} className="cart__min-amount-warning">
                  {`${item.name} must meet minimum quantity of ${item.minAmount} to proceed.`}
                </p>
              ))}
            <div className="cart__items" style={{ maxHeight: cartItemsMaxHeight }}>
              {cart.map((cartItem, index) => (
                <CartItem
                alcoholLimitExceeded={
                  cartItem.isAlcohol
                      ? alcoholItemCount === alcoholLimit && !isAlcoholWarningConfirmed
                      : false
                }
                cart={cart}
                confirmAlcoholWarning={() => toggleAppState({ isAlcoholWarningConfirmed: true })}
                item={cartItem}
                key={`${cartItem.id}${index}`}
                removeFromCart={() => this.removeItem(index, cartItem)}
                setItemsSummary={setItemsSummary}
                incrementQuantityForItem={() => this.incrementCartItemQuantity(index, cartItem)}
                decrementQuantityForItem={() => this.decrementQuantityForItem(index, cartItem)}
                onItemClick={() => onItemClick(cartItem.id, index)}
                index={index}
                refProp={(node, buttonIndex) => {this.addCartItemRef(node, buttonIndex, index)}}
              />
            ))}
            <div ref={this.cartItemsEndRef} />
            </div>
            <div className="cart__price-summary" ref={this.cartSummaryRef}>
              {!isEmpty(cart) && <div className="cart__row">
                Subtotal:
                <div className="cart__price">{formatPrice(subTotalPrice)}</div>
              </div>
              } 
              {taxRate > 0 && (
                <div className="cart__row">
                  Tax:
                  <div className="cart__price">{formatPrice(taxRate)}</div>
                </div>
              )}
              <div className="cart__row__container">
                {!isEmpty(cart) && <div className="cart__row__container -discount-message">
                  <div>{`Discounts & fees`} <span style={{fontWeight: "bold"}}>Not Yet</span> {`Applied`}</div>
                </div>
                }
                {extraPaymentOptions && <div className="cart__row__container -payment-message">PAY WITH</div>}
              </div>
              {extraPaymentOptions && (
                <div>
                  <Button
                    className="cart__payRichCheckout btn -primary"
                    disabled={!cart.length}
                    onClick={this._payNow}
                    title="Credit Card"
                    tabIndex={1}
                    ariaLabel="credit card"
                  />
                  {mobilePaySetting && <Button
                    className="cart__payRichCheckout btn -primary"
                    disabled={!cart.length}
                    onClick={() => {
                      if (mobilePaySetting === 'pos_shows_qr') {
                        this._payMobileWallet()
                      } else {
                        checkIfOfflineModeWithCallback(this._payMobileWallet)
                      }
                    }}
                    title={mobilePaySetting === 'pos_shows_qr' ? "Mobile Wallet" : 'QR Pay'}
                    tabIndex={2}
                    ariaLabel="mobile wallet"
                  />}
                  {ticketEnabled && <Button
                    className="cart__payRichCheckout btn -primary"
                    disabled={!cart.length}
                    onClick={() => checkIfOfflineModeWithCallback(this._payTicketTender)}
                    title="Ticket"
                    tabIndex={3}
                    ariaLabel="ticket payment"
                  />}
                  {giftCardEnabled && <Button
                    className="cart__payRichCheckout btn -primary"
                    disabled={!cart.length}
                    onClick={() => checkIfOfflineModeWithCallback(this._payGiftCard)}
                    title="Gift Card"
                    tabIndex={4}
                    ariaLabel="gift card payment"
                  />}
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const menu = getCurrentMenu(state)
  
  return {
    tipSuggestions: menu?.tipSuggestions,
    cart: CartItemsViewModel(getCartItems(state)),
    alcoholLimit: menu?.alcoholLimit,
    totalPrice: getTotal(state),
    subTotalPrice: getSubtotal(state),
    taxRate: getTax(state),
    alcoholItemCount: alcoholItemCountSelector(state),
    isAlcoholWarningConfirmed: state.appState.isAlcoholWarningConfirmed,
    mobilePaySetting: getMobilePaySetting(state),
    ticketEnabled: getTicketEnabled(state),
    giftCardEnabled: getGiftCardEnabled(state),
    safMode: getSafMode(state),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    safelyIncrementCartItemQuantity: (index) => dispatch(safelyIncrementCartItemQuantity({ index })),
    safelyDecrementCartItemQuantity: (index) => dispatch(safelyDecrementCartItemQuantity({ index })),
    removeFromCart: (index) => dispatch(removeFromCart({ index })),
    toggleAppState: (data) => dispatch(toggleAppState({ data })),
    setKioskPayment: (paymentType) => dispatch(setKioskPayment(paymentType)),
    clearOrderData: () => {
      dispatch(clearOrderInProgress())
      dispatch(clearRemoteOrderTotal())
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart))

