import { useContext } from 'react'
import SpinnerContext from '../SpinnerContext'

const useSpinner = () => {
  const useSpinnerContext = () => useContext(SpinnerContext)

  const { startSpinner, stopSpinner, showSpinner } = useSpinnerContext()

  return { startSpinner, stopSpinner, showSpinner }
}

export default useSpinner
