import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, isEmpty } from 'lodash'

import { addToCartOrIncrement } from '../actions/cart'
import { updateSelectedCategory } from '../actions/search'

import Items from '../components/Items'

import { mapStateToPropsForItems } from './shared/Items'

const mapDispatchToProps = (dispatch, ownProps) => {
  const currentPath = get(ownProps, 'match.path', () => {})
  const push = get(ownProps, 'history.push', () => {})

  return {
    onTapItem: (item) => {
      dispatch(addToCartOrIncrement({ itemId: item.id }))
      // TODO (CFD) (mkramerl): Call VN_UPDATE_CART here, or in the saga.

      if (!isEmpty(item.modifierGroups)) {
        push({
          pathname: `${currentPath}/cart`,
          state: {
            cartItemNotValidIfClosed: true
          }
        })
      }
    },
    setSelectedCategory: (selectedCategory) => dispatch(updateSelectedCategory(selectedCategory))
  }
}

export default withRouter(connect(mapStateToPropsForItems, mapDispatchToProps)(Items))
