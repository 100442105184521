import { connect } from 'react-redux'

import OrderDetails from '../../components/orders/Details'

import {
  clearSelectedOrder,
  voidOrderSucceeded,
  voidOrderFailed,
  syncOrderRequested,
  setOrderInProgress,
  clearOrderInProgress,
} from '../../actions/order'

import { setCartFromTab, clearCart, addNotesToCart } from '../../actions/cart'

import createDataDogLog from '../../utils/datadog'

import { getIsKiosk, getTabsModeEnabled } from '../../selectors/menus'
import { getMutationInProgress, makeGetSelectedOrder } from '../../selectors/order'

const mapStateToProps = (state) => {
  const getSelectedOrder = makeGetSelectedOrder()
  const order = getSelectedOrder(state)
  const isKiosk = getIsKiosk(state)
  const mutationInProgress =  getMutationInProgress(state)
  const tabsModeEnabled = getTabsModeEnabled(state)

  return {
    order,
    isKiosk,
    mutationInProgress,
    tabsModeEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    syncOrder: (id, order) => {
      dispatch(syncOrderRequested(id))
      createDataDogLog('info', `Sync Single Order ${id}`, { order })
    },
    onClearOrder: () => { 
      dispatch(clearSelectedOrder())
      dispatch(clearOrderInProgress())
    },
    voidOrderSucceeded: (id, freedomPayResponse) => { dispatch(voidOrderSucceeded(id, freedomPayResponse)) },
    voidOrderFailed: (id, freedomPayResponse) => { dispatch(voidOrderFailed(id, freedomPayResponse)) },
    setCartFromTab: (order) => { dispatch(setCartFromTab(order)) },
    clearCart: () => { dispatch(clearCart()) },
    setOrderInProgressFromTab: (order) => { dispatch(setOrderInProgress(order)) },
    addNotesToCart: (userNotes) => { dispatch(addNotesToCart(userNotes)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
