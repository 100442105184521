import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Modal from 'react-modal'
import { get, isEmpty } from 'lodash'

// import analytics from '../utils/analytics'
import IconExit from '../components/icons/IconExit'
import { getCurrentStand } from '../../selectors/menus'
import { menuMessage } from '../utils/messages'

import { KEY_CODES } from "../utils/constants";

const MenuWithConfirmationModal = ({
  standName,
  cartItems,
  history,
  redirectTo,
  handleMenuClick,
  refPropTitle,
  refPropExit,
  onTitleKeyPress,
  onExitKeyPress
}) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const confirmExitButtonRef = useRef(null)

  const goBackToAttractLoop = () => {
    isEmpty(cartItems) ? history.push(redirectTo) : setModalOpen(!isModalOpen)
  }

  const handleConfirm = () => {
    setModalOpen(!isModalOpen)
    history.push(redirectTo)
  }

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        confirmExitButtonRef.current.focus()
      }, 0)
    }
  }, [isModalOpen])

  const confirmExitCart = event => {
    switch (event.keyCode) {
      case KEY_CODES.ENTER:
        handleConfirm()
        break
      case KEY_CODES.UP:
        setModalOpen(!isModalOpen)
        break
      default:
        break
    }
  }

  return (
    <>
      <button
        type="button"
        className="menu__exit"
        onClick={goBackToAttractLoop}
        ref={refPropExit}
        tabIndex={0}
        onKeyDown={onExitKeyPress}
      >
        <IconExit className="menu__icon" />
        Exit
      </button>
      <div className="menu__top">
        <button
          className="menu__brand-btn"
          onClick={handleMenuClick}
          aria-label={menuMessage(standName)}
          ref={refPropTitle}
          onKeyDown={onTitleKeyPress}
        >
          <div className="menu__brand">{standName}</div>
        </button>
      </div>
      <Modal className="modal" overlayClassName="overlay" isOpen={isModalOpen}>
        <div className="modal__copy">
          This will remove the items in your cart.
        </div>
        <div className="modal__actions">
          <button className="btn -outline" type="button" onClick={() => setModalOpen(!isModalOpen)}>Cancel</button>
          <button
            ref={confirmExitButtonRef}
            onKeyDown={confirmExitCart}
            className="btn -primary"
            type="button"
            onClick={handleConfirm}
            tabIndex={0}
            aria-label="This will remove the items in your cart. Press enter to confirm or press arrow up to stay"
          >
            Proceed
          </button>
        </div>
      </Modal>
    </>
  )
}

MenuWithConfirmationModal.defaultProps = {
  redirectTo: '/kiosk/menu_select',
}

MenuWithConfirmationModal.propTypes = {
  standName: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
  handleMenuClick: PropTypes.func,
}

const mapStateToProps = state => ({
  standName: get(getCurrentStand(state), 'name'),
})

export default compose(
  withRouter,
  connect(mapStateToProps),
)(MenuWithConfirmationModal)
