import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useVirtual } from "react-virtual"
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './List.module.scss'

import OrderRow from './OrderRow'
import ConfirmModalV2 from '../ConfirmModalV2'

import { ORDERS_STATUS_OPTIONS, STADIUM_ORDER_STATES } from '../../utils/orderStates'
import { createOrderParams } from '../../utils/orderUtils'

import { getActiveAttendantId } from '../../selectors/attendant'

import useSpinner from '../../context/hooks/useSpinner'

const OrdersList = ({ orderDetailsRequested, selectOrder, orders, selectedOrderId, safIsUploading,
    isKiosk, ordersFilter, className }) => {
  const [showSyncingInProgress, setShowSyncingInProgress] = useState(false)
  const classNames = cn(styles.ordersList, className)
  const activeAttendantId = useSelector(getActiveAttendantId)
  const syncingInProgressMessage = "Orders are currently being synced. Please stop the sync to view orders."
  const listRef = useRef()

  // useEffect(() => { onMount() }, [])
  // useEffect(()=> {
  //     if(isFocused && listRef.current) {
  //       addPaddingAndScrollUpToElement(listRef.current.parentElement)
  //     }
  // }, [isFocused])

  const { stopSpinner } = useSpinner()

  useEffect(()=> {
    stopSpinner()
  }, [])


  const onSelectOrder = (order) => {
    const orderId = order.uuid

    if(order.syncedAt) {
      orderDetailsRequested(orderId)
    }

    selectOrder(orderId)
  }

  const maybeOnSelectOrder = (order) => {
    if (safIsUploading) {
      setShowSyncingInProgress(true)
      return
    }

    onSelectOrder(order)
  }

  const filterOrders = (orders) => {
    switch (ordersFilter.showOrders) {
      case ORDERS_STATUS_OPTIONS.OPEN_ORDERS:
        return orders?.filter(order => {
          return (order.employeeId === activeAttendantId ||
                  createOrderParams(order)?.employeeId === activeAttendantId) &&
                 (order.balanceDueInCents > 0 || ((order.state === STADIUM_ORDER_STATES.TABBED || order.state === STADIUM_ORDER_STATES.TABBED_OFFLINE) && order.orderState !== 'closed'))
        })
      case ORDERS_STATUS_OPTIONS.CLOSED_ORDERS:
        return orders?.filter(order => {
          return (order.employeeId === activeAttendantId ||
                  createOrderParams(order)?.employeeId === activeAttendantId) &&
                 (order.balanceDueInCents <= 0 && (!(order.state === STADIUM_ORDER_STATES.TABBED || order.state === STADIUM_ORDER_STATES.TABBED_OFFLINE) || order.orderState === 'closed') && order.orderNumber)
        })
      default:
        return orders
    }
  }

  const filteredOrders = filterOrders(orders)
  const windowRef = useRef(window);
  const rowVirtualizer = useVirtual({
    size: filteredOrders.length,
    parentRef: listRef,
    windowRef,
    estimateSize: useCallback(() => 75, []),
    overscan: 10
  })
  const content = rowVirtualizer.virtualItems.map(virtualRow => {
    const order = filteredOrders[virtualRow.index]
    return <OrderRow
      isSelected={order.uuid === selectedOrderId}
      order={order ?? {}}
      className={styles.row}
      key={order?.id}
      onSelect={() => maybeOnSelectOrder(order)}
      refundable={order?.refundable}
      isKiosk={isKiosk}
      paddingEnd={'200px'}
    />
  })

  return (
    <div className={classNames}>
      <div className={styles.headerRow}>
        <div>Tender/Name</div>
        <div>Amount</div>
        <div>Order #</div>
        <div>Last Updated</div>
        <div>Status</div>
        <div></div>
      </div>
      <div className={styles.scrollable}>
        <div className="inner-virtual-list" style={{width: "100%", position: "relative"}} ref={listRef}>
          {content}
        </div>
      </div>
      {showSyncingInProgress && (
        <ConfirmModalV2
          headerText={''}
          subtext={syncingInProgressMessage}
          buttonTwoText={"OK"}
          onButtonOneClick={() => setShowSyncingInProgress(false)}
          onButtonTwoClick={() => setShowSyncingInProgress(false)}
          singleButtonOnly
        />
      )}
    </div>
  )
}

OrdersList.defaultProps = {
  orders: [],
  orderFilter : {},
}

OrdersList.propTypes = {
  orderDetailsRequested: PropTypes.func,
  selectOrder: PropTypes.array,
  selectedOrderId: PropTypes.string,
}

export default OrdersList
