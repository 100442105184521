const CONCESSIONS = 'concessions-pos'
const KIOSK = 'kiosk'
const CFD = 'cfd'

const DISPLAY_NAMES = {
  [CONCESSIONS]: 'Concessions POS',
  [KIOSK]: 'Kiosk',
  [CFD]: 'Customer Facing Display POS'
}

const PRINTING_TYPES = {
  [CONCESSIONS]: 'pos',
  [KIOSK]: 'kiosk',
}

const RECEIPT_TYPES = {
  [CONCESSIONS]: 'receipt',
  [KIOSK]: 'receipt',
}


class DeviceSubtypes {
  static all = [CONCESSIONS, KIOSK]
  static displayName = (type) => DISPLAY_NAMES[type]
  static isConcessions = (type) => type === CONCESSIONS
  static isCFD = (type) => type === CFD
  static isKiosk = (type) => type === KIOSK
  static printerDeviceType = (type) => PRINTING_TYPES[type]
  static printerReceiptType = (type) => RECEIPT_TYPES[type]
}

export default DeviceSubtypes
