import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import CartItems from '../components/CartItems'

import CartItemsViewModel from '../viewmodels/CartItemsViewModel'

import { getCartItems, getNewItemFocus } from '../selectors/cart'
import { removeFromCart, clearFocus, addNotesToCart } from '../actions/cart'
import { addPromotionsToOrder, clearPromotionsOnOrder } from '../actions/promotion'

import { getCurrentStand } from '../selectors/menus'
import { getSelectedPromotions } from '../selectors/promotion'
import { getCartNotes } from '../selectors/cart'

const mapStateToProps = (state) => {
  const fetchedCartItems = getCartItems(state)
  const cartItems = CartItemsViewModel(fetchedCartItems)
  const cartNotes = getCartNotes(state)
  const discounts = getSelectedPromotions(state)
  const newItemFocus = getNewItemFocus(state)
  const currentStandUuid = getCurrentStand(state)?.uuid

  return { cartItems, newItemFocus, discounts, cartNotes, currentStandUuid }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const currentPath = get(ownProps, 'match.url', () => {})
  const push = get(ownProps, 'history.push', () => {})

  return {
    onSelect: (index) => { push(`${currentPath}/cart/${index}`)},
    onRemove: (index) => dispatch(removeFromCart({ index })),
    clearFocus: () => dispatch(clearFocus()),
    addPromotionsToOrder: (selectedPromotions) => dispatch(addPromotionsToOrder({ selectedPromotions })),
    clearPromotions: () => dispatch(clearPromotionsOnOrder()),
    addNotesToCart: (userNotes) => dispatch(addNotesToCart(userNotes))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartItems))
