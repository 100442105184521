import React, { useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"

import cn from "classnames"
import styles from "./EmailReceiptModal.module.scss"
import useGetIsMobileScreen from "../../hooks/useGetIsMobileScreen"
import { submitEmail, submitText } from "./ReceiptUtils"
import { ToastManager } from '../../utils/toastManager'

export const RECEIPT_TYPES = {
  EMAIL: "email",
  TEXT: "phone number",
}

const ALLOWED_SMS = /^[0-9+\-*()]*$/

const ReceiptModal = ({
  onClose,
  receiptType,
  allowMultiple,
  order,
  paymentId,
  className,
}) => {
  const classNames = cn(styles.emailReceiptModal, className)
  const [recipientInfo, setRecipientInfo] = useState({ isFocused: false, isValid: true, recipients: '' })
  const isMobileViewPort = useGetIsMobileScreen()
  const inputType = useMemo(() => {
    switch (receiptType) {
      case RECEIPT_TYPES.TEXT:
        return 'tel';
      default:
        return 'text'
    }
  }, [receiptType])

  const isSendDisabled = useMemo(() => {
    return !recipientInfo.isValid || !recipientInfo?.recipients
  }, [recipientInfo])

  let subTextMessage = null

  if (allowMultiple) {
    subTextMessage = isMobileViewPort
      ? "Separate multiple " + receiptType + "s with\n commas"
      : "Separate multiple " + receiptType + "s with commas"
  } else {
    subTextMessage = "Enter " + receiptType
  }

  const dispatch = useDispatch()

  /**
   * @TODO: [T35898] add telephone validation
   */
  const onRecipientSubmit = () => {
    if (!recipientInfo.recipients.length) {
      ToastManager.error(`The ${receiptType} cannot be empty.`)
    }

    if (isSendDisabled) return

    if (receiptType === RECEIPT_TYPES.EMAIL) {
      dispatch(submitEmail(recipientInfo, order, paymentId, setRecipientInfo))
    } else if (receiptType === RECEIPT_TYPES.TEXT) {
      dispatch(submitText(recipientInfo, order, setRecipientInfo))
    }
    onClose()
  }

  const inputKeyDownHandler = (e) => {
    if(e.key !== 'Enter') return
    onRecipientSubmit()
  }

  const onChangeHandler = (e) => {
    const val = e.target.value
    if (receiptType === RECEIPT_TYPES.TEXT && !ALLOWED_SMS.test(val)) {
      return
    }

    setRecipientInfo({ ...recipientInfo, recipients: val })
  }

  return (
    <div className={classNames}>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <div className={styles.emailText}>Enter {receiptType}</div>
        <div className={styles.emailSubtext}>{subTextMessage}</div>
        <div className={styles.inputContainer}>
          <input
            autoFocus
            type={inputType}
            value={recipientInfo.recipients}
            className={styles.inputField}
            onBlur={() => {
              setRecipientInfo({ ...recipientInfo, isFocused: false })
            }}
            onFocus={() => {
              setRecipientInfo({ ...recipientInfo, isFocused: true, isValid: true })
            }}
            onKeyDown={inputKeyDownHandler}
            onChange={onChangeHandler}
          />
        </div>
        <div className={styles.buttonContainer}>
          <div
            onClick={onClose}
            className={styles.cancelButton}
          >
            Cancel
          </div>
          <div className={styles.partition} />
          <div
            onClick={onRecipientSubmit}
            className={styles.sendButton}
            disabled={isSendDisabled}
          >
            Send
          </div>
        </div>
      </div>
    </div>
  )
}

ReceiptModal.defaultProps = {
  onClose: () => {},
}

ReceiptModal.propTypes = {
  onClose: PropTypes.func,
}

export default ReceiptModal
