import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../components/Button'

export default class Category extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    refProp: PropTypes.func.isRequired,
    tabHandleArrowPress: PropTypes.func.isRequired,
  }

  render() {
    const { category, isActive, onSelect, refProp, tabHandleArrowPress } = this.props
    return (
      <div className="menu__link-wrapper" ref={this.props.refProp} tabIndex={0} onKeyDown={tabHandleArrowPress}>
        <Button
          ariaLabel={category.name}
          className={classNames('menu__link', { '-active': isActive })}
          onClick={onSelect}
          ref={refProp}
          title={category.name}
          tabIndex={0}
        />
      </div>
    )
  }
}
