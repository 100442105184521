export const vn_getEntries = (obj) => {
  if (!Object.entries) {
    return Object.keys(obj).map(key => [key, obj[key]])
  } else {
    return Object.entries(obj)
  }
}

/**
 * Calculate the distribution of payments into an N number of buckets.
 * @param {int} amount The amount to distribute
 * @param {int} total The entire value of the payment
 * @param {int} count How many distributions need to happen
 * @returns {Array} Returns an array of all the distributions, may not be even.
 */
export const calculateDistributionOfPayment = (amount, total, count) => {
  let distributedAmount = 0;
  const distributedAmounts = [];

  // First distribute by weight with a possible remainder. Simultaneously aggregate the possible
  // previous tax amount of items which are now removed.
  for (let i = 0; i < count; i++) {
    const distribution = Math.floor((amount * total) / total);
    distributedAmount += distribution;
    distributedAmounts[i] = distribution;
  }
  
  // Next distribute what hasn't been distributed unless that setting has been turned off.
  let undistributedAmount = amount - distributedAmount;

  const additive = undistributedAmount > 0 ? 1 : -1;
  undistributedAmount *= additive;
  let objectNumber = 0;

  while (undistributedAmount > 0) {
    distributedAmounts[objectNumber] += additive;
    undistributedAmount -= 1;
    objectNumber = (objectNumber + 1) % count;
  }

  return distributedAmounts;
}

/**
 * Checks to see if an object is empty
 * @param {Object} - The object to check
 * @returns {Boolean} - Returns true if empty, false otherwise
 */
export const isEmpty = (value) => {
  // check for falsy values
  if (!value) {
    return true
  }

  if (value.constructor === Object) {
    if (vn_getEntries(value).length === 0) {
      return true
    }
  }

  return false
}

/**
 * given a JWT, this will parse and return it
 * @param {String} token - A JWT token
 * @returns {Object} A valid JSON object if it can be parsed, null otherwise
 */
export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

/**
   * Checks to see if the value has already been applied on the order
   * @param {Object} order - The current order
   * @param {String} code - The ticket to check
   * @returns {Boolean} - Returns true if the ticket has already been used, false otherwise
   */
export const codeAlreadyUsed = (order, code) => {

  if (code) {
    for (let i = 0; i < order?.payments?.length; i++) {
      
      const payment = order?.payments[i]

      if (payment.ticketId === code ||
          payment.qrCode === code ||
          payment.token === code) {
            return true
      }
    }
  }

  return false
    
}