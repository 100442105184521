import { get } from 'lodash'

const ModifierViewModel = (modifier) => {
  return {
    name: get(modifier, 'name', 'Modifier'),
    min: get(modifier, 'min', null),
    max: get(modifier, 'max', null),
    sortOrder: get(modifier, 'sortOrder', 0),
    secondaryPriceThresholdCount: get(modifier, 'secondaryPriceThresholdCount', 0),
    items: get(modifier, 'modifierPropertiesContainer.items', []),
    defaults: get(modifier, 'modifierPropertiesContainer.defaults', []),
    featured: get(modifier, 'modifierPropertiesContainer.featured', []),
    uuid: get(modifier, 'uuid', 'modifier-uuid')
  }
}

export default ModifierViewModel
