import { get, isEmpty } from 'lodash'
import {
  loadConfigSucceeded,
  loadConfigFailed,
  createConfig,
  updateConfig,
  updateConfigSucceeded,
  createConfigSucceeded,
  createConfigFailed,
  updateConfigFailed,
} from '@ordernext/networking-stadium/stadium/actions'

import {
  UPDATE_MODE,
  SET_NETWORK_AVAILABLE,
  SHOULD_WAIT_FOR_REDIRECT,
  ENABLE_BACKBAR_MODE,
  DISABLE_BACKBAR_MODE,
} from '../actions/config'

const defaultState = {
  hasConfig: false,
  networkAvailable: true,
  remote: {
    loading: false,
    failed: false,
  },
}

const reducer = (state = defaultState, action) => {
  if (action.type === createConfig.type || action.type === updateConfig.type) {
    return {
      ...state,
      remote: {
        loading: true,
        failed: false,
      }
    }
  }

  if (action.type === createConfigSucceeded.type || action.type === updateConfigSucceeded.type) {
    const configurations = get(action, 'payload.data.appDeviceConfigurations[0]', {})

    return {
      ...state,
      ...configurations,
      hasConfig: true,
      shouldRefreshConfig: false,
      remote: {
        loading: false,
        failed: false,
      },
    }
  }

  if (action.type === createConfigFailed.type || action.type === updateConfigFailed.type) {
    return {
      ...state,
      remote: {
        loading: false,
        failed: true,
      }
    }
  }

  if (action.type === loadConfigSucceeded.type) {
    let configurations = get(action, 'payload.data.appDeviceConfigurations[0]')

    /**
     * TODO:
     * We should ask about getting this changed.
     * if there is only one configuration, Stadium returns a singular key name.
     */
    if (!configurations) {
      configurations = get(action, 'payload.data.appDeviceConfiguration', {})
    }

    const hasConfig = !isEmpty(configurations)

    return {
      ...state,
      ...configurations,
      hasConfig,
      shouldRefreshConfig: false,
    }
  }

  if (action.type === loadConfigFailed.type) {
    if (get(action, 'payload.response.status') === 404) {
      return {
        ...state,
        hasConfig: false,
      }
    }

    return state
  }

  if (action.type === UPDATE_MODE) {
    return {
      ...state,
      mode: action.payload.mode
    }
  }

  if (action.type === SET_NETWORK_AVAILABLE) {
    return {
      ...state,
      networkAvailable: action.payload
    }
  }

  if (action.type === SHOULD_WAIT_FOR_REDIRECT) {
    return {
      ...state,
      shouldWaitForRedirect: action.payload
    }
  }

  if (action.type === ENABLE_BACKBAR_MODE) {
    return {
      ...state,
      backbarMode: true
    }
  }

  if (action.type === DISABLE_BACKBAR_MODE) {
    return {
      ...state,
      backbarMode: false
    }
  }

  return state
}

export default reducer
