import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import ChangeCalculatorForm from '../components/ChangeCalculatorForm'
import { getTotal } from '../selectors/cart'
import { addPaymentToOrderInProgress, closeNoOpTender } from '../actions/order'

import PeripheralBridge from '../utils/peripheralBridge'

const mapStateToProps = (state, ownProps) => {
  const paymentId = ownProps?.match?.params?.paymentId
  const tenderAmountInCents = ownProps?.history?.location?.state?.tenderAmountInCents ?? 0
  const total = paymentId ? tenderAmountInCents : getTotal(state)

  return { 
    total,
    paymentId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const paymentId = ownProps?.match?.params?.paymentId
  const tenderAmountInCents = ownProps?.history?.location?.state?.tenderAmountInCents ?? 0
  const goBack = get(ownProps, 'history.goBack', () => {})

  return {
    onBack: () => {
      goBack()
    },
    onComplete: (noPaymentRequired) => {
      const bridge = PeripheralBridge.getBridge()
      if (bridge && !noPaymentRequired) bridge.call('openDrawer')

      if (paymentId) {
        dispatch(addPaymentToOrderInProgress({ tenderAmountInCents, paymentId, paymentType: 'cash', shortDescription: 'Cash' }))
      } else {
        dispatch(closeNoOpTender())
      }

    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeCalculatorForm))
