import React from 'react'
import cn from 'classnames'

import styles from './BackChevron.module.scss'

const BackChevron = ({ className, ...props }) => {
  const classNames = cn(styles.backChevron, className)

  return (
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames} {...props}>
      <path d="M12.1201 2.12L4.24012 10L12.1201 17.88L10.0001 20L0.000117302 10L10.0001 0L12.1201 2.12Z" fill="white"/>
    </svg>
  )
}

export default BackChevron
