import { filter, get, isEmpty, omit, difference, keys, find, compact, reduce, values, uniqBy } from 'lodash'
import generateUuid from 'uuid/v4'

import moment from 'moment'

import {
  CLEAR_ORDER_SEARCH,
  CLEAR_SELECTED_ORDER,
  CREATE_OFFLINE_ORDER_SUCCEEDED,
  CREATE_OFFLINE_ORDER_FAILED,
  FILTER_ORDERS,
  REPLAY_AUTH_REQUEST_SUCCEEDED,
  REPLAY_AUTH_REQUEST_FAILED,
  SAVE_ORDER,
  SAVE_OFFLINE_ORDER,
  SEARCH_ORDER,
  SELECT_ORDER,
  SYNC_ORDER_INITIATED,
  SYNC_ORDER_SUCCEEDED,
  SYNC_ORDER_FAILED,
  SYNC_STATUS_RESET,
  UPDATE_ORDER_SUCCEEDED,
  UPDATE_ORDER_FAILED,
  VOID_ORDER_SUCCEEDED,
  VOID_ORDER_FAILED,
  ADD_SIGNATURE_TO_ORDER,
  ADD_TIP_TO_ORDER,
  PURGE_ORDERS,
  PURGE_ALL_ORDERS,
  LOAD_TAB_ORDERS_SUCCEEDED,
  LOAD_TAB_ORDERS_FAILED,
  STORE_ORDER_UUID_AND_ORDER_NUMBER,
  CLEAR_STORED_ORDER_UUID_AND_ORDER_NUMBER,
  REMOVE_ORDER,
  PERFORM_MAINTENANCE,
  UPDATE_MAINTENANCE_TIMESTAMP,
  SET_MAINTENANCE_ENABLED,
  SET_KIOSK_PAYMENT,
  UPDATE_ORDER_IN_PROGRESS,
  CLEAR_ORDER_IN_PROGRESS,
  ADD_TIP_ORDER_IN_PROGRESS,
  LOAD_ORDER_DETAILS_SUCCEEDED,
  LOAD_ORDER_DETAILS_FAILED,
  SET_ORDER_IN_PROGRESS,
  ADD_ITEMS_TO_TAB,
  ADD_ITEMS_TO_TAB_FAILED,
  ADD_ITEMS_TO_TAB_SUCCEEDED,
  ADD_ITEMS_TO_ORDER,
  REPLACE_ITEMS_FROM_ORDER,
  UPDATE_ORDER_TOTALS,
  RESET_MUTATION_IN_PROGRESS,
  REMOVE_QR_PAYMENT_AND_UNSYNCABLE_STATUS,
  SET_ORDER_IN_PROGRESS_WITH_ID,
  RESTORE_QR_PAYMENT_AND_UNSYNCABLE_STATUS,
  SET_QR_PAY_AUTH_ERROR,
  DUPLICATE_ORDER_WITH_NEW_IDS,
  SET_UPDATED_SINCE,
  ORDER_REPORTS_LAST_FETCHED_AT
} from '../actions/order'

import { ON_RESET_SAF_FULL_RESPONSE, ON_SAF_FULL_RESPONSE_RECEIVED } from '../actions/peripheral'
import { SAVE_RICH_CHECKOUT_ORDER } from '../actions/richCheckout/order'
import { REQUEST_REFUND, REQUEST_TABBED_REFUND, REQUEST_TABBED_REFUND_SUCCEEDED,
  REQUEST_TABBED_REFUND_FAILED } from '../actions/refund'

import { getNumericSafValue } from '../selectors/peripheral'
import OrderStates, { INTERNAL_ORDER_STATES } from '../utils/orderStates'
import { createOrderParams } from '../utils/orderUtils'

const defaultState = {}

const safeAddToArray = (array, value) => {
  const arr = array || []

  if (arr.indexOf(value) < 0) return arr.concat(value)

  return arr
}

const safeRemoveFromArray = (array, value) => {
  const arr = array || []

  return filter(arr, (x) => x !== value)
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case CREATE_OFFLINE_ORDER_SUCCEEDED: {
      const { uuid } = action
      const order = state.byId[uuid]
      const offlineLocalIds = safeRemoveFromArray(state.offlineLocalIds, uuid)
      const successRemoteIds = safeAddToArray(state.successRemoteIds, uuid)

      return {
        ...state,
        offlineLocalIds,
        successRemoteIds,
        byId: {
          ...state.byId,
          [uuid]: {
            ...order,
            errorMessage: null,
            isSyncing: false,
            wasCreatedInStadium: true,
            syncedAt: new Date(),
          }
        }
      }
    }

    case CREATE_OFFLINE_ORDER_FAILED: {
      const { uuid, order } = action

      return {
        ...state,
        byId: {
          ...state.byId,
          [uuid]: {
            ...order,
            wasCreatedInStadium: false,
            isSyncing: false,
          }
        }
      }
    }

    case CLEAR_ORDER_SEARCH:
      return {
        ...state,
        ordersSearch: '',
      }

    case CLEAR_SELECTED_ORDER:
      return {
        ...state,
        selectedOrderId: null,
      }

    case REMOVE_ORDER: {
      const { orderId } = action.payload
      const { [orderId]: value, ...byId } = state.byId

      return {
        ...state,
        byId: {
          ...byId,
        },
      }
    }

    case FILTER_ORDERS:
      return {
        ...state,
        ordersFilter: action.payload,
      }

    case SET_KIOSK_PAYMENT: {
      const { paymentType } = action.payload
      return {
        ...state,
        kioskPayment: paymentType
      }
    }

    case REPLAY_AUTH_REQUEST_SUCCEEDED: {
      const order = get(action, 'payload.order', {})
      const id = order.uuid
      const newOrder = state.byId[id]

      const replayStatus = get(action, 'payload.replayStatus', { statusDisplay: 'No info while checking status' })

      // next step is to sync with stadium, which has been kicked off in the saga
      const offlineCardIds = safeRemoveFromArray(state.offlineCardIds, id)
      const activeOfflineCardCount = Math.max((state?.activeOfflineCardCount ?? 0) - 1, 0)

      return {
        ...state,
        offlineCardIds,
        activeOfflineCardCount,
        byId: {
          ...state.byId,
          [id]: {
            ...newOrder,
            replayStatus,
          }
        }
      }
    }

    case REPLAY_AUTH_REQUEST_FAILED: {
      const order = get(action, 'payload.order', {})
      const id = order.uuid
      const replayStatus = get(action, 'payload.replayStatus', { statusDisplay: 'No info while checking status' })
      const offlineLocalIds = !order.wasCreatedInStadium ? safeAddToArray(state.offlineLocalIds, id) : state.offlineLocalIds

      // TODO(mkramerl): Refactor state to utilize the state on the order, not the creation parameters.
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            offlineLocalIds,
            ...order,
            isSyncing: false,
            replayStatus,
          }
        }
      }
    }

    case SAVE_OFFLINE_ORDER: {
      const orderData = action.payload
      const uuid = orderData?.uuid ?? generateUuid()

      const offlineCardIds = safeAddToArray(state.offlineCardIds, uuid)
      const activeOfflineCardCount = Math.max((state?.activeOfflineCardCount ?? 0) + 1, 0)

      const offlineLocalIds = safeAddToArray(state.offlineLocalIds, uuid)

      return {
        ...state,
        offlineCardIds,
        activeOfflineCardCount,
        offlineLocalIds,
        byId: {
          ...state.byId,
          [uuid]: orderData,
        },
        currentOrderId: uuid,
      }
    }

    case SAVE_ORDER: {
      const uuid = action?.payload?.uuid ?? generateUuid()

      const offlineLocalIds =
          action?.addToSyncArray
          ? safeAddToArray(state.offlineLocalIds, uuid)
          : state.offlineLocalIds

      const offlineCardIds =
          action?.removeFromCardSyncArray
          ? safeRemoveFromArray(state.offlineCardIds, uuid)
          : state.offlineCardIds

      return {
        ...state,
        offlineLocalIds,
        offlineCardIds,
        byId: {
          ...state.byId,
          [uuid]: {
            ...get(state, `byId.${uuid}`, {}),
            ...action.payload,
            modifiedAt: new Date(),
          },
        },
        currentOrderId: uuid,
      }
    }

    case SAVE_RICH_CHECKOUT_ORDER: {
      const { uuid } = action.payload

      const successRemoteIds = safeAddToArray(state.successRemoteIds, uuid)

      return {
        ...state,
        successRemoteIds,
        byId: {
          ...state.byId,
          [uuid]: {
            ...action.payload,
            isRichCheckout: true,
            modifiedAt: new Date(),
            syncedAt: new Date(),
            wasCreatedInStadium: true,
          },
        },
        currentOrderId: uuid,
      }
    }

    case SEARCH_ORDER:
      return {
        ...state,
        ordersSearch: action.payload,
      }

    case SELECT_ORDER:
      return {
        ...state,
        selectedOrderId: action.payload,
      }

    case SYNC_ORDER_INITIATED: {
      const id = action.id

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            isSyncing: !state.byId[id]?.unsyncable,
          }
        }
      }
    }

    case SYNC_ORDER_SUCCEEDED: {
      const order = action.order || {}
      const id = order.uuid

      const successRemoteIds = safeAddToArray(state.successRemoteIds, id)
      const offlineLocalIds = safeRemoveFromArray(state.offlineLocalIds, id)

      let unsyncableIds = state.unsyncableIds
      if (order.unsyncable) {
        unsyncableIds = safeRemoveFromArray(state.unsyncableIds, id)
      }

      return {
        ...state,
        offlineLocalIds,
        successRemoteIds,
        unsyncableIds,
        byId: {
          ...state.byId,
          [id]: {
            ...order,
            errorMessage: null,
            isSyncing: false,
            syncedAt: new Date(),
            wasCreatedInStadium: true,
          }
        }
      }
    }

    case SYNC_ORDER_FAILED: {
      let order = action.order || {}
      const id = order.uuid
      order.isSyncing = false

      const response = get(action, 'payload.response', {})
      const status = get(response, 'status', null)

      // An order forcibly marked as unsyncable should not be unmarked.
      order.unsyncable = order.unsyncable || status === 422 || status === 408

      let unsyncableIds = state.unsyncableIds
      if (order.unsyncable) {
        unsyncableIds = safeAddToArray(state.unsyncableIds, id)
      }
      const err = action?.payload
      const applicationMessage = err?.response?.data?.errorMessage ?? ''
      const friendlyMessage = err?.response?.data?.friendlyMessage ?? ''

      let errorMessage = friendlyMessage ?? applicationMessage ?? err.message

      errorMessage = errorMessage || get(response, 'data.errorMessage', null)
      if (err?.message === 'Network Error') {
        errorMessage = 'Network error while trying to submit order'
      }

      //QR pay auth failed specific processing
      if (err.data?.state === 'authorization_failed' && order.payments?.[0].paymentType === 'wallet_nonce') {
        errorMessage = err.data?.stateDisplayName
      }

      order.errorMessage = errorMessage

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: order,
        },
        unsyncableIds,
      }
    }

    case SYNC_STATUS_RESET: {
      const byId = reduce(state.byId, (ordersById, order) => ({
        ...ordersById,
        [order.uuid]: {
          ...state.byId[order.uuid],
          isSyncing: false
        }
      }), {})

      return {
        ...state,
        byId,
      }
    }

    case UPDATE_ORDER_SUCCEEDED: {
      const { uuid } = action
      const order = state.byId[uuid]

      const offlineLocalIds = safeRemoveFromArray(state.offlineLocalIds, uuid)

      // orderStates of preauth or failure are initial states and must be removed in favor of final
      // states post-update
      // TODO(mkramerl): Don't use the intermediary orderState (part of legacy code), use the actual
      // order state given to us or known to us through card reader data.

      const clearableOrderStates = [
        INTERNAL_ORDER_STATES.PREAUTH,
        INTERNAL_ORDER_STATES.FAILED,
        INTERNAL_ORDER_STATES.CANCELLED,
      ]

      const updatedOrderState = values(clearableOrderStates).includes(order.orderState)  ?  undefined : order.orderState

      // move from offlineLocalIds to Completed
      return {
        ...state,
        offlineLocalIds,
        byId: {
          ...state.byId,
          [uuid]: {
            ...order,
            orderState: updatedOrderState,
            receiptSignature: null,
            errorMessage: null,
            isSyncing: false,
            syncedAt: new Date(),
          }
        }
      }
    }

    case UPDATE_ORDER_FAILED: {
      const { uuid, order } = action
      const offlineLocalIds = safeAddToArray(state.offlineLocalIds, uuid)

      return {
        ...state,
        byId: {
          ...state.byId,
          offlineLocalIds,
          [uuid]: {
            ...order,
            isSyncing: false,
          }
        }
      }
    }

    case VOID_ORDER_SUCCEEDED: {
      const id = action.orderId
      const order = state.byId[id]
      const voidResponse = action.freedomPayResponse

      if (isEmpty(order)) return state

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...order,
            voidResponse,
            voidedAt: new Date(),
            voidFailedAt: null,
          }
        }
      }
    }

    case VOID_ORDER_FAILED: {
      const id = action.orderId
      const order = state.byId[id]

      // transtype is SALE for void failures. not sure it matters but just in case...
      const voidResponse = {
        ...action.freedomPayResponse,
        transType: "VOID"
      }

      if (isEmpty(order)) return state

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...order,
            voidResponse,
            voidFailedAt: new Date(),
          }
        }
      }
    }

    case ADD_SIGNATURE_TO_ORDER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderUuid]: {
            ...state.byId[action.payload.orderUuid],
            receiptSignature: action.payload.signature,
          }
        }
      }

    case ADD_TIP_TO_ORDER: {
      const orderUuid = get(action, 'payload.orderUuid', '')
      const tipAmountInCents = get(action, 'payload.tipAmountInCents', 0)

      const order = get(state, `byId.${orderUuid}`, {})
      const amountInCents = get(order, `amountInCents`, 0)
      const newTotal = tipAmountInCents + amountInCents

      const { createOrderParams } = order
      // not handling split payment types.
      let newPayment = get(createOrderParams, ['payments', 0])
      if(!newPayment) newPayment = {}
      newPayment.amountInCents = newTotal

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderUuid]: {
            ...state.byId[orderUuid],
            amountInCents: newTotal,
            payments: [ newPayment ],
            tipAmountInCents: action.payload.tipAmountInCents,
          }
        }
      }
    }

    case PURGE_ORDERS: {
      return {
        ...state,
        byId: {
          ...omit(state.byId, action.payload.orderIds),
        },
        successRemoteIds: difference(state.successRemoteIds, action.payload.orderIds),
        purgedAt: new Date(),
      }
    }

    case PURGE_ALL_ORDERS: {
      return {
        ...state,
        byId: { },
        offlineCardIds: [ ],
        activeOfflineCardCount: 0,
        failureRemoteIds: [ ],
        offlineLocalIds: [ ],
        successRemoteIds: [ ],
        maintenancedAt: action.payload?.maintenancedAt ?? new Date(),
      }
    }

    case UPDATE_MAINTENANCE_TIMESTAMP: {
      return {
        ...state,
        maintenancedAt: action.payload.maintenanceTimestamp ?? new Date(),
      }
    }

    case SET_MAINTENANCE_ENABLED: {
      const maintenancedAt = action.payload.maintenanceEnabled ? new Date() : state.maintenancedAt

      return {
        ...state,
        maintenanceEnabled: action.payload.maintenanceEnabled,
        maintenancedAt: maintenancedAt
      }
    }

    case PERFORM_MAINTENANCE: {
      return {
        ...state,
        byId: {
          ...omit(state.byId, action.payload.orderIds),
        },
        offlineCardIds: difference(state.offlineCardIds, action.payload.orderIds),
        failureRemoteIds: difference(state.failureRemoteIds, action.payload.orderIds),
        offlineLocalIds: difference(state.offlineLocalIds, action.payload.orderIds),
        successRemoteIds: difference(state.successRemoteIds, action.payload.orderIds),
        maintenancedAt: action.payload?.maintenancedAt ?? new Date(),
      }
    }

    //This reducer is not updating order state since the saga is refetching the updated order
    case REQUEST_TABBED_REFUND: {
      return {
        ...state,
        mutationInProgress: true,
      }
    }

    case REQUEST_TABBED_REFUND_SUCCEEDED: {
      return {
        ...state,
        mutationInProgress: false,
      }
    }

    case REQUEST_TABBED_REFUND_FAILED: {
      return {
        ...state,
        mutationInProgress: false,
      }
    }

    case REQUEST_REFUND: {
      const orderId = get(action, 'payload.orderId', '')
      const refundItemIndexes = get(action, 'payload.itemIndexes', [])
      const orderMenuItems = [...get(state, `createOrderParams(byId.${orderId}).orderMenuItems`, [])]
      const newOrderMenuItems = [...orderMenuItems.map((item, index) => {
        const isRefundItem = refundItemIndexes.includes(index)
        if (isRefundItem) return { ...item, refund: true }
        return item
      })]
      const newItemModels = [...state.byId[orderId].itemModels.map((item, index) => {
        const isRefundItem = refundItemIndexes.includes(index)
        if (isRefundItem) return { ...item, refund: true }
        return item
      })]

      const refundable = !newItemModels.some((item) => !item.refund)

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            orderMenuItems: [...newOrderMenuItems],
            itemModels: newItemModels,
            refundable
          }
        },
      }
    }

    case LOAD_TAB_ORDERS_SUCCEEDED: {
      const orders = state.byId
      const preOrders = get(action, 'payload', {})

      const updatedLocalOrders = reduce(orders, (updatedOrders, order) => {

        if (!preOrders[order?.uuid]) return updatedOrders

        if (order?.orderState === INTERNAL_ORDER_STATES.CLOSED) {
          preOrders[order.uuid].balanceDueInCents = 0
        }

        updatedOrders[order?.uuid] = {
          ...orders[order?.uuid],
          ...(preOrders[order?.uuid] ?? {})
        }

        return updatedOrders
      }, {})

      const mergedOrders = { ...preOrders, ...updatedLocalOrders }

      keys(mergedOrders).forEach((orderId) => {
        const localItemModels = get(orders, `${orderId}.itemModels`, [])
        const remoteItemModels = get(preOrders, `${orderId}.itemModels`, [])

        // If this order doesn't have item models, or if there is no matching preorder, skip the next steps
        if (isEmpty(localItemModels) || isEmpty(remoteItemModels)) return

        let mergedItemModels = remoteItemModels.map((remoteItemModel) => {
          const maybedMatchItemModel = find(localItemModels, ['lineItemUuid', remoteItemModel.lineItemUuid])

          if (maybedMatchItemModel !== undefined && maybedMatchItemModel.refund === true) {
            return maybedMatchItemModel
          }

          if (maybedMatchItemModel !== undefined) {
            return remoteItemModel
          }

          return undefined
        })

        const cleanMergedItems = compact([ ...localItemModels, ...mergedItemModels ])

        mergedOrders[orderId].itemModels = uniqBy(cleanMergedItems, 'lineItemUuid')
        mergedOrders[orderId].syncedAt = new Date()
        mergedOrders[orderId].modifiedAt = mergedOrders[orderId].updatedAt || mergedOrders[orderId].modifiedAt
        mergedOrders[orderId].latestFromServer = true
      })

      // Reset the remote syncing data when all orders are pulled since this is a complete sync.
      return {
        ...state,
        byId: {
          ...state.byId,
          ...mergedOrders
        },
        orderDeltasSyncSuccessAt: null,
        unsyncedRemoteIds: [],
        preOrdersAreLoading: false,
        initialPreOrderLoadComplete: true
      }
    }

    case LOAD_TAB_ORDERS_FAILED: {
      return {
        ...state
      }
    }

    case LOAD_ORDER_DETAILS_SUCCEEDED: {
      const orders = state.byId
      let order = get(action, 'payload', {})
      order.latestFromServer = true
      order.isSyncing = false
      order.syncedAt = new Date()

      // if placed on the same device, we have some data stored locally that we want to keep around
      // TODO - need to rely on backend for payment types and such
      if (OrderStates.isRemovable(order.state)) {
        delete orders[order.uuid]
      } else {
        const localOrder = orders[order.uuid]

        if (localOrder.orderState === INTERNAL_ORDER_STATES.CLOSED) {
          order.balanceDueInCents = 0
        }

        orders[order.uuid] = {
          ...localOrder,
          ...order,
          createOrderParams: {
            ...createOrderParams(localOrder),
            state: order.state,
          },
          pendingMutation: undefined,
          wasCreatedInStadium: true
        }
      }

      let unsyncedRemoteIds = safeRemoveFromArray(state.unsyncedRemoteIds, order.uuid)

      return {
        ...state,
        byId: {
          ...state.byId
        },
        unsyncedRemoteIds
      }
    }

    case LOAD_ORDER_DETAILS_FAILED: {
      const orders = state.byId
      const orderUuid = get(action, 'payload.orderUuid', '')

      orders[orderUuid] = {
        ...orders[orderUuid],
        latestFromServer: false,
        isSyncing: false,
        syncedAt: null,
        pendingMutation: undefined,
      }

      return {
        ...state,
        byId: {
          ...state.byId
        }
      }
    }

    case STORE_ORDER_UUID_AND_ORDER_NUMBER: {
      const { orderUuid, orderNumber } = get(action, 'payload', '')

      return {
        ...state,
        storedOrderUuid: orderUuid,
        storedOrderNumber: orderNumber,
        byId: {
          ...state.byId
        }
      }
    }

    case CLEAR_STORED_ORDER_UUID_AND_ORDER_NUMBER: {
      return {
        ...state,
        storedOrderUuid: undefined,
        storedOrderNumber: undefined,
        byId: {
          ...state.byId
        }
      }
    }

    case SET_UPDATED_SINCE: {
      return {
        ...state,
        updatedSince: moment()
      }
    }

    case SET_ORDER_IN_PROGRESS: {
      return {
        ...state,
        orderInProgress: action.payload
      }
    }

    case UPDATE_ORDER_IN_PROGRESS: {
      return {
        ...state,
        orderInProgress: {
          ...state.orderInProgress,
          ...action?.payload,
        }
      }
    }

    case ADD_TIP_ORDER_IN_PROGRESS: {
      const tipAmountInCents = action?.payload ?? 0

      return {
        ...state,
        orderInProgress: {
          ...state.orderInProgress,
          tipAmountInCents: tipAmountInCents,
          balanceDueInCents: state?.orderInProgress?.balanceDueInCents + tipAmountInCents,
          amountInCents: state?.orderInProgress?.amountInCents + tipAmountInCents,
          hasTipApplied: true,
        }
      }
    }

    case CLEAR_ORDER_IN_PROGRESS: {
      return {
        ...state,
        orderInProgress: {}
      }
    }

    case ON_SAF_FULL_RESPONSE_RECEIVED: {
      const totalCount = getNumericSafValue(action?.payload?.SAFTotalCount)

      if (totalCount < 0) {
        return state
      }

      const uploadedCount = getNumericSafValue(action?.payload?.SAFUploadedCount)

      if (uploadedCount < 0) {
        return {
          ...state,
          activeOfflineCardCount: totalCount,
        }
      }

      return {
        ...state,
        activeOfflineCardCount: Math.max(totalCount - uploadedCount, 0),
      }
    }

    case ON_RESET_SAF_FULL_RESPONSE: {
      // Reset the active offline card count when resetting the SAF response.
      // If the offline card count is undfined, set it to 0 when resetting the card reader response;
      // otherwise, set it to the current active offline card count.
      // The active offline card count differs from the managed array.
      //   * The managed array is used to track orders which potentially need status updates.
      //   * The active offline card count is used to track the number of cards on the card reader
      //     which have yet to be synced. It will either align with the count of offline orders
      //     (i.e. the count of orders in the managed array) or be the result from the card reader.
      return {
        ...state,
        activeOfflineCardCount: state?.activeOfflineCardCount ?? 0,
      }
    }

    case ADD_ITEMS_TO_TAB : {
      return {
        ...state,
        mutationInProgress: true,
      }
    }

    case ADD_ITEMS_TO_TAB_SUCCEEDED : {
      const { orderId, userNotes } = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            userNotes: userNotes,
            createOrderParams: {
              ...createOrderParams(state.byId[orderId]),
              userNotes: userNotes,
            }
          }
        },
        mutationInProgress: false,
      }
    }

    case ADD_ITEMS_TO_TAB_FAILED : {
      return {
        ...state,
        mutationInProgress: false,
      }
    }

    case RESET_MUTATION_IN_PROGRESS : {
      return {
        ...state,
        mutationInProgress: false,
      }
    }

    case ADD_ITEMS_TO_ORDER: {
      const { orderMenuItems, itemModels, orderId, subtotalAmountInCents, taxAmountInCents, amountInCents, balanceDueInCents, promotions, payments } = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            subtotalAmountInCents: state.byId[orderId].subtotalAmountInCents + subtotalAmountInCents,
            taxAmountInCents: state.byId[orderId].taxAmountInCents + taxAmountInCents,
            amountInCents: state.byId[orderId].amountInCents + amountInCents,
            balanceDueInCents: state.byId[orderId].balanceDueInCents + balanceDueInCents,
            createOrderParams: {
              ...createOrderParams(state.byId[orderId]),
              orderMenuItems: [...createOrderParams(state.byId[orderId]).orderMenuItems, ...orderMenuItems],
              promotions,
              payments,
            },
            itemModels: [...state.byId[orderId].itemModels, ...itemModels],
            payments,
          }
        },
      }
    }

    case REPLACE_ITEMS_FROM_ORDER: {
      const { orderId, newOrderMenuItems, newItemModels } = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            createOrderParams: {
              ...createOrderParams(state.byId[orderId]),
              orderMenuItems: newOrderMenuItems
            },
            itemModels: newItemModels
          }
        },
      }
    }

    case UPDATE_ORDER_TOTALS: {
      const { orderId, subtotalAmountInCents, taxAmountInCents, amountInCents, balanceDueInCents } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            subtotalAmountInCents,
            taxAmountInCents,
            amountInCents,
            balanceDueInCents,
          }
        },
      }
    }

    case REMOVE_QR_PAYMENT_AND_UNSYNCABLE_STATUS: {
      const { orderId, balanceDueInCents } = action.payload

      //store previous values
      const previousUnsyncableStatus = {
        orderState: state.byId[orderId]?.orderState,
        errorMessage: state.byId[orderId]?.errorMessage,
        balanceDueInCents: state.byId[orderId]?.balanceDueInCents,
        payments: [...state.byId[orderId]?.payments || []]
      }

      //remove QR pay from the payments array, if not removed it'll get repushed to stadium and it'll fail
      const payments = state.byId[orderId]?.payments?.filter(payment => payment.paymentType !== 'wallet_nonce')

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            orderState: 'open',
            unsyncable: false,
            errorMessage: "",
            balanceDueInCents,
            payments,
            previousUnsyncableStatus
          }
        },
        QRPayAuthError: false
      }
    }

    case RESTORE_QR_PAYMENT_AND_UNSYNCABLE_STATUS: {
      const orderId = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            orderState: state.byId[orderId]?.previousUnsyncableStatus.orderState,
            unsyncable: true,
            errorMessage: state.byId[orderId]?.previousUnsyncableStatus.errorMessage,
            balanceDueInCents: state.byId[orderId]?.previousUnsyncableStatus.balanceDueInCents,
            payments: state.byId[orderId]?.previousUnsyncableStatus.payments,
          }
        }
      }
    }

    case SET_ORDER_IN_PROGRESS_WITH_ID: {
      const orderId = action.payload

      const orderInProgress = {...state.byId[orderId]}

      return {
        ...state,
        orderInProgress: orderInProgress
      }
    }

    case SET_QR_PAY_AUTH_ERROR: {

      return {
        ...state,
        QRPayAuthError: action.payload
      }
    }

    case DUPLICATE_ORDER_WITH_NEW_IDS: {
      const { oldOrderId, newOrderId, newOrderNumber } = action.payload
      const offlineLocalIds = safeRemoveFromArray(state.offlineLocalIds, oldOrderId)

      return {
        ...state,
        byId: {
          ...state.byId,
          [newOrderId]: {
            ...state.byId[oldOrderId],
            uuid: newOrderId,
            createOrderParams: {
              ...createOrderParams(state.byId[oldOrderId]),
              uuid: newOrderId,
              orderNumber: newOrderNumber
            },
            orderNumber: newOrderNumber,
            isSyncing: false,
            previousUnsyncableStatus: null,
          }
        },
        offlineLocalIds,
      }
    }

    case ORDER_REPORTS_LAST_FETCHED_AT: {
      return {
        ...state,
        orderReportsLastFetchedAt: action.payload ?? moment().format('MM/DD/YY h:mm A'),
      }
    }

    default:
      return state

  }
}

export default reducer
