import axios from 'axios'
import { API_ENDPOINT } from '../constants'
import { decamelizeKeys } from 'humps'
import { isEmpty, parseJWT } from '../VNUtil/VNUtils'
import Portico from '@ordernext/networking-stadium/portico'
import v4 from 'uuid/v4'

/**
 * Get PPI configurations given the org and venue.
 * Documentation: https://docs.google.com/document/d/1S9Li3l4qSDDtl1i_nqOdGOQdJ2enURzeXBI44j8KcP4/edit#heading=h.t2s3fe6ntoho
 * @returns {Axios Response Object} - Returns the response from VNAPI PPI /configurations
 */
export const apiGetPpiConfigurations = () => {
  return axios.get(`${API_ENDPOINT}/ppi/configurations?venue=${process.env.REACT_APP_VENUE_UUID}`)
}

/**
 * Make a VNAPI PPI Proxy call to the API Functionality that you want. See VNAPI PPI Documentation for what is avialable per integration.
 * Documenation: https://docs.google.com/document/d/1S9Li3l4qSDDtl1i_nqOdGOQdJ2enURzeXBI44j8KcP4/edit#heading=h.t2s3fe6ntoho
 * @param {String} apiFunc - Which api functionality within PPI do you need to connect with? Examples: getAmenities, getDiscounts, reportOrder, etc.
 * @param {String} integration - What integration (FANMAKER, etc) do you want to call VNAPI ppi proxy with?
 * @param {Object} transaction - The transaction that you want applied to the apiFunc and integration.
 * @returns {Axios Response Object} - Returns the response from VNAPI PPI /proxy.
 */
export const apiPostPpiProxy = (apiFunc, integration, transaction) => {
  return axios.post(`${API_ENDPOINT}/ppi/proxy`, {
    apiFunc: apiFunc,
    integration: integration,
    transaction: transaction,
    venue: process.env.REACT_APP_VENUE_UUID
  })
}

/**
 * Make chained VNAPI PPI Proxy calls in order, synchronously. 
 * Documentation: https://docs.google.com/document/d/1S9Li3l4qSDDtl1i_nqOdGOQdJ2enURzeXBI44j8KcP4/edit#heading=h.t2s3fe6ntoho
 * @param {Array} apiFuncs - An ordered array of which apiFuncs within PPI that need to be hit. ['getAmenities', 'getDiscounts']
 * @param {String} integration - What integration (FANMAKER, etc) do you want to call VNAPI ppi proxy with?
 * @param {Object} transactions - An keyed object of transactions to supply to each service call. { 'getAmenities' : {...}, 'getDiscounts': {...} }
 * @returns {Array} Returns an array of responses, body, in order, of each API call.
 */
export const apiMakeChainServicesPpiProxyCalls = async (apiFuncs, integration, transactions) => {
  let responses = []
  for (let i = 0; i < apiFuncs.length; i++) {
    let response
    try {
      
      response = await apiPostPpiProxy(apiFuncs[i], integration, transactions[apiFuncs[i]])

      if (response.data) {
        response = response.data
      }
    } catch(e) {
      response = new Error(e.response?.data?.error)
    }
    responses.push(response)
  }
  return responses
}

/**
 * A temporary spot to check and apply expired portico tokens if PPI needs to handle this
 */
export const apiCheckAndApplyExpiredPorticoToken = async () => {
  
  // need to check JWT expiration
  const parsedToken = parseJWT(localStorage.getItem('accessToken'))
  const tokenExpirationMS = parsedToken.exp * 1000
  const timeThreshold = 30000 // 30 seconds

  // is this too closed to be expired
  if (Date.now() - tokenExpirationMS - timeThreshold <= 0) {
    
    // we need to get a new token
    const deviceId = window.localStorage.device_id
    const appKey = process.env.REACT_APP_OIDC_CLIENT_ID
    const organizationName = process.env.REACT_APP_ORG

    const response = await Portico.deviceLogin(deviceId, appKey, organizationName)

    if (response?.data && response.data.accessToken) {
      localStorage.setItem('accessToken', response.data.accessToken)
    }
  }
}

/**
 * Direct call to orders without going through the saga
 * @param {Array} promotions - Array of all promotions
 * @param {Array} orderMenuItems - formatItems(orderMenuItems)
 * @param {String} standMenuUuid - The stand menu uuid
 * @param {Boolean} isKiosk - is this kiosk or not
 * @param {String} orderUuid - The order UUID is required
 * @returns 
 */
export const apiMakePosOrdersTotal = async (promotions, orderMenuItems, standMenuUuid, isKiosk, orderUuid, isTabbed) => {
  // if there are no items, do not attempt total request
  if (isEmpty(orderMenuItems)) return Promise.reject()
  
  await apiCheckAndApplyExpiredPorticoToken()

  const path = isKiosk ? 'kiosk/v3/orders' : 'pos/orders'

  let requestData = {
    affiliations: [],
    stand_menu_uuid: standMenuUuid,
    uuid: orderUuid
  }

  if (isTabbed) {
    // generate a new uuid for tabbed orders
    requestData.uuid = v4()
  }

  if (orderMenuItems) {
    requestData.order_menu_items = decamelizeKeys(orderMenuItems)
  }

  if (promotions) {
    requestData.promotions = decamelizeKeys(promotions)
  }

  return axios.post(`${process.env.REACT_APP_BASE_URL}/${path}/total`, requestData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
      Expires: 0
    }
  })
}