import { reduce } from 'lodash'
import { getDeviceId } from './deviceData'

export const incrementNextOrderNumber = () => {
  const rangeMin = parseInt(localStorage.getItem('orderNumberMin')) || 1000
  const rangeMax = parseInt(localStorage.getItem('orderNumberMax')) || 1999

  let oldId = parseInt(localStorage.getItem('posOrderNumber')) || rangeMin

  // if it's out of range, let's assume the range has changed after a re-registration
  if (oldId < rangeMin || oldId > rangeMax) oldId = rangeMin

  // rollover / reset
  let newId = oldId + 1
  if (newId > rangeMax) newId = rangeMin

  localStorage.setItem('posOrderNumber', newId)
}

export const nextPosOrderNumber = () => {
  const rangeMin = parseInt(localStorage.getItem('orderNumberMin')) || 1000

  return `${parseInt(localStorage.getItem('posOrderNumber')) || rangeMin}`
}

// Pad a number with leading zeros.
const padString = (str, length) => {
  let padded = str

  if (!str) {
    padded = ''
  }

  while (padded.length < length) {
    padded = `0${padded}`
  }

  return padded
}

// orderNumber : The order number as specified within the bounds of this device's order number
//     range.
// returns : An invoice number that can track the order number and device number within a payment
//     processor's system.

const hexKey = {
  a: '10',
  b: '11',
  c: '12',
  d: '13',
  e: '14',
  f: '15',
}

const convertToBaseTen = (deviceId) => {
  return reduce(deviceId?.toLowerCase().split(''), (convertedId, symbol) => convertedId + (hexKey[symbol] ?? symbol), '')
}

export const invoiceNumber = (orderNumber) => {
  const deviceId = convertToBaseTen(getDeviceId())
  return padString(deviceId.slice(-4), 4) + padString(orderNumber.slice(-6), 6)
}
