import { isEmpty } from 'lodash'
import { MENUS_SUCCEEDED } from '../actions/menus'

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MENUS_SUCCEEDED: {
      const stands = payload?.data?.stands ?? []
      const stand = stands?.[0]
      const venue = stand?.venue ?? {}

      const returnState = {
        ...state,
      }

      if (!isEmpty(stand?.venue)) {
        returnState.currentVenue = venue
      }

      return returnState
    }
    

    default:
      return state
  }
}
