export const FETCH_PROMOTIONS_REQUESTED = 'FETCH_PROMOTIONS_REQUESTED'
export const FETCH_PROMOTIONS_SUCCEEDED = 'FETCH_PROMOTIONS_SUCCEEDED'
export const FETCH_PROMOTIONS_FAILED = 'FETCH_PROMOTIONS_FAILED'
export const ADD_PROMOTIONS_TO_ORDER = 'ADD_PROMOTIONS_TO_ORDER'
export const CLEAR_PROMOTIONS_ON_ORDER = 'CLEAR_PROMOTIONS_ON_ORDER'


export const fetchPromotions = (payload) => ({
  type: FETCH_PROMOTIONS_REQUESTED,
  payload
})

fetchPromotions.type = FETCH_PROMOTIONS_REQUESTED

export const fetchPromotionsSucceeded = (payload) => ({
  type: FETCH_PROMOTIONS_SUCCEEDED,
  payload
})

fetchPromotionsSucceeded.type = FETCH_PROMOTIONS_SUCCEEDED

export const fetchPromotionsFailed = (payload) => ({
  type: FETCH_PROMOTIONS_FAILED,
  payload
})

fetchPromotionsFailed.type = FETCH_PROMOTIONS_FAILED

export const addPromotionsToOrder = (payload) => ({
  type: ADD_PROMOTIONS_TO_ORDER,
  payload
})

addPromotionsToOrder.type = ADD_PROMOTIONS_TO_ORDER

export const clearPromotionsOnOrder = (payload) => ({
  type: CLEAR_PROMOTIONS_ON_ORDER,
  payload
})

clearPromotionsOnOrder.type = CLEAR_PROMOTIONS_ON_ORDER
