import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import SectionCard from './SectionCard'
import QRCode from './QRCode'

import styles from './QRCodePayment.module.scss'
import androidIcon from '../assets/icons/android-icon.svg'
import iosIcon from '../assets/icons/ios-icon.svg'

const QRCodePayment = ({ title, qrCodeData, className }) => {
  const classNames = cn(styles.qrCodePayment, className)

  return (
    <SectionCard title={title} className={classNames}>
      <div className={styles.qrCodeContainer}>
        <QRCode data={qrCodeData} />
        <div className={styles.qrCodeFooter}>
          <img className={styles.androidIcon} src={androidIcon} alt="Android" />
          <img className={styles.iosIcon} src={iosIcon} alt="iOS" />
          <span className={styles.scan}>scan with camera</span>
        </div>
      </div>
    </SectionCard>
  )
}

QRCodePayment.defaultProps = {
  title: '',
  qrCodeData: '',
}

QRCodePayment.propTypes = {
  title: PropTypes.string,
  qrCodeData: PropTypes.string,
}

export default memo(QRCodePayment)
