/* eslint-disable react-hooks/exhaustive-deps */
// TODO: Move these event listeners into a saga and pull the status from the store
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, some } from 'lodash'

import { logout } from '../actions/attendant'

import NetworkIndicator from '../components/NetworkIndicator'
import { networkSignal } from '../utils/networkConnected'

const locationBlackList = ['card-reader', 'split-tender', 'ticket', 'signature', 'rich-checkout', 'qr-pay', 'tender-selection']

const NetworkIndicatorWrapper = ({ text, onClick, className }) => {
  const [speed, setSpeed] = useState(0)
  const pingRef = useRef()

  const startPinging = () => {
    stopPinging()
    const ping = () => {
      networkSignal(setSpeed)
    }

    ping()
    pingRef.current = setInterval(ping, 10000)
  }

  const stopPinging = () => {
    clearInterval(pingRef.current)
    pingRef.current = undefined
  }

  useEffect(() => {
    startPinging()

    return () => {
      stopPinging()
    }
  }, [])

  return <NetworkIndicator speed={speed} text={text} onClick={onClick} className={className} />
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const push = get(ownProps, 'history.push', () => {})

  return {
    logout: () => dispatch(logout()),
    onClick: () => {
      if (!some(locationBlackList, (pathname) => ownProps?.location?.pathname?.includes(pathname))) {
        push('/debug-info')
      }
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(NetworkIndicatorWrapper))
