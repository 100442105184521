import { get, isEmpty, join, map, sumBy } from 'lodash'
import { Decimal } from 'decimal.js'

export const formatCartItemModifiers = (modifierItems, notes) => {
  const modifierStrings = map(modifierItems, (modifierItem) => {
    const name = get(modifierItem, 'name', '')
    const priceInCents = get(modifierItem, 'defaultPriceInCents', 0)

    if (priceInCents > 0) {
      return `${name} +$${(priceInCents / 100).toFixed(2)}`
    } else {
      return name
    }
  })

  let result = join(modifierStrings, ', ')

  if (!isEmpty(result) && !isEmpty(notes)) result += '. '
  if (!isEmpty(notes)) result += notes

  return result
}

/**
 * NOTE (@westinschepper): This is a globalpay only requirment. The Terminal id
 * Field can only be numeric values.
 */
export const getDeviceSerial = () => {
  let deviceId = localStorage?.getItem('device_id')

  return deviceId?.replace(/\D/gi, '')
}

export const centsToDollars = (cents) => {
  let result = `$${(Math.abs(cents) / 100).toFixed(2)}`
  if (cents < 0) result = `-${result}`

  return result
}

/**
 * return string amount in cents without rounding down or up | This will return any negative values positive
 * @param {String} stringAmount an amount $1.00 or 1.00 
 */
export const stringToCents = (stringAmount) => {
  const regex = /[^a-zA-Z0-9.]/g // removes everything but period and number
  const stringToCents = new Decimal(stringAmount.replace(regex, '')).mul(100).toNumber()

  return parseInt(stringToCents)
}

export const toFixed = (number, digits) => {
  const regex = /^[0]{0,}\./
  let result = number
  if (parseInt(number) !== number) {
    result = parseFloat(number.toFixed(digits))
  }

  return result.toString().replace(regex, '.')
}

export const stringFloatToCents = (stringFloat) => {
  // The leading negative shouldn't be removed, as it is part of the value.
  const isNegative = (stringFloat && stringFloat?.length != 0) ? (stringFloat.trim().charAt(0) == '-') : false
  const multiplier = isNegative ? -1 : 1

  return multiplier * parseInt(stringFloat?.replace?.(/\D/g,'') ?? 0)
}

export const centsToFloat = (cents) => (cents / 100).toFixed(2)

export const getTicketIdFromResult = (barCodeData) => {
  const formattedData = barCodeData?.split('|')?.reduce((dataObj, keyValue) => {
    const [key, value] = keyValue?.split('=') ?? []
    return {
      ...dataObj,
      [key]: value,
    }
  }, {}) ?? {}
  return formattedData['TBN']
}

export const displayPreOrdersItemPrice = (cartItem, modifiers) => {
  const basePrice = get(cartItem, 'price', "0.00")
  const priceInCents = stringFloatToCents(basePrice)

  let totalModPrice = 0
  modifiers.forEach(mod => {
    if (mod.price) {
      totalModPrice += stringFloatToCents(mod.price)
    }
  })

  const price = priceInCents + totalModPrice

  return `$${(price  / 100).toFixed(2)}`
}

export const displayPrice = (cartItem, modifiers) => {
  const basePrice = get(cartItem, 'priceInCents', 0)
  const modifierPrice = sumBy(modifiers, (mod) => get(mod, 'defaultPriceInCents', 0))
  const price = basePrice + modifierPrice

  return `$${(price  / 100).toFixed(2)}`
}