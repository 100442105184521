import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { map, isEmpty, filter, isEqual } from "lodash"

import cn from "classnames"

import PropTypes from "prop-types"

import CloudOffIcon from '@material-ui/icons/CloudOff'

import { VNDialog } from '../VNCustomerFacingDisplay/components/VNDialog'

import CartItem from "./CartItem"
import DropdownSelector from "./DropdownSelector"
import styles from "./CartItems.module.scss"

import { ReactComponent as CartIcon } from "../assets/icons/EmptyCart.svg"

import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import useOfflineModal from "../hooks/useOfflineModal"
import useGetSeats from '../hooks/useGetSeats'

import { getNetworkAvailableValue } from '../utils/networkConnected'
import { createOrderParams } from '../utils/orderUtils'

import ConfirmModalV2 from '../components/ConfirmModalV2'

import { sendCFDScreenNav, sendUpdateCart } from "../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend"
import { getDeviceMode } from "../VNMode/Selectors"
import { MODES } from "../VNMode/Reducer"
import { CFD_SCREEN } from "../VNCustomerFacingDisplay/Reducer"
import { getIsLocationPickerActive } from "../VNLocationPicker/Selectors"

import { getOrderInProgress } from "../selectors/order"
import { isTabbed } from "../utils/orderStates"
import { ReactComponent as AddItemIcon } from '../assets/icons/add-item.svg'
import Button from "./Button"
import { getTabsModeEnabled } from "../selectors/menus"

const CartItems = ({
  cartItems,
  onRemove,
  onSelect,
  newItemFocus,
  clearFocus,
  addPromotionsToOrder,
  total,
  history,
  className,
  discountsAvailable,
  discounts,
  cartNotes,
  clearPromotions,
  addNotesToCart,
  currentStandUuid,
  remoteOrderTotalLoading,
  clearOrderInProgress,
  clearItemsAndPromotions,
}) => {
  const classNames = cn(styles.cartItems, className);
  const [checkIfOfflineWithCallback, offlineModal] = useOfflineModal()
  const cfdMode = useSelector(state => getDeviceMode(state))
  const isLocationPickerActive = useSelector(state => getIsLocationPickerActive(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))

  const [localCartItems, setLocalCartItems] = useState({})
  const [localDiscounts, setLocalDiscounts] = useState({})
  const isMobileViewPort = useGetIsMobileScreen()
  const [showLocationModal, setShowLocationModal] = useState(false)
  const [section, setSection] = useState('')
  const [isSuite, setIsSuite] = useState(false)
  const [row, setRow] = useState('')
  const [seat, setSeat] = useState('')
  const networkAvailable = getNetworkAvailableValue()
  const tabsModeEnabled = useSelector(state => getTabsModeEnabled(state))
  
  const [ getSections, getRows, getSeats, {
    sections, rows, seats,
    seatsLoading, seatsSucceeded, seatsFailed
  } ] = useGetSeats()
  const localSections = useMemo(() => sections?.map((section) => section.name), [sections])

  let emptyElement = null;

  const updateTheCartWithTheLatest = () => {
     // save it
     setLocalCartItems(cartItems)
     setLocalDiscounts(discounts)

     const mappedDiscounts = discounts.map((discount) => {
       discount.type = 'discount'
       return discount
     })

     sendUpdateCart([...cartItems, ...mappedDiscounts])
  }

  useEffect(() => {
    if (networkAvailable) {
      getSections(currentStandUuid)
    }
  }, [networkAvailable])

  useEffect(()=> {
    if (isEmpty(cartItems)) {
      clearPromotions()
    }

    if (!isEmpty(orderInProgress)) {
      addPromotionsToOrder(createOrderParams(orderInProgress)?.promotions)
    }
  }, [cartItems.length])

  useEffect(() => {
    if (seatsLoading || !section || !sections || sections.length === 0) {
      return
    }

    const suite = sections.find((storedSection) => storedSection.name === section)?.isSuite

    setIsSuite(suite)
  }, [section])


  useEffect(() => {
    if (seatsLoading || !row || !rows || rows.length === 0) {
      return
    }

    getSeats(section, row)
  }, [row])

  // TODO: (PLOETZ) - Prevent this component from constantly re-rendering (one of offenders is NetworkIndicator.js)
  useEffect(() => {
    if (cfdMode === MODES.POS) {

      // we only care about when the cart items actually update
      if (!isEqual(localCartItems, cartItems) || !isEqual(localDiscounts, discounts)) {

        // if this is ever called, usually on initialization with nothing, we don't need to do anything
        if (isEmpty(cartItems) && isEmpty(localCartItems)) {
          return
        }

        // going from something in the cart to empty
        if (isEmpty(cartItems) && !isEmpty(localCartItems)) {
          sendCFDScreenNav(CFD_SCREEN.IDLE)
          updateTheCartWithTheLatest()
          return
        }

        // we have something new in the cart, go to the cart screen and update the data
        if (!isEqual(localCartItems, cartItems)) {
          sendCFDScreenNav(CFD_SCREEN.CART)
          updateTheCartWithTheLatest()
          return
        }

        // the discounts updated, update the cart, but don't navigate
        if (!isEqual(localDiscounts, discounts)) {
          updateTheCartWithTheLatest()
          return
        }
      }
    }
  }, [cartItems, discounts])

  if (isEmpty(cartItems)) {

    const getEmptyCart = () => {
      if (!isTabbed(orderInProgress)) {
        return <p>Your cart is empty</p>;
      }
      return (
        <>
          <p>Add to Tab or</p>
          <p
            className={styles.startNewTab}
            onClick={() => {
              clearOrderInProgress()
            }}
          >
            Start New Order
          </p>
        </>
      );
    };

    return (
      <div className={classNames}>
        <div className={styles.empty}>
          <CartIcon />
          {getEmptyCart()}
        </div>
      </div>
    )
  }

  const seatSelectorContent = (
    <div style={{paddingRight: 8, paddingLeft: 16}}>
      <DropdownSelector
        autocompleteLabel={'Section'}
        autocompleteOptions={localSections}
        onAutocompleteChange={(inputValue) => {
          setSection(inputValue)
          setRow('')
          setSeat('')
          getRows(inputValue)
        }}
        textInputVariant={'filled'}
        disabled={seatsLoading}
      />
      {
        !isSuite &&
        <>
          <DropdownSelector
            autocompleteLabel={'Row'}
            textInputVariant={'filled'}
            autocompleteOptions={rows}
            onAutocompleteChange={(inputValue) => {
              setRow(inputValue)
            }}
            disabled={isSuite || seatsLoading || !section}
          />
          <DropdownSelector
            autocompleteLabel={'Seat'}
            textInputVariant={'filled'}
            autocompleteOptions={seats}
            onAutocompleteChange={(inputValue) => {
              setSeat(inputValue)
            }}
            disabled={isSuite || seatsLoading || !row}
          />
        </>
      }
    </div>)

  const discountsSubtext = isMobileViewPort
    ? "Discounts are not supported while the device is offline."
    : "Discounts are not supported while the\ndevice is offline."

  const locationSubtext = isMobileViewPort ?
      "Location selection is not supported while the device is offline." :
      "Location selection is not supported\nwhile the device is offline."

  return (
    <div className={classNames}>
      {emptyElement}

      {!networkAvailable && showLocationModal &&<ConfirmModalV2
        icon={<CloudOffIcon />}
        headerText={null}
        onButtonTwoClick={() => setShowLocationModal(false)}
        subtext={'Location selection is not supported while the device is offline.'}
        singleButtonOnly={true}
        buttonTwoText={'Okay'}
      />}

      {networkAvailable && <VNDialog
        open={showLocationModal}
        subTitle={'Location'}
        label= {'Location'}
        showTextfield={false}
        height={isSuite ? '36%' : '55%'}
        width={'32.5%'}
        primaryButtonText={'DONE'}
        customContent={seatSelectorContent}
        primaryButtonDisabled={
          isSuite ?
          !section :
          !section || !seat || !row
        }
        onPrimaryButtonClick={() => {
          if (isSuite && !section) {
            return
          } else if (!isSuite &&(!section || !row || !seat)) {
            return
          }

          if (isSuite) {
            addNotesToCart(`Suite ${section}`)
          } else {
            addNotesToCart(`Section ${section},\nRow ${row},\nSeat ${seat}`)
          }
          setRow('')
          setSeat('')
          setSection('')
          setIsSuite(false)
          setShowLocationModal(false)
        }}
        secondaryButtonText={'CANCEL'}
        onSecondaryButtonClick={() => {
          setRow('')
          setSeat('')
          setSection('')
          setIsSuite(false)
          setShowLocationModal(false)
        }}
        fullWidth
     />}

      {map(cartItems, (cartItem, index, items) => (
        <CartItem
          key={`${cartItem.id}-${index}`}
          {...cartItem}
          modifiers={cartItem.modifiersString}
          onRemove={() => onRemove(index)}
          onSelect={() => {
            if (remoteOrderTotalLoading) return
            onSelect(index)
          }}
          className={styles.cartItem}
          focusItem={newItemFocus && index === items.length - 1}
          clearFocus={clearFocus}
          remoteOrderTotalLoading={remoteOrderTotalLoading}
        />
      ))}
      {
        cartNotes &&
        <CartItem
          key={`${cartNotes}`}
          onRemove={() => addNotesToCart(null)} // reset to default
          className={styles.cartItem}
          isDiscount={false}
          isLocation={true}
          name={`Location: ${cartNotes}`}
        />
      }
      {map(discounts, (discount, index, discounts) => (
        <CartItem
          key={`${discount.uuid}-${index}`}
          onRemove={() => {
            const selectedDiscounts = filter(
              discounts,
              (selectedDiscount) => selectedDiscount.uuid !== discount.uuid
            );
            addPromotionsToOrder(selectedDiscounts);
          }}
          className={styles.cartItem}
          isDiscount={true}
          {...discount}
        />
      ))}
      <div className={styles.totalContainer}>
        <span className={styles.total}>{total}</span>
      </div>
      {discountsAvailable && !tabsModeEnabled && (
        <div className={styles.addDiscount}>
          <span className={styles.discounts}>Discounts</span>
          <AddItemIcon
            className={styles.addButton}
            onClick={() => {
              checkIfOfflineWithCallback(() => history.push("/concession-order/select-discount"), discountsSubtext, false)
            }}
          />
        </div>
      )}
      {
        !cartNotes && isLocationPickerActive &&
        <div className={styles.addDiscount}>
          <span className={styles.discounts}>Location</span>
          <AddItemIcon
            className={styles.addButton}
            onClick={() => {
              checkIfOfflineWithCallback(() => {
                if (isMobileViewPort) {
                  history.push("/concession-order/select-location")
                } else {
                  setShowLocationModal(true)
                }
              }, locationSubtext, false)
            }}/>
        </div>
      }
      <div className={styles.clearButtonContainer}>
        <Button className={styles.clearButton} onClick={clearItemsAndPromotions}>
          Clear Cart
        </Button>
      </div>
      {offlineModal}
    </div>
  );
};

CartItems.propTypes = {
  cartItems: [],
  onRemove: () => {},
  onSelect: () => {},
};

CartItems.propTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  clearOrderInProgress: PropTypes.func
};

export default CartItems;
