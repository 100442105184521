import generateUuid from 'uuid/v4'

export const getRemoteOrderTotal = (state) => {
  return state?.orderTotalRemote
}

// returns remoteOrderTotal uuid if one exists
// then if a order in progress, exists, return that uuid
// then if none exists, returns passed in order uuid
// and then if none of the above exists, returns a new generated uuid
export const getOrderSubumissionUuid = (state, alternativeOrderUuid) => {
  const remoteOrderTotal = state?.orderTotalRemote ?? {}
  const orderInProgress = state?.order?.orderInProgress ?? {}
  if (remoteOrderTotal.uuid) return remoteOrderTotal.uuid
  if (orderInProgress.uuid) return orderInProgress.uuid
  if (alternativeOrderUuid) return alternativeOrderUuid
  return generateUuid()
}
