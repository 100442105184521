import React from 'react'
import cn from 'classnames'

import styles from './VNBottomButton.module.scss'
import Button from './Button'

const VNBottomButton = ({ className, text, onClick, disabled, higherPosition, ...props }) => {
  const classNames = cn(styles.vnBottomButton, className)
  const style = {bottom: higherPosition ? '7.031vh' : ''}
  return (
    <Button className={classNames} {...props} disabled={disabled} onClick={onClick} style={style}>{text}</Button>
  )
}

export default VNBottomButton
