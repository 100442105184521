export const USER_PROGRESS = {
  CARD_PAYMENT: {
    CUSTOMER_TIP: {
      title: "Customer Entering Tip on {AMOUNT}",
      index: 1
    },
    PAYING_CARD: {
      title: "Customer Paying {AMOUNT} With Card",
      index: 2
    },
    AUTHORIZED_CARD: {
      title: "Card Authorized for {AMOUNT}",
      index: 2
    },
    PAYMENT_FINISHED: {
      VIEWING_RECEIPT: {
        title: "Customer Viewing Receipt",
        index: 3,
        flow: 'VIEWING_RECEIPT'
      },
      TRANSACTION_COMPLETE: {
        title: "Transaction Complete",
        index: 4,
        flow: 'TRANSACTION_COMPLETE'
      }
    },
    ERROR: {
      title: "Card Reader Status", 
      subTitle: `Card Declined\nError Code:`
    }
  },
  RICH_CHECKOUT: {
    CUSTOMER_SCANNING: {
      index: 1,
      title: "Customer Scanning QR to Pay {AMOUNT}"
    },
    CUSTOMER_PAYING: {
      index: 2,
      title: "Customer Paying {AMOUNT} on Phone"
    },
    PAYMENT_FINISHED: {
      VIEWING_RECEIPT: {
        title: "Customer Viewing Receipt",
        index: 2
      },
      TRANSACTION_COMPLETE: {
        title: "Transaction Complete",
        index: 3
      }
    },
  }
}

export const CFD_POS_PAYMENT_FLOWS = {
  UNSET: 'UNSET',
  SPLIT_PAY: 'SPLIT_PAY',
  QR_PAY: 'QR_PAY',
  TICKET: 'TICKET',
  GIFT_CARD: 'GIFT_CARD',
  CREDIT_CARD_AUTHORIZATION: 'CREDIT_CARD_AUTHORIZATION',
}

export const CFD_SCREEN = {
  IDLE: 'IDLE',
  CART: 'CART',
  TRANSACTION_SUMMARY: 'TRANSACTION_SUMMARY',
  RECEIPT: 'RECEIPT',
  PROCESSING: 'PROCESSING',
  TRANSACTION_ERROR: 'TRANSACTION_ERROR',
  RICH_CHECKOUT_SCAN_QR_CODE: 'RICH_CHECKOUT_SCAN_QR_CODE',
  RICH_CHECKOUT_FINISH_TRANSACTION_ON_PHONE: 'RICH_CHECKOUT_FINISH_TRANSACTION_ON_PHONE',
  RICH_CHECKOUT_SHOW_RECEIPT: 'RICH_CHECKOUT_SHOW_RECEIPT',
  SPLIT_PAY_WAITING_FOR_ATTENDANT: 'SPLIT_PAY_WAITING_FOR_ATTENDANT',
  PRESENT_QR_PAY_CODE: 'PRESENT_QR_PAY_CODE',
  BALANCE_DUE: 'BALANCE_DUE',

  /// OLD
  CARD_DECLINED: 'CARD_DECLINED',
  RICH_CHECKOUT_QR_CODE: 'RICH_CHECKOUT_QR_CODE',

}

export const CFD_EVENTS = {
  IDLE: 'IDLE',
  CUSTOMER_WAITING_FOR_ATTENDANT: 'CUSTOMER_WAITING_FOR_ATTENDANT',
  CUSTOMER_DONE_WITH_RECEIPT: 'CUSTOMER_DONE_WITH_RECEIPT',
  CC_PAYMENT_COMPLETE: 'CC_PAYMENT_COMPLETE'
}