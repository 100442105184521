import { get, reduce } from 'lodash'

export const calculateSubtotal = (cartItems) => {
  const total = reduce(cartItems, (accumulator, item) => {
    const quantity = get(item, 'quantity', 0)
    const itemModifiers = get(item, 'modifiers', [])
    const itemSubtotal = get(item, 'defaultPriceInCents', 0) * quantity

    const modifiersSubtotal = reduce(itemModifiers, (acc, modifierItem) => {
      const priceInCents = get(modifierItem, 'defaultPriceInCents', 0)
      return acc + priceInCents * quantity
    }, 0)

    const lineItemTotal = itemSubtotal + modifiersSubtotal

    return accumulator + lineItemTotal
  }, 0)

  return total
}

// Convenience method to ensure consistency of calculations of taxes on items.
// taxRate: The tax rate, as a float.
// itemSubtotal: The subtotal of the item in cents.
// taxable - fee is taxable (defaults to true)
// Return: The tax amount in cents.
export const calculateIndividualTaxAmount = (taxRate, itemSubtotal, taxable = true) => {
  if (!taxable) return 0
  if (!taxRate) return 0
  if (!itemSubtotal) return 0

  return Math.ceil(taxRate * itemSubtotal)
}

// serviceChargeRate: The service charge rate object.
// subtotal: The subtotal in cents.
// Return: The service charge in cents based on the subtotal and tax rate.
export const calculateServiceCharge = (serviceChargeObject, subtotal) => {
  if (!serviceChargeObject) return 0
  if (!subtotal) return 0

  const rate = serviceChargeObject.rate
  const flatFee = subtotal > 0 ? serviceChargeObject.priceInCents : 0

  if (!(rate && rate > 0) && !flatFee) return 0

  // TODO(mkramerl): Match this to the platform once the platform has a consistent strategy.
  return Math.round(flatFee + ((rate && rate > 0) ? (subtotal * rate) : 0))
}

export const calculateTax = (cartItems, taxRate, taxByItem) => {
  let totalTax = 0
  let taxableTotal = 0

  // Axioms:
  //   - tax inclusive items should be marked as non-taxable
  //   - the tax rate of the item should override the tax rate of the menu, if it is present
  //   - not taxing by item precludes the use of item-level rates
  const total = reduce(cartItems, (itemAcc, item) => {
    // The taxable total represents that of the item if we are taxing by item.
    taxableTotal = taxByItem ? 0 : taxableTotal

    const itemQuantity = get(item, 'quantity', 0)
    const itemPriceInCents = get(item, 'defaultPriceInCents', 0)
    const itemSubtotal = itemPriceInCents * itemQuantity
    const itemModifiers = get(item, 'modifiers', [])

    // The default taxability of an item is false. Keep this in sync with platform.
    const itemTaxable = get(item, 'taxable', false)
    let itemTaxRate = get(item, 'rate', null) ?? taxRate
    itemTaxRate = itemTaxRate == 0.0 ? taxRate : itemTaxRate

    if (itemTaxable) {
      taxableTotal = taxableTotal + itemSubtotal

      if (taxByItem) {
        totalTax = totalTax + itemQuantity * calculateIndividualTaxAmount(itemTaxRate, itemPriceInCents)
      }
    }


    const modifiersSubtotal = reduce(itemModifiers, (modifierAcc, modifierItem) => {
      const modifierPriceInCents = get(modifierItem, 'defaultPriceInCents', 0)
      const modifierQuantity = get(modifierItem, 'quantity', itemQuantity)
      const modifierSubtotal = modifierPriceInCents * modifierQuantity

      // The default taxability of an item is false. Keep this in sync with platform.
      const modifierTaxable = get(modifierItem, 'taxable', false)
      let modifierTaxRate = get(modifierItem, 'rate', null) ?? taxRate
      modifierTaxRate = modifierTaxRate == 0.0 ? taxRate : modifierTaxRate

      if (modifierTaxable) {
        taxableTotal = taxableTotal + modifierSubtotal

        if (modifierTaxRate) {
          totalTax = totalTax + modifierQuantity * calculateIndividualTaxAmount(modifierTaxRate, modifierPriceInCents)
        }
      }

      return modifierAcc + modifierSubtotal
    }, 0)

    return itemAcc + itemSubtotal + modifiersSubtotal
  }, 0)

  return taxByItem ? totalTax : (taxableTotal * taxRate)
}
