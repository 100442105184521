export const PRINT_ORDER_REQUESTED = 'PRINT_ORDER_REQUESTED'
export const FETCH_PRINTERS_REQUESTED = 'FETCH_PRINTERS_REQUESTED'
export const FETCH_PRINTERS_SUCCEEDED = 'FETCH_PRINTERS_SUCCEEDED'
export const FETCH_PRINTERS_FAILED = 'FETCH_PRINTERS_FAILED'
export const PRINTER_DISCOVERED = 'PRINTER_DISCOVERED'
export const PRINTER_CONNECTION_CHANGED = 'PRINTER_CONNECTION_CHANGED'
export const CURRENT_NETWORK_PRINTER_SELECTED = 'CURRENT_NETWORK_PRINTER_SELECTED'

export const printOrder = (payload) => ({
  type: PRINT_ORDER_REQUESTED,
  payload,
})

export const fetchPrinters = () => ({
  type: FETCH_PRINTERS_REQUESTED,
})

export const fetchPrintersFailed = (payload) => ({
  type: FETCH_PRINTERS_FAILED,
  payload,
})

export const fetchPrintersSucceeded = (payload) => ({
  type: FETCH_PRINTERS_SUCCEEDED,
  payload,
})

export const printerDiscovered = (printer) => ({
  type: PRINTER_DISCOVERED,
  payload: {
    printer,
  },
})

export const printerConnectionChanged = (printerState) => ({
  type: PRINTER_CONNECTION_CHANGED,
  payload: {
    state: printerState,
  },
})

export const currentNetworkPrinterSelected = (printerUuid) => ({
  type: CURRENT_NETWORK_PRINTER_SELECTED,
  payload: {
    printerUuid,
  },
})
