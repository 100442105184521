import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './TipEntryModal.module.scss'

const formatValue = (value) => (parseInt(value, 10) / 100 || 0).toFixed(2)

const TipEntryModal = ({ title, initialValue, saveButtonText, errorMessage, validator, onSubmit, onClose, isKiosk, className }) => {
  const [value, setValue] = useState(formatValue(initialValue) || '0.00');

  useEffect(() => {
    setValue(formatValue(initialValue))
  }, [initialValue])

  const handleUpdateValue = () => {
    onSubmit(Math.round(value*100))
    onClose()
  }

  const handleInput = (event) => {

    // If Enter was pressed, submit the entered value
    if(event.key === 'Enter' || event.keyCode === 13) {
      handleUpdateValue()
      return
    }
  
    let input = event.target.value?.replace(/\D/g, '');
    let formattedValue = (parseInt(input, 10) / 100).toFixed(2);
  
    // Save the current cursor position before updating the value
    setValue(formattedValue);

    // Move the cursor to the correct position after the input value is updated
    const cursorPosition = event.target.selectionStart;
    const valueLength = formattedValue.length + 1
    if (cursorPosition <= 4) {
      setTimeout(() => {
        event.target.setSelectionRange(valueLength, valueLength);
      }, 0);
    }
  };

  return (
    <div className={isKiosk ? styles.kioskTipEntryModal : styles.tipEntryModal}>
      <div className={styles.overlay}>
        <div className={styles.content}>
            <div className={styles.tipText}>Custom Tip</div>
            <div className={styles.inputContainer}>
              <input
                type="tel"
                placeholder="$0.00"
                value={`$${value}`}
                onChange={handleInput}
                pattern="\d*"
                autoFocus
              />
            </div>
            <div className={styles.buttonContainer}>
              <div
                onClick={onClose}
                className={styles.cancelButton}
              >
                Cancel
              </div>
              <div className={styles.partition} />
              <div
                onClick={handleUpdateValue}
                className={styles.doneButton}
              >
                Done
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

TipEntryModal.defaultProps = {
  title: '',
  errorMessage: '',
  initialValue: 0,
  saveButtonText: 'SAVE',
  validator: () => {},
  onSubmit: () => {},
  onClose: () => {},
}

TipEntryModal.propTypes = {
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  initialValue: PropTypes.number,
  saveButtonText: PropTypes.string,
  validator: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}

export default TipEntryModal
