import React from 'react'
import PropTypes from 'prop-types'

const IconAdd = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <path d="M8.5 11.5H2v-3h6.5V2h3v6.5H18v3h-6.5V18h-3z" />
  </svg>
)

IconAdd.propTypes = {
  className: PropTypes.string,
}

export default IconAdd
