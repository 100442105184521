import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import styles from './Sidebar.module.scss'

const Sidebar = ({ children, isOpen, onKeyDown }) => {
  const sidebarClasses = isOpen ? cn(styles.sidebar, styles.open) : cn(styles.sidebar)

  return (
    <section className={sidebarClasses} onKeyDown={onKeyDown}>
      <div className={styles.sidebarOverlay}></div>
      <div className={styles.sidebarContent}>
        {children}
      </div>
    </section>
  )
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
}

Sidebar.defaultProps = {
  isOpen: false,
}

export default Sidebar
