import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class Button extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    title: PropTypes.string,
    ariaLabel: PropTypes.string
  }

  render() {
    const { className, onClick, onKeyPress, title, disabled, ariaLabel = "", tabIndex, refProp } = this.props
    return (
      <button onKeyDown={onKeyPress} ref={refProp} className={className} disabled={disabled} tabindex={tabIndex} onClick={onClick} aria-label={ariaLabel}>
        {title}
      </button>
    )
  }
}
