import React from 'react'
import { useSelector } from 'react-redux'
import { VNDialog } from '../../VNCustomerFacingDisplay/components/VNDialog'
import { getCustomerPayingWithCard } from '../Selectors'
import { centsToDollars } from '../../utils/formatters'


export const VNCustomerPayingWithCard = () => {
  const dialogOptions = useSelector(state => getCustomerPayingWithCard(state))

  return (
    <VNDialog  open={dialogOptions?.get('open')}
                      subTitle={"Customer Paying {{AMOUNT}} with Card".replace('{{AMOUNT}}', centsToDollars(dialogOptions?.get('amount')))}/>
  )
}
