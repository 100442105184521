import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { isEmpty } from 'lodash'

import Image from '../components/Image'
import IconExit from '../components/icons/IconExit'
import Button from '../components/Button'
import IdleWarning from '../Main/IdleWarning'

import { toggleAppState } from '../../actions/appState'
import { menusRequested, currentMenuSelected } from '../../actions/menus'
import { clearCart } from '../../actions/cart'
import { setActiveRevenueCenter } from '../../actions/revenueCenter'

import { getSelectedMenus } from '../../selectors/menus'

import UserIdleMonitor from '../utils/UserIdleMonitor'
import ImageVersioningContext from '../context/ImageVersioningContext'
import { KEY_CODES } from "../utils/constants";
import { menuWelcomeMessage } from '../utils/messages'

class MenuSelect extends Component {
  static contextType = ImageVersioningContext

  constructor(props) {
    super(props)

    this.itemsRefs = {}
    this.exitRef = React.createRef()
    this.readSectionRef = null
    this.idleMonitor = new UserIdleMonitor(process.env.REACT_APP_DEV_IDLE_TIMEOUT || 60000)
  }

  state = {
    selectedIndex: -1,
    isWelcomeAriaLabel: true
  }

  componentDidMount() {
    const { menusRequested, clearCart, toggleAppState, menus } = this.props

    this.idleMonitor.addEventListeners()
    menusRequested()
    clearCart()
    toggleAppState({
      isAgeConfirmed: false,
      isAlcoholWarningConfirmed: false,
    })

    this.timeout = setTimeout(() => {
      const itemToFocus = menus.filter(menu => menu.isAvailable && menu?.orderNow)[0]
      if (itemToFocus) this.itemsRefs[itemToFocus.uuid].focus()
    }, 0)
  }

  componentWillUnmount() {
    this.idleMonitor.removeEventListeners()
    clearInterval(this.timeout)
  }

  selectMenu = (e, menuData) => {
    e.preventDefault()
    e.stopPropagation()

    const { currentMenuSelected, setActiveRevenueCenter, history } = this.props

    setActiveRevenueCenter(menuData.standUuid)
    currentMenuSelected(menuData.uuid)

    history.push('/kiosk/order/new')
  }

  handleSelectMenuOnKeyPress = (e, menuData) => {
    if (e.keyCode !== KEY_CODES.ENTER) return

    this.selectMenu(e, menuData)
  }

  handleKeyPress = (event) => {
    event.stopPropagation();
    if(event.keyCode === KEY_CODES.LEFT) {
      this.setState({ isWelcomeAriaLabel: false })
      this.handlePreviousItem()
    } else if (event.keyCode === KEY_CODES.RIGHT) {
      this.setState({ isWelcomeAriaLabel: false })
      this.handleNextItem();
    }
    else if (event.keyCode === KEY_CODES.ENTER) {
      this.handleItemClick()
    }
  }

  handlePreviousItem = () => {
    const { menus } = this.props
    const { selectedIndex } = this.state

    if (selectedIndex === 0) {
      this.setState({selectedIndex: -1})
      this.exitRef && this.exitRef.current.focus()
    }
    else if (selectedIndex === -1) {
      const itemToFocus = menus[menus.length - 1]
      this.setState({selectedIndex: menus.length - 1})
      this.itemsRefs[itemToFocus.uuid].focus()
    }
    else {
      const itemToFocus = menus[selectedIndex-1]
      this.setState({selectedIndex: selectedIndex - 1})
      this.itemsRefs[itemToFocus.uuid].focus()
    }
  }

  handleNextItem = () => {
    const { selectedIndex } = this.state
    const { menus } = this.props


    if (selectedIndex === menus.length - 1) {
      this.setState({selectedIndex: -1})
      this.exitRef && this.exitRef.current.focus()
    }
    else {
      const itemToFocus = menus[selectedIndex + 1]
      this.setState({selectedIndex: selectedIndex + 1})
      this.itemsRefs[itemToFocus.uuid].focus()
    }
  }

  handleItemClick = () => {
    document.activeElement.click()
  }

  renderMenu = menuData => {
    const { isAvailable, shortDescription, images, name, orderNow } = menuData
    const menuIsAvailable = isAvailable && orderNow
    const { getUrlWithVersion } = this.context
    const { isWelcomeAriaLabel } = this.state

    const buttonLabel = `${name}. ${menuIsAvailable ? shortDescription : 'Closed'}`
    const ariaLabel = isWelcomeAriaLabel ? `${menuWelcomeMessage}, ${buttonLabel}` : buttonLabel

    return (
      <div key={menuData.uuid} className={cn('menu-select__item', { '-inactive': !isAvailable })}>
        {menuIsAvailable &&
        <Button
          ariaLabel={ariaLabel}
          className='menu-select__btn'
          refProp={node => {
            this.itemsRefs[menuData.uuid] = node
          }}
          onKeyDown={(e, menuData) => this.handleSelectMenuOnKeyPress(e, menuData)}
          onClick={(e) => this.selectMenu(e, menuData)}
        />}
        <Image className='menu-select__image' url={getUrlWithVersion(images.detail)}/>
        <div className='menu-select__image-description'>
          <p className='menu-select__image-description__header'>{name}</p>
          <p>{menuIsAvailable ? shortDescription : 'Closed'}</p>
        </div>
      </div>
    )
  }

  render() {
    const { menus } = this.props

    return (
      <>
        <IdleWarning />
        <div className='menu-select' ref={node => {this.menuRef = node}} tabindex="0" onKeyDown={this.handleKeyPress}>
          <NavLink
            tabindex="1"
            aria-label='exit'
            className="menu__exit"
            to="/kiosk/attract_loop"
            onKeyPress={e => e.preventDefault()}
            innerRef={this.exitRef}
          >
            <IconExit className="menu__icon" />
            Exit
          </NavLink>
          <div aria-describedby="description" className='menu-select__list'>
            {!isEmpty(menus) && menus.map(this.renderMenu)}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  menus: getSelectedMenus(state),
})

const mapDispatchToProps = {
  menusRequested,
  currentMenuSelected,
  setActiveRevenueCenter,
  clearCart,
  toggleAppState,
}


export default connect(mapStateToProps, mapDispatchToProps)(MenuSelect)
