import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getCurrentPaymentFlow, getCurrentTransactionLifecycleMoment, getPaymentProgress } from '../Selectors'
import { CFD_EVENTS, CFD_POS_PAYMENT_FLOWS, USER_PROGRESS } from '../Enums'
import { setConfirmPaymentDialog, setCustomerPayingWithCard, setCustomerViewingReceiptDialog, setOpenCustomerEnteringTipDialog, setTransactionCompleteDialog } from '../../VNDialogs/ActionCreators'
import { getOrderInProgress } from '../../selectors/order'
import { updateTransactionLifecycle } from '../ActionCreators'
import { getCustomerViewingReceipt } from '../../VNDialogs/Selectors'

export const VNPOSListeners = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  // SELECTORS
  const currentTransactionLifecycleMoment = useSelector(state => getCurrentTransactionLifecycleMoment(state))
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const userPaymentProgress = useSelector(state => getPaymentProgress(state))
  const customerViewingReceipt = useSelector(state => getCustomerViewingReceipt(state))

  // LOCAL STATE

  // USE EFFECTS
  useEffect(() => {

    if (currentTransactionLifecycleMoment === CFD_EVENTS.CUSTOMER_WAITING_FOR_ATTENDANT &&
        currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
          dispatch(setConfirmPaymentDialog(false))
          dispatch(setOpenCustomerEnteringTipDialog({open: false}))
          history.push('/split-tender')
    } else if ( currentTransactionLifecycleMoment === CFD_EVENTS.CUSTOMER_DONE_WITH_RECEIPT && 
                (currentPaymentFlow !== CFD_POS_PAYMENT_FLOWS.UNSET)) { //either qr pay or split pay

                  dispatch(setCustomerViewingReceiptDialog(false))
                  dispatch(setTransactionCompleteDialog(true))
    } else if ( currentTransactionLifecycleMoment === CFD_EVENTS.CC_PAYMENT_COMPLETE && 
                currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {

                  // only close customer paying with card if balance is higher than 0 otherwise receipt screen will close it
                  if (parseInt(orderInProgress?.balanceDueInCents) > 0) {
                    dispatch(setCustomerPayingWithCard({open: false}))
                  }

                  // reset the lifecycle
                  dispatch(updateTransactionLifecycle(CFD_EVENTS.IDLE))

                  // move the user to the receipt screen to determine what to do next
                  history.push(`/receipt/split-tender/${orderInProgress?.paymentId}`)
    }
  }, [currentTransactionLifecycleMoment, currentPaymentFlow])

  useEffect(() => {
    if (customerViewingReceipt && userPaymentProgress.title === USER_PROGRESS.CARD_PAYMENT.PAYMENT_FINISHED.TRANSACTION_COMPLETE.title) {
      // For Free, Custom Tender or Cash orders
      dispatch(setCustomerViewingReceiptDialog(false))
      dispatch(setTransactionCompleteDialog(true))
    } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET && userPaymentProgress.title === USER_PROGRESS.CARD_PAYMENT.PAYMENT_FINISHED.VIEWING_RECEIPT.title) {
      dispatch(setCustomerViewingReceiptDialog(true))
      dispatch(setCustomerPayingWithCard({open: false}))
      dispatch(setTransactionCompleteDialog(false))
    } 
  }, [userPaymentProgress])

  return <></>
}