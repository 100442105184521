import React, { useState } from 'react'
import cn from 'classnames'
import { filter, some, get, head, find } from 'lodash'
import { ReactComponent as CloseIcon } from '../assets/icons/Close-Icon.svg'
import { ReactComponent as InputSearchIcon } from '../assets/input-search-icon.svg'
import styles from './QuickMenuSearch.module.scss'

const QuickMenuSearch = ({ className, query, menuItems, updateUseQuickSearchFlag, highlightedIds, setHighlightedIds, setFocusedItemId, quickSearchRef, setSelectedCategory }) => {
  const classNames = cn(styles.quickMenuSearch, className)
  const [focusedItemIndex, setFocusedItemIndex] = useState(0)
  const mediaQuery = window.matchMedia("(max-width: 500px)")
  const focus = get(quickSearchRef, 'current.focus', () => {}).bind(get(quickSearchRef, 'current', () => {}))

  const queryMatch = (query, item) => {
    return item.name.toUpperCase().includes(query.toUpperCase()) && !item.isModifier
  }

  const getNewSearchParams = (query) => {
    const highlightedIds = filter(menuItems, item =>
      queryMatch(query, item)
    ).map(item => item.id)

    const focusedItemId = head(highlightedIds)
    return {
      query,
      highlightedIds,
      focusedItemId,
    }
  }

  const resetState = (query) => {
    setFocusedItemIndex(0)
    setHighlightedIds({query: query, highlightedIds: [], focusedItemId: undefined})
  }

  const clearInput = () => {
    focus()
    resetState('')
  };

  const closeSearch = () => {
    updateUseQuickSearchFlag(false)
    resetState('')
  }

  const queryUpdate = query => {
    const anyMatchingItems = some(menuItems, item => {
      return queryMatch(query, item)
    })

    if (!anyMatchingItems) {
      resetState(query)
      return
    }

    if (query) {
      setFocusedItemIndex(1)
      const newSearchParams = getNewSearchParams(query)
      setHighlightedIds(newSearchParams)
      setSelectedCategory(getSelectedCategoryParams(newSearchParams.focusedItemId))
    } else {
      clearInput()
    }
  };

  const  getSelectedCategoryParams = (focusedItemId) => {
    const selectedCategoryId = find(menuItems, (item) => item.id === focusedItemId)?.categoryId ?? ''
    return { id: selectedCategoryId, search: false, highlight: true }
  }

  return (
    <div className={classNames}>
      <div className={styles.searchContainer}>
        <div className={styles.inputContainer} >
          <div className={styles.searchIcon}><InputSearchIcon /></div>
          <input className={styles.quickSearchInput} type="text" data-allow-keyboard-activity value={query} onBlur={!query ? closeSearch : null} autoComplete="off" ref={quickSearchRef} autoFocus={!mediaQuery.matches} onChange={(e)=> queryUpdate(e.target.value) }/>
          {query && (<div className={styles.clearButton} onClick={clearInput}>
            <CloseIcon />
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default QuickMenuSearch
