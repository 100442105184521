/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { isEmpty } from 'lodash'
import Lottie from "lottie-react";

import { getOrderInProgress } from '../../selectors/order'
import { addPaymentToOrderInProgress, closeNoOpTender } from '../../actions/order'

// import analytics from '../utils/analytics'
import { formatPrice } from '../utils/formatters'
import PeripheralBridge from '../../utils/peripheralBridge'
import UserIdleMonitor from '../utils/UserIdleMonitor'
import { KEY_CODES } from "../utils/contants"

import OfflineModal from '../OfflineModal'
import ConnectingIndicator from '../../components/ConnectingIndicator'
import BackButton from '../components/BackButton'
import ScanGiftCardGif from '../assets/images/kiosk-gift-card-scan.gif'
import { ReactComponent as Alert } from '../assets/icons/alert.svg'

import useCreateOrderInProgress from '../../hooks/useCreateOrderInProgress'
import useGetGiftCardBalance from '../../hooks/useGetGiftCardBalance'
import loadingSpinnerAnimationData from '../assets/images/kiosk-loading.json'
import { handleFullPpiFlow } from '../../VNPPI/Utils'
import { VNPPI_TENDERS } from '../../VNPPI/Enums'
import { VNInjectablePpi } from '../../VNPPI/containers/VNInjectablePpi'
import { codeAlreadyUsed } from '../../VNUtil/VNUtils'

const KioskGiftCard = ({
  onGoBack,
  needCreateOrder,
  order,
  addPaymentToOrderInProgress,
  balanceDueInCents,
  placeFreeOrder,
  push
}) => {

  const [isProcessing, setIsProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [giftCardCode, setGiftCardCode] = useState('')
  const [createOrder, orderCreated] = useCreateOrderInProgress()
  const orderExists = !isEmpty(order)
  const [getGiftCardBalance, { giftCardBalance, dataSucceeded, dataFailed, cardNotRecognized }] = useGetGiftCardBalance()
  const noPaymentRequired = order?.amountInCents === 0
  const hasNoBalance = +giftCardBalance === 0
  const backButtonRef = useRef()

  const ppiInjectableRef = useRef()

  // MANUAL ENTRY HACK FOR KIOSK MODE ON BROWSER (KEEP IN CODE)
  // const ticketInputRef = useRef()

  const scannedResultText = errorMessage !== '' ? errorMessage : 'Gift card is not Valid. \nPlease try again.'
  let primaryText = 'SCAN GIFT CARD'
  let secondaryText = errorMessage ? scannedResultText : 'Please scan gift card.'

  useEffect(() => {
    if (needCreateOrder) {
      createOrder()
    }

    return () => {
      const bridgeInstance = PeripheralBridge.getBridge()
      if (!bridgeInstance) return
      bridgeInstance.call('stopScanner')
    }
  }, [])

  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.UP) {
      onGoBack()
    }
  }

  useEffect(() => {
  if (dataFailed) {
    setIsProcessing(false)

    if (cardNotRecognized) {
      setErrorMessage(
        'Gift card not recognized.\nPlease try again'
      )
    } else {
      setErrorMessage(
        'Error requesting gift card balance.\nPlease try again'
      )
    }
  }
}, [dataFailed])

  useEffect(() => {
    if (orderCreated && noPaymentRequired) {
      placeFreeOrder()
      return
    }
  }, [orderCreated])

  useEffect(() => {
    if (dataSucceeded) {
      handleSuccess()
    }
  }, [dataSucceeded])

  useEffect(() => {
    if (orderExists) {
      if (noPaymentRequired) {
        return
      }
      const bridgeInstance = PeripheralBridge.getBridge()
      if (!bridgeInstance) {
        return
      }
      bridgeInstance.registerHandler('scanPayload', function (data, callback) {
        const scannedCode = data?.valueOf()
        if (codeAlreadyUsed(order, scannedCode)) {
          setErrorMessage('Gift card already scanned and applied to order')
          return
        }

        setGiftCardCode(scannedCode)
        startBalanceCheck(scannedCode)
      })

      bridgeInstance.registerHandler('scanError', function (err, callback) {
        let message = 'Gift card scan error. Try again.'
        if(err?.toLowerCase()?.includes('no scanner detected')) {
          message = 'No scanner detected'
        }

        setErrorMessage(message)
      })
    }
  }, [orderExists, order])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    if (isProcessing) {
      bridgeInstance.call('stopScanner')
    } else if (orderExists) {
      bridgeInstance.call('startScanner')
    }
  }, [isProcessing, orderExists])

  const resetTicket = () => {
    setGiftCardCode('')
    setIsProcessing(false)
  }

  const startBalanceCheck = async (code) => {

    setIsProcessing(true)
    setErrorMessage('')

    const ppiInjectableData = ppiInjectableRef.current.getData(VNPPI_TENDERS.GIFTCARD_TENDER)

    if (!isEmpty(ppiInjectableData.ppiTender)) {

      const externalFunctionality = {
        functions: {
          ...ppiInjectableData.functions,
          setErrorMessage: setErrorMessage,
          redirect: redirect,
          push,
          placeFreeOrder: placeFreeOrder
        },
        balanceDueInCents: ppiInjectableData.order?.balanceDueInCents,
        promotions: ppiInjectableData.promotions,
        token: code,
        paymentType: "vnapi_giftcard",
        shortDescription: "Gift Card",
      }

      await handleFullPpiFlow(ppiInjectableData, externalFunctionality)

      resetTicket()

    } else {
      getGiftCardBalance(code)
    }
  }

  const PaymentBackBtn = () => {
    return (
      <BackButton
        handleKeyDown={handleKeyDown}
        refProp={backButtonRef}
        className="payment__back"
        withIcon={true}
        onClick={onGoBack}
      >
        {!orderExists ? '-' : formatPrice(order?.balanceDueInCents)}
      </BackButton>
    )
  }

  const idleMonitor = new UserIdleMonitor(process.env.REACT_APP_DEV_IDLE_TIMEOUT || 60000)
  useEffect(() => {
    backButtonRef.current && backButtonRef.current.focus()

    idleMonitor.addEventListeners()
    return () => {
      idleMonitor.removeEventListeners()
    }

  }, [])

  const redirect = (tenderAmountInCents, paymentWasSuccessful) => {
    push({
      pathname: `/kiosk/order/payment`,
      state: {
        tenderAmountInCents,
        paymentWasSuccessful,
        scanScreenType: 'Gift card',
      }
    })
  }

  const handleSuccess = () => {
    if (hasNoBalance) {
      setIsProcessing(false)
      setErrorMessage('Card has no funds.\nPlease try another gift card or tender.')
      return
    } 
    let tenderAmountInCents
    let redirectToSplit = false
    if (giftCardBalance >= balanceDueInCents) {
      tenderAmountInCents = balanceDueInCents
    } else {
      tenderAmountInCents = giftCardBalance
      redirectToSplit = true
    }
    addPaymentToOrderInProgress({
      tenderAmountInCents,
      paymentType: 'gift_card',
      shortDescription: 'Gift Card',
      token: giftCardCode,
    })
    if (redirectToSplit) {
      redirect(balanceDueInCents - giftCardBalance, true)
    }
  }

  if (!orderExists) {
    return <ConnectingIndicator isKiosk={true} position={'initial'}/>
  }

  let footerElement = null

  if (errorMessage) {
    footerElement = <div className="payment__footer-button" onClick={() => {
      redirect(balanceDueInCents, false)
    }}>
      PAY WITH CREDIT CARD
    </div>
  }

  let content = (
    <>
      <PaymentBackBtn />
      <div className="payment__header">{primaryText}</div>
      <div className="payment__sub-header">{(errorMessage) && <Alert className="payment__alert-icon"/>}{secondaryText}</div>
      <div className="payment__ticket-wrapper">
      <img src={ScanGiftCardGif} alt={"Scan Gift Card"} className={"payment__card-reader-icon"}/>
      </div>
      <div className={`payment__footer-text${!isEmpty(footerElement) ? '-with-button' : ''}`}>
        Scanner is Ready
      </div>
        {footerElement}
    </>
    )

  if (isProcessing) {
    content = (
      <>
        <PaymentBackBtn />
        <div className="payment__header">Processing</div>
        <div className="payment__sub-header">Please wait.</div>
        <div className="payment__ticket-wrapper">
          <div className="payment__card-reader-icon">
            <Lottie animationData={loadingSpinnerAnimationData} />
          </div>
        </div>
      </>
    )
  }

  if (noPaymentRequired) {
    content = (
      <>
        <div className="payment__content">
          <PaymentBackBtn />
          <div className="payment__header">No Charge!</div>
          <div className="payment__sub-header">Submitting your order...</div>
        </div>
      </>
      )
  }

  // MANUAL ENTRY HACK FOR KIOSK MODE ON BROWSER (KEEP IN CODE)
  // const handleKeyPress = e => {
  //   if (e.keyCode === KEY_CODES.ENTER) {
  //     if (codeAlreadyUsed(order, ticketInputRef.current.value)) {
  //       return
  //     }
  //     startBalanceCheck(ticketInputRef.current.value)
  //   }
  // }

  return (
    <div className="payment">
      <VNInjectablePpi ref={ppiInjectableRef} />
      {/* MANUAL ENTRY HACK FOR KIOSK MODE ON BROWSER (KEEP IN CODE) */}
      {/* <input
        ref={ticketInputRef}
        onKeyDown={handleKeyPress}
      /> */}
      {content}
      <OfflineModal />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const order = getOrderInProgress(state)
  const balanceDueInCents = order?.balanceDueInCents ?? 0
  const push = props?.history?.push ?? (() => {})

  return {
    order, 
    needCreateOrder: isEmpty(order),
    balanceDueInCents,
    push
  }
}

const mapDispatchToProps = (dispatch, props) => { 
  const push = props?.history?.push ?? (() => {})
  const goBack = props?.history?.goBack ?? (() => {})

  return {
    onGoBack: () => goBack(),
    addPaymentToOrderInProgress: (payment) => dispatch(addPaymentToOrderInProgress({ ...payment, push })),
    placeFreeOrder: (payload) => {
      dispatch(closeNoOpTender(payload))
      push(`/kiosk/order/summary/${payload.orderUuid}`)
    }
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(KioskGiftCard)
