import React, { useEffect, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

import { VNButtonContainer } from 'vn-react-components/VNComponents/VNButtonContainer'
import { getCanExecuteSalesRequest, getCartTotals, getCurrentPaymentFlow, getTips } from '../Selectors'
import { VNButton } from 'vn-react-components/VNComponents/VNButton'
import { setCFDScreen, setCartTotals, setPaymentData } from '../ActionCreators'
import { CFD_SCREEN } from '../Reducer'
import { bridge_setCurrentOrder, bridge_updateTransactionLifecycle, sendExecuteSalesRequest, sendOrderUpdate, sendUserPaymentProgress } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { VNDialog } from 'vn-react-components/VNComponents/VNDialog'
import { VNInputDialog } from 'vn-react-components/VNComponents/VNInputDialog'
import { centsToDollars, getDeviceSerial } from '../../utils/formatters'
import { CFD_EVENTS, CFD_POS_PAYMENT_FLOWS, USER_PROGRESS } from '../Enums'
import { Info } from '@material-ui/icons'
import { VNCFD_TRANSACTION_RESULT } from '../ActionTypes'
import { invoiceNumber } from '../../utils/orderNumbers'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  list: {
    width: 552,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  totals: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  discount: {
    color: `${theme.custom.colors.brand} !important`
  },
  bottomPayButton: {
    paddingBottom: 60
  },
  tipText: {
    color: `${theme.custom.colors.disabledGrey} !important`,
    textAlign: 'center',
    paddingTop: 43,
    paddingBottom: 32
  }
}))

export const VNPayment = (props) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  // SELECTORS
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))
  const canExecuteSalesRequest = useSelector(state => getCanExecuteSalesRequest(state))
  const cartTotals = useSelector(state => getCartTotals(state))
  // cartTotals below used for debug
  // const cartTotals = Map({
  //   "subtotal": 1234,
  //   "discount": 15,
  //   "tax": 86,
  //   "tip": "0",
  //   "total": 1320,
  //   "uuid": "81fd39bb-5ee3-4a30-9287-370b03b21fc2",
  //   "serviceCharge": 220,
  //   "serviceChargeName": "Service Charge (POS)",
  //   "orderNumber": "869230",
  //   "hRef": "1422869230"
  // })
  const tips = useSelector(state => getTips(state))
  // tips below used for debug
  // const tips = Map({enabled: 'true', suggestions: [{tipCustom: 'true', tipSelected: 'false'}, {tipDisplay: 'None', tipValue: 0, tipSelected: 'true'}]})

  // LOCAL STATE
  const [openDialog, setOpenDialog] = useState(false)
  const [serviceChargeDialog, setServiceChargeDialog] = useState(false)
  const [lastTipButtonSelected, setLastTipButtonSelected] = useState(null)

  // USE EFFECTS

  useEffect(() => {
    let totals = cartTotals.toJS()

    if (tips && tips.get('enabled') === "true" && tips.get('suggestions').length > 0) {

      let defaultTip = 0

      // if the total is 0, we don't want to use the default tip suggestion
      const totalInCents = getTotalWithoutTipInCents()
      if (totalInCents === 0) {
        return
      }

      for (let i = 0; i < tips.get('suggestions').length; i++) {
        const element = tips.get('suggestions')[i]
        if (element.tipSelected === "true") {
          defaultTip = parseFloat(element.tipPercentage)
        }
      }

      if (defaultTip > 0) {
        totals.tip = parseInt(defaultTip * totals.subtotal)
      }
    }

    dispatch(setCartTotals(totals))
  }, [])

  const onButtonContainerClick = (id) => {

    // force it so you can't click the same button twice,
    // saving time on the client for more responsiveness
    if (id !== lastTipButtonSelected) {

      let percent
      let tip

      setLastTipButtonSelected(id)

      switch(id) {
        case 'custom':
          setOpenDialog(true)
          break
        case 'none':
          percent = 0
          break
        default:
          percent = id
          break
      }

      if (percent) {
        // tip is calculated off the subtotal before tax and discounts
        tip = parseInt(cartTotals.get('subtotal') * percent)
      } else if (percent === 0) {
        tip = 0
      } else {
        // waiting on the custom tip
        return
      }

      let totals = cartTotals.toJS()
      totals.tip = tip

      dispatch(setCartTotals(totals))
    }
  }

  // calculate the tip and save it
  const onDialogSave = (val) => {

    if (val === undefined || val === null) {
      return
    }

    let totals = cartTotals.toJS()
    totals.tip = val
    dispatch(setCartTotals(totals))
  }

  const getTotalInCents = () => {
    return  cartTotals.get('tax') +
            cartTotals.get('subtotal') +
            cartTotals?.get('tip') +
            cartTotals.get('serviceCharge') +
            cartTotals.get('discount')
  }

  const getTotalWithoutTipInCents = () => {
    return parseInt(getTotalInCents()) - cartTotals?.get('tip')
  }

  const handlePayClick = () => {
    const totalInCents = getTotalInCents()
    const tipInCents = cartTotals?.get('tip')
    let paymentData = {
      amount: totalInCents,
      tipAmount: tipInCents,
      hRef: cartTotals.get('orderNumber') && invoiceNumber(cartTotals?.get('orderNumber')),
      orderNumber: cartTotals?.get('orderNumber'),
      uuid: cartTotals?.get('uuid'),
      deviceSerial: getDeviceSerial()
    }

    sendOrderUpdate(paymentData)
    paymentData.amount = centsToDollars(totalInCents)
    paymentData.tip = centsToDollars(tipInCents)

    if (cartTotals?.get('isFinalizingTip') === "true") {
      sendUserPaymentProgress({...USER_PROGRESS.CARD_PAYMENT.PAYMENT_FINISHED.VIEWING_RECEIPT, ...{ tabbed: true, tipInCents, tenderData: cartTotals?.get('tenderData') }})
      return
    }

    // sent for QR Pay - up for deprecation
    if (canExecuteSalesRequest === 'false') {
      if (totalInCents === 0) {
        freeOrder(paymentData)
      } else {
        sendUserPaymentProgress({tipAmount: tipInCents || 0})
        dispatch(setCFDScreen(CFD_SCREEN.PROCESSING))
      }
      return
    } else {

      dispatch(setPaymentData(paymentData))
      // if the total, including tip, is 0, then we skip broadPOS and go straight to the receipt

      if (totalInCents === 0) {
        freeOrder(paymentData)
      } else {

        // Split Pay has a different mechanism
        if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {

          // need to send tip over to POS
          bridge_setCurrentOrder(tipInCents || 0)

          // update the CFD screen
          dispatch(setCFDScreen(CFD_SCREEN.SPLIT_PAY_WAITING_FOR_ATTENDANT))

          // tell POS what happened
          bridge_updateTransactionLifecycle(CFD_EVENTS.CUSTOMER_WAITING_FOR_ATTENDANT)

        } else {
          // send off to Android for payment
          sendExecuteSalesRequest(paymentData)
          sendUserPaymentProgress({...USER_PROGRESS.CARD_PAYMENT.PAYING_CARD, amount: paymentData.amount})

          // move to the processing screen
          dispatch(setCFDScreen(CFD_SCREEN.PROCESSING))
        }
      }
    }
  }

  const freeOrder = (paymentData) => {
    dispatch({
      type: VNCFD_TRANSACTION_RESULT,
      data: { amount: paymentData.amount }
    })

    sendUserPaymentProgress({...USER_PROGRESS.CARD_PAYMENT.PAYMENT_FINISHED.VIEWING_RECEIPT, ...{ approvedOffline: false, freeItem: true }})

    dispatch(setCFDScreen(CFD_SCREEN.RECEIPT))
  }

  // display a line itme of the totals that were passed in
  const displayTotal = (title, amount, className, titleIcon) => {
    return (
      <Box pb={2} className={classes.total}>
        <Typography style={{...theme.custom.subtitle3, ...{overflow: 'auto', textOverflow: 'ellipsis'}}} className={className ?? className}>{title}{titleIcon}</Typography>
        <Typography style={theme.custom.subtitle3} className={className ?? className}>{amount}</Typography>
      </Box>
    )
  }

  const displayTotals = () => {
    if (!cartTotals.isEmpty()) {

      const addPadding = () => {
        if (tips && tips.get('enabled')) {
          return 2
        } else {
          return 3
        }
      }

      const cartDiscount = cartTotals.get('discount')
      const cartTax = cartTotals.get('tax')
      const cartServiceCharge = cartTotals.get('serviceCharge')

      return (
        <Box pt={addPadding()} className={classes.totals}>
          <Box pl={2} pr={2}>
            {displayTotal('Subtotal', centsToDollars(cartTotals.get('subtotal')))}
            {cartDiscount !== 0 && displayTotal('Discount', `${centsToDollars(cartDiscount)}`)}
            {cartTax !== 0 && displayTotal('Tax', centsToDollars(cartTax))}
            {tips?.get('enabled') === "true" && displayTotal('Tip', centsToDollars(cartTotals.get('tip')))}
            {cartServiceCharge !== 0 && displayTotal(`${cartTotals.get('serviceChargeName')}`, centsToDollars(cartServiceCharge), null, <Info onClick={() => setServiceChargeDialog(true)}/>)}
          </Box>
          <Box pb={3}>
            <VNButton buttonVariant='button6' text={`Pay ${centsToDollars(parseInt(getTotalInCents()))}`} onClick={handlePayClick} />
          </Box>
        </Box>
      )
    }
  }

  // tipping can be enabled / disabled per menu
  const displayTipping = () => {

    if (tips && tips.get('enabled') === "true") {

      // only show the tip values if the total is over $0
      const totalInCents = getTotalWithoutTipInCents()

      let tipsDisplayArray = [[], []]
      let showTipSuggestions = false

      if (tips?.get('suggestions').length > 0) {
        showTipSuggestions = true
        tips.get('suggestions').forEach(tip => {

          const tipValue = parseFloat(tip.tipPercentage)

          if (tipValue === 0) {
            tipsDisplayArray[1][1] = {buttonVariant: 'button2', selected: tip.tipSelected === "true" ? true : false, title: tip.tipDisplay, id: 'none'}
          } else if (tip.tipCustom === "true") {
            tipsDisplayArray[1][0] = {buttonVariant: 'button2', selected: tip.tipSelected === "true" ? true : false, title: 'Custom Tip', id: 'custom', disableRipple: true}
          } else if (totalInCents > 0) {
            tipsDisplayArray[0].push({buttonVariant: 'button3', selected: tip.tipSelected === "true" ? true : false, title: tip.tipDisplay, id: tipValue})
          }
        })
      }

      return (
        <Box>
          <Typography className={classes.tipText} variant='h5'>Add a Tip</Typography>
          {showTipSuggestions && <VNButtonContainer
            onClick={onButtonContainerClick}
            buttons = {tipsDisplayArray}
          />
          }
        </Box>
      )
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.list}>
        {displayTipping()}
        {displayTotals()}
      </Box>
      <VNInputDialog
        open={openDialog}
        title={"Custom Tip"}
        onSave={onDialogSave}
        onClose={() => { setOpenDialog(false) }}
        fullWidth
        closeOnSave
        endAdornment
        width={'480px'}
        money
      />
      <VNDialog
        open={serviceChargeDialog}
        subtitleVariant={'h5'}
        subTitle={"Please note that all food and beverage items are subject to a 20% service charge plus applicable sales tax.  This service charge is not a tip or gratuity and is not distributed to service employees.  Additional payment for tips or gratuity for service, if any, is voluntary and at your discretion."}
        save={"OKAY"}
        onSave={() => setServiceChargeDialog(false)}
        fullWidth
        height={460}
        width={624}
      />
    </Box>
  )
}