import { get } from 'lodash'

export const getHighlightedItemsById = (state) => {
    const highlightedIds = get(state, 'search.highlightedIds', [])
    const highlightedItemsById = highlightedIds.reduce((byId, itemId) => {
        return { ...byId, [itemId] : getItem(state, itemId) }
    }, {})
    return highlightedItemsById
}

export const getHighlightedIds = (state) => {
    const highlightedIds = get(state, 'search.highlightedIds', [])
    return highlightedIds
}

export const getSearchQuery = (state) => {
    const searchQuery = get(state, 'search.query', '')
    return searchQuery
}

export const getQuickSearchFlag = (state) => {
   return state?.search?.useQuickSearchFlag
}

export const getFocusedItemId = (state) => {
    const focusedItemId = get(state, 'search.focusedItemId', '')
    return focusedItemId
}

export const getHasQuickSearchResults = (state) => {
    const hasQuickSearchResults = get(state, 'search.hasQuickSearchResults', false)
    return hasQuickSearchResults
}

export const getSelectedCategory = (state) => {
    const selectedCategory = get(state, 'search.selectedCategory', false)
    return selectedCategory
}

const getItem = (state, id) => {
    const item = get(state, `item.byId.${id}`, {})
    const cartQuantity = get(state, `cart.quantityById.${id}`, 0)
  
    return { ...item, cartQuantity }
}