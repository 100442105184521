import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import QRCodeRegistration from '../components/QRCodeRegistration'
import useInterval from '../hooks/useInterval'

import styles from './RegisterDevice.module.scss'

const RegisterDevice = ({ history, className }) => {
  const classNames = cn(styles.registerDevice, className)

  useInterval(() => { history.push('/device-login') }, 30000)

  return (
    <div className={classNames}>
      <div className={styles.qrCode}>
        <h1>Register Device</h1>
        <QRCodeRegistration className={styles.qrCodeRegistration}/>
        <h2>Scan with camera</h2>
      </div>
      <Link to={'/device-login'} className={styles.doneButton}>DONE</Link>
    </div>
  )
}

export default withRouter(RegisterDevice)
