import { get, keys } from 'lodash'
import { LOAD_REVENUE_CENTERS_SUCCEEDED, SET_ACTIVE_REVENUE_CENTER } from '../actions/revenueCenter'
import { MENUS_SUCCEEDED } from '../actions/menus'
import DataMapper from '../utils/dataMapper'

const defaultState = { byId: {}, allIds: [], activeRevenueCenter: ''}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_REVENUE_CENTERS_SUCCEEDED: {
      const byId = DataMapper.objectsById(get(action, 'payload.data.stands', []))
      const allIds = keys(byId)

      return {
        ...state,
        byId: { ...byId, 'unassigned': { uuid: 'unassigned', name: 'Unassigned Orders' } },
        allIds: [...allIds, 'unassigned'],
      }
    }

    case SET_ACTIVE_REVENUE_CENTER: {
      return {
        ...state,
        activeRevenueCenter: get(action, 'payload.revenueCenterId')
      }
    }

    case MENUS_SUCCEEDED: {
      const stands = action?.payload?.data?.stands ?? []
      const activeRevenueCenter = stands?.[0]

      return {
        ...state,
        byId: {
          ...state.byId,
          [activeRevenueCenter?.uuid]: activeRevenueCenter,
        },
        activeRevenueCenter: activeRevenueCenter?.uuid,
      }
    }

    default:
      return state
  }
}

export default reducer
