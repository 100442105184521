import { memoize, get, isEmpty, reduce, last, isEqual, cloneDeep } from 'lodash'
import moment from 'moment'

const lastNth = (string, count = 1) => {
  return string.substring(string.length - count)
}

const getId = (order) => get(order, 'uuid', new Date().getTime())

const getOrderId = (order) => get(order, 'uuid', 'unknown')

const getPayment = (order, type) => {
  const cardReaderData = get(order, 'cardReaderData', {})
  const orderState = get(order, 'orderState', get(order, 'state'))
  const isPreOrder = get(order, 'isPreOrder', false)

  if (isEmpty(cardReaderData)) {
    const isNotClosed = orderState !== 'closed'

    return isNotClosed || isPreOrder ? 'Payment on file' : 'Paid with cash'
  }

  const cardIssuer = order?.cardReaderData?.cardIssuer ? order.cardReaderData.cardIssuer : order.cardReaderData.cardType
  const lastFour = lastNth(get(order, 'cardReaderData.receiptData.maskedCardNumber', '----'), 4)
  const cardholderName = get(order, 'cardReaderData.cardholderName', '')

  if (type === 'row') {
    return `${cardIssuer} ${lastFour} ${cardholderName}`
  }

  if (type === 'detail') {
    return `${cardholderName}${isEmpty(cardholderName) ? '' : ' / '}${cardIssuer} ${lastFour}`
  }
}

const getRowPayment = (order) => getPayment(order, 'row')

const getDetailPayment = (order) => getPayment(order, 'detail')

const getAmount = (order) => `$${(get(order, 'amountInCents', 0) / 100).toFixed(2)}` || '--'

const getModifiedAt = (order) => {
  let modifiedAt = get(order, 'modifiedAt', 'unknown')

  if (modifiedAt !== 'unknown') {
    modifiedAt = moment(modifiedAt).format('h:mm a MMM D')
  }

  return modifiedAt
}

const getOrderNumber = (order) => get(order, 'orderNumber', 'unknown')

const getSyncStatus = (order) => !get(order, 'syncedAt') ? 'unsynced' : 'synced'

const getOrderStatus = (order) => get(order, 'orderState', 'open')

const getItemCount = (order) => get(order, 'itemModels.length', 0)

const getSubtotal = (order) => {
  const itemModels = get(order, 'itemModels', [])

  const priceInCents = reduce(itemModels, (accumulator, item) => {
    const quantity = get(item, 'quantity', 0)
    const itemModifiers = get(item, 'modifiers', [])
    const itemSubtotal = get(item, 'priceInCents', 0) * quantity

    const modifiersSubtotal = reduce(itemModifiers, (acc, modifierItem) => {
      const priceInCents = get(modifierItem, 'defaultPriceInCents', 0)
      return acc + priceInCents * quantity
    }, 0)

    const lineItemTotal = itemSubtotal + modifiersSubtotal

    return accumulator + lineItemTotal
  }, 0)

  return `$${(priceInCents / 100).toFixed(2)}` || '--'
}

const getTax = (order, taxRate) => `$${((get(order, 'amountInCents', 0) * taxRate) / 100).toFixed(2)}`

const getTip = (order) => `$${(get(order, 'tipAmountInCents', 0) / 100).toFixed(2)}`

const getItems = (order) => {
  const itemModels = get(order, 'itemModels', [])
  const result = itemModels.map((item) => ({ ...item, quantity: 1 }))

  return result
}

const getGroupedItems = (order) => {
  const itemModels = get(order, 'itemModels', [])
  const result = reduce(itemModels, (accumulator, item) => {
    const idsMatch = isEqual((last(accumulator) || {}).id, item.id)
    const modifiersMatch = isEqual((last(accumulator) || {}).modifiers, item.modifiers)

    if (idsMatch && modifiersMatch) {
      last(accumulator).quantity += 1
    } else {
      accumulator.push(item)
    }

    return accumulator
  }, [])

  return result
}

const getIsPreOrder = (order) => get(order, 'isPreOrder', false)

const TabOrderRowViewModel = memoize((order) => {
  const clonedOrder = cloneDeep(order)

  return {
    id: getId(clonedOrder),
    orderId: getOrderId(clonedOrder),
    payment: getRowPayment(clonedOrder),
    amount: getAmount(clonedOrder),
    modifiedAt: getModifiedAt(clonedOrder),
    orderNumber: getOrderNumber(clonedOrder),
    syncStatus: getSyncStatus(clonedOrder),
    orderStatus: getOrderStatus(clonedOrder),
    isPreOrder: getIsPreOrder(clonedOrder),
  }
})

const TabOrderDetailsViewModel = memoize((order, taxRate) => {
  const clonedOrder = cloneDeep(order)

  return {
    id: getId(clonedOrder),
    orderId: getOrderId(clonedOrder),
    payment: getDetailPayment(clonedOrder),
    amount: getAmount(clonedOrder),
    modifiedAt: getModifiedAt(clonedOrder),
    orderNumber: getOrderNumber(clonedOrder),
    syncStatus: getSyncStatus(clonedOrder),
    orderStatus: getOrderStatus(clonedOrder),
    subtotal: getSubtotal(clonedOrder),
    tax: getTax(clonedOrder, taxRate),
    total: getAmount(clonedOrder),
    tip: getTip(clonedOrder),
    itemCount: getItemCount(clonedOrder),
    items: getItems(clonedOrder),
    groupedItems: getGroupedItems(clonedOrder),
  }
})

export { TabOrderRowViewModel, TabOrderDetailsViewModel }
