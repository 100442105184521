import React from 'react'
import { times } from 'lodash'
import cn from 'classnames'

import styles from './SecureDots.module.scss'

const SecureDots = ({ count = 4, subjectSize = 0 }) => {
  return (
    <div className={styles.secureDots}>
      {times(count, (index) => {
        const active = index + 1 <= subjectSize

        return (
          <span key={index} className={cn(styles.secureDot, { [styles.active]: active })}></span>
        )
      })}
    </div>
  )
}

export default SecureDots
