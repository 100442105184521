export const CLEAR_SELECTED_ORDER = 'CLEAR_SELECTED_ORDER'
export const CLEAR_ORDER_SEARCH = 'CLEAR_ORDER_SEARCH'
export const FILTER_ORDERS = 'FILTER_ORDERS'

export const REPLAY_AUTH_REQUESTED = 'REPLAY_AUTH_REQUESTED'
export const REPLAY_AUTH_REQUEST_SUCCEEDED = 'REPLAY_AUTH_REQUEST_SUCCEEDED'
export const REPLAY_AUTH_REQUEST_FAILED = 'REPLAY_AUTH_REQUEST_FAILED'

export const SAVE_ORDER = 'SAVE_ORDER'
export const SAVE_OFFLINE_ORDER = 'SAVE_OFFLINE_ORDER'

export const REMOVE_ORDER = 'REMOVE_ORDER'
export const UPDATE_CARD_READER_DATA = 'UPDATE_CARD_READER_DATA'

export const START_A_TAB = 'START_A_TAB'
export const CLOSE_TAB_ORDER = 'CLOSE_TAB_ORDER'
export const ADD_ITEMS_TO_TAB = 'ADD_ITEMS_TO_TAB'
export const ADD_ITEMS_TO_TAB_SUCCEEDED = 'ADD_ITEMS_TO_TAB_SUCCEEDED'
export const ADD_ITEMS_TO_TAB_FAILED = 'ADD_ITEMS_TO_TAB_FAILED'
export const ADD_ITEMS_TO_TAB_OFFLINE = 'ADD_ITEMS_TO_TAB_OFFLINE'
export const ADD_ITEMS_TO_ORDER = 'ADD_ITEMS_TO_ORDER'
export const ADD_DISCOUNTS_TO_TAB_ORDER = 'ADD_DISCOUNTS_TO_TAB_ORDER'
export const DELETE_DISCOUNTS_FROM_TAB_ORDER = 'DELETE_DISCOUNTS_FROM_TAB_ORDER'

export const REPLACE_ITEMS_FROM_ORDER = 'REPLACE_ITEMS_FROM_ORDER'
export const UPDATE_ORDER_TOTALS = 'UPDATE_ORDER_TOTALS'

export const RESET_MUTATION_IN_PROGRESS = 'RESET_MUTATION_IN_PROGRESS'

export const START_OFFLINE_CARD_ORDER = 'START_OFFLINE_CARD_ORDER'
export const START_ONLINE_CARD_ORDER = 'START_ONLINE_CARD_ORDER'
export const CLOSE_NO_OP_TENDER = 'CLOSE_NO_OP_TENDER'

export const SEARCH_ORDER = 'SEARCH_ORDER'
export const SELECT_ORDER = 'SELECT_ORDER'

export const SYNC_ORDERS_REQUESTED = 'SYNC_ORDERS_REQUESTED'
export const SYNC_REPLAY_AUTH_ORDERS_REQUESTED = 'SYNC_REPLAY_AUTH_ORDERS_REQUESTED'

export const SYNC_AND_SAVE_ORDER_IN_PROGRESS = 'SYNC_AND_SAVE_ORDER_IN_PROGRESS'
export const SYNC_ORDER_INITIATED = 'SYNC_ORDER_INITIATED'
export const SYNC_ORDER_REQUESTED = 'SYNC_ORDER_REQUESTED'
export const SYNC_ORDER_SUCCEEDED = 'SYNC_ORDER_SUCCEEDED'
export const SYNC_ORDER_FAILED = 'SYNC_ORDER_FAILED'
export const SYNC_STATUS_RESET = 'SYNC_STATUS_RESET'

export const CREATE_OFFLINE_ORDER_SUCCEEDED = 'CREATE_OFFLINE_ORDER_SUCCEEDED'
export const CREATE_OFFLINE_ORDER_FAILED = 'CREATE_OFFLINE_ORDER_FAILED'
export const SYNC_OFFLINE_ORDER_REQUESTED = 'SYNC_OFFLINE_ORDER_REQUESTED'

export const UPDATE_ORDER_SUCCEEDED = 'UPDATE_OFFLINE_ORDER_SUCCEEDED'
export const UPDATE_ORDER_FAILED = 'UPDATE_OFFLINE_ORDER_FAILED'

export const VOID_ORDER_SUCCEEDED = 'VOID_ORDER_SUCCEEDED'
export const VOID_ORDER_FAILED = 'VOID_ORDER_FAILED'

export const ADD_SIGNATURE_TO_ORDER = 'ADD_SIGNATURE_TO_ORDER'
export const ADD_TIP_TO_ORDER = 'ADD_TIP_TO_ORDER'

export const SET_KIOSK_PAYMENT = 'SET_KIOSK_PAYMENT'

export const PURGE_ORDERS = 'PURGE_ORDERS'
export const PURGE_ALL_ORDERS = 'PURGE_ALL_ORDERS'

export const REQUEST_PERFORM_MAINTENANCE = 'REQUEST_PERFORM_MAINTENANCE'
export const PERFORM_MAINTENANCE = 'PERFORM_MAINTENANCE'
export const UPDATE_MAINTENANCE_TIMESTAMP = 'UPDATE_MAINTENANCE_TIMESTAMP'
export const SET_MAINTENANCE_ENABLED = 'SET_MAINTENANCE_ENABLED'

export const SET_UPDATED_SINCE = 'SET_UPDATED_SINCE'

export const TAB_ORDERS_REQUESTED = 'TAB_ORDERS_REQUESTED'
export const LOAD_TAB_ORDERS_SUCCEEDED = 'LOAD_TAB_ORDERS_SUCCEEDED'
export const LOAD_TAB_ORDERS_FAILED = 'LOAD_TAB_ORDERS_FAILED'

export const ORDER_DETAILS_REQUESTED = 'ORDER_DETAILS_REQUESTED'
export const LOAD_ORDER_DETAILS_SUCCEEDED = 'LOAD_ORDER_DETAILS_SUCCEEDED'
export const LOAD_ORDER_DETAILS_FAILED = 'LOAD_ORDER_DETAILS_FAILED'

export const ORDER_TOTAL_REQUESTED = 'ORDER_TOTAL_REQUESTED'
export const ORDER_TOTAL_SUCCEEDED = 'ORDER_TOTAL_SUCCEEDED'
export const ORDER_TOTAL_FAILED = 'ORDER_TOTAL_FAILED'

export const STORE_ORDER_UUID_AND_ORDER_NUMBER = 'STORE_ORDER_UUID_AND_ORDER_NUMBER'
export const CLEAR_STORED_ORDER_UUID_AND_ORDER_NUMBER = 'CLEAR_STORED_ORDER_UUID_AND_ORDER_NUMBER'

export const SEND_ORDER_EMAIL = 'SEND_ORDER_EMAIL'
export const SEND_ORDER_TEXT = 'SEND_ORDER_TEXT'
export const SET_ORDER_IN_PROGRESS = 'SET_ORDER_IN_PROGRESS'
export const UPDATE_ORDER_IN_PROGRESS = 'UPDATE_ORDER_IN_PROGRESS'
export const ADD_PAYMENT_TO_ORDER_IN_PROGRESS = 'ADD_PAYMENT_TO_ORDER_IN_PROGRESS'
export const CLEAR_ORDER_IN_PROGRESS = 'CLEAR_ORDER_IN_PROGRESS'
export const SAVE_AND_SYNC_ORDER_IN_PROGRESS = 'SAVE_AND_SYNC_ORDER_IN_PROGRESS'
export const ADD_TIP_ORDER_IN_PROGRESS = 'ADD_TIP_ORDER_IN_PROGRESS'

export const REMOVE_QR_PAYMENT_AND_UNSYNCABLE_STATUS = 'REMOVE_QR_PAYMENT_AND_UNSYNCABLE_STATUS'
export const SET_ORDER_IN_PROGRESS_WITH_ID = 'SET_ORDER_IN_PROGRESS_WITH_ID'
export const RESTORE_QR_PAYMENT_AND_UNSYNCABLE_STATUS = 'RESTORE_QR_PAYMENT_AND_UNSYNCABLE_STATUS'
export const SET_QR_PAY_AUTH_ERROR = 'SET_QR_PAY_AUTH_ERROR'
export const DUPLICATE_ORDER_WITH_NEW_IDS = 'DUPLICATE_ORDER_WITH_NEW_IDS'
export const ADD_NEW_PAYMENTS = 'ADD_NEW_PAYMENTS'
export const REMOVE_PAYMENT = 'REMOVE_PAYMENT'
export const UPDATE_SAVED_PAYMENT = 'UPDATE_SAVED_PAYMENT'

export const CLOSE_PRE_AUTHED_RC_ORDER = 'CLOSE_PRE_AUTHED_RC_ORDER'
export const ORDER_REPORTS_LAST_FETCHED_AT = 'ORDER_REPORTS_LAST_FETCHED_AT'

export const setKioskPayment = (paymentType) => {
  return ({
    type: SET_KIOSK_PAYMENT,
    payload: { paymentType }
  })
}

export const storeOrderUuidAndOrderNumber = ({ orderUuid, orderNumber }) => ({
  type: STORE_ORDER_UUID_AND_ORDER_NUMBER,
  payload: { orderUuid, orderNumber }
})

export const clearStoredOrderUuidAndOrderNumber = () => ({
  type: CLEAR_STORED_ORDER_UUID_AND_ORDER_NUMBER,
})

export const purgeOrders = (orderIds) => ({
  type: PURGE_ORDERS,
  payload: { orderIds },
})

export const purgeAllOrders = (orderIds) => ({
  type: PURGE_ALL_ORDERS,
  payload: { },
})

export const removeOrder = (orderId) => ({
  type: REMOVE_ORDER,
  payload: { orderId },
})

export const updateCardReaderData = (payload) => ({
  type: UPDATE_CARD_READER_DATA,
  payload,
})

export const clearOrderSearch = () => ({
  type: CLEAR_ORDER_SEARCH,
})

export const clearSelectedOrder = () => ({
  type: CLEAR_SELECTED_ORDER,
})

export const filterOrders = (filter) => ({
  type: FILTER_ORDERS,
  payload: filter,
})

export const replayAuthRequested = (orderId, lastId) => ({
  type: REPLAY_AUTH_REQUESTED,
  orderId,
  lastId,
})

export const replayAuthRequestSucceeded = (payload) => ({
  type: REPLAY_AUTH_REQUEST_SUCCEEDED,
  payload,
})

export const replayAuthRequestFailed = (payload) => ({
  type: REPLAY_AUTH_REQUEST_FAILED,
  payload,
})

export const syncAndSaveOrderInProgress = () => ({
  type: SYNC_AND_SAVE_ORDER_IN_PROGRESS,
})

export const saveOrder = (payload, addToSyncArray = true, removeFromCardSyncArray = false) => ({
  type: SAVE_ORDER,
  payload,
  addToSyncArray,
  removeFromCardSyncArray,
})

export const saveOfflineOrder = (payload) => ({
  type: SAVE_OFFLINE_ORDER,
  payload,
})

export const searchOrders = (search) => ({
  type: SEARCH_ORDER,
  payload: search,
})

export const selectOrder = (id) => ({
  type: SELECT_ORDER,
  payload: id,
})

export const startATab = (payload) => ({
  type: START_A_TAB,
  payload,
})

export const addItemsToTab = (payload) => ({
  type: ADD_ITEMS_TO_TAB,
  payload,
})

export const addItemsToTabSucceeded = (payload) => ({
  type: ADD_ITEMS_TO_TAB_SUCCEEDED,
  payload,
})

export const addItemsToTabFailed = (payload) => ({
  type: ADD_ITEMS_TO_TAB_FAILED,
  payload,
})

export const addItemsToTabOffline = (payload) => ({
  type: ADD_ITEMS_TO_TAB_OFFLINE,
  payload,
})

export const addItemsToOrder = (payload) => ({
  type: ADD_ITEMS_TO_ORDER,
  payload
})

export const addDiscountsToTabOrder = (payload) => ({
  type: ADD_DISCOUNTS_TO_TAB_ORDER,
  payload
})

export const deleteDiscountsFromTabOrder = (payload) => ({
  type: DELETE_DISCOUNTS_FROM_TAB_ORDER,
  payload
})


export const replaceItemsFromOrder = (payload) => ({
  type: REPLACE_ITEMS_FROM_ORDER,
  payload
})

export const updateOrderTotals = (payload) => ({
  type: UPDATE_ORDER_TOTALS,
  payload
})

export const resetMutationInProgress = (payload) => ({
  type: RESET_MUTATION_IN_PROGRESS,
  payload
})

export const startOfflineCardOrder = (payload) => ({
  type: START_OFFLINE_CARD_ORDER,
  payload,
})

export const startOnlineCardOrder = (payload) => ({
  type: START_ONLINE_CARD_ORDER,
  payload,
})

export const closeNoOpTender = (payload) => ({
  type: CLOSE_NO_OP_TENDER,
  payload,
})

export const syncOrdersRequested = () => ({
  type: SYNC_ORDERS_REQUESTED,
})

export const syncReplayAuthOrdersRequested = () => ({
  type: SYNC_REPLAY_AUTH_ORDERS_REQUESTED,
})

export const syncOrderInitiated = (id) => ({
  type: SYNC_ORDER_INITIATED,
  id,
})

export const syncOrderRequested = (id, redirect, kioskDirectTo, displayOrderToast = true) => ({
  type: SYNC_ORDER_REQUESTED,
  id,
  redirect,
  kioskDirectTo,
  displayOrderToast
})

export const syncOrderSucceeded = (payload, order) => ({
  type: SYNC_ORDER_SUCCEEDED,
  payload,
  order,
})

export const syncOrderFailed = (payload, order) => ({
  type: SYNC_ORDER_FAILED,
  payload,
  order,
})

export const syncStatusReset = () => ({
  type: SYNC_STATUS_RESET
})

export const syncOfflineOrderRequested = (id) => ({
  type: SYNC_OFFLINE_ORDER_REQUESTED,
  id,
})

export const createOfflineOrderSucceeded = (payload, uuid) => ({
  type: CREATE_OFFLINE_ORDER_SUCCEEDED,
  payload,
  uuid,
})

export const createOfflineOrderFailed = (uuid, order, err) => ({
  type: CREATE_OFFLINE_ORDER_FAILED,
  uuid,
  order,
  err,
})

export const updateOrderSucceeded = (payload, uuid) => ({
  type: UPDATE_ORDER_SUCCEEDED,
  payload,
  uuid,
})

export const updateOrderFailed = (uuid, order, err) => ({
  type: UPDATE_ORDER_FAILED,
  uuid,
  order,
  err,
})

export const voidOrderSucceeded = (orderId, freedomPayResponse) => ({
  type: VOID_ORDER_SUCCEEDED,
  orderId,
  freedomPayResponse,
})

export const voidOrderFailed = (orderId, freedomPayResponse) => ({
  type: VOID_ORDER_FAILED,
  orderId,
  freedomPayResponse,
})

export const addSignatureToOrder = (orderUuid, signature) => ({
  type: ADD_SIGNATURE_TO_ORDER,
  payload: { orderUuid, signature }
})

export const addTipToOrder = (orderUuid, tipAmountInCents) => ({
  type: ADD_TIP_TO_ORDER,
  payload: { orderUuid, tipAmountInCents }
})

export const tabOrdersRequested = () => ({
  type: TAB_ORDERS_REQUESTED
})

tabOrdersRequested.type = TAB_ORDERS_REQUESTED

export const loadTabOrdersSucceeded = (payload) => ({
  type: LOAD_TAB_ORDERS_SUCCEEDED,
  payload
})

export const loadTabOrdersFailed = (payload) => ({
  type: LOAD_TAB_ORDERS_FAILED,
  payload
})

export const orderDetailsRequested = (orderUuid) => ({
  type: ORDER_DETAILS_REQUESTED,
  payload: { orderUuid }
})

orderDetailsRequested.type = ORDER_DETAILS_REQUESTED

export const loadOrderDetailsSucceeded = (payload) => ({
  type: LOAD_ORDER_DETAILS_SUCCEEDED,
  payload
})

export const loadOrderDetailsFailed = (payload) => ({
  type: LOAD_ORDER_DETAILS_FAILED,
  payload
})

export const getOrderTotal = (payload) => ({
  type: ORDER_TOTAL_REQUESTED,
  payload
})

getOrderTotal.type = ORDER_TOTAL_REQUESTED

export const getOrderTotalSucceeded = (payload) => ({
  type: ORDER_TOTAL_SUCCEEDED,
  payload
})

getOrderTotalSucceeded.type = ORDER_TOTAL_SUCCEEDED

export const getOrderTotalFailed = (payload) => ({
  type: ORDER_TOTAL_FAILED,
  payload
})

getOrderTotalFailed.type = ORDER_TOTAL_FAILED

export const requestPerformMaintenance = () => ({
  type: REQUEST_PERFORM_MAINTENANCE,
})

export const updateMaintenancedTimestamp = (maintenancedAt) => ({
  type: UPDATE_MAINTENANCE_TIMESTAMP,
  payload: { maintenancedAt },
})

export const setMaintenanceEnabled = (maintenanceEnabled) => ({
  type: SET_MAINTENANCE_ENABLED,
  payload: { maintenanceEnabled },
})

export const performMaintenance = (orderIds, maintenancedAt) => ({
  type: PERFORM_MAINTENANCE,
  payload: { orderIds, maintenancedAt }
})

export const sendOrderEmail = (payload) => ({
  type: SEND_ORDER_EMAIL,
  payload,
})

export const setUpdatedSince = () => ({
  type: SET_UPDATED_SINCE,
})

export const sendOrderText = (orderUuid, userPhone) => ({
  type: SEND_ORDER_TEXT,
  payload: { orderUuid, userPhone }
})

export const setOrderInProgress = (payload) => ({
  type: SET_ORDER_IN_PROGRESS,
  payload,
})

export const updateOrderInProgress = (payload) => ({
  type: UPDATE_ORDER_IN_PROGRESS,
  payload,
})

export const addPaymentToOrderInProgress = (payload) => ({
  type: ADD_PAYMENT_TO_ORDER_IN_PROGRESS,
  payload,
})

export const clearOrderInProgress = () => ({
  type: CLEAR_ORDER_IN_PROGRESS,
})

export const addTipOrderInProgress = (payload) => ({
  type: ADD_TIP_ORDER_IN_PROGRESS,
  payload,
})

export const closeTabOrder = (payload) => ({
  type: CLOSE_TAB_ORDER,
  payload,
})

export const removeQRPaymentandUnsyncableStatus = (payload) => ({
  type: REMOVE_QR_PAYMENT_AND_UNSYNCABLE_STATUS,
  payload,
})

export const restoreQRPaymentandUnsyncableStatus = (payload) => ({
  type: RESTORE_QR_PAYMENT_AND_UNSYNCABLE_STATUS,
  payload,
})

export const setOrderInProgressWithId = (payload) => ({
  type: SET_ORDER_IN_PROGRESS_WITH_ID,
  payload,
})

export const setQRPayAuthError = (payload) => ({
  type: SET_QR_PAY_AUTH_ERROR,
  payload,
})

export const duplicateOrderWithNewIds = (payload) => ({
  type: DUPLICATE_ORDER_WITH_NEW_IDS,
  payload,
})

export const closePreAuthedRCOrder = (payload) => ({
  type: CLOSE_PRE_AUTHED_RC_ORDER,
  payload,
})

export const addNewPayments = (payload) => ({
  type: ADD_NEW_PAYMENTS,
  payload,
})

/**
 * Remove Payment from Order's neededToBeVoidedAuthPayment or paymentInfo
 * 
 * @param {object} payload 
 * @param {number} paymentIndex - local index of payment to remove
 * @param {bool} silentFail - attempt to remove payment without displaying errors
 * 
 * @returns 
 */
export const removePayment = (payload, paymentIndex, silentFail = false) => ({
  type: REMOVE_PAYMENT,
  order: payload?.order,
  paymentIndex,
  silentFail,
})

export const updateSavedPayment = (payload) => ({
  type: UPDATE_SAVED_PAYMENT,
  payload,
})

export const orderReportsLastFetchedAt = (payload) => ({
  type: ORDER_REPORTS_LAST_FETCHED_AT,
  payload,
})
