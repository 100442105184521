import { partition, omit, get } from 'lodash'

const partition_refund_ids = {
  1: (state) => {
    const refundIds = get(state, 'refund.successRemoteIds', [])
    const orderIds = get(state, 'order.successRemoteIds', [])
    const partitionedRefundIds = partition(refundIds, (id) => { orderIds.includes(id) } )
    const refundIdsToKeep = partitionedRefundIds[0]
    const refundIdsToRemove = partitionedRefundIds[1]
    return {
      ...state,
      refund: {
        byId: {
          ...omit(state.refund.byId, refundIdsToRemove),
        },
        successRemoteIds: refundIdsToKeep,
      }
    }
  }
}

export default partition_refund_ids
