import { get, map, find } from 'lodash'
import { calculateTax, calculateSubtotal, calculateServiceCharge } from './totalUtils'
import { invoiceNumber, nextPosOrderNumber } from './orderNumbers'
import generateUuid from 'uuid/v4'
import { centsToDollars } from './formatters'

export const DEFAULT_EXEMPTIONS = {
  applyDiscount: true,
  applySalesTax: true,
  applyServiceFee: true,
}

// adds display attributes for fees, subtotal, and total
export const formatOrderTotals = (args = {}) => {
  const {
    discountAmountInCents,
    lineItems,
    promotions,
    serviceChargeInCents,
    taxAmountInCents,
    tipAmountInCents,
    totalAmountInCents,
  } = args

  const serviceChargeLineItem = find(lineItems, (li) => {
    return li.specialType === 'service_charge' || li.specialType === 'taxable_service_charge'
  })
  const serviceChargeTaxInCents = parseFloat(serviceChargeLineItem?.taxAmountInCents ?? 0)
  const serviceChargeDiscountInCents = parseFloat(serviceChargeLineItem?.promotions?.[0]?.discountAmountInCents ?? 0)
  const serviceChargeName = serviceChargeLineItem?.name ?? 'Service Charge'

  const safeTipCents = tipAmountInCents || 0
  const fees = discountAmountInCents + serviceChargeInCents + taxAmountInCents + safeTipCents
  const subtotalInCents = totalAmountInCents - fees - Math.abs(serviceChargeDiscountInCents)
  const promotionParamsForClient = makeClientPromotionParams(promotions)
  const taxesByExemptions = parseExemptableTaxes(lineItems)

  return {
    ...args,
    discountDisplay: centsToDollars(discountAmountInCents),
    promotionParamsForClient,
    serviceChargeInCents,
    serviceChargeTaxInCents,
    serviceChargeDiscountInCents,
    serviceChargeName,
    serviceChargeDisplay: centsToDollars(serviceChargeInCents),
    subtotalDisplay: centsToDollars(subtotalInCents),
    subtotalInCents,
    taxDisplay: centsToDollars(taxAmountInCents),
    totalDisplay: centsToDollars(totalAmountInCents),
    ...taxesByExemptions,
  }
}

// adds display attributes for fees, subtotal, and total
export const formatOrderDetails = (args = {}) => {
  const {
    discountAmountInCents,
    lineItems,
    promotions,
    serviceChargeInCents,
    taxAmountInCents,
    tipAmountInCents,
    totalAmountInCents,
  } = args

  const serviceChargeLineItem = lineItems.find((li) => {
    return li.itemSpecialType === 'service_charge' || li.itemSpecialType === 'taxable_service_charge'
  })
  const serviceChargeTaxInCents = parseFloat(serviceChargeLineItem?.taxAmountInCents ?? 0)
  const serviceChargeName = serviceChargeLineItem?.name ?? 'Service Charge'

  const safeTipCents = tipAmountInCents || 0
  const fees = discountAmountInCents + serviceChargeInCents + taxAmountInCents + safeTipCents
  const subtotalInCents = totalAmountInCents - fees
  const promotionParamsForClient = makeClientPromotionParams(promotions)
  const taxesByExemptions = parseExemptableTaxes(lineItems)

  return {
    ...args,
    discountDisplay: centsToDollars(discountAmountInCents),
    promotionParamsForClient,
    serviceChargeDisplay: centsToDollars(serviceChargeInCents),
    serviceChargeTaxInCents,
    serviceChargeName,
    subtotalDisplay: centsToDollars(subtotalInCents),
    subtotalInCents,
    taxDisplay: centsToDollars(taxAmountInCents),
    totalDisplay: centsToDollars(totalAmountInCents),
    ...taxesByExemptions,
  }
}

export const makeClientPromotionParams = (promotions = []) => {
  return map(promotions, (p) => {
    return { 
      uuid: get(p, 'uuid'),
      name: get(p, 'name'),
      redemptionMethod: get(p, 'redemptionMethod')
     }
  })
}

// remote order total might not take exemptions into consideration
// currently just need to handle tax, but could be extended if/when needed

export const parseExemptableTaxes = (lineItems = []) => {
  let exemptableTaxRules = {}
  let exemptableTaxAmountInCents = 0
  let nonExemptableTaxRules = {}
  let nonExemptableTaxAmountInCents = 0

  lineItems.forEach(lineItem => {
    const taxRules = get(lineItem, 'taxRules') || []

    taxRules.forEach(taxRule => {
      const uuid = get(taxRule, 'taxRuleUuid', 'unknown')
      const taxInCents = get(taxRule, 'taxAmountInCents', 0)

      const isExempt = get(taxRule, 'exempt', false)
      isExempt ? exemptableTaxAmountInCents += taxInCents : nonExemptableTaxAmountInCents += taxInCents

      let collection = isExempt ? exemptableTaxRules : nonExemptableTaxRules
      const oldRuleData = get(collection, uuid, {})
      const newRuleData = {
        ...oldRuleData,
        ...taxRule,
        lineItemCount: get(oldRuleData, 'lineItemCount', 0) + 1,
        taxAmountInCents: taxInCents + get(oldRuleData, 'taxAmountInCents', 0),
      }

      collection[uuid] = newRuleData
    })
  })

  return {
    exemptableTaxRules,
    exemptableTaxAmountInCents,
    exemptableTaxDisplay: centsToDollars(exemptableTaxAmountInCents),
    nonExemptableTaxRules,
    nonExemptableTaxAmountInCents,
    nonExemptableTaxDisplay: centsToDollars(nonExemptableTaxAmountInCents),
  }
}

const getOrderTotal = (subtotalAmountInCents, discount, orderInProgress, remoteOrderTotal, taxAmount, cartItems, serviceCharge = 0) => {
  if (subtotalAmountInCents + discount === 0) return 0

  if (orderInProgress && 'amountInCents' in orderInProgress) return orderInProgress.amountInCents
  if (orderInProgress &&  'totalAmountInCents' in orderInProgress) return orderInProgress.totalAmountInCents
  if (remoteOrderTotal && 'totalAmountInCents' in remoteOrderTotal) return remoteOrderTotal?.totalAmountInCents

  return calculateSubtotal(cartItems) + taxAmount + (serviceCharge ?? 0)
}

/**
 * Calculates the cart totals and moves on - only used in CFD and POS mode
 * @param {Boolean} richCheckout a boolean depending on which button was press App or Card
 * @returns {Object} - Cart totals required for CFD to make a payment
 */
export const calculateCartTotals = (remoteOrderTotal, cartItems, taxRate, taxByItem, menuServiceCharge, orderInProgress, isFinalizingTip, tenderData) => {

  const discount = orderInProgress?.discountAmountInCents || remoteOrderTotal?.discountAmountInCents || 0
  const taxAmount = orderInProgress?.taxAmountInCents ?? remoteOrderTotal?.taxAmountInCents ?? calculateTax(cartItems, taxRate, taxByItem)
  const subtotalAmountInCents = orderInProgress?.subtotalAmountInCents ?? remoteOrderTotal?.subtotalAmountInCents ?? calculateSubtotal(cartItems)
  let serviceCharge = orderInProgress?.serviceChargeInCents ?? remoteOrderTotal?.serviceChargeInCents ?? (calculateServiceCharge(menuServiceCharge, subtotalAmountInCents) ?? 0)
  const orderTotal = getOrderTotal(subtotalAmountInCents, discount, orderInProgress, remoteOrderTotal, taxAmount, cartItems, serviceCharge) 
  serviceCharge = subtotalAmountInCents + discount === 0 ? 0 : serviceCharge
  const serviceChargeName = orderInProgress?.serviceChargeName || remoteOrderTotal?.serviceChargeName || menuServiceCharge?.name || "Service Charge"

  const orderNumber = orderInProgress?.orderNumber || nextPosOrderNumber()
  const orderUuid = orderInProgress?.uuid || remoteOrderTotal?.uuid || generateUuid()

  return {
    subtotal: subtotalAmountInCents,
    discount: discount,
    tax: taxAmount,
    tip: '0',
    total: orderTotal,
    uuid: orderUuid,
    serviceCharge: serviceCharge,
    serviceChargeName: serviceChargeName,
    orderNumber: orderNumber,
    hRef: invoiceNumber(orderNumber),
    invoiceNumber: invoiceNumber(orderNumber),
    isFinalizingTip,
    tenderData
  }
}