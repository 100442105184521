import { useDispatch, useSelector } from "react-redux"
import { APPLICATION_STRINGS } from "../../strings"
import { setKitchenPrintErrorDialogOpen } from "../ActionCreators"
import ConfirmModalV2 from "../../components/ConfirmModalV2"
import { getKitchenPrintErrorDialogOpen } from "../Selectors"

export const VNKitchenPrintingModal = () => {
  const dispatch = useDispatch()
  const open = useSelector(state => getKitchenPrintErrorDialogOpen(state))

  if (open) {
    return (
      <ConfirmModalV2
        onButtonOneClick={() => dispatch(setKitchenPrintErrorDialogOpen(false))}
        onButtonTwoClick={() => dispatch(setKitchenPrintErrorDialogOpen(false))}
        headerText={APPLICATION_STRINGS.KITCHEN_PRINTER_ERROR_TITLE}
        subtext={APPLICATION_STRINGS.OFFLINE_KITCHEN_PRINTER_ERROR}
        buttonOneText={APPLICATION_STRINGS.OK_BUTTON_TEXT}
        buttonTwoText={APPLICATION_STRINGS.OK_BUTTON_TEXT}
        singleButtonOnly={true}
      />
    )
  }

  return <></>
}