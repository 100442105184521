import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import { get, map, isNumber, findIndex, isFinite, isEmpty } from 'lodash'
import CanvasDraw from 'react-canvas-draw'
import useDeepCompareEffect from 'use-deep-compare-effect'
import generateUuid from 'uuid/v4'

import GratuityHeader from '../components/GratuityHeader'
import Button from '../components/Button'
import SegmentedControl from '../components/SegmentedControl'
import TipEntryModal from '../components/TipEntryModal'
import ConfirmModalV2 from '../components/ConfirmModalV2'
import BackButton from '../kiosk/components/BackButton'
import OfflineModal from '../kiosk/OfflineModal'

import { makeGetCurrentMenu, getIsKiosk } from '../selectors/menus'
import { setKioskPayment, closeNoOpTender, addTipOrderInProgress, clearOrderInProgress, addPaymentToOrderInProgress, updateSavedPayment } from '../actions/order'
import { KIOSK_PAYMENT_TYPES} from '../utils/paymentTypes'
import { KEY_CODES } from "../kiosk/utils/contants"
import makeTipSuggestions from '../utils/makeTipSuggestions'
import styles from './Signature.module.scss'

import { getCartItemsCount, getTotal } from '../selectors/cart'
import { getKioskPayment, getOrderInProgress } from '../selectors/order'
import { getDefaultTipEnabled } from '../selectors/revenueCenter'
import useRemoteOrderTotal from '../hooks/useRemoteOrderTotal'
import { ReactComponent as BlackX } from '../assets/icons/signature-x.svg'
import { ReactComponent as KioskX } from '../assets/icons/black-x.svg'
import { SIGNATURE_THRESHOLD_AMOUNT } from '../constants'
import ConnectingIndicator from '../components/ConnectingIndicator'
import useCreateOrderInProgress from '../hooks/useCreateOrderInProgress'
import VNConcessionsNavBar from '../components/VNConcessionsNavBar'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import VNBottomButton from '../components/VNBottomButton'
import { isTabbed } from '../utils/orderStates'

const makeTipEntryModalTitle = (maxGratuity) => {
  const hasMaxGratuity = isFinite(maxGratuity)

  if (hasMaxGratuity) {
    return `$${(maxGratuity / 100).toFixed(2)} maximum amount`
  } else {
    return ''
  }
}

const isUsableNumber = (value) => isNumber(value) && value > 0

const Signature = ({
  addTipToOrderInProgress,
  addTipToSavedCard,
  confirmDefaultTipEnabled,
  className,
  closeNoOpTender,
  closeWithSavedCard,
  customTender,
  goPrevScreen,
  history,
  isKiosk,
  kioskPayment,
  maxGratuity,
  menuTipSuggestions,
  offlineTotal,
  order,
  paymentId,
  placeFreeOrder,
  setKioskPayment,
  tenderAmountInCents,
  addTipThenRedirectPath,
}) => {
  const classNames = cn(styles.signature, isKiosk ? styles.signatureKiosk : null, className)
  const signaturePadRef = useRef(null)
  const intervalRef = useRef(null)
  const backButtonRef = useRef()
  const [selectedTipSuggestionIndex, setSelectedTipSuggestionIndex] = useState()
  const [selectedTipAmount, setSelectedTipAmount] = useState(0)
  const [isSignatureValid, setIsSignatureValid] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [newLinesMatch, setNewLinesMatch] = useState(false)
  const [previousLines, setPreviousLines] = useState([])
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [showCustomTipModal, setShowCustomTipModal] = useState(false)
  const [showConfirmDefaultTipModal, setConfirmDefaultTipModal] = useState(false)
  const [selectionIsDefault, setSelectionIsDefault] = useState(true)
  const [orderUuid, setOrderUuid] = useState(generateUuid())
  const [createOrder, orderCreated] = useCreateOrderInProgress()
  const [isSubmitted, setIsSubmitted] = useState(false)
  
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, loading, success, failed }] = useRemoteOrderTotal()
  const tipEntryModalTitle = makeTipEntryModalTitle(maxGratuity)
  const isDoneLoading = (paymentId || !isEmpty(order) || (!loading && (failed || success))) && !isSubmitted
  const tipAmountInCents = selectedTipAmount || 0
  let customTipModalElement = null
  let confirmDefaultTipModalElement = null
  const isTabOrder = isTabbed(order)
  const isMobile = useGetIsMobileScreen()
  
  let totalInCents
  if (paymentId) {
    totalInCents = tenderAmountInCents
  } else if (addTipThenRedirectPath || isTabOrder) {
    totalInCents = order?.amountInCents ?? offlineTotal
  } else {
    totalInCents = remoteOrderTotal?.totalAmountInCents ?? offlineTotal
  }
  
  const [tipSuggestions, setTipSuggestions] = useState(makeTipSuggestions(menuTipSuggestions, totalInCents))
  const total = `$${(totalInCents / 100).toFixed(2)}`
  const tip = `$${(tipAmountInCents / 100).toFixed(2)}`
  const grandTotalInCents = totalInCents + tipAmountInCents
  const grandTotal = `$${(grandTotalInCents / 100).toFixed(2)}`
  const noPaymentRequired = grandTotalInCents === 0
  const signatureRequired = isEmpty(customTender) && !addTipThenRedirectPath && !!((SIGNATURE_THRESHOLD_AMOUNT === 0 && !noPaymentRequired) || (SIGNATURE_THRESHOLD_AMOUNT && grandTotalInCents >= SIGNATURE_THRESHOLD_AMOUNT))
  const isFormValid = signatureRequired ? isSignatureValid && isNumber(selectedTipSuggestionIndex) : isNumber(selectedTipSuggestionIndex)
  const tipSuggestionItems = map(tipSuggestions, 'display')
  const initialTipPercentage = get(tipSuggestions, selectedTipSuggestionIndex)?.display

  useEffect(() => {
    const { creditCard, mobileWallet } = KIOSK_PAYMENT_TYPES
    if (!paymentId && !addTipThenRedirectPath && !isTabOrder) {
      loadRemoteOrderTotal()
    }
    if (addTipThenRedirectPath && !isTabOrder) {
      createOrder()
    }
    if (isKiosk && kioskPayment.paymentType === mobileWallet) {
      setKioskPayment(creditCard)
    }
  }, [])
  // check for empty tipSuggestions so only placeFreeOrder for orders totaling 0 with no suggestions.
  // We want users to be able to tip on orders totaling zero, but only for tip enabled menus
  useEffect(() => {
    if (isDoneLoading) {
      if (totalInCents === 0 && isEmpty(tipSuggestions)) {
        placeFreeOrder(orderUuid, isKiosk)
      } else if (!signatureRequired && isEmpty(tipSuggestions) && !addTipThenRedirectPath) {
        setIsReady(true)
      } else if (totalInCents !== offlineTotal) {
        setTipSuggestions(makeTipSuggestions(menuTipSuggestions, totalInCents))
      }
    }
  }, [loading, success, failed, orderCreated])

  useEffect(() => {
    if (success) {
      setOrderUuid(remoteOrderTotal.uuid)
    }
  }, [success])

  useEffect(() => {
    if (isDoneLoading && backButtonRef.current) backButtonRef.current.focus()
  }, [isDoneLoading])

  useEffect(() => {
  if (!selectedTipAmount) {
    setSelectedTipAmount(0)
    }
  }, [showCustomTipModal])

  useEffect(() => {
    if (isReady && signatureRequired) {
      intervalRef.current = setInterval(() => {
        const newLinesDrawn = JSON.parse(signaturePadRef?.current?.getSaveData()).lines.length

        if (newLinesDrawn === previousLines) {
          setNewLinesMatch(true)
        }
      }, 10)
    }
  }, [isReady, newLinesMatch, previousLines, signatureRequired])

  useDeepCompareEffect(() => {
    const selectedIndex = findIndex(tipSuggestions, 'selected')
    const initialSelectedIndex = isNumber(selectedIndex) && selectedIndex >= 0 ? selectedIndex : tipSuggestions?.findIndex(item => item.display === 'No Tip' )
    const initialSelectedTipSuggestion = get(tipSuggestions, `[${initialSelectedIndex}]`, {})
    const initialSelectedTipAmount = get(initialSelectedTipSuggestion, 'amountInCents', 0)

    setSelectedTipSuggestionIndex(initialSelectedIndex)
    setSelectedTipAmount(initialSelectedTipAmount)
  }, [tipSuggestions])

  useEffect(() => () => clearInterval(intervalRef.current))

  if (showCustomTipModal) {
    customTipModalElement = (
      <TipEntryModal
        key='custom'
        title={tipEntryModalTitle}
        errorMessage={tipEntryModalTitle}
        isKiosk={isKiosk}
        initialValue={selectedTipAmount}
        validator={(value) => (value <= maxGratuity && value >= 0)}
        saveButtonText="Set Tip"
        onClose={() => setShowCustomTipModal(false)}
        onSubmit={(tipAmountInCents) => handleSetNewTip(tipAmountInCents)}
      />
    )
  }

  if (showConfirmDefaultTipModal) {
  confirmDefaultTipModalElement = (
    <ConfirmModalV2
      onButtonOneClick={() => setConfirmDefaultTipModal(false)}
      onButtonTwoClick={() => {
        onNext()
        setConfirmDefaultTipModal(false)
      }}
      headerText={`Confirm ${initialTipPercentage} Suggested Tip`}
      subtext={'Our staff appreciates your generosity'}
      isKiosk={isKiosk}
      buttonOneText={'CANCEL'}
      buttonTwoText={'CONFIRM'}
    />
  )
}

  const handleSetNewTip = (tipAmountInCents) => {
    const newTipAmount = isUsableNumber(maxGratuity) ? Math.min(maxGratuity, tipAmountInCents) : tipAmountInCents

    setSelectedTipAmount(newTipAmount)
    triggerPopAnimation()
  }

  const triggerPopAnimation = () => {
    setShouldAnimate(true)
    setTimeout(() => setShouldAnimate(false), 100)
  }

  const selectedTipSuggestionDidChange = (selectedIndex) => {
    const selectedSuggestion = get(tipSuggestions, `[${selectedIndex}]`, {})
    const selectionIsSame = selectedIndex === selectedTipSuggestionIndex
    const resolvedTipAmount = selectionIsSame ? selectedTipAmount : selectedSuggestion.amountInCents

    if (selectedSuggestion.custom) {
      setShowCustomTipModal(true)
    }

    if(!selectionIsSame) {
      setSelectionIsDefault(false)
    }

    if (!selectedSuggestion.custom && !selectionIsSame && !shouldAnimate) {
      triggerPopAnimation()
    }

    setSelectedTipSuggestionIndex(selectedIndex)
    setSelectedTipAmount(resolvedTipAmount)
  }

  const completeSignature = () => {
    const save = signaturePadRef.current.getSaveData()
    const nextDrawing = save.replace(/#FFFFFF/img, '#000000')
    signaturePadRef.current.loadSaveData(nextDrawing, true)
    setPreviousLines(JSON.parse(save).lines.length)
    setIsReady(true)
  }

  const completeTipping = () => {
    if (noPaymentRequired) {
        placeFreeOrder(orderUuid, isKiosk)
      } else if (addTipThenRedirectPath) {
        addTipToOrderInProgress(tipAmountInCents, addTipThenRedirectPath)
      } else if (!isEmpty(customTender)) {
        closeNoOpTender({ order, orderUuid, customTender, tipAmountInCents, tenderAmountInCents, paymentId })
        setIsSubmitted(true)
      } else if (isKiosk) {
        history.push({
          pathname: `/kiosk/order/payment`,
          state: { tipAmountInCents }
      })
    } else if (closeWithSavedCard) {
      addTipToSavedCard(tipAmountInCents)
    } else {
      setIsReady(true)
    }
  }

  const clearSignature = () => {
    signaturePadRef.current.clear()
    setIsSignatureValid(false)
  }

  useEffect(() => {
    if (isReady && !processing && (newLinesMatch || !signatureRequired)) {
      clearInterval(intervalRef.current)

      if (noPaymentRequired) {
        placeFreeOrder(orderUuid, isKiosk)
      }

      let signature = ''
      if (signatureRequired) {
        const drawing = get(signaturePadRef, 'current.canvas.drawing')
        signature = drawing ? drawing.toDataURL('image/svg+xml') : 'Signature Error'
      }

      if (closeWithSavedCard) {
        setProcessing(true)
        addTipToSavedCard(tipAmountInCents, signature)
      }

      if (isKiosk && !noPaymentRequired) {
        history.push({
          pathname: `/kiosk/order/payment`,
          state: { receiptSignature: signature, tipAmountInCents }
        })
      }

      if (!isKiosk && !closeWithSavedCard) {
        const orderUuid = order?.uuid
        history.push({
          pathname: `/card-reader`,
          state: { orderUuid, selectedTipAmount, remoteOrderTotal, isKiosk, paymentId,
            tenderAmountInCents, offlineTotal, signature}
        })
      }
    }
  }, [isReady, newLinesMatch, signatureRequired])

  const clientWidth = document?.body?.clientWidth
  const clientHeight = document?.body?.clientHeight
  const canvasWidth = clientWidth < 450 || isKiosk ? clientWidth * 0.90 : clientWidth * .6 || 500
  const getResponsiveCanvasHeight = () => {
    let canvasHeight = clientHeight * .445

    if (isMobile && clientHeight <= 640) {
      canvasHeight = clientHeight * .33
    }

    if (isMobile && !isEmpty(tipSuggestions)) {
      canvasHeight = clientHeight * .36
    }

    if(isKiosk) {
      canvasHeight = clientHeight * .31
    }
    
    return canvasHeight
  }

  let primaryText = !isDoneLoading ? '-' : grandTotal
  let secondaryTextKiosk = !isDoneLoading ? '-' : `${total} + ${tip} Tip`
  let secondaryText = !isKiosk ? tipAmountInCents > 0 ? `${total} + ${tip} tip` : 'Add a tip' : secondaryTextKiosk

  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.UP) {
      if (isKiosk) {
        history.push('/kiosk/order/new')
      } else {
        goPrevScreen(order)
      }
    }
  }

  const SignatureIcon = () => {
    if (isKiosk) {
      return <KioskX onClick={clearSignature} />
    }

    return <BlackX onClick={clearSignature} />
  }

  const goBack = () => isKiosk ? history.push('/kiosk/order/new') : goPrevScreen(order)
  const onConfirm = () => {
    if(selectionIsDefault && initialTipPercentage && confirmDefaultTipEnabled) {
      setConfirmDefaultTipModal(true)
    } else {
      onNext()
    }

  }
  const onNext = ((SIGNATURE_THRESHOLD_AMOUNT || SIGNATURE_THRESHOLD_AMOUNT === 0) && signatureRequired) ? completeSignature : completeTipping
  
  const containerStyle = { 
    marginTop: signatureRequired && isEmpty(tipSuggestions) && !isMobile  && !isKiosk ? '10vh' : addTipThenRedirectPath === '/split-tender' || (!addTipThenRedirectPath && isEmpty(customTender)) ? '2.037vh' : '4.259vh', 
    height: !signatureRequired ? '37%' : '',
    justifyContent: !signatureRequired ? 'space-between' : ''
  }
  return isDoneLoading ? (
    <div className={cn(classNames)}>
      {
        isKiosk ? (
          <>
            <OfflineModal />
            <div className={styles.gratuityHeaderKiosk}>
                <BackButton
                  handleKeyDown={handleKeyDown}
                  ariaLabel={primaryText}
                  refProp={backButtonRef}
                  className={styles.backButton}
                  withIcon={true}
                  onClick={goBack}
                  iconStyle={styles.backButtonIcon}
                >
                  <div className={styles.orderTotal}>{primaryText}</div>
                </BackButton>
              {!isEmpty(tipSuggestions) && <span>{secondaryTextKiosk}</span>}
            </div>
          </>
          ) : (
          <>
          <VNConcessionsNavBar 
            onClick={goBack}
            textDisplay={isMobile && primaryText}
          />
          {signatureRequired && isEmpty(tipSuggestions) && isMobile ? <span className={styles.noTipMessage}>Please sign below</span>
          :
            ( <GratuityHeader
              className={cn(styles.gratuityHeader, { [styles.pop]: shouldAnimate })}
              primaryText={!isMobile && primaryText}
              secondaryText={isEmpty(tipSuggestions) ? <span className={styles.noTipMessage}>Please sign below</span> : secondaryText}
            />)
          }
          
        </>
          )
      }

      <div className={styles.contentContainer} style={containerStyle}>
        {isDoneLoading && !isEmpty(tipSuggestions) && <SegmentedControl items={tipSuggestionItems} selectedIndex={selectedTipSuggestionIndex} onChange={selectedTipSuggestionDidChange} isKiosk={isKiosk} />}
        {isKiosk && (isDoneLoading && signatureRequired && isEmpty(tipSuggestions)) && <span className={styles.noTipMessage}>PLEASE SIGN BELOW</span>}
        {isDoneLoading && signatureRequired &&
          <>
            <div className={styles.signaturePadContainer} onTouchEnd={() => setIsSignatureValid(true)} onMouseUp={() => setIsSignatureValid(true)}>
              <CanvasDraw
                canvasWidth={canvasWidth}
                canvasHeight={getResponsiveCanvasHeight()}
                className={styles.signaturePad}
                brushRadius={1.5}
                brushColor={isKiosk ? '#000000' : '#ffffff'}
                backgroundColor="transparent"
                catenaryColor="transparent"
                hideGrid
                ref={signaturePadRef}
              />
         
              {isKiosk && 
                <>
                  <div className={styles.signHereContainer}>
                    <SignatureIcon />
                    <div className={styles.signHereKiosk} />
                  </div>
                  <p className={styles.disclaimerKiosk}>I agree to pay the above total amount according to my card issuer agreement</p>
                </>
              }

            </div>
            {!isKiosk && <div className={styles.signHereContainer}>
              <SignatureIcon />
              <span className={styles.signHere} />
            </div>}
          </>
        }
        {!isKiosk && isDoneLoading && signatureRequired && <p className={styles.disclaimer}>I agree to pay the above total amount according to my card issuer agreement.</p>}
        {(isKiosk && isDoneLoading && (!isEmpty(tipSuggestions) || signatureRequired)) &&
          <div className={styles.clearButtonContainer}>
            {signatureRequired && <Button onClick={clearSignature} className={styles.clearButtonKiosk}>CLEAR SIGNATURE</Button>}
            <Button disabled={!isFormValid} onClick={onConfirm} className={styles.completeButtonKiosk}>Next</Button>
          </div>
        }
        {!isKiosk &&
          (<VNBottomButton
            disabled={!isFormValid || !isDoneLoading}
            onClick={onConfirm}
            text={'Next'}
          />)
        }
      </div>
      {customTipModalElement}
      {confirmDefaultTipModalElement}
    </div>
  ) : <ConnectingIndicator isKiosk={isKiosk}/>
}

const mapStateToProps = (state, ownProps) => {
  const offlineTotal = getTotal(state)
  const itemCount = getCartItemsCount(state)
  const isKiosk = getIsKiosk(state)
  const kioskPayment = getKioskPayment(state)
  const paymentId = ownProps?.match?.params?.paymentId
  const tenderAmountInCents = ownProps?.history?.location?.state?.tenderAmountInCents ?? 0
  const addTipThenRedirectPath = ownProps?.history?.location?.state?.addTipThenRedirectPath ?? ''
  const closeWithSavedCard = ownProps?.history?.location?.state?.closeWithSavedCard ?? false
  const customTender = ownProps?.history?.location?.state?.customTender ?? {}
  const order = getOrderInProgress(state)
  const menu = makeGetCurrentMenu()(state)
  const menuTipSuggestions = order?.hasTipApplied && !addTipThenRedirectPath ? [] : menu?.tipSuggestions ?? []
  let maxGratuity = Infinity
  const confirmDefaultTipEnabled = getDefaultTipEnabled(state)

  return {
    offlineTotal,
    itemCount,
    menuTipSuggestions,
    maxGratuity,
    isKiosk,
    kioskPayment,
    paymentId,
    tenderAmountInCents,
    addTipThenRedirectPath,
    order,
    confirmDefaultTipEnabled,
    customTender,
    closeWithSavedCard,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const goBack = props?.history?.goBack ?? (() => {})
  const push = props?.history?.push ?? (() => {})
  const addTipThenRedirectPath = props?.history?.location?.state?.addTipThenRedirectPath ?? ''

  return {
    goPrevScreen: (order) => {
      goBack()
      if (addTipThenRedirectPath && !isTabbed(order)) {
        dispatch(clearOrderInProgress())
      }
    },
    setKioskPayment: (paymentType) => {
      dispatch(setKioskPayment(paymentType))
    },
    placeFreeOrder: (orderUuid, isKiosk) => {
      dispatch(closeNoOpTender({ orderUuid }))
      if (isKiosk) {
        push(`/kiosk/order/summary/${orderUuid}`)
      }
    },
    addTipToOrderInProgress: (tipAmountInCents, addTipThenRedirectPath) => {
      dispatch(addTipOrderInProgress(tipAmountInCents))
      push(addTipThenRedirectPath)
    },
    closeNoOpTender: ({ order, orderUuid, customTender, tipAmountInCents, tenderAmountInCents, paymentId }) => {
      if (isEmpty(order)){
        dispatch(closeNoOpTender({ orderUuid, tender: customTender, tipAmountInCents }))
      } else {
        dispatch(addPaymentToOrderInProgress({ tenderAmountInCents, paymentId, paymentType: customTender.name, shortDescription: customTender.displayName, tipAmountInCents }))
      }
    },
    addTipToSavedCard: (tipAmountInCents, signature) => {
      dispatch(updateSavedPayment({tipAmountInCents, signature }))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signature))
