import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { VNDialog } from '../../VNCustomerFacingDisplay/components/VNDialog'
import { getConfirmPaymentReadyDialogOpen } from '../Selectors'
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg'
import { getDeviceMode } from '../../VNMode/Selectors'
import { MODES } from '../../VNMode/Reducer'
import { CFD_SCREEN } from "../../VNCustomerFacingDisplay/Enums"
import { CFD_POS_PAYMENT_FLOWS } from '../../VNCustomerFacingDisplay/Enums'
import { setConfirmPaymentDialog, setOpenCustomerEnteringTipDialog } from '../ActionCreators'
import { bridge_setCFDScreen } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { customHistory } from '../../stores/index'
import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'
import { clearRemoteOrderTotal } from '../../actions/orderTotalRemote'
import { clearOrderInProgress } from '../../actions/order'
import { calculateCartTotals } from '../../utils/orderTotalUtils'
import { getCartItems, getTaxByItem, getTaxRate } from '../../selectors/cart'
import { getTipsEnabled, makeGetCurrentMenu  } from '../../selectors/menus'
import { getMenuServiceCharge } from '../../selectors/items'
import { setCurrentCart, setCurrentPaymentFlow } from '../../VNCustomerFacingDisplay/ActionCreators'
import useCreateOrderInProgress from '../../hooks/useCreateOrderInProgress'
import { getOrderInProgress } from '../../selectors/order'
import { isTabbed } from '../../utils/orderStates'
import useGetIsMobileScreen from '../../hooks/useGetIsMobileScreen'
import ConfirmModalV2 from '../../components/ConfirmModalV2'
import { ReactComponent as CreditCardIconMobile } from '../../assets/icons/credit-card-mobile.svg'

const useStyles = makeStyles(theme => ({
  centeredCard: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content'
  }
}))

export const VNConfirmPaymentDialog = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  // legacy functionality
  const confirmationText = "Please confirm guest has all forms of payment ready. Once used, a tender may not be canceled."
  const getMenu = makeGetCurrentMenu()
  const menu = useSelector(state => getMenu(state))
  const tipSuggestions = menu?.tipSuggestions
  const cartItems = useSelector(state => getCartItems(state))
  const taxRate = useSelector(state => getTaxRate(state))
  const taxByItem = useSelector(state => getTaxByItem(state))
  const menuServiceCharge = useSelector(state => getMenuServiceCharge(state))
  const tipsEnabled = useSelector(state => getTipsEnabled(state))
  const [createOrder, orderCreated, noPaymentRequired, remoteOrderTotal] = useCreateOrderInProgress()
  const open = useSelector(state => getConfirmPaymentReadyDialogOpen(state))
  const deviceMode = useSelector(state => getDeviceMode(state))
  const [loadRemoteOrderTotal, {}, resetOrderTotals] = useRemoteOrderTotal()
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const [canProceed, setCanProceed] = useState(true)
  const [proceedSplitTabOrder, setProceedSplitTabOrder] = useState(false)
  const isMobile = useGetIsMobileScreen()

  useEffect(() => {
    if (open) {
      setCanProceed(true)
    }
  }, [open])
  
  const getCardIcon = () => {
    return (
      <div className={classes.centeredCard}>
        <CreditCardIcon />
      </div>
    )
  }

  const prepareAndDispatchCartData = () => {
    const _calculatedCartValues = calculateCartTotals(remoteOrderTotal, cartItems, taxRate, taxByItem, menuServiceCharge, orderInProgress)
    const extraData = {
      cartTotals: {..._calculatedCartValues, uuid: orderInProgress.uuid},
      tips: {
        enabled: tipsEnabled,
        suggestions: tipSuggestions
      },
      currentPaymentFlow: CFD_POS_PAYMENT_FLOWS.SPLIT_PAY
    }

    // store the current cart values in state on the POS side
    dispatch(setCurrentCart(_calculatedCartValues))

    // need to show the tip screen on the CFD
    bridge_setCFDScreen(CFD_SCREEN.TRANSACTION_SUMMARY, extraData)

    // need to show the modal on POS showing that the customer is entering the tip
    dispatch(setOpenCustomerEnteringTipDialog({ open: true, amount: _calculatedCartValues.total, tipsEnabled: tipsEnabled }))

    setCanProceed(true)
    setProceedSplitTabOrder(false)
  }

  useEffect(() => {
    if((proceedSplitTabOrder || orderCreated) && deviceMode === MODES.POS) {
      prepareAndDispatchCartData()
    }
  }, [orderCreated, proceedSplitTabOrder])


  const primaryButtonClick = () => {
    if (tipsEnabled) {

      if (deviceMode === MODES.POS) {

        if(canProceed) {
          setCanProceed(false)

          // store on the POS side what flow we are in
          dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.SPLIT_PAY, true))


          if (isTabbed(orderInProgress)) {
            setProceedSplitTabOrder(true)
            return
          }

          // create an order before allowing tip
          createOrder(true)
        }
      } else {

        dispatch(setConfirmPaymentDialog(false))

        customHistory.push({
          pathname: '/signature',
          state: {
            addTipThenRedirectPath: '/split-tender'
          }
        })
      }
    } else {
      
      if (deviceMode === MODES.POS) {

        // store on the POS side what flow we are in
        dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.SPLIT_PAY, true))
        
        // show waiting for attendant modal on CFD
        bridge_setCFDScreen(CFD_SCREEN.SPLIT_PAY_WAITING_FOR_ATTENDANT)
        
        if (isTabbed(orderInProgress)) {
          setProceedSplitTabOrder(true)
          return
        }

        createOrder(true)
      } else {
        dispatch(setConfirmPaymentDialog(false))
        customHistory.push('/split-tender')
      }
    }
  }

  const secondaryButtonClick = () => {
    dispatch(clearRemoteOrderTotal())
    bridge_setCFDScreen(CFD_SCREEN.CART)
    // reset order totals
    resetOrderTotals()
    if (!isTabbed(orderInProgress)) {
      dispatch(clearOrderInProgress())
    }
    dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true))
    dispatch(setConfirmPaymentDialog(false))
  }

  const display = () => {
    if (isMobile && open) {
      return (
        <ConfirmModalV2
          headerText={<CreditCardIconMobile/>}
          subtext={confirmationText}
          buttonOneText={'GO BACK'}
          buttonTwoText={'PROCEED'}
          onButtonOneClick={secondaryButtonClick}
          onButtonTwoClick={primaryButtonClick}
        />
      )
    }

    return (
      <VNDialog 
        open={open}
        image={getCardIcon()}
        subTitle={confirmationText}
        primaryButtonText='PROCEED'
        onPrimaryButtonClick={primaryButtonClick}
        secondaryButtonText='GO BACK'
        onSecondaryButtonClick={secondaryButtonClick}
      />
    )
  }

  return display()
}
