import { Box, Divider, Typography, makeStyles, useTheme } from "@material-ui/core"
import { useSelector } from "react-redux"
import { VNButton } from "vn-react-components/VNComponents/VNButton"
import { getDeviceMode } from "../../VNMode/Selectors"
import { MODES } from "../../VNMode/Reducer"
import { getBalanceToBePaid } from "../Selectors"
import { centsToDollars } from "../../utils/formatters"
import { bridge_setCFDScreen } from "../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend"
import { useHistory } from 'react-router-dom'
// import { getOrderInProgress } from "../../selectors/order"
import { CFD_SCREEN } from "../Enums"

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000'
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  textContainer: {
    width: '100%'
  },
  disabledGrey: theme.custom.colors.disabledGrey,
  divider: {
    border: `1px solid ${theme.custom.colors.white}`,
    borderColor: theme.custom.colors.white,
    backgroundColor: theme.custom.colors.white
  },
  payButtonContainer: {
    position: 'absolute',
    alignItems: 'flex-end',
    height: '100%',
    width: 492,
    display: 'flex'
  },
  cfdLarge: theme.custom.large1, // a3700
  h2: theme.typography.h2, // a3700
  large1: theme.custom.posLarge1, //l1400
  h3: theme.typography.h3
}))

/**
 * 
 * @param {Function} onPayClick what to do when Pay Balance Due is clicked
 * @param {String} amountPaid string to show in Paid
 * @param {String} balanceDue string to show in Balance Paid
 * @param {String} displayHorizontal whether balance due is displayed horizontally or vertically
 */
export const VNBalanceDue = (props) => {

  const styles = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const deviceMode = useSelector(state => getDeviceMode(state))
  const balanceToBePaid = useSelector(state => getBalanceToBePaid(state))
  // const order = useSelector(state => getOrderInProgress(state))

  const getDisabledColor = () => {
    return {
      color: theme.custom.colors.disabledGrey
    }
  }

  const onPayClick = async() => {
    await bridge_setCFDScreen(CFD_SCREEN.SPLIT_PAY_WAITING_FOR_ATTENDANT, null, true)
    history.push({
      pathname: '/split-tender'
    })
  }

  const displayDivider = () => {
    if (props.displayHorizontal) {
      return (
        <Box pr={10} pl={10}>
          <Divider className={styles.divider} style={{height: 81}}/>
        </Box>
      )
    }

    return (
      <Box pb={6} pt={6}>
        <Divider className={styles.divider} style={{width: 384}}/>
      </Box>
    )
  }

  const getRootStyle = () => {
    if (props.displayHorizontal) {
      return {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }
    }

    return {}
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.center} style={getRootStyle()} pb={5}>
        <Box className={styles.textContainer}>
          <Box pb={1}>
            <Typography style={getDisabledColor()} className={deviceMode !== MODES.CFD ? styles.h2 : styles.h3}>Paid</Typography>
          </Box>
          <Typography style={getDisabledColor()} className={deviceMode !== MODES.CFD ? styles.cfdLarge : styles.h3}>{centsToDollars(balanceToBePaid.get('amountPaid'))}</Typography>
        </Box>
        {displayDivider()}
        <Box className={styles.textContainer}>
          <Box pb={1}>
            <Typography className={deviceMode !== MODES.CFD ? styles.h2 : styles.h3}>Balance Due</Typography>
          </Box>
          <Typography className={deviceMode !== MODES.CFD ? styles.cfdLarge : styles.h3}>{centsToDollars(balanceToBePaid.get('balanceDue'))}</Typography>
        </Box>
      </Box>
      {
        deviceMode === MODES.POS &&
        (
          <Box className={styles.payButtonContainer} pb={8}>
            <VNButton buttonVariant='button4' text={`Pay Balance Due`} onClick={onPayClick}/>
          </Box>
        )
      }
    </Box>
  )
}