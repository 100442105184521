import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { get, some, map } from 'lodash'

import Portico from '@ordernext/networking-stadium/portico'
import Stadium from '@ordernext/networking-stadium/stadium'

import createDataDogLog from '../utils/datadog'
import { ToastManager } from '../utils/toastManager'

import { injectNonDecamelizedPaymentsKeysForPpi } from '../VNPPI/Utils'

const generateUuid = require('uuid/v4')

let fetchingTokens = false

// Do not toast errors received from these endpoints
const STADIUM_ENDPOINT_ERROR_BLACKLIST = [ '/gift_cards', '/tickets', '/nonces' ]
const ERROR_EXCLUSION_LIST = [
  "Could not find such an attendant!",
  'Nonce charge failed.',
  'Wallet charge failed.'
]

export const instance = axios.create({
  transformResponse: [...axios.defaults.transformResponse, camelizeKeys]
})

instance.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Expires: 0,
  }

  let deepCopy = {}
  Object.assign(deepCopy, config?.data)

  config.baseURL = process.env.REACT_APP_BASE_URL
  config.params = decamelizeKeys(config?.params ?? {})
  config.data = decamelizeKeys(config?.data ?? {})

  // not every parameter that we send to stadium needs to be decamelized
  // these variables aren't read by stadium specifically, but are
  // passed around to VNAPI and POS for reading.
  injectNonDecamelizedPaymentsKeysForPpi(deepCopy, config.data)

  // LAVA is being sent as l_a_v_a this fixes that
  if (config?.data?.user_attributes?.ppi_extras && deepCopy?.userAttributes?.ppiExtras) {
    if (!!deepCopy?.userAttributes?.ppiExtras['LAVA']) {
      config.data.user_attributes.ppiExtras = deepCopy?.userAttributes?.ppiExtras
    }
  }

  return config
}, (error) => Promise.reject(error))

const handleBadToken = async (config) => {
  fetchingTokens = true
  config.sent = true

  const deviceId = window.localStorage.device_id
  const appKey = process.env.REACT_APP_OIDC_CLIENT_ID
  const organizationName = process.env.REACT_APP_ORG
  let response = { data: {} }

  try {
    response = await Portico.deviceLogin(deviceId, appKey, organizationName)
  } catch (error) {
    fetchingTokens = false
    return Promise.reject(error)
  }

  const accessToken = get(response, 'data.accessToken', undefined)
  const expiresAt = get(response, 'data.expiresAt', undefined)

  if (accessToken && expiresAt) {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('expiresAt', expiresAt)

    Stadium.config = {
      ...Stadium.config,
      headers: {
        ...Stadium.config.headers,
        Authorization: `Bearer ${accessToken}`,
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: 0,
      }
    }
  }

  fetchingTokens = false
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`

  return axios(config)
}

const reportableIssue = (error) => {
  const response = error?.response

  const applicationMessage = response?.data?.errorMessage
  const friendlyMessage = response?.data?.friendlyMessage
  const errorMessage = friendlyMessage ?? applicationMessage

  return {
    shouldLog: errorMessage && error?.message !== "Network Error" &&
      !ERROR_EXCLUSION_LIST.includes(applicationMessage),
    shouldToast: !some(STADIUM_ENDPOINT_ERROR_BLACKLIST,
      (endpoint) => response?.config?.url?.includes(endpoint)),
    errorMessage: (friendlyMessage || errorMessage)?.slice(0, 100)
  }
}

instance.interceptors.response.use((response) => {
  return response
}, async (err) => {
  const response = err?.response
  const config = err?.config

  if (response?.status === 401 && response?.data?.error === 'invalid_token' && !config?.sent &&
      !fetchingTokens) {
    return await handleBadToken(config)
  }

  const reportable = reportableIssue(err)

  if (reportable.shouldToast && reportable.errorMessage) {
    ToastManager.error(`${reportable.errorMessage}`, { autoClose: true })
  }

  if (reportable.shouldLog) {
    createDataDogLog('error', reportable.errorMessage)
  }

  return Promise.reject(err)
})

class Remote {
  static deviceRoute = isKiosk => isKiosk ? '/kiosk/v3/orders' : '/pos/orders'

  static createPosOrder(params = {}, isKiosk, callback = (response) => {}) {
    const response = instance.post(`${Remote.deviceRoute(isKiosk)}`, params)
    callback(response)

    return response
  }

  static getOrderDeltas = (venueUuid, sinceTimestamp) => {
    let payload = decamelizeKeys({
      params: { venueUuid },
    })

    if (sinceTimestamp) {
      payload.params.since_timestamp = sinceTimestamp
    }

    return instance.get('/pos/data_deltas/order', payload)
  }

  static getOrders = ({ venueUuid, standMenuUuids, updatedSince }) => {
    // These have to be sent in params. The backing library to Axios will not allow a body.
    const payload = decamelizeKeys({
      params: { venueUuid, standMenuUuids, updatedSince },
    })
    return instance.get('/pos/orders', payload)
  }

  static getOrderDetails = (orderUuid) => instance.get(`/pos/orders/${orderUuid}`)

  static updatePosOrder(uuid, params = {}, isKiosk) {
    return instance.put(`${Remote.deviceRoute(isKiosk)}/${uuid}`, params)
  }

  static fetchMenus = ({ organizationName, standUuids, paymentType }) => {
    let translatedPaymentType = paymentType ? (paymentType === 'fcc' ? 'freedompay' : 'shift4') : null
    var payload = {
      params: { organizationName, venueUuid: process.env.REACT_APP_VENUE_UUID, paymentType: translatedPaymentType },
    }

    if (standUuids !== undefined && standUuids !== null) {
      payload = {
        params: { organizationName, uuids: standUuids, paymentType: translatedPaymentType },
      }
    }

    return instance.get('/pos/stands', payload)
  }

  static fetchPrinters = () => {
    var payload = {
      params: { organizationName: process.env.REACT_APP_ORG, venueUuid: process.env.REACT_APP_VENUE_UUID },
    }

    return instance.get('/pos/printers', payload)
  }

  static addLineItems = (orderUuid, orderMenuItems, isKiosk) => {
    const payload = { orderUuid, orderMenuItems };
    return instance.post(`${Remote.deviceRoute(isKiosk)}/${orderUuid}/line_items`, payload)
  }

  static closeTabOrder = (orderId, receiptSignature, tipAmountInCents, payments, isKiosk) => {
   const payload = {
      uuid: orderId,
      receiptSignature,
      tipAmountInCents,
      payments,
    }

    return instance.post(`${Remote.deviceRoute(isKiosk)}/${orderId}/close`, payload)
  }

  static refundOrderItems = (orderUuid, reason, lineItemUuids) => {
    const payload = {
      orderUuid,
      reason,
      userDetails: {},
      lineItemUuids,
    }

    return instance.post('/pos/refund', payload)
  }

  static voidItems = (orderUuid, reason, lineItemUuids) => {
    const payload = {
      data: {
        orderUuid,
        reason,
        userDetails: {},
        lineItemUuids,
      }
    }

    return instance.delete(`/pos/orders/${orderUuid}/line_items`, payload)
  }

  static getEmployeeRoles = () => {
    return instance.get('/operator/employee_roles')
  }

  static getAttendants = (organizationName) => {
    const payload = {
      params: { organizationName, venueUuid: process.env.REACT_APP_VENUE_UUID }
    }

    return instance.get('/operator/attendants', payload)
  }

  static getAttendantSettings = (pinNumber) => {
    const payload = {
      params: { pinNumber, venueUuid: process.env.REACT_APP_VENUE_UUID },
    }

    return instance.get('/api/v1/attendant_settings', payload)
  }

  static getPromotions = (venueUuid, standMenuUuids) => {
    const payload = {
      params: { venueUuid, standMenuUuids },
    }

    return instance.get('/pos/promotions', payload)
  }

  static getRemoteOrderTotal = (orderMenuItems, standMenuUuid, promotions, configOverride = {},
      isKiosk, affiliations = [], userUuid = undefined) => {
    let payload = {
      affiliations,
      userUuid,
      promotions: !promotions ? undefined : map(promotions, (promotion) => ({ uuid: promotion.uuid })),
      orderMenuItems,
      standMenuUuid,
      taxExempt: false // TODO: This may need to be removed since changing this flag resets any promotions in Moneyball
    }

    payload.uuid = generateUuid()

    return instance.post(`${Remote.deviceRoute(isKiosk)}/total`, payload, configOverride)
  }

  static getSummaryReport = (deviceId, employeeUuid) => {
    const payload = {
      params: { deviceId, employeeUuid },
    }

    return instance.get('/pos/order_reports', payload)
  }

  static sendEmailJobToStadium = ({ orderUuid, emails, paymentUuid }) => {
    const payload = decamelizeKeys({
      emails,
      paymentUuid,
   })
    return instance.post(`/pos/orders/${orderUuid}/emails/PosOrderReceiptEmailJob`, payload)
  }

  static getTicketBalance = (ticketId) => {

    return instance.get(`/pos/tickets/${ticketId}`)
  }

  static getQRBalance = (qrCode) => {

    return instance.get(`/pos/nonces/${qrCode}`)
  }

  static getGiftCardBalance = (giftCardId) => {

    return instance.get(`/pos/gift_cards/${giftCardId}`)
  }

  // config requests
  static getConfig = () => instance.get(`/api/v1/app_device_configurations`)

  static createConfig = (params = {}) => {
    const payload = decamelizeKeys({
      ...params,
    })

    return instance.post('/api/v1/app_device_configurations', payload)
  }

  static updateConfig = (params = {}) => {
    const payload = decamelizeKeys({
      ...params,
    })

    return instance.put(`/api/v1/app_device_configurations?uuid=${params.uuid}`, payload)
  }

  static getSections = (standUuid) => {
    // If the stand UUID is undefined, don't attempt to make an API call, which would result in an
    // error due to a string being passed as a parameter.
    if (!standUuid) {
      return new Promise((resolve, reject) => {
        resolve({ data: [] })
      })
    }

    return instance.get(`/pos/stands/${standUuid}/sections`)
  }

  static getRows = (sectionName) => {

    return instance.get(`/pos/sections/${sectionName}/rows?venue_uuid=${process.env.REACT_APP_VENUE_UUID}`)
  }

  static getSeats = (sectionName, row) => {
    return instance.get(`/pos/sections/${sectionName}/rows/${row}/seats?venue_uuid=${process.env.REACT_APP_VENUE_UUID}`)
  }
}

export default Remote
