import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'isLocationPickerActive': false,
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.IS_LOCATION_PICKER_ACTIVE:
      return state.set('isLocationPickerActive', action.isActive)
    default:
      return state
  }
}

export default reducer
