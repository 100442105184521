/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { map, find, findIndex, isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { isEmpty } from '../VNUtil/VNUtils'

import SidebarModal from './SidebarModal'
import ConnectingIndicator from '../components/ConnectingIndicator'
import { ReactComponent as Unchecked } from '../assets/icons/fill-unchecked.svg'
import { ReactComponent as Checkmark } from '../assets/icons/fill-checkmark.svg'

import styles from './SelectDiscount.module.scss'
import Button from './Button'
import useRemoteOrderTotal from '../hooks/useRemoteOrderTotal'
import { getOrderInProgress } from '../selectors/order'

const SelectDiscount = ({ promotions, onClose, addPromotionsToOrder, selectedIndexes, selectedPromotions, remoteTotalAvailable, className, ...props }) => {
  const classNames = cn(styles.selectDiscount, className)
  const orderInProgress = useSelector(getOrderInProgress)
  const [ selectedDiscounts, setSelectedDiscounts ] = useState([ ...selectedPromotions ])
  const [loadRemoteOrderTotal, { loading }] = useRemoteOrderTotal()
  const title = "Discounts"
  const discountsChanged = !isEqual(map(selectedDiscounts, (discount) => ({ uuid: discount.uuid })), selectedPromotions)

  useEffect(() => {
    if (!remoteTotalAvailable && isEmpty(orderInProgress)) {
      loadRemoteOrderTotal()
    }
  }, [])

  useEffect(() => {
    setSelectedDiscounts([ ...selectedPromotions ])
  }, [selectedPromotions])

  const onSelect = (promotion) => {
    const promotionIndex = findIndex(selectedDiscounts, (discount) => discount.uuid === promotion.uuid)
    let newSelectedDiscounts

    if (promotionIndex > -1) {
      selectedDiscounts?.splice(promotionIndex, 1)
      newSelectedDiscounts = [ ...selectedDiscounts ]
    } else {
      newSelectedDiscounts = [ ...selectedDiscounts, promotion ]
    }
    setSelectedDiscounts(newSelectedDiscounts)
  }

  const renderPromotionSelections = (promotion, index) => {
    const isSelected = !!(find(selectedDiscounts, (discount) => discount.uuid === promotion.uuid))
    let checkmarkElement = isSelected ? <Checkmark /> : <Unchecked className={styles.unchecked} />

    return (
      <div className={styles.selectableItem} key={`${promotion.uuid}-${index}`} onClick={() => onSelect(promotion)}>
        <div className={cn([styles.discount, isSelected && styles.selected])}>
          <div className={styles.column}>
            {checkmarkElement}
          </div>
          <div className={styles.column}>
            <span className={styles.name}>{promotion.name}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <SidebarModal
      show
      title={title}
      onClose={onClose}
      className={cn(classNames, styles.sidebarHeader)} {...props}
    >
      <div className={styles.selectDiscount}>
        <div className={styles.discountContainer}>
          {loading ? <ConnectingIndicator position='relative' isSidebarLoading={true} className={styles.loading}  /> : map(promotions, renderPromotionSelections)}
        </div>
      </div>
      <div className={styles.submitContainer}>
        <Button
          className={styles.submitButton}
          disabled={!discountsChanged}
          onClick={
            () => {
              addPromotionsToOrder(selectedDiscounts)
              onClose()
            }
          }>
          Apply Discounts
        </Button>
      </div>
    </SidebarModal>
  )
}

export default SelectDiscount
