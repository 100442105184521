export const KEY_CODES = Object.freeze({
  ENTER: 13,
  ESC: 27,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  JACK_IN: 106,
  JACK_OUT: 107,
  //dev only
  TAB: 9,
  JACK_IN_TEST: 120, //F9
  JACK_OUT_TEST: 121 //F10
})
