import { loadConfig } from '@ordernext/networking-stadium/stadium/actions'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ConnectingIndicator from '../components/ConnectingIndicator'
import { getShouldWaitForRedirect } from '../selectors/config'

const useCheckConfig = () => {

  const [showSpinner, setShowSpinner] = useState(false)
  const state = useStore().getState()
  const dispatch = useDispatch()
  const config = state?.config ?? {}
  const shouldRefreshConfig = config?.shouldRefreshConfig ?? true
  const shouldWaitForRedirect = useSelector(getShouldWaitForRedirect)
  const history = useHistory()
  const blackListPaths = ['/', '/device-login', '/revenue-center-setup', '/register-device']
  const pathname = history?.location?.pathname ??  '/' 
  const search = history?.location?.search ?? ''
  const hash = history?.location?.hash ?? ''
  const redirect = `${pathname}${search}${hash}`

  useEffect(()=> {
    if (!shouldRefreshConfig || !shouldWaitForRedirect) {
      setShowSpinner(false)
    }
  }, [shouldRefreshConfig, shouldWaitForRedirect])

  useEffect(() => {
    if (!localStorage.getItem('accessToken') || isEmpty(state)) {
      history.replace('/device-login')
    } else 
    if (shouldRefreshConfig && !blackListPaths.includes(redirect)) {
      dispatch(loadConfig())
      setShowSpinner(true)
    }
  }, [])

  if (showSpinner) {
    return <ConnectingIndicator />
  }
}

export default useCheckConfig
