import React, { useState } from 'react'
import moment from 'moment'

import useInterval from '../hooks/useInterval'

const Clock = ({ className }) => {
  const [time, setTime] = useState(moment().format('h:mm'))

  const updateClock = () => setTime(moment().format('h:mm'))

  useInterval(() => { updateClock() }, 10000)

  return (
    <div className={className}>
      <span>{time}</span>
    </div>
  )
}

export default Clock
