import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CartModal from '../components/CartModal'
import { hasCartItems } from '../selectors/cart'
import { clearCart } from '../actions/cart'
import { startATab, addItemsToTab, clearOrderInProgress, addItemsToTabOffline } from '../actions/order'
import CartItemsViewModel from '../viewmodels/CartItemsViewModel'
import { getCartItems, getSubtotal, getCartQuantity2 } from '../selectors/cart'
import { getTabsModeEnabled } from '../selectors/menus'
import { centsToDollars } from '../utils/formatters'
import { getDiscountsAvailable, getSelectedPromotions } from '../selectors/promotion'
import { getCashEnabled, getGiftCardEnabled, getMobilePaySetting, getTicketEnabled, makeGetNoOpTenders } from '../selectors/revenueCenter'
import { getTipsEnabled, makeGetCurrentMenu } from '../selectors/menus'
import { getSafMode } from '../selectors/appState'
import { SIGNATURE_THRESHOLD_AMOUNT } from '../constants'
import { addPromotionsToOrder, clearPromotionsOnOrder } from '../actions/promotion'
import { getMutationInProgress } from '../selectors/order'


const mapStateToProps = (state, ownProps) => {
  const history = ownProps?.history ?? {}
  const fetchedCartItems = getCartItems(state)
  const cartItems = CartItemsViewModel(fetchedCartItems)
  const total = getSubtotal(state)
  const formattedTotal = centsToDollars(total)
  const itemCount = getCartQuantity2(state)
  const discounts = getSelectedPromotions(state)
  const noOpPayments = makeGetNoOpTenders()(state)
  const cashEnabled = getCashEnabled(state)
  const tipsEnabled = getTipsEnabled(state)
  const threshHoldExceeded = (SIGNATURE_THRESHOLD_AMOUNT || SIGNATURE_THRESHOLD_AMOUNT === 0) && total > SIGNATURE_THRESHOLD_AMOUNT
  const signatureRequired = tipsEnabled || threshHoldExceeded
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const tipSuggestions = menu?.tipSuggestions
  const safMode = getSafMode (state)
  const networkAvailable = (state?.config?.networkAvailable ?? false) && safMode !== 2
  const ticketEnabled = getTicketEnabled(state)
  const discountsAvailable = getDiscountsAvailable(state)
  const mobilePaySetting = getMobilePaySetting(state)
  const giftCardEnabled = getGiftCardEnabled(state)
  const tabsModeEnabled = getTabsModeEnabled(state)
  const mutationInProgress = getMutationInProgress(state)

  return {
    hasCartItems: hasCartItems(state),
    tabsModeEnabled,
    cartItems,
    total: formattedTotal,
    itemCount,
    history,
    discountsAvailable,
    discounts,
    noOpPayments,
    signatureRequired,
    tipSuggestions,
    cashEnabled,
    networkAvailable,
    ticketEnabled,
    mobilePaySetting,
    giftCardEnabled,
    mutationInProgress
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearCart: () => dispatch(clearCart()),
    addPromotionsToOrder: (selectedPromotions) => dispatch(addPromotionsToOrder({ selectedPromotions })),
    clearPromotionsOnOrder: ()=> dispatch(clearPromotionsOnOrder()),
    startATab: (payload) => { dispatch(startATab(payload))},
    addItemsToTab: () => {
      dispatch(addItemsToTab())
    },
    clearOrderInProgress: () => {
      dispatch(clearOrderInProgress())
    },
    addItemsToTabOffline: () => {
      dispatch(addItemsToTabOffline())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartModal))
