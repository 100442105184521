import { connect } from 'react-redux'

import SelectDiscount from '../components/SelectDiscount'

import {
  addPromotionsToOrder
} from '../actions/promotion'
import { clearRemoteOrderTotal } from '../actions/orderTotalRemote'

import { makeGetPromotions, getSelectedPromotions } from '../selectors/promotion'
import { getRemoteOrderTotal } from '../selectors/orderTotalRemote'

const mapStateToProps = (state, ownProps) => {
  const selectedPromotions = getSelectedPromotions(state)
  const promotions = makeGetPromotions()(state)
  const remoteTotalAvailable = getRemoteOrderTotal(state)?.isAvailable

  return {
    promotions,
    selectedPromotions,
    remoteTotalAvailable,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const replace = ownProps?.history?.replace ?? (() => {})

  return {
    onClose: () => {
      replace('/concession-order')
      dispatch(clearRemoteOrderTotal())
    },
    addPromotionsToOrder: (selectedPromotions) => dispatch(addPromotionsToOrder({ selectedPromotions })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDiscount)
