import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import Remote from '../remote'
import createDataDogLog from '../utils/datadog'

const useGetGiftCardBalance = () => {
  const [dataLoading, setLoading] = useState(false)
  const [dataSucceeded, setSuccess] = useState(false)
  const [dataFailed, setFailed] = useState(false)
  const [giftCardBalance, setGiftCardBalance] = useState()
  const [cardNotRecognized, setCardNotRecognized] = useState(false)
  const cancelTokenRef = useRef()

  useEffect(() => {
    cancelTokenRef.current = axios.CancelToken.source()

    return () => {
      cancelTokenRef.current.cancel()
    }
  }, [])

  const getGiftCardBalance = (giftCardId) => {
    setLoading(true)
    setSuccess(false)
    setFailed(false)

    Remote.getGiftCardBalance(giftCardId).then((result) => {
      setGiftCardBalance(result?.data?.balance ?? 0)
      setLoading(false)
      setSuccess(true)
    }).catch((err) => {
      if (axios.isCancel(err)) return
      
      const errorMessage = err?.response?.data?.errorMessage ?? ''
      if (errorMessage) {
        setCardNotRecognized(true)
      }

      createDataDogLog('error', `There was an error in retrieving this gift card balance amount, ERROR: ${err ?? 'No Error Sent'}`, { giftCardId, errorMessage })
      setLoading(false)
      setFailed(true)
    })
  }

  return [getGiftCardBalance, { giftCardBalance, dataLoading, dataSucceeded, dataFailed, cardNotRecognized }]
}

export default useGetGiftCardBalance