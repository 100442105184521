export const TOGGLE_APP_STATE = 'TOGGLE_APP_STATE'
export const ACCESSIBILITY_REQUIRED = 'ACCESSIBILITY_REQUIRED'
export const SET_VERSION_INFO = 'SET_VERSION_INFO'
export const SET_AUTOMATIC_UPDATE = 'SET_AUTOMATIC_UPDATE'
export const SET_SAF_MODE = 'SET_SAF_MODE'
export const SET_SAF_RESPONSE = 'SET_SAF_RESPONSE'
export const SET_ERROR_INFO = 'SET_ERROR_INFO'
export const SET_PERFORM_SAFE_UPDATE = 'SET_PERFORM_SAFE_UPDATE'

export const toggleAppState = (newState) => ({
  payload: newState,
  type: TOGGLE_APP_STATE,
})

export const toggleAccessibilityRequired = (accessibilityRequired) => ({
  payload: accessibilityRequired,
  type: ACCESSIBILITY_REQUIRED,
})

export const setVersionInfo = (payload) => ({
  type: SET_VERSION_INFO,
  payload,
})

export const setAutomaticUpdate = (payload) => ({
  type: SET_AUTOMATIC_UPDATE,
  payload,
})

export const setSafMode = (payload) => ({
  type: SET_SAF_MODE,
  payload,
})

export const setSafResponse = (payload) => ({
  type: SET_SAF_RESPONSE,
  payload,
})

export const setErrorInfo = (payload) => ({
  type: SET_ERROR_INFO,
  payload,
})

export const setPerformSafeUpdate = (payload) => ({
  type: SET_PERFORM_SAFE_UPDATE,
  payload,
})
