import { dispatchHistory } from "../stores"
import { getDeviceSerial } from "./formatters"

export const createDataDogLog = (logType, message, additionalData = {}) => {
  const deviceSerial = getDeviceSerial() ?? 'NO_ID'
  const deviceId = localStorage.getItem('device_id') ?? 'NO_ID'
  const venueUuid = process?.env?.REACT_APP_VENUE_UUID
  const org = process?.env?.REACT_APP_ORG
  if (['error', 'info', 'warn', 'debug'].includes(logType)) {
    window?.DD_LOGS?.logger?.[logType](message, {
      deviceId,
      deviceSerial,
      venueUuid,
      org,
      dispatchHistoryLastThree: dispatchHistory.slice(0, 3),
      ...additionalData,
    })
  }
}

export default createDataDogLog
