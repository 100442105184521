import { createOrderParams } from "./orderUtils"

const responseStatuses = {
  1: "No response data exists",
  2: "Card reader still offline",
  3: "Transaction declined during replay",
  4: "Transaction approved",
  5: "Voided without communicating with the server",
}

const isPaxResponse = (data) => data.reasonText || data.message

export const statusCheckComplete = (data) => {
  if (isPaxResponse(data)) return true

  return [ 3, 4 ].includes(data.responseStatus)
}

export const responseStatusString = (responseStatus) => responseStatuses[responseStatus]

export const approvedOfflineThenDeclined = (data) => {
  if (isPaxResponse(data)) {
    if (data.reasonText === 'DECLINE' || data.message === 'DECLINE') {
      return true
    }

    if (data.reasonText === 'NOT FOUND' || data.message === 'NOT FOUND') {
      return true
    }

    if (data.reasonText === 'APPROVED OFFLINE' || data.message === 'APPROVED OFFLINE') {
      const receiptData = data.receiptData

      if (receiptData?.approvalCode === 'D') {
        return true
      }
    }
  }

  return data.responseStatus === 3
}

export const isOfflineApproval = (order) => {
  const cardReaderData = order?.cardReaderData ?? {}
  const userAttributes = createOrderParams(order)?.userAttributes ?? order?.userAttributes ?? {}
  const requestId = cardReaderData.requestId || ""

  return cardReaderData?.isOfflineApproval || userAttributes?.isOfflineApproval || requestId.substring(0,3) === "00Y"
}
