import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import Button from '../Button'

import { KEY_CODES } from "../../utils/constants";

const AlcoholWarningModal = ({ isOpen, cancel, refProp }) => {
  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.ENTER) {
      cancel()
    }
  }
  return (
    <Modal className="modal" isOpen={isOpen} overlayClassName="overlay">
      <div className="modal__copy">
        Only 2 alcoholic beverages are allowed per ID. If you cannot produce valid IDs, your
        transaction will not be processed, and you will not be served.
      </div>
      <div className="modal__actions">
        <Button
          className="btn -outline"
          refProp={refProp}
          onClick={cancel}
          onKeyPress={handleKeyDown}
          title="Got it"
          ariaLabel="Only 2 alcoholic beverages are allowed per ID. If you cannot produce valid IDs, your
          transaction will not be processed, and you will not be served. Press enter to confirm"
        />
      </div>
    </Modal>
  )
}

AlcoholWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default AlcoholWarningModal
