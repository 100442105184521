import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'isVNScannerInitialized': false,
    'isUsingVNScanner': false,
    'scanResults': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNSCANNER_INITIALIZED:
      return state.set('isVNScannerInitialized', action.initialized)
    case ActionTypes.USING_VNSCANNER:
      return state.set('isUsingVNScanner', action.usingVNScanner)
    case ActionTypes.SCANNER_RESULTS:
      return state.set('scanResults', Map({results: action.scanResults, timestamp: new Date()}))
    default:
      return state
  }
}

export default reducer
