import { get, nth, pullAt, isNil } from 'lodash'

import {
  setCartFromTab,
  addToCart,
  removeFromCart,
  updateCartItemQuantity,
  incrementCartItemQuantity,
  decrementCartItemQuantity,
  updateCartItemModifiers,
  updateCartItemNotes,
  clearCart,
  clearFocus,
  addNotesToCart,
} from '../actions/cart'

export const defaultState = {
  lineItems: [],
  newItemFocus: false,
  userNotes: null,
}

const lineItemsReducer = (state = [], action = { payload: {} }) => {
  switch (action.type) {
    case setCartFromTab.type: {
      const keysToKeep = ['menuItemUuid', 'price']
      const mappedKeys = {
        menuItemUuid: 'itemId',
        price: 'price'
      }
      const filterLineItemProperties = (lineItem) => {
        return Object.keys(lineItem)
          .filter(key => keysToKeep.includes(key))
          .reduce((obj, key) => {
            obj[mappedKeys[key]] = lineItem[key]
            return obj
          }, {})
      }
      const lineItems = get(action, 'payload.lineItems', []).filter((lineItem) => {
        return !isNil(lineItem.menuItemUuid) && !lineItem.itemSpecialType
      }).map((lineItem) => {
        return filterLineItemProperties(lineItem)
      })
      return lineItems
    }

    case addToCart.type: {
      const itemId = get(action, 'payload.itemId', '')
      const modifiers = get(action, 'payload.modifiers', [])
      const quantity = get(action, 'payload.quantity', 1)
      const isAlcohol = get(action, 'payload.isAlcohol', false)

      return [
        ...state,
        { itemId, modifiers, quantity, isAlcohol }
      ]
    }

    case removeFromCart.type: {
      const index = get(action, 'payload.index', -1)
      const clonedState = [ ...state ]

      if (index === -1) {
        clonedState.pop()
      } else {
        pullAt(clonedState, index)
      }

      return clonedState
    }

    case updateCartItemQuantity.type: {
      const index = get(action, 'payload.index', -1)
      const quantity = get(action, 'payload.quantity')
      const clonedState = [ ...state ]

      if (quantity === undefined) return state

      nth(clonedState, index).quantity = parseInt(quantity, 10)

      return clonedState
    }

    case incrementCartItemQuantity.type: {
      const index = get(action, 'payload.index', -1)
      const clonedState = [ ...state ]

      nth(clonedState, index).quantity += 1

      return clonedState
    }

    case decrementCartItemQuantity.type: {
      const index = get(action, 'payload.index', -1)
      const clonedState = [ ...state ]

      nth(clonedState, index).quantity -= 1

      return clonedState
    }

    case updateCartItemModifiers.type: {
      const index = get(action, 'payload.index', -1)
      const modifiers = get(action, 'payload.modifiers', [])
      const clonedState = [ ...state ]

      nth(clonedState, index).modifiers = modifiers

      return clonedState
    }

    case updateCartItemNotes.type: {
      const index = get(action, 'payload.index', -1)
      const notes = get(action, 'payload.notes', [])
      const clonedState = [ ...state ]

      nth(clonedState, index).notes = notes

      return clonedState
    }

    case clearCart.type: {
       return []
    }

    default:
      return state
  }
}

const itemFocusReducer = (state = false, action = { payload: {} }) => {
  switch (action.type) {
    case addToCart.type: {
      return true
    }

    case clearFocus.type: {
      return false
    }

    default:
      return state
  }
}

const reducer = (state = defaultState, action) => {
  const updatedLineItems = lineItemsReducer(state.lineItems, action)
  const updatedNewItemFocus = itemFocusReducer(state.newItemFocus, action)

  let updatedUserNotes = null

  switch (action.type) {
    case addNotesToCart.type: {
      updatedUserNotes = get(action, 'payload.userNotes', null)
      break
    }

    case clearCart.type: {
      updatedUserNotes = null
      break
    }

    default: {
      updatedUserNotes = state.userNotes
    }
  }

  return {
    lineItems: updatedLineItems,
    newItemFocus: updatedNewItemFocus,
    userNotes: updatedUserNotes,
  }
}

export default reducer
