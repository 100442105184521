import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import VNSpinner from 'vn-react-components/VNComponents/VNSpinner'

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%'
  }
}))

export const VNProcessing = (props) => {

  const classes = useStyles()

  const theme = useTheme()

  // SELECTORS

  // LOCAL STATE

  // USE EFFECTS

  return (
    <Box className={classes.root}>
      <Box>
        <VNSpinner loadingIcon='./imgs/loading.png'/>
        <Typography variant='h3'>Processing...</Typography>
        <Typography style-={theme.custom.subtitle3}>Please wait.</Typography>
      </Box>
    </Box>
  )
}
