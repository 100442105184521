import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { List } from '@material-ui/core'
import { getCartItems } from '../Selectors'
import { Fragment } from 'react'
import { VNCartListItem } from '../components/VNCartListItem'
import { centsToDollars } from '../../utils/formatters'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column-reverse',
    overflowY: 'scroll'
  },
  list: {
    width: '56%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingBottom: 100
  },
  listHider: {
    width: '100%',
    position: 'fixed',
    zIndex: 1,
    height: '20%',
    top: 0,
    'background-image': 'linear-gradient( rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))'
  }
}))

export const VNCart = (props) => {

  const classes = useStyles()

  // SELECTORS
  const items = useSelector(state => getCartItems(state))

  // LOCAL STATE

  const scrollRef = useRef(null)

  // USE EFFECTS

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth"})
    }
  }, [items])

  const displayItems = () => {
    if (!items.isEmpty()) {
      return (
        items.map((item, index) => {

          // need to calculate the price per item (modifiers price + item price) * quantity
          const calculatedPrice = (_item) => {

            if(_item.type === 'discount') {
              return
            }
            // get the total price for all the modifiers for this item added together
            let totalPriceFromModifiers = 0
            _item?.modifiers?.forEach(modifier => {
              totalPriceFromModifiers += parseInt(modifier.defaultPriceInCents)
            })

            // calculate the price
            const totalPrice = (parseInt(_item?.priceInCents) + totalPriceFromModifiers) * parseInt(_item?.quantity)
            return centsToDollars(totalPrice)
          }

          return (
            <Fragment key={index}>
              <VNCartListItem key={index} title={item?.name} quantity={item?.quantity} type={item?.type} price={calculatedPrice(item)} modifiers={item?.modifiers} />
            </Fragment>
          )
        })
      )
    } else {
      return
    }
  }

  /**
   * Enable for debugging only. Find the matching commented out code in the full return as well.
   * @returns 
   */
  // eslint-disable-next-line no-unused-vars
  const displayDebug = () => {
    if (!items.isEmpty()) {
      return (
        items.map((item, index) => {
          return (
            <Fragment key={index}>
              <div>{JSON.stringify(item)}</div>
            </Fragment>
          )
        })
      )
    } else {
      return
    }
  }

  return (
    <Box className={classes.root}>
      <div className={classes.listHider}></div>
      <List className={classes.list}>
        {displayItems()}
        <span ref={scrollRef} />
        {/* {displayDebug()} */}
      </List>
    </Box>
  )
}
