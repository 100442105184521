import { connect } from 'react-redux'
import { get, map, intersection, flatten, nth, isEmpty } from 'lodash'

import CartItemDetailsModal from '../components/CartItemDetailsModal'

import {
  removeFromCart,
  safelyIncrementCartItemQuantity,
  safelyDecrementCartItemQuantity,
  updateCartItemModifiers,
  safelyUpdateCartItemNotes,
  safelyUpdateCartItemQuantity,
} from '../actions/cart'
import { getCartLineItems } from '../selectors/cart';
import { getItemsForCurrentMenu } from '../selectors/items';
import { getIsKeyboardActive } from '../VNAndroidSDK/Selectors';

const mapStateToProps = (state, ownProps) => {
  const cartItemIndex = get(ownProps, 'match.params.cartItemIndex', -1)
  const cartItem = nth(getCartLineItems(state), cartItemIndex) || {}
  const item = get(getItemsForCurrentMenu(state), ['byId', get(cartItem, 'itemId', '')], {})
  const modifierGroups = get(item, 'modifierGroups', [])
  let selectedModifiers = get(cartItem, 'modifiers', [])
  const isKeyboardActive = getIsKeyboardActive(state)

  const modifierGroupsSelections = map(modifierGroups, (modifierGroup) =>
    intersection(map(get(modifierGroup, 'items'), (item) => `${item.id}|${modifierGroup.uuid}`), flatten(selectedModifiers)))
  const notesAreEmpty = isEmpty(cartItem.notes)
  const cartItemNotValidIfClosed = ownProps?.history.location?.state?.cartItemNotValidIfClosed ?? false
  
  return {
    modifierGroups,
    id: item.id,
    title: item.name,
    price: `$${(item.defaultPriceInCents / 100).toFixed(2)}`,
    quantity: cartItem.quantity,
    canEnterNotes: item.canEnterNotes,
    notesAreEmpty,
    notes: cartItem.notes,
    selectedItemIds: modifierGroupsSelections,
    orderMaxAmount: item?.orderMaxAmount ?? 999, 
    orderMinCount: item?.orderMinCount ?? 1,
    cartItemNotValidIfClosed,
    isKeyboardActive
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const goBack = get(ownProps, 'history.goBack', () => {})
  const cartItemIndex = get(ownProps, 'match.params.cartItemIndex', -1)

  return {
    onClose: (isValid) => {
      goBack()

      if (!isValid) {
        dispatch(removeFromCart({ index: cartItemIndex }))
      }
    },
    incrementQuantity: () => {
      dispatch(safelyIncrementCartItemQuantity({ index: cartItemIndex }))
    },
    decrementQuantity: () => {
      dispatch(safelyDecrementCartItemQuantity({ index: cartItemIndex }))
    },
    updateCartItemQuantity: (quantity) => {
      dispatch(safelyUpdateCartItemQuantity({ index: cartItemIndex, quantity }))
    },
    updateModifiers: (modifiers) => {
      dispatch(updateCartItemModifiers({ index: cartItemIndex, modifiers }))
    },
    updateNotes: (notes) => {
      dispatch(safelyUpdateCartItemNotes({ index: cartItemIndex, notes }))
    },
    onRemove: () => {
      goBack()

      dispatch(removeFromCart({ index: cartItemIndex }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemDetailsModal)
