import { connect } from 'react-redux'
import { clearOrderSearch, searchOrders } from '../../actions/order'
import { get } from 'lodash'

import TopNav from '../../components/orders/TopNav'

import { filterOrders } from '../../actions/order'

import { onSafIsUploading } from '../../actions/peripheral'
import { getSafIsUploading } from '../../selectors/peripheral'
import { getIsKiosk, getTabsModeEnabled } from '../../selectors/menus'
import { getUnsyncedOrderCounts } from '../../selectors/order'

const mapStateToProps = (state) => {
  const currentFilter = get(state, 'order.ordersFilter')
  const currentSearch = get(state, 'order.ordersSearch')
  const [activeOfflineCardCount, unsyncedLocalOrderCount] = getUnsyncedOrderCounts(state)
  const hasUnsyncedOrders = activeOfflineCardCount > 0 || unsyncedLocalOrderCount > 0
  const safIsUploading = getSafIsUploading(state)
  const isKiosk = getIsKiosk(state)
  const tabsModeEnabled = getTabsModeEnabled(state)

  return {
    currentFilter,
    currentSearch,
    hasUnsyncedOrders,
    safIsUploading,
    isKiosk,
    tabsModeEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSearch: () => dispatch(clearOrderSearch()),
    onSearch: (search) => dispatch(searchOrders(search)),
    setSafIsUploading: (safIsUploading) => dispatch(onSafIsUploading(safIsUploading)),
    filterOrders: (filter) => dispatch(filterOrders(filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)
