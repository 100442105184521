export const getDeviceMode = (state) => {
  return state.VNMode.get('mode')
}

export const getDeviceId = (state) => {
  return state.VNMode.get('deviceId')
}

export const getPorticoAuthTokens = (state) => {
  return state.VNMode.get('porticoAuthTokens')
}

export const getSummitMode = (state) => {
  return state.VNMode.get('summit')
}