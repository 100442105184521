import React, { useState } from 'react'
import cn from 'classnames'

import VNConcessionsNavBar from '../VNConcessionsNavBar'
import NumberPad from '../../components/NumberPad'
import SecureDots from './SecureDots'
import Lock from '@material-ui/icons/Lock';
import styles from './Component.module.scss'
import useNumberPad from '../../hooks/useNumberPad'
import ConnectingIndicator from '../ConnectingIndicator'
import VNBottomButton from '../VNBottomButton'
import NavMenu from '../NavMenu'

import { ToastManager } from '../../utils/toastManager'

const UserLoginComponent = ({ standName, pinLoginStatus, handleSubmit, approvalCodes,
    hamburgerUnlocked, fetchAttendantsStatus, fetchRolesStatus, isMounted,
    header = "Enter Your Passcode", buttonText="Sign In", forManagerApproval = false }) => {
  const pinMinLength = 1
  const pinMaxLength = 6
  const [
    pin,
    handlePinEntry,
    isValid,
  ] = useNumberPad(
    '',
    (value) => value,
    (value) => value.length >= pinMinLength && value.length <= pinMaxLength,
    pinMaxLength,
    ''
  )

  const onHandleSubmit = (pin) => {
    if(!isValid) {
      ToastManager.error(`Pin must be at least ${pinMinLength} characters`)
      return
    } else if (!approvalCodes?.includes(pin)) {
      ToastManager.error('That code did not work')
      return
    }
    handleSubmit(pin)
  }

  let content = (
    <>
      <h1>{header}</h1>
      <SecureDots count={pinMaxLength} subjectSize={pin.length} />
      <NumberPad onTapButton={handlePinEntry} />
      <VNBottomButton
        disabled={!isValid}
        onClick={() => onHandleSubmit(pin)}
        text={buttonText}
      />
    </>
  )

  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false)
  const checkOpenHamburger = () => {
    if (approvalCodes?.includes(pin)) {
      setOpenHamburgerMenu(true)
    }
  }

  const isLoading = !forManagerApproval && (['loading', 'succeeded'].includes(pinLoginStatus) ||
    fetchAttendantsStatus === 'loading' || !fetchRolesStatus || !isMounted)

  if (isLoading) {
    content = <ConnectingIndicator />
  }

  const lockIcon = <Lock className={styles.lock} onClick={checkOpenHamburger}/>

  return (
    <div className={cn(styles.userLogin, { [styles.loading]: isLoading })}>
      {!isLoading && (
        <VNConcessionsNavBar
          useHamburgerIcon={hamburgerUnlocked}
          useSmallTitleFont={true}
          textDisplay={standName}
          showBorder={false}
          leftAccessory={lockIcon}
        />
      )}
      <div className={styles.pinForm}>
        {content}
      </div>
      { openHamburgerMenu && <NavMenu onToggle={() => setOpenHamburgerMenu(false)} /> }
    </div>
  )
}

export default UserLoginComponent
