export const getVNScannerInitialized = (state) => {
  return state.VNMobileScanner.get('isVNScannerInitialized')
}

export const getIsVNScannerBeingUsed = (state) => {
  return state.VNMobileScanner.get('isUsingVNScanner')
}

export const getVNScannerResults = (state) => {
  return state.VNMobileScanner.get('scanResults')
}