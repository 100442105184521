import { compact } from 'lodash'
import { createSelector } from 'reselect'

import { getAttendants } from './attendant'

export const getManagerIds = (state) => state?.employeeRole.managerEmployeeIds

export const getRolesLoadingComplete = (state) => state?.employeeRole?.rolesLoadingComplete

export const getManagerApprovalCodes = createSelector(
  getAttendants,
  getManagerIds,
  (attendants, managerIds) => {
    if (!managerIds) return [ ]

    const managers = managerIds.map((employeeId) => {
      return attendants.find((attendant) => {
        return attendant.id === employeeId
      })
    })

    return compact(managers).reduce((map, manager) => {
      map[manager?.pinNumber] = manager.uuid
      return map
    }, {})
  }
)

export const getEmployeeApprovalCodes = createSelector(
  getAttendants,
  (attendants) => {
    const attendantPins = attendants.map(attendant => attendant.pinNumber)

    return attendantPins
  }
)
