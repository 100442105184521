import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'

import Button from '../components/Button'
import IconOrderNext from '../components/icons/IconOrderNext'

import { getCurrentMenu } from '../../selectors/menus'

import { printOrder } from '../../actions/printer'

// import analytics from '../utils/analytics'
import UserIdleMonitor from '../utils/UserIdleMonitor'

export class ReceiptSelection extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    menu: PropTypes.object,
    order: PropTypes.object,
  }

  componentDidMount() {
    // analytics.receiptSelectionEvent()
    document.title = 'Receipt selection'

    this.printReceipt()

    this.idleMonitor = new UserIdleMonitor(15000)
    this.idleMonitor.addEventListeners()
  }

  componentWillUnmount() {
    this.idleMonitor.removeEventListeners()
  }

  printReceipt = () => {
    const { order, printOrder } = this.props

    printOrder({
      deviceType: 'kiosk',
      orderUuid: order?.uuid,
    })
  }

  noReceipt = () => {
    // analytics.checkoutFinishedEvent()
    this.props.history.push('/kiosk/attract_loop')
  }

  render() {
    const { menu, order } = this.props

    return (
      <div className="payment">
        <IconOrderNext className="payment__ordernext" />
        <div className="payment__summary">
          <div className="payment__subheader">Your order number</div>
          <div className="payment__header -order">{order.orderNumber}</div>

          <div className="payment__info">{menu.longDescription}</div>
          <Button
            className="payment__btn -primary"
            onClick={this.printReceipt}
            title="Print Receipt"
          />
        </div>
        <button className="payment__done" onClick={this.noReceipt} type="button">
          Done
        </button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  menu: getCurrentMenu(state),
  order: state.order.byId[state.order.currentOrderId],
})

const mapDispatchToProps = (dispatch) => {
  return {
    printOrder: (params) => dispatch(printOrder(params)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ReceiptSelection)
