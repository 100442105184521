/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { get, groupBy, isEmpty, keys, sumBy } from 'lodash'
import moment from 'moment'

import RoundButton from '../RoundButton'
import RequestRefundModal from '../../containers/RequestRefundModal'

import styles from './Details.module.scss'
import SidebarModal from '../SidebarModal'
import ConfirmModalV2 from '../ConfirmModalV2'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'

import { STADIUM_ORDER_STATES, isTabbed, wasTabbed } from '../../utils/orderStates'
import { createOrderParams } from '../../utils/orderUtils'
import sumUpModifiers from '../../utils/sumUpPrice'
import PeripheralBridge from '../../utils/peripheralBridge'
import { getNetworkAvailableValue } from '../../utils/networkConnected'
import { getTenderInfo } from '../../utils/orderDisplay'
import { isOfflineApproval } from '../../utils/offlineReplayStatuses'
import { useSelector } from 'react-redux'
import { getDiscountsAvailable } from '../../selectors/promotion'

const OrderDetails = ({ history, syncOrder, onClearOrder, voidOrderSucceeded, order, isKiosk,
  setCartFromTab, setOrderInProgressFromTab, className, clearCart, mutationInProgress, addNotesToCart,
  tabsModeEnabled }) => {

  const classNames = cn(styles.orderDetails, className)
  const itemModels = get(order, 'itemModels', [])
  const groupedItems = groupBy(itemModels, 'id')
  const modifiersSubtotal = itemModels.map(item =>  sumUpModifiers(item.modifiers)).reduce(
    (a, b) => a + b, 0)
  const subtotalInCents = sumBy(itemModels, 'priceInCents') + modifiersSubtotal
  const totalInCents = order?.amountInCents
  const discountInCents = Math.abs(order?.discountAmountInCents)
  const serviceFeeTotal = order?.serviceFee?.total
  const taxInCents = order?.taxAmountInCents
  const tip = order?.tipAmountInCents
  const isMultiPayment = order?.isMultiPayment ?? false
  const [isVoiding, setIsVoiding] = useState(false)
  const [noBridge, setNoBridge] = useState(false)
  const [noCardTransaction, setNoCardTransaction] = useState(false)
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [showRefundStateModal, setShowRefundStateModal] = useState(false)
  const isTab = isTabbed(order)
  const orderCompleted = order?.paymentCompleted
  const wasTab = wasTabbed(order)
  const networkConnected = getNetworkAvailableValue()

  const discountsAvailable = useSelector(state => getDiscountsAvailable(state))

  const centsToDollars = (cents) => `$${(cents / 100).toFixed(2)}`

  const displayCreated = (created) => {
    const date = moment(created).format('M/D/YY')
    const time = moment(created).format('h:mm a')

    return date + ", " + time
  }

  const displayPayment = (order) => {
    let paymentDisplay
    const issuer = createOrderParams(order)?.userAttributes?.freedompayIssuerName
    const lastFour = createOrderParams(order)?.userAttributes?.last_4
    const cardType = createOrderParams(order)?.payments?.cardType ?? ''
    const hasCreditCardInfo = issuer && lastFour
    const tenderResponse = getTenderInfo(order)
    const isSubmitted = createOrderParams(order)?.state === 'submitted' || createOrderParams(order)?.state === 'submission_failed'

    if (hasCreditCardInfo) {
      paymentDisplay = `${cardType} ${lastFour} ${issuer}`
    } else if (isSubmitted) {
      paymentDisplay = "N/A"
    } else {
      paymentDisplay = order.displayName
    }

    if (!order?.tabName) {
      return paymentDisplay || `${tenderResponse?.displayText}`
    }

    return `${tenderResponse?.displayText} • ${order?.tabName}`
  }


  const syncUnsyncedOrder = () => {
    order.unsyncable = false
    if (!order.id && !order.uuid) return
    syncOrder(order.id || order.uuid, order)
  }

  useEffect(() => {
    setIsVoiding(false)
    setNoBridge(false)
    setNoCardTransaction(false)

    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    bridgeInstance.registerHandler('handleResult', function(data, callback) {
      voidOrderSucceeded(order.id || order.uuid, data)
      setIsVoiding(false)
    })

    bridgeInstance.registerHandler('voidTransaction', function(data, callback) {
      voidOrderSucceeded(order.id || order.uuid, data)
      setIsVoiding(false)
    })
  }, [order])

  const onVoidOrder = () => {
    setIsVoiding(true)

    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) {
      setIsVoiding(false)
      setNoBridge(true)
      return
    }

    const fccReqId = get(order, 'cardReaderData.requestId')
    if (!fccReqId) {
      setIsVoiding(false)
      setNoCardTransaction(true)
      return
    }

    bridgeInstance.call('voidTransactionAttempt', { 'requestId': fccReqId })
  }

  const isOfflineCardApproval = isOfflineApproval(order)
  const isCardOrder = () => !isEmpty(order.cardReaderData) || order?.userAttributes?.freedompayInvoiceNumber
  const replayAuthDidFail = () => isOfflineCardApproval && keys(order.replayStatus).length > 0
  const showVoidOptions = () => isCardOrder() && !voidDidSucceed() && !isOfflineCardApproval
  const voidDidSucceed = () => !!order.voidedAt
  const voidDidFail = () => !!order.voidFailedAt
  const mediaQueryMobile = window?.matchMedia("(max-width: 500px)")
  const numOfItems = Array(groupedItems).length
  const refundButtonText = mediaQueryMobile.matches? 'Void' : 'Void Items'
  const paymentTitle = tabsModeEnabled ? 'Tender/Name:' : "Payment:"

  return (
    <SidebarModal
      show
      title="Order Details"
      onClose={onClearOrder}
      className={classNames}
    >
      {showRefundStateModal && <ConfirmModalV2
        onButtonTwoClick={() => setShowRefundStateModal(false)}
        headerText={''}
        subtext="Orders cannot be voided in this state."
        buttonTwoText={'OK'}
        singleButtonOnly={true}
      />}
      <div className={styles.bodyContainer}>
        <div className={styles.scrollable}>
          <div className={styles.metadata}>
            <div className={styles.row}>
              <span>{`${order.revenueName} Order #${order.orderNumber}`}</span>
            </div>

            <div className={styles.row}>
              <span>
                {paymentTitle} {isMultiPayment ? "Split Pay" : displayPayment(order)}
              </span>
            </div>

            <div className={styles.row}>
              <span>Created: {displayCreated(order?.submittedAt)}</span>
            </div>

            <div className={styles.row}>
              <span>
                Synced: {order.syncedAt ? displayCreated(order.syncedAt) : "-"}
              </span>
            </div>

            <div className={styles.row}>
              <span>Subtotal: {centsToDollars(subtotalInCents)}</span>
            </div>

            {
              order?.userNotes &&
              <div className={styles.row}>
                <span>Location: {order?.userNotes}</span>
              </div>
            }
          </div>

          <div className={styles.lineItems}>
            {keys(groupedItems).map((itemId) => {
              const items = groupedItems[itemId]
              const name = (items[0] || {}).name
              const modifierString = (items[0] || {}).modifiersString
              const modifiers = (items[0] || {}).modifiers
              const quantity = items.length
              const price = centsToDollars(sumBy(items, "priceInCents") +  sumUpModifiers(modifiers))

              return (
                <div className={styles.item} key={itemId}>
                  <div className={styles.column}>
                    <span className={styles.quantity}>{`${quantity}`}</span>
                  </div>
                  <div className={styles.column}>
                    <span className={modifierString ? styles.name : styles.nameWithoutMod}>{`${name}`}</span>
                    <span className={styles.modifiers}>{`${modifierString}`}</span>
                  </div>
                  <div className={styles.column}>
                    <span className={styles.price}>{`${price}`}</span>
                  </div>
                </div>
              )
            })}
          </div>

          <div className={styles.total}>
            <div className={styles.row}>
              <span>{`Subtotal (${numOfItems})`}</span>
              <span className={styles.totalRow}>
                {centsToDollars(subtotalInCents)}
              </span>
            </div>
            {discountInCents !== 0 && (
              <div className={styles.row}>
                <span className={styles.discountRow}>Discount</span>
                <span className={cn(styles.totalRow, styles.discountRow)}>
                  - {centsToDollars(discountInCents)}
                </span>
              </div>
            )}
            {serviceFeeTotal > 0 && (
              <div className={styles.row}>
                <span>{order?.serviceFee?.name ?? 'Administrative Charge'}</span>
                <span className={styles.totalRow}>
                  {centsToDollars(serviceFeeTotal)}
                </span>
              </div>
            )}
            {taxInCents > 0 && (
              <div className={styles.row}>
                <span>Sales Tax</span>
                <span className={styles.totalRow}>
                  {centsToDollars(taxInCents)}
                </span>
              </div>
            )}
            {tip > 0 && (
              <div className={styles.row}>
                <span>Gratuity</span>
                <span className={styles.totalRow}>{centsToDollars(tip)}</span>
              </div>
            )}
            <div className={styles.row}>
              <span>Total</span>
              <span className={styles.totalRow}>
                {centsToDollars(totalInCents)}
              </span>
            </div>
          </div>

          <div className={styles.orderHistoryContainer} style={!order.errorMessage ? {borderTop: "0"} : null}>
            {isOfflineCardApproval && (
              <div className={styles.row}>
                <span className={styles.errorMessage}>
                  Credit card approved offline
                </span>
              </div>
            )}

            {replayAuthDidFail() && (
              <div className={styles.row}>
                <span
                  className={styles.errorMessage}
                >{`${order?.replayStatus?.statusDisplay}`}</span>
              </div>
            )}

            {voidDidSucceed() && (
              <div className={styles.row}>
                <span
                  className={styles.errorMessage}
                >{`Voided at ${displayCreated(order.voidedAt)}`}</span>
              </div>
            )}

            {voidDidFail() && (
              <div className={styles.row}>
                <span
                  className={styles.errorMessage}
                >{`Unsuccessful void attempt at ${displayCreated(
                  order.voidFailedAt
                )}`}</span>
              </div>
            )}

            {order.errorMessage && (
              <div className={styles.row}>
                <div className={styles.column}>
                  <ErrorIcon />
                </div>

                <div className={styles.column}>
                  <span
                    className={styles.errorMessage}
                  >{`${order.errorMessage}`}</span>
                </div>
              </div>
            )}

            {showVoidOptions() && false && (
              <div className={styles.row}>
                {isVoiding && (
                  <span className={styles.errorMessage}>Voiding...</span>
                )}

                {noBridge && (
                  <span className={styles.errorMessage}>
                    Couldn't connect to the card reader
                  </span>
                )}

                {noCardTransaction && (
                  <span className={styles.errorMessage}>
                    No card transaction found to void
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          {!isKiosk && isTab && !orderCompleted && (
            <RoundButton
              text="Add Items"
              disabled={mutationInProgress || (!networkConnected && order?.wasCreatedInStadium)}
              className={cn(styles.actionButton)}
              onClick={() => {
                setOrderInProgressFromTab(order)
                clearCart()
                addNotesToCart(order.userNotes)
                history.push("/concession-order")
              }}
            />
          )}
          {isTab && (
            <>
              {!isVoiding && !noBridge && !noCardTransaction && (
                <RoundButton
                  text="Void Items"
                  className={cn(styles.actionButton)}
                  disabled={mutationInProgress || (!networkConnected && order?.wasCreatedInStadium)}
                  onClick={() => {
                    if (isOfflineCardApproval) {
                      setShowRefundStateModal(true)
                      return
                    }

                    if (wasTab) {
                      setShowRefundStateModal(true)
                      return
                    }

                    setOrderInProgressFromTab(order)
                    setShowRefundModal(true)
                  }}
                />
              )}
            </>
          )}
          {showVoidOptions() && false && !isTab && (
            <>
              {!isVoiding && !noBridge && !noCardTransaction && (
                <RoundButton
                  text="Void Payment"
                  className={cn(styles.actionButton)}
                  onClick={onVoidOrder}
                />
              )}
            </>
          )}
          {!isTab && (<RoundButton
            text={order.refundable ? "View Refund" : refundButtonText}
            className={cn(styles.actionButton)}
            onClick={() => {
              if (isOfflineCardApproval) {
                setShowRefundStateModal(true)
                return
              }

              if (order?.state === STADIUM_ORDER_STATES.TABBED_OFFLINE || order?.state === STADIUM_ORDER_STATES.APPROVED_OFFLINE) {
                setShowRefundStateModal(true)
                return
              }

              if (wasTab) {
                setShowRefundStateModal(true)
                return
              }

              setShowRefundModal(true)
            }}
          />)}
          {showRefundModal && (
            <RequestRefundModal
              order={order}
              edit={!order.refundable}
              onClose={() => setShowRefundModal(false)}
            />
          )}

          {!isKiosk && (!isTab || orderCompleted) && (
            <RoundButton
              text="Receipt"
              className={cn(styles.actionButton)}
              onClick={() => {
                history.push(`receipt/${order.uuid}?origin=orderDetails`)
              }}
            />
          )}
          {!isKiosk && isTab && !orderCompleted && (
            <RoundButton
              text="Close Order"
              disabled={mutationInProgress || (!networkConnected && order?.wasCreatedInStadium)}
              className={cn(styles.actionButton)}
              onClick={() => {
                setOrderInProgressFromTab(order)
                setCartFromTab(order)

                if (discountsAvailable && order.wasCreatedInStadium) {
                  return history.push('/discounts')
                } 
                history.push("/tender-selection")
              }}
            />
          )}
          {(!order?.syncedAt && order?.unsyncable) && (
            <RoundButton
              className={cn(styles.actionButton)}
              onClick={syncUnsyncedOrder}
              text="Sync Order"
            />
          )}
        </div>
      </div>
    </SidebarModal>
  )
}

OrderDetails.defaultProps = {
  order: {},
}

OrderDetails.propTypes = {
  syncOrder: PropTypes.func,
  onCloseDetails: PropTypes.func,
  voidOrderSucceeded: PropTypes.func,
  setCartFromTab: PropTypes.func,
  setOrderInProgressFromTab: PropTypes.func,
  order: PropTypes.object,
  tabsModeEnabled: PropTypes.bool
}

export default withRouter(OrderDetails)
