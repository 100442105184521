/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import { useStore } from 'react-redux'

import useSafMode from '../hooks/useSafMode'

import { primaryNetworkConnectedWithCallbacks, setNetworkAvailableValue } from '../utils/networkConnected'

const useNetworkIsAvailable = () => {
  const pingRef = useRef()
  const [initiateSafBridgeCommunication] = useSafMode()
  const state = useStore().getState()
  const networkAvailable = (state?.config?.networkAvailable ?? false)

  const startPinging = () => {
    stopPinging()
    const ping = () => {
      primaryNetworkConnectedWithCallbacks(() => {
        setNetworkAvailableValue(true)
      }, () => {
        setNetworkAvailableValue(false)
      })

      initiateSafBridgeCommunication()
    }

    ping()
    pingRef.current = setInterval(ping, 10000)
  }

  const stopPinging = () => {
    clearInterval(pingRef.current)
    pingRef.current = undefined
  }

  useEffect(() => {
    // Do not utilize window event listeners as they will only provide network availability not
    // internet availability.

    const updateAvailability = (event) => {
      const available = event.type === "online"

      // Always ensure pinging is happening.
      if (!pingRef.current) startPinging()

      // Offline listening is reliable. If the app loses LAN, it loses internet.
      if (!available) setNetworkAvailableValue(false)

      // Old "always active" logic. Deprecated by the above.
      // if (available && !pingRef.current) startPinging()
      // if (!available && pingRef.current) stopPinging()

      // setAvailable(available)
    }

    startPinging()

    window.addEventListener('offline', updateAvailability)
    window.addEventListener('online', updateAvailability)

    return () => {
      stopPinging()
      window.removeEventListener('online', updateAvailability)
      window.removeEventListener('offline', updateAvailability)
    }
  }, [networkAvailable])
}

export default useNetworkIsAvailable
