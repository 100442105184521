import React, { useRef, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { getCartTotals, getCurrentPaymentFlow, getTransactionResult, getInitializedValues } from '../Selectors'

import { APPLICATION_STRINGS } from '../../strings'

import { VNButton } from 'vn-react-components/VNComponents/VNButton'
import { VNTimeoutButton } from 'vn-react-components/VNComponents/VNTimeoutButton'
import { VNInputDialog } from 'vn-react-components/VNComponents/VNInputDialog'

import { bridge_updateTransactionLifecycle, sendPrintReceipt, sendUserPaymentProgress } from '../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { resetCFD } from '../ActionCreators'
import { CFD_EVENTS, CFD_POS_PAYMENT_FLOWS, USER_PROGRESS } from '../Enums'
import { floatToDollars } from '../../VNUtil/Money'
import { setSnackbarSystemDataAlertSuccess, setSnackbarSystemDataAlertError } from 'vn-react-components/VNSnackbarSystem/ActionCreators'
import { emailValidation } from '../../VNUtil/Validator'

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%'
  },
  receiptOverview: {
    width: '50%'
  }
}))

export const VNReceipt = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const timeoutRef = useRef()

  const transactionResult = useSelector(state => getTransactionResult(state))
  const cartTotals = useSelector(state => getCartTotals(state))
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))

  const initialValues = useSelector(state => getInitializedValues(state))

  // SELECTORS

  // LOCAL STATE
  const [openEmailDialog, setOpenEmailDialog] = useState(false)
  const [openTextDialog, setOpenTextDialog] = useState(false)

  // USE EFFECTS

  const onEmailDialogSave = async (inputVal) => {
    let emailsToSend = inputVal.split(',')

    for (let i = 0; i < emailsToSend.length; i++) {
      const email = emailsToSend[i];
      const result = emailValidation(email)
      if (!result) {
        setSnackbarSystemDataAlertError("Can't send email. Incorrect email format.")
        return
      }
    }

    sendPrintReceipt(cartTotals.get('uuid'), emailsToSend, null)
    setSnackbarSystemDataAlertSuccess('Email Sent!')
    setOpenEmailDialog(false)

    timeoutRef.current.resetTimer()
    timeoutRef.current.isPaused(false)
  }

  const onEmailDialogClose = () => {
    timeoutRef.current.resetTimer()
    timeoutRef.current.isPaused(false)
    setOpenEmailDialog(false)
  }

  const onTextDialogSave = async (inputVal) => {
    sendPrintReceipt(cartTotals.get('uuid'), null, inputVal)
    setSnackbarSystemDataAlertSuccess('Text Sent!')
    setOpenTextDialog(false)

    timeoutRef.current.resetTimer()
    timeoutRef.current.isPaused(false)
  }

  const onTextDialogClose = () => {
    timeoutRef.current.resetTimer()
    timeoutRef.current.isPaused(false)
    setOpenTextDialog(false)
  }

  // open the dialog on the screen
  const handleEmailReceiptClick = () => {
    timeoutRef.current.resetTimer()
    timeoutRef.current.isPaused(true)
    setOpenEmailDialog(true)

    document.addEventListener("keyup", (event) => {
      const keyName = event.key

      if (keyName === "Enter") {
        setOpenEmailDialog(false)
      }
    })
  }

    // open the dialog on the screen
    const handleTextReceiptClick = () => {
      timeoutRef.current.resetTimer()
      timeoutRef.current.isPaused(true)
      setOpenTextDialog(true)

      document.addEventListener("keyup", (event) => {
        const keyName = event.key

        if (keyName === "Enter") {
          setOpenTextDialog(false)
        }
      })
    }

  // send the receipt to print
  const handlePrintClick = () => {
    setSnackbarSystemDataAlertSuccess('Receipt print in progress')
    // print the receipt - send through bus all the way to POS Web
    // to handle the print through the old bridge
    sendPrintReceipt(cartTotals?.get('uuid'))
  }

  // handle the complete click
  const handleCompleteClick = () => {
    if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
      bridge_updateTransactionLifecycle(CFD_EVENTS.CUSTOMER_DONE_WITH_RECEIPT)
    } else {
      sendUserPaymentProgress(USER_PROGRESS.CARD_PAYMENT.PAYMENT_FINISHED.TRANSACTION_COMPLETE)
    }

    // reset the CFD - only want to clear state locally
    dispatch(resetCFD(false))
  }

  const displayPrintingButton = () => {
    if (initialValues.get('printingEnabled') === 'true') {
      return (
        <Box pt={1}>
          <VNButton buttonVariant='button4' text={APPLICATION_STRINGS.PRINT_BUTTON_LONG_TEXT} onClick={handlePrintClick} />
        </Box>
      )
    }
  }

  const displayEmailButton = () => {
    const emailReceiptsEnabled = initialValues.get('emailReceiptsEnabled') === 'true'
    if (emailReceiptsEnabled && !transactionResult.has('offline')) {
      return (
        <Box pt={7}>
          <VNButton disableRipple={true} buttonVariant='button4' text={APPLICATION_STRINGS.EMAIL_BUTTON_LONG_TEXT} onClick={handleEmailReceiptClick} />
        </Box>
      )
    }
    return
  }

  const displayTextButton = () => {
    const textReceiptsEnabled = initialValues.get('textReceiptsEnabled') === 'true'
    if (textReceiptsEnabled && !transactionResult.has('offline')) {
      return (
        <Box pt={1}>
          <VNButton disableRipple={true} buttonVariant='button4' text={APPLICATION_STRINGS.TEXT_BUTTON_LONG_TEXT} onClick={handleTextReceiptClick} />
        </Box>
      )
    }
    return
  }

  const display = () => {
    if (!transactionResult.isEmpty()) {
      return (
        <Box className={classes.receiptOverview}>
          <Typography variant='h2'>Total Paid</Typography>
          <Typography style={theme.custom.large1}>${floatToDollars(transactionResult.get('amount'))}</Typography>
          <Box style={{width: 480, margin: 'auto'}}>
            {displayEmailButton()}
            {displayTextButton()}
            {displayPrintingButton()}
            <Box pt={1}>
              <VNTimeoutButton ref={timeoutRef} buttonVariant='button4' countdownTime={10} text="I'm Done" onClick={handleCompleteClick} />
            </Box>
          </Box>
        </Box>
      )
    }
  }

  const displayDebug = () => {
    if (!cartTotals.isEmpty()) {
      return (
        <div>
          {JSON.stringify(cartTotals)}
        </div>
      )
    }
  }

  return (
    <Box className={classes.root}>
      {/* {displayDebug()} */}
      {display()}
      <VNInputDialog
        open={openEmailDialog}
        title={"Enter Email"}
        subTitle={"Separate multiple emails with commas"}
        onSave={onEmailDialogSave}
        onClose={onEmailDialogClose}
        type={"email"}
        endAdornment
        fullWidth
        width={'480px'}
      />
      <VNInputDialog
        open={openTextDialog}
        title={"Enter Phone"}
        onSave={onTextDialogSave}
        onClose={onTextDialogClose}
        type={"phone"}
        endAdornment
        fullWidth
        width={'480px'}
      />
    </Box>
  )
}
