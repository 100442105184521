import FullModifierViewModel from './FullModifierViewModel'
import { formatCartItemModifiers, displayPreOrdersItemPrice } from '../utils/formatters'

const PreOrdersItemViewModel = ((lineItem) => {
  const modifiers = lineItem?.modifiers ?? [[]]
  const notes = lineItem?.notes ?? null
  const safePrice = lineItem?.price || '0.00' 

  const model = {
    id: lineItem.menuItemUuid,
    name: lineItem.name,
    price: displayPreOrdersItemPrice(lineItem, modifiers[0]),
    color: null,
    priceInCents: parseInt(safePrice.replace('.', '')),
    lineItemUuid: lineItem.uuid,
    quantity: lineItem.quantity,
    fractionalQuantity: lineItem.fractionalQuantity,
    modifiersString: ''
  }

  if (modifiers.length) {
    model.modifiers = modifiers[0].map(modifier => {
      return FullModifierViewModel({
        ...modifier,
        modifiedLineItemUuid: lineItem.uuid,
      })
    })
    model.modifiersString = formatCartItemModifiers(model.modifiers, notes)
  }
  
  return model
})

export default PreOrdersItemViewModel