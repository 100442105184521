import React from 'react'
import { VNProgressCounter } from '../../../VNCustomerFacingDisplay/components/VNProgressCounter'
import { useDispatch, useSelector } from 'react-redux'
import { sendCFDScreenNav } from '../../../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../../../VNCustomerFacingDisplay/Reducer'
import { setCardAuthStepTwo } from '../../ActionCreators'
import { VNDialog } from '../../../VNCustomerFacingDisplay/components/VNDialog'
import { getCardAuthStepTwo } from '../../Selectors'

export const VNCardAuthStepTwo = () => {

  const dialogData = useSelector(state => getCardAuthStepTwo(state))
  const dispatch = useDispatch()

  const getImage = () => {
    return (
      <VNProgressCounter count={3} total={2} />
    )
  }

  const getTitle = () => {
    return `Card Authorized for ${dialogData?.get('amount')}`
  }

  const primaryButtonCountdownTime = 10

  const getPrimaryButtonText = () => {
    return 'NEW ORDER'
  }

  // TODO: Subject to change
  const primaryButtonClick = () => {
    sendCFDScreenNav(CFD_SCREEN.IDLE)
    dispatch(setCardAuthStepTwo({open: false}))
  }

  return (
    <VNDialog 
      open={dialogData?.get('open')}
      image={getImage()}
      title={getTitle()}
      primaryButtonCountdownTime={primaryButtonCountdownTime}
      primaryButtonText={getPrimaryButtonText()}
      onPrimaryButtonClick={primaryButtonClick}
    />
  )
}