import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { nth, isArray } from 'lodash'

import styles from './SegmentedControl.module.scss'

const SegmentedControl = ({ items, selectedIndex, onChange, className, isKiosk }) => {
  const classNames = isKiosk ? cn(styles.segmentedControlKiosk, className) : cn(styles.segmentedControl, className)

  return (
    <div className={classNames}>
      {items.map((item, index) => {
        const shouldRenderSubtext = isArray(item)

        const text = shouldRenderSubtext ? nth(item, 0) : item
        const subtext = shouldRenderSubtext ? nth(item, 1) : null

        const textElement = <span className={styles.text}>{text}</span>
        let subtextElement = null

        if (shouldRenderSubtext) {
          subtextElement = <span className={styles.subtext}>{subtext}</span>
        }

        return (
          <div
            key={`${item}-${index}`}
            className={!isKiosk ? styles.item : cn(styles.item, { [styles.selected]: selectedIndex === index })}
            onClick={() => onChange(index)}
          >
            <div className={!isKiosk ? cn(styles.item, { [styles.selected]: selectedIndex === index }) : null}>{textElement}</div>
            {subtextElement}
          </div>
        )
      })}
    </div>
  )
}

SegmentedControl.defaultProps = {
  items: [],
  selectedIndex: '',
  onChange: () => {},
}

SegmentedControl.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.array ])),
  selectedIndex: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func,
}

export default SegmentedControl
