import { getItemsWithQuantity } from '../../selectors/items'
import { getItemCategories } from '../../selectors/itemCategory'
import {
  getHighlightedItemsById,
  getFocusedItemId,
  getSearchQuery,
  getHasQuickSearchResults,
  getSelectedCategory
} from '../../selectors/search'

import ItemsViewModel from '../../viewmodels/ItemsViewModel'

export const mapStateToPropsForItems = (state) => {
  const highlightedItemsById = getHighlightedItemsById(state)
  const searchQuery = getSearchQuery(state)
  const focusedItemId = getFocusedItemId(state)
  const hasQuickSearchResults = getHasQuickSearchResults(state)
  const itemsWithQuantity = getItemsWithQuantity(state)
  const items = ItemsViewModel(itemsWithQuantity)
  const selectedCategory = getSelectedCategory(state)
  const itemCategories = getItemCategories(state)

  return { items, highlightedItemsById, searchQuery, focusedItemId, hasQuickSearchResults, selectedCategory, itemCategories }
}
