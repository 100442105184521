/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { MODES } from './VNMode/Reducer';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = { hasError: false };

  static getDerivedStateFromError(error) {
    console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    window.onerror = this.props.handleError;
    window.addEventListener('error', this.props.handleError);
  }

  componentDidCatch(error, errorInfo) {
    this.props.handleError(error);
  }

  render() {
    const { children, cfdMode } = this.props;
    const { hasError } = this.state;
    console.log(hasError)

    if (hasError) {
      // You can render any custom fallback UI

      if ( cfdMode === MODES.CFD) {
        return (
          <>
            <p>Something went wrong. Please contact support. If you aren't redirected, please click <a href="/?apk=true&cfd=true">here</a> to start over.</p>
          </>
        )
      }

      return (
        <>
          <p>Something went wrong. Please contact support. If you aren't redirected, please click <a href="/user-login">here</a> to go back to login.</p>
        </>
      )
    }

    return children;
  }
}

export default ErrorBoundary;