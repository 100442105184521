/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { isEmpty } from 'lodash'
import Lottie from "lottie-react";

import { getOrderInProgress } from '../../selectors/order'
import { addPaymentToOrderInProgress, closeNoOpTender } from '../../actions/order'

import { formatPrice } from '../utils/formatters'
import { centsToDollars } from '../../utils/formatters'
import PeripheralBridge from '../../utils/peripheralBridge'
import UserIdleMonitor from '../utils/UserIdleMonitor'
import { KEY_CODES } from "../utils/contants"
import { ToastManager } from '../../utils/toastManager'

import OfflineModal from '../OfflineModal'
import ConnectingIndicator from '../../components/ConnectingIndicator'
import BackButton from '../components/BackButton'
import ScanTicketGif from '../assets/images/kiosk-ticket-scan.gif'
import { ReactComponent as Alert } from '../assets/icons/alert.svg'

import useGetTicketBalance from '../../hooks/useGetTicketBalance'
import useCreateOrderInProgress from '../../hooks/useCreateOrderInProgress'
import loadingSpinnerAnimationData from '../assets/images/kiosk-loading.json'
import { handleFullPpiFlow } from '../../VNPPI/Utils'
import { VNPPI_TENDERS } from '../../VNPPI/Enums'
import { VNInjectablePpi } from '../../VNPPI/containers/VNInjectablePpi'
import { codeAlreadyUsed } from '../../VNUtil/VNUtils'

export const KioskTicket = ({
  onGoBack,
  needCreateOrder,
  order,
  addPaymentToOrderInProgress,
  balanceDueInCents,
  amountInCents,
  placeFreeOrder,
  push
}) => {

  const [isProcessing, setIsProcessing] = useState(false)
  const [showPayWithCard, setShowPayWithCard] = useState(false)
  const [ticketId, setTicketId] = useState('')
  const [createOrder] = useCreateOrderInProgress()
  const orderExists = !isEmpty(order)
  const [codeError, setCodeError] = useState(false)
  const [getTicketBalance, { ticketBalance, directAuthEnabled, dataSucceeded, dataFailed }] = useGetTicketBalance()
  const [componentMounted, setComponentMounted] = useState(false)
  const orderStillHasRemainingBalance = balanceDueInCents !== amountInCents && orderExists
  const noPaymentRequired = order?.amountInCents === 0
  const ticketHasNoBalance = +ticketBalance === 0
  const backButtonRef = useRef()

  const ppiInjectableRef = useRef()

  // MANUAL ENTRY HACK FOR KIOSK MODE ON BROWSER (KEEP IN CODE)
  // const ticketInputRef = useRef()

  const scannedResultText = ticketHasNoBalance ? 'No funds remain on this ticket. \nPlease scan another ticket.' : `Ticket Scanned! Scan another ticket to pay the remaining balance of ${centsToDollars(balanceDueInCents)}`
  let primaryText = orderStillHasRemainingBalance ? 'SCAN NEXT TICKET' : 'SCAN TICKET'
  let secondaryText = orderStillHasRemainingBalance || ticketHasNoBalance ? scannedResultText : 'Please scan ticket QR code or barcode.'

  if (codeError) {
    secondaryText = 'Ticket scan error. Try again.'
  }

  useEffect(() => {
    if (needCreateOrder) {
      createOrder()
    }
    setComponentMounted(true)
    return () => {
      const bridgeInstance = PeripheralBridge.getBridge()
      if (!bridgeInstance) return
      bridgeInstance.call('stopScanner')
    }
  }, [])

  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.UP) {
      onGoBack()
    }
  }

  useEffect(() => {
    if (dataFailed) {
      setIsProcessing(false)
      setCodeError(true)
    }
  }, [dataFailed])

  useEffect(() => {
    if (dataSucceeded) {
      setCodeError(false)
      let tenderAmountInCents
      let remainingTicketBalance
      if (directAuthEnabled) {
        tenderAmountInCents = balanceDueInCents
      } else
      if (ticketHasNoBalance) {
        resetTicket()
        return
      } else
      if (ticketBalance >= balanceDueInCents) {
        tenderAmountInCents = balanceDueInCents
        remainingTicketBalance = ticketBalance - balanceDueInCents
      } else {
        tenderAmountInCents = ticketBalance
        remainingTicketBalance = 0
      }
      addPaymentToOrderInProgress({
        tenderAmountInCents,
        paymentType: 'loaded_ticket',
        shortDescription: 'Ticket',
        token: ticketId,
        remainingTicketBalance,
        directAuthEnabled,
      })
      resetTicket()
    }
  }, [dataSucceeded])

  useEffect(() => {
    if (needCreateOrder || !componentMounted) return

    if (!balanceDueInCents) {
      setIsProcessing(true)
    }
  }, [balanceDueInCents])

  useEffect(() => {
    if (orderExists) {
      if (noPaymentRequired) {
        return
      }
      const bridgeInstance = PeripheralBridge.getBridge()
      if (!bridgeInstance) {
        return
      }
      bridgeInstance.registerHandler('scanPayload', function (data, callback) {
        const scannedTicket = data?.valueOf()

        // need to look at payment.token as well
        if (codeAlreadyUsed(order, scannedTicket)) {
          ToastManager.error('Ticket already scanned and applied to order')
        } else {
          setTicketId(scannedTicket)
          startBalanceCheck(scannedTicket)
        }
      })

      bridgeInstance.registerHandler('scanError', function (err, callback) {
        setCodeError(true)
        if(err?.toLowerCase()?.includes('no scanner detected')) {
          setShowPayWithCard(true)
        }
      })
    }
  }, [orderExists, order])

  useEffect(() => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    if (isProcessing) {
      bridgeInstance.call('stopScanner')
    } else if (orderExists) {
      bridgeInstance.call('startScanner')
    }
  }, [isProcessing, orderExists])

  const resetTicket = () => {
    setTicketId('')
    setIsProcessing(false)
  }

  const startBalanceCheck = async (code) => {

    setIsProcessing(true)
    setCodeError(false)

    const ppiInjectableData = ppiInjectableRef.current.getData(VNPPI_TENDERS.TICKET_TENDER)

    if (!isEmpty(ppiInjectableData.ppiTender)) {

      const externalFunctionality = {
        functions: {
          ...ppiInjectableData.functions,
          setError: setCodeError,
          push,
          placeFreeOrder: placeFreeOrder
        },
        balanceDueInCents: ppiInjectableData.order?.balanceDueInCents,
        promotions: ppiInjectableData.promotions,
        token: code,
        paymentType: "vnapi_ticket",
        shortDescription: "Ticket"
      }

      await handleFullPpiFlow(ppiInjectableData, externalFunctionality)

      resetTicket()

    } else {
      getTicketBalance(code)
    }
  }

  const PaymentBackBtn = () => {
    return (
      <BackButton
        handleKeyDown={handleKeyDown}
        refProp={backButtonRef}
        className="payment__back"
        withIcon={true}
        onClick={onGoBack}
      >
        {!orderExists ? '-' : formatPrice(order?.balanceDueInCents)}
      </BackButton>
    )
  }

  const idleMonitor = new UserIdleMonitor(process.env.REACT_APP_DEV_IDLE_TIMEOUT || 60000)
  useEffect(() => {
    backButtonRef.current && backButtonRef.current.focus()

    idleMonitor.addEventListeners()
    return () => {
      idleMonitor.removeEventListeners()
    }

  }, [])

  if (!orderExists) {
    return <ConnectingIndicator isKiosk={true} position={'initial'}/>
  }

  let footerElement = null

  const isShowPayWithCardTxt = dataFailed || showPayWithCard || codeError

  if (orderStillHasRemainingBalance || ticketHasNoBalance || isShowPayWithCardTxt) {
    footerElement = <div className="payment__footer-button" onClick={() => {
      push({
        pathname: `/kiosk/order/payment`,
        state: {
          tenderAmountInCents: balanceDueInCents,
          scanScreenType: 'Ticket',
        }
      })
    }}>
      { isShowPayWithCardTxt ? 'PAY WITH CREDIT CARD' : "I DON'T HAVE ANOTHER TICKET" }
    </div>
  }

  let content = (
    <>
      <PaymentBackBtn />
      <div className="payment__header">{primaryText}</div>
      <div className="payment__sub-header">{(ticketHasNoBalance || codeError) && <Alert className="payment__alert-icon"/>}{secondaryText}</div>
      <div className="payment__ticket-wrapper">
      <img src={ScanTicketGif} alt={"Scan Ticket"} className={"payment__card-reader-icon"}/>
      </div>
      <div className={`payment__footer-text${!isEmpty(footerElement) ? '-with-button' : ''}`}>
        Scanner is Ready
      </div>
        {footerElement}
    </>
    )

  if (isProcessing) {
    content = (
      <>
        <PaymentBackBtn />
        <div className="payment__header">Processing</div>
        <div className="payment__sub-header">Please wait.</div>
        <div className="payment__ticket-wrapper">
          <div className="payment__card-reader-icon">
            <Lottie animationData={loadingSpinnerAnimationData} />
          </div>
        </div>
      </>
    )
  }

  if (noPaymentRequired) {
    content = (
      <>
        <div className="payment__content">
          <PaymentBackBtn />
          <div className="payment__header">No Charge!</div>
          <div className="payment__sub-header">Submitting your order...</div>
        </div>
      </>
      )
  }

  if (dataFailed) {
    content = (
      <>
        <PaymentBackBtn />
        <div className="payment__header">{primaryText}</div>
        <div className="payment__sub-header"><Alert className="payment__alert-icon"/>Failed to retrieve ticket balance</div>
      </>
    )
  }

  // MANUAL ENTRY HACK FOR KIOSK MODE ON BROWSER (KEEP IN CODE)
  // const handleKeyPress = e => {
  //   if (e.keyCode === KEY_CODES.ENTER) {
  //     if (codeAlreadyUsed(order, ticketInputRef.current.value)) {
  //       return
  //     }
  //     startBalanceCheck(ticketInputRef.current.value)
  //   }
  // }

  return (
    <div className="payment">
      <VNInjectablePpi ref={ppiInjectableRef} />

      {/* MANUAL ENTRY HACK FOR KIOSK MODE ON BROWSER (KEEP IN CODE) */}
      {/* <input
        ref={ticketInputRef}
        onKeyDown={handleKeyPress}
      /> */}
      {content}
      <OfflineModal />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const order = getOrderInProgress(state)
  const balanceDueInCents = order?.balanceDueInCents ?? 0
  const amountInCents = order?.amountInCents
  const push = props?.history?.push ?? (() => {})

  return {
    order,
    needCreateOrder: isEmpty(order),
    balanceDueInCents,
    amountInCents,
    push
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const push = props?.history?.push ?? (() => {})
  const goBack = props?.history?.goBack ?? (() => {})

  return {
    onGoBack: () => goBack(),
    addPaymentToOrderInProgress: (payment) => dispatch(addPaymentToOrderInProgress({ ...payment, push })),
    placeFreeOrder: (payload) => {
      dispatch(closeNoOpTender(payload))
      push(`/kiosk/order/summary/${payload.orderUuid}`)
    }
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(KioskTicket)
