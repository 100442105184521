import React from 'react'
import { VNCustomerPayingWithCard } from '../components/VNCustomerPayingWithCard'
import { VNCustomerViewingReceiptDialog } from '../components/VNCustomerViewingReceiptDialog'
import { VNTransactionCompleteDialog } from '../components/VNTransactionCompleteDialog'
import { VNTransactionErrorDialog } from '../components/VNTransactionErrorDialog'
import { VNCustomerPaymentDialog } from '../components/VNCustomerPaymentDialog'
import { VNConfirmPaymentDialog } from '../components/VNConfirmPaymentDialog'
import { VNCustomerEnteringTipDialog } from '../components/VNCustomerEnteringTipDialog'
import { VNCardAuthStepOne } from '../components/Auth/VNCardAuthStepOne'
import { VNCardAuthStepTwo } from '../components/Auth/VNCardAuthStepTwo'
import { VNKitchenPrintingModal } from '../components/VNKitchenPrintingModal'

export const VNDialogsContainer = () => {

  return (
    <>
      <VNCustomerPayingWithCard />
      <VNCustomerViewingReceiptDialog />
      <VNTransactionCompleteDialog />
      <VNTransactionErrorDialog />
      <VNCustomerPaymentDialog />
      <VNConfirmPaymentDialog />
      <VNCardAuthStepOne/>
      <VNCardAuthStepTwo/>
      <VNCustomerEnteringTipDialog />
      <VNKitchenPrintingModal/>
    </>
  )
}
