import React from 'react'
import cn from 'classnames'

import ReceiptDetails from '../../containers/receipt/ReceiptDetails'

import styles from './ReceiptCard.module.scss'

const ReceiptCard = ({ className, isRichCheckoutOrder, serviceChargeEnabled, isMobile }) => {
  const classNames = cn(styles.receiptCard, className)
  const disclaimers = (
    <>
      {isRichCheckoutOrder && <div className={styles.disclaimer}>
        Discounts and gratuity applied from mobile purchases are not included.
        Please check your mobile receipt for final total.
      </div>}
      {serviceChargeEnabled && <div className={styles.disclaimer}>
        {serviceChargeEnabled}
      </div>}
    </>
    
  )

  return (
    <div className={classNames}>
      <ReceiptDetails/>
      {isMobile && disclaimers}
    </div>
  )
}

export default ReceiptCard
