import PeripheralBridge from './peripheralBridge'
import { PUSHER_KEY, PUSHER_CLUSTER } from '../constants'
import { isEqual } from 'lodash'

const executeDeviceAuthenticated = (data) => {
  const bridgeInstance = PeripheralBridge.getBridge()
  if (!bridgeInstance) return

  bridgeInstance?.call('deviceAuthenticated', data)
}

const memoizeDeviceAuthenticated = () => {
  let cache = undefined

  return (params) => {
    const data = {
      pusherAPIKey: PUSHER_KEY,
      pusherCluster: PUSHER_CLUSTER,
      porticoURL: process.env.REACT_APP_OIDC_ISSUER,
      porticoClientId: process.env.REACT_APP_OIDC_CLIENT_ID,
      orgName: process.env.REACT_APP_ORG,
      isPrintController: params?.isPrintController ?? false,
    }

    if (!isEqual(cache, data)) {
      executeDeviceAuthenticated(data)
      cache = data
    }
  }
}

const executeSaveConfig = (config) => {
  const bridgeInstance = PeripheralBridge.getBridge()

  if (bridgeInstance?.callHandler) {
    bridgeInstance.callHandler('saveConfig', config)
  }
}

const memoizeSaveConfig = () => {
  let cache = undefined

  return (config) => {
    if (!isEqual(cache, config)) {
      executeSaveConfig(config)
      cache = config
    }
  }
}

export const executePerformMaintenance = () => {
  const bridgeInstance = PeripheralBridge.getBridge()
  if (!bridgeInstance) return

  bridgeInstance.call('performMaintenance')
}

export const deviceAuthenticated = memoizeDeviceAuthenticated()

export const saveConfig = memoizeSaveConfig()
