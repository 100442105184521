import { bridgePaymentMethods } from "../reducers/peripheral"

export const ON_FIRMWARE_INFO_RECEIVED = 'ON_FIRMWARE_INFO_RECEIVED'
export const ON_BUILD_INFO_RECEIVED = 'ON_BUILD_INFO_RECEIVED'
export const ON_TERMINAL_INFO_RECEIVED = 'ON_TERMINAL_INFO_RECEIVED'
export const ERROR_TRANSACTION = 'ERROR_TRANSACTION'
export const CLEAR_TRANSACTION_ERROR = 'CLEAR_TRANSACTION_ERROR'
export const ON_PERIPHERAL_BUSY_STATE_CHANGE = 'ON_PERIPHERAL_BUSY_STATE_CHANGE'
export const ON_SAF_IS_UPLOADING = 'ON_SAF_IS_UPLOADING'
export const ON_SAF_UPLOAD_COMPLETE = 'ON_SAF_UPLOAD_COMPLETE'
export const ON_SAF_FULL_RESPONSE_RECEIVED = 'ON_SAF_FULL_RESPONSE_RECEIVED'
export const ON_RESET_SAF_FULL_RESPONSE = 'ON_RESET_SAF_FULL_RESPONSE'
export const ON_DATA_IS_UPLOADING = 'ON_DATA_IS_UPLOADING'
export const ON_DATA_UPLOAD_COMPLETE = 'ON_DATA_UPLOAD_COMPLETE'
export const SET_BRIDGE_PAYMENT_METHOD = 'SET_BRIDGE_PAYMENT_METHOD'

export const onFirmwareInfoReceived = (payload) => {
  return {
    type: ON_FIRMWARE_INFO_RECEIVED,
    payload,
  }
}

export const onBuildInfoReceived = (payload) => {
  return {
    type: ON_BUILD_INFO_RECEIVED,
    payload,
  }
}

export const onTerminalInfoReceived = (payload) => {
  return {
    type: ON_TERMINAL_INFO_RECEIVED,
    payload,
  }
}

export const onPeripheralBusyStateChange = (payload) => {
  return {
    type: ON_PERIPHERAL_BUSY_STATE_CHANGE,
    payload
  }
}

// TODO(mkramerl): Rename this to setSafBatchUploadComplete.
export const setSafUploadComplete = (payload) => {
  return {
    type: ON_SAF_UPLOAD_COMPLETE,
    payload
  }
}

// TODO(mkramerl): Rename this to setSafStatusesAreSyncing.
export const onSafIsUploading = (payload) => {
  return {
    type: ON_SAF_IS_UPLOADING,
    payload
  }
}

// TODO(mkramerl): Rename this to setSafBatchUploadComplete.
export const setDataUploadComplete = (payload) => {
  return {
    type: ON_DATA_UPLOAD_COMPLETE,
    payload
  }
}

// TODO(mkramerl): Rename this to setSafStatusesAreSyncing.
export const onDataIsUploading = (payload) => {
  return {
    type: ON_DATA_IS_UPLOADING,
    payload
  }
}

export const setSafFullResponse = (payload) => {
  return {
    type: ON_SAF_FULL_RESPONSE_RECEIVED,
    payload
  }
}

export const resetSafFullResponse = () => {
  return {
    type: ON_RESET_SAF_FULL_RESPONSE,
  }
}

// only payment methods are 'executeSaleRequest' and 'executeAuthRequest'
export const setBridgePaymentMethod = (payload) => {
  if (!bridgePaymentMethods.hasOwnProperty(payload)) {
    return
  }

  return {
    type: SET_BRIDGE_PAYMENT_METHOD,
    payload
  }
}