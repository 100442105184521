import { call, takeEvery, put, select } from 'redux-saga/effects'
import { keyBy } from 'lodash'

import Remote from '../remote'

import {
  PRINT_ORDER_REQUESTED,
  FETCH_PRINTERS_REQUESTED,
  fetchPrintersSucceeded,
  fetchPrintersFailed
} from '../actions/printer'

import { makeGetCurrentMenu } from '../selectors/menus'
import { getDeviceSubtype } from '../selectors/config'
import { getNetworkPrinter } from '../selectors/printer'
import { getOrderInProgress, makeGetOrder } from '../selectors/order'
import { makeGetServiceChargeLegalText, getVenueName } from '../selectors/venue'

import createPrinterData, { DEFAULT_PRINTERS } from '../utils/printing'
import PeripheralBridge from '../utils/peripheralBridge'
import createDataDogLog from '../utils/datadog'

import MutationApi from '../remote/mutationApi'
import { getAttendantName } from '../selectors/attendant'
import { createOrderParams } from '../utils/orderUtils'
import { ToastManager } from '../utils/toastManager'

export function* printOrder(params = {}) {
  const orderUuid = params?.payload?.orderUuid
  const deviceType = params?.payload?.deviceType
  const isNewOrder = params?.payload?.isNewOrder
  const currentNetworkPrinter = yield(select((state) => getNetworkPrinter(state)))

  if (!orderUuid) {
    ToastManager.error('Required: orderUuid is missing')
    createDataDogLog('error', 'Print Failed :Required: orderUuid is missing')
    return
  }

  if (currentNetworkPrinter && currentNetworkPrinter !== DEFAULT_PRINTERS.USB.uuid && deviceType !== 'kiosk') {
    try {
      // eslint-disable-next-line
      const result = yield call(MutationApi.printOrder, {
        orderUuid,
        printType: 'suite-receipt',
        printerUuids: [ currentNetworkPrinter ],
        paymentUuid: null
      })

      ToastManager.info('Receipt print sent to printer.')

      return
    } catch (err) {
      ToastManager.error("There was an error printing the receipt.")
    }
  }

  const getOrder = makeGetOrder()
  const getServiceChargeLegalText = makeGetServiceChargeLegalText()

  const order = isNewOrder ? yield select((state) => getOrderInProgress(state)) : yield select((state) => getOrder(state, orderUuid))

  const menu = yield select((state) => makeGetCurrentMenu()(state, order.standMenuUuid))
  const serviceChargeLegal = yield select((state) => getServiceChargeLegalText(state, orderUuid))
  const deviceSubtype = yield select(getDeviceSubtype)
  const venueName = yield select(getVenueName)
  const attendantName = yield select((state) => getAttendantName(state, createOrderParams(order)?.employeeId))

  const printingData = createPrinterData({ deviceSubtype, menu, order, serviceChargeLegal, venueName, attendantName })
  const bridgeInstance = PeripheralBridge.getBridge()

  if (!bridgeInstance) {
    if (deviceType !== 'kiosk') {
      ToastManager.error('Print Failed: Communication could not be established')
      createDataDogLog('error', 'Print Failed: Communication could not be established')
    }
    return
  }

  bridgeInstance.callHandler('printReceipt', printingData, () => {})
}

export function* fetchPrinters(params = {}) {
  const venueUuid = process.env.REACT_APP_VENUE_UUID

  try {
    const result = yield call(Remote.getPrinters, venueUuid)
    const byId = keyBy(result?.data?.printers, 'uuid')
    yield put(fetchPrintersSucceeded({ byId }))
  } catch (err) {
    yield put(fetchPrintersFailed(err))
  }
}

export function* watchPrintOrder() {
  yield takeEvery(PRINT_ORDER_REQUESTED, printOrder)
}


export function* watchFetchPrinters() {
  yield takeEvery(FETCH_PRINTERS_REQUESTED, fetchPrinters)
}
