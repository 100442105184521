import Pusher from 'pusher-js'
import { PUSHER_KEY, PUSHER_CLUSTER } from '../constants'

// CHANNELS
export const CHANNEL_PRIVATE_POS_VENUE = `private-pos-${process.env.REACT_APP_VENUE_UUID}`
export const CHANNEL_PRIVATE_SOURCE_DEVICE_ID= `private-source-device-${localStorage.getItem('device_id')}`

// EVENTS
export const PUSHER_EVENT_ORDER_MUTATION = 'order-mutation'
export const PUSHER_EVENT_ORDER_TRANSFERRED = 'order-transferred'
export const PUSHER_EVENT_ORDER_CREATED = 'order-created'

// BINDS
export const BIND_ORDERS_MUTATION = 'BIND_ORDERS_MUTATION'
export const BIND_CUSTOMER_TABS_MUTATION = 'BIND_CUSTOMER_TABS_MUTATION'
export const BIND_PAYMENT_INDEX = 'BIND_PAYMENT_INDEX'
export const BIND_RICH_CHECKOUT_CONTAINER = 'BIND_RICH_CHECKOUT_CONTAINER'
export const BIND_RICH_CHECKOUT_CONTAINER_ORDER_CREATED = 'BIND_RICH_CHECKOUT_CONTAINER_ORDER_CREATED'

// WARNING (@westinschepper): This is deprecated for Full-Service. Keeping for specs.
class _DEPRECATED_VNPusher {

  constructor() {
    if (!_DEPRECATED_VNPusher.instance) {
      _DEPRECATED_VNPusher.instance = this
    }

    this.socketSetup()

    // setup a channels map to there is only one subscribe per react instance
    this.channels = {}

    // setup a binds map to only create one bind each time the dev actually ones to receive the message
    // the bind isn't idompotent, every bind is unique. The value inside this map is NOT used
    this.binds = {}

    return _DEPRECATED_VNPusher.instance
  }

  socketSetup() {
    this.socket = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      authEndpoint: `${process.env.REACT_APP_OIDC_ISSUER}/pusher/auth`,
      forceTLS: true,
      auth: {
        params: {
          'bearer_token': localStorage.getItem('accessToken')
        }
      }
    })
  }

  subscribe(channelName) {
    if (!this.channels[channelName]) {
      this.channels[channelName] = this.socket.subscribe(channelName)
    }
  }

  unsubscribe(channelName) {
    if (this.channels[channelName]) {
      this.socket.unsubscribe(channelName)
      this.socket.disconnect()
      delete this.channels[channelName]
    }
  }

  bind(uniqueBindId, channelName, eventName, eventFunc) {
    if (!this.binds[uniqueBindId]) {
      this.channels[channelName].bind(eventName, eventFunc)
      this.binds[uniqueBindId] = 'exists'
    }
  }
}

export class VNPusher {
  constructor() {
    this.socketSetup()

    // setup a channels map to there is only one subscribe per react instance
    this.channels = {}

    // setup a binds map to only create one bind each time the dev actually ones to receive the message
    // the bind isn't idompotent, every bind is unique. The value inside this map is NOT used
    this.binds = {}
  }

  getDeviceChannel() {
    // We could take this a step further and call the bridge if `device_id` is null
    return `private-source-device-${localStorage.getItem('device_id')}`
  }

  socketSetup() {
    this.socket = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      authEndpoint: `${process.env.REACT_APP_OIDC_ISSUER}/pusher/auth`,
      forceTLS: true,
      auth: {
        params: {
          'bearer_token': localStorage.getItem('accessToken')
        }
      }
    })
  }

  subscribe(channelName) {
    if (!this.channels[channelName]) {
      this.channels[channelName] = this.socket.subscribe(channelName)
    }
  }

  unsubscribe(channelName) {
    if (this.channels[channelName]) {
      this.socket.unsubscribe(channelName)
      this.socket.disconnect()
      delete this.channels[channelName]
    }
  }

  bind(uniqueBindId, channelName, eventName, eventFunc) {
    if (!this.binds[uniqueBindId]) {
      this.channels[channelName].bind(eventName, eventFunc)
      this.binds[uniqueBindId] = 'exists'
    }
  }

  subscribeDeviceChannel() {
    this.subscribe(this.getDeviceChannel())
  }


  unsubscribeDeviceChannel() {
    this.unsubscribe(this.getDeviceChannel())
  }

  bindOrderTransfered(callback) {
    this.bind(
      BIND_RICH_CHECKOUT_CONTAINER,
      this.getDeviceChannel(),
      PUSHER_EVENT_ORDER_TRANSFERRED,
      callback,
    )
  }

  bindOrderCreated(callback) {
    this.bind(
      BIND_RICH_CHECKOUT_CONTAINER_ORDER_CREATED,
      this.getDeviceChannel(),
      PUSHER_EVENT_ORDER_CREATED,
      callback,
    )
  }
}

const instance = new _DEPRECATED_VNPusher()
Object.freeze(instance)

export default _DEPRECATED_VNPusher
