import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import { orange } from '@material-ui/core/colors';
import { CloudUploadOutlined } from '@material-ui/icons'

import PropTypes from 'prop-types'

import { getSafMode } from '../selectors/appState'
import { getSafIsUploading } from '../selectors/peripheral'

import StatusIndicator from './StatusIndicator'

const NetworkIndicator = ({ available, safMode, safIsUploading, speed, onClick, className, ...props }) => {
  var color = "white"
  var icon = <CloudDoneIcon style={{color: color}} />
  var networkSignal = "Online First"

  const updateStatus = (available, speed, safMode, safIsUploading) => {
    var signalOverride = undefined
    var colorOverride = undefined

    if (safMode === 2) {
      signalOverride = "Offline Only"

      // Per product spec, use a non-standard color for offline mode.
      colorOverride = "#FFD600"
    }

    if (safIsUploading) {
      color = orange[500]
      icon = <CloudUploadOutlined style={{ color: color }} />
      networkSignal = "Syncing"
    } else if (!available) {
      color = "#EB628D"
      icon = <CloudOffIcon style={{ color: color }} />
      networkSignal= "Offline"
    } else if (speed === 1) {
      color = colorOverride || "#FFF2AF"
      icon = <CloudQueueIcon style={{ color: color }} />
      networkSignal= signalOverride || "Weak"
    } else if (speed === 2) {
      color = colorOverride || "#FFF2AF"
      icon = <CloudQueueIcon style={{ color: color }} />
      networkSignal = signalOverride || "Fair"
    } else if (available) {
      color = colorOverride || "white"
      icon = <CloudDoneIcon style={{ color: color }} />
      networkSignal = signalOverride || "Online"
    }
  }

  updateStatus(available, speed, safMode, safIsUploading)

  return <StatusIndicator {...props} icon={icon} text={networkSignal} color={color} onClick ={onClick} className={className} />
}

const mapStateToProps = (state) => {
  const available = get(state, 'config.networkAvailable', true)
  const safMode = getSafMode(state)
  const safIsUploading = getSafIsUploading(state)

  return { available, safMode, safIsUploading }
}

NetworkIndicator.propTypes = {
  available: PropTypes.bool,
  safMode: PropTypes.number
}

export default connect(mapStateToProps, null)(NetworkIndicator)
