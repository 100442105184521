import { map } from "lodash"

const makeTipSuggestion = (display, amountInCents, selected, custom) => {
  if(display === "Other") {
    display = "Custom"
  }
  return {
    display,
    amountInCents,
    selected,
    custom,
  }
}

const makeTipSuggestionFromService = (suggestion, total) => {
  const { tipDisplay, tipAmountInCents, tipPercentage, tipSelected, tipCustom } = suggestion
  const actualTipAmountInCents = tipAmountInCents ?? parseInt(Math.round(parseFloat(tipPercentage) * total))
  return makeTipSuggestion(tipDisplay, actualTipAmountInCents, tipSelected, tipCustom)
}

const makeTipSuggestions = (menuTipSuggestions, total) => {
  const tipSuggestions = map(menuTipSuggestions, (suggestion) => makeTipSuggestionFromService(suggestion, total))
  return total === 0 ? tipSuggestions?.filter(suggestion => suggestion.display === "Custom" || suggestion.display === "No Tip") : tipSuggestions
}

export default makeTipSuggestions
