import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'

import CartDetails from '../components/CartDetails'
import { getSubtotal, getTax, getTotal } from '../selectors/cart'
import CartDetailsViewModel from '../viewmodels/CartDetailsViewModel'
import { getSelectedPromotions } from '../selectors/promotion'
import { getMenuServiceCharge } from '../selectors/items'

const mapStateToProps = (state, ownProps) => {
  // if a tabbed order is passed in, we should add the previous total to the running total
  const order = get(ownProps, 'order', {})
  const orderSubtotal = order.amountInCents || 0 // there's no separate subtotal
  const orderTax = order.taxInCents || 0 // this doesn't exist yet
  const orderTotal = order.amountInCents || 0

  // TODO(mkramerl): Clean up the distinctions between totals and subtotals and taxes.
  const subtotal = getSubtotal(state) + orderSubtotal
  const tax = getTax(state) + orderTax
  const totalWithTax = getTotal(state) + orderTotal
  const serviceChargeObject = getMenuServiceCharge(state)
  const serviceCharge = subtotal * serviceChargeObject.rate
  const total = totalWithTax
  const discountSelected = !isEmpty(getSelectedPromotions(state))

  return CartDetailsViewModel({ subtotal, tax, serviceCharge, total, discountSelected })
}

export default connect(mapStateToProps, undefined)(CartDetails)
