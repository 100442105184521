import { TOGGLE_APP_STATE, SET_VERSION_INFO, SET_AUTOMATIC_UPDATE, SET_SAF_MODE, SET_SAF_RESPONSE, SET_ERROR_INFO, SET_PERFORM_SAFE_UPDATE } from '../actions/appState'
import { ACCESSIBILITY_REQUIRED } from '../actions/appState'
import { CURRENT_MENU_SELECTED } from '../actions/menus'

import { APPLICATION_STRINGS } from '../strings'

import { ToastManager } from '../utils/toastManager'

const initialState = {
  automaticallyUpdate: false,
  currentMenuId: null,
  errorInfo: { },
  isAccessibilityRequired: false,
  isActive: true,
  isAgeConfirmed: false,
  isAlcoholWarningConfirmed: false,
  safMode: 3,
  safResponse: null,
  updateAvailable: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_APP_STATE: {
      return { ...state, ...payload }
    }

    case CURRENT_MENU_SELECTED: {
      return {
        ...state,
        currentMenuId: payload
      }
    }

    case ACCESSIBILITY_REQUIRED: {
      return {
        ...state,
        isAccessibilityRequired: payload,
      }
    }

    case SET_VERSION_INFO: {
      return {
        ...state,
        ...payload,
      }
    }

    case SET_AUTOMATIC_UPDATE: {
      return {
        ...state,
        automaticallyUpdate: payload.automaticallyUpdate,
      }
    }

    case SET_SAF_MODE: {
      return {
        ...state,
        safMode: payload,
      }
    }

    case SET_SAF_RESPONSE: {
      if (payload["success"] !== true) {
        ToastManager.error(APPLICATION_STRINGS.SAF_ERROR)
      }

      return {
        ...state,
        safResponse: payload ?? { success: true, message: null },
      }
    }

    case SET_ERROR_INFO: {
      return {
        ...state,
        errorInfo: payload ?? { },
      }
    }

    case SET_PERFORM_SAFE_UPDATE: {
      return {
        ...state,
        performSafeUpdate: payload ?? false,
      }
    }

    default:
      return state
  }
}
