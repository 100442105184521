import React, { useEffect, useContext } from "react"
import { keys } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";

import UserLoginComponent from "../components/userLogin/Component"
import ManagerApprovalContext from "./ManagerApprovalContext"

import callable from "../utils/callable"
import { ToastManager } from '../utils/toastManager'

import { setManagerId } from "../actions/attendant"

import { getCurrentMenu } from "../selectors/menus"
import { getManagerApprovalCodes } from "../selectors/employeeRole"

const ManagerApprovalComponent = () => {
  const approvalCodeToManagerMap = useSelector(getManagerApprovalCodes)
  const menu = useSelector(getCurrentMenu)

  const dispatch = useDispatch()
  const history = useHistory()

  const useManagerApprovalContext = () => useContext(ManagerApprovalContext)

  const {
    managerApprovalOnSuccess,
    managerApprovalOnFailure,
    closeManagerApproval,
    managerApprovalButtonText,
  } = useManagerApprovalContext()

  useEffect(() => {
    /**
     * We need this component to close itself everytime the user navigates
     */
    const unlisten = history.listen(() => {
      closeManagerApproval()
    })

    return () => {
      unlisten()
    }
  }, [history])

  const handleSubmit = (pin, success, failure) => {
    if (!!approvalCodeToManagerMap[pin]) {
      dispatch(setManagerId(approvalCodeToManagerMap[pin]))
      callable(success)
      closeManagerApproval()
    } else {
      callable(failure)
      ToastManager.error("Invalid Approval Code, Please Try Again", {
        autoClose: 2500,
      })
    }
  }

  return (
    <UserLoginComponent
      standName={menu?.standName}
      handleSubmit={(pin) =>
        handleSubmit(pin, managerApprovalOnSuccess, managerApprovalOnFailure)
      }
      hamburgerUnlocked={true}
      header={"Input Manager Pin"}
      forManagerApproval={true}
      approvalCodes={keys(approvalCodeToManagerMap)}
      buttonText={managerApprovalButtonText}
    />
  )
}

export default ManagerApprovalComponent
