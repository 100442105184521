/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { withRouter } from 'react-router'
import { get, isEmpty } from 'lodash'

import { VNPusher as VNPusherPrototype } from '../../pusher'
import {
  RICH_CHECKOUT_TEXT_PROMPT,
  TEAM_LOGO_EXISTS,
  SIGNATURE_THRESHOLD_AMOUNT,
} from '../../constants'

import BackButton from '../components/BackButton'
import QRCode from '../../components/QRCode'
import ConnectingIndicator from '../../components/ConnectingIndicator'

import { KEY_CODES } from '../utils/contants'
import Environment from '../../utils/environment'
import { formatPrice } from '../utils/formatters'
import { nextPosOrderNumber } from '../../utils/orderNumbers'
import { generateURL } from '../../utils/richCheckout'
import { KIOSK_PAYMENT_TYPES} from '../../utils/paymentTypes'

import { saveRichCheckoutOrderRequested } from '../../actions/richCheckout/order'
import { closeNoOpTender, setKioskPayment } from '../../actions/order'
import { getStoredRickCheckoutOrderNumber } from '../../selectors/order'
import { getTotal, getCartItems } from '../../selectors/cart'
import { makeGetCurrentMenu } from '../../selectors/menus'
import OfflineModal from '../OfflineModal'

import useRemoteOrderTotal from '../../hooks/useRemoteOrderTotal'

if (!Environment.node.isTest) {
  Modal.setAppElement('#root')
}
// TODO:: move to the utils
const getDeviceId = () => localStorage.getItem('device_id')
const ScannedScreen = ({
  saveRichCheckoutOrder,
  orderDidComplete,
  placeFreeOrder,
  total,
  history,
  appPaymentSubHeader,
  cart,
  getNextOrderNumber,
  deviceId,
  setKioskPayment,
  tipSuggestions
}) => {
  const VNPusher = useRef()
  const backButtonRef = useRef()
  const [isScanned, setIsScanned] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [orderUuid, setOrderUuid] = useState() 
  const [orderNumber] = useState(getNextOrderNumber(true))
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, failed, success }] = useRemoteOrderTotal()
  const totalInCents = remoteOrderTotal?.totalAmountInCents ?? total
  const noPaymentRequired = totalInCents === 0
  const [qrCodeData, setQrCodeData] = useState()
  const isDoneLoading = (success && !!orderUuid) || failed

  useEffect(() => {
    VNPusher.current = new VNPusherPrototype()
    loadRemoteOrderTotal()
  }, [])

  useEffect(() => {
    if (success) {
      setOrderUuid(remoteOrderTotal.uuid)
      setQrCodeData(generateURL(remoteOrderTotal.uuid, orderNumber, deviceId, totalInCents, cart))
    }
  }, [success])

  useEffect(() => {
    if (isDoneLoading) backButtonRef.current.focus()
  }, [isDoneLoading])


  useEffect(() => {
    if (isSuccess) {
      saveRichCheckoutOrder({ uuid: orderUuid, orderNumber })
      orderDidComplete(orderUuid)
    }
  }, [isSuccess])

  useEffect(() => {
    if (noPaymentRequired && success && orderUuid) {
      placeFreeOrder({ orderUuid })
    }
  }, [totalInCents, success, orderUuid])

  const onBind = () => {
    setIsScanned(true)
  }

  const onOrderCreated = () => {
    setIsSuccess(true)
  }

  useEffect(() => {
    const deviceChannelName = `private-source-device-${localStorage.getItem('device_id')}`
    VNPusher.current.subscribeDeviceChannel(deviceChannelName)
    VNPusher.current.bindOrderTransfered(onBind)
    VNPusher.current.bindOrderCreated(onOrderCreated)

    return () => {
      VNPusher.current.unsubscribeDeviceChannel(deviceChannelName)
    }
  }, [])

  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.UP) {
      goBack()
    }
  }

  const payWithCard = () => {
    const { creditCard } = KIOSK_PAYMENT_TYPES
    setKioskPayment(creditCard) 
    
    if (((SIGNATURE_THRESHOLD_AMOUNT !== 0 && !SIGNATURE_THRESHOLD_AMOUNT) || (SIGNATURE_THRESHOLD_AMOUNT && totalInCents < SIGNATURE_THRESHOLD_AMOUNT)) && isEmpty(tipSuggestions))  {
      history.push('/kiosk/order/payment')
    } else {
      history.push(`/kiosk/signature`)
    }
  }

 const goBack = () => history.push('/kiosk/order/new')

    return isDoneLoading ? (
      <>
        <OfflineModal intervalTimer={1000} intervalTimeout={900} />
        {<div className="scanned-screen__top-half">
          <div className="scanned-screen__gratuity-header">
          {isDoneLoading && 
            <BackButton handleKeyDown={handleKeyDown}
            refProp={backButtonRef}
            className="scanned-screen__back"
            withIcon={true}
            onClick={goBack}
            >
              {!isDoneLoading ? '-' : formatPrice(totalInCents)}
            </BackButton>
          }
          </div>
          {!isScanned ?
            (<div className="scanned-screen__app-method">
              <div className="scanned-screen__header-wrapper">
                {TEAM_LOGO_EXISTS && <img className="scanned-screen__logo" src="/teamLogo.png" alt="team-logo" />}
                <div className="scanned-screen__header">PAY WITH APP</div>
              </div>
              <div className="scanned-screen__sub-header">{appPaymentSubHeader}</div>
              {isDoneLoading && qrCodeData ?
              <div className="scanned-screen__qr-code-gradient">
                <div className="scanned-screen__qr-code">
                  <QRCode data={qrCodeData}/>
                </div> 
              </div> : <div style={{height: '33vh'}}><ConnectingIndicator isKiosk={true} position={'initial'} /></div>
              }
            </div>
          ) : 
            (<div>
              <div className="scanned-screen__scanned-header-wrapper">
                <div className="scanned-screen__scanned-header">Complete This Order On Your Phone</div>
              </div>
              <div className="scanned-screen__scanned-logo">
                {TEAM_LOGO_EXISTS && <img className="scanned-screen__logo" src="/teamLogo.png" alt="team-logo" width="339" height="339"/>}
              </div>
            </div>)
          }
        </div>}
        {!isScanned && <div className="scanned-screen__pay-with-card-container">
          <button className="scanned-screen__pay-with-card-button" onClick={() => payWithCard()}>No Thanks, Pay With Card</button>
        </div>
        }
      </>
    ) : <ConnectingIndicator isKiosk={true}/>
}

ScannedScreen.propTypes = {
  saveRichCheckoutOrder: PropTypes.func,
  orderDidComplete: PropTypes.func,
  placeFreeOrder: PropTypes.func,
  total: PropTypes.number
}

const mapStateToProps = (state, ownProps) => {
  const cart = getCartItems(state)
  const total = getTotal(state)
  const getMenu = makeGetCurrentMenu()
  const menu = getMenu(state)
  const tipSuggestions = menu?.tipSuggestions
  
  return {
    deviceId: get(state, 'config.uuid', getDeviceId()),
    getNextOrderNumber: nextPosOrderNumber,
    cart,
    appPaymentSubHeader: RICH_CHECKOUT_TEXT_PROMPT,
    orderNumber: getStoredRickCheckoutOrderNumber(state),
    total,
    tipSuggestions
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    saveRichCheckoutOrder: (payload) => {
      dispatch(saveRichCheckoutOrderRequested({...payload, isKiosk: true}))
    },
    orderDidComplete: (uuid) => {
      props.history.push(`/kiosk/order/summary/${uuid}`)
    },
    placeFreeOrder: ({ orderUuid }) => {
      dispatch(closeNoOpTender({ orderUuid }))
      props.history.push(`/kiosk/order/summary/${orderUuid}`)
    },
    setKioskPayment: (paymentType) => {
      dispatch(setKioskPayment(paymentType))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScannedScreen))
