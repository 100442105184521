import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './Navbar.module.scss'

import {ReactComponent as HamburgerIcon} from '../assets/icons/hamburger.svg'
import { ReactComponent as ConfirmVersionUpdateIcon } from '../assets/icons/yellow-exclamation.svg'

import NavMenu from './NavMenu'
import ConfirmModalV2 from './ConfirmModalV2'

import NetworkIndicator from '../containers/NetworkIndicator'
import RevenueCenterTitle from '../containers/RevenueCenterTitle'

import { refreshCache, morningUpdate } from '../utils/newAppVersionAvailable'

const Navbar = ({ updateAvailable, setAutomaticUpdate, automaticallyUpdate, safIsUploading,
    setSafIsUploading, className }) => {
  const [menuActive, setMenuActive] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [hamburgerClickedWhileSyncing, setHamburgerClickedWhileSyncing] = useState(false)
  const classNames = cn(styles.navbar, className)

  // const mediaQuery = window.matchMedia("(min-width: 450px)")
  const toggleMenuActive = () => setMenuActive(!menuActive)

  const stopSyncMessage = "These functions are not supported\n while a sync is in progress. Stopping\n the sync will leave some orders\n unsynced."

  let confirmVersionUpdateComponent
  if (showConfirm) {
    confirmVersionUpdateComponent = (
      <ConfirmModalV2
        headerText="Version Update Available"
        subtext={"Syncing may interrupt orders in\n progress"}
        buttonOneText="Try Later"
        buttonTwoText="Update Now"
        onButtonOneClick={() => {
          setShowConfirm(false)
          setAutomaticUpdate()
          morningUpdate()
        }}
        onButtonTwoClick={() => {
          refreshCache()
          setAutomaticUpdate()
        }}
      />
    )
  }

  const onHamburgerClick = () => {
    if (safIsUploading) {
      setHamburgerClickedWhileSyncing(true)
      return
    }

    toggleMenuActive()
  }

  const onStopSyncClick = () => {
    setSafIsUploading(false)
    setHamburgerClickedWhileSyncing(false)
  }


  return (
    <div className={classNames}>
      {menuActive && <NavMenu onToggle={toggleMenuActive} />}

      <div className={styles.contentLeft} >
        <button className={styles.iconContainer} onClick={onHamburgerClick}>
          <HamburgerIcon className={styles.hamburgerIcon} />
        </button>
        {hamburgerClickedWhileSyncing && (
          <ConfirmModalV2
          headerText={''}
          subtext={stopSyncMessage}
          buttonOneText={'Stop Sync'}
          buttonTwoText={'Continue Sync'}
          onButtonOneClick={onStopSyncClick}
          onButtonTwoClick={() => setHamburgerClickedWhileSyncing(false)}
          />
        )}
        { updateAvailable && automaticallyUpdate && <div className={styles.versionUpdateIcon}>
          <ConfirmVersionUpdateIcon onClick={() => setShowConfirm(true)}/>
        </div> }
      </div>
      <div className={styles.revenueCenterName}>
        <RevenueCenterTitle className={styles.title} />
      </div>

      <div className={styles.contentRight}>
        <NetworkIndicator className={styles.indicator} />
      </div>
      {confirmVersionUpdateComponent}
    </div>
  )
}

Navbar.propTypes = {
  toggleMenu: PropTypes.func,
  setSafIsUploading: PropTypes.func,
}

export default Navbar
