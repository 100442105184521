// A module for handling carousel images.
import { CLOUDINARY_NAME, CLOUDINARY_PRESET_NAME } from '../../constants';

export const imageFileName = (imageNumber) => {
  return 'public/carousel/carousel_' + imageNumber + '.png'
}

export const CLOUDINARY_RESOURCES_URL = `https://res.cloudinary.com/${CLOUDINARY_NAME}`

export const getImagePath = (menuUuid, index, timestamp) =>
  fetch(`${CLOUDINARY_RESOURCES_URL}/image/upload/v${timestamp}/${getCloudinaryPublicId(menuUuid, index)}`).then(response => {
    if (response.status === 200) {
      return response.url
    } else {
      return null
    }
  })

export const getCloudinaryPublicId = (menuUuid, index) => `${CLOUDINARY_PRESET_NAME}/${menuUuid}_${index}`
