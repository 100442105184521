import React, { useEffect } from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, isEmpty } from 'lodash';

import { getOrderInProgress, makeGetOrder } from '../selectors/order'
import ReceiptCard from '../components/receipt/ReceiptCard'
import QRCodeReceipt from '../containers/receipt/QRCodeReceipt'
import { clearCart } from '../actions/cart'
import { clearPromotionsOnOrder } from '../actions/promotion'
import { ReactComponent as CloseIcon } from '../assets/closeMobile.svg'
import { isEmpty as vnIsEmpty } from '../VNUtil/VNUtils'

import styles from './Receipt.module.scss'
import { getIsKiosk } from '../selectors/menus';
import { getActiveRevenueCenter } from '../selectors/revenueCenter';
import { makeGetServiceChargeLegalText } from '../selectors/venue';

import { getDeviceMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import { bridge_setCFDScreen } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../VNCustomerFacingDisplay/Reducer'
import { getCurrentPaymentFlow } from '../VNCustomerFacingDisplay/Selectors'
import { CFD_POS_PAYMENT_FLOWS } from '../VNCustomerFacingDisplay/Enums'
import { setCustomerPayingWithCard, setCustomerViewingReceiptDialog } from '../VNDialogs/ActionCreators'
import { customHistory } from '../stores/index'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core';
import { setBalanceToBePaid } from '../VNCustomerFacingDisplay/ActionCreators';
import VNConcessionsNavBar from "../components/VNConcessionsNavBar"
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import { handlePpiReportOrder } from '../VNPPI/Utils';
import { getPpiConfigurations, getPpiOrderDetails } from '../VNPPI/Selectors';
import { getActiveAttendantId } from '../selectors/attendant';
import { isTabbed } from '../utils/orderStates';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#000000'
  },
}))

const Receipt = ({ className, order, orderNumber, isRichCheckoutOrder, serviceChargeLegal, onDone, hasBalanceDueInCents, isKiosk, ppiConfigurations, ppiOrderDetails, activeAttendantId, activeRevenueCenter }) => {

  const dispatch = useDispatch()

  // SELECTORS
  // what mode are we in
  const cfdMode = useSelector(state => getDeviceMode(state))
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))

  // apply receipt styles for cfd
  const cfdStyles = useStyles()

  // navigate to the receipt screen if we are in CFD mode
  useEffect(() => {
    if (cfdMode !== MODES.POS) return
    if (parseInt(order.balanceDueInCents) > 0) return

    if (isTabbed(order)) {
      dispatch(setCustomerViewingReceiptDialog(true))
      bridge_setCFDScreen(
        CFD_SCREEN.RECEIPT,
        { cartTotals: { uuid: order?.uuid },
        transaction: { amount: order?.amountInCents ? (order?.amountInCents / 100) : 0 }}
      )
    }

    if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
      dispatch(setCustomerPayingWithCard({open: false}))
    }

    if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.UNSET || currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
      dispatch(setCustomerViewingReceiptDialog(true))
      bridge_setCFDScreen(
        CFD_SCREEN.RECEIPT,
        { cartTotals: { uuid: order?.uuid },
        transaction: { amount: order?.amountInCents ? (order?.amountInCents / 100) : 0 }}
      )
    }
  }, [order])

  useEffect(() => {
    if (cfdMode !== MODES.POS) return
    const balanceDueInCents = parseInt(order?.balanceDueInCents || 0)
    if (isTabbed(order) && balanceDueInCents <= 0) return

    if (balanceDueInCents > 0) {
      let totalAmountPaid = 0
      order.payments.forEach(payment => {
        totalAmountPaid += payment.amountInCents
      })

      const balanceToBePaid = {
        amountPaid: totalAmountPaid,
        balanceDue: balanceDueInCents ?? 0
      }

      dispatch(setBalanceToBePaid(balanceToBePaid))
      bridge_setCFDScreen(CFD_SCREEN.BALANCE_DUE, { balanceToBePaid: balanceToBePaid})

      customHistory.push({
        pathname: '/pos/balance-due'
      })
    }
  }, [])

  const classNames = cn(styles.receipt, className)
  const isMobile = useGetIsMobileScreen()
  const heightSetting = { height: isKiosk ? "auto" : "100%" }

  const richCheckoutDisclaimer = isRichCheckoutOrder ? (
    <div className={styles.disclaimer}>
      Discounts and gratuity applied from mobile purchases are not included.
      Please check your mobile receipt for final total.
    </div>
  ) : null

  const serviceChargeDisclaimer =
    !isKiosk && serviceChargeLegal ? (
      <div className={styles.disclaimer}>{serviceChargeLegal}</div>
    ) : null

  const display = () => {
    if (cfdMode === MODES.POS) {
      return <div className={cfdStyles.root}></div>
    } else {
      return (
        <div className={classNames}>
          <VNConcessionsNavBar
            showIcon={isMobile ? true : false}
            useHamburgerIcon={false}
            leftAccessory="Receipt"
            rightAccessory={
              <CloseIcon onClick={() => onDone(hasBalanceDueInCents, order, ppiConfigurations, ppiOrderDetails, { venueId: process.env.REACT_APP_VENUE_UUID, userId: activeAttendantId, vendorName: activeRevenueCenter.name })} />
            }
          />
          <div className={styles.contentWindow}>
            <div className={styles.receiptContent}>
              <div className={styles.orderNumber}>{orderNumber}</div>
              <ReceiptCard
                className={styles.receiptCard}
                isRichCheckoutOrder={isRichCheckoutOrder}
                serviceChargeEnabled={serviceChargeLegal}
                isMobile={isMobile}
              />
              {!isMobile && (
                <div className={styles.disclaimer}>
                  {richCheckoutDisclaimer}
                  {serviceChargeDisclaimer}
                </div>
              )}
            </div>
            {!isMobile && <QRCodeReceipt className={styles.buttons} />}
            {isMobile && <QRCodeReceipt className={cn([styles.buttons, hasBalanceDueInCents && styles.splitReceiptButtons])} />}
          </div>
        </div>
      )
    }
  }

  return display()
}

const mapStateToProps = (state, ownProps) => {
  const orderUuid = get(ownProps, "match.params.orderUuid", undefined)
  const paymentId = ownProps?.match?.params?.paymentId
  const getOrder = makeGetOrder()
  const isKiosk = getIsKiosk(state)
  const orderInProgress = getOrderInProgress(state)

  let order = {}
  if (!isEmpty(orderInProgress)) {
    order = orderInProgress
  } else if (orderUuid) {
    order = getOrder(state, orderUuid)
  } else {
    order = getOrder(state, state.order.currentOrderId)
  }
    
  const hasBalanceDueInCents = paymentId ? order.balanceDueInCents !== 0 : false

  const orderNumber = order?.orderNumber

  const isRichCheckoutOrder = order?.isRichCheckout
  const serviceChargeLegal =
    order?.serviceFee?.total > 0 && makeGetServiceChargeLegalText()(state)

  // PPI
  const ppiConfigurations = getPpiConfigurations(state)
  const ppiOrderDetails = getPpiOrderDetails(state, order?.uuid)
  const activeAttendantId = getActiveAttendantId(state)
  const activeRevenueCenter = getActiveRevenueCenter(state)

  return {
    order,
    orderNumber,
    isRichCheckoutOrder,
    serviceChargeLegal,
    hasBalanceDueInCents,
    isKiosk,
    ppiConfigurations,
    ppiOrderDetails,
    activeAttendantId,
    activeRevenueCenter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const push = get(ownProps, "history.push", () => {})

  return {
    onDone: (hasBalanceDueInCents, order, ppiConfigurations, ppiOrderDetails, posInfo, activeRevenueCenter) => {
      if (hasBalanceDueInCents) {
        push({
          pathname: "/split-tender",
          state: { order },
        })
      } else {

        if (!vnIsEmpty(order) && !vnIsEmpty(ppiOrderDetails) && !vnIsEmpty(ppiConfigurations) && !vnIsEmpty(activeRevenueCenter)) {
          handlePpiReportOrder(order, ppiConfigurations, ppiOrderDetails, posInfo, activeRevenueCenter.customerAttributes?.ppiExtras)
        }
        dispatch(clearCart())
        dispatch(clearPromotionsOnOrder())
        push("/concession-order")
      }
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Receipt))
