import React from 'react'
import { createRoot } from "react-dom/client"
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { store, persistor } from './stores'
import configureServices from './utils/configureServices'

import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import { VNProvider } from 'vn-react-components/VNProvider'

configureServices()

const Component = (
  <VNProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </VNProvider>
)

const root = createRoot(document.getElementById('root'))
root.render(Component)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
