import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { get, isEmpty } from 'lodash'

import { menusSucceeded, menusItemsSucceeded, menusItemsFailed, MENUS_REQUESTED, MENUS_ITEMS_REQUESTED, menusFailed } from '../actions/menus'
import { getSelectedMenusIds } from '../selectors/config'
import { getSelectedMenus, getCurrentMenu, getIsKiosk, getMenus, getMenusNeedReload, getStands } from '../selectors/menus'

import Stadium from '@ordernext/networking-stadium/stadium'
import Remote from '../remote'

import { getPaymentType } from '../selectors/peripheral'

export function* fetchMenus(params) {
  const ignoreThrottle = params?.payload?.ignoreThrottle
  const allowRequest = yield select(getMenusNeedReload)
  const isKiosk = yield select(state => getIsKiosk(state));

  if (!allowRequest && !ignoreThrottle && !isKiosk) {
    yield put(menusFailed())
    return
  }
  let payload

  const paymentType = yield select((state) => getPaymentType(state))
  if (isKiosk) {
    const menus = yield select(getSelectedMenus)
    if (isEmpty(menus)) {
      yield put(menusFailed())
      return
    }
    const standUuids = [...new Set(menus.map(menu => menu.standUuid))]
    payload = { standUuids, paymentType }
  } else {
    const organizationName = yield select((state) => state?.config?.organizationName ?? '')
    const menu = yield select(getCurrentMenu)
    if (isEmpty(menu)) {
      yield put(menusFailed())
      return 
    }
    const standUuids = [ menu?.standUuid ]
    payload = {
      organizationName,
      standUuids,
      paymentType
    }
  }

  try {
    const result = yield call(Remote.fetchMenus, payload)
    if (isEmpty(result)) {
      yield put(menusFailed())
      return 
    }
    yield put(menusSucceeded(result))
  } catch (error) {
    const previouslyFetchedMenus = {
      data: {
        menus: yield select(getMenus),
        stands: yield select(getStands)
      }
    }

    yield put(menusSucceeded(previouslyFetchedMenus))
  }
}

export function* getMenusItems() {
  try {
    const orgName = yield select((state) => get(state, 'config.organizationName', ''))
    const menuUuids = yield select(getSelectedMenusIds)
    const result = yield all(menuUuids.map(menuUuid => call(Stadium.getPosMenu, menuUuid, orgName)))

    yield put(menusItemsSucceeded(result))

  } catch (error) {
    menusItemsFailed()
    console.log(error)
  }
}

export function* watchGetMenus() {
  yield takeLatest(MENUS_REQUESTED, fetchMenus)
}

export function* watchGetMenusItems() {
  yield takeLatest(MENUS_ITEMS_REQUESTED, getMenusItems)
}
