import { get } from 'lodash'

import { loadConfigSucceeded } from '@ordernext/networking-stadium/stadium/actions'

import {
    FETCH_PRINTERS_SUCCEEDED,
    FETCH_PRINTERS_FAILED,
    PRINTER_DISCOVERED,
    PRINTER_CONNECTION_CHANGED,
    CURRENT_NETWORK_PRINTER_SELECTED,
} from '../actions/printer'

import { CLEANUP_STATE } from '../actions/shared'

import PrinterModel from '../models/Printer'

  const initialState = {
    byId: {},
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case CLEANUP_STATE: {
        const localPrinter = state.byId?.local ?? {}
        const networkPrinterUuid = state.networkPrinterUuid

        return {
          ...initialState,
          byId: {
            local: localPrinter,
          },
          networkPrinterUuid,
        }
      }

      case FETCH_PRINTERS_SUCCEEDED: {
        let localPrinter = {}

        if (state.byId?.local) {
          localPrinter = { local: state.byId.local }
        }

        return {
          ...state,
          byId: {
            ...(action?.payload?.byId ?? {}),
            ...localPrinter,
          }
        }
      }

      case FETCH_PRINTERS_FAILED:
        return state

      case PRINTER_DISCOVERED: {
        const printer = PrinterModel({
          uuid: 'local',
          name: action.payload.printer ?? 'Local Printer',
          ...(state.byId.local ?? {})
        })

        const newState = {
          ...state,
          byId: {
            ...state.byId,
            local: printer,
          }
        }

        console.log('%c discoveredPrinters:: local DB', 'font-size: 16px; font-weight: bold;')
        console.log(newState.byId.local)

        return newState
      }

      case PRINTER_CONNECTION_CHANGED: {
        const newState = {
          ...state,
          byId: {
            ...state.byId,
            local: {
              ...state.byId.local,
              state: action.payload.state,
            }
          }
        }

        console.log('%c printerConnectionState:: local DB', 'font-size: 16px; font-weight: bold;')
        console.log(newState.byId.local)

        return newState
      }

      case CURRENT_NETWORK_PRINTER_SELECTED: {
        const newState = {
          ...state,
          networkPrinterUuid: action.payload.printerUuid,
        }

        console.log('%c currentNetworkPrinterUuid:: local DB', 'font-size: 16px; font-weight: bold;')
        console.log(newState)

        return newState
      }

      case loadConfigSucceeded.type: {
        let configurations = get(action, 'payload.data.appDeviceConfigurations[0]')

        /**
         * TODO:
         * We should ask about getting this changed.
         * if there is only one configuration, Stadium returns a singular key name.
         */
        if (!configurations) {
          configurations = get(action, 'payload.data.appDeviceConfiguration', {})
        }

        if (!configurations?.configuration) return state

        const newState = {
          ...state,
          networkPrinterUuid: configurations?.configuration.receiptPrinterUuid,
        }

        console.log('%c currentNetworkPrinterUuid:: local DB', 'font-size: 16px; font-weight: bold;')
        console.log(newState)

        return newState
      }

      default:
        return state
    }
  }