import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import { get } from 'lodash'

import GratuityHeader from '../components/GratuityHeader'
import styles from './CardReader.module.scss'

import { ReactComponent as CreditCard } from '../assets/icons/NewCreditCard.svg'
import { ReactComponent as Discover } from '../assets/icons/discoverCard.svg'
import { ReactComponent as Amex } from '../assets/icons/amexCard.svg'
import { ReactComponent as Visa } from '../assets/icons/visaCard.svg'
import { ReactComponent as Master } from '../assets/icons/masterCardSm.svg'

import { Oval } from  'react-loader-spinner'
import ConnectingIndicator from '../components/ConnectingIndicator'

import { getTotal } from '../selectors/cart'
import { getBridgePaymentMethod, getPaymentType,
  getSafIsUploading
} from '../selectors/peripheral'

import { INTERNAL_ORDER_STATES, isTabbed } from '../utils/orderStates'
import { getNetworkAvailableValue } from '../utils/networkConnected'
import { getTipsEnabled } from '../selectors/menus'
import { cardReaderApproved } from '../utils/orderUtils'
import { invoiceNumber } from '../utils/orderNumbers'
import PeripheralBridge from '../utils/peripheralBridge'
import { isOfflineApproval } from '../utils/offlineReplayStatuses'
import { fakeCreditCardResponse, showFakeCreditCard } from '../utils/fakeCreditCardResponse'
import { nextPosOrderNumber } from '../utils/orderNumbers'
import { getDeviceSerial } from '../utils/formatters'
import createDataDogLog from '../utils/datadog'
import { ToastManager } from '../utils/toastManager'

import { useRemoteOrderTotalWithCallbacks } from '../hooks/useRemoteOrderTotal'

import {
  addPaymentToOrderInProgress,
  closeNoOpTender,
  startOfflineCardOrder,
  startOnlineCardOrder,
} from '../actions/order'
import { resetSafFullResponse, setBridgePaymentMethod } from '../actions/peripheral'
import VNConcessionsNavBar from '../components/VNConcessionsNavBar'
import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import ConfirmModalV2 from '../components/ConfirmModalV2'
import { getRemoteOrderTotal } from '../selectors/orderTotalRemote'
import { getOrderInProgress } from '../selectors/order'
import { bridgePaymentMethods } from '../reducers/peripheral'
import { PAYMENT_TYPES } from '../utils/paymentTypes'

const Ready = ({ onFakeCreditCardFailSwipe, onFakeCreditCardSwipe }) => {
  let modalText = "Pay with Card"
  let subText = "All major credit cards accepted"

  return (
    <>
      <CreditCard className={styles.creditCard} />
      <p className={styles.cardReadyText}>{modalText}</p>
      <p className={styles.subText}>{subText}</p>
      <div className={styles.cardsContainer} >
        <Master className={styles.cards} onClick={showFakeCreditCard() ? onFakeCreditCardSwipe : null} />
        <Visa className={styles.cards} />
        <Discover className={styles.cards} />
        <Amex className={styles.cards} onClick={showFakeCreditCard() ? onFakeCreditCardFailSwipe : null} />
      </div>
    </>
  )
}

const Processing = (isMobile) => {
  let spinnerSize = isMobile ? 148 : 198

  return (
    <>
      <div className={styles.loader}>
        <Oval
          height={spinnerSize}
          width={spinnerSize}
          color='#1463F5'
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor='#000000'
          strokeWidth={2}
          strokeWidthSecondary={3}
        />
      </div>
    </>
  )
}

const ErrorModal = ({ message, onCancel, onRetry }) => {
  return ( <>
     <ConfirmModalV2
       headerText={'Card Reader Status'}
       subtext={message}
       buttonOneText={'Cancel'}
       buttonTwoText={'Retry'}
       onButtonOneClick={onCancel}
       onButtonTwoClick={onRetry}
     />
</>)
}

const Busy = ({ onCancel }) => {
  return (
    <>
      <p className={styles.paymentFailedSubtext}>{"Card Reader Is Busy Syncing Offline Orders"}</p>
      <button
        onClick={onCancel}
        className={styles.cancelButton}
        >cancel</button>
    </>
  )
}

const CardReader = ({ bridgeMethod, onStartAuth, onSuccess, onCancelAuth, onFailureAuth,
    onOfflineSuccess, total, placeFreeOrder, signature, selectedTipAmount, deviceType,
    safIsUploading, addPaymentToOrder, clearSafResults, paymentId, orderUuid, tenderAmountInCents,
    offlineTotal, history, className, goPrevScreen, redirectPath, authTabs, shouldShowTip }) => {
  const classNames = cn(styles.cardReader, className)
  const generateUuid = require('uuid/v4')
  const dispatch = useDispatch()

  const [uuid] = useState(orderUuid ?? generateUuid())
  const [status, setStatus] = useState('ready')
  const [errorMessage, setErrorMessage] = useState()
  const orderInProgress = useSelector(getOrderInProgress)
  const [orderNumber] = useState(orderInProgress?.orderNumber ?? nextPosOrderNumber())

  const [transactionApproved, setTransactionApproved] = useState(false)
  const [transactionError, setTransactionError] = useState(false)
  const [cardReaderInitialized, setCardReaderInitialized] = useState(false)
  const [transactionCancelled, setTransactionCancelled] = useState(false)
  const transactionCancelledRef = useRef(transactionCancelled)
  const isMobile = useGetIsMobileScreen()
  const isTabOrder = isTabbed(orderInProgress)

  const tipAmountInCents = selectedTipAmount || 0

  // If there is a redirect path, we handle it as a tabbed order
  const redirect = (data, orderNumber) => {
    if (redirectPath) {
      if (authTabs) {
        history.push(redirectPath, { tabNameRequired: true, cardReaderData: data, paymentId, paymentType: PAYMENT_TYPES.CREDIT_CARD, orderNumber })
        return
      }
      history.push(redirectPath, { tabNameRequired: true })
      return
    }
    history.push(`receipt/${uuid}`)
  }

  // The single entry point for bridge registration on mount.
  const registerBridgeHandlers = (orderTotal) => {
    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) {
      createDataDogLog('error', 'Card Reader - No Bridge Instance', { uuid })
      return
    }

    createDataDogLog('info', 'Card Reader - Registering Bridge Handlers', { uuid, totalInCents: orderTotal })
    bridgeInstance.registerHandler('handleResult', function(data, callback) {
      // If handle result returns null or undefined, set error and abort transaction
      if (!data) {
        setStatus('error')
        setErrorMessage('Bad response.')
        createDataDogLog('error', 'Card Reader - Bad Response', { data, uuid, paymentId })
        return
      }

      // if true, abort due to transaction cancelled
      if (transactionCancelledRef.current) return

      setCancelEnabled(true)
      setCardReaderInitialized(false)

      const dataWithUuid = { ...data, orderNumber, uuid }
      const orderDetails = [ dataWithUuid, signature, tipAmountInCents, orderNumber ]

      if (cardReaderApproved(data)) {
        setTransactionApproved(true)

        if (paymentId) {
          addPaymentToOrder(dataWithUuid, signature, tipAmountInCents, tenderAmountInCents, paymentId, orderNumber, isOfflineApproval({cardReaderData: data}))
        } else {
          if (authTabs) {
            redirect(dataWithUuid, signature, tipAmountInCents, tenderAmountInCents, tenderAmountInCents, orderNumber, isTabOrder)
          } else {
            isOfflineApproval({cardReaderData: data}) ? onOfflineSuccess(...orderDetails) : onSuccess(...orderDetails)
            redirect()
          }
        }
      } else {
        const message = [data?.reasonCode ?? '', data?.reasonText ?? '', data?.message ?? ''].join(', ')

        if (!authTabs && !isTabOrder) {
          onFailureAuth(...orderDetails)
        }

        setStatus('error')
        setErrorMessage(message)

        createDataDogLog('error', 'Card Reader - Card Reader Error', { data, uuid, paymentId, invoiceNumber: data?.invoiceNumber })
      }

      var response = { 'message': data }
      callback(response)
    })

    bridgeInstance.registerHandler('errorTransaction', function(data, callback) {

      // if true, abort due to transaction cancelled
      if (transactionCancelledRef.current) return

      createDataDogLog('error', 'Card Reader - Error Transaction Event', { data, uuid })
      setCardReaderInitialized(false)

      const dataWithUuid = { ...data, uuid }
      const orderDetails = [ dataWithUuid, signature, tipAmountInCents, orderNumber ]
      const response = { 'message': dataWithUuid }

      if (!authTabs && !isTabOrder) {
        onFailureAuth(...orderDetails)
      }

      setCancelEnabled(true)
      setStatus('error')
      setTransactionError(true)

      callback(response)
    })

    bridgeInstance.registerHandler('onTransactionRequestStarted', function(data, callback) {
      createDataDogLog('info', 'Card Reader - Transaction Request Started', { data, uuid })
    })
  }

  // Initiate the card reader with a transaction for a specific amount.
  const initiateCardReader = (orderTotal) => {
    // Don't initiate the card reader if the order is free.
    if (orderTotal === '0.00') return

    // Synchronously register the bridge handlers when the card reader is known to be being
    // initialized. This prevents erroneous bridge callbacks from being made by the APK before the
    // card reader is actually ready. (e.g. a cancellation attempt before a transaction has
    // actually started)
    registerBridgeHandlers(orderTotal)

    const bridgeInstance = PeripheralBridge.getBridge()
    if (!bridgeInstance) return

    if (!orderTotal) {
      ToastManager.error('Reader initialization failed : Invalid total. Please go back and try your transaction again.')
      return
    }

    if (deviceType === 'pax' && !safIsUploading) {
      setCancelEnabled(false)
    }

    const deviceSerial = getDeviceSerial()
    setCardReaderInitialized(true)

    bridgeInstance.call(bridgeMethod, {
      'uuid': uuid, orderNumber,
      'hRef': invoiceNumber(orderNumber),
      'amount': orderTotal,
      'tip': `$${(tipAmountInCents / 100).toFixed(2)}`,
      'tipEnabled': shouldShowTip,
      'deviceSerial': deviceSerial,
    })

    createDataDogLog('info', 'Card Reader - Execute Sale Request Made', {
      orderUuid: uuid,
      orderNumber,
      invoiceNumber: invoiceNumber(orderNumber),
      transactionAmount: orderTotal
    })
  }

  // TODO (JEFF) Now that totaling happens from the cart, we should no longer need totaling logic in this component
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, loading, success, failed }] = useRemoteOrderTotalWithCallbacks(initiateCardReader, (offlineTotal || total), tipAmountInCents)
  /**
   * @link (T35923)[https://airtable.com/app2mE1mTl0c4GAir/tbl4lm32cBJ1JL2IG/viw0vwMeV6axrLNsj/recj6zXAlTS4v4Xyj?blocks=hide]
   * @deprecated unused and may cause additional re-render.  Marking for deletion.
   */
  // eslint-disable-next-line
  const [cancelEnabled, setCancelEnabled] = useState(true)

  const showProcessing = isMobile && deviceType === 'pax'
  const totalInCents = paymentId ? tenderAmountInCents : (remoteOrderTotal?.totalAmountInCents ?? (offlineTotal || total))
  const totalDisplay = `$${(totalInCents / 100).toFixed(2)}`
  const tip = `$${(tipAmountInCents / 100).toFixed(2)}`
  const grandTotalInCents = totalInCents + tipAmountInCents
  const grandTotal = `$${(grandTotalInCents / 100).toFixed(2)}`
  const formattedTotal = (grandTotalInCents / 100).toFixed(2)
  const totalInCentsWithTips = totalInCents + tipAmountInCents
  const noPaymentRequired = totalInCentsWithTips === 0

  let primaryText = grandTotal ?? '-'
  let secondaryText = `${totalDisplay} + ${tip} tip`

  const performPreauthSubmission = () => {
    // do not attempt to create a new order if the tabbed order already exists
    if (orderInProgress?.wasCreatedInStadium) return
    // If the order is a tabbed order, we don't need to create a new order.
    if (authTabs) return

    const dataWithUuid = { orderNumber, uuid }
    const orderDetails = [ dataWithUuid, signature, tipAmountInCents, orderNumber ]

    onStartAuth(...orderDetails)
  }

  const onCancel = (shouldAttemptCancelation = true, reason) => {
    setTransactionCancelled(true)

    createDataDogLog('info', 'Card Reader - Cancel', {
      uuid,
      orderNumber,
      invoiceNumber: invoiceNumber(orderNumber),
      amountInCents: totalInCentsWithTips
    })

    const bridgeInstance = PeripheralBridge.getBridge()

    if (!bridgeInstance || transactionError || !shouldAttemptCancelation) {
      setTransactionError(false)
    } else {
      bridgeInstance.call('cancelTransactionAttempt')
    }

    const dataWithUuid = { orderNumber, uuid }
    const orderDetails = [ dataWithUuid, signature, tipAmountInCents, orderNumber, reason ]

    if (!authTabs && !isTabOrder) {
      onCancelAuth(...orderDetails)
    }

    goPrevScreen()
  }

  const onGoBack = () => {
    onCancel(false, 'User cancelled order by selecting Go Back')
  }

  const onFakeCreditCardSwipe = () => {
    const networkConnected = getNetworkAvailableValue()

    if (paymentId) {
      addPaymentToOrder(fakeCreditCardResponse(uuid, formattedTotal), signature, tipAmountInCents, tenderAmountInCents, paymentId, orderNumber, !networkConnected)
    } else if (!networkConnected) {
      if (authTabs) {
        redirect(fakeCreditCardResponse(uuid, formattedTotal, 'auth'), signature, tipAmountInCents, tenderAmountInCents, orderNumber)
      } else {
        onOfflineSuccess(fakeCreditCardResponse(uuid, formattedTotal), signature, tipAmountInCents, orderNumber)
        redirect()
      }
    } else {
      if (authTabs) {
        redirect(fakeCreditCardResponse(uuid, formattedTotal, 'auth'), signature, tipAmountInCents, tenderAmountInCents, orderNumber)
      } else {
        onSuccess(fakeCreditCardResponse(uuid, formattedTotal), signature, tipAmountInCents, orderNumber)
        redirect()
      }
    }
  }

  const onFakeCreditCardFailSwipe = () => {
    if (!authTabs && !isTabOrder) {
      onFailureAuth(fakeCreditCardResponse(uuid, formattedTotal), signature, tipAmountInCents, orderNumber)
    }
    setStatus('error')
    setErrorMessage('You faked a CC failure.')
  }

  const onRetry = () => {
    createDataDogLog('info', 'Card Reader - Retry', { uuid, orderNumber, invoiceNumber: invoiceNumber(orderNumber), amountInCents: totalInCentsWithTips })

    const bridgeInstance = PeripheralBridge.getBridge()

    if (!bridgeInstance || transactionError) {
      setTransactionError(false)
      setStatus('ready')
      initiateCardReader(formattedTotal)
      return
    }

    setStatus('ready')
    if (deviceType === 'pax') {
      setCancelEnabled(false)
    }
    setErrorMessage()
    initiateCardReader(formattedTotal)
  }

  const shutDownCardReader = () => {
    createDataDogLog('info', 'Card Reader - Shut Down', { uuid, orderNumber, invoiceNumber: invoiceNumber(orderNumber), amountInCents: totalInCentsWithTips })

    const bridgeInstance = PeripheralBridge.getBridge()

    if (bridgeInstance) bridgeInstance.call('cancelTransactionAttempt')
  }

  useEffect(() => {
    clearSafResults()
    performPreauthSubmission()
    registerBridgeHandlers()

    if (!paymentId) {
      loadRemoteOrderTotal()
    } else {
      // For split orders, the remote totals doesn't need to be waited on.
      initiateCardReader(formattedTotal)
    }

    const handleBeforeUnload = event => {
      createDataDogLog('error', 'Card Reader - Screen Reloaded Unexpectedly', {
        uuid,
        orderNumber,
        invoiceNumber: invoiceNumber(orderNumber),
        amountInCents: totalInCentsWithTips,
        bridgeActive: cardReaderInitialized,
      })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeSaleRequest))
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (noPaymentRequired && !loading && (success || failed)) {
      placeFreeOrder(uuid, orderNumber)
    }
    // eslint-disable-next-line 
  }, [totalInCentsWithTips, loading, success, failed])

  useEffect(() => {
    if (transactionError) {
      setStatus('error')
    }
  }, [transactionError])

  useEffect(() => {
    transactionCancelledRef.current = transactionCancelled
  }, [transactionCancelled])

  let content = showProcessing ? <Processing isMobile={isMobile}/> : <Ready onFakeCreditCardFailSwipe={onFakeCreditCardFailSwipe} onFakeCreditCardSwipe={onFakeCreditCardSwipe} deviceType={deviceType} safIsUploading={safIsUploading}/>

  if (status === 'error') {
    content = <ErrorModal message={errorMessage} onCancel={() => onCancel(true, 'User cancelled order transaction from card reader ERROR modal')} onRetry={onRetry} deviceType={deviceType}/>
  }

  if (status === 'busy') {
    content = <Busy onCancel={() => onCancel(true, 'User cancelled order transaction from card reader BUSY modal')} />
  }

  useEffect(() => {
    return () => {
      if (!transactionApproved) {
        shutDownCardReader()
      }
    }
    // eslint-disable-next-line 
  }, [transactionApproved])

  return !noPaymentRequired ? (
    <div className={cn(classNames)}>

      <VNConcessionsNavBar
        textDisplay={isMobile && primaryText}
        onClick={onGoBack}
        cardReaderScreen={true}
        cardReaderInitialized={cardReaderInitialized}
      />
      <GratuityHeader
        primaryText={!isMobile && primaryText}
        secondaryText={secondaryText}
      />
      <div className={styles.contentContainer} >
        {content}
      </div>
    </div>
  ) : <ConnectingIndicator />

}

const mapStateToProps = (state, ownProps) => {
  const total = getTotal(state)
  const redirectPath = ownProps?.history?.location?.state?.redirectPath ?? ''
  const authTabs = ownProps?.history?.location?.state?.authTabs ?? ''
  const offlineTotal = ownProps?.history?.location?.state?.offlineTotal
  const paymentId = ownProps?.history?.location?.state?.paymentId
  const tenderAmountInCents = ownProps?.history?.location?.state?.tenderAmountInCents ?? 0
  const selectedTipAmount = ownProps?.history?.location?.state?.selectedTipAmount
  const signature = ownProps?.history?.location?.state?.signature
  const remoteOrderTotal = getRemoteOrderTotal(state)
  const orderUuid = remoteOrderTotal?.uuid

  const shouldShowTip = getTipsEnabled(state)
  const bridgeMethod = getBridgePaymentMethod(state)
  const deviceType = getPaymentType(state)
  const safIsUploading = getSafIsUploading(state)

  return {
    offlineTotal,
    paymentId,
    orderUuid,
    tenderAmountInCents,
    selectedTipAmount,
    shouldShowTip,
    bridgeMethod,
    deviceType,
    safIsUploading,
    signature,
    total,
    redirectPath,
    authTabs
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const goPrevScreen = get(ownProps, 'history.goBack', () => {})

  return {
    goPrevScreen: () => (goPrevScreen()),
    onClose: () => (ownProps?.history?.location?.state?.onClose),
    onStartAuth: (cardReaderData, signature, tipAmountInCents, orderNumber) => {
      dispatch(startOnlineCardOrder({ cardReaderData, signature, tipAmountInCents, orderNumber, orderState: INTERNAL_ORDER_STATES.PREAUTH }))
    },
    onCancelAuth: (cardReaderData, signature, tipAmountInCents, orderNumber, reason) => {
      dispatch(startOnlineCardOrder({ cardReaderData, signature, tipAmountInCents, orderNumber, orderState: INTERNAL_ORDER_STATES.CANCELLED, reason }))
    },
    onFailureAuth: (cardReaderData, signature, tipAmountInCents, orderNumber) => {
      dispatch(startOnlineCardOrder({ cardReaderData, signature, tipAmountInCents, orderNumber, orderState: INTERNAL_ORDER_STATES.FAILED }))
    },
    onOfflineSuccess: (cardReaderData, signature, tipAmountInCents, orderNumber) => {
      dispatch(startOfflineCardOrder({ cardReaderData, signature, tipAmountInCents, orderNumber }))
    },
    onSuccess: (cardReaderData, signature, tipAmountInCents, orderNumber) => {
      dispatch(startOnlineCardOrder({ cardReaderData, signature, tipAmountInCents, orderNumber }))
    },
    placeFreeOrder: (orderUuid, orderNumber) => {
      dispatch(closeNoOpTender({ orderUuid, orderNumber }))
    },
    addPaymentToOrder: (cardReaderData, signature, tipAmountInCents, tenderAmountInCents, paymentId, orderNumber, isApprovedOffline) => {
      dispatch(addPaymentToOrderInProgress({ cardReaderData, signature, orderNumber, tipAmountInCents, tenderAmountInCents, paymentId, paymentType: PAYMENT_TYPES.CREDIT_CARD, isApprovedOffline }))
    },
    clearSafResults: () => {
      // Clear the SAF results so that the next time the user goes to the card reader screen, the
      // results are not stale.
      dispatch(resetSafFullResponse())
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardReader))