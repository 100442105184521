import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Modal from 'react-modal'
import { get, isEmpty, isEqual } from 'lodash'
import cn from 'classnames'


import Loader from '../components/Loader'
import Button from '../components/Button'
import { toggleAppState } from '../../actions/appState'
import { clearCart } from '../../actions/cart'
import { getCarouselImages } from '../../actions/carouselImages'
import { currentMenuSelected, menusRequested, menusItemsRequested } from '../../actions/menus'
import { clearStoredOrderUuidAndOrderNumber } from '../../actions/order'
// import analytics from '../utils/analytics'
import { KEY_CODES } from "../utils/contants"
import OfflineModal from '../OfflineModal'

import ImageVersioningContext from '../context/ImageVersioningContext'
import { getItemsImages } from '../../selectors/items'
import { getSelectedMenus, getIsStandAvailable, getSelectedMenusImages, getIsMenusLoading } from '../../selectors/menus'
import { getCarouselImagesForAnimationLoop } from '../../selectors/carouselImages'
import { getIsAccessibilityRequired, getPerformSafeUpdate } from '../../selectors/appState'
import { welcomeMessage } from '../utils/messages'
import { primaryNetworkConnectedWithCallbacks } from '../../utils/networkConnected'
import { refreshCache } from '../../utils/newAppVersionAvailable'
import { getDeviceSubtype } from '../../selectors/config'
import DeviceSubtypes from '../../utils/deviceSubtypes'
import { incrementNextOrderNumber } from '../../utils/orderNumbers'

const MINUTE_IN_MILLISECONDS = 60000
const SECOND_IN_MILLISECONDS = 1000
const PRELOAD_INTERVAL = 15
const CAROUSEL_SLIDE_INTERVAL = 5

export class AttractLoop extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    toggleAppState: PropTypes.func.isRequired,
    clearCart: PropTypes.func.isRequired,
    isStandAvailable: PropTypes.bool,
    isMenusLoading: PropTypes.bool,
    menuItemsImages: PropTypes.array.isRequired,
    carouselImageVersion: PropTypes.number.isRequired,
    menusRequested: PropTypes.func.isRequired,
    menusItemsRequested: PropTypes.func.isRequired,
    currentMenuSelected: PropTypes.func.isRequired,
    selectedMenusImages: PropTypes.arrayOf(PropTypes.string),
    carouselImages: PropTypes.arrayOf(PropTypes.string),
    getCarouselImages: PropTypes.func.isRequired,
    currentMenuUuid: PropTypes.string,
    selectedMenus: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string.isRequired
    })),
    deviceSubtype: PropTypes.string,
  }

  static contextType = ImageVersioningContext

  state = {
    isModalOpen: false,
    modalMessage: '',
    isAttractLoopTapped: false,
    isOfflineModalOpen: false
  }

  refreshImageIntervalId = null
  carouselIntervalId = null
  displayedImageIndex = 0

  constructor(props) {
    super(props)
    this.animationContainerRef = React.createRef()
    this.readSectionRef = React.createRef()
    this.mainSectionRef = React.createRef()
  }

  componentDidMount = () => {
    const {
      clearCart,
      toggleAppState,
      menuItemsImages,
      selectedMenusImages,
      carouselImages,
      getCarouselImages,
      clearStoredOrderUuidAndOrderNumber,
      performSafeUpdate,
      deviceSubtype,
      history,
    } = this.props
    if (performSafeUpdate) {
      refreshCache()
    }

    if (!DeviceSubtypes.isKiosk(deviceSubtype)) {
      history.replace('/revenue-center-setup')
    }

    const imagesToPreload = [...menuItemsImages, ...selectedMenusImages]

    document.addEventListener('keydown', this.keyDown, false)

    clearCart()
    clearStoredOrderUuidAndOrderNumber()
    incrementNextOrderNumber()
    this.setCurrentlySelectedMenuAndFetchData()
    if (carouselImages.length === 0) {
      getCarouselImages()
    }

    // analytics.resetDataLayer()
    document.title = 'Ordering Kiosk'
    if (carouselImages.length > 1) {
      this.setupCarouselImages(carouselImages.length)
    }
    this.preloadingImages(imagesToPreload, Date.now())
    this.refreshImageIntervalId = setInterval(() => {
      this.preloadingImages(imagesToPreload, Date.now())
    }, PRELOAD_INTERVAL * MINUTE_IN_MILLISECONDS)

    toggleAppState({
      isAgeConfirmed: false,
      isAlcoholWarningConfirmed: false,
    })

    setTimeout(() => this.mainSectionRef?.current?.focus(), 10)

  }

  componentDidUpdate(prevProps, prevState) {
    const { carouselImages, isMenusLoading, history, selectedMenus, isStandAvailable } = this.props;
    const { isAttractLoopTapped } = this.state
    if (!isEqual(prevProps.carouselImages, carouselImages)) {
      if (carouselImages.length > 1) {
        this.setupCarouselImages(carouselImages.length)
      }
    }
    const isMenusReloaded = isMenusLoading === false
    const multipleMenusAvailable = (selectedMenus.length > 1)

    if (isMenusReloaded && isAttractLoopTapped) {
      primaryNetworkConnectedWithCallbacks(() => {
        if (isStandAvailable) {
          toggleAppState({ isActive: true })
          multipleMenusAvailable
            ? history.push('/kiosk/menu_select')
            : history.push('/kiosk/order/new')
        } else {
          this.setState({
            isModalOpen: false,
          }, () => {
            if (isEmpty(selectedMenus)) {
              this.setState({
                isModalOpen: true,
                modalMessage: 'There is no available menu.',
              })
            } else {
              this.setState({
                modalMessage: 'This location is not currently accepting orders.',
                isModalOpen: true,
              })
            }
          })
        }
      }, () => {
        this.setState({
          isModalOpen: false,
        })
      })

      this.setState({ isAttractLoopTapped: false })
    }
  }

  setCurrentlySelectedMenuAndFetchData = () => {
    const {
      selectedMenus,
      currentMenuSelected,
      currentMenuUuid,
      menusRequested,
      menusItemsRequested,
    } = this.props
    menusRequested()
    if (selectedMenus.length === 0) {
      currentMenuSelected(currentMenuUuid)
    } else if (selectedMenus.length === 1) {
      currentMenuSelected(selectedMenus[0].uuid)
    } else {
      currentMenuSelected(null)
    }
    menusItemsRequested()
  }

  setupCarouselImages = imagesCount => {
    clearInterval(this.carouselIntervalId)
    this.carouselIntervalId = setInterval(() => {
      this.displayedImageIndex = this.displayedImageIndex + 1
      if (this.displayedImageIndex === imagesCount) {
        this.toggleAnimation()
        this.animateTransition(0)
        setTimeout(() => {
          this.displayedImageIndex = 1
          this.toggleAnimation()
          this.animateTransition(this.displayedImageIndex)
        }, 150)
      } else {
        this.animateTransition(this.displayedImageIndex)
      }
    }, CAROUSEL_SLIDE_INTERVAL * SECOND_IN_MILLISECONDS)
  }

  toggleAnimation = () => {
    const container = this.animationContainerRef.current
    if (container) {
      container.classList.contains('-active')
        ? container.classList.remove('-active')
        : container.classList.add('-active')
    }
  }

  animateTransition = index =>
    this.animationContainerRef.current.setAttribute('style', `transform: translateX(-${index}00%)`)

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false)

    if (this.refreshImageIntervalId) {
      clearInterval(this.refreshImageIntervalId)
    }
    if (this.carouselIntervalId) {
      clearInterval(this.carouselIntervalId)
    }
  }

  preloadingImages = (images) => {
    const { getUrlWithVersion } = this.context

    images.map(imageUrl => (new Image().src = getUrlWithVersion(imageUrl)))
  }

  toggleModal = () =>
    this.setState(({ isModalOpen }) => ({
      isModalOpen: !isModalOpen,
    }))

  goActive = () => {
    const { menusRequested } = this.props
    const { isOfflineModalOpen } = this.state
    
    if (!isOfflineModalOpen) {
      this.setState({
        isAttractLoopTapped: true,
        isModalOpen: true,
        modalMessage: ''
      }, () => menusRequested())
    }
  }

  keyDown = event => {
    const accessibilityKeyCodes = [KEY_CODES.ENTER, KEY_CODES.UP, KEY_CODES.DOWN, KEY_CODES.LEFT, KEY_CODES.RIGHT]
    if (accessibilityKeyCodes.includes(event.keyCode))
      this.goActive()

      if (event.keyCode === KEY_CODES.JACK_IN){
        this.mainSectionRef.current.focus()
    }
  }

  render() {
    const { carouselImages } = this.props
    const { isModalOpen, modalMessage } = this.state

    return (
      <>
        <OfflineModal id="offlineModal" intervalTimer={1000} intervalTimeout={900} onRenderChange={(isOfflineModalOpen) => this.setState({ isOfflineModalOpen })}/>
        <div className="attract-loop" tabindex="0" aria-label={welcomeMessage} ref={this.mainSectionRef}>
          <Button className="attract-loop__btn" onClick={this.goActive} />
          <div className="attract-loop__center">
            <div className="attract-loop__cta">Order</div>
            <div className="attract-loop__cta -large">Now</div>
            <div className="attract-loop__cta -small">Tap screen to start</div>
          </div>
          <div
            className="attract-loop__animation -active"
            ref={this.animationContainerRef}
            style={{ transform: 'translateX(-0%)' }}
          >
            {carouselImages.map((imagePath, index) => (
              <img alt="" className="attract-loop__img" key={`image${index}`} src={imagePath} />
            ))}
          </div>
          <Modal
            className={cn('modal', {'-padding-bottom': isEmpty(modalMessage)})}
            isOpen={isModalOpen}
            onRequestClose={this.toggleModal}
            overlayClassName="overlay -higher-layer"
          >
            {
              !isEmpty(modalMessage) ? (
                <>
                  <div className="modal__copy">{modalMessage}</div>
                  <div className="modal__actions">
                    <Button className="btn -primary" onClick={this.toggleModal} title="OK" />
                  </div>
                </>
              ) : (
                <Loader container={false} />
              )
            }
          </Modal>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentMenuUuid: get(state, 'config.configuration.menuUuids[0]', undefined),
  isStandAvailable: getIsStandAvailable(state),
  isMenusLoading: getIsMenusLoading(state),
  menuItemsImages: getItemsImages(state),
  selectedMenus: getSelectedMenus(state),
  selectedMenusImages: getSelectedMenusImages(state),
  carouselImages: getCarouselImagesForAnimationLoop(state),
  isAccessibilityRequired: getIsAccessibilityRequired(state),
  performSafeUpdate: getPerformSafeUpdate(state),
  deviceSubtype: getDeviceSubtype(state)
})

const mapDispatchToProps = {
  toggleAppState,
  clearCart,
  currentMenuSelected,
  menusItemsRequested,
  menusRequested,
  getCarouselImages,
  clearStoredOrderUuidAndOrderNumber,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(AttractLoop)
