import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'

import PrintIcon from '@material-ui/icons/Print'

import styles from './StatusIndicator.module.scss'

import Clock from './Clock'

import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'

import { getIsPrintController } from '../selectors/config'

const StatusIndicator = ({ text, icon, color, available, className, ...props }) => {
  const currentStatus = available ? 'available' : 'unavailable'
  const classNames = cn(styles.statusIndicator, styles[currentStatus], className)
  const isMobileScreen = useGetIsMobileScreen()

  const isPrintController = useSelector((state) => getIsPrintController(state))

  return (
    <div {...props} className={classNames}>
      {icon}

      {!isMobileScreen && (<>
        <span className={styles.text} style={{ color: color }}>
          {text}
        </span>

        <span style={{color: color}}>
          <Clock className={styles.clock} />
        </span>

      </>)}

      {isPrintController && <PrintIcon className={styles.printIcon} />}
    </div>
  )
}

StatusIndicator.defaultProps = {
  text: '',
	icon: null,
  color: 'white',
	available: false,
}

StatusIndicator.propTypes = {
  text: PropTypes.string,
	icon: PropTypes.element,
  color: PropTypes.string,
	available: PropTypes.bool,
}

export default StatusIndicator
