import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { head, isEmpty } from "lodash";

import CartItems from "../containers/CartItems";

import CartDetails from "../containers/CartDetails";
import Button from "./Button";
import TabOrderCartDetails from "../components/tabs/TabOrderCartDetails";

import styles from "./CartSidebar.module.scss";
import networkConnected, { getNetworkAvailableValue } from "../utils/networkConnected";
import ConfirmSplitPaymentDialog from "./dialog/ConfirmSplitPaymentDialog";
import useOfflineModal from "../hooks/useOfflineModal";

import { MODES } from "../VNMode/Reducer";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceMode } from "../VNMode/Selectors";
import { getCurrentPaymentFlow, getPaymentProgress, getTransactionResult } from "../VNCustomerFacingDisplay/Selectors";
import { addTipOrderInProgress } from "../actions/order";
import { CFD_SCREEN } from "../VNCustomerFacingDisplay/Reducer";
import useCreateOrderInProgress from "../hooks/useCreateOrderInProgress";
import { bridge_sendExecuteAuthRequest, sendCFDScreenNav } from "../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend";
import { setCalculatedCurrentCartValues, setCurrentPaymentFlow } from "../VNCustomerFacingDisplay/ActionCreators";
import { CFD_POS_PAYMENT_FLOWS } from "../VNCustomerFacingDisplay/Enums";
import { setCardAuthStepOne, setConfirmPaymentDialog, setKitchenPrintErrorDialogOpen, setOpenCustomerPaymentDialog } from "../VNDialogs/ActionCreators";
import { getCardAuthStepOne, getCardAuthStepTwo, getCustomerPaymentDialogOpen } from "../VNDialogs/Selectors";
import { getOrderInProgress } from "../selectors/order";
import { getKitchenPrintingEnabled, getRequirePaymentForTabs } from "../selectors/menus";
import useGetIsMobileScreen from "../hooks/useGetIsMobileScreen";
import { isSDKCompatible } from "../VNAndroidSDK/Utils";
import { getAndroidSDKVersion, getIsKeyboardActive } from "../VNAndroidSDK/Selectors";
import ConfirmModalV2 from "./ConfirmModalV2";
import { ReactComponent as MobileShoppingCartOutlinedIcon } from '../assets/icons/mobileCart.svg'
import { ReactComponent as ShoppingCartOutlinedIcon } from '../assets/icons/Cart.svg'
import { isTabbed } from '../utils/orderStates'
import useRemoteOrderTotal from "../hooks/useRemoteOrderTotal";
import { setBridgePaymentMethod } from "../actions/peripheral";
import { bridgePaymentMethods } from "../reducers/peripheral";
import { centsToDollars, getDeviceSerial } from "../utils/formatters";
import { PAYMENT_TYPES } from "../utils/paymentTypes";
import { VNCFD_TRANSACTION_RESULT } from "../VNCustomerFacingDisplay/ActionTypes";
import { getBridgePaymentMethod } from "../selectors/peripheral";
import { calculateCartTotals } from "../utils/orderTotalUtils";
import { ToastManager } from '../utils/toastManager'

import { getSelectedPromotions } from '../selectors/promotion'
import { getCartItems, getTaxByItem, getTaxRate } from "../selectors/cart";
import { getMenuServiceCharge } from "../selectors/items";
import useSpinner from "../context/hooks/useSpinner";
import { pushAfterNextRerender } from "../utils/history";
import { APPLICATION_STRINGS } from "../strings";

const CartSidebar = ({
  topButton,
  bottomButton,
  clearCart,
  hasCartItems,
  order,
  total,
  history,
  discountsAvailable,
  noOpTenders,
  className,
  setMenus,
  startATab,
  addItemsToTab,
  addItemsToTabOffline,
  mutationInProgress,
  stands,
  menu,
  tabsModeEnabled,
  cashEnabled,
  ticketEnabled,
  tipEnabled,
  mobilePaySetting,
  clearPromotionsOnOrder,
  giftCardEnabled,
  startCFDPayment,
  requiredOrderData,
  remoteOrderTotalLoading,
  clearOrderInProgress,
}) => {
  const classNames = cn(styles.cartSidebar, className);
  const [showSplitPayDialog, setShowSplitPayDialog] = useState(false);
  const cardReaderData = history?.location?.state?.cardReaderData ?? null;
  const [showStartTabModal, setShowStartTabModal] = useState(history?.location?.state?.tabNameRequired ?? false);
  const [showSelectTabPaymentMethod, setShowSelectTabPaymentMethod] = useState(false)
  const [showConfirmAbandonCartModal, setShowConfirmAbandonCartModal] = useState(false)

  const [checkIfOfflineWithCallback, offlineModal] = useOfflineModal()
  const [createOrder, orderCreated] = useCreateOrderInProgress()
  const isKeyboardActive = useSelector(state => getIsKeyboardActive(state))
  const cfdMode = useSelector(state => getDeviceMode(state))
  const orderInProgress = useSelector(state => getOrderInProgress(state))
  const paymentProgress = useSelector(state => getPaymentProgress(state))
  const qrPayDialog = useSelector(state => getCustomerPaymentDialogOpen(state))
  const isMobileViewPort = useGetIsMobileScreen()
  const androidSDKVersion = useSelector(state => getAndroidSDKVersion(state))
  const currentPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))
  const cfdAuthResponse = useSelector(state => getTransactionResult(state))
  const stepTwoModal = useSelector(state => getCardAuthStepTwo(state))
  const stepOneModal = useSelector(state => getCardAuthStepOne(state))
  const cloudPrintingEnabled = useSelector(state => getKitchenPrintingEnabled(state))
  const bridgePaymentMethod = useSelector(state => getBridgePaymentMethod(state))
  const selectedPromotions = useSelector(state => getSelectedPromotions(state))
  const requirePaymentForTabs = useSelector(state => getRequirePaymentForTabs(state))
  const taxByItem = useSelector(state => getTaxByItem(state))
  const menuServiceCharge = useSelector(state => getMenuServiceCharge(state))
  const taxRate = useSelector(state => getTaxRate(state))
  const currentCart = useSelector(state => getCartItems(state))

  const { startSpinner } = useSpinner()

  const dispatch = useDispatch()

  const [loadRemoteOrderTotal, { data: remoteOrderTotal, success, failed, loading }] = useRemoteOrderTotal()

  const clearItemsAndPromotions = () => {
    clearCart()
    clearPromotionsOnOrder()
  }

  const isTab = !isEmpty(order)
  let modal = null
  let cartFooterElement = null

  const offlineModalScanScreenMessage = isMobileViewPort
    ? "This payment tender is not supported while the device is offline"
    : "This payment tender is not supported\nwhile the device is offline"

  const offlineGiftCardModalMessage = isMobileViewPort
    ? "Gift card tender is not supported while the device is offine"
    : "Gift card tender is not supported while\n the device is offine"

  const offlineSubtext = isMobileViewPort
    ? "Sorry, this device is now offline. Please choose another method of payment."
    : "Sorry, this device is now offline. Please\nchoose another method of payment."

  useEffect(() => {
    if(paymentProgress?.tipAmount && qrPayDialog?.get('open')) {
      createOrder()
    }
  }, [paymentProgress, qrPayDialog])

  useEffect(() => {
    const tipAmount = parseInt(paymentProgress?.tipAmount)
    if (!paymentProgress.tipAmount || !qrPayDialog?.get('open') || !orderCreated) return

    dispatch(setOpenCustomerPaymentDialog({open: false}))
    dispatch(addTipOrderInProgress(tipAmount))
    if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET) {
      checkIfOfflineWithCallback(() => history.push('/ticket'), offlineModalScanScreenMessage, true)
    } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.QR_PAY) {
      checkIfOfflineWithCallback(() => history.push('/qr-pay'), offlineModalScanScreenMessage, true)
      sendCFDScreenNav(CFD_SCREEN.PRESENT_QR_PAY_CODE, {cartTotals: {...requiredOrderData.cartTotals, uuid: orderInProgress.uuid}})
    } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.GIFT_CARD) {
      checkIfOfflineWithCallback(() => history.push('/gift-card'), offlineGiftCardModalMessage, true)
    }
  }, [paymentProgress, qrPayDialog, orderCreated])

  useEffect(() => {
    // if offline
    networkConnected().catch(() => {
      if (menu) setMenus(head(stands), menu);
    });
  }, [menu]);

  if (showSplitPayDialog) {
    modal = (
      <ConfirmSplitPaymentDialog
        onClose={() => setShowSplitPayDialog(false)}
        fromConcessions={true}
      />
    );
  }

  const processPromotions = async () => {
    try {
      await loadRemoteOrderTotal()
    } catch (e) {
      ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
      //continue processing if orderTotal fails and we can't apply promos
      return Promise.resolve()
    }
  }

  useEffect(() => {
    const parsedCfdAuthResponse = cfdAuthResponse.toJS()
    if (stepOneModal?.get('open') || stepTwoModal?.get('open') || currentPaymentFlow !== CFD_POS_PAYMENT_FLOWS.CREDIT_CARD_AUTHORIZATION) return
    if (isEmpty(parsedCfdAuthResponse)) return

    setShowStartTabModal(true)
    setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true)
  }, [currentPaymentFlow, cfdAuthResponse, stepTwoModal])

  const initiateTab = async (tabName) => {
    if (!tabName) return

    if (getNetworkAvailableValue()) {
      await processPromotions()
    }

    const tabData = {
      tabName
    }

    if (cfdMode === MODES.POS && !isEmpty(cfdAuthResponse.toJS())) {
      tabData.cardReaderData = cfdAuthResponse.toJS()
      tabData.paymentType = PAYMENT_TYPES.CREDIT_CARD
      tabData.paymentId = order?.paymentId
      dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true))
    }

    if(cardReaderData && cfdMode !== MODES.POS) {
      tabData.cardReaderData = cardReaderData
      tabData.paymentType = history?.location?.state?.paymentType
      tabData.paymentId = history?.location?.state?.paymentId
    }

    dispatch({
      type: VNCFD_TRANSACTION_RESULT,
      data: {}
    })

    startATab(tabData)
    setShowStartTabModal(false)
    setShowSelectTabPaymentMethod(false)

    if (!getNetworkAvailableValue() && cloudPrintingEnabled) {
      dispatch(setKitchenPrintErrorDialogOpen(true))
    }

    history.replace(history.location.pathname, {})
  }

  useEffect(() => {
    if ((!success && !failed) || loading || (!showSelectTabPaymentMethod && !requirePaymentForTabs) || cfdMode !== MODES.POS) return
    if (bridgePaymentMethods.executeAuthRequest !== bridgePaymentMethod) return
    const _calculatedCartValues = calculateCartTotals(remoteOrderTotal, currentCart, taxRate, taxByItem, menuServiceCharge)
    dispatch(setCalculatedCurrentCartValues(_calculatedCartValues))

    const displayAmount = centsToDollars(_calculatedCartValues.total)

    let paymentData = {
      amount: displayAmount,
      tipAmount: 0,
      hRef: _calculatedCartValues.hRef,
      orderNumber: _calculatedCartValues.orderNumber,
      uuid: _calculatedCartValues.uuid,
      deviceSerial: getDeviceSerial()
    }

    dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.CREDIT_CARD_AUTHORIZATION, true))
    dispatch(setCardAuthStepOne({open: true, amount: displayAmount}))
    bridge_sendExecuteAuthRequest(paymentData)
    setShowSelectTabPaymentMethod(false)
  }, [bridgePaymentMethod, showSelectTabPaymentMethod, remoteOrderTotal, success, failed, loading])

  const handleAuthorizePaymentRequest = () => {
    dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeAuthRequest))

    if (cfdMode === MODES.POS) {
      loadRemoteOrderTotal()
      return
    }

    history.push("/card-reader", {redirectPath: '/concession-order', authTabs: true});
  }

  const getInitialInputValue = () => {
    if (cfdMode === MODES.POS) return cfdAuthResponse?.get('cardholderName')

    return cardReaderData?.cardholderName || ''
  }

  if (showSelectTabPaymentMethod) {
    modal = (
      <ConfirmModalV2
        headerText={'Tab Payment Method'}
        classNames={{
          textBox: styles.textBox,
          container: styles.confirmModalContainer,
        }}
        emptyButtonContainer
        subtext={
          <div style={{display: "flex", flexDirection: 'column'}}>
            <Button onClick={handleAuthorizePaymentRequest} style={{height: 72}}>
              <span>Authorize Card Now</span>
            </Button>
            <Button onClick={() => {
              // we know that if the bridge payment method is Auth then we are authorizing the card and cannot add payment later
              if (bridgePaymentMethod === bridgePaymentMethods.executeSaleRequest) {
                setShowStartTabModal(true)
                setShowSelectTabPaymentMethod(false)
              }
            }} style={{marginTop: 24, height: 72}}>
              <span>Add Payment Later</span>
            </Button>
          </div>
        }
        displayCloseButton
        onCloseButtonClick={() => setShowSelectTabPaymentMethod(false)}
      />
    );
  }

  if (showStartTabModal) {
    modal = (
      <ConfirmModalV2
        headerText={'Tab Name'}
        buttonTwoText={'SAVE'}
        initialInputValue={getInitialInputValue()}
        singleButtonOnly
        onButtonTwoClick={initiateTab}
        useInput={true}
      />
    );
  }

  const handleScanScreenSelection = (scanScreenPath) => {
    if (tipEnabled) {
      history.push({
        pathname: '/signature',
        state: {
          addTipThenRedirectPath: scanScreenPath,
        }
      })
      return
    }
    history.push(scanScreenPath)
  }

  /**
   * addToTab logic:
   *
   *          order in Stadium   | order not in Stadium
   *         +-------------------+---------------------+
   * online  | call mutation api | add items locally   |
   *         +-------------------+---------------------+
   * offline | can't add items   | add items locally   |
   *         +-------------------+---------------------+
   *
   */
  const addToTab = async () => {

    const networkConnected = getNetworkAvailableValue()

    //Online processing
    if (networkConnected) {
      //Order aready in Stadium then we can mutate the order
      if (orderInProgress.wasCreatedInStadium) {
        await processPromotions()

        addItemsToTab()
        return
      }
      //Order is not in Stadium so even we're online we can't mutate we can just do a local update
      addItemsToTabOffline()
      return
    }

    //offline and order already in stadium then we need to error off
    if (orderInProgress.wasCreatedInStadium) {
      ToastManager.error("Items can't be added offline for this order")
      return
    }

    if (cloudPrintingEnabled) {
      dispatch(setKitchenPrintErrorDialogOpen(true))
    }

    //offline and order is not in stadium
    addItemsToTabOffline()
  }

  const trueButtonCount =
    !!cashEnabled +
    !isEmpty(noOpTenders) +
    !!mobilePaySetting +
    !!ticketEnabled +
    !!giftCardEnabled + 1

  let buttonCount;
  if (trueButtonCount > 4) {
    buttonCount = 4;
  } else {
    buttonCount = trueButtonCount;
  }

  const oddButtonCount = buttonCount % 2 === 1;

  cn([styles.checkout, buttonCount === 1 ? styles.long : styles.short])
  const showOtherButton = trueButtonCount > 3;

  const buttons = []

  if (!isTabbed(orderInProgress)) {
    buttons.push(
    <Button
      className={cn([styles.checkout, (buttonCount === 1 || tabsModeEnabled) ? styles.long : styles.short])}
      disabled={topButton.disabled}
      onClick={topButton.onClick}
    >
      {topButton.text}
    </Button>,
  );
  }

  // Quick Service Only Buttons
  if (!tabsModeEnabled) {
    if (cashEnabled) {
      buttons.push(
        <Button className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
        disabled={!hasCartItems}
        onClick={bottomButton.onClick}>
          {bottomButton.text}
        </Button>
      );
    }

    if (
      (buttons.length < 3 || (buttons.length < 4 && !showOtherButton)) &&
      mobilePaySetting
    ) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          disabled={!hasCartItems}
          onClick={() => {

            // CFD - Rich Checkout Button
            if (cfdMode === MODES.POS && mobilePaySetting === "pos_shows_qr") {
              startCFDPayment(true)
              return
            }
            if (mobilePaySetting === "pos_shows_qr") {
              history.push("/rich-checkout");
              return;
            }

            // qr pay for cfd
            if (cfdMode === MODES.POS) {
              checkIfOfflineWithCallback(() => {
                dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.QR_PAY, true))
                startCFDPayment(false)
              }, offlineModalScanScreenMessage, true)
            } else {
              checkIfOfflineWithCallback(() => handleScanScreenSelection('/qr-pay'), offlineModalScanScreenMessage, true)
            }
          }}
        >
          {mobilePaySetting === "pos_shows_qr" ? "App" : "QR Pay"}
        </Button>
      );
    }

    if (
      (buttons.length < 3 || (buttons.length < 4 && !showOtherButton)) &&
      ticketEnabled
    ) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          onClick={() => checkIfOfflineWithCallback(() => {
            if (cfdMode === MODES.POS) {
              dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.TICKET, true))
              startCFDPayment(false)
            } else {
              handleScanScreenSelection('/ticket')
            }
          }, offlineModalScanScreenMessage, true)}
          disabled={!hasCartItems}
        >
          Ticket
        </Button>
      );
    }

    if (
      (buttons.length < 3 || (buttons.length < 4 && !showOtherButton)) &&
      giftCardEnabled
    ) {
      buttons.push(
        <Button
          className={cn([styles.checkout, oddButtonCount && buttonCount === buttons.length+1 ? styles.long : styles.short])}
          disabled={!hasCartItems}
          onClick={() => checkIfOfflineWithCallback(() => {
            if (cfdMode === MODES.POS) {
              dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.GIFT_CARD, true))
              startCFDPayment(false)
            } else {
              handleScanScreenSelection('/gift-card')
            }
          }, offlineGiftCardModalMessage, true)}
        >
          Gift Card
        </Button>
      );
    }

    if (showOtherButton || (buttons.length < 4 && !isEmpty(noOpTenders))) {
      buttons.push(
        <Button
          className={cn([styles.checkout, buttons.length === 2 ? styles.long : styles.short])}
          disabled={!hasCartItems}
          onClick={() => history.push("/tender-selection")}
        >
          Other
        </Button>
      );
    }

    // IF CFD MODE && Android 2.13.3
    if (cfdMode !== MODES.POS || isSDKCompatible(androidSDKVersion, '2.13.3')) {
      buttons.push(
        <Button
          className={cn([styles.checkout, styles.long])}
          onClick={() => checkIfOfflineWithCallback(() => dispatch(setConfirmPaymentDialog(true)), offlineSubtext, true)}
          disabled={!hasCartItems}
        >
          Split
        </Button>
      )
    }
  }

  const handleStartTab = () => {
    if (requirePaymentForTabs) {
      handleAuthorizePaymentRequest()
      return
    }
    setShowSelectTabPaymentMethod(true)
  }

  // Full Service Enabled Buttons
  if (tabsModeEnabled) {
    if (isTabbed(orderInProgress)) {
      buttons.push(
        <Button
          className={cn([styles.checkout, styles.long])}
          disabled={!hasCartItems || mutationInProgress}
          onClick={addToTab}
        >
          Add to Tab
        </Button>
      );
    } else {
      buttons.push(
        <Button
          className={cn([styles.checkout, styles.long])}
          disabled={!hasCartItems || mutationInProgress}
          onClick={handleStartTab}
        >
          Start a Tab
        </Button>
      );
    }
  }

  cartFooterElement = (
    <div className={styles.cartDetailsContainer}>
      <CartDetails
        className={styles.cartDetails}
        order={order}
        lineItemPrefix={isTab ? "new" : ""}
      />
      {!isKeyboardActive && <div className={styles.buttonContainer}>{buttons}</div>}
    </div>
  )

  const shoppingCartIcon = isMobileViewPort ? (
    <MobileShoppingCartOutlinedIcon />
  ) : (
    <ShoppingCartOutlinedIcon />
  )

  const abandonCartMessage = isMobileViewPort
    ? "Items in cart will be removed upon syncing or navigating away from the menu. Do you wish to continue?"
    : "Items in cart will be removed upon\n syncing or navigating away from the\n menu. Do you wish to continue?"

  //TODO T32579 Create a common abandon cart modal
  const confirmAbandonCartElement = (
    <ConfirmModalV2
      onButtonOneClick={() => {
        setShowConfirmAbandonCartModal(false)
      }}
      onButtonTwoClick={() => {
        setShowConfirmAbandonCartModal(false)
        clearCart()
        clearOrderInProgress()
        if (cfdMode === MODES.POS) {
          sendCFDScreenNav(CFD_SCREEN.IDLE)
        }
        startSpinner()
        pushAfterNextRerender('/orders')
      }}
      headerText={shoppingCartIcon}
      subtext={abandonCartMessage}
      isKiosk={false}
      buttonOneText={'Cancel'}
      buttonTwoText={'Abandon Cart'}/>
  )


  const handleallOrdersButtonClick = () => {
    if (hasCartItems) {
      setShowConfirmAbandonCartModal(true)
      return
    }
    clearOrderInProgress()
    startSpinner()
    pushAfterNextRerender('/orders')
  }

  const allOrdersButton = (
    <Button
      className={styles.allOrdersButton}
      onClick={handleallOrdersButtonClick}
    >
      All Orders
    </Button>
  )

  const headerText = isTabbed(orderInProgress) ? orderInProgress.tabName : "New Order"

  return (
    <div className={classNames}>
      <div className={styles.header}>
        <span>{headerText}</span>
        {allOrdersButton}
      </div>

      <div className={styles.border}></div>
      {isTab && <TabOrderCartDetails order={order} />}
      <CartItems
        className={styles.cartItems}
        discountsAvailable={discountsAvailable}
        total={total}
        remoteOrderTotalLoading={remoteOrderTotalLoading}
        clearOrderInProgress={clearOrderInProgress}
        clearItemsAndPromotions={clearItemsAndPromotions}
      />
      {cartFooterElement}
      {modal}
      {offlineModal}
      {showConfirmAbandonCartModal && confirmAbandonCartElement}
    </div>
  );
};

CartSidebar.defaultProps = {
  clearCart: () => {},
  tabsModeEnabled: false,
  order: {},
};

CartSidebar.propTypes = {
  clearCart: PropTypes.func,
  startATab: PropTypes.func,
  addItemsToTab: PropTypes.func,
  addItemsToTabOffline: PropTypes.func,
  mutationInProgress: PropTypes.bool,
  clearOrderInProgress: PropTypes.func,
  hasCartItems: PropTypes.bool,
  tabsModeEnabled: PropTypes.bool,
  className: PropTypes.string,
  order: PropTypes.object,
};

export default CartSidebar;
