import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import cn from 'classnames'

import Navbar from '../containers/Navbar'
import OrdersList from '../containers/orders/List'
import OrdersSidebar from '../containers/orders/Sidebar'
import TopNav from '../containers/orders/TopNav'

import styles from './Orders.module.scss'

import Button from '../components/Button'
import ConfirmModalV2 from '../components/ConfirmModalV2'

import { clearSelectedOrder, tabOrdersRequested } from '../actions/order'
import { onSafIsUploading } from '../actions/peripheral'

import { getSafIsUploading } from '../selectors/peripheral'
import { getTabsModeEnabled } from '../selectors/menus'


const Orders = ({ className, onUnmount, offlineCards, offlineOrders, safIsUploading,
    setSafIsUploading, fetchTabs, tabsModeEnabled }) => {
  const classNames = cn(styles.orders, className)
  const history = useHistory()

  const [showConfirmStopSync, setShowConfirmStopSync] = useState(false)

  const stopSyncMessage = "Navigation is not supported while a sync is in progress.  Stopping the sync will leave some orders unsynced."
  let confirmStopSyncModal
  if (showConfirmStopSync) {
    confirmStopSyncModal = (
      <ConfirmModalV2
        headerText={"Stop Syncing Process?"}
        subtext={stopSyncMessage}
        buttonOneText={"Stop Sync"}
        buttonTwoText={"Continue Sync"}
        onButtonOneClick={() => {
          history.push('/concession-order')
          setSafIsUploading(false)
        }}
        onButtonTwoClick={() => {
          setShowConfirmStopSync(false)
        }}
      />
    )
  }

  const onNewOrderClick = () => {
    if (!safIsUploading) {
      history.push('/concession-order')
    } else {
      setShowConfirmStopSync(true)
    }
  }

  const [isMobileScreen, setIsMobileScreen] = useState(false)
  const [screenResize, setScreenResize] = useState(window.innerHeight)
  const [initialScreenSize, setInitialScreenSize] = useState(window.innerHeight)

  function updateIsMobileScreen(e) {
    setIsMobileScreen(e.matches)
  }

  useEffect(() => {
    const mediaWatcher = window.matchMedia('(max-width: 700px)')
    setIsMobileScreen(mediaWatcher.matches)

    mediaWatcher.addEventListener('change', updateIsMobileScreen)

    return function cleanup() {
      mediaWatcher.removeEventListener('change', updateIsMobileScreen)
    }
  }, [])
  
  useEffect(()=> {
    if(tabsModeEnabled) {
      fetchTabs()
    }
  }, [tabsModeEnabled, fetchTabs])

  const handleWindowResize = useCallback(e => {
    setScreenResize(window.innerHeight)
  }, [])

  useEffect(() => {
    setInitialScreenSize(window.innerHeight)
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  useEffect(() => {
    return () => {
      onUnmount()
    }
  })

  useEffect(() => {
    if (offlineCards === 0 && offlineOrders === 0 && safIsUploading) {
      setSafIsUploading(false)
    }
  }, [offlineCards, offlineOrders])

  return (
    <div className={classNames}>
      <Navbar className={styles.navbar} />
      <div className={styles.content}>
        <div className={styles.ordersContainer}>
          {isMobileScreen && <TopNav className={styles.header} />}
          <div className={styles.row}>
            <div className={styles.col1}>
              <OrdersList />
            </div>
            <div className={styles.col2}>
              <OrdersSidebar />
            </div>
          </div>
        </div>
        <div className={styles.mobileBtnContainer}>
          {initialScreenSize === screenResize ?
            <Button className={styles.mobileOrderBtn} onClick={onNewOrderClick} >
              New Order
            </Button>
            : null
          }

        </div>
      </div>
      {confirmStopSyncModal}
    </div>
  )
}

const mapStateToProps = (state) => ({
  offlineCards: state?.order?.offlineCardIds?.length ?? 0,
  offlineOrders: state?.order?.offlineLocalIds?.length ?? 0,
  safIsUploading: getSafIsUploading(state),
  tabsModeEnabled: getTabsModeEnabled(state)
})

const mapDispatchToProps = (dispatch) => ({
  onUnmount: () => dispatch(clearSelectedOrder()),
  setSafIsUploading: (syncState) => dispatch(onSafIsUploading(syncState)),
  fetchTabs: () => dispatch(tabOrdersRequested())
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
