import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import CartSidebar from './CartSidebar'
import { useSelector } from 'react-redux'
import { sendCFDScreenNav } from '../VNAndroidSDK/bridgeCalls/VNWebSDKDataSend'
import { CFD_SCREEN } from '../VNCustomerFacingDisplay/Reducer'
import { getDeviceMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import useRemoteOrderTotal from '../hooks/useRemoteOrderTotal'
import { CFDModalDialogs } from '../VNCustomerFacingDisplay/containers/CFDModalDialogs'
import { useDispatch } from 'react-redux'
import { calculateCartTotals } from '../utils/orderTotalUtils'
import queryString from 'query-string'
import { SIGNATURE_THRESHOLD_AMOUNT } from '../constants'
import { setCalculatedCurrentCartValues, setCurrentPaymentFlow, setUserProgress } from '../VNCustomerFacingDisplay/ActionCreators'
import { CFD_POS_PAYMENT_FLOWS } from '../VNCustomerFacingDisplay/Enums'
import { getCurrentPaymentFlow } from '../VNCustomerFacingDisplay/Selectors'
import { setOpenCustomerPaymentDialog } from '../VNDialogs/ActionCreators'
import { closeNoOpTender } from '../actions/order'
import { updateHighlightedItems, updateUseQuickSearchFlag } from '../actions/search'
import { getOrderInProgress } from '../selectors/order'
import { isTabbed } from '../utils/orderStates'
import { formatLocalItems, formatRemoteItems } from '../utils/richCheckout'

const ConcessionsCartSidebar = ({
  history,
  location,
  clearCart,
  tabsModeEnabled,
  hasCartItems,
  discountsAvailable,
  noOpTenders,
  className,
  setMenus,
  startATab,
  addItemsToTab,
  addItemsToTabOffline,
  clearOrderInProgress,
  mutationInProgress,
  stands,
  menu,
  tipSuggestions,
  tipsEnabled,
  cashEnabled,
  ticketEnabled,
  mobilePaySetting,
  clearPromotionsOnOrder,
  giftCardEnabled,
  cartItems,
  taxRate,
  taxByItem,
  menuServiceCharge,
  offlineTotal,
  total,
}) => {

  const dispatch = useDispatch()

  // SELECTORS
  // what mode are we in
  const cfdMode = useSelector(state => getDeviceMode(state))
  const cfdPaymentFlow = useSelector(state => getCurrentPaymentFlow(state))

  // LOCAL STATE
  const [CFDDialogDisplay, setCFDDialogDisplay] = useState(false)
  const [isRichCheckout, setIsRichCheckout] = useState(false)
  const [calculatedCartValues, setCalculatedCartValues] = useState(false)
  const [resetCFDModalDialog, setResetCFDModalDialog] = useState(false)
  const [cartTotals, setCartTotals] = useState(null)

  // used as a marker to determined if we have already received cart totals or not
  const [getCartTotals, setGetCartTotals] = useState(false)

  const [loadRemoteOrderTotal, { data: remoteOrderTotal, success, failed, loading }, resetOrderTotals] = useRemoteOrderTotal()
  const orderInProgress = useSelector(state => getOrderInProgress(state))

  // logic to determine whether to navigate to signature screen or not after selecting CC as the tender type
  const totalInCents = remoteOrderTotal?.totalAmountInCents ?? offlineTotal
  const threshHoldExceeded =  SIGNATURE_THRESHOLD_AMOUNT === 0 || (SIGNATURE_THRESHOLD_AMOUNT && totalInCents > SIGNATURE_THRESHOLD_AMOUNT)
  const signatureRequired = tipsEnabled || threshHoldExceeded

  // USE EFFECTS

  // check for query string messages being sent from TenderSelect screen.
  useEffect(() => {

    if (cfdMode === MODES.POS) {

      const queryStrings = queryString.parse(location.search, { arrayFormat: 'comma' })
      if (queryStrings?.payment === "card") {
        startCFDPayment(false)
      } else if (queryStrings?.payment === "rich_checkout") {
        startCFDPayment(true)
      }
    }
  }, [])

  // Listen for changes on the remote order total and used it to calculate the cart totals both online or offline
  useEffect(() => {
    if ((!success && !failed)) return
    if (!getCartTotals) return
    if (cfdMode !== MODES.POS) return

    if (cfdPaymentFlow === CFD_POS_PAYMENT_FLOWS.UNSET) {

      setGetCartTotals(false)
      let _calculatedCartValues
      dispatch(setCurrentPaymentFlow(CFD_POS_PAYMENT_FLOWS.UNSET, true))

      const orderInProgressTabbed = isTabbed(orderInProgress)

      if (isRichCheckout) {
        let richCheckoutTotal
        if (orderInProgressTabbed) {
          _calculatedCartValues = calculateCartTotals(null, cartItems, taxRate, taxByItem, menuServiceCharge, orderInProgress)
          dispatch(setCalculatedCurrentCartValues(_calculatedCartValues))
          setCalculatedCartValues(_calculatedCartValues)
        } else {
          _calculatedCartValues = getCalculatedCartTotals()
        }
        richCheckoutTotal = parseInt(_calculatedCartValues?.tax) +
                            parseInt(_calculatedCartValues?.subtotal) +
                            parseInt(_calculatedCartValues?.tip) +
                            parseInt(_calculatedCartValues?.serviceCharge) +
                            parseInt(_calculatedCartValues?.discount)

        if (richCheckoutTotal > 0) {
          // this will not work with tabbed orders
          const items = success
          ? formatRemoteItems(remoteOrderTotal?.lineItems)
          : formatLocalItems(cartItems)

          sendCFDScreenNav(CFD_SCREEN.RICH_CHECKOUT_QR_CODE, { cartTotals: _calculatedCartValues, formattedCartItems: items })
          setResetCFDModalDialog(true)
          setCFDDialogDisplay(true)
        } else {
          dispatch(closeNoOpTender())
        }

        return
      }

      _calculatedCartValues = getCalculatedCartTotals()

      sendCFDScreenNav(CFD_SCREEN.CARD_PAYMENT, { cartTotals: _calculatedCartValues, tips: { enabled: tipsEnabled, suggestions: tipSuggestions }})
      setResetCFDModalDialog(true)
      setCFDDialogDisplay(true)

      return
    }

    if (cfdPaymentFlow !== CFD_POS_PAYMENT_FLOWS.QR_PAY &&
      cfdPaymentFlow !== CFD_POS_PAYMENT_FLOWS.TICKET &&
      cfdPaymentFlow !== CFD_POS_PAYMENT_FLOWS.GIFT_CARD) {
      return
    }

    const calculatedCartTotals = getCalculatedCartTotals()
    dispatch(setOpenCustomerPaymentDialog({open: true, tipsEnabled: tipsEnabled, amount: calculatedCartTotals?.total}))
    setCartTotals(calculatedCartTotals)
    dispatch(setUserProgress({})) // reset the user progress incase of a tip already existing
    sendCFDScreenNav(CFD_SCREEN.CARD_PAYMENT, { cartTotals: calculatedCartTotals, executeSalesRequest: false, tips: { enabled: tipsEnabled, suggestions: tipSuggestions }})
  }, [getCartTotals, success, failed])

  const getCalculatedCartTotals = () => {
    const _calculatedCartValues = calculateCartTotals(remoteOrderTotal, cartItems, taxRate, taxByItem, menuServiceCharge)
    setCalculatedCartValues(_calculatedCartValues)
    dispatch(setCalculatedCurrentCartValues(_calculatedCartValues))
    return _calculatedCartValues
  }

  // When not in CFD mode, user will be navigated either to signature or card reader screen depending on the fetched order total
  useEffect(() => {
    if ((success || failed) && cfdMode !== MODES.POS) {
      if (signatureRequired || !isEmpty(tipSuggestions)) {
        history.push("/signature");
      } else {
        history.push("/card-reader")
      }
    }
  }, [success, failed])

  /**
   * Starts the CFD Payment flow
   * sets the correct states for CFD to use card payment or richcheckout
   */
  const startCFDPayment = (isRichCheckout) => {
    loadRemoteOrderTotal(true)
    setIsRichCheckout(isRichCheckout)
    setGetCartTotals(true)
    dispatch(updateUseQuickSearchFlag(false))
    dispatch(updateHighlightedItems({query: '', highlightedIds: [], focusedItemId: undefined}))
  }

  /**
   * The card button has been pressed
   */
  const onCardPayment = () => {

    if (cfdMode !==MODES.POS) {
      loadRemoteOrderTotal(true)
    } else {
      startCFDPayment(false)
    }
  }

  let topButton = {
    text: "Card",
    disabled: !hasCartItems,
    onClick: onCardPayment,
  }

  if (tabsModeEnabled) {
    topButton = {
      text: "Pay Now",
      disabled: !hasCartItems,
      onClick: () => {
        if (discountsAvailable) {
          return history.push('/discounts')
        } 
        history.push('/tender-selection')
      }
    }
  }

  const bottomButton = {
    text: "Cash",
    disabled: !hasCartItems,
    onClick: () => history.push("/change-calculator"),
  }

  const displayCFDModalDialogs = () => {
    if (cfdMode === MODES.POS) {
      return (
        <CFDModalDialogs
          clearCart={clearCart}
          clearPromotionsOnOrder={clearPromotionsOnOrder}
          CFDDialogDisplay={CFDDialogDisplay}
          setCFDDialogDisplay={setCFDDialogDisplay}
          isRichCheckout={isRichCheckout}
          tipsEnabled={tipsEnabled}
          resetOrderTotals={resetOrderTotals}
          resetCFDModalDialog={resetCFDModalDialog}
          setResetCFDModalDialog={setResetCFDModalDialog}
        />
      )
    }
  }

  return (
    <>
      {displayCFDModalDialogs()}
      <CartSidebar
        topButton={topButton}
        clearCart={clearCart}
        bottomButton={bottomButton}
        hasCartItems={hasCartItems}
        className={className}
        discountsAvailable={discountsAvailable}
        noOpTenders={noOpTenders}
        history={history}
        setMenus={setMenus}
        startATab={startATab}
        addItemsToTab={addItemsToTab}
        addItemsToTabOffline={addItemsToTabOffline}
        mutationInProgress={mutationInProgress}
        clearOrderInProgress={clearOrderInProgress}
        stands={stands}
        remoteOrderTotalLoading={loading}
        menu={menu}
        tabsModeEnabled={tabsModeEnabled}
        cashEnabled={cashEnabled}
        ticketEnabled={ticketEnabled}
        tipEnabled={!isEmpty(tipSuggestions)}
        mobilePaySetting={mobilePaySetting}
        clearPromotionsOnOrder={clearPromotionsOnOrder}
        startCFDPayment={startCFDPayment}
        giftCardEnabled={giftCardEnabled}
        total={total}
        requiredOrderData={{
          getCalculatedCartTotals, tipSuggestions, cartTotals
        }}// gives cart sidebar getCalculatedCartTotals, tipSuggestions without separating.
          // since its used only once
      />
    </>
  )
}

export default withRouter(ConcessionsCartSidebar)
