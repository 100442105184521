import { connect } from 'react-redux'

import OrdersList from '../../components/orders/List'

import {
  makeGetOrders,
  makeGetOfflineCardIds,
  makeGetOfflineLocalIds,
  makeGetSelectedOrder,
  getOrdersFilter,
} from '../../selectors/order'
import { getSafIsUploading } from '../../selectors/peripheral'
import { getIsKiosk } from '../../selectors/menus'

import { orderDetailsRequested, selectOrder } from '../../actions/order'

const mapStateToProps = (state) => {
  const getOrders = makeGetOrders()
  const orders = getOrders(state)

  const getSelectedOrder = makeGetSelectedOrder()
  const selectedOrder = getSelectedOrder(state) || {}

  const getOfflineCardIds = makeGetOfflineCardIds()
  const offlineCardIds = getOfflineCardIds(state)

  const getOfflineLocalIds = makeGetOfflineLocalIds()
  const offlineLocalIds = getOfflineLocalIds(state)

  const safIsUploading = getSafIsUploading(state)
  const isKiosk = getIsKiosk(state)

  const ordersFilter = getOrdersFilter(state)

  return {
    orders,
    selectedOrderId: selectedOrder.uuid,
    safIsUploading,
    offlineCardIds,
    offlineLocalIds,
    isKiosk,
    ordersFilter,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    orderDetailsRequested: (orderId) => {
      dispatch(orderDetailsRequested(orderId))
    },
    selectOrder: (orderId) => {
      dispatch(selectOrder(orderId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList)
