import React from 'react'
import cn from 'classnames'
import { Oval } from  'react-loader-spinner'

import styles from './ConnectingIndicator.module.scss'
import { BRANDING_COLOR } from '../constants'

const ConnectingIndicator = ({ className, isSidebarLoading, isKiosk, position = 'absolute', ...props }) => {
  const classNames = cn(styles.connectingIndicator, className, props.transparent ? styles.transparent : "")
  const containerStyle = isKiosk ? { backgroundColor: 'white', position } : { position }
  const spinnerStyle = isKiosk ? { backgroundColor: BRANDING_COLOR } : isSidebarLoading ? { backgroundColor: "#383B3D", height: 191, width: 191} : {}
  const color = isKiosk ? '#cccccc' : '#1463F5'
  const sidebarOvalSize = 144

  return (
    <div {...props} className={classNames} style={containerStyle}>
      <div className={styles.loadingSpinner} style={spinnerStyle}>
        <Oval
          height={isSidebarLoading ? sidebarOvalSize : 262}
          width={isSidebarLoading ? sidebarOvalSize : 262}
          color={color}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor='#000000'
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    </div>
  )
}

export default ConnectingIndicator
