import React, { useEffect } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { VNDetailedListItemArea } from 'vn-react-components/VNComponents/VNDetailedListItemArea'
import { centsToFloat } from '../../VNUtil/Money'

const useStyles = makeStyles(theme => ({
  root: {
  },
  leftAreaContainer: {
    textAlign: 'left',
    justifyContent: 'left',
    width: '100%',
    wordBreak: 'break-word'
  },
  rightAreaContainer: {
    textAlign: 'right',
    justifyContent: 'right',
    width: '100%'
  },
  discount: {
    color: `${theme.custom.colors.accent} !important`
  }
}))

export const VNCartListItem = (props) => {

  const classes = useStyles()
  const theme = useTheme()

  // SELECTORS

  // LOCAL STATE
  // const [openDialog, setOpenDialog] = useState(false)

  // USE EFFECTS

  useEffect(() => {
  }, [])

  /**
   * 
   * @param {Object} style a style object used for the text DEFAULT null
   * @param {Object} className extra styling for the text object DEFAULT null
   * @param {String} title the string value of the text object 
   * @param {String} variant Typography variant IE h3 DEFAULT empty string ''
   * @param {Boolean} left is left area?
   * @returns React Element
   */
  const displayArea = (style, className, title, variant, left) => {
    return (
      <Box className={left ? classes.leftAreaContainer : classes.rightAreaContainer}>
        <Typography style={style} variant={`${variant}`} className={className}>
          {title}
        </Typography>
      </Box>
    )
  }

  const display = () => {

    let items = []
    if(props.type) {
      if (props.type === "discount") {
        items.push(
          <VNDetailedListItemArea 
            leftArea={displayArea(theme.custom.subtitle4, classes.discount, props?.title, '', true)}
            leftAreaRatio={6}
          />
        )
      }
    } else {
      items.push(
        <VNDetailedListItemArea
          leftArea={displayArea(null, null, `${props.quantity} ${props.title}`, 'h3', true)}
          leftAreaRatio={6}
          rightArea={displayArea(null, null, props.price, 'h3', false)}
          rightAreaRatio={4}
        />
      )
    }
    
    if (props.modifiers && props.modifiers.length > 0) {
      props.modifiers.forEach(modifier => {
        items.push(
          <VNDetailedListItemArea 
            leftArea={displayArea(theme.custom.subtitle4, null, modifier.name, '', true)}
            leftAreaRatio={6}
            rightArea={displayArea(theme.custom.subtitle4, null, `$${centsToFloat(modifier.defaultPriceInCents)}`, '', false)}
            rightAreaRatio={4}
          />
        )
      })
    }

    return items
  }

  return (
    <Box className={classes.root}>
      {display()}
    </Box>
  )
}
