import React from 'react'
import {
  Route,
} from 'react-router-dom'

import Main from './screens/Main'
import Gratuity from './screens/Gratuity'
import Orders from './screens/Orders'
import DeviceSummary from './containers/orders/Summary'
import PaymentMethod from './screens/PaymentMethod'
import PaymentCompleted from './screens/PaymentCompleted'
import ChangeCalculator from './screens/ChangeCalculator'
import RegisterDevice from './screens/RegisterDevice'
import Receipt from './screens/Receipt'
import ReceiptSelect from '../src/containers/receipt/ReceiptSelect'
import RevenueCenterSetup from './screens/RevenueCenterSetup'
import RichCheckout from './screens/RichCheckout'
import NotAuthorized from './screens/NotAuthorized'
import Signature from './screens/Signature'
import CardReader from './screens/CardReader'
import DebugInfo from './components/DebugInfo'
import CartItemDetailsModal from './containers/CartItemDetailsModal'
import OrdersMenuSelection from './screens/OrdersMenuSelection'
import SelectDiscount from './containers/SelectDiscount'
import SelectNoOpPayment from './containers/SelectNoOpPayment'

import DeviceLogin from './containers/DeviceLogin'
import UserLogin from './containers/UserLogin'
import BridgeHandler from './containers/BridgeHandler'
import Hooks from './components/Hooks'
import SplitTender from './screens/tender'
import TenderSelect from './screens/tender/components/TenderSelect'
import TicketTender from './screens/TicketTender'
import QRPay from './screens/QRPay'
import GiftCard from './screens/GiftCard'
import Discounts from './screens/Discounts'
import { SelectLocation } from './components/SelectLocation'

const AppRoutes = () => {

  const allRoutes = [
    '/concession-order',
    '/concession-order/cart/:cartItemIndex?',
    '/concession-order/select-payment',
    '/concession-order/select-discount',
    '/concession-order/select-location',
    '/gratuity',
    '/orders/',
    '/orders/device-summary',
    '/menuSelection',
    '/payment-method',
    '/payment-completed',
    '/change-calculator/:paymentId?',
    '/receipt/:orderUuid?/:paymentId?',
    '/orders/:orderUuid?/receiptSelect',
    '/signature/:paymentId?',
    '/card-reader',
    '/rich-checkout',
    '/register-device',
    '/revenue-center-setup',
    '/device-login',
    '/user-login',
    '/not-authorized',
    '/debug-info',
    '/split-tender',
    '/discounts',
    '/tender-selection',
    '/ticket',
    '/gift-card',
    '/qr-pay',
    '/pos/balance-due',
    '/kiosk',
    '/kiosk/order',
    '/kiosk/attract_loop',
    '/kiosk/signature',
    '/kiosk/menu_select',
    '/kiosk/order/payment',
    '/kiosk/order/ticket',
    '/kiosk/order/qr-pay',
    '/kiosk/order/gift-card',
    '/kiosk/order/receipt_selection',
    '/kiosk/order/new',
    '/kiosk/order/scanned',
    '/kiosk/order/summary/:orderUuid',
  ]

  return (
    <>
      {/* <Route path="/" component={BridgeHandler} /> */}
      {/* <Route path="/" exact component={DeviceLogin} /> */}
      {/* <Route path="/" component={Hooks} /> */}

      <Route exact path={allRoutes} component={BridgeHandler} />
      <Route exact path={allRoutes} component={Hooks} />

      <Route path="/concession-order" component={Main} />
      <Route path="/concession-order/cart/:cartItemIndex?" exact component={CartItemDetailsModal} />
      <Route path="/concession-order/select-payment" exact component={SelectNoOpPayment} />
      <Route path="/concession-order/select-discount" exact component={SelectDiscount} />
      <Route path="/concession-order/select-location" exact component={SelectLocation} />

      <Route path="/gratuity" exact component={Gratuity} />
      <Route path="/orders/" component={Orders} />
      <Route path="/orders/device-summary" component={DeviceSummary} />
      <Route path="/menuSelection" exact component={OrdersMenuSelection} />
      <Route path="/payment-method" exact component={PaymentMethod} />
      <Route path="/payment-completed" exact component={PaymentCompleted} />
      <Route path="/change-calculator/:paymentId?" exact component={ChangeCalculator} />
      <Route path="/receipt/:orderUuid?/:paymentId?" exact component={Receipt} />
      <Route path="/orders/:orderUuid?/receiptSelect" exact component={ReceiptSelect} />
      <Route path="/signature/:paymentId?" exact component={Signature} />
      <Route path="/card-reader" exact component={CardReader} />
      <Route path="/rich-checkout" exact component={RichCheckout} />

      <Route path="/register-device" exact component={RegisterDevice} />
      <Route path="/revenue-center-setup" exact component={RevenueCenterSetup} />
      <Route path="/device-login" component={DeviceLogin} />
      <Route path="/user-login" component={UserLogin} />
      <Route path="/not-authorized" component={NotAuthorized} />
      <Route path="/debug-info/:presetToggle?" component={DebugInfo} />

      <Route exact path="/discounts" component={Discounts} />
      <Route exact path="/split-tender" component={SplitTender} />
      <Route exact path="/tender-selection" component={TenderSelect} />
      <Route exact path="/ticket" component={TicketTender} />
      <Route exact path="/qr-pay" component={QRPay} />
      <Route exact path="/gift-card" component={GiftCard} />
    </>
  )
}

export default AppRoutes
