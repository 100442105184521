import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPpiConfigurations } from '../ActionCreators'

export const VNPPI = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPpiConfigurations())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}