import { get } from 'lodash';

export const getCurrentMenuId = (state) => get(state, 'appState.currentMenuId')

export const getIsAccessibilityRequired = state => state?.appState?.isAccessibilityRequired ?? false

export const getUpdateAvailable = (state) => state?.appState?.updateAvailable ?? false

export const getAutomaticallyUpdate = (state) => state?.appState?.automaticallyUpdate ?? false

export const getPerformSafeUpdate = (state) => state?.appState?.performSafeUpdate ?? false

export const getSafMode = (state) => state?.appState?.safMode ?? 0
export const getSafResponse = (state) => state?.appState?.safResponse ?? { success: true, message: null }
export const getErrorInfo = (state) => state?.appState?.errorInfo ?? { }
