export const KEY_CODES = Object.freeze({
  ENTER: 13,
  ESC: 27,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39
});

export const ITEM_HEIGHTS = {
  imageHeight: '19vh',
  defaultHeight: '13.6vh'
}
