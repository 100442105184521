export const VNPPI_SERVICES = {
  REPORT_ORDER: 'reportOrder',
  GET_BALANCE: 'getBalance',
  GET_CONTEXTUAL_BALANCE: 'getContextualBalance',
  GET_CONTEXTUAL_DISCOUNTS: 'getContextualDiscounts',
  GET_DISCOUNTS: 'getDiscounts'
}

export const VNPPI_TENDERS = {
  GIFTCARD_TENDER: 'GIFTCARD_TENDER',
  TICKET_TENDER: 'TICKET_TENDER',
  QRPAY_TENDER: 'QRPAY_TENDER'
}

export const VNPPI_REPORT = {
  ALL: 'ALL',
  PARTIAL: 'PARTIAL'
}