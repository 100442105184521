import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import generateUuid from 'uuid/v4'

import { getCartItems, getTotal } from '../selectors/cart'
import { getCurrentMenu, getIsKiosk } from '../selectors/menus'
import useRemoteOrderTotal from './useRemoteOrderTotal'
import { makeLineItemData } from '../utils/orderUtils'
import { getActiveRevenueCenterId } from '../selectors/revenueCenter'
import { updateOrderInProgress } from '../actions/order'
import { getOrder } from '../selectors/order'
import { isTabbed } from '../utils/orderStates'
import { useHistory } from 'react-router-dom'


const useCreateOrderInProgress = (orderUuid) => {
  const [loadRemoteOrderTotal, { data: remoteOrderTotal, success, failed }] = useRemoteOrderTotal()
  const [orderCreated, setOrderCreated] = useState(false)
  const [tipAmountInCents, setTipAmountInCents] = useState(0)
  const [affiliations, setAffiliations] = useState([])
  const [userUuid, setUserUuid] = useState(null)

  const menu = useSelector(getCurrentMenu)
  const cartItems = useSelector(getCartItems)
  const revenueCenterUuid =  useSelector(getActiveRevenueCenterId)
  const offlineTotal = useSelector(getTotal)
  const noPaymentRequired = (remoteOrderTotal?.totalAmountInCents ?? offlineTotal) === 0
  const dispatch = useDispatch()
  const orderFromState = useSelector(state => getOrder(state, orderUuid))
  const isKiosk = useSelector(getIsKiosk)
  const history = useHistory()

  useEffect(() => {
    if (success || failed) {
      // If somehow arriving here with no items, re-route back to menu
      if (isEmpty(cartItems) && isEmpty(orderFromState?.itemModels)) {
        if (isKiosk) {
          history.push('/kiosk/order/new')
        } else {
          history.push('/concession-order')
        }
        return
      }

      const total = remoteOrderTotal?.totalAmountInCents ?? offlineTotal
      let order = {
        uuid: orderFromState?.uuid ?? remoteOrderTotal?.uuid ?? generateUuid(),
        balanceDueInCents: total + tipAmountInCents,
        payments: [],
        amountInCents: total + tipAmountInCents,
        taxAmountInCents: remoteOrderTotal?.taxAmountInCents ?? 0,
        subtotalAmountInCents: remoteOrderTotal?.subtotalInCents ?? total,
        discountAmountInCents: remoteOrderTotal?.discountAmountInCents ?? 0,
        serviceFee: remoteOrderTotal?.serviceChargeInCents ? { name: remoteOrderTotal?.serviceChargeName, total: remoteOrderTotal?.serviceChargeInCents, legal: remoteOrderTotal?.serviceChargeLegal } : null,
        itemModels: orderFromState?.itemModels ?? makeLineItemData(cartItems),
        menuSubtype: orderFromState?.menuSubtype ?? menu?.subtype,
        revenueCenterUuid,
        tipAmountInCents,
        standMenuUuid: menu?.standUuid,
        hasRemoteTotal: remoteOrderTotal?.totalAmountInCents ?? false,
        affiliations,
        userUuid,
      }

      if (isTabbed(orderFromState)) {
        order = {
          ...orderFromState,
          ...order,
        }
      }
      dispatch(updateOrderInProgress(order))
      setOrderCreated(true)
    }
  }, [success, failed])

  const createOrder = (reTotaling, userUuid, affiliations = [], oldTipAmountInCents = 0) => {
    setTipAmountInCents(oldTipAmountInCents)
    setAffiliations(affiliations)
    setOrderCreated(false)
    setUserUuid(userUuid)

    loadRemoteOrderTotal(reTotaling, affiliations, userUuid)
  }

  return [createOrder, orderCreated, noPaymentRequired, remoteOrderTotal]
}

export default useCreateOrderInProgress
