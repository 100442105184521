import * as ActionTypes from './ActionTypes'
import * as VNCFD_ActionTypes from '../VNCustomerFacingDisplay/ActionTypes'

import Remote from '../remote'

import { VN_BRIDGE_RECEIVE_METHODS } from './Enums'
import { userPaymentProgressHandler } from './bridgeReceiveHandlers/userPaymentProgressHandler'

import { VNDataBridgeReceiveType } from './bridgeCalls/VNWebSDKDataReceive'

import { CFD_SCREEN } from '../VNCustomerFacingDisplay/Reducer'
import { bridge_updateTransactionLifecycle, sendExecuteAuthRequest, sendExecuteSalesRequest, sendUserPaymentProgress } from './bridgeCalls/VNWebSDKDataSend'
import { CFD_EVENTS, CFD_POS_PAYMENT_FLOWS, USER_PROGRESS } from '../VNCustomerFacingDisplay/Enums'
import { setCFDScreen, setOrderUpdate, setBalanceToBePaid, setCurrentPaymentFlow, updateTransactionLifecycle, setCurrentOrder, setPaymentData } from '../VNCustomerFacingDisplay/ActionCreators'
import { getCartTotals, getCurrentCart, getCurrentPaymentFlow, getPaymentData } from '../VNCustomerFacingDisplay/Selectors'

import { printOrder } from '../actions/printer'
import { addPaymentToOrderInProgress, addTipOrderInProgress, sendOrderText } from '../actions/order'
import { setBridgePaymentMethod } from '../actions/peripheral'

import { getCardAuthStepOne } from '../VNDialogs/Selectors'
import { setCardAuthStepOne, setCardAuthStepTwo, setCustomerPayingWithCard, setCustomerViewingReceiptDialog } from '../VNDialogs/ActionCreators'

import { getDeviceMode, getSummitMode } from '../VNMode/Selectors'
import { MODES } from '../VNMode/Reducer'
import { setDeviceId } from '../VNMode/ActionCreators'

import { bridgePaymentMethods } from '../reducers/peripheral'

import { getOrderInProgress } from '../selectors/order'

import { getDeviceSerial, stringToCents } from '../utils/formatters'
import { invoiceNumber } from '../utils/orderNumbers'
import { isTabbed } from '../utils/orderStates'

const TRANSACTION_DELAY = 5000
const TRANSACTION_ERROR_DELAY = 3000

/**
 * TODO: REMOVE ALL SET TIMEOUT FUNCTIONS. To be fixed on F3678
 * Called to set receiving method name and accompanying data
 * from Native to JS via the bridge.
 * @param {object} data
 */
export function setWebSDKDataReceive(response) {
  return (dispatch, getState) => {

    // handleAcknowledgement(dispatch, getState, response)

    // pairing result has returned for the device
    if (response.method === VNDataBridgeReceiveType.VN_DEVICE_PAIRING_RESULT) {

      let paired = false
      if (response.data.result === 'SUCCESS') {
        paired = true
      }

      if (response.data.device === 'POS') {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_POS_SET_PAIRED,
          POSisPaired: paired
        })
      } else if (response.data.device === 'CFD') {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_CFD_SET_PAIRED,
          CFDisPaired: paired
        })
      }
    } else if (response.method === VNDataBridgeReceiveType.VN_INITIALIZE_CFD) {
      // This is the POS devices ID - the L1400 more than likely at this time.
      if (response?.data?.deviceId) {
        localStorage.setItem('device_id', response.data.deviceId)
      }

      // initialize the CFD
      dispatch({
        type: VNCFD_ActionTypes.VNCFD_SET_CFD_INITIALIZED,
        data: response.data
      })
    } else if (response.method === VNDataBridgeReceiveType.VN_UPDATE_CART) {

      // set the cart items for the CFD
      dispatch({
        type: VNCFD_ActionTypes.VNCFD_UPDATE_CART,
        cartItems: response.data.cartItems
      })
    } else if (response.method === VNDataBridgeReceiveType.VNCFD_USER_PAYMENT_PROGRESS) {
      userPaymentProgressHandler(getState(), dispatch, response.data)
    } else if (response.method === VNDataBridgeReceiveType.VN_CFD_SCREEN_NAV) {

      // must set executeSalesRequest to false every time because we can set it
      // to false and it'll stay false unless added to every screen nav request
      if(response.data.executeSalesRequest) {
        dispatch({
          type: VNCFD_ActionTypes.VN_CAN_EXECUTE_SALES_REQUEST,
          data: response.data.executeSalesRequest
        })
      } else {
        dispatch({
          type: VNCFD_ActionTypes.VN_CAN_EXECUTE_SALES_REQUEST,
          data: true
        })
      }

      if (response.data.balanceToBePaid) {
        dispatch(setBalanceToBePaid(response.data.balanceToBePaid))
      }

      // check for other data that tells us where to store it prior to the navigation
      // if we need to update the cart prior to the screen navigation
      if (response.data.cartItems) {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_UPDATE_CART,
          cartItems: response.data.cartItems
        })
      }

      if (response.data.formattedCartItems) {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_UPDATE_FORMATTED_CART,
          cartItems: response.data.formattedCartItems
        })
      }

      if (response.data.cartTotals) {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_SET_CART_TOTALS,
          totals: {
            ...response.data.cartTotals,
            tax: Boolean(parseInt(response.data.cartTotals.tax)) ? parseInt(response.data.cartTotals.tax) : 0,
            subtotal: Boolean(parseInt(response.data.cartTotals.subtotal)) ? parseInt(response.data.cartTotals.subtotal) : 0,
            tip: Boolean(parseInt(response.data.cartTotals.tip)) ? parseInt(response.data.cartTotals.tip) : 0,
            serviceCharge: Boolean(parseInt(response.data.cartTotals.serviceCharge)) ? parseInt(response.data.cartTotals.serviceCharge) : 0,
            discount: Boolean(parseInt(response.data.cartTotals.discount)) ? parseInt(response.data.cartTotals.discount) : 0
          }
        })
      }

      if (response.data.tips) {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_TIPS_DATA,
          tips: response.data.tips
        })
      }

      if (response.data.transaction) {
        dispatch({
          type: VNCFD_ActionTypes.VNCFD_TRANSACTION_RESULT,
          data: response.data.transaction
        })
      }

      if (response.data.currentPaymentFlow) {
        dispatch(setCurrentPaymentFlow(response.data.currentPaymentFlow))
      }

      // set the screen
      dispatch({
        type: VNCFD_ActionTypes.VNCFD_SET_SCREEN,
        screen: response.data.screen
      })
    } else if (response.method === VNDataBridgeReceiveType.VN_RESET_CFD) {

      // we are starting a new transaction, resest the CFD state

      dispatch({
        type: VNCFD_ActionTypes.VNCFD_SET_SCREEN,
        screen: CFD_SCREEN.IDLE
      })

      dispatch({
        type: VNCFD_ActionTypes.VNCFD_EXECUTE_SALES_REQUEST,
        data: {}
      })

      dispatch({
        type: VNCFD_ActionTypes.VNCFD_UPDATE_CART,
        cartItems: []
      })

      dispatch({
        type: VNCFD_ActionTypes.VNCFD_SET_CART_TOTALS,
        totals: {}
      })

      dispatch({
        type: VNCFD_ActionTypes.VNCFD_TRANSACTION_RESULT,
        data: {}
      })

    } else if (response.method === VNDataBridgeReceiveType.VN_TRANSACTION_RESULT) {

      const cfdMode = getDeviceMode(getState())
      const currentPaymentFlow = getCurrentPaymentFlow(getState())

      let paymentProgressObj = {
        ...USER_PROGRESS.CARD_PAYMENT.PAYMENT_FINISHED.VIEWING_RECEIPT
      }

      // Approvals are A and C
      if (response.data?.receiptData?.approvalCode === "A" || response.data?.receiptData?.approvalCode === "C") {
        const authStepOne = getCardAuthStepOne(getState())
        if (authStepOne?.get('open')) {
          dispatch(setCardAuthStepOne({open: false}))
          dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeSaleRequest)) // reset the bridge payment method
          dispatch(setCardAuthStepTwo({open: true, amount: authStepOne.get('amount')}))
        }

        dispatch({
          type: VNCFD_ActionTypes.VNCFD_TRANSACTION_RESULT,
          data: response?.data
        })

        if (cfdMode === MODES.POS) {

          if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET ||
            currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.GIFT_CARD ||
            currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.QR_PAY
          ) {
            const orderInProgress = getOrderInProgress(getState())

            dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                    orderNumber: orderInProgress?.orderNumber,
                                                    tenderAmountInCents: stringToCents(response.data.amount),
                                                    paymentId: orderInProgress?.paymentId,
                                                    paymentType: 'freedompay_credit',
                                                    navigateHistory: false }))
            dispatch(setCustomerPayingWithCard({open: false}))
            dispatch(setCustomerViewingReceiptDialog(true))
          } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {

            // don't move on POS receiving the message from android, lets CFD complete and return to the web first
            const orderInProgress = getOrderInProgress(getState())
            // when first split pay payment is done get order in progress doesn't exist.
            // Use get current cart to gather the required data so the orders saga doesn't create more variables.
            const currentCart = getCurrentCart(getState())

            dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                    orderNumber: orderInProgress?.orderNumber ? orderInProgress?.orderNumber : currentCart?.get('orderNumber'),
                                                    tenderAmountInCents: stringToCents(response.data.amount),
                                                    paymentId: orderInProgress?.paymentId,
                                                    paymentType: 'freedompay_credit',
                                                    navigateHistory: false }))
          } else {
            const orderInProgress = getOrderInProgress(getState())
            if (isTabbed(orderInProgress)) {
              // when first split pay payment is done get order in progress doesn't exist.
              // Use get current cart to gather the required data so the orders saga doesn't create more variables.
              const currentCart = getCurrentCart(getState())

              dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                      orderNumber: orderInProgress?.orderNumber ? orderInProgress?.orderNumber : currentCart?.get('orderNumber'),
                                                      tenderAmountInCents: stringToCents(response.data.amount),
                                                      paymentId: orderInProgress?.paymentId,
                                                      paymentType: 'freedompay_credit',
                                                      navigateHistory: false }))
            }

            dispatch({
              type: VNCFD_ActionTypes.VNCFD_USER_PAYMENT_PROGRESS,
              data: paymentProgressObj
            })
          }
        } else if (cfdMode === MODES.CFD) {
          const paymentData = getPaymentData(getState())
          // dispatch transaction result again because the transaction result only has the amount paid and not the
          // full order total
          if(paymentData?.get('orderTotal')) {
            dispatch({
              type: VNCFD_ActionTypes.VNCFD_TRANSACTION_RESULT,
              data: {
                amount: paymentData.get('orderTotal')
              }
            })
          }
          if (currentPaymentFlow !== CFD_POS_PAYMENT_FLOWS.CREDIT_CARD_AUTHORIZATION && currentPaymentFlow !== CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
            dispatch({
              type: VNCFD_ActionTypes.VNCFD_SET_SCREEN,
              screen: CFD_SCREEN.RECEIPT
            })
          }

          // can't let POS decide since broadPOS doesn't release quick enough, CFD needs to decide
          if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
            // if the time isn't here, then the bridge won't be active on the way BACK to the CFD from the POS.
            // THIS message will go OUT to the POS, but the subsequent call from POS won't ever reach CFD. Strange...
            setTimeout(() => {
              bridge_updateTransactionLifecycle(CFD_EVENTS.CC_PAYMENT_COMPLETE)
            }, TRANSACTION_DELAY)

            if (paymentData?.get('isFinalPayment') === 'true') {
              dispatch({
                type: VNCFD_ActionTypes.VNCFD_SET_CART_TOTALS,
                totals: {
                  uuid: paymentData.get('uuid'),
                }
              })

              dispatch({
                type: VNCFD_ActionTypes.VNCFD_SET_SCREEN,
                screen: CFD_SCREEN.RECEIPT
              })
            }
          }
        }
      } else if (response.data?.receiptData?.approvalCode === "" && response.data?.status === "APPROVED OFFLINE") {
        const authStepOne = getCardAuthStepOne(getState())
        if (authStepOne?.get('open')) {
          dispatch(setCardAuthStepOne({open: false}))
          dispatch(setBridgePaymentMethod(bridgePaymentMethods.executeSaleRequest)) // reset the bridge payment method
          dispatch(setCardAuthStepTwo({open: true, amount: authStepOne.get('amount')}))
        }

        dispatch({
          type: VNCFD_ActionTypes.VNCFD_TRANSACTION_RESULT,
          data: response?.data
        })

        if (cfdMode === MODES.POS) {
          if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.QR_PAY) {

            const orderInProgress = getOrderInProgress(getState())

            dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                    orderNumber: orderInProgress?.orderNumber,
                                                    tenderAmountInCents: stringToCents(response.data.amount),
                                                    paymentId: orderInProgress?.paymentId,
                                                    paymentType: 'freedompay_credit',
                                                    navigateHistory: false,
                                                    isApprovedOffline: true }))

            dispatch(setCustomerPayingWithCard({open: false}))
            dispatch(setCustomerViewingReceiptDialog(true))
          } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
            const orderInProgress = getOrderInProgress(getState())

            dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                    orderNumber: orderInProgress?.orderNumber,
                                                    tenderAmountInCents: stringToCents(response.data.amount),
                                                    paymentId: orderInProgress?.paymentId,
                                                    paymentType: 'freedompay_credit',
                                                    navigateHistory: false,
                                                    isApprovedOffline: true }))
          } else if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.TICKET) {
            const orderInProgress = getOrderInProgress(getState())

            dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                    orderNumber: orderInProgress?.orderNumber,
                                                    tenderAmountInCents: stringToCents(response.data.amount),
                                                    paymentId: orderInProgress?.paymentId,
                                                    paymentType: 'freedompay_credit',
                                                    navigateHistory: false,
                                                    isApprovedOffline: true }))
              dispatch(setCustomerPayingWithCard({open: false}))
              dispatch(setCustomerViewingReceiptDialog(true))
          } else {
            const orderInProgress = getOrderInProgress(getState())
            if (isTabbed(orderInProgress)) {
              const currentCart = getCurrentCart(getState())

              dispatch(addPaymentToOrderInProgress({  cardReaderData: response.data,
                                                      orderNumber: orderInProgress?.orderNumber ? orderInProgress?.orderNumber : currentCart?.get('orderNumber'),
                                                      tenderAmountInCents: stringToCents(response.data.amount),
                                                      paymentId: orderInProgress?.paymentId,
                                                      paymentType: 'freedompay_credit',
                                                      navigateHistory: false,
                                                      isApprovedOffline: true }))
            }

            dispatch({
              type: VNCFD_ActionTypes.VNCFD_USER_PAYMENT_PROGRESS,
              data: paymentProgressObj
            })
          }
        } else if (cfdMode === MODES.CFD) {
          if (currentPaymentFlow === CFD_POS_PAYMENT_FLOWS.SPLIT_PAY) {
            // if the time isn't here, then the bridge won't be active on the way BACK to the CFD from the POS.
            // THIS message will go OUT to the POS, but the subsequent call from POS won't ever reach CFD. Strange...
            setTimeout(() => {
              bridge_updateTransactionLifecycle(CFD_EVENTS.CC_PAYMENT_COMPLETE)
            }, TRANSACTION_DELAY)
          } else {
            const paymentData = getPaymentData(getState())
            // dispatch transaction result again because the transaction result only has the amount paid and not the
            // full order total
            if(paymentData?.get('orderTotal')) {
              dispatch({
                type: VNCFD_ActionTypes.VNCFD_TRANSACTION_RESULT,
                data: {
                  amount: paymentData?.get('orderTotal')
                }
              })
            }

            if (currentPaymentFlow !== CFD_POS_PAYMENT_FLOWS.CREDIT_CARD_AUTHORIZATION) {
              dispatch({
                type: VNCFD_ActionTypes.VNCFD_SET_SCREEN,
                screen: CFD_SCREEN.RECEIPT
              })
            }
          }
        }

      } else {

        const words = response.data?.reasonText?.split(" ");

        const responseText = words?.map((word) => {
          return word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase()
        }).join(" ")

        // this is a declined card from BroadPOS App
        const error = {
          title: USER_PROGRESS.CARD_PAYMENT.ERROR.title,
          error: true,
          subTitle: `${responseText}\n Error Code: ${response.data.reasonCode}`,
          flow: 'TRANSACTION_ERROR'
        }

        if (cfdMode === MODES.CFD) {
          setTimeout(() => {
            sendUserPaymentProgress(error)
          }, TRANSACTION_ERROR_DELAY);
        }

        dispatch({
          type: VNCFD_ActionTypes.VNCFD_SET_SCREEN,
          screen: CFD_SCREEN.CARD_DECLINED
        })

        const CFDerror = {
          title: `${responseText}\n Error Code: ${response.data.reasonCode}`,
          subTitle: "Please wait for cashier."
        }

        dispatch({
          type: VNCFD_ActionTypes.VNCFD_CARD_ERROR,
          data: CFDerror
        })
      }
    } else if (response.method === VNDataBridgeReceiveType.VN_PRINT_RECEIPT) {
      if(response.data.emails) {
        Remote.sendEmailJobToStadium({orderUuid: response.data.orderUuid, emails: response.data.emails})
      } else if (response.data.phone) {
        dispatch(sendOrderText(response.data.orderUuid, response.data.phone))
      } else if (response.data.TEMP_activateCardReader) {

        const cartTotals = getCartTotals(getState())

        // pick up hijacked request - i'm on the CFD
        let currentPaymentData = {
          amount: parseInt(response.data.total) / 100,
          tip: 0,
          hRef: response?.data?.orderNumber ? invoiceNumber(response?.data?.orderNumber) : invoiceNumber(cartTotals?.get('orderNumber')),
          orderNumber: response?.data?.orderNumber ? response?.data?.orderNumber : cartTotals?.get('orderNumber'),
          uuid: cartTotals?.get('uuid'),
          deviceSerial: getDeviceSerial(),
          orderTotal: parseInt(response.data.orderTotal) / 100,
          isFinalPayment: response.data?.isFinalPayment // this data is fine to send to android since android only uses the data it needs
                                                        // and ignores the rest
        }
        dispatch(setPaymentData(currentPaymentData))
        dispatch(setCFDScreen(CFD_SCREEN.PROCESSING))
        sendExecuteSalesRequest(currentPaymentData)
      } else {
        // we are on the POS, need to send this through the old bridge to print
        dispatch(printOrder({ orderUuid: response.data.orderUuid }))
      }
    } else if (response.method === VNDataBridgeReceiveType.RETRY_PAYMENT) {

      if (response.data?.bridgePaymentMethod === bridgePaymentMethods.executeAuthRequest) {
        sendExecuteAuthRequest(getPaymentData(getState()))
      } else {
        sendExecuteSalesRequest(getPaymentData(getState()))
      }

      sendUserPaymentProgress({...USER_PROGRESS.CARD_PAYMENT.PAYING_CARD, amount: getPaymentData(getState()).get('amount')})

      // move to the processing screen
      dispatch(setCFDScreen(CFD_SCREEN.PROCESSING))
    } else if (response.method === VNDataBridgeReceiveType.VN_ORDER_UPDATE) {
      dispatch(setOrderUpdate(response.data))
    } else if (response.method === VNDataBridgeReceiveType.VN_DEVICE_ID) {
      dispatch(setDeviceId(response.data.deviceId))
    } else if (response.method === VN_BRIDGE_RECEIVE_METHODS.VN_BRIDGE_PASSTHROUGH) {

      if(response.data.method === VNDataBridgeReceiveType.VN_EXECUTE_AUTH_REQUEST) {
        dispatch(setPaymentData(response.data.data))
        dispatch(setCFDScreen(CFD_SCREEN.PROCESSING))
        sendExecuteAuthRequest(response.data.data)
      }

      if (response.data.method === VN_BRIDGE_RECEIVE_METHODS.VN_UPDATE_TRANSACTION_LIFECYCLE) {

        dispatch(updateTransactionLifecycle(response.data.data.moment))

      } else if (response.data.method === VN_BRIDGE_RECEIVE_METHODS.VN_SET_CURRENT_ORDER) {

        dispatch(addTipOrderInProgress(parseInt(response.data.data.tip)))
        dispatch(setCurrentOrder(response.data.data))

      } else if (response.data.method === VN_BRIDGE_RECEIVE_METHODS.VN_SET_CURRENT_PAYMENT_FLOW) {

        dispatch(setCurrentPaymentFlow(response.data.data.flow))

      } else if (response.data.method === VN_BRIDGE_RECEIVE_METHODS.VN_UPDATE_CFD_VERSION) {

        const deviceMode = getDeviceMode(getState())
        const isSummit = getSummitMode(getState())

        let endOfUrl = ''

        if (deviceMode === MODES.CFD) {
          endOfUrl += `&cfd=true`
        }

        if (isSummit) {
          endOfUrl += `&summit=true`
        }

        window.location = `${window.location.origin}/?cacheBuster=${new Date().toISOString()}${endOfUrl}`
      }
    }

    // always update the latest received
    dispatch({
      type: ActionTypes.VNANDROIDSDK_SET_DATA_RECIEVE,
      latestReceived: response.data
    })
  }
}

/**
 * Semantic version string of native SDK being utilized
 * @param {string} version
 */
export function setWebSDKVersion(version) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNANDROIDSDK_SET_VERSION,
      version: version
    })
  }
}

/**
 * @param {boolean} isActive set keyboard active if keyboard is active on the device
 */
export function setIsKeyboardActive(isActive) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNANDROIDSDK_IS_KEYBOARD_ACTIVE,
      isActive
    })
  }
}