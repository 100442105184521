import { createSelector } from 'reselect'
import { get, values, find } from 'lodash'
import { getAttendantSettings } from './attendantSettings'

export const MENU_TYPES = [ 'preorder', 'day_of_event', 'coffee', 'cordial', 'dessert', 'restock' ]

export const getAttendants = (state) => values(get(state, 'attendant.byId'))

export const getAttemptPinLoginStatus = (state) => get(state, 'attendant.attemptPinLoginStatus')

export const getFetchAttendantsStatus = (state) => get(state, 'attendant.fetchAttendantsStatus')

export const getActiveAttendantId = (state) => get(state, 'attendant.activeAttendantId')

export const makeGetAttendant = () => createSelector(
  getAttendants, (_, attendantId) => attendantId,
  (attendants, attendantId) => find(attendants, ['uuid', attendantId])
)

export const makeGetAttendantByPin = () => createSelector(
  getAttendants, (_, pin) => pin,
  (attendants, pin) => find(attendants, ['pinNumber', pin])
)

export const makeGetActiveAttendant = () => createSelector(
  getAttendants, getAttendantSettings, getActiveAttendantId, (state) => values(state?.employeeRole?.byId),
  (attendants, attendantSettings, activeAttendantId, employeeRoles) => {
    const attendant = find(attendants, ['uuid', activeAttendantId])
    const attendantSettingsId = get(attendant, 'attendantSettingsId')
    const settings = find(attendantSettings, ['uuid', attendantSettingsId])
    const employeeId = attendant?.id
    const employeeRole = employeeRoles.find((role) => role.employeeId === employeeId)

    return {
      ...attendant,
      settings,
      employeeRole,
    }
  }
)

export const getAttendantName = (state, employeeId) => {
  return state?.attendant?.byId?.[employeeId]?.name ?? ''
}

export const makeGetAttendantMenuTypes = () => createSelector(
  /**
   * This is not the most efficient since the `(state) => state` selector will
   * change most of the time and not provide the benefit memoization.
   */
  makeGetActiveAttendant(), (state) => state,
  (attendantSettings, state) => {
    const employeeId = attendantSettings.id
    const employeeRoles = values(state.employeeRole?.byId)
    const employeeRole = employeeRoles.find((role) => role.employeeId === employeeId)
    const hasAllAccess = ['manager', 'supervisor'].includes(employeeRole?.role?.toLowerCase?.())
    const attendantMenuSubtypes = get(attendantSettings, 'settings.configuration.menuSubtypes')

    if (attendantMenuSubtypes === null || hasAllAccess) {
      return MENU_TYPES
    } else {
      return attendantMenuSubtypes ?? []
    }
  }
)

export const makeGetAttendantStandUuids = () => createSelector(
  /**
   * This is not the most efficient since the `(state) => state` selector will
   * change most of the time and not provide the benefit memoization.
   */
  makeGetActiveAttendant(), (state) => state,
  (attendantSettings, state) => {
    const employeeId = attendantSettings.id
    const employeeRoles = values(state.employeeRole?.byId)
    const attendantStandUuids = get(attendantSettings, 'settings.configuration.standUuids')
    const employeeRole = employeeRoles.find((role) => role.employeeId === employeeId)
    const hasAllAccess = ['manager', 'supervisor'].includes(employeeRole?.role?.toLowerCase?.())

    if (attendantStandUuids === null || hasAllAccess) {
      return Object.keys(state.stands.byId)
    } else {
      return attendantStandUuids ?? []
    }
  }
)
