import React from 'react'

import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import { styled, Switch } from '@material-ui/core'

const ELO_DEVICE_HEIGHT = 1400;

const VNBlueSwitch = ({ ...props }) => {
  const isMobileViewPort = useGetIsMobileScreen()
  const clientHeight = document?.body?.clientHeight 
  const switchThumbSize = isMobileViewPort ? '3.125vh' : clientHeight > ELO_DEVICE_HEIGHT ? '2.15vh' : '1.6vw'
  const switchMargin = isMobileViewPort ? '0.313vh' : 0

  const baseStyle = (theme) => ({
    width: "3.125vw",
    height: "3.056vh",
    padding: 4,
    '& .MuiSwitch-switchBase': {
      margin: switchMargin,
      padding: 0,
      transitionDuration: '3000ms',
      transform: 'translateX(2.5px)',
      '&.Mui-checked': {
        transform: isMobileViewPort ? 'translateX(16px)' : clientHeight > ELO_DEVICE_HEIGHT ? 'translateX(3.4vw)' : 'translateX(1.3vw)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#000000',
          opacity: 1,
          border: 0,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: "#2253FF",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: "#999999",
      width: switchThumbSize,
      height: switchThumbSize,
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      borderRadius: 26,
      transition: theme.transitions.create(['background-color'], {
        duration: 2000,
      }),
    },
  })

  if(isMobileViewPort) {
    const BlueSwitch = styled(Switch)(({theme}) => ({
      ...baseStyle(theme),
      width: "5.938vh",
      height: "3.75vh",
    }))

    return <BlueSwitch {...props} />
  }

  if (clientHeight > ELO_DEVICE_HEIGHT) {
    const BlueSwitch = styled(Switch)(({theme}) => ({
      ...baseStyle(theme),
      width: "7.4vw",
      height: "2.2vh",
    }))

    return <BlueSwitch {...props} />
  }

  const BlueSwitch = styled(Switch)(({ theme }) => ({
    ...baseStyle(theme),
  }))
 
  return <BlueSwitch {...props} />

}

export default VNBlueSwitch