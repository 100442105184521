export const APPLICATION_STRINGS = {
    PLEASE_LOGIN : 'Please login before placing an order.',
    ORDER_ONE_ITEM : 'Orders must have at least one associated item',
    EMAIL_SENT : "Email Sent!",
    EMAIL_FAILED : "Email failed to send: ",
    TEXT_SENT : "Text Sent!",
    TEXT_FAILED : "Text failed to send: ",
    SAF_ERROR : "There was an issue syncing offline transactions. Please try again later.",
    TAB_STARTED : "Success! New tab opened.",
    CLOSE_TAB_SUCCEEDED: "Success! Tab Closed.",
    CLOSE_TAB_FAILED: "Error! There was an error closing the tab.",
    VOID_PAYMENT_FAILED: "Error! There was an error voiding payment.",
    TAB_UPDATED: "Success! Tab has been updated.",
    TAB_UPDATE_FAILED: "Error! There was an error adding items to the tab.",
    REFUND_TABBED_ORDER_SUCCEEDED: "Success! Items voided.",
    REFUND_TABBED_ORDER_FAILED: "Error! There was an error voiding order items.",
    REFUND_TABBED_ORDER_CANCELLED: "Order has been cancelled.",
    PROMOTIONS_NOT_APPLIED: "Not all promotions were applied",
    DISCOUNTS_APPLIED: "Discounts applied.",
    EMAIL_BUTTON_LONG_TEXT: "Email Receipt",
    TEXT_BUTTON_LONG_TEXT: "Text Receipt",
    EMAIL_BUTTON_SHORT_TEXT: "Email",
    TEXT_BUTTON_SHORT_TEXT: "Text",
    PRINT_BUTTON_LONG_TEXT: "Print Receipt",
    PRINT_BUTTON_SHORT_TEXT: "Print",
    NEXT_BUTTON_NO_BALANCE: "I'm Done",
    NEXT_BUTTON_BALANCE: "Continue to next tender",
    NETWORK_PRINTER_ERROR_TITLE: "Network Printer Error",
    ORDER_MUST_BE_SYNCED_BEFORE_PRINTING: "Order must be synced before printing to the selected receipt printer.",
    KITCHEN_PRINTER_ERROR_TITLE: "Kitchen Printer Error",
    OFFLINE_KITCHEN_PRINTER_ERROR: "This device is currently offline. Your order will not be sent to the kitchen.",
    OK_BUTTON_TEXT: "OK",
}
