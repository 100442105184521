import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { some, partition, filter, isEmpty } from 'lodash'

import Remote from '../remote'

import { OFFLINE_SAF_MODE } from '../hooks/useSafMode'
import useSafMode from '../hooks/useSafMode'

import { addPromotionsToOrder } from '../actions/promotion'
import { clearRemoteOrderTotal, fetchRemoteOrderTotalFailed, fetchRemoteOrderTotalSucceeded } from '../actions/orderTotalRemote'

import { getCartItems } from '../selectors/cart'
import { getCurrentMenu, getIsKiosk } from '../selectors/menus'
import { getSelectedPromotions } from '../selectors/promotion'
import { getRemoteOrderTotal } from '../selectors/orderTotalRemote'
import { getOrderInProgress } from '../selectors/order'

import { getNetworkAvailableValue } from '../utils/networkConnected'
import { centsToDollars } from '../utils/formatters'
import { createOrderParams, makeLineItemData, parseModifiers } from '../utils/orderUtils'
import createDataDogLog from '../utils/datadog'
import { formatOrderTotals } from '../utils/orderTotalUtils'
import { incrementNextOrderNumber } from '../utils/orderNumbers'
import { ToastManager } from '../utils/toastManager'

import { APPLICATION_STRINGS } from '../strings'

const FORCE_CONTINUE_IN_MS = 5_000

export const formatItems = (items, pMakeLineItemData = true) => {

  let itemModels

  if (pMakeLineItemData) {
    itemModels = makeLineItemData(items) ?? []
  } else {
    itemModels = items
  }

  const orderMenuItems = itemModels.map((lineItem) => {
    return {
      menuItemUuid: lineItem?.menuItemUuid ?? lineItem?.id ?? '',
      quantity: lineItem.quantity,
      modifiers: parseModifiers(lineItem.modifiers, 1),
      price: centsToDollars(lineItem.priceInCents).slice(1),
      priceInCents: lineItem.priceInCents,
    }
  })

  return orderMenuItems
}

const useRemoteOrderTotal = () => {
  return useRemoteOrderTotalWithCallbacks(null, null, null)
}

export const useRemoteOrderTotalWithCallbacks = (cardReaderCallback, originalTotal, tipAmountInCents) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [data, setData] = useState()

  const cartItems = useSelector(getCartItems)
  const orderInProgress = useSelector(getOrderInProgress)
  const menu = useSelector(getCurrentMenu)
  const menuUuid = createOrderParams(orderInProgress)?.standMenuUuid ?? menu?.uuid

  const [updateSafMode] = useSafMode()

  let promotions = useSelector(getSelectedPromotions)

  let previousRemoteOrderTotal = useSelector(getRemoteOrderTotal)
  let manualPromotions = filter(promotions, (promotion) => promotion.redemptionMethod !== 'auto')

  if (isEmpty(manualPromotions)) {
    manualPromotions = undefined
  }

  const dispatch = useDispatch()
  const isKiosk = useSelector(getIsKiosk)

  const handleResult = (result) => {
    const [applied, unApplied] = partition(result?.data?.promotions, (promotion) => promotion.applied || promotion.applies)

    if (!isEmpty(unApplied) && some(unApplied, (promotion) => promotion.redemptionMethod === 'manual')) {
      ToastManager.error(APPLICATION_STRINGS.PROMOTIONS_NOT_APPLIED)
    } else {
      if (applied.some(promotion => promotion.redemptionMethod === 'manual')) {
        ToastManager.success(APPLICATION_STRINGS.DISCOUNTS_APPLIED)
      }
    }

    dispatch(addPromotionsToOrder({ selectedPromotions: applied }))
    dispatch(fetchRemoteOrderTotalSucceeded({ result }))

    //TODO (JEFF) refactor the execution of formatting order totals in a way that makes sense across the code base
    setData(formatOrderTotals(result.data))
    setLoading(false)
    setSuccess(true)
  }

  const getGrandTotal = (totalInCents, tipAmountInCents) => {
    return ((totalInCents + tipAmountInCents) / 100).toFixed(2)
  }

  const resetOrderTotals = () => {
    // Clearing order totals is a sign of a new order, so we should increment the order number.
    incrementNextOrderNumber()

    setLoading(false)
    setSuccess(false)
    setFailed(false)
    setData({})
    dispatch(clearRemoteOrderTotal())
  }

  const executeRequest = (reTotaling, affiliations, userUuid = undefined) => {
    const orderMenuItems = formatItems(orderInProgress?.itemModels ?? cartItems, isEmpty(orderInProgress))

    if (createOrderParams(orderInProgress)?.promotions && reTotaling) {
      promotions = createOrderParams(orderInProgress)?.promotions
      manualPromotions = filter(promotions, (promotion) => promotion.redemptionMethod !== 'auto')

      if (isEmpty(manualPromotions)) {
        manualPromotions = undefined
      }
    }

    setLoading(true)
    setSuccess(false)
    setFailed(false)

    const failureCardReaderCallback = () => {
      if (!cardReaderCallback) return

      cardReaderCallback(getGrandTotal(originalTotal, tipAmountInCents))
    }

    const getTotalPromise = new Promise((resolve, reject) => {
      if (previousRemoteOrderTotal.isAvailable && !reTotaling) {
        setData(previousRemoteOrderTotal)
        setLoading(false)
        setSuccess(true)
        resolve(previousRemoteOrderTotal)

        return
      }
      const cancelToken = axios.CancelToken.source()
      const configOverride = { cancelToken: cancelToken?.token ?? '' }

      // This needs to be local because of closure weirdness.
      let totalsComplete = false

      //if offline or empty cart items, abort request and set status to fail
      if (!getNetworkAvailableValue() || isEmpty(orderMenuItems)) {
        setLoading(false)
        setSuccess(false)
        setFailed(true)

        return reject()
      }

      const timeoutCallback = () => {
        // If loading is still in progress, reject this promise.
        if (totalsComplete) return

        setLoading(false)
        setSuccess(false)
        setFailed(true)

        if (cancelToken) cancelToken.cancel()

        failureCardReaderCallback()
        reject()
      }

      // If no manual promotions the timeout is around 5 seconds
      // With manual promotions the timeout is around 10 seconds.
      setTimeout(timeoutCallback, manualPromotions ? FORCE_CONTINUE_IN_MS * 2 : FORCE_CONTINUE_IN_MS)

      Remote.getRemoteOrderTotal(orderMenuItems, menuUuid, undefined, configOverride, isKiosk,
          affiliations, userUuid).then((result) => {
        const shouldForceOfflineMode = result?.data?.shouldForceOfflineMode

        if (shouldForceOfflineMode) {
          updateSafMode(OFFLINE_SAF_MODE)
        }

        if (!manualPromotions) {
          handleResult(result)
          totalsComplete = true
          resolve(result.data)

          return
        }

        // TODO(mkramerl): This is problematic since the timer above can probably cancel this even
        // though this is a double-call and might take longer.
        Remote.getRemoteOrderTotal(orderMenuItems, menuUuid, [...manualPromotions,
          ...(result?.data?.promotions ?? [])], configOverride, isKiosk, affiliations, userUuid).then((result) => {
          handleResult(result)
          totalsComplete = true
          resolve(result.data)
        }).catch((err) => {
          totalsComplete = true
          reject(err)
        })
      }).catch((err) => {
        createDataDogLog('error', 'Remote Order Total Failed', {
          err,
          items: orderMenuItems,
          menuUuid,
          promotions,
          configOverride,
          isKiosk
        })

        dispatch(fetchRemoteOrderTotalFailed(err))

        setLoading(false)
        setSuccess(false)
        setFailed(true)

        totalsComplete = true
        reject(err)
      })
    }).then((data) => {
      if (!cardReaderCallback) return

      cardReaderCallback(getGrandTotal(data?.totalAmountInCents ?? originalTotal, tipAmountInCents))
    }).catch((err) => {
      failureCardReaderCallback()
    })

    return getTotalPromise
  }

  return [executeRequest, { data, loading, success, failed }, resetOrderTotals]
}

export default useRemoteOrderTotal
