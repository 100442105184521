/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { map, some, get, flatten, isNumber } from 'lodash'

import SidebarModal from './SidebarModal'
import Button from './Button'
import ModifierGroup from './ModifierGroup'

import useGetIsMobileScreen from '../hooks/useGetIsMobileScreen'
import quantityValidator from '../utils/quantityValidator'
import quantityUpdater from '../utils/quantityUpdater'
import { ReactComponent as PlusIcon } from '../assets/icons/plus-icon.svg'
import { NOTES_CHARACTER_LIMIT } from '../constants'

import styles from './CartItemDetailsModal.module.scss'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";

const textFieldStyles = makeStyles({
  root: {
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.MuiInputBase-formControl fieldset": {
        border: 0,
      },
      "&.Mui-focused fieldset": {
        border: 0,
      }
    }
  }
});

const CartItemDetailsModal = ({
  cartItemNotValidIfClosed,
  dispatch, 
  orderMaxAmount, 
  orderMinCount, 
  id, 
  title, 
  price, 
  quantity, 
  canEnterNotes, 
  notes, 
  notesAreEmpty, 
  modifierGroups, 
  selectedItemIds, 
  onClose, 
  onRemove, 
  className, 
  addToCart, 
  subtractFromCart, 
  updateModifiers, 
  updateNotes,
  updateCartItemQuantity,
  isKeyboardActive,
  ...props }) => {
  const classNames = cn(styles.cartItemDetailsModal, className)
  const classes = textFieldStyles()
  const isMobile = useGetIsMobileScreen()
  const [modifierGroupsSelections, setModifierGroupsSelections] = useState(selectedItemIds)
  const [validators, setValidators] = useState(map(modifierGroups, ({ min, max }) => quantityValidator(min, max)))
  const [isValid, setIsValid] = useState(false)
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  const isMaxQuantity = quantity === orderMaxAmount
  const isMinQuantity = quantity === orderMinCount || quantity === 1
  const [isQuantityUpdating, setIsQuantityUpdating] = useState(false)
  const [inputValue, setInputValue] = useState(quantity)
  const [inputPlaceholder, setInputPlaceholder] = useState(quantity)
  const clientHeight = document?.body?.clientHeight

  const onSelect = (itemId, index) => {
    const modifierGroup = modifierGroups[index]
    const { min, max } = modifierGroup
    const selectedModifiers = modifierGroupsSelections[index]

    const nextSelectedModifiers = [...modifierGroupsSelections]
    nextSelectedModifiers[index] = quantityUpdater(min, max, selectedModifiers, itemId)

    setModifierGroupsSelections(nextSelectedModifiers)
  }

  useEffect (() => {
    setInputPlaceholder(quantity)
  }, [quantity])

  useEffect(() => {
    const validations = map(validators, (validator, index) => {
      const modifierGroupSelections = modifierGroupsSelections[index]

      return validator.isValid(get(modifierGroupSelections, 'length', 0))
    })

    const isValid = !some(validations, (valid) => valid === false)
    setIsValid(isValid)

  }, modifierGroupsSelections)

  useEffect(() => {
    setValidators(map(modifierGroups, ({ min, max }) => quantityValidator(min, max)))
    setModifierGroupsSelections(selectedItemIds)
  }, [modifierGroups])

  let notesElement = null

  const hasCharacterMax = isNumber(NOTES_CHARACTER_LIMIT)

  const handleSetNotes = (event) => {
    const notes = get(event, 'target.value', '')
    let trimmedNotes = notes

    if (hasCharacterMax) {
      trimmedNotes = notes.substring(0, NOTES_CHARACTER_LIMIT)
    }
    updateNotes(trimmedNotes)
  }

  const onFocus = () => { 
    if(!isQuantityUpdating) {
      setIsKeyboardOpen(true) 
    }
  }
  const onBlur = () => { setIsKeyboardOpen(false) }

  if (canEnterNotes) {
    notesElement = (
      <div className={cn(styles.notesContainer, { [styles.emptyNotes]: notesAreEmpty })}>
        <TextField id="special-instructions" label="Add Special Instructions"
          value={notes}
          onChange={handleSetNotes}
          className={cn(styles.notes, classes.root)}
          InputLabelProps={{className: styles.textfield__label}}
          inputProps={{'data-allow-keyboard-activity': true}}
          InputProps={{className: styles.textfield__input}}
          variant='outlined'
          multiline
          onFocus={onFocus}
          onBlur={onBlur}
          rows={5} />
      </div>
    )
  }

  const incrementQuantity = () => {
    const newValue = inputValue === orderMaxAmount ? orderMaxAmount :  inputValue + 1 
    setInputValue(newValue)
  }

  const decrementQuantity = () => {
    const newValue = inputValue === orderMinCount ? orderMinCount : inputValue - 1 
    setInputValue(newValue)
  }

  const handleInput = (e) => {
    const input = get(e, 'target.value', '')
    setInputValue(input)
  }

  const handleSetQuantity = (inputValue) => {
    let newValue = inputValue
    if (!inputValue || inputValue < orderMinCount) {
      newValue = orderMinCount
    } 
    if (inputValue > orderMaxAmount) {
      newValue = orderMaxAmount
    }
    setInputValue(newValue)
  }

  const handleUpdateClick = (e) => {
    updateCartItemQuantity(inputValue)
    updateModifiers(flatten(modifierGroupsSelections))
    onClose(e)
  }

  return (
    <SidebarModal show title="Item Detail" onClose={() => onClose(cartItemNotValidIfClosed ? false : isValid, id)} className={cn(classNames, styles.header)} {...props} >
      <div className={styles.cartItemDetailsModal}>
        <div className={styles.scrollable} style={isKeyboardOpen ? {height: "80vh"} : null}>
        <div className={styles.header}>
          <div className={styles.itemName}>{title}</div>
          <div className={styles.price}>{price}</div>
        </div>
        {modifierGroups.map((modifierGroup, index) => {
          if(!modifierGroup.name) {
            return null
          }
          return (
            <ModifierGroup
              key={index}
              className={styles.modifierGroup}
              title={modifierGroup.name}
              label={get(validators, `[${index}].label`)}
              items={modifierGroup.items}
              selectedItemIds={modifierGroupsSelections[index]}
              isRequired={get(validators, `[${index}].validationType`) === quantityValidator.types.required}
              min={modifierGroup.min}
              max={modifierGroup.max}
              onSelect={(itemId) => onSelect(itemId, index)}
              uuid={modifierGroup.uuid}
            />
          )
        })}
        {notesElement}
        </div>
        {(!isKeyboardActive || isQuantityUpdating) &&  
          <div className={styles.footer} style={isMobile && isQuantityUpdating && (clientHeight > 800) ? {top: "48vh"} : null}>
            <div className={styles.updateQuantity}>
              <span className={isMinQuantity ? cn(styles.disabled, styles.minus) : cn(styles.quantityOperator, styles.minus)} onClick={decrementQuantity}>–</span>
              <input
                type="number"
                className={styles.quantity}
                placeholder={inputPlaceholder}
                value={inputValue}
                onChange={handleInput}
                onFocus={() => {
                  setIsQuantityUpdating(true)
                  setInputPlaceholder('')
                }}
                onBlur={() => {
                  setIsQuantityUpdating(false)
                  handleSetQuantity(+inputValue)
                }}
                pattern="\d*"
              />
              <span className={isMaxQuantity ? cn(styles.disabled, styles.plus) : cn(styles.quantityOperator, styles.plus)} onClick={incrementQuantity}><PlusIcon /></span>
            </div>
            <div className={styles.update}>
              <Button className={styles.doneButton} disabled={!isValid} onClick={handleUpdateClick}><span>Update</span></Button>
            </div>
          </div>
        }
      </div>
    </SidebarModal>
  );
}

export default CartItemDetailsModal
