import React from 'react'

import { ManagerApprovalProvider } from './ManagerApprovalContext'
import { SpinnerProvider } from './SpinnerContext'
import ContextualComponents from './ContextualComponents'

const AppProviders = ({ children }) => {
  return (
    <ManagerApprovalProvider>
      <SpinnerProvider>
        <ContextualComponents/>
        {children}
      </SpinnerProvider>
    </ManagerApprovalProvider>
  )
}

export default AppProviders
