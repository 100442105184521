
export const REQUEST_REFUND = 'REQUEST_REFUND'
export const REQUEST_REFUND_SUCCEEDED = 'REQUEST_REFUND_SUCCEEDED'
export const REQUEST_REFUND_FAILED = 'REQUEST_REFUND_FAILED'
export const REQUEST_TABBED_REFUND = 'REQUEST_TABBED_REFUND'
export const REQUEST_TABBED_REFUND_OFFLINE = 'REQUEST_TABBED_REFUND_OFFLINE'
export const REQUEST_TABBED_REFUND_SUCCEEDED = 'REQUEST_TABBED_REFUND_SUCCEEDED'
export const REQUEST_TABBED_REFUND_FAILED = 'REQUEST_TABBED_REFUND_FAILED'
export const SYNC_REFUND_REQUESTED = 'SYNC_REFUND_REQUESTED'

export const requestRefund = ({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }) => ({
  type: REQUEST_REFUND,
  payload: {
    orderId,
    itemIndexes,
    itemIds,
    lineItemIds,
    reason,
    amountInCents,
  }
})

requestRefund.type = REQUEST_REFUND

export const requestRefundSucceeded = (orderId, result) => ({
  type: REQUEST_REFUND_SUCCEEDED,
  payload: {
    orderId,
    ...result,
  },
})

requestRefundSucceeded.type = REQUEST_REFUND_SUCCEEDED

export const requestRefundFailed = (orderId, result) => ({
  type: REQUEST_REFUND_FAILED,
  payload: {
    orderId,
    ...result,
  },
})

requestRefundFailed.type = REQUEST_REFUND_FAILED

export const requestTabbedRefund = ({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }) => ({
  type: REQUEST_TABBED_REFUND,
  payload: {
    orderId,
    itemIndexes,
    itemIds,
    lineItemIds,
    reason,
    amountInCents,
  }
})


export const requestTabbedRefundSucceeded = (orderId, result) => ({
  type: REQUEST_TABBED_REFUND_SUCCEEDED,
  payload: {
    orderId,
    ...result,
  },
})

export const requestTabbedRefundFailed = (orderId, result) => ({
  type: REQUEST_TABBED_REFUND_FAILED,
  payload: {
    orderId,
    ...result,
  },
})

export const requestTabbedRefundOffline = ({ orderId, itemIndexes, itemIds, lineItemIds, reason, amountInCents }) => ({
  type: REQUEST_TABBED_REFUND_OFFLINE,
  payload: {
    orderId,
    itemIndexes,
    itemIds,
    lineItemIds,
    reason,
    amountInCents,
  }
})

export const syncRefundRequested = (orderId) => ({
  type: SYNC_REFUND_REQUESTED,
  payload: { orderId },
})

syncRefundRequested.type = SYNC_REFUND_REQUESTED
