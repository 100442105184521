export const SAVE_RICH_CHECKOUT_ORDER_REQUESTED = 'SAVE_RICH_CHECKOUT_ORDER_REQUESTED'
export const SAVE_RICH_CHECKOUT_ORDER = 'SAVE_RICH_CHECKOUT_ORDER'

export const saveRichCheckoutOrderRequested = (payload) => ({
  type: SAVE_RICH_CHECKOUT_ORDER_REQUESTED,
  payload,
})

export const saveRichCheckoutOrder = (payload) => ({
  type: SAVE_RICH_CHECKOUT_ORDER,
  payload,
})
