const add_should_refesh_config = {
  0: (state) => {
    return {
      ...state,
      config: {
        ...state.config,
        shouldRefreshConfig: true,
      }
    }
  },
}

export default add_should_refesh_config
