import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isEmpty, head, find, findIndex, groupBy, map, sortBy, values } from 'lodash'
import Modal from 'react-modal'

import { PIN_CODE_CONFIGURATION_ACCESS } from '../../constants'

import MenuWithConfirmationModal from '../MenuWithConfirmationModal'
import Menu from '../Menu'
import CategoryItems from '../CategoryItems'
import Category from '../Menu/Category'
import Cart from '../Cart'
import NavMenu from '../../components/NavMenu'

import { getUniqCategoriesWithExistingItems } from '../../selectors/categories'
import { getSelectedMenusIds } from '../../selectors/config'
import { getCartItems } from '../../selectors/cart'
import { getCarouselImages } from '../../actions/carouselImages'
import { clearRemoteOrderTotal } from '../../actions/orderTotalRemote'
// import analytics from '../utils/analytics'
import UserIdleMonitor from '../utils/UserIdleMonitor'
import Slider from '../components/Slider'
import CartItemDetailsKiosk from '../CartItemDetailKiosk'
import { getItemsForCurrentMenu } from '../../selectors/items'
import { safelyUpdateCartItemQuantity, removeFromCart } from '../../actions/cart';
import { KEY_CODES } from '../utils/constants'
import { categoryMessage } from '../utils/messages'


export class NewOrder extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
  }

  state = {
    selectedCategoryId: null,
    categorySetFromMenu: false,
    modeName: 'Add to cart',
    isSidebarOpen: false,
    editedIndex: undefined,
    isSidebarEditMode: false,
    cartItemsSummary: [],
    menuClickedCount: 0,
    isPINModalOpen: false,
    isConfigurationMenuSidebarOpen: false,
    isPinCodeError: false,
    isItemWithModifierAddedToCart: false,
    voiceInstructions: ''
  }

  viewedItems = []

  constructor(props) {
    super(props)
    this.categoryRefs = {}
    this.categoryLinkRefs = {}
    this.mainSectionRefs = {}
    this.mainSections = {'title': 0, 'navigation': 1, 'cart': 2, 'exit': 3}
    this.categoriesListingRef = React.createRef()
    this.pinCodeInputRef = React.createRef()
    this.currentFocusedItemRef = null
    this.idleMonitor = new UserIdleMonitor(process.env.REACT_APP_DEV_IDLE_TIMEOUT || 60000)
    this.timer = 0
    this.menuClickDebounceTime = 30000
    this.menuClickCount = 7
  }
  
  componentDidMount() {
    this.initActiveCategory()
    document.title = 'New order'
    this.idleMonitor.addEventListeners()
    this.props.getCarouselImages()
    this.props.clearRemoteOrderTotal()

    setTimeout(() => this.mainSectionRefs[0]?.focus(), 0)
  }

  setRef = ref => this.currentFocusedItemRef = ref

  componentWillUnmount() {
    this.idleMonitor.removeEventListeners()
    clearTimeout(this.timer)
  }

  handleMenuClick = () => {
    this.setState({
      menuClickedCount: this.state.menuClickedCount + 1
    }, () => {
      if (this.state.menuClickedCount === this.menuClickCount) {
        this.setState({ isPINModalOpen: true})
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setState({ menuClickedCount: 0 })
      }, this.menuClickDebounceTime                                                                                                                                                                                                                                                                                                                       );
    })
  }

  closePINModal = () => {
    this.setState({
      isPINModalOpen: false,
      menuClickedCount: 0,
      isPinCodeError: false,
      pinCode: '',
    })
  }

  handleOpenConfigurationMenu = () => {
    if (this.state.pinCode === PIN_CODE_CONFIGURATION_ACCESS) {
      this.setState({
        isConfigurationMenuSidebarOpen: true,
        isPINModalOpen: false,
        pinCode: '',
      })
    } else {
      this.setState({
        pinCode: '',
        isPinCodeError: true
      })
    }
  }

  handleCloseConfigurationMenuSidebar = () => {
    this.setState({
      menuClickedCount: 0,
      isConfigurationMenuSidebarOpen: !this.state.isConfigurationMenuSidebarOpen
    })
  }

  handlePinCodeChange = e => {
    const { name, value } = e.target
    this.setState({[name]: value})
  }


  handleScroll = () => {
    if (this.state.categorySetFromMenu) {
      this.setState({ categorySetFromMenu: false })
      return
    }
    const categoriesList = this.categoriesListingRef.current.getBoundingClientRect()
    const category = find(this.props.categories, category => {
      const refDimensions = this.categoryRefs[category.itemCategoryUuid].getBoundingClientRect()
      return (
        refDimensions.top >= categoriesList.top ||
        (refDimensions.bottom - 50 >= categoriesList.top &&
          refDimensions.bottom - 50 <= categoriesList.bottom) ||
        (refDimensions.top < categoriesList.top && refDimensions.bottom > categoriesList.bottom)
      )
    })
    if (category && this.state.selectedCategoryId !== category.itemCategoryUuid) {
      this.setState({ selectedCategoryId: category.itemCategoryUuid })
      this.categoryLinkRefs[category.itemCategoryUuid].scrollIntoView()
    }
  }

  componentDidUpdate() {
    this.initActiveCategory()
  }

  initActiveCategory = () => {
    const { categories } = this.props
    if (!this.state.selectedCategoryId && !isEmpty(categories)) {
      this.setState({ selectedCategoryId: head(categories).itemCategoryUuid })
    }
  }

  onSelectCategory = category => {
    // analytics.categoryClickedEvent(category.name)
    this.setState({ selectedCategoryId: category.itemCategoryUuid, categorySetFromMenu: true })
    this.setRef(this.categoryRefs[category.itemCategoryUuid])
    this.categoryRefs[category.itemCategoryUuid].scrollIntoView()
    this.categoryRefs[category.itemCategoryUuid].focus()
  }

  backToMenu = () => {
    this.categoryLinkRefs[this.props.categories[0].itemCategoryUuid].focus()
  }

  backToMainSection = () => {
    this.mainSectionRefs[0].focus()
  }


  setVoiceInstructions = (voiceInstructions) => {
    this.setState({ voiceInstructions })
  }

  mainSectionKeyPress = (event, index) => {
    if (event.keyCode === KEY_CODES.UP) {
      this.handlePreviousSection(index);
      this.setState({ voiceInstructions: '' })
    } else if (event.keyCode === KEY_CODES.DOWN) {
      this.handleNextSection(index);
      this.setState({ voiceInstructions: '' })
    } else if (event.keyCode === KEY_CODES.ENTER) {
      switch(index) {
        case this.mainSections.navigation:
          this.categoryLinkRefs[this.props.categories[0].itemCategoryUuid].focus()
          break;
        case this.mainSections.cart:
          break
        default:
          break
      }
    }
  }

  handlePreviousSection = (index) => {
    if (index === 0) {
      this.mainSectionRefs[Object.keys(this.mainSectionRefs).length -1].focus()
    } else {
      this.mainSectionRefs[index - 1].focus()
    }
  }

  handleNextSection = (index) => {
    if (index === Object.keys(this.mainSectionRefs).length - 1) {
      this.mainSectionRefs[0].focus()
    } else {
      this.mainSectionRefs[index + 1].focus()
    }
  }

  tabHandleArrow = (event, category, index) => {
    event.stopPropagation()
    if(event.keyCode === KEY_CODES.LEFT) {
      this.handlePreviousTab(index)
    } else if (event.keyCode === KEY_CODES.RIGHT) {
      this.handleNextTab(index);
    } else if (event.keyCode === KEY_CODES.ENTER) {
      this.onSelectCategory(category)
    } else if (event.keyCode === KEY_CODES.DOWN) {
      this.handleNextSection(this.mainSections.navigation)
    } else if (event.keyCode === KEY_CODES.UP) {
      this.handlePreviousSection(this.mainSections.navigation)
    }
  }

  handlePreviousTab = (index) => {
    const { categories } = this.props;
    let itemToFocus;
    if (index === 0) {
      itemToFocus = categories[categories.length -1]
    } else {
      itemToFocus = categories[index-1]
    }
    this.categoryLinkRefs[itemToFocus.itemCategoryUuid].focus()
  }

  handleNextTab = (index) => {
    const { categories } = this.props;
    let itemToFocus;
    if (index === categories.length -1) {
      itemToFocus = categories[0]
    } else {
      itemToFocus = categories[index+1]
    }
    this.categoryLinkRefs[itemToFocus.itemCategoryUuid].focus()
  }

  // sendAnalytics = () => {
  //   analytics.itemsViewedEvent(this.viewedItems)
  //   this.viewedItems = []
  // }

  // if this gets added back, need to import debounce from lodash
  // sendDataToAnalytics = debounce(this.sendAnalytics, 1500)

  handleViewportItemEnter = item => {
    // this.sendDataToAnalytics()
    this.viewedItems.push(item)
  }

  renderMenu = () => {
    const { selectedMenuIds, cartItems } = this.props
    const displayConfirmationModal = (selectedMenuIds.length > 1) && !isEmpty(cartItems)
    const redirectTo = selectedMenuIds.length > 1
      ? '/kiosk/menu_select'
      : '/kiosk/attract_loop'

    return displayConfirmationModal
      ? <MenuWithConfirmationModal
          cartItems={cartItems}
          handleMenuClick={this.handleMenuClick}
          redirectTo={redirectTo}
          refPropTitle={node => {
            this.mainSectionRefs[this.mainSections.title] = node
          }}
          refPropExit = {node => {
            this.mainSectionRefs[this.mainSections.exit] = node
          }}
          onTitleKeyPress={(event) => this.mainSectionKeyPress(event, this.mainSections.title)}
          onExitKeyPress={(event) => this.mainSectionKeyPress(event, this.mainSections.exit)}
        />
      : <Menu
          handleMenuClick={this.handleMenuClick}
          redirectTo={redirectTo}
          refPropTitle={node => {
            this.mainSectionRefs[this.mainSections.title] = node
          }}
          refPropExit = {node => {
            this.mainSectionRefs[this.mainSections.exit] = node
          }}
          onTitleKeyPress={(event) => this.mainSectionKeyPress(event, this.mainSections.title)}
          onExitKeyPress={(event) => this.mainSectionKeyPress(event, this.mainSections.exit)}
        />
  }

  toggleSidebar = isOpen => this.setState({isSidebarOpen: isOpen, isSidebarEditMode: false})

  editItem = (itemId, index) => {
    const modifierGroups = get(this.props.items, ['byId', itemId, 'modifierGroups'])

    if (!isEmpty(modifierGroups)) {
      this.setState({
        editedIndex: index,
        isSidebarOpen: true,
        isSidebarEditMode: true,
      })
    }
  }

  resetEditedIndex = () => this.setState({editedIndex: undefined})

  setModifiersMode = modeName => this.setState({sidebarMode: modeName})

  _generateGroupKeyForCartItem = cartItem => {
    const { itemId, modifiers } = cartItem
    const sortedModifiers = sortBy(modifiers, 'id')
    return sortedModifiers.reduce((result, modifierObject) => (result + get(modifierObject, 'id', '')), itemId)
  }

  mergeQuantityWithIdenticalItemInCart = () => {
    const { cartItems, removeFromCart, safelyUpdateCartItemQuantity } = this.props
    const identicalItemsGroups = map(groupBy(cartItems, this._generateGroupKeyForCartItem), group => values(group))

    identicalItemsGroups.forEach(itemsGroup => {
      if (itemsGroup.length > 1) {
        const [firstItem, ...itemsWithoutFirstItem] = itemsGroup
        const firstItemKey = this._generateGroupKeyForCartItem(firstItem)
        const firstItemQuantity = get(firstItem, 'quantity', 0)
        const firstItemIndex = findIndex(cartItems, cartItem => this._generateGroupKeyForCartItem(cartItem) === firstItemKey)
        itemsWithoutFirstItem.forEach(item => {
          const currentItemQuantity = get(item, 'quantity', 0)
          const currentItemIndex = findIndex(
            cartItems,
            cartItem => this._generateGroupKeyForCartItem(cartItem) === firstItemKey,
            firstItemIndex + 1,
          )
          removeFromCart({ index: currentItemIndex })
          safelyUpdateCartItemQuantity({
            index: firstItemIndex,
            quantity: firstItemQuantity + currentItemQuantity,
          })
        })
      }
    })
  }

  setItemsSummary = cartItemsSummary => {
    this.setState({ cartItemsSummary })
  }

  setIsItemWithModifierAddedToCart = isAdded => this.setState({ isItemWithModifierAddedToCart: isAdded })

  render() {
    const { categories } = this.props
    const {
      selectedCategoryId,
      editedIndex,
      isSidebarOpen,
      isSidebarEditMode,
      isPINModalOpen,
      isConfigurationMenuSidebarOpen,
      isPinCodeError,
      pinCode,
      isItemWithModifierAddedToCart,
      voiceInstructions
    } = this.state

    const isMobileView = window.innerWidth <= 1080
    return (
      <>
        {isConfigurationMenuSidebarOpen && <NavMenu onToggle={this.handleCloseConfigurationMenuSidebar} />}
        <CartItemDetailsKiosk
          setIsItemWithModifierAddedToCart={this.setIsItemWithModifierAddedToCart}
          mergeQuantityWithIdenticalItemInCart={this.mergeQuantityWithIdenticalItemInCart}
          resetEditedIndex={this.resetEditedIndex}
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={this.toggleSidebar}
          cartItemIndex={editedIndex}
          isSidebarEditMode={isSidebarEditMode}
          backToMainSection={this.backToMainSection}
          currentFocusedItemRef={this.currentFocusedItemRef}
        />
        <div className="order">
          <div className={isMobileView ? 'order__main-mobile' : 'order__main'}>
            {this.renderMenu()}
            <div
              className="menu"
              ref={node => this.mainSectionRefs[this.mainSections.navigation] = node}
              tabIndex={0}
              aria-label="menu"
              onKeyDown={(event) => this.mainSectionKeyPress(event, this.mainSections.navigation)}
            >
              <Slider>
                {categories.map((category, index) => (
                  <Category
                    category={category}
                    isActive={selectedCategoryId === category.itemCategoryUuid}
                    isSidebarOpen={isSidebarOpen}
                    key={category.itemCategoryUuid}
                    tabHandleArrowPress={(event) => this.tabHandleArrow(event, category, index)}
                    onSelect={() => this.onSelectCategory(category)}
                    refProp={node => {
                      this.categoryLinkRefs[category.itemCategoryUuid] = node
                    }}
                  />
                ))}
              </Slider>
            </div>
            <div
              className="categories"
              onScroll={this.handleScroll}
              ref={this.categoriesListingRef}
              aria-label={categoryMessage}
              tabIndex={0}
            >
              {categories.map(category => (
                <CategoryItems
                  isItemWithModifierAddedToCart={isItemWithModifierAddedToCart}
                  setRef={this.setRef}
                  setItemsSummary={this.setItemsSummary}
                  setIsItemWithModifierAddedToCart={this.setIsItemWithModifierAddedToCart}
                  category={category}
                  toggleSidebar={this.toggleSidebar}
                  key={category.itemCategoryUuid}
                  onViewportItemEnter={this.handleViewportItemEnter}
                  refProp={node => {
                    this.categoryRefs[category.itemCategoryUuid] = node
                  }}
                  isMobileView={isMobileView}
                  scrollableAncestorRef={this.categoriesListingRef ?? {}}
                  handleBackToCategories={this.backToMenu}
                  onAddedToCart={this.onAddedToCart}
                  onRemovedFromCart={this.onRemovedFromCart}
                  handleMainSectionKeyPress={this.mainSectionKeyPress}
                  isSidebarOpen={isSidebarOpen}
                  voiceInstructions={voiceInstructions}
                  setVoiceInstructions={this.setVoiceInstructions}
                />
              ))}
            </div>
          </div>
          <Cart
            isSidebarOpen={isSidebarOpen}
            setItemsSummary={this.setItemsSummary}
            cartItemsSummary={this.state.cartItemsSummary}
            onItemClick={this.editItem}
            refProp={node => {
              this.mainSectionRefs[this.mainSections.cart] = node
            }}
            mainSectionKeyPress={(e) => this.mainSectionKeyPress(e, this.mainSections.cart)}
            handleBackToSection={this.backToMainSection}
            voiceInstructions={voiceInstructions}
            setVoiceInstructions={this.setVoiceInstructions}
          />
        </div>
        <Modal className="modal" overlayClassName="overlay" isOpen={isPINModalOpen}>
          <p className="modal__copy -center">
            Enter your PIN
          </p>
          <input
            autoFocus
            className="modal__input"
            ref={this.pinCodeInputRef}
            name="pinCode"
            value={pinCode}
            onChange={this.handlePinCodeChange}
            type="password"
          />
          {isPinCodeError && <p className="modal__error-msg">PIN is not valid</p>}
          <div className="modal__actions">
            <button className="btn -outline" type="button" onClick={this.closePINModal}>Cancel</button>
            <button className="btn -primary" type="button" onClick={this.handleOpenConfigurationMenu}>Proceed</button>
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  items: getItemsForCurrentMenu(state),
  categories: getUniqCategoriesWithExistingItems(state),
  selectedMenuIds: getSelectedMenusIds(state),
  cartItems: getCartItems(state),
})

const mapDispatchToProps = {
  getCarouselImages,
  safelyUpdateCartItemQuantity,
  removeFromCart,
  clearRemoteOrderTotal,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder)
