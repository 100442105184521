import * as ActionTypes from './ActionTypes'

/**
 * It takes a boolean because if in offline mode the stands cannot be switched so no need to store the uuid.
 * @param {Boolean} isActive - A boolean to show whether the current stand has location picker active or not.
 */
export function setIsLocationPickerActive(isActive) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.IS_LOCATION_PICKER_ACTIVE,
      isActive
    })
  }
}
