import Environment from './environment'
import PeripheralBridge from './peripheralBridge'

export const showFakeCreditCard = () => {
  const bridge = PeripheralBridge.getBridge()

  return Environment.app.isDevelopment && !bridge
}

export const fakeCreditCardResponse = (uuid, amount, transType = "sale") => {
  return {
    amount: amount,
    authResponseData: {
      authorizationCode: "123123",
      processorTransactionId: "123123123",
      reconciliationId: "123123123"
    },
    cardIssuer: "AMEX",
    cardType: "CREDIT",
    cardholderName: "VALUED CUSTOMER           ",
    invoiceNumber: "1231231231",
    merchantReferenceCode: uuid,
    reasonCode: "100",
    receipt: "↵↵***************** SALE *****************",
    receiptData: {
      approvalCode: "123123",
      authStatus: "ONLINE_APPROVAL",
      maskedCardNumber: "***********1234",
      maskedPan: "123400000001234"
    },
    requestId: "01Z12345123451234512345123451234",
    status: "ACCEPT",
    storeId: "abc123",
    terminalId: "abc123",
    transType: transType,
    uuid: uuid,
  }
}

export default fakeCreditCardResponse
