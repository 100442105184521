import { call, put, takeLatest } from 'redux-saga/effects'
import { filter } from 'lodash'
import {
  FETCH_EMPLOYEE_ROLES,
  fetchEmployeeRolesSucceeded,
  fetchEmployeeRolesFailed,
} from '../actions/employeeRole'

import Remote from '../remote'

export function* fetchEmployeeRolesSaga() {
  try {
    const result = yield call(Remote.getEmployeeRoles)
    const managerIds = filter(result?.data?.employeeRoles, ['role', 'manager']).map((role) => role.employeeId)

    yield put(fetchEmployeeRolesSucceeded(managerIds))
  } catch (err) {
    yield put(fetchEmployeeRolesFailed(err))
  }
}

export function* watchFetchEmployeeRolesSaga() {
  yield takeLatest(FETCH_EMPLOYEE_ROLES, fetchEmployeeRolesSaga)
}