import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

import { formatPrice } from '../utils/formatters'
import sumUpModifiers from '../../utils/sumUpPrice'

import IconAdd from '../components/icons/IconAdd'
import IconMinus from '../components/icons/IconMinus'
import IconRemove from '../components/icons/IconRemove'
import AlcoholWarningModal from '../components/AlcoholWarningModal'
import { clickToDecrement, clickToIncrement, clickToRemoveFromCart, clickToDecrementLastItem } from '../utils/messages'
// import analytics from '../utils/analytics'

export class CartItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    incrementQuantityForItem: PropTypes.func.isRequired,
    decrementQuantityForItem: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    alcoholLimitExceeded: PropTypes.bool.isRequired,
    confirmAlcoholWarning: PropTypes.func.isRequired,
    cart: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    refProp: PropTypes.func,
  }

  state = {
    showAlcoholWarningModal: false,
  }

  _updateQuantity = modifier => {
    const {
      item: { quantity },
      removeFromCart,
      incrementQuantityForItem,
      decrementQuantityForItem,
      alcoholLimitExceeded,
      setItemsSummary
    } = this.props
    const newQuantity = quantity + modifier

    setItemsSummary([])
    if (modifier >= 1 && alcoholLimitExceeded) {
      this.setState({ showAlcoholWarningModal: true })
    }
    if (newQuantity <= 0) {
      removeFromCart()
    } else if (modifier > 0) {
      incrementQuantityForItem()
    } else if (modifier < 0) {
      decrementQuantityForItem()
    }
  }

  _removeItem = () => {
    const { removeFromCart, setItemsSummary } = this.props
    removeFromCart()
    setItemsSummary([])
    // const { cart } = this.props
    // const cartItem = cart.find(cartItem => cartItem.id === item.id)
    // analytics.removeFromCartEvent(cartItem, cartItem.quantity)
  }

  _confirmAlcoholWarning = () => {
    this.setState({ showAlcoholWarningModal: false })
    this.props.confirmAlcoholWarning()
  }

  render() {
    const {
      item: {
        name,
        quantity,
        priceInCents,
        modifiersString,
        modifiers
      },
      item,
      onItemClick,
      refProp,
    } = this.props

    const quantityMax = get(item, 'orderMaxAmount', 999)
    const quantityMin = get(item, 'orderMinCount', 1)

    return (
      <React.Fragment>
        <AlcoholWarningModal
          cancel={this._confirmAlcoholWarning}
          isOpen={this.state.showAlcoholWarningModal}
        />
        <div className="cart__item">
          <button aria-label={clickToRemoveFromCart(item)} className="cart__btn -remove" onClick={this._removeItem} ref={(node) => refProp(node, 0)}>
            <IconRemove />
          </button>
          <div onClick={onItemClick}>
            <div  className="cart__itemname">{name}</div>
            {!isEmpty(modifiersString) && <span>{modifiersString}</span>}
            <div className="cart__price">{formatPrice((priceInCents + sumUpModifiers(modifiers)) * quantity || 0)}</div>
          </div>
          <div className="cart__amount">
            <button
              className={quantity === quantityMin || quantity === 1 ? "cart__btn -quantityDisabled" : "cart__btn -quantityEnabled"}
              disabled={quantity === quantityMin || quantity === 1}
              aria-label={quantity === 1 ? clickToDecrementLastItem(name) : clickToDecrement(name)}
              onClick={() => this._updateQuantity(-1)}
              ref={(node) => refProp(node, 1)}
            >
              <IconMinus />
            </button>
            <div className="cart__quantity">{quantity}</div>
            <button
              className={quantity === quantityMax ? "cart__btn -quantityDisabled" : "cart__btn -quantityEnabled"}
              disabled={quantity === quantityMax}
              aria-label={clickToIncrement(name)}
              onClick={() => this._updateQuantity(1)}
              ref={(node) => refProp(node, 2)}
            >
              <IconAdd />
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CartItem
