export const REMOTE_ORDER_TOTAL_REQUESTED = 'REMOTE_ORDER_TOTAL_REQUESTED'
export const REMOTE_ORDER_TOTAL_SUCCEEDED = 'REMOTE_ORDER_TOTAL_SUCCEEDED'
export const REMOTE_ORDER_TOTAL_FAILED = 'REMOTE_ORDER_TOTAL_FAILED'

export const CLEAR_REMOTE_ORDER_TOTAL = 'CLEAR_REMOTE_ORDER_TOTAL'

export const fetchRemoteOrderTotal = (payload) => ({
  type: REMOTE_ORDER_TOTAL_REQUESTED,
  payload
})

fetchRemoteOrderTotal.type = REMOTE_ORDER_TOTAL_REQUESTED

export const fetchRemoteOrderTotalSucceeded = (payload) => ({
  type: REMOTE_ORDER_TOTAL_SUCCEEDED,
  payload
})

fetchRemoteOrderTotalSucceeded.type = REMOTE_ORDER_TOTAL_SUCCEEDED

export const fetchRemoteOrderTotalFailed = (payload) => ({
  type: REMOTE_ORDER_TOTAL_FAILED,
  payload
})

fetchRemoteOrderTotalFailed.type = REMOTE_ORDER_TOTAL_FAILED

export const clearRemoteOrderTotal = () => ({
  type: CLEAR_REMOTE_ORDER_TOTAL,
})

clearRemoteOrderTotal.type = CLEAR_REMOTE_ORDER_TOTAL
