import {
  ON_FIRMWARE_INFO_RECEIVED,
  ON_BUILD_INFO_RECEIVED,
  ON_PERIPHERAL_BUSY_STATE_CHANGE,
  ON_SAF_IS_UPLOADING,
  ON_SAF_UPLOAD_COMPLETE,
  ON_SAF_FULL_RESPONSE_RECEIVED,
  ON_RESET_SAF_FULL_RESPONSE,
  ON_TERMINAL_INFO_RECEIVED,
  ON_DATA_UPLOAD_COMPLETE,
  ON_DATA_IS_UPLOADING,
  SET_BRIDGE_PAYMENT_METHOD
} from '../actions/peripheral'
import { getNumericSafValue } from '../selectors/peripheral'
import { ToastManager } from '../utils/toastManager'

export const bridgePaymentMethods = {
  executeSaleRequest: 'executeSaleRequest',
  executeAuthRequest: 'executeAuthRequest',
}

const defaultState = {
  peripheralConnected: true,
  firmwareInfoReceived: false,
  buildInfoReceived: false,
  terminalInfoReceived: false,
  firmwareInfo: {},
  buildInfo: {},
  terminalInfo: {},
  peripheralIsBusy: false,
  safIsUploading: false,
  safFullResponse: {},
  bridgeMethod: 'executeSaleRequest'
}

const peripheralReducer = (state = defaultState, action) => {
  const connected = action.type === 'LANE_OPENED' || action.type === 'LANE_ATTACHED'
  const disconnected = action.type === 'LANE_CLOSED' || action.type === 'LANE_REMOVED'


  if (action.type === SET_BRIDGE_PAYMENT_METHOD) {
    return {
      ...state,
      bridgeMethod: action?.payload ?? 'executeSaleRequest',
    }
  }

  if (action.type === ON_FIRMWARE_INFO_RECEIVED) {
    return {
      ...state,
      firmwareInfoReceived: true,
      firmwareInfo: {
        ...(state?.firmwareInfo ?? {}),
        ...(action?.payload ?? {}),
      }
    }
  }

  if (action.type === ON_BUILD_INFO_RECEIVED) {
    return {
      ...state,
      buildInfoReceived: true,
      buildInfo: {
        ...(state?.buildInfo ?? {}),
        ...(action?.payload ?? {}),
      }
    }
  }

  if (action.type === ON_FIRMWARE_INFO_RECEIVED) {
    return {
      ...state,
      firmwareInfoReceived: true,
      firmwareInfo: {
        ...(state?.firmwareInfo ?? {}),
        ...(action?.payload ?? {}),
      }
    }
  }

  if (action.type === ON_TERMINAL_INFO_RECEIVED) {
    return {
      ...state,
      terminalInfoReceived: true,
      terminalInfo: {
        ...(state?.terminalInfo ?? {}),
        ...(action?.payload ?? {}),
      }
    }
  }

  if (action.type === ON_PERIPHERAL_BUSY_STATE_CHANGE) {
    return {
      ...state,
      peripheralIsBusy: action?.payload ?? false,
    }
  }

  if (action.type === ON_SAF_UPLOAD_COMPLETE) {
    return {
      ...state,
      safUploadComplete: action?.payload ?? false,
    }
  }

  if (action.type === ON_SAF_IS_UPLOADING) {
    return {
      ...state,
      safIsUploading: action?.payload ?? false,
    }
  }

  if (action.type === ON_DATA_UPLOAD_COMPLETE) {
    return {
      ...state,
      dataUploadComplete: action?.payload ?? false,
    }
  }

  if (action.type === ON_DATA_IS_UPLOADING) {
    return {
      ...state,
      dataIsUploading: action?.payload ?? false,
    }
  }

  if (action.type === ON_SAF_FULL_RESPONSE_RECEIVED) {
    const totalCount = getNumericSafValue(action?.payload?.SAFTotalCount)

    // When the total count from the card reader is 0, there is nothing on the card reader.
    if (totalCount === 0) {
      ToastManager.error('Nothing to Sync')
    }

    return {
      ...state,
      safFullResponse: action?.payload ?? { },
    }
  }

  if (action.type === ON_RESET_SAF_FULL_RESPONSE) {
    const currentResponse = state?.safFullResponse ?? { }
    let resetSafFullResponse = { }

    const totalCount = getNumericSafValue(action?.payload?.SAFTotalCount)
    const uploadedCount = getNumericSafValue(action?.payload?.SAFUploadedCount)

    if (totalCount > 0 && uploadedCount > 0 && (totalCount - uploadedCount) > 0) {
      resetSafFullResponse = currentResponse
    }

    return {
      ...state,
      safFullResponse: resetSafFullResponse,
    }
  }

  if (connected) {
    return {
      ...state,
      peripheralConnected: true
    }
  }

  if (disconnected) {
    return {
      ...state,
      peripheralConnected: false
    }
  }

  return state
}

export default peripheralReducer
